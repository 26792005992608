import React from 'react';
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ReactDoM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import Submit from 'components/Submit';
import ClientParametersComponent from 'components/ClientParametersComponent';
import { TreadDepthUnit } from '../TireSpecs/TreadDepthUnit';
import './Brakes.css';
import * as helperFunctions from 'utils/HelperFunctions';
import BrakeAxle from './BrakeAxle';
import BrakeAxleWithThickness from './BrakeAxleWithThickness';
import BrakeAxleWithDiameter from './BrakeAxleWithDiameter';
import * as constants from 'constants/App';
import Help from 'components/Help';
import StepNumber from "components/StepNumber";

class Brakes extends React.Component {

    render() {
        const messages = defineMessages({
            close_dialog: {
                id: 'close_dialog',
                description: '  Close',
                defaultMessage: 'Close'
            },
            save_brake_changes: {
                id: 'save_brake_changes',
                description: 'Save',
                defaultMessage: 'Save'
            },
            parameters_header: {
                id: 'parameters_header',
                description: 'Header for ata parameters ',
                defaultMessage: 'Parameters '
            },
            empty_parameters: {
                id: 'empty_parameters',
                description: 'Description when parameters are empty',
                defaultMessage: 'None at this time'
            },
            brake_title: {
                id: 'brake_title',
                description: 'Add new line item caption',
                defaultMessage: 'Brakes {brake_component} required'
            },
            continue_to_brake_spec: {
                id: 'continue_to_brake_spec',
                description: 'Continue',
                defaultMessage: 'Continue'
            },
            return: {
                id: 'return',
                description: 'Return',
                defaultMessage: 'Return'
            },
            front_brakes: {
                id: 'front_brakes',
                description: 'front_brakes',
                defaultMessage: 'Front'
            },
            left_brakes: {
                id: 'left_brakes',
                description: 'left_brakes',
                defaultMessage: 'Left'
            },
            right_brakes: {
                id: 'right_brakes',
                description: 'right_brakes',
                defaultMessage: 'Right'
            },
            rear_brakes: {
                id: 'rear_brakes',
                description: 'rear_brakes',
                defaultMessage: 'Rear'
            },
            inches_caption: {
                id: 'inches_caption',
                description: 'Inches',
                defaultMessage: 'Inches'
            },
            milli_meter: {
                id: 'milli_meter',
                description: 'MM',
                defaultMessage: 'MM'
            },
            add_axle: {
                id: 'add_axle',
                description: 'add_axle',
                defaultMessage: 'Add Axle'
            },
            remove_axle: {
                id: 'remove_axle',
                description: 'remove_axle',
                defaultMessage: 'Remove Axle'
            },
            measurements_in: {
                id: 'measurements_in',
                description: 'measurements_in',
                defaultMessage: 'Measurements in:'
            },
        });

        let productCodeParams = this.props.showParameters ? this.props.ataParametersSorted == undefined ? '' :
            this.props.ataParametersSorted.map((param) => {
                return <ClientParametersComponent key={param.get('id')}
                    description={param.get('description')}
                />
            })
            : false
            ;


        const saveBrakeInfo = <Submit
            caption={<FormattedMessage {...messages.save_brake_changes} />}
            imagePath="/assets/VMS_33button_add_blue.png"
            onClickHandler={this.props.onSaveBrakeChanges}
            showSpinner={this.props.isSavingBrakeInfo}
            spinnerType="blue" />


        const brakeDetailContainterWidthClass = 'brakes__image-frame-20';
        const vehicleFrameContainerWidthClass = 'brakes__image-frame-80';
        const brakesComponentHeader = this.props.componentType == constants.BRAKE_COMPONENT_TYPE.ROTOR ? 'Min. Thk.'
            : this.props.componentType == constants.BRAKE_COMPONENT_TYPE.DRUM ? 'Max. Dia.' : '';

        const showMinMaxHeader = this.props.componentType == constants.BRAKE_COMPONENT_TYPE.ROTOR
            || this.props.componentType == constants.BRAKE_COMPONENT_TYPE.DRUM

        let unit = this.props.defaultMeasurementUnit == 'inch' ? <FormattedMessage {...messages.inches_caption} />: <FormattedMessage {...messages.milli_meter} />;

        const brakesSpecificationContainerDimentions = {
            width: this.props.width,
        }
        const widthHeightForContainer =  { style: brakesSpecificationContainerDimentions } ;
        const addContainerPadding = this.props.showSave
        return (
            <div >
                <div className={classnames(addContainerPadding?"brakes-container":false)}>

                    {
                        this.props.showCloseLink ?
                            <div className="row brakes__close">
                                <div onClick={this.props.onCloseWindow}>
                                    <img src="/assets/VMS_33button_kill_blue.png" />
                                    {"  "} {<FormattedMessage {...messages.close_dialog} />}
                                </div>
                            </div>
                            : false
                    }

                    

                    <div key='div-container' className={classnames('row brakes__content', this.props.readOnly ? 'brakes-content-disabled' : false)}>
                        <StepNumber number={this.props.stepNumber}/>
                        <div key='div-container-spec'  {...widthHeightForContainer}>
                            <div className={classnames('brakes__sub-content')}>
                                <div className={vehicleFrameContainerWidthClass}>
                                <div className="row brake-frame__title">
                                    <div>
                                        {this.props.title}
                                        <Help videoId="brakes"  whiteSpaceAtBottomHeight="1.9em"/>  
                                    </div>
                                </div>
                                    <div className='flex-container brakes__image'>
                                        {this.props.brakeComponentImage}
                                    </div>
                                </div>
                                <div className={brakeDetailContainterWidthClass}>
                                <div className="brake-frame__title">{" "}</div>
                                    <div>
                                        <TreadDepthUnit caption={<FormattedMessage {...messages.measurements_in} />}
                                            onChangeMeasurementUnit={this.props.onChangeMeasurementUnit}
                                            defaultMeasurementUnit={this.props.defaultMeasurementUnit}
                                            canShowUnitChangeConfirmation = {this.props.canShowUnitChangeConfirmation}
                                            isProductType = {'brakes'}
                                        />
                                    </div>
                                    <div className='brakes-components'>
                                        <div className={'brake-axle-flex'}>
                                            <div className={'brakes-component-header-front'}><FormattedMessage {...messages.front_brakes} /></div>
                                            {showMinMaxHeader ?
                                                <div className={'brakes-component-header'}></div>
                                                : false
                                            }
                                            <div className='brake-axle-simple'></div>


                                        </div>
                                        <div className={'brake-axle-flex'}>
                                            <div className={'brakes-component-header brakes-component-header-left'}><FormattedMessage {...messages.left_brakes} /></div>
                                            <div className={'brakes-component-header brakes-component-header-right'}><FormattedMessage {...messages.right_brakes} /></div>
                                            {
                                                showMinMaxHeader ?
                                                    <div className={'brakes-component-header'}>{brakesComponentHeader}</div>
                                                    : false
                                            }

                                            <div className='brake-axle-simple'></div>

                                        </div>
                                        {
                                            this.props.brakeAxles.map((axle) => {
                                                if (this.props.componentType == constants.BRAKE_COMPONENT_TYPE.ROTOR) {
                                                    return <BrakeAxleWithThickness
                                                        axleNumber={axle.axleNumber}
                                                        unit={this.props.defaultMeasurementUnit}
                                                        minRotorThickness={axle.minRotorThickness}
                                                        leftBrake={axle.leftBrake}
                                                        rightBrake={axle.rightBrake}
                                                        componentType={axle.componentType}
                                                        onChangeBrakeThickness={this.props.onChangeBrakeThickness}
                                                        showValidationError={this.props.showValidationError}
                                                        validationErrors={this.props.validationErrors}
                                                        defaultMeasurementUnit={unit}
                                                    />
                                                }
                                                if (this.props.componentType == constants.BRAKE_COMPONENT_TYPE.DRUM) {
                                                    return <BrakeAxleWithDiameter
                                                        axleNumber={axle.axleNumber}
                                                        unit={this.props.defaultMeasurementUnit}
                                                        maxDrumDiameter={axle.maxDrumDiameter}
                                                        leftBrake={axle.leftBrake}
                                                        rightBrake={axle.rightBrake}
                                                        componentType={axle.componentType}
                                                        onChangeBrakeThickness={this.props.onChangeBrakeThickness}
                                                        showValidationError={this.props.showValidationError}
                                                        validationErrors={this.props.validationErrors}
                                                        defaultMeasurementUnit={unit}
                                                    />
                                                }
                                                if (this.props.componentType == constants.BRAKE_COMPONENT_TYPE.PAD
                                                    || this.props.componentType == constants.BRAKE_COMPONENT_TYPE.SHOE) {
                                                    return <BrakeAxle
                                                        axleNumber={axle.axleNumber}
                                                        unit={this.props.defaultMeasurementUnit}
                                                        leftBrake={axle.leftBrake}
                                                        rightBrake={axle.rightBrake}
                                                        componentType={axle.componentType}
                                                        onChangeBrakeThickness={this.props.onChangeBrakeThickness}
                                                        showValidationError={this.props.showValidationError}
                                                        validationErrors={this.props.validationErrors}
                                                        defaultMeasurementUnit={unit}
                                                    />
                                                }
                                            })
                                        }
                                        <div className={'brake-axle-flex'}>
                                            <div className={'brakes-component-header-front'}><FormattedMessage {...messages.rear_brakes} /></div>
                                            {showMinMaxHeader ?
                                                <div className={'brakes-component-header'}></div>
                                                : false
                                            }
                                            <div className='brake-axle-simple'></div>

                                        </div>
                                        <div className='brakes__axle-footer brake-axle-flex'>
                                            {
                                                this.props.canAddAxle && !this.props.readOnly ?
                                                    <div >
                                                        <Submit
                                                            caption={<FormattedMessage {...messages.add_axle} />}
                                                            onClickHandler={this.props.onAddAxle}
                                                            imagePath="/assets/VMS_33button_add_blue.png"
                                                            imageHeight={'25px'}
                                                            imageWidth={'auto'}
                                                        />


                                                    </div>
                                                    : false
                                            }
                                            {showMinMaxHeader ?
                                                <div className={'brakes-component-header'}></div>
                                                : false
                                            }

                                            <div className='brake-axle-simple'></div>

                                        </div>

                                        <div className='brakes__axle-footer brake-axle-flex'>

                                            {
                                                this.props.canRemoveAxle && !this.props.readOnly ?
                                                    <div>
                                                        <Submit
                                                            caption={<FormattedMessage {...messages.remove_axle} />}
                                                            onClickHandler={this.props.onRemoveAxle}
                                                            imagePath="/assets/VMS_33button_remove_blue.png"
                                                            imageHeight={'25px'}
                                                            imageWidth={'auto'}
                                                        />
                                                    </div>
                                                    : false
                                            }
                                            {showMinMaxHeader ?
                                                <div className={'brakes-component-header'}></div>
                                                : false
                                            }

                                            <div className='brake-axle-simple'></div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.showSave && !this.props.readOnly ?
                            <div className="row">
                                <div className={classnames('col-sm-12', 'brakes__save')}>
                                    {saveBrakeInfo}
                                </div>
                            </div>
                            : false
                    }
                    {this.props.showParameters ?
                        <div className="row brakes__parameters">
                            <div className="brakes__parameters-header">
                                <FormattedMessage {...messages.parameters_header} />
                            </div>
                            <div>
                                {productCodeParams.size === 0 ?
                                    <p className="brakes__parameters__empty__description">
                                        <FormattedMessage {...messages.empty_parameters} />
                                    </p>
                                    :
                                    <div> {productCodeParams} </div>
                                }
                            </div>
                        </div>
                        : false
                    }
                </div>
            </div >
        );
    }
}

export default Brakes;