import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import './SideBar.css';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useAppState } from 'shared/AppState/app-state';
import * as constants from 'constants/App';
import { isAuthenticated, isPersonaAuthenticated } from 'utils/HelperFunctions';
import * as Api from 'utils/Api';
import styled from "styled-components";

// #region Styled Components
const AgedPO = styled.div`
            height: 120px;
            width: auto;
            padding-bottom: 9em;
            padding-left: 1em;
            cursor: pointer;
`;
const AgedPOChild = styled.div`
            position: relative;
            top: -74px;
            left: 24px;
            font-size: 15px;
            font-family: "Gotham SSm A", "Gotham SSm B";font-style: normal;font-weight: 800;
            color: white;
`;

const AgedPOText = styled.div`
            color:#337ab7;
            font-size: 16px;
            text-align: center;
            padding-right: .7em;
            margin-top: -1em;
            
`;

// #endregion


const SideBar = ({ location, dispatch, loginPersona, loginVendor, personaDefaultStoreId, vendorId }) => {

    const [appState] = useAppState();
    const [agedPoCount, setAgedPoCount] = useState(0);
    const { serviceClassification, dealerDraftStatus, token } = appState;
    const showAllOpenPOsIcon = token === undefined ? false : (location.pathname == '/') || (location.pathname == '/carWash') || (location.pathname == '/ariuser') || (location.pathname.toLowerCase() == '/tlswarning/learnmore') ? false : true;
    const isDealerDraft = (location.pathname == '/dealerDrafts') ? false : dealerDraftStatus ? true : false;
    const showHelpNFeedBackLinks = (location.pathname.toLowerCase() == '/tlswarning/learnmore') ? false : true;
    const personaUserWithoutDefaultStore = loginPersona && personaDefaultStoreId === undefined;
    const personaUserWithDefaultStore = personaDefaultStoreId !== undefined && location.pathname.includes('/Persona');
    const showAdvancePOSearch = token === undefined ? false : true;

    // #region UseEffect
    useEffect(() => {
        const fetchAgedPOsCount = async () => {
            try {

                // Make API call 
                if (token !== undefined) {
                    const count = await Api.fetchOpenOrdersOver30DayCount(token, vendorId);

                    setAgedPoCount(count.statistics.ordersStatistics.openOrdersOver30DaysCount);
                }

            }
            catch (e) {
            }
        };
        fetchAgedPOsCount();
    }, [dispatch, token, vendorId, location.pathname]);
    // #endregion

    const messages = defineMessages({
        help_link: {
            id: 'help_link',
            description: 'Text for Help Link in header',
            defaultMessage: 'Help'
        },
        contact_link: {
            id: 'contact_link',
            description: 'Text for Contact Link in header',
            defaultMessage: 'Contact'
        },
        view_all_courtesy: {
            id: 'view_all_courtesy',
            description: 'view_all_courtesy',
            defaultMessage: 'View all open Courtesy Requests'
        }, open_pos_link_2: {
            id: 'open_pos_link_2',
            description: 'Text for Viewing All Open Purchase Orders Link in header',
            defaultMessage: 'View all open purchase orders'
        },
        advanced_search_link: {
            id: 'advanced_search_link',
            description: 'Text for navigating to advance search',
            defaultMessage: 'SEARCH PURCHASE ORDERS'
        },
        aged_po_link: {
            id: 'aged_po_link',
            description: 'Text for navigating to aged po',
            defaultMessage: 'View aged purchase orders'
        }
    });

    function onLogoClickHandler() {

        if (personaUserWithoutDefaultStore) {
            dispatch({ type: constants.NAVIGATE_TO, path: '/Persona/AccessStores' });

        } else if (personaUserWithDefaultStore) {
            personaDefaultLoginShopView(personaDefaultStoreId)

        } else if (loginVendor) {
            if (serviceClassification === "CARWASH") {
                dispatch({ type: constants.NAVIGATE_TO, path: '/carWash' });
            } else {
                dispatch({ type: constants.NAVIGATE_TO, path: '/' });
            }

        } else {
            dispatch({ type: constants.NAVIGATE_TO, path: '/login' });
        }
    }

    function personaDefaultLoginShopView(storeId) {

        dispatch({
            type: "PERSONA_ACCESS_STORE_CLICKED",
            storeId: storeId
        });

    }

    function expandSelect() {
        var advanceSearchDivElement = document.getElementById('divAdvanceSearchOption');
        var advanceSearchBtnElement = document.getElementById('btnAdvanceSearchOptions');
        advanceSearchBtnElement.focus();
        advanceSearchDivElement.classList.toggle('open');
    }

    return <div className="site-sidebar">
        <div>
            <div css={`
                  display: inline-block;                                                    
                  padding-top: 4rem;
                  padding-bottom: 4rem;
                  padding-right: 1rem;
                  max-width: 200px;
                `}>
                <a onClick={onLogoClickHandler}> <img className="site-sidebar__logo" src="/assets/Logo-Primary-PMS.png" /></a>
            </div>

            {
                showHelpNFeedBackLinks && (<div>
                    <img src='/assets/Help_Icon_light_blue.png' />
                    <Link className="site-sidebar__nav-link"
                        to='/contact/HelpFAQ'><FormattedMessage {...messages.help_link} /></Link>
                    {' '} | {' '}
                    <img src='/assets/ContactIcon_light_blue.png' />
                    <Link className="site-sidebar__nav-link"
                        to="/contact/FeedbackForm"><FormattedMessage {...messages.contact_link} /></Link>
                </div>)
            }
            {showAdvancePOSearch ?
                <div onClick={expandSelect} className="site-sidebar__search">
                    <img className="site-sidebar__logo_search" src="/assets/vms_adv_search_glass.png" />
                    <a className="site-sidebar__adv_search">
                        <FormattedMessage {...messages.advanced_search_link} />
                    </a>
                </div>
                : false
            }
            {agedPoCount > 0 && (location.pathname != '/attentionNeeded') && appState.isReviewOpenPOsUponLoginEnabled ?
                <AgedPO>
                    <Link to={"/attentionNeeded?type=agedPOs"}>
                        <img src="/assets/Aged_PO_icon.png" width={85} />
                        <AgedPOChild>
                            {agedPoCount}
                        </AgedPOChild>
                        <AgedPOText><FormattedMessage {...messages.aged_po_link} /></AgedPOText>
                    </Link>
                </AgedPO>
                : false
            }
            {showAllOpenPOsIcon ?
                <div>
                    <Link to={serviceClassification !== "CARWASH" ? "/" : "/carWash"}><img className="site-sidebar__logo" src="/assets/VMS_POs.png" /></Link>
                    <Link to={serviceClassification !== "CARWASH" ? "/" : "/carWash"}><div className="site-sidebar__open_pos"><FormattedMessage {...messages.open_pos_link_2} /></div></Link>
                </div>
                : false
            }
            {isDealerDraft ?
                <div>
                    <Link to="/dealerDrafts"><img className="site-sidebar__logo-courtesy" src="/assets/View_All_Courtesy_DeliveryIcon.png" /></Link>
                    <Link to="/dealerDrafts"><div className="site-sidebar__open_pos"><FormattedMessage {...messages.view_all_courtesy} /></div></Link>
                </div>
                : false

            }
        </div>
    </div>
};

function select(state) {

    return {
        loginVendor: isAuthenticated(state),
        loginPersona: isPersonaAuthenticated(state),
        personaDefaultStoreId: state.appState.getIn(['serverData', 'shared', 'personaUser', 'defaultStoreId']) || undefined,
        vendorId: state.appState.getIn(["serverData", "shared", "vendorId"]),

    };
}
export default connect(select)(SideBar);
