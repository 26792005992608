/**
 * Created by prboyapa on 1/11/2016.
 */
import React from 'react';
import { defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import { PO_AUTHORIZATION_STATUS, PO_PAYMENT_STATUS,PAYMENT_TYPE } from 'constants/App';
import moment from 'moment';
import Title from 'components/Title';
import Help from 'components/Help';
import './PurchaseOrderTitle.css';
import { IntlProvider } from 'react-intl';
import Features from 'components/Features';

class PurchaseOrderTitle extends React.Component {
    static getCaptionByPOStatus(poNumber, authorizedStatus, paymentStatus,readOnlyPOMode,country) {
        const messages = defineMessages({
            approved_pos_caption: {
                id: 'approved_pos_caption',
                description: 'Approved for service',
                defaultMessage: 'Approved for service'
            },
            waiting_for_ari_approval_caption: {
                id: 'waiting_for_ari_approval_caption',
                description: 'Waiting for Holman approval',
                defaultMessage: 'Waiting for Holman approval'
            },
            waiting_for_client_approval_caption: {
                id: 'waiting_for_client_approval_caption',
                description: 'Waiting for client approval',
                defaultMessage: 'Waiting for client approval'
            },
            waiting_for_driver_validation_caption: {
                id: 'waiting_for_driver_validation_caption',
                description: 'Waiting for driver validation',
                defaultMessage: 'Waiting for driver validation'
            },
            purchase_order_payment_in_progress: {
                id: 'purchase_order_payment_in_progress',
                description: 'Payment in progress',
                defaultMessage: 'Payment in progress'
            },
            purchase_order_paid: {
                id: 'purchase_order_paid',
                description: 'Purchase order no. {poNumber}',
                defaultMessage: 'Purchase order no. {poNumber}'
            },
            your_action_required: {
                id: 'your_action_required',
                description: 'Your action required',
                defaultMessage: 'Your action required'
            },
            edit_purchase_order: {
                id: 'edit_purchase_order',
                description: 'Purchase order no.',
                defaultMessage: 'Purchase order no.'
            },
            work_completed_pos_caption: {
                id: 'work_completed_pos_caption',
                description: 'Work Completed',
                defaultMessage: 'Work Completed'
            },
            new_work_assignment:{
                id: 'new_work_assignment',
                description:'New Work Assignment',
                defaultMessage:'New Work Assignment'
            },
            waiting_for_ari_approval_caption_mex: {
                id: 'waiting_for_ari_approval_caption_mex',
                description: 'Waiting for ARIZA approval',
                defaultMessage: 'Waiting for ARIZA approval'
            },
            approval_not_requested:{
                id:'approval_not_requested',
                description:'Approval not requested',
                defaultMessage:'Approval not requested'
            }
            
        });

        if (authorizedStatus === PO_AUTHORIZATION_STATUS.APPROVED && paymentStatus === PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED) {
            return <FormattedMessage {...messages.approved_pos_caption} />;
        } else if (paymentStatus === PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS) {
            return <FormattedMessage {...messages.purchase_order_payment_in_progress} />;
        } else if (authorizedStatus === PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL) {
            return country=="MEX"?<FormattedMessage {...messages.waiting_for_ari_approval_caption_mex} />:
             <FormattedMessage {...messages.waiting_for_ari_approval_caption} />;
        } else if (authorizedStatus === PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL) {
            return <FormattedMessage {...messages.waiting_for_client_approval_caption} />;
        } else if (authorizedStatus === PO_AUTHORIZATION_STATUS.WORK_COMPLETED) {
            return <FormattedMessage {...messages.work_completed_pos_caption} />;
        } else if (authorizedStatus === PO_AUTHORIZATION_STATUS.WAITING_FOR_DRIVER_VALIDATION) {
            return <FormattedMessage {...messages.waiting_for_driver_validation_caption} />;            
        } else if (paymentStatus === PO_PAYMENT_STATUS.PAID) {
            return <FormattedMessage values={{ poNumber: poNumber }} {...messages.purchase_order_paid} />;
        } else if (readOnlyPOMode && authorizedStatus==PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT){
            return <FormattedMessage {...messages.new_work_assignment} />;                        
        }else if(authorizedStatus === PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED){
            return <FormattedMessage {...messages.approval_not_requested} />;           
        }else if(authorizedStatus === PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL){
            return <FormattedMessage {...messages.your_action_required} />;   
        }
        else {
            return country=="MEX"?<FormattedMessage {...messages.waiting_for_ari_approval_caption_mex} />:
             <FormattedMessage {...messages.waiting_for_ari_approval_caption} />;
        }
    }

    render() {
        //render the view
        const messages = defineMessages({
            purchase_order_no: {
                id: 'purchase_order_no',
                description: 'Purchase order no. {poNumber}',
                defaultMessage: 'Purchase order no. {poNumber}'
            },
            approved_po_total: {
                id: 'approved_po_total',
                description: 'Approved total: ',
                defaultMessage: 'Approved total: '
            },
            bank_account_no: {
                id: 'bank_account_no',
                description: 'Last four digits of bank account: {bankAccountNo}',
                defaultMessage: 'Last four digits of bank account: {bankAccountNo}'
            },
            purchase_order_title_unavailable: {
                id: 'purchase_order_title_unavailable',
                description: 'Unavailable',
                defaultMessage: 'Unavailable'
            },
            close_purchase_order: {
                id: 'close_purchase_order',
                description: 'Close purchase order no.',
                defaultMessage: 'Close purchase order no.'
            },

            purchase_order_title_paid_to_bank: {
                id: 'purchase_order_title_paid_to',
                description: ' to *******{bankAccountNo}',
                defaultMessage: ' to *******{bankAccountNo}'
            },
            purchase_order_title_paid_to_date: {
                id: 'purchase_order_title_paid_to',
                description: ' paid on {paidDate} ',
                defaultMessage: ' paid on {paidDate} '
            },
            purchase_order_title_paid_to: {
                id: 'purchase_order_title_paid_to',
                description: ' paid on {paidDate} to *******{bankAccountNo}',
                defaultMessage: ' paid on {paidDate} to *******{bankAccountNo}'
            }
        });

        return <Features render={restrictedFeatures => ( <div className="purchase_order_vehicle_title">
            {
                //Title by actionType
                this.props.actionType == "edit"
                    ? this.props.authorizedStatus==PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED?       <Title caption={PurchaseOrderTitle.getCaptionByPOStatus(this.props.poNumber, this.props.authorizedStatus, this.props.paymentStatus,restrictedFeatures.readOnlyPOMode,restrictedFeatures.selectedCountry)}  />   
                    : <FormattedMessage values={{ poNumber: this.props.poNumber }} {...messages.purchase_order_no} />
                    : this.props.actionType == "close"
                        ? <FormattedMessage {...messages.close_purchase_order} />
                        :
                        this.props.authorizedStatus== PO_AUTHORIZATION_STATUS.WORK_COMPLETED ?
                        <Title caption={PurchaseOrderTitle.getCaptionByPOStatus(this.props.poNumber, this.props.authorizedStatus, this.props.paymentStatus,restrictedFeatures.readOnlyPOMode,restrictedFeatures.selectedCountry)} videoId={"work_completed_pos"} /> :
                        <Title caption={PurchaseOrderTitle.getCaptionByPOStatus(this.props.poNumber, this.props.authorizedStatus, this.props.paymentStatus,restrictedFeatures.readOnlyPOMode,restrictedFeatures.selectedCountry)}  />                     
                        
                        
            }

            {
                //2nd level sub title by actionType
                /*Show PO number along with the caption for edit and close po*/
                this.props.actionType == "close"
                    ? this.props.poNumber
                    : false
            }

            {
                //2nd level sub title by paymentStatus                
                this.props.paymentStatus == PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS ?
                    <div>
                        <div className="purchase_order_vehicle__sub_title">
                            <FormattedMessage values={{ poNumber: this.props.poNumber }} {...messages.purchase_order_no} />
                        </div>
                        <div className="purchase_order_vehicle__sub_title">
                            <FormattedMessage {...messages.approved_po_total} />
                            <IntlProvider locale="en">
                            <FormattedNumber value={this.props.approvedPOTotal + this.props.taxAmount}
                                style="currency" currency={this.props.selectedCurrency} /></IntlProvider>
                        </div>
                        {this.props.features.get('workCompletion') ? false:
                            <div className="purchase_order_vehicle__sub_title">
                                <FormattedMessage values={{
                                    bankAccountNo: this.props.bankAccountNo == ''
                                        ? <FormattedMessage {...messages.purchase_order_title_unavailable} />
                                        : this.props.bankAccountNo
                                }} {...messages.bank_account_no} />
                            </div>
                        }
                    </div>
                    : this.props.paymentStatus == PO_PAYMENT_STATUS.PAID  && !this.props.hideCostInformation?

                         <div className="purchase_order_vehicle__sub_title">
                             <IntlProvider locale="en">
                            <FormattedNumber value={this.props.approvedPOTotal + this.props.taxAmount}
                                style="currency" currency={this.props.selectedCurrency}/></IntlProvider>
                                <FormattedMessage values={{                                     
                                     paidDate: moment(this.props.paymentDate).format('MMM DD, YYYY')
                                 }} {...messages.purchase_order_title_paid_to_date} />
                             {this.props.features.get('workCompletion') || ( this.props.paymentInformation.get("paymentType") === 'STANDARD' && this.props.paymentInformation.get("paymentMethod ") === "CHECK") ?
                                 false
                                 :
                                 <FormattedMessage values={{
                                     bankAccountNo: this.props.bankAccountNo                                     
                                 }} {...messages.purchase_order_title_paid_to_bank} />
                             }
                        </div>
                        : false
            }
        </div>)} />;
    }
}

export default PurchaseOrderTitle;
