import React from 'react';
import ReactDOM from 'react-dom';
import {defineMessages, FormattedMessage, injectIntl,RawIntlProvider} from 'react-intl';
import {Link} from 'react-router-dom';
import {Popover, Overlay} from 'react-bootstrap';
import './VehicleSearchCreatePOView.css';
import Spinner from 'components/Spinner';
import * as actions from '../actions/VehicleSearchCreatePO';
import {falseIfUndefined, emptyMapIfUndefined, emptyStringIfUndefined} from 'utils/HelperFunctions';
import {connect} from 'react-redux';
import * as constants from 'constants/App';
import Help from '../../components/Help';
import {fromJS} from "immutable";

class VehicleSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            createFilters: {
            }
        };
    }

    componentDidMount() {
        this.OnHide();

        if (this.props.serviceClassification === "CARWASH") {
            this.props.dispatch({type: "FETCH_CLIENT_NUMBERS"});
            this.getVehicleSearchCreateOptionText(constants.SEARCH_FILTER_TYPE.CLIENT_VEHICLE);
            this.onSearchTypeChangedHandler(constants.SEARCH_FILTER_TYPE.CLIENT_VEHICLE);

        } else {
            this.getVehicleSearchCreateOptionText(constants.SEARCH_FILTER_TYPE.VIN8);
            this.onSearchTypeChangedHandler(constants.SEARCH_FILTER_TYPE.VIN8);
        }
    }


    //on create click handler
    onCreateClickHandler() {

        let createFilters = this.state.createFilters;

        createFilters.createValue = {
            vin8: this.refs.vin8 !== undefined ? this.refs.vin8.value : '',
            licensePlate: this.refs.plate !== undefined || this.refs.tag !== undefined ? this.props.country === "USA" ? this.refs.plate.value : this.refs.tag.value : '',
            licensePlateState: this.refs.vendorState !== undefined || this.refs.vendorProv !== undefined ? this.props.country === "USA" ? this.refs.vendorState.value : this.refs.vendorProv.value : '',
            countryCode: this.props.country,
            custId: this.refs.clientNumber !== undefined ? this.refs.clientNumber.value : '',
            vehicle: this.refs.searchARIUnitNumber !== undefined ? this.refs.searchARIUnitNumber.value : '',
            vehicleServiceCardNo: this.refs.serviceNo !== undefined ? this.refs.serviceNo.value : '',
            serviceClass: this.props.serviceClassification
        };

        this.props.dispatch({type: "CREATE_PO_SEARCH_CLICK", createFilters});


    }

    //error message hide
    OnHide() {

        this.props.dispatch(actions.hideVehicleSearchErrorMessage());
        this.props.dispatch({type: constants.CLEAR_EXISTING_PO});
        this.props.dispatch({ type: constants.CLEAR_PURCHASE_ORDER })
        this.props.dispatch({type: "VALIDATION_SEARCH_PO_CREATION_RESOLVED"})
    }


    //create option text
    getVehicleSearchCreateOptionText(optionValue) {
        const messages = defineMessages({
            vin8_label: {
                id: 'vin8_label',
                description: 'Search by VIN8',
                defaultMessage: 'VIN8'
            },
            plate_state_label: {
                id: 'plate_state_label',
                description: 'plate_state_label',
                defaultMessage: 'License Plate and State'
            },
            tag_prov_label: {
                id: 'tag_prov_label',
                description: 'tag_prov_label',
                defaultMessage: 'Tag and Providence'
            },
            service_card_label: {
                id: 'service_card_label',
                description: 'service_card_label',
                defaultMessage: 'Service Card No.'
            },
            client_vehicle_label: {
                id: 'client_vehicle_label',
                description: 'client_vehicle_label',
                defaultMessage: 'Client ID & Unit No.'
            },
        });

        switch (optionValue) {
            case constants.SEARCH_FILTER_TYPE.VIN8:
                return <FormattedMessage {...messages.vin8_label} />;

            case constants.SEARCH_FILTER_TYPE.PLATE_STATE:
                return <FormattedMessage {...messages.plate_state_label} />;

            case constants.SEARCH_FILTER_TYPE.TAG_PROV:
                return <FormattedMessage {...messages.tag_prov_label} />;

            case constants.SEARCH_FILTER_TYPE.SERVICE_CARD:
                return <FormattedMessage {...messages.service_card_label} />;

            case constants.SEARCH_FILTER_TYPE.CLIENT_VEHICLE:
                return <FormattedMessage {...messages.client_vehicle_label} />;

            default:
                return this.props.serviceClassification === "CARWASH"?<FormattedMessage {...messages.client_vehicle_label} />  : <FormattedMessage {...messages.vin8_label} />;
        }
    }

    //which create is selected
    onSearchTypeChangedHandler(createForValue, e) {
        this.props.dispatch({type: "CLEAR_SEARCH_ERROR_MESSAGE"});
        this.props.dispatch({type: constants.CLEAR_ERROR_MESSAGE});
        this.setState({createFilters : {createFor:createForValue }});

    }

    //rendering textboxes for search
    renderTextboxDisplay(createForValue,e){
        let createFilters = this.state.createFilters;
        const {formatMessage} = this.props.intl;
        let location = this.props.country === "CAN" ? this.props.canadianProvinces : this.props.usStates;

            switch (createForValue) {
                case constants.SEARCH_FILTER_TYPE.VIN8:
                    return <div className="veh-search__input">
                        <input type="text"
                               className="form-control-create"
                               maxLength="8"
                               size="10"
                               ref="vin8"
                               placeholder={this.getSearchInputPlaceHolderText(createFilters.createFor)}
                               autoFocus
                        />

                    </div>;
                    break;
                case constants.SEARCH_FILTER_TYPE.PLATE_STATE:
                    return <span>
                            <div className="veh-search__input">
                                <input
                                    type="text"
                                    className="form-control-create"
                                    cols="10"
                                    maxLength="10"
                                    size="10"
                                    ref="plate"
                                    placeholder={this.getSearchInputPlaceHolderText(constants.SEARCH_FILTER_TYPE.LICENSE)}
                                    autoFocus
                                />
                            </div>
                            <div className="veh-search__input">
                                <select className="form-control-create2" ref='vendorState'
                                >
                                    <option key="select_state" value=""
                                            default> {this.getSearchInputPlaceHolderText(constants.SEARCH_FILTER_TYPE.STATE)}</option>
                                    {location.map((item) => {
                                        return <option key={item.get("abbreviation")}
                                                       value={item.get("abbreviation")}>{item.get("name")}</option>
                                    })}
                                </select>
                            </div>
                        </span>;
                    break;
                case constants.SEARCH_FILTER_TYPE.TAG_PROV:
                    return <span>
                                <div className="veh-search__input2">
                                    <input
                                        type="text"
                                        className="form-control-create"
                                        maxLength="10"
                                        size="10"
                                        ref="tag"
                                        placeholder={this.getSearchInputPlaceHolderText(constants.SEARCH_FILTER_TYPE.TAG)}
                                        autoFocus
                                    />
                                </div>
                                <div className="veh-search__input3">
                                    <select ref='vendorProv' className="form-control-create3"
                                    >
                                        <option key="select_state" value=""
                                                default> {this.getSearchInputPlaceHolderText(constants.SEARCH_FILTER_TYPE.PROVIDENCE)}</option>
                                        {location.map((item) => {
                                            return <option key={item.get("abbreviation")}
                                                           value={item.get("abbreviation")}>{item.get("name")}</option>
                                        })}
                                    </select>
                                </div>
                            </span>;
                    break;
                case constants.SEARCH_FILTER_TYPE.SERVICE_CARD:
                    return <div className="veh-search__input">
                        <input type="text"
                               maxLength="22"
                               size="22"
                               ref="serviceNo"
                               className="form-control-create"
                               placeholder={this.getSearchInputPlaceHolderText(constants.SEARCH_FILTER_TYPE.SERVICE_CARD)}
                               autoFocus
                        />
                    </div>;
                    break;
                default:
                    return <span>
                         <div className="veh-search__input2">
                             {this.props.clientNumbers.size > 0 ?
                                 <select ref='clientNumber'
                                         className="form-control-create">
                                     <option key="selectClientNo" value=""
                                             default> {this.getSearchInputPlaceHolderText(constants.SEARCH_FILTER_TYPE.CLIENT)} </option>
                                     {this.props.clientNumbers.valueSeq().map((item) => {
                                         return <option key={item.get("Id")}
                                                        value={item.get("Id")}>{item.get("Id")}</option>
                                     })}
                                 </select>

                                 :
                                 <input className="form-control-create"
                                        maxLength="14"
                                        size="14"
                                        placeholder={this.getSearchInputPlaceHolderText(constants.SEARCH_FILTER_TYPE.CLIENT)}
                                        ref='clientNumber'/>


                             }
                                    </div>

                                    <div className="veh-search__input2">
                                        <input className="form-control-create"
                                               type="text" ref="searchARIUnitNumber"
                                               placeholder={this.getSearchInputPlaceHolderText(constants.SEARCH_FILTER_TYPE.VEHICLE)}
                                               maxLength="14"
                                               size="10"
                                               defaultValue={""}
                                        />
                                    </div>
                                </span>;
            }
    }

    //placeholder text
    getSearchInputPlaceHolderText(searchForFilter) {
        const {formatMessage} = this.props.intl;
        const messages = defineMessages({
            search_for_po_orders_by_vin8: {
                id: 'search_for_po_orders_by_vin8',
                description: 'search_for_po_orders_by_vin8',
                defaultMessage: 'Enter the last 8 digits of the VIN'
            }, search_for_po_orders_by_plate: {
                id: 'search_for_po_orders_by_plate',
                description: 'search_for_po_orders_by_plate',
                defaultMessage: 'Enter license plate'
            }, search_for_po_orders_by_state: {
                id: 'search_for_po_orders_by_state',
                description: 'search_for_po_orders_by_state',
                defaultMessage: 'State'
            }, search_for_po_orders_by_prov: {
                id: 'search_for_po_orders_by_prov',
                description: 'search_for_po_orders_by_prov',
                defaultMessage: 'Province'
            }, search_for_po_orders_by_client: {
                id: 'search_for_po_orders_by_client',
                description: 'search_for_po_orders_by_client',
                defaultMessage: 'Enter client number'
            }, search_for_po_orders_by_vehicle: {
                id: 'search_for_po_orders_by_vehicle',
                description: 'search_for_po_orders_by_invoice',
                defaultMessage: 'Enter vehicle number'
            }, search_for_po_orders_by_service_no: {
                id: 'search_for_po_orders_by_service_no',
                description: 'search_for_po_orders_by_service_no',
                defaultMessage: 'Enter service card number'
            },

        });

        if (searchForFilter === constants.SEARCH_FILTER_TYPE.VIN8)
            return formatMessage(messages.search_for_po_orders_by_vin8);
        else if (searchForFilter === constants.SEARCH_FILTER_TYPE.LICENSE)
            return formatMessage(messages.search_for_po_orders_by_plate);
        else if (searchForFilter === constants.SEARCH_FILTER_TYPE.STATE)
            return formatMessage(messages.search_for_po_orders_by_state);
        else if (searchForFilter === constants.SEARCH_FILTER_TYPE.TAG)
            return formatMessage(messages.search_for_po_orders_by_plate);
        else if (searchForFilter === constants.SEARCH_FILTER_TYPE.PROVIDENCE)
            return formatMessage(messages.search_for_po_orders_by_prov);
        else if (searchForFilter === constants.SEARCH_FILTER_TYPE.CLIENT)
            return formatMessage(messages.search_for_po_orders_by_client);
        else if (searchForFilter === constants.SEARCH_FILTER_TYPE.VEHICLE)
            return formatMessage(messages.search_for_po_orders_by_vehicle);
        else if (searchForFilter === constants.SEARCH_FILTER_TYPE.SERVICE_CARD)
            return formatMessage(messages.search_for_po_orders_by_service_no);
    }

    //error handling overlay
    renderValidationOverlay(fieldName, errorMessage, flag, placement) {

        return <Overlay
            show={this.props.poCreationErrorFlag}
            target={() => ReactDOM.findDOMNode(this.refs[fieldName])}
            placement={placement != undefined ? placement : "bottom"}
            container={this}>
            <Popover id={"popOver" + fieldName} className="enrollment-request__error_message">
            <RawIntlProvider value={this.props.intl}>
                <FormattedMessage
                    values={{vehicleSearchError: this.props.newErrorMessage}} {...errorMessage} />
                    </RawIntlProvider>
            </Popover>
        </Overlay>
    }

    validationVehicleSearchCreate(e) {
        e.preventDefault();
        const messages = defineMessages({
            vin8_error: {
                id: 'vin8_error',
                description: 'vin8_error',
                defaultMessage: 'Please input a vin8'
            },
            plate_error: {
                id: 'plate_error',
                description: 'plate_error',
                defaultMessage: 'Please enter your license plate'
            },
            state_error: {
                id: 'state_error',
                description: 'state_error',
                defaultMessage: 'Please select a State'
            },
            tag_error: {
                id: 'tag_error',
                description: 'tag_error',
                defaultMessage: 'Please enter your tag'
            },
            providence_error: {
                id: 'providence_error',
                description: 'providence_error',
                defaultMessage: 'Please select a Providence'
            },
            client_error: {
                id: 'client_error',
                description: 'client_error',
                defaultMessage: 'Please input your client number'
            },
            vehicle_error: {
                id: 'vehicle_error',
                description: 'vehicle_error',
                defaultMessage: 'Please input your vehicle number'
            },
            service_error: {
                id: 'service_error',
                description: 'service_error',
                defaultMessage: "Please input your service card number"
            },

        });

        let createFilters = this.state.createFilters;
        let requestPOInfo;

        const {formatMessage} = this.props.intl;
        if (createFilters.createFor == constants.SEARCH_FILTER_TYPE.VIN8 && this.refs.vin8.value === '') {
            this.props.dispatch(actions.addRequestInfoPO(requestPOInfo = {
                value: formatMessage(messages.vin8_error)
            }));
        }
        else if (createFilters.createFor == constants.SEARCH_FILTER_TYPE.PLATE_STATE && this.refs.plate.value === '') {
            this.props.dispatch(actions.addRequestInfoPO(requestPOInfo = {
                value: formatMessage(messages.plate_error)
            }));
        }
        else if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.TAG_PROV && this.refs.tag.value === '') {
            this.props.dispatch(actions.addRequestInfoPO(requestPOInfo = {
                value: formatMessage(messages.tag_error)
            }));
        }
        else if (createFilters.createFor == constants.SEARCH_FILTER_TYPE.CLIENT_VEHICLE && this.refs.clientNumber.value === '') {
            this.props.dispatch(actions.addRequestInfoPO(requestPOInfo = {
                value: formatMessage(messages.client_error)
            }));
        }
        else if (createFilters.createFor == constants.SEARCH_FILTER_TYPE.CLIENT_VEHICLE && this.refs.searchARIUnitNumber.value === '') {
            this.props.dispatch(actions.addRequestInfoPO(requestPOInfo = {
                value: formatMessage(messages.vehicle_error)
            }));
        }
        else if (createFilters.createFor == constants.SEARCH_FILTER_TYPE.PLATE_STATE && this.refs.vendorState.value === '') {
            this.props.dispatch(actions.addRequestInfoPO(requestPOInfo = {
                value: formatMessage(messages.state_error)
            }));
        }
        else if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.TAG_PROV && this.refs.vendorProv.value === '') {
            this.props.dispatch(actions.addRequestInfoPO(requestPOInfo = {
                value: formatMessage(messages.providence_error)
            }));
        }
        else if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.SERVICE_CARD && this.refs.serviceNo.value === '') {
            this.props.dispatch(actions.addRequestInfoPO(requestPOInfo = {
                value: formatMessage(messages.service_error)
            }));
        }
        else {
            this.OnHide();
            this.onCreateClickHandler();
        }

    }

    renderExistingPOOverlay(fieldName) {

        const messages = defineMessages({
            vehicle_search_message: {
                id: 'vehicle_search_message',
                description: 'vehicle search error message',
                defaultMessage: '{vehicleSearchMessage}'
            },
            existing_po_link: {
                id: 'existing_po_link',
                description: 'existing po',
                defaultMessage: '{po} already exists. Are you sure you want to continue with a new PO {yes}|{no}?'

            },
            existing_po_error_selection_no: {
                id: 'existing_po_error_selection_no',
                description: 'existing_po_error_selection_no',
                defaultMessage: 'No'
            },
            existing_po_error_selection_yes: {
                id: 'existing_po_error_selection_yes',
                description: 'existing_po_error_selection_yes',
                defaultMessage: 'Yes'
            },
            existing_po_error_selection_po: {
                id: 'existing_po_error_selection_po',
                description: 'existing_po_error_selection_po',
                defaultMessage: 'Purchase Order'
            },
            existing_po_voided_link: {
                id: 'existing_po_voided_link',
                description: 'existing voided po',
                defaultMessage: 'User voided POs exist for this asset. As a result, this purchase order will be subjected to further review. {click}'

            },
            existing_po_voided_error_selection_continue: {
                id: 'existing_po_voided_error_selection_continue',
                description: 'existing_po_voided_error_selection_continue',
                defaultMessage: 'Click here to continue'
            },
            existing_po_voided_error_selection_po: {
                id: 'existing_po_voided_error_selection_po',
                description: 'existing_po_voided_error_selection_po',
                defaultMessage: 'PO'
            },
        });

        return <Overlay
            show={this.props.showErrorMessage}
            onHide={this.OnHide.bind(this)}
            placement="bottom"
            container={this}
            target={() => ReactDOM.findDOMNode(this.refs[fieldName])}
            rootClose={true}
        >
            <Popover id={"popOver" + fieldName} className="veh-search__errorMessage">
            <RawIntlProvider value={this.props.intl}>
                {this.props.existingOrderPreviouslyVoided != undefined && this.props.existingOrderPreviouslyVoided > 0 ?
                    
                    <FormattedMessage
                        values={{
                            click: <Link to={`/po/odometer/${this.props.vehicleDetailsId}`}>
                                <FormattedMessage {...messages.existing_po_voided_error_selection_continue}/></Link>
                        }}
                        {...messages.existing_po_voided_link}
                    />
                :
                this.props.existingOrderWaitingOrApprovedAuthorization != undefined && this.props.existingOrderWaitingOrApprovedAuthorization > 0 ?
                    <FormattedMessage
                        values={{
                            po: <Link
                                to={`/po/view/${this.props.existingOrderWaitingOrApprovedAuthorization}`}><FormattedMessage {...messages.existing_po_error_selection_po}/></Link>,
                            yes: <Link to={`/po/odometer/${this.props.vehicleDetailsId}`}>
                                <FormattedMessage {...messages.existing_po_error_selection_yes}/></Link>,
                            no: <Link to="/" onClick={this.OnHide.bind(this)}>
                                <FormattedMessage {...messages.existing_po_error_selection_no}/></Link>
                        }}
                        {...messages.existing_po_link}
                    />
                    : <FormattedMessage
                        values={{vehicleSearchMessage: this.props.vehicleSearchErrorMessage}}
                        {...messages.vehicle_search_message}
                    />
                }
                </RawIntlProvider>
            </Popover>
        </Overlay>
    }

    render() {
        const messages = defineMessages({
            skip_the_phone: {
                id: 'skip_the_phone',
                description: 'Skip the phone message',
                defaultMessage: 'Skip the phone'
            },
            vehicle_search_error: {
                id: 'vehicle_search_error',
                description: 'vehicle_search_error',
                defineMessages: '{vehicleSearchError}',
            },
        });
        let createFilters = this.state.createFilters;

        return <div className="veh-search">
            <form onSubmit={this.validationVehicleSearchCreate.bind(this)}>
            <div className="input-group">

                <div className="veh-search__caption" ref="divSearch">
                    <FormattedMessage {...messages.skip_the_phone}/>
                    <Help videoId="build_po" whiteSpaceAtBottomHeight="0.7em"/>
                </div>
                <div className="dropdown veh-search__input">
                    <button id="btnSearchOptions" name="btnSearchOptions" type="button"
                            className="btn btn-default dropdown-toggle" data-toggle="dropdown">
                                    <span id="search_for"
                                          className="basic_search__text">{this.getVehicleSearchCreateOptionText(createFilters.createFor)}</span>
                        <span className="caret"/>
                    </button>
                    <ul id="ulSearchOptions" name="ulSearchOptions" className="dropdown-menu"
                        role="menu">
                        <li><a
                            onClick={this.onSearchTypeChangedHandler.bind(this, constants.SEARCH_FILTER_TYPE.VIN8)}>
                            {this.getVehicleSearchCreateOptionText(constants.SEARCH_FILTER_TYPE.VIN8)}
                        </a></li>
                        <li><a
                                onClick={this.props.country === "CAN" ? this.onSearchTypeChangedHandler.bind(this, constants.SEARCH_FILTER_TYPE.TAG_PROV) : this.onSearchTypeChangedHandler.bind(this, constants.SEARCH_FILTER_TYPE.PLATE_STATE)}>
                                {this.props.country === "CAN" ? this.getVehicleSearchCreateOptionText(constants.SEARCH_FILTER_TYPE.TAG_PROV) : this.getVehicleSearchCreateOptionText(constants.SEARCH_FILTER_TYPE.PLATE_STATE)}
                         </a></li>
                        <li><a
                            onClick={this.onSearchTypeChangedHandler.bind(this, constants.SEARCH_FILTER_TYPE.CLIENT_VEHICLE)}>
                            {this.getVehicleSearchCreateOptionText(constants.SEARCH_FILTER_TYPE.CLIENT_VEHICLE)}
                        </a></li>

                        <li><a
                            onClick={this.onSearchTypeChangedHandler.bind(this, constants.SEARCH_FILTER_TYPE.SERVICE_CARD)}>
                            {this.getVehicleSearchCreateOptionText(constants.SEARCH_FILTER_TYPE.SERVICE_CARD)}
                        </a></li>
                    </ul>
                </div>
                {this.renderTextboxDisplay(createFilters.createFor)}
                <div className="veh-search__image-box">
                  <span className="input-group-btn">

                    {this.props.isfetchingData ?
                        <div className="form-group veh-search__spinner-container">
                            <Spinner/>
                        </div>
                        : <input type="image" className="basic_search__image" ref="search"
                                 src="/assets/VMS_33button_add_blue.png" role="button"
                                 onClick={this.validationVehicleSearchCreate.bind(this)}/>
                    }
                  </span>
                </div>
                {this.props.existingOrderWaitingOrApprovedAuthorization > 0 || this.props.existingOrderPreviouslyVoided > 0 ? this.renderExistingPOOverlay('search') :

                    this.renderValidationOverlay('search', messages.vehicle_search_error, this.props.newErrorMessage != null)

                }
            </div>
            </form>
        </div>

    }
}

function select(state) {
    return {
        isfetchingData: falseIfUndefined(state.appState.getIn(['uiData', 'shared', 'isFetchingVehicleDataForPOCreation'])),
        showErrorMessage: state.appState.getIn(['uiData', 'shared', 'showVehicleSearchErrorMessage']) || state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'existingOrderWaitingOrApprovedAuthorization']) > 0 || state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'existingOrderPreviouslyVoided']) > 0,
        vehicleSearchErrorMessage: state.appState.getIn(['uiData', 'shared', 'vehicleSearchErrorMessage']),
        existingOrderWaitingOrApprovedAuthorization: state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'existingOrderWaitingOrApprovedAuthorization']),
        genericErrorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage']),
        usStates: emptyStringIfUndefined(fromJS(state.appState.getIn(['uiData', 'shared', 'usStates']))),
        canadianProvinces: emptyStringIfUndefined(fromJS(state.appState.getIn(['uiData', 'shared', 'canadianProvinces']))),
        country: state.appState.getIn(['uiData', 'shared', 'selectedCountry']),
        clientNumbers: emptyMapIfUndefined(state.appState.getIn(['uiData', 'vehicleSearchCreatePO', 'vendorClientNumbers'])),
        vehicleSearchVin8Value: state.appState.getIn(['uiData', 'vehicleSearchCreatePO', 'vehicleCreateVin8Value']),
        serviceClassification: state.appState.getIn(['serverData', 'shared', 'serviceClassification']),
        newErrorMessage: state.appState.getIn(['uiData', 'vehicleSearchCreatePO', 'poCreationError']),
        poCreationErrorFlag: state.appState.getIn(['uiData', 'vehicleSearchCreatePO', 'poCreationErrorFlag']),
        isFetchingCarWashClients: falseIfUndefined(state.appState.getIn(['uiData', 'carWashAccount', 'isFetchingData'])),
        vehicleDetailsId: state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']),
        existingOrderPreviouslyVoided: state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'existingOrderPreviouslyVoided'])
    };
}

export default connect(select)(injectIntl((VehicleSearch)));