import React from 'react';
import classnames from 'classnames';
import {Modal} from 'react-bootstrap';
import VendorTermsAndConditions from '../shared/components/VendorTermsAndConditions';
import {defineMessages, FormattedMessage} from 'react-intl';
import FormWrapper from 'components/FormWrapper';
import './TermsAndConditionsModal.css';
import Spinner from 'components/Spinner';
import { connect } from 'react-redux';
import * as constants from 'constants/App';
import * as appActions from '../actions/App';
import queryString from 'query-string'

class TermsAndConditionsModal extends React.Component {

    constructor(props) {
        super(props);        
    }
  
    componentDidMount() {

        this.props.dispatch({type:constants.CLEAR_TERMS_AND_CONDITIONS});        
    }    

    onCloseWindowHandler(){

        this.props.dispatch(appActions.signOut());
        this.props.dispatch({type:constants.NAVIGATE_TO, path: '/login'});
    }

    onAcceptedTermsHandler(){

        this.props.dispatch(appActions.termsAndConditionsToggleAccepted());
    }

    onSubmitTermsHandler(){
        let params =  queryString.parse(this.props.location.search);        
        let returnUrl = params.returnUrl;
        if (!params.returnUrl) returnUrl = '/';

        this.props.dispatch(appActions.termsAndConditionsAccepted(true));
        this.props.dispatch({type: constants.ACCEPTED_TERMS_AND_CONDITIONS_SUBMISSION, returnUrl});
    }

    render() {
        const messages = defineMessages({
            close_dialog: {
                id: 'close_dialog',
                description: '  Close',
                defaultMessage: 'Close'
            },
            cancel_verify_caption: {
                id: 'cancel_verify_caption',
                description: 'Title for cancel button',
                defaultMessage: 'Cancel'
            },
            continue_verify_caption: {
                id: 'continue_verify_caption',
                description: 'Title for continue button',
                defaultMessage: 'Continue'
            },
            terms_and_conditions_subheader_login: {
                id: 'terms_and_conditions_subheader_login',
                description: 'terms_and_conditions_subheader_login',
                defaultMessage: "We're updating the terms for your account."
            },
            terms_and_conditions_header_login: {
                id: 'terms_and_conditions_header_login',
                description: 'terms_and_conditions_header_login',
                defaultMessage: 'Terms & Conditions'
            }
        });

        if (this.props.isFetchingTermsAndConditionsStatus ) {

            return <div className="terms_and_conditions_modal__save2"> <Spinner spinnerType="dark-blue"/> </div>;

        }

            return <div className="row container-fluid" >
                <div className="visible-lg links col-md-offset-1 col-md-1 col-lg-offset-1 col-lg-2">
                    <Modal show={this.props.acceptedTermsAndConditions === "N" || this.props.isFetchingTermsAndConditionsAccepted}
                           onHide={()=> this.props.acceptedTermsAndConditions === "Y" || this.props.termsAndConditions === null}
                           dialogClassName="terms_and_conditions_modal__modal"
                           backdrop="static"
                           keyboard={false}
                           onEntered={() => this.setState({modalLoaded: true})}                           
                    >                        
                        <Modal.Body>
                            <FormWrapper key='dealerDraftLineItemsErr'
                                         id="dealerDraftLineItemsErr" {...this.props}
                                         formErrors={this.props.errorMessage}/>
                            <div className="row terms_and_conditions_modal__close">
                                <div onClick={this.onCloseWindowHandler.bind(this)}>
                                    <img
                                        src="/assets/VMS_33button_kill_blue.png"/>
                                    {"  "} {<FormattedMessage {...messages.close_dialog} />}
                                </div>
                            </div>
                            <div className='row terms_and_conditions_modal__header'>
                                <FormattedMessage {...messages.terms_and_conditions_header_login} />
                            </div>
                             <div className="row terms_and_conditions_modal__subheader">
                                <FormattedMessage {...messages.terms_and_conditions_subheader_login}/>
                            </div>
                            <VendorTermsAndConditions termsAndConditions={this.props.termsAndConditions}
                                                      toggleAcceptedFlag={this.props.toggleAcceptedFlag}
                                                      onAcceptedTerms={this.onAcceptedTermsHandler.bind(this)}
                                                      isFetchingTermsAndConditions={this.props.isFetchingTermsAndConditions}
                            />
                            <div className="terms_and_conditions_modal__footer row">
                                <div className="col-sm-9 terms_and_conditions_modal__caption"
                                     onClick={this.onCloseWindowHandler.bind(this)}>

                                    <img src="/assets/VMS_28button_delete_blue.png"/>{' '}
                                    <a>
                                        <FormattedMessage {...messages.cancel_verify_caption}/>
                                    </a>


                                </div>
                                <div
                                    className={classnames(this.props.toggleAcceptedFlag ? "terms_and_conditions_modal__caption2 col-sm-3" : 'terms_and_conditions_modal__caption-disabled col-sm-3')}
                                    onClick={this.onSubmitTermsHandler.bind(this)}>
                                    <a>
                                        <FormattedMessage {...messages.continue_verify_caption}/>{' '}
                                        <img src="/assets/VMS_28button_edit_clear.png"/>                                        
                                    </a>

                                </div>
                                <div className="terms_and_conditions_modal__save inline-block">
                                    {this.props.isFetchingTermsAndConditionsAccepted ?
                                        <Spinner spinnerType="dark-blue"/>
                                        : false
                                    }
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>;
    }
};

function mapStateToProps(state, props) {
    return {
        termsAndConditions:state.appState.getIn(['serverData', 'shared', 'vendorTermsAndConditions']) || null,
        acceptedTermsAndConditions:state.appState.getIn(['serverData', 'shared', 'acceptedTermsAndConditions']),
        toggleAcceptedFlag:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','toggleAcceptedFlag']) || false,
        isFetchingTermsAndConditionsStatus:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','isFetchingTermsStatus']) || false,
        isFetchingTermsAndConditions:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','isFetchingTermsAndCondition']) || false,
        isFetchingTermsAndConditionsAccepted:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','isFetchingTermsAndConditionAccepted']) || false,
        paymentType:state.appState.getIn(['serverData', 'shared', 'paymentType']) || null,
        selectedCountry:state.appState.getIn(['uiData', 'shared', 'selectedCountry']) || null,
    };
}

export default connect(mapStateToProps) (TermsAndConditionsModal);