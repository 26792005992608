import React from 'react';
import ReactDOM from 'react-dom';
import Submit from 'components/Submit';
import * as constants from 'constants/App';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import { Table } from 'react-bootstrap';
import { Popover, Overlay } from 'react-bootstrap';
import * as helperFunctions from 'utils/HelperFunctions';
import classnames from 'classnames';
import './AuthEmailNotification.css';

class AuthEmailNotificationAddEdit extends React.Component {
     constructor(props) {
        super(props);
        this.state = {
            rows: [{ seq: 1, name: '', email: '' }],
            fieldsFocusedAfterValidation: [],   //array of fields that have recieved focus event, 
        };
    }
    toggleNotificationEmails(isSelected, event) {
        this.onFieldFocus( 'isRegistered');
        event.target.src = isSelected ? "/assets/VMS_checked.png" : "/assets/VMS_unchecked.png";
        event.target.value = isSelected;
        this.props.updateEmailConfigRegistration(isSelected);
    }
    onRemove(index) {
        this.props.removeContact(index);
    }
    //Adds the field to the list of focused fields
    onFieldFocus(fieldName) {
        let updatedFocusFields = this.state.fieldsFocusedAfterValidation.concat(fieldName);
        this.setState({ fieldsFocusedAfterValidation: updatedFocusFields });
        this.props.removeValidationErrForAuthEmailConfig(fieldName);
    }
    onSubmit() {
        this.setState({ fieldsFocusedAfterValidation: [] });
        this.props.onSubmitStep(5);
    }
  
    onChange(inputName, index, event) {
        this.props.updateContact(inputName, index, event);
    }


    renderValidationOverlay(fieldName, index, placement) {        
        let message;

        if(fieldName==='isRegistered')
            message = this.props.authorizationEmailConfigValidationErrs != undefined && this.props.authorizationEmailConfigValidationErrs.get(fieldName);
        else 
            message = this.props.authorizationEmailConfigValidationErrs != undefined && this.props.authorizationEmailConfigValidationErrs.get(index);    

        let showError = message != undefined && !this.state.fieldsFocusedAfterValidation.includes(fieldName);
        if (message != undefined)
            return <Overlay
                show={showError}
                target={() => ReactDOM.findDOMNode(this.refs[fieldName])}
                placement={placement != undefined ? placement : "right"}>
                <Popover id={"popOver" + fieldName} className="enrollment-details__error_message step1__popover">
                    <RawIntlProvider value={this.props.intl}>
                    <FormattedMessage {...message} />
                    </RawIntlProvider>
                </Popover>
            </Overlay>
        else
            return false;
    }

    render() {

        const messages = defineMessages({
            notification_email: {
                id: 'services',
                description: 'Step 5 of 5: Authorization Email Configuration',
                defaultMessage: 'Step 5 of 5: Authorization Email Configuration'
            },
            submit: {
                id: 'submit',
                description: 'Submit',
                defaultMessage: 'Submit'
            },
            note: {
                id: 'note',
                description: 'Checking Register indicates your acceptance of the authorization program.<br/>' +
                'Notification Emails will be delivered to the below address provided.<br/>' +
                'Additionally, you will also immediately be advised of the notification when logged into this website.<br/>',
                defaultMessage: 'Checking Register indicates your acceptance of the authorization program.<br/>' +
                'Notification Emails will be delivered to the below address provided.<br/>' +
                'Additionally, you will also immediately be advised of the notification when logged into this website.<br/>',
            },
            register: {
                id: 'register',
                description: 'Register',
                defaultMessage: 'Register'
            },
            edit_configuration_caption: {
                id: 'edit_configuration_caption',
                description: 'Edit Configurations',
                defaultMessage: 'Edit Configurations'
            },
            cancel: {
                id: 'cancel',
                description: 'Cancel',
                defaultMessage: 'Cancel'
            },
            save_contacts: {
                id: 'save_contacts',
                description: 'Save changes to contact information settings',
                defaultMessage: 'Save changes to contact information settings'
            },
            name_auth_email: {
                id: 'name_auth_email',
                description: 'name_auth_email',
                defaultMessage: 'Name'
            },
            optOut: {
                id: 'optOut',
                description: 'Opt out',
                defaultMessage: 'Opt out'
            },
            email_auth: {
                id: 'email_auth',
                description: 'email_auth',
                defaultMessage: 'Email Address'
            },
            add_new_contact: {
                id: 'add_new_contact',
                description: 'add_new_contact',
                defaultMessage: 'Add new contact'
            },
        });

        const isRegistered = this.props.isRegistered != undefined ? this.props.isRegistered : false;
        const optOut = this.props.isRegistered == undefined ? false : !this.props.isRegistered;
        const tabIndexGroup = this.props.tabIndexStartFrom;
        let tabIndexGroupForDynamicContacts = tabIndexGroup + 10;
        return (
            <div className={classnames(this.props.formMode == constants.EDIT ? 'auth-email-notification__edit' : 'auth-email-notification__add')}>
                <div className={classnames(this.props.formMode == constants.ADD ? 'auth-email-notification__header_add' : 'auth-email-notification__header_view')}>
                    {
                        this.props.title == undefined ?
                            <FormattedMessage {...messages.notification_email } />
                            : this.props.title
                    }
                </div>
                <div className={classnames('auth-email-notification__notification-emails', this.props.formMode == constants.ADD ? 'auth-email-notification__container_add' : false)}>
                    <div className=''>
                        {this.props.error}
                    </div>
                    <div className=''>
                        <div>
                            <FormattedMessage {...messages.note }  values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>
                        </div>
                        <div className='row auth-email-notification__notification-options'>
                            <div>
                                <div className='col-sm-3 auth-email-notification__notification-register'>
                                    <img className='auth-email-notification__notification-register-button'
                                        src={isRegistered ? "/assets/VMS_checked.png" : "/assets/VMS_unchecked.png"}
                                        onClick={this.toggleNotificationEmails.bind(this, true)}
                                        tabIndex = {tabIndexGroup+1} 
                                        />
                                    <label>{' '}<FormattedMessage {...messages.register } /></label>
                                </div>
                                <div className='col-sm-6 auth-email-notification__notification-opt-out'>
                                    <img className='auth-email-notification__notification-opt-out-button'
                                        src={optOut ? "/assets/VMS_checked.png" : "/assets/VMS_unchecked.png"}
                                        onClick={this.toggleNotificationEmails.bind(this, false)} 
                                        tabIndex = {tabIndexGroup+2} 
                                        />
                                    <label ref={'isRegistered'} >{' '}   <FormattedMessage {...messages.optOut} /></label>
                                </div>
                                {this.renderValidationOverlay('isRegistered', -1, 'right')}
                            </div>
                        </div>
                        <div>
                            <div className='auth-email-notification__contacts'>
                                <div className='row'>
                                    <div className='col-sm-1'></div>
                                    <div className={classnames('col-sm-4', 'auth-email-notification__contact-name-header',
                                        this.props.formMode == constants.ADD ? 'auth-email-notification__contact-name-header_add' : false)} > <FormattedMessage {...messages.name_auth_email} /></div>
                                    <div className={classnames('col-sm-5', 'auth-email-notification__contact-email-header',
                                        this.props.formMode == constants.ADD ? 'auth-email-notification__contact-email-header_add' : false)} ><FormattedMessage {...messages.email_auth} /></div>
                                </div>
                                <div>
                                    {
                                        
                                        this.props.authorizationEmailConfig.map((item, index) => {
                                            tabIndexGroupForDynamicContacts = tabIndexGroupForDynamicContacts + index + 4;
                                            return <div className={classnames('row', this.props.formMode == constants.EDIT ? 'auth-email-notification__contact-row_edit' : 'auth-email-notification__contact-row_add')}>

                                                <div className='col-sm-1 auth-email-notification__notification-contact-delete' >
                                                    <img className='auth-email-notification__delete-name'
                                                        src={"/assets/VMS_33button_kill_clear.png"}
                                                        onClick={this.onRemove.bind(this, index)}
                                                        onFocus={this.onFieldFocus.bind(this, index)}
                                                        tabIndex = {tabIndexGroupForDynamicContacts+1} 
                                                    />
                                                </div>
                                                <div className={classnames('col-sm-4', this.props.formMode == constants.ADD ? 'auth-email-notification__name_add' : false)} >
                                                    <input type='text'
                                                        className=' auth-email-notification__name-input'
                                                        key={'name' + index}
                                                        maxLength={'75'}
                                                        onChange={this.onChange.bind(this, 'name', index)}
                                                        onFocus={this.onFieldFocus.bind(this, index)}
                                                        value={item.name} 
                                                        tabIndex = {tabIndexGroupForDynamicContacts+2} />
                                                </div>
                                                <div className={classnames('col-sm-5', this.props.formMode == constants.ADD ? 'auth-email-notification__email_add' : false)} >
                                                    <input type='text'
                                                        className=' auth-email-notification__email-input'
                                                        maxLength={'75'}
                                                        key={'email' + index}
                                                        ref={'email' + index + this.props.formMode}
                                                        onChange={this.onChange.bind(this, 'email', index)}
                                                        value={item.email}
                                                        onFocus={this.onFieldFocus.bind(this, index)} 
                                                        tabIndex = {tabIndexGroupForDynamicContacts+3} />
                                                    {this.renderValidationOverlay('email' + index + this.props.formMode, index, 'right')}
                                                </div>

                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div>
                                <div className='auth-email-notification__add-contact'>
                                    <Submit caption={<FormattedMessage {...messages.add_new_contact } />}
                                        imagePath="/assets/VMS_28button_add_clear.png"
                                        onClickHandler={this.props.addContactRow} 
                                        tabIndex={tabIndexGroupForDynamicContacts+40}/>
                                </div>
                            </div>

                        </div>
                    </div>
                    {
                        this.props.formMode == constants.EDIT ?
                            <div className='row auth-email-notification__footer-buttons'>
                                <div className="col-sm-3 auth-email-notification__cancel">
                                    <a
                                        onClick={this.props.onCancel != undefined ? this.props.onCancel : () => { }}
                                        tabIndex={tabIndexGroupForDynamicContacts+42}>
                                        <FormattedMessage {...messages.cancel} />{' '}
                                        <img src="/assets/VMS_28button_delete_blue.png" />
                                    </a>
                                </div>
                                <div className='col-sm-9 auth-email-notification__save'>
                                    <Submit caption={<FormattedMessage {...messages.save_contacts } />}
                                        imagePath="/assets/VMS_28button_edit_clear.png"
                                        onClickHandler={this.onSubmit.bind(this)}
                                        showSpinner={this.props.isSubmittingAuthEmailConfigInfo} 
                                        spinnerType ={'blue'}
                                        tabIndex={tabIndexGroupForDynamicContacts+41}/>
                                </div>

                            </div>
                            : false
                    }

                </div>
                {
                    this.props.formMode == constants.ADD ?
                        <div className='auth-email-notification__submit'>
                            <Submit caption={<FormattedMessage {...messages.submit } />}
                                imagePath="/assets/VMS_33button_go_bblue.png"
                                onClickHandler={this.onSubmit.bind(this)}
                                showSpinner={this.props.isSubmittingAuthEmailConfigInfo} 
                                tabIndex={tabIndexGroupForDynamicContacts+43}/>
                        </div>
                        : false
                }
            </div>
        );
    }
};

export default injectIntl(AuthEmailNotificationAddEdit);

