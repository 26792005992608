import React from 'react';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage, FormattedNumber, injectIntl} from 'react-intl';
import moment from 'moment';
import './ClosePO.css';
import { LINE_ITEM_TYPE, INVOICE_FIELD_TYPE } from 'constants/App';
import InvoiceInformation from '../components/InvoiceInformationClosedPO';
import TaxInformation from '../components/TaxInformationClosedPO';
import BankingInformation from '../components/BankingInformationClosedPO';
import * as actions from '../actions/ClosePO';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as constants from 'constants/App';
import ReactDOM from 'react-dom';
import Spinner from 'components/Spinner';
import classnames from 'classnames';
import * as helperFunctions from 'utils/HelperFunctions';
import { IntlProvider } from 'react-intl';
import *  as fieldValidations from 'utils/FieldValidations';


class ClosePurchaseOrder extends React.Component {

    constructor(props) {
        super(props);
        moment.locale(this.props.userLocale);
        this.state = {
            showErrorMessage: false,
            showError: false
        }

    }

    onInputChangeHandler(key, value, e) {

        var applicationTaxType =this.props.applicableTaxTypes !== undefined ? this.props.applicableTaxTypes.map((tax)=> {return tax.toArray().toString().substring(0,3)}).toArray().toString() : "";


        if(applicationTaxType === "" && key === "IS_TAX_EXEMPTED" && value === false ){
            this.props.dispatch(actions.showPopOverTaxExe(true));
        }else {
            this.props.dispatch(actions.showPopOverTaxExe(false));


            let poInvoiceDetails = Map({
                key: key,
                value: e != undefined && e.target.value != undefined ? e.target.value : value,
                taxType: e != undefined && e.target.attributes['data-taxType'] != undefined ? e.target.attributes['data-taxType'].value : '',

            });

            this.props.onChange(poInvoiceDetails);
        }
    }

    onInputFocusHandler(key) {
        switch (key) {
            case key = "odometer": {
                this.props.dispatch(actions.showPopOverOdometer(false))
                break;
            }
             case key = "engineHours": {
                this.props.dispatch(actions.showPopOverEngineHours(false))
                break;
            }
            default:
                break;
        }
    }
    onInputBlurHandler(key) {
        const { formatMessage } = this.props.intl;
        const messages = defineMessages({
            error_invoice_tax: {
                id: 'error_invoice_tax',
                description: 'Please enter tax.',
                defaultMessage: 'Please enter tax.'
            },
            error_tax_amount: {
                id: 'error_tax_amount',
                description: 'tax amount exceeds 15% of subtotal',
                defaultMessage: 'PO exceeds the tax threshold, Please call the call center at 1-866-274-1025'

            }
        });


        var applicationTaxType =this.props.applicableTaxTypes !== undefined ? this.props.applicableTaxTypes.map((tax)=> {return tax.toArray().toString().substring(0,3)}).toArray().toString() : "";

        if (this.props.validationDetails.get('isFormValid') == undefined ||
            this.props.validationDetails.get('isFormValid') == false) {

            switch (key) {
                case key = "invoiceNo": {
                    this.props.validationDetails.get('invoiceNo') == false
                        ? this.props.dispatch(actions.showPopOverInvoiceNo(true))
                        : this.props.dispatch(actions.showPopOverInvoiceNo(false))
                    this.setState(()=>({
                        showErrorMessage: this.props.validationDetails.get('invoiceNo') == false
                    }))
                }
                    break;
                case key = "invoiceDate": {
                    this.props.validationDetails.get('invoiceDate') == false
                        ? this.props.dispatch(actions.showPopOverInvoiceDate(true))
                        : this.props.dispatch(actions.showPopOverInvoiceDate(false))
                }
                    break;
                case key = "submittedBy": {
                    this.props.validationDetails.get('submittedBy') == false
                        ? this.props.dispatch(actions.showPopOverInvoiceName(true))
                        : this.props.dispatch(actions.showPopOverInvoiceName(false))
                }
                    break;
                case key = "repairCompletionDate": {
                    this.props.validationDetails.get('repairCompletionDate') == false
                        ? this.props.dispatch(actions.showPopOverRepairCompleteDate(true))
                        : this.props.dispatch(actions.showPopOverRepairCompleteDate(false))
                }
                    break;
                case key = "repairStartDate": {
                    this.props.validationDetails.get('repairStartDate') == false
                        ? this.props.dispatch(actions.showPopOverRepairStartDate(true))
                        : this.props.dispatch(actions.showPopOverRepairStartDate(false))
                }
                    break;
                case key = "odometer": {
                    this.props.validationDetails.get('odometer') == false || this.props.showOdometerWarning
                        ? this.props.dispatch(actions.showPopOverOdometer(true))
                        : this.props.dispatch(actions.showPopOverOdometer(false))
                }
                case key = "engineHours": {
                    this.props.showEngineHoursWarning
                        ? this.props.dispatch(actions.showPopOverEngineHours(true))
                        : this.props.dispatch(actions.showPopOverEngineHours(false))
                }
                    break;
                case key = "tax": {
                    if (this.props.invoiceDetails.get('isTaxExempted') == false && this.props.invoiceDetails.get('canShowMultipleTaxTypes') == false && this.props.validationDetails.get('taxAmount') == false && this.props.lineItemsTax == 0 && this.props.taxTotal < this.props.subTotal * .15) {

                        this.props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_invoice_tax })));
                        this.props.dispatch(actions.showPopOverTax(true));
                    } else {
                        if (this.props.taxTotal > this.props.subTotal * .15) {
                            this.props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_tax_amount })));
                            this.props.dispatch(actions.showPopOverTax(true));

                        } else {
                            this.props.dispatch(actions.showPopOverTax(false))

                        }
                    }
                }
                    break;
                case key = "taxCanada": {

                    if (this.props.invoiceDetails.get('isTaxExempted') == false && this.props.invoiceDetails.get('canShowMultipleTaxTypes') == true) {
                        this.props.validationDetails.get('canadianTaxes') == false
                            ? this.props.dispatch(actions.showPopOverTaxCanada(true))
                            : this.props.dispatch(actions.showPopOverTaxCanada(false))
                    }
                }
                    break;

                case key = "taxExe":{
                    if(applicationTaxType === "" && this.props.invoiceDetails.get('isTaxExempted') == false ){
                        this.props.dispatch(actions.showPopOverTaxExe(true));
                    }else{
                        this.props.dispatch(actions.showPopOverTaxExe(false));
                    }

                }
                break;

                case key = "refNumber":{
                    this.props.validationDetails.get('refNumber') == false ?
                            this.props.dispatch(actions.showPopOverRefNumber(true))
                            :
                            this.props.dispatch(actions.showPopOverRefNumber(false))
                }
                    break;
            }
        }

    }

    onClosePOSaveHandler(e) {

        if (this.props.validationDetails.get('isFormValid') == undefined ||
            this.props.validationDetails.get('isFormValid') == false) {

            this.setState({
                showError: true
            });

            this.validateFormAndShowErrors();
            ReactDOM.findDOMNode(this).scrollIntoView();
            return false;
        }   
        else {
            this.setState({
                showError: false
            });
            this.props.onClosePO(this.props.invoiceDetails);
            return true;
        }
    }


    validateFormAndShowErrors() {
        const { formatMessage } = this.props.intl;
        const messages = defineMessages({
            error_invoice_tax: {
                id: 'error_invoice_tax',
                description: 'Please enter tax.',
                defaultMessage: 'Please enter tax.'
            },
            error_tax_amount: {
                id: 'error_tax_amount',
                description: 'tax amount exceeds 15% of subtotal',
                defaultMessage: 'PO exceeds the tax threshold, Please call the call center at 1-866-274-1025'

            },
        });
        var applicationTaxType =this.props.applicableTaxTypes !== undefined ? this.props.applicableTaxTypes.map((tax)=> {return tax.toArray().toString().substring(0,3)}).toArray().toString() : "";


        if (this.props.validationDetails.get('isFormValid') == undefined ||
            this.props.validationDetails.get('isFormValid') == false) {
            {
                this.props.validationDetails.get('invoiceNo') == false
                    ? this.props.dispatch(actions.showPopOverInvoiceNo(true))
                    : this.props.dispatch(actions.showPopOverInvoiceNo(false))

                this.setState(()=>({
                    showErrorMessage: this.props.validationDetails.get('invoiceNo') == false
                }))
            }

            this.props.validationDetails.get('invoiceDate') == false
                ? this.props.dispatch(actions.showPopOverInvoiceDate(true))
                : this.props.dispatch(actions.showPopOverInvoiceDate(false))
            this.props.validationDetails.get('submittedBy') == false
                ? this.props.dispatch(actions.showPopOverInvoiceName(true))
                : this.props.dispatch(actions.showPopOverInvoiceName(false))

            this.props.validationDetails.get('repairCompletionDate') == false
                ? this.props.dispatch(actions.showPopOverRepairCompleteDate(true))
                : this.props.dispatch(actions.showPopOverRepairCompleteDate(false))

            this.props.validationDetails.get('repairStartDate') == false
                ? this.props.dispatch(actions.showPopOverRepairStartDate(true))
                : this.props.dispatch(actions.showPopOverRepairStartDate(false))

            this.props.validationDetails.get('odometer') == false || this.props.showOdometerWarning
                ? this.props.dispatch(actions.showPopOverOdometer(true))
                : this.props.dispatch(actions.showPopOverOdometer(false))

            if (this.props.showBankAccountRadioButton) {
                this.props.validationDetails.get('isPaymentCreditToAccount') == false
                    ? this.props.dispatch(actions.showPopOverPaymentCredit(true))
                    : this.props.dispatch(actions.showPopOverPaymentCredit(false))
            }
            
            
            this.props.validationDetails.get('isWorkCompleted') == false
                ? this.props.dispatch(actions.showPopOverWorkComplete(true))
                : this.props.dispatch(actions.showPopOverWorkComplete(false))
            
            
            if(this.props.showInvoiceRadioButton){
                this.props.validationDetails.get('isInvoiceReproducible') == false
                ? this.props.dispatch(actions.showPopOverStandard(true))
                : this.props.dispatch(actions.showPopOverStandard(false))               
            
            }

            if(fieldValidations.isRequired(this.props.fieldValidationsData,'TAX'))
            {
            if (this.props.invoiceDetails.get('isTaxExempted') == false && this.props.invoiceDetails.get('canShowMultipleTaxTypes') == false && this.props.validationDetails.get('taxAmount') == false && Number(this.props.onTaxAmount) == 0) {

                this.props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_invoice_tax })));
                this.props.dispatch(actions.showPopOverTax(true));

            } else {
                if (this.props.taxTotal > this.props.subTotal * .15) {
                    this.props.dispatch(actions.setTaxErrorMessage(formatMessage({ ...messages.error_tax_amount })));
                    this.props.dispatch(actions.showPopOverTax(true));
                } else {
                    this.props.dispatch(actions.showPopOverTax(false))
                }
            }

            if (this.props.invoiceDetails.get('isTaxExempted') == false && this.props.invoiceDetails.get('canShowMultipleTaxTypes') == true) {
                this.props.validationDetails.get('canadianTaxes') == false
                    ? this.props.dispatch(actions.showPopOverTaxCanada(true))
                    : this.props.dispatch(actions.showPopOverTaxCanada(false))
            }

            if(applicationTaxType === "" && this.props.invoiceDetails.get('isTaxExempted') == false ){
                this.props.dispatch(actions.showPopOverTaxExe(true));
            }else{
                this.props.dispatch(actions.showPopOverTaxExe(false));
            }
        }
        

            this.props.validationDetails.get('refNumber') == false ?
                    this.props.dispatch(actions.showPopOverRefNumber(true))
                    :
                    this.props.dispatch(actions.showPopOverRefNumber(false));

        }

    }

    render() {

        const messages = defineMessages({
            problem_with_close_po: {
                id: 'problem_with_close_po',
                description: 'Found a problem? Edit PO before closing',
                defaultMessage: 'Found a problem? Edit PO before closing'
            },
            close_po_submit_1: {
                id: 'close_po_submit_1',
                description: 'Request payment of ',
                defaultMessage: 'Request payment of '
            },
            close_po_submit_2: {
                id: 'close_po_submit_2',
                description: ' and close PO',
                defaultMessage: ' and close PO'
            },
            work_completed_tax_deferred_header: {
                id: 'work_completed_tax_deferred_header',
                description: 'header for wc tax deferred',
                defaultMessage: 'Tax amount to be added later.'
            },
            work_completed_tax_deferred_subtext: {
                id: 'work_completed_tax_deferred_subtext',
                description: 'Sub text for wc tax deferred',
                defaultMessage: 'If you believe you are seeing this message in error, please call 1-866-274-1025 to discuss with a Holman technician.'
            },
            work_completed_tax_deferred_subtext_can: {
                id: 'work_completed_tax_deferred_subtext_can',
                description: 'Sub text for wc tax deferred canada number',
                defaultMessage: 'If you believe you are seeing this message in error, please call 1-800-363-7676 to discuss with a Holman technician.'
            }
        });

        const closePoTotal = helperFunctions.addFloats(this.props.subTotal, this.props.taxTotal);

        return (
            <div className="close-po">
                <div className="close-po__invoice-details">
                    <InvoiceInformation
                        invoiceDetails={this.props.invoiceDetails}
                        poCreatedDate={this.props.poCreatedDate}
                        onInputChangeHandler={this.onInputChangeHandler.bind(this)}
                        userLocale={this.props.userLocale}
                        popOverDisplayShowInvoiceNo={this.props.popOverDisplayShowInvoiceNo}
                        validationDetails={this.props.validationDetails}
                        onInputBlurHandler={this.onInputBlurHandler.bind(this)}
                        popOverDisplayShowInvoiceDate={this.props.popOverDisplayShowInvoiceDate}
                        popOverDisplayShowInvoiceName={this.props.popOverDisplayShowInvoiceName}
                        popOverDisplayShowRepairCompleteDate={this.props.popOverDisplayShowRepairCompleteDate}
                        popOverDisplayShowRepairStartDate={this.props.popOverDisplayShowRepairStartDate}
                        popOverDisplayShowOdometer={this.props.popOverDisplayShowOdometer}
                        popOverDisplayShowEngineHours = {this.props.popOverDisplayShowEngineHours}                        
                        vehicleDetails={this.props.vehicleDetails}
                        vehicleDetailsForOdometerWarnings={this.props.vehicleDetailsForOdometerWarnings}
                        updateOdometerWarnings={this.props.updateOdometerWarnings}
                        updateEngineHoursWarnings = {this.props.updateEngineHoursWarnings}
                        showOdometerWarning={this.props.showOdometerWarning}
                        showEngineHoursWarning = {this.props.showEngineHoursWarning}
                        onInputFocusHandler={this.onInputFocusHandler.bind(this)}
                        allowWorkCompletion = {this.props.allowWorkCompletion}
                        popOverDisplayShowRefNumber={this.props.popOverDisplayShowRefNumber}
                        vendor={this.props.vendor}
                        closePOVendorReferenceNo={this.props.closePOVendorReferenceNo}
                        fieldValidationsData={this.props.fieldValidationsData}

                    />
                    {fieldValidations.isVisible(this.props.fieldValidationsData,'TAX') ?
                    <div>
                    <hr className="close-po__hr-style" />
                    <TaxInformation
                        approvedAmountTotal={this.props.approvedAmountTotal}
                        pendingAmountTotal={this.props.pendingAmountTotal}
                        invoiceDetails={this.props.invoiceDetails}
                        onInputChangeHandler={this.onInputChangeHandler.bind(this)}
                        validationDetails={this.props.validationDetails}
                        taxAmount={this.props.taxAmount}
                        popOverDisplayShowTax={this.props.popOverDisplayShowTax}
                        popOverDisplayShowTaxCanada={this.props.popOverDisplayShowTaxCanada}
                        popOverDisplayShowTaxExe={this.props.popOverDisplayShowTaxExe}
                        onInputBlurHandler={this.onInputBlurHandler.bind(this)}
                        onTotalAmount={this.props.onTotalAmount}
                        onTaxAmount={this.props.onTaxAmount}
                        taxErrorMessage={this.props.taxErrorMessage}
                        applicableTaxTypes={this.props.applicableTaxTypes}
                        uiTaxes={this.props.uiTaxes}
                    />
                </div>
            : <div>
                <hr className="close-po__hr-style" />
                <div className="close-po__tax_deferred">
                    <FormattedMessage {...messages.work_completed_tax_deferred_header} />
                    <p>&nbsp;</p>
                        {this.props.selectedCountry == 'USA' ?
                            <FormattedMessage {...messages.work_completed_tax_deferred_subtext} /> :
                            <FormattedMessage {...messages.work_completed_tax_deferred_subtext_can} />
                    }
                            </div>
                        </div>
                            }
                    <hr className="close-po__hr-style" />
                    <BankingInformation
                        approvedAmountTotal={this.props.approvedAmountTotal}
                        pendingAmountTotal={this.props.pendingAmountTotal}
                        vendor={this.props.vendor}
                        invoiceDetails={this.props.invoiceDetails}
                        validationDetails={this.props.validationDetails}
                        onInputChangeHandler={this.onInputChangeHandler.bind(this)}
                        taxAmount={this.props.taxAmount}
                        lineItemsTax={this.props.lineItemsTax}
                        popOverDisplayShowPaymentCredit={this.props.popOverDisplayShowPaymentCredit}
                        popOverDisplayShowWorkComplete={this.props.popOverDisplayShowWorkComplete}
                        popOverDisplayShowStandard={this.props.popOverDisplayShowStandard}
                        subTotal={this.props.subTotal}
                        taxTotal={this.props.taxTotal}                        
                        showBankAccountRadioButton={this.props.showBankAccountRadioButton}
                        showInvoiceRadioButton={this.props.showInvoiceRadioButton}
                        selectedCurrency={this.props.selectedCurrency}
                    />
                </div>

                <div tabIndex="16" className={classnames(
                    {'close-po__request_payment submit': !this.props.isClosingPO},
                    {'close-po__request_payment-disable submit':this.props.isClosingPO})} role="button"
                    onClick={this.onClosePOSaveHandler.bind(this)}>
                    <FormattedMessage {...messages.close_po_submit_1} />
                    <IntlProvider locale="en">
                        <FormattedNumber value={closePoTotal} style="currency"
                                     currency={this.props.selectedCurrency} />
                    </IntlProvider>
                    <FormattedMessage {...messages.close_po_submit_2} />
                    <img src="/assets/VMS_33button_go_blue.png" />
                    <div className="close-po__request_payment inline-block">
                        {this.props.isClosingPO ?
                            <Spinner spinnerType='blue' />
                            : false
                        }
                    </div>
                </div>
                <p>&nbsp;</p>

                {
                    this.props.allowEditPO?
                    <div tabIndex="18" className="close-po__report-problem submit" role="button"
                        onClick={this.props.onEditPO}>
                        <FormattedMessage {...messages.problem_with_close_po} />
                        <img src="/assets/VMS_28button_go_clear.png" />
                    </div>
                    :false
                }
                
            </div>
        );
    }
}
;


export default connect(null)(injectIntl((ClosePurchaseOrder)));
