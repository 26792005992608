import React from 'react';
import ReactDoM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage, injectIntl, RawIntlProvider } from 'react-intl';
import './CorrectionDetails.css';
import * as helperFunctions from 'utils/HelperFunctions';
import DynamicNumber from 'react-dynamic-number';
import {  OverlayTrigger, Tooltip } from 'react-bootstrap';

class CorrectionDetailsReplace extends React.Component {
    renderInputBoxWithNALabel() {
        const messages = defineMessages({
            contract_pricing_cannot_be_edited_displayed_message: {
                id: 'contract_pricing_cannot_be_edited_displayed_message',
                description: 'This product code has contract pricing. Price cannot be edited or displayed.',
                defaultMessage: 'This product code has contract pricing. Price cannot be edited or displayed.'
            },
        });

        return <OverlayTrigger placement='right'
            overlay={<Tooltip><RawIntlProvider value={this.props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_displayed_message} /></RawIntlProvider></Tooltip>}
        >
            <input
                disabled={true}
                className="correction-type__input-readonly"
                value="N/A"
            />
        </OverlayTrigger>
    }

    renderLaborRateInputBoxWithConditionalOverlay(laborRate, showOverlay) {
        const messages = defineMessages({
            contract_pricing_cannot_be_edited_message: {
                id: 'contract_pricing_cannot_be_edited_message',
                description: 'This product code has contract pricing. Price cannot be edited.',
                defaultMessage: 'This product code has contract pricing. Price cannot be edited.'
            },
        });

        const laborRateInputBox = <DynamicNumber
            disabled={!this.props.laborCostEditable}
            className={this.props.laborCostEditable ? "correction-type__input-medium" : "correction-type__input-readonly"}
            onChange={this.props.handleChange.bind(this, "RATE")}
            separator={'.'}
            integer={6}
            fraction={2}
            positive={true}
            negative={false}
            thousand={false}
            value={laborRate}
        />;

        if (showOverlay)
            return <OverlayTrigger placement='right'
                overlay={<Tooltip><RawIntlProvider value={this.props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_message} /></RawIntlProvider></Tooltip>}
            >
                {laborRateInputBox}
            </OverlayTrigger>;
        else
            return laborRateInputBox
    }

    renderpartsCostInputBoxWithConditionalOverlay(partsCost, showOverlay) {
        const messages = defineMessages({
            contract_pricing_cannot_be_edited_message: {
                id: 'contract_pricing_cannot_be_edited_message',
                description: 'This product code has contract pricing. Price cannot be edited.',
                defaultMessage: 'This product code has contract pricing. Price cannot be edited.'
            },
        });

        const partsCostInputBox = <DynamicNumber
            disabled={!this.props.partsCostEditable}
            className={this.props.partsCostEditable ? "correction-type__input-medium" : "correction-type__input-readonly"}
            onChange={this.props.handleChange.bind(this, "COST")}
            separator={'.'}
            integer={6}
            fraction={2}
            positive={true}
            negative={false}
            thousand={false}
            value={partsCost}
        />;

        if (showOverlay)
            return <OverlayTrigger placement='right'
                overlay={<Tooltip><RawIntlProvider value={this.props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_message} /></RawIntlProvider></Tooltip>}
            >
                {partsCostInputBox}
            </OverlayTrigger>;
        else
            return partsCostInputBox
    }

    qtySync(evt, modelValue, viewValue)
    {
        this.props.handleChange("QTY",evt, modelValue, viewValue);
        this.props.handleChange("HOURS",evt, modelValue, viewValue);
    }

    render() {

        let laborRate = this.props.currentLaborRate === 0 ? "" : this.props.currentLaborRate.toString().match(/^-?\d+(?:\.\d{0,2})?/);
        let laborHours = this.props.currentLaborHours === 0 ? "" : this.props.currentLaborHours.toString().match(/^-?\d+(?:\.\d{0,2})?/);
        let laborTotal = this.props.correctionDetailsTotal.get('laborTotal').toFixed(2);

        let partsQty = this.props.currentPartsQty === 0 ? "" : this.props.currentPartsQty.toString().match(/^-?\d+(?:\.\d{0,2})?/);
        let partsCost = this.props.currentPartsCost === 0 ? "" : this.props.currentPartsCost.toString().match(/^-?\d+(?:\.\d{0,2})?/);
        let partsTotal = this.props.correctionDetailsTotal.get('partTotal').toFixed(2);

        let months = this.props.currentMonths === 0 ? "" : this.props.currentMonths;
        let miles = this.props.currentMiles === 0 ? "" : this.props.currentMiles;
        let hours = this.props.currentHours === 0 ? "" : this.props.currentHours;


        const messages = defineMessages({
            replace_hours: {
                id: 'replace_hours',
                description: 'Hours',
                defaultMessage: 'Hours'
            },
            replace_rate: {
                id: 'replace_rate',
                description: 'Rate',
                defaultMessage: 'Rate'
            },
            replace_qty: {
                id: 'replace_qty',
                description: 'Qty',
                defaultMessage: 'Qty'
            },
            replace_cost: {
                id: 'replace_cost',
                description: 'Cost',
                defaultMessage: 'Part Cost'
            },
            replace_amount: {
                id: 'replace_amount',
                description: 'Amount',
                defaultMessage: 'Amount'
            },
            replace_hide_labor: {
                id: 'replace_hide_labor',
                description: 'Labor for {newProductDescription} should be included in {existingProductDescription}',
                defaultMessage: 'Labor for {newProductDescription} should be included in {existingProductDescription}'
            },
            warranty: {
                id: 'warranty',
                description: 'Warranty',
                defaultMessage: 'Warranty:'
            },
            warranty_months: {
                id: 'warranty_months',
                description: 'Months',
                defaultMessage: 'Months'
            },
            warranty_miles: {
                id: 'warranty_miles',
                description: 'Miles',
                defaultMessage: 'Miles'
            },
            warranty_hours: {
                id: 'warranty_hours',
                description: 'Hours',
                defaultMessage: 'Hours'
            },
            replace_labor_cost: {
                id: 'replace_labor_cost',
                description: 'Lab Cost',
                defaultMessage: 'Lab Cost'
            },
            replace_labor_qty: {
                id: 'replace_labor_qty',
                description: 'Qty',
                defaultMessage: 'Qty'
            },
        });

        return (
            <div className="correction-type__replace">
                {this.props.restrictedRepairTypes == undefined || (!this.props.restrictedRepairTypes.includes("ALL") && !this.props.restrictedRepairTypes.includes("PARTS")) ?
                    <div className="correction-type__part-detail col-lg-14">
                        <div className="row">
                            <div className="form-group_small col-xs-3 ">
                                {this.props.intl.locale == 'es-MX' ? <div><br /></div> : ''}
                                <FormattedMessage {...messages.replace_qty} />
                                <DynamicNumber
                                    className="correction-type__input-small"
                                    onChange={this.props.isQtySyncFeatureEnabled ? this.qtySync.bind(this) : this.props.handleChange.bind(this, "QTY")}
                                    separator={'.'}
                                    integer={3}
                                    fraction={this.props.QtyShouldBeWholeNumber ? 0 : 2}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={partsQty}
                                />
                            </div>
                            <div className="form-group col-xs-4">
                                <FormattedMessage {...messages.replace_cost} />
                                {this.props.partsCostHidden ?
                                    this.renderInputBoxWithNALabel()
                                    :
                                    this.renderpartsCostInputBoxWithConditionalOverlay(partsCost,!this.props.partsCostEditable)
                                }
                            </div>
                            <div className="form-group col-xs-4 ">
                                {this.props.intl.locale == 'es-MX' ? <div><br /></div> : ''}
                                <FormattedMessage {...messages.replace_amount} />
                                {this.props.partsCostHidden ?
                                    this.renderInputBoxWithNALabel()
                                    :
                                    <DynamicNumber
                                        disabled
                                        className="correction-type__input-readonly"
                                        separator={'.'}
                                        integer={6}
                                        fraction={2}
                                        positive={true}
                                        negative={false}
                                        thousand={false}
                                        value={partsTotal}
                                    />
                                }
                            </div>
                        </div>
                    </div> : false}
                {!this.props.hideLaborInputFields.size > 0 && (this.props.restrictedRepairTypes == undefined || (!this.props.restrictedRepairTypes.includes("ALL") && !this.props.restrictedRepairTypes.includes("LABOR"))) ?
                    <div className="correction-type__labor-detail col-lg-14">
                        <div className="row">
                            <div className="form-group_small col-xs-3">
                                {this.props.isFlatRateFeatureEnabled ? <FormattedMessage {...messages.replace_labor_qty} /> : <FormattedMessage {...messages.replace_hours} />}
                                <DynamicNumber
                                    className="correction-type__input-small"
                                    onChange={this.props.isQtySyncFeatureEnabled ? this.qtySync.bind(this) : this.props.handleChange.bind(this, "HOURS")}
                                    separator={'.'}
                                    integer={3}
                                    fraction={this.props.QtyShouldBeWholeNumber ? 0 : 2}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={laborHours}
                                />
                            </div>
                            <div className="form-group col-xs-4">
                                {this.props.isFlatRateFeatureEnabled ? <FormattedMessage {...messages.replace_labor_cost} /> : <FormattedMessage {...messages.replace_rate} />}
                                {this.props.laborCostHidden ?
                                    this.renderInputBoxWithNALabel()
                                    :
                                    this.renderLaborRateInputBoxWithConditionalOverlay(laborRate, !this.props.laborCostEditable)
                                }
                            </div>
                            <div className="form-group col-xs-4 ">
                                <FormattedMessage {...messages.replace_amount} />
                                {this.props.laborCostHidden ?
                                    this.renderInputBoxWithNALabel()
                                    :
                                    <DynamicNumber
                                        disabled
                                        className="correction-type__input-readonly"
                                        separator={'.'}
                                        integer={6}
                                        fraction={2}
                                        positive={true}
                                        negative={false}
                                        thousand={false}
                                        value={laborTotal}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    :
                    this.props.hideLaborInputFields.size > 0 ? <div className="correction-type__labor-detail">
                        <FormattedMessage values={{
                            newProductDescription: this.props.hideLaborInputFields.get('newProductDescription'),
                            existingProductDescription: this.props.hideLaborInputFields.get('existingProductDescription')
                        }} {...messages.replace_hide_labor} />
                    </div> : false
                }

                {this.props.restrictedRepairTypes == undefined || (!this.props.restrictedRepairTypes.includes("ALL") && !this.props.restrictedRepairTypes.includes("PARTS")) ?

                    <div className="correction-type__warranty-detail col-lg-14">
                        <div className="row">
                            <div className="correction-type__warranty-title ">
                                <FormattedMessage {...messages.warranty} />
                            </div>
                            <div className="form-group_small col-xs-3 ">
                                <FormattedMessage {...messages.warranty_months} />
                                <DynamicNumber
                                    className="correction-type__input-small"
                                    onChange={this.props.handleChange.bind(this, "WARRANTY_MONTHS")}
                                    separator={'.'}
                                    integer={3}
                                    fraction={0}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={months}
                                />
                            </div>
                            <div className="form-group col-xs-4 ">
                                <FormattedMessage {...messages.warranty_miles} />
                                <DynamicNumber
                                    className="correction-type__input-medium"
                                    onChange={this.props.handleChange.bind(this, "WARRANTY_MILES")}
                                    separator={'.'}
                                    integer={6}
                                    fraction={0}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={miles}
                                />
                            </div>
                            <div className="form-group col-xs-4 ">
                                <FormattedMessage {...messages.warranty_hours} />
                                <DynamicNumber
                                    className="correction-type__input-large2"
                                    onChange={this.props.handleChange.bind(this, "WARRANTY_HOURS")}
                                    separator={'.'}
                                    integer={6}
                                    fraction={0}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={hours}
                                />
                            </div>
                        </div>
                    </div>
                    : false
                }

            </div>
        );
    }
}
;


export default injectIntl(CorrectionDetailsReplace);
