import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Button, Grid, Row, Col } from 'react-bootstrap';
import ReactJWPlayer from 'react-jw-player';
import videos from 'data/videos';
import './Help.css';
import {FormattedMessage,defineMessages} from 'react-intl';
import OldBrowserWarning from 'components/OldBrowserWarning';

class Help extends React.Component {
    constructor() {
        super();
        this.state = {
            showModal: false
        }
    }

    onCloseModal() {

        this.setState({
            showModal: false
        })
    }

    onClick(event) {
        event.preventDefault();
        this.setState({
            showModal: true
        })
    }

    render() {
        const messages = defineMessages({
            close: {
                id: 'close',
                description: 'close',
                defaultMessage: 'Close'
            },
            help_videos:{
                id: "help_videos",
                description: "Tutorial Videos",
                defaultMessage: "Tutorial Videos"

            }

        });
        const whiteSpaceStyling = {
            minHeight: this.props.whiteSpaceAtBottomHeight ? this.props.whiteSpaceAtBottomHeight : 0
        }

        if (!this.state.showModal) {
            return <div className="help-link-container" onClick={this.onClick.bind(this)}>
                <div style={whiteSpaceStyling}></div>
                <i className="help-link fa fa-question-circle" aria-hidden="true"></i>
            </div>
        }
        else
            return <div className="help-link-container">
                <div style={whiteSpaceStyling}></div>
                <i className=" help-link fa fa-question-circle" aria-hidden="true"></i>
                <Modal className="help-link-modal" show={true} bsSize="large" onHide={this.onCloseModal.bind(this)}>
                    <Modal.Header closeButton>
                        <ModalTitle>
                            <FormattedMessage {...messages.help_videos} />
                        </ModalTitle>
                    </Modal.Header>
                    <ModalBody>
                        <div className="old-browser-warning"><OldBrowserWarning /></div>
                        <ReactJWPlayer
                            playerId={`${this.props.videoId}_modal`}
                            file={videos[this.props.videoId].videoLocation}
                            playerScript={videos.playerLocation}
                            image={videos[this.props.videoId].imageLocation}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.onCloseModal.bind(this)}> <FormattedMessage {...messages.close} /></Button>
                    </ModalFooter>
                </Modal>
            </div>
    }
}


export default Help;