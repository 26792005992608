import React from 'react';
import ReactDOM from 'react-dom'
import { Map } from 'immutable';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider} from 'react-intl';
import classnames from 'classnames';
import Submit from 'components/Submit';
import { Popover, Overlay } from 'react-bootstrap';
import * as helperFunctions from 'utils/HelperFunctions';
import * as constants from 'constants/App';
import './BrakeAxle.css';

class BrakeAxle extends React.Component {
    renderValidationOverlay(fieldName, placement) {
        let message = this.props.validationErrors.get(fieldName);
        let showError = message != undefined && this.props.showValidationError;
        if (message != undefined)
            return <Overlay
                show={showError}
                target={() => ReactDOM.findDOMNode(this.refs[fieldName])}
                placement={placement != undefined ? placement : "right"}>
                <Popover id={"popOver" + fieldName} className="enrollment-details__error_message step1__popover">
                    <RawIntlProvider value={this.props.intl}>
                    <FormattedMessage {...message} />
                    </RawIntlProvider>
                </Popover>
            </Overlay>
        else
            return false;
    }
    render() {
         const axleId = 'axle' + this.props.axleNumber;
        return (
            <div className='brake-axle-flex'
            ref={axleId}>
                <div className='brake-axle-simple-left'>                   
                    <input className={'brakes-thickness'}
                                            maxLength = {'5'}

                        type='text'
                        onChange={(event) => this.props.onChangeBrakeThickness(this.props.axleNumber, constants.BRAKE_POSITIONS.LEFT, event.target.value)}
                        value={this.props.leftBrake.measurement}
                        placeholder={''}
                        onKeyPress={helperFunctions.isNumberKey.bind(this)}
                    />
                </div>
                <div className='brake-axle-simple-right'>                                  
                    <input className={'brakes-thickness'}
                                            maxLength = {'5'}

                        type='text'
                        onChange={(event) => this.props.onChangeBrakeThickness(this.props.axleNumber, constants.BRAKE_POSITIONS.RIGHT, event.target.value)}
                        value={this.props.rightBrake.measurement}
                        placeholder={''}
                        onKeyPress={helperFunctions.isNumberKey.bind(this)}
                    />
                </div>
                <div className='brake-axle-simple'>                                  
                    {this.props.defaultMeasurementUnit}
                </div>
                {this.renderValidationOverlay(axleId, 'left')}
            </div>
        )
    }
}

export default injectIntl(BrakeAxle);
