import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage,FormattedNumber,RawIntlProvider,injectIntl} from 'react-intl';
import {Map, fromJS} from 'immutable';
import './CreatePOForm.css';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import {emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError, zeroIfUndefined} from 'utils/HelperFunctions';
import {Overlay, Popover} from 'react-bootstrap';
import DynamicNumber from 'react-dynamic-number';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import classNames from 'classnames';
import { PO_FIELD_TYPE,LINE_ITEM_TYPE,PO_FIELD_ERROR_TYPE } from 'constants/App';
import ReactDOM from 'react-dom';
import FormWrapper from 'components/FormWrapper';
import { IntlProvider } from 'react-intl';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from '../../../../../shared/utils/MomentLocaleUtils';

class CreatePOForm extends React.Component {

    constructor(props) {

        super(props);
        moment.locale(this.props.userLocale);
        this.state = {
            date: moment(),
            format: "MM/DD/YY h:mm A",
            inputFormat: "MM/DD/YYYY",
            mode: "date",
        }
    }

    onCreatePOValidation() {

        this.props.onCreatePOClick(this.refs.dtPickerServiceDate.state.value,
            this.refs.txtInvoiceNo.value,
            this.refs.txtCarWashRepairCost.state.viewValue,
            this.refs.txtDetailCarWashRepairCost.state.viewValue,
            this.refs.txtCarWashTax !== undefined ?  this.refs.txtCarWashTax.state.viewValue : 0,
            this.refs.txtOdometer.value,
            this.refs.txtHST !== undefined ?  this.refs.txtHST.state.viewValue : 0,
            this.refs.txtGST !== undefined ?  this.refs.txtGST.state.viewValue : 0,
            this.refs.txtQST !== undefined ?  this.refs.txtQST.state.viewValue : 0,
            this.refs.txtPST !== undefined ?  this.refs.txtPST.state.viewValue : 0,

        );

    }

    resetMaxValue(maxLength, event) {
        event.preventDefault();

        if (event.target.value != undefined && event.target.value.length > maxLength) {
            event.target.value = event.target.value.slice(0, maxLength);
        }
    }


    isCarWashRepairChangeHandler(value, event) {
        event.preventDefault();

        this.props.carWashRepairChange(value);
    }

    onDateChangeHandler = (key, newDate) => {
        if (newDate !== undefined) {
            if (moment(newDate) >= moment().subtract(30, "days") && moment(newDate) <= moment().subtract(0, "days")) {
                this.props.onInputChangeHandler(key, newDate, undefined);
            } else {
                this.props.onInputChangeHandler(key, new Date(moment(this.props.poDetails.get("serviceDate"))), undefined);
            }
        }
    };


    renderValidationPOOverlay(fieldName, errorMessage, flag,errorMessageValue) {

        return <div className=""> <Overlay
            show={flag}
            target={() => ReactDOM.findDOMNode(this.refs[fieldName])}
            placement="right"

          >
            <Popover id={"popOver" + fieldName} className="create_po_form__error_message">
            <RawIntlProvider value={this.props.intl}>
                <FormattedMessage values={{poCreationError: errorMessageValue}} {...errorMessage} />
                </RawIntlProvider>
            </Popover>
        </Overlay></div>
    }

    odometerValidationCheck(){

        this.props.odometerCheck(this.refs.txtOdometer.value);
    }
    isOutsideRange = day => (
        focusedInput === END_DATE && (day.isBefore(moment().subtract(30, "days")) || day.isAfter(moment().subtract(0, "days")))
    );
    render() {

        const messages = defineMessages({
            odometer_details_caption: {
                id: 'odometer_details_caption',
                description: 'Required update',
                defaultMessage: 'Required update'
            },
            odometer_details_odometer: {
                id: 'odometer_details_odometer',
                description: 'Odometer',
                defaultMessage: 'Odometer'
            },
            odometer_details_last_reading: {
                id: 'odometer_details_last_reading',
                description: 'Last reading',
                defaultMessage: 'Last reading'
            },
            car_wash_service_date_label: {
                id: 'car_wash_service_date_label',
                description: 'service date',
                defaultMessage: 'Service date'
            },
            car_wash_invoice_no_label: {
                id: 'car_wash_invoice_no_label',
                description: 'invoice no.',
                defaultMessage: 'Invoice No.'
            },
            odometer_details_optional: {
                id: 'odometer_details_optional',
                description: 'optional display',
                defaultMessage: ' (optional)'
            },
            odometer_details_select_one: {
                id: 'odometer_details_select_one',
                description: 'Select One',
                defaultMessage: 'Select One'
            },
            car_wash_repair_selection: {
                id: 'car_wash_repair_selection',
                description: 'Car Wash',
                defaultMessage: 'Car Wash'
            },
            detail_car_wash_repair_selection: {
                id: 'detail_car_wash_repair_selection',
                description: 'detail car wash',
                defaultMessage: 'Detail Car Wash'
            },
            car_wash_create_po: {
                id: 'car_wash_create_po',
                description: 'request payment',
                defaultMessage: 'Request Payment of '
            },
            odometer_details_unavailable: {
                id: 'odometer_details_unavailable',
                description: 'Unavailable',
                defaultMessage: 'Unavailable'
            },
            ododmeter_Validation_Message_1: {
                id: 'ododmeter_Validation_Message_1',
                description: 'Warning: The meter entered {newOdometer} differs from the estimated meter by:{odometerDifference}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the estimated meter by:{odometerDifference}.'
            },
            ododmeter_Validation_Message_2: {
                id: 'ododmeter_Validation_Message_2',
                description: 'Warning: The meter entered exceeds the estimated meter by: {newOdometer}.',
                defaultMessage: 'Warning: The meter entered exceeds the estimated meter by: {newOdometer}.'
            },
            ododmeter_Validation_Message_3: {
                id: 'ododmeter_Validation_Message_3',
                description: 'Warning: The meter entered {newOdometer} differs from the expected meter {estimatedOdometer} for {currentDate} by:{estimatedOdometerDiff}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the expected meter {estimatedOdometer} for {currentDate} by:{estimatedOdometerDiff}.'
            },
            ododmeter_Validation_Message_4: {
                id: 'ododmeter_Validation_Message_4',
                description: 'Warning: The meter entered {newOdometer} differs from the estimated meter by: {odometerDifference}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the estimated meter by: {odometerDifference}.'
            },
            ododmeter_Validation_Message_5: {
                id: 'ododmeter_Validation_Message_5',
                description: 'Warning: This Unit is identified as EQUIPMENT. Odometer reading will be copied to Hour Meter reading based on client needs.',
                defaultMessage: 'Warning: This Unit is identified as EQUIPMENT. Odometer reading will be copied to Hour Meter reading based on client needs.'
            },
            hourmeter_Validation_Message: {
                id: 'hourmeter_Validation_Message',
                description: 'Warning: Hour meter cannot be less than last recorded value.',
                defaultMessage: 'Warning: Hour meter cannot be less than last recorded value.'
            },
            ododmeter_Validation_Message_6: {
                id: 'ododmeter_Validation_Message_6',
                description: 'Please enter Odometer value',
                defaultMessage: 'Please enter Odometer value'
            },
            shop_select: {
                id: 'shop_select',
                description: 'Odometer shop or not selection error message',
                defaultMessage: 'Please Select One!'
            },
            driver_validation_text: {
                id: 'driver_validation_text',
                description: 'validation warning for drivers who need to call Holman',
                defaultMessage: 'This purchase order requires driver validation. Advise driver to validate repairs with Holman prior to performing work.s'

            },
            total_info_label_car_wash: {
                id: 'total_info_label_car_wash',
                description: 'total',
                defaultMessage: 'Total'
            },
            tax_info_label_car_wash: {
                id: 'tax_info_label_car_wash',
                description: 'tax',
                defaultMessage: 'Tax'
            },tax_gst: {
                id: 'tax_gst',
                description: 'GST',
                defaultMessage: 'GST'
            },
            tax_pst: {
                id: 'tax_pst',
                description: 'PST',
                defaultMessage: 'PST'
            },
            tax_hst: {
                id: 'tax_hst',
                description: 'HST',
                defaultMessage: 'HST'
            },
            tax_qst: {
                id: 'tax_qst',
                description: 'QST',
                defaultMessage: 'QST'
            },
            po_creation_error: {
                id: 'po_creation_error',
                description: 'po_creation_error',
                defaultMessage: '{poCreationError}'
            },
            user_action_cancel_po: {
                id: 'user_action_cancel_po',
                description: 'Cancel PO',
                defaultMessage: 'Cancel PO'
            },

        });
        var vehicleDetails = this.props.vehicleDetails.toJSON();
        var applicationTaxType =this.props.applicableTaxTypes !== undefined ? this.props.applicableTaxTypes.map((tax)=> {return tax.toArray().toString().substring(0,3)}).toArray().toString() : "";

        return <div className="container-fluid create_po_form">
            <FormWrapper key='poCreationErrorMessage' id="poCreationErrorMessage" {...this.props} formErrors={this.props.errorMessage} />
            <div className="row create_po_form__caption">
                <div className="">
                    <FormattedMessage {...messages.odometer_details_caption} />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="create_po_form__label">
                                <FormattedMessage {...messages.car_wash_service_date_label} />
                            </div>

                        </div>
                        <div className="col-sm-3">
                            <DayPickerInput
                                        ref='dtPickerServiceDate'
                                        inputProps={{class:'create_po_day_picker', tabIndex:1 } }
                                        placeholder ={'MM/DD/YYYY'}
                                        onDayChange={this.onDateChangeHandler.bind(this, PO_FIELD_TYPE.SERVICE_DATE)}
                                        dayPickerProps={{
                                            disabledDays: {
                                                before: new Date(moment().subtract(29, "days")),
                                                after:new Date(moment().subtract(0, "days")),
                                            },
                                            selectedDays:new Date(moment(this.props.poDetails.get("serviceDate"))),
                                            locale: this.props.userLocale.substring(0,2),
                                            localeUtils: MomentLocaleUtils,
                                        }}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        format="MM/DD/YYYY"
                                        value={this.props.poDetails.get("serviceDate") !== undefined && moment(this.props.poDetails.get("serviceDate")).isValid()
                                            ? new Date(moment(this.props.poDetails.get("serviceDate")))
                                            : undefined}

                            />
                            {this.renderValidationPOOverlay('dtPickerServiceDate',messages.po_creation_error, this.props.serviceDateError !== undefined,this.props.serviceDateError)}

                        </div>
                      </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="create_po_form__label">
                                <FormattedMessage {...messages.car_wash_invoice_no_label} />
                            </div>

                        </div>
                        <div className="col-sm-3">

                            <input className="create_po_form__input-box" type="text"
                                   ref="txtInvoiceNo"
                                   onInput={this.resetMaxValue.bind(this, 25)}
                                   onChange={this.props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.INVOICE_NO,null)}
                                   cols="6"
                                   maxLength="25"
                                   size="6"
                                   tabIndex={2}
                            />
                            {this.renderValidationPOOverlay('txtInvoiceNo',messages.po_creation_error, this.props.invoiceNumberError !== undefined,this.props.invoiceNumberError)}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="create_po_form__label">
                                <FormattedMessage {...messages.odometer_details_odometer} />
                            </div>
                        </div>
                        <div className="col-sm-3">

                            <input className="create_po_form__input-box" type="number"
                                   ref="txtOdometer"
                                   cols="6"
                                   size="6"
                                   tabIndex={3}
                                   onBlur={this.props.poDetails.get("odometer") !== 0 ? this.odometerValidationCheck.bind(this) : false}
                                   onChange={this.props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.ODOMETER,null)}
                                   defaultValue={this.props.poDetails.get("odometer") !== 0 ? this.props.poDetails.get("odometer") : ""}
                            />
                            {this.renderValidationPOOverlay('txtOdometer',messages.po_creation_error, this.props.odometerError !== undefined,this.props.odometerError)}

                        </div>
                    </div>
                    <div className="row">
                    <div className=" col-sm-4 create_po_form__label_optional">
                        <FormattedMessage {...messages.odometer_details_optional} />
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 create_po_form_unedit__label">
                            <FormattedMessage {...messages.odometer_details_last_reading} />
                        </div>
                        <div className="col-sm-3 create_po_form_unedit__val">
                                {vehicleDetails.lastOdometerReading == undefined || vehicleDetails.lastOdometerReading == '' ?
                                    <FormattedMessage {...messages.odometer_details_unavailable} /> : vehicleDetails.lastOdometerReading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 create_po_form_select">
                            <FormattedMessage {...messages.odometer_details_select_one} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="create_po_form__label">
                                <div className="create_po_form__radio"
                                     onClick={this.isCarWashRepairChangeHandler.bind(this, true)}

                                >
                                    <img src={!this.props.carWashServiceData || this.props.carWashServiceData === undefined
                                        ? "/assets/VMS_unchecked.png"
                                        : "/assets/VMS_checked.png"}
                                    />
                                    {' '} <FormattedMessage {...messages.car_wash_repair_selection} />

                                </div>
                            </div>

                        </div>
                        <div className="col-sm-3">

                            <DynamicNumber
                                className={classNames('tax-control',"create_po_form__tax",{"create_po_form-input disabled" : !this.props.carWashServiceData  })}
                                ref="txtCarWashRepairCost"
                                tabIndex={!this.props.carWashServiceData ? -1 : 4}
                                onChange={this.props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.CAR_WASH_COST,null)}
                                separator={'.'}
                                integer={7}
                                fraction={2}
                                positive={true}
                                negative={false}
                                thousand={false}
                                value={this.props.poDetails.get("carWashCost") !== undefined  ? this.props.poDetails.get("carWashCost") : 0}
                            />
                            {this.renderValidationPOOverlay('txtCarWashRepairCost',messages.po_creation_error, this.props.carWashCostError !== undefined,this.props.carWashCostError)}

                        </div>
                    </div>


                    <div className="row">
                        <div className="col-sm-4">
                            <div className="create_po_form__label">
                                <div className="create_po_form__radio"
                                     onClick={this.isCarWashRepairChangeHandler.bind(this, false)}

                                >
                                    <img src={this.props.carWashServiceData || this.props.carWashServiceData === undefined
                                        ? "/assets/VMS_unchecked.png"
                                        : "/assets/VMS_checked.png"}
                                    />
                                    {' '} <FormattedMessage {...messages.detail_car_wash_repair_selection} />
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-3">

                            <DynamicNumber
                                className={classNames('tax-control',"create_po_form__tax",{"create_po_form-input disabled" : this.props.carWashServiceData })}
                                ref="txtDetailCarWashRepairCost"
                                tabIndex={this.props.carWashServiceData ? -1 : 5}
                                onChange={this.props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.DETAIL_CAR_WASH_COST,null)}
                                separator={'.'}
                                integer={7}
                                fraction={2}
                                positive={true}
                                negative={false}
                                thousand={false}
                                value={this.props.poDetails.get("detailCarWashCost") !== undefined ? this.props.poDetails.get("detailCarWashCost") : 0}
                            />
                            {this.renderValidationPOOverlay('txtDetailCarWashRepairCost',messages.po_creation_error, this.props.detailCarWashCostError !== undefined,this.props.detailCarWashCostError)}

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4 ">
                            <div className="create_po_form__label">
                                <FormattedMessage {...messages.tax_info_label_car_wash} />
                            </div>
                        </div>
                        {this.props.country === "USA" ?
                            <div className="col-sm-3">
                                <DynamicNumber
                                    className={classNames("create_po_form__tax", 'tax-control')}
                                    ref="txtCarWashTax"
                                    data-taxType={LINE_ITEM_TYPE.TAX}
                                    tabIndex={6}
                                    separator={'.'}
                                    integer={7}
                                    fraction={2}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={this.props.poDetails.get("taxAmount") !== undefined ? this.props.poDetails.get("taxAmount") : 0}
                                    onChange={this.props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.TAX_AMOUNT,null)}
                                />
                                {this.renderValidationPOOverlay('txtCarWashTax',messages.po_creation_error,this.props.taxAmountError !== undefined,this.props.taxAmountError)}


                            </div>

                            : <div>
                                <div className="create_po_form__canadian-control">
                                    <div
                                       >
                                        <div className="create_po_form__tax_field-header">
                                            <FormattedMessage {...messages.tax_hst}/>
                                            {' '}
                                        </div>


                                        <DynamicNumber
                                            className={classNames("create_po_form__tax_canada", 'tax-control')}
                                            data-taxType="HST" ref="txtHST"
                                            tabIndex={6}
                                             separator={'.'}
                                            integer={5}
                                            fraction={2}
                                            positive={true}
                                            negative={false}
                                            thousand={false}
                                            value={this.props.taxCanada.get("HST") !== undefined ? this.props.taxCanada.get("HST") : 0}
                                            onChange={this.props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.TAX_AMOUNT,null)}
                                            disabled={!applicationTaxType.includes("HST")}
                                        />

                                    </div>

                                    <div
                                       >
                                        <div className="create_po_form__tax_field-header">
                                            <FormattedMessage {...messages.tax_gst} />
                                            {' '}
                                        </div>


                                        <DynamicNumber
                                            className={classNames("create_po_form__tax_canada", 'tax-control')}
                                            data-taxType="GST" ref="txtGST"
                                            tabIndex={7}
                                            onChange={this.props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.TAX_AMOUNT,null)}
                                            separator={'.'}
                                            integer={5}
                                            fraction={2}
                                            positive={true}
                                            negative={false}
                                            thousand={false}
                                            value={this.props.taxCanada.get("GST") !== undefined ? this.props.taxCanada.get("GST") : 0 }
                                            disabled={!applicationTaxType.includes("GST")}
                                        />
                                    </div>
                                </div>

                                <div className="create_po_form__canadian-control">
                                    <div
                                       >
                                        <div className="create_po_form__tax_field-header">
                                            <FormattedMessage {...messages.tax_pst}/>
                                            {' '}
                                        </div>


                                        <DynamicNumber
                                            className={classNames("create_po_form__tax_canada", 'tax-control')}
                                            data-taxType="PST" ref="txtPST"
                                            tabIndex={8}
                                            onChange={this.props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.TAX_AMOUNT,null)}
                                            separator={'.'}
                                            integer={5}
                                            fraction={2}
                                            positive={true}
                                            negative={false}
                                            thousand={false}
                                            value={this.props.taxCanada.get("PST") !== undefined ? this.props.taxCanada.get("PST"): 0 }
                                            disabled={!applicationTaxType.includes("PST")}
                                        />

                                    </div>

                                    <div>
                                        <div className="create_po_form__tax_field-header">
                                            <FormattedMessage {...messages.tax_qst}/>
                                            {' '}
                                        </div>



                                        <DynamicNumber
                                            className={classNames("create_po_form__tax_canada", 'tax-control')}
                                            data-taxType="QST" ref="txtQST"
                                            tabIndex={9}
                                            onChange={this.props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.TAX_AMOUNT,null)}
                                            separator={'.'}
                                            integer={5}
                                            fraction={2}
                                            positive={true}
                                            negative={false}
                                            thousand={false}
                                            value={this.props.taxCanada.get("QST") !== undefined ? this.props.taxCanada.get("QST") : 0 }
                                            disabled={!applicationTaxType.includes("QST")}
                                        />

                                    </div>
                                    {this.renderValidationPOOverlay('txtQST',messages.po_creation_error, this.props.canadianTaxError !== undefined,this.props.canadianTaxError)}

                                </div>
                            </div>


                        }
                    </div>
                    <div className="row">
                        <div className="col-sm-4 ">
                            <div className="create_po_form__label">
                                <FormattedMessage {...messages.total_info_label_car_wash} />
                            </div>
                        </div>
                        <div className="col-sm-3">

                            <DynamicNumber
                                className={classNames("create_po_form__tax", 'tax-control')}
                                ref="txtCarWashRepairTotal"
                                disabled
                                separator={'.'}
                                integer={7}
                                fraction={2}
                                positive={true}
                                negative={false}
                                thousand={false}
                                value={this.props.carWashTotal !== undefined ? Number(this.props.carWashTotal).toFixed(2) : 0}
                            />
                        </div>
                    </div>
                    <div className="row create_po_form__save">
                        <div className='create_po_form__cancel col-sm-6'>
                            <a onClick={this.props.onCancelClick}>
                                <img src="/assets/VMS_28button_delete_blue.png"/>
                                {' '}{<FormattedMessage {...messages.user_action_cancel_po}/>}
                            </a>
                        </div>
                        <a onClick={this.onCreatePOValidation.bind(this)}>
                            <FormattedMessage {...messages.car_wash_create_po} />
                            <IntlProvider locale="en">
                                <FormattedNumber value={Number(this.props.carWashTotal).toFixed(2)} style="currency"
                                             currency={this.props.selectedCurrency} />
                            </IntlProvider>
                            {' '}<img src="/assets/VMS_33button_go_bblue.png"/>

                        </a>
                        <div className="inline-block">
                            {this.props.isCreatingPO ?
                                <Spinner spinnerType="dark-blue"/>
                                : false
                            }
                        </div>
                    </div>
                </div>
            </div>


        </div>;
    }
}


export default injectIntl(CreatePOForm);

