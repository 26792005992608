import React from 'react';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import Spinner from 'components/Spinner';
import './Complaint.css';
import ComplaintNotes from './ComplaintNotes.js';
import ComplaintNotesReadOnly from './ComplaintNotesReadOnly.js';


class Complaint extends React.Component {

    getHeader() {
        const messages = defineMessages({
            add_edit_view_complaint_view_complaint_caption: {
                id: 'add_edit_view_complaint_view_complaint_caption',
                description: 'Complaint',
                defaultMessage: 'Complaint'
            }
        });

        return <div className="add-edit-view-complaint__caption">
            { this.props.description == undefined ?
                <FormattedMessage {...messages.add_edit_view_complaint_view_complaint_caption}/>
                : <FormattedMessage
                        id={'complaint_code_' + this.props.code}
                        description={this.props.description}
                        defaultMessage={this.props.description}/>

            }
        </div>
    }

    getErrorMessage() {
        const messages = defineMessages({
            complaint_error: {
                id: 'complaint_error',
                description: 'Please add complaint type in order to continue.',
                defaultMessage: 'Please add complaint type in order to continue.'
            }
        });

        return <div className="add-edit-view-complaint__error">
            {this.props.isComplaintOpen ?
                <FormattedMessage {...messages.complaint_error}/>
                : false}
        </div>
    }

    getComplaintTypeHeader(isChangingComplaintType, actionType) {
        const messages = defineMessages({
            complaint_type_header: {
                id: 'complaint_type_header',
                description: 'SELECT A COMPLAINT TYPE',
                defaultMessage: 'SELECT A COMPLAINT TYPE'
            }
        });

        return <div className="add-edit-view-complaint__type-header">
            <FormattedMessage {...messages.complaint_type_header}/>
            <div className="inline-block">
                {isChangingComplaintType ?
                    <Spinner spinnerType={ actionType === 'ADD' || actionType === 'EDIT' ? '' : 'blue' }/>
                    : false
                }
            </div>
        </div>
    }


    getComplaintTypeColumn(complaintTypeColumn, onComplaintTypeClick, intl) {

        return complaintTypeColumn.valueSeq().map(function (complaintType, index) {
            return <div key={index}
                        className={classNames('add-edit-view-complaint__type',
                            {'add-edit-view-complaint__type__disabled': complaintType.get('selected')})}
                        onMouseDown={!complaintType.get('selected') ?
                            () => onComplaintTypeClick(complaintType.get('code'), complaintType.get('description'), this.props.complaintKey, this.props.code)
                            : false}>
                {
                    /* get translated complaint type*/
                    intl.formatMessage({
                        id: complaintType.get('id'),
                        defaultMessage: complaintType.get('description'),
                        description: complaintType.get('defaultMessage')
                    })
                }
            </div>
        }, this)
    }

    getSelectedComplaint() {
        const messages = defineMessages({
            complaint_type: {
                id: 'complaint_type',
                description: 'Complaint type:',
                defaultMessage: 'Complaint type:'
            },
            change_complaint_type: {
                id: 'change_complaint_type',
                description: 'Change complaint type',
                defaultMessage: 'Change complaint type'
            },
            add_complaint_type: {
                id: 'add_complaint_type',
                description: 'Add complaint type',
                defaultMessage: 'Add complaint type'
            }
        });

        return <div className="add-edit-view-complaint__selected-complaint">
            <FormattedMessage {...messages.complaint_type}/>
            {' '}

            <a href="#" onClick={this.props.onChangeComplaintClick.bind(this, this.props.complaintKey)}>
                {this.props.code != undefined ?
                    <FormattedMessage {...messages.change_complaint_type}/>
                    : <FormattedMessage {...messages.add_complaint_type}/>
                }
            </a>
        </div>
    }

    render() {
        const {isSelectMode, complaintTypes, onComplaintTypeClick, intl} = this.props;
        const total = complaintTypes.count(o => o.get('visible'));
        const col1 = complaintTypes.filter(o => o.get('index') < total / 3 && o.get('visible')).sortBy(o => o.get('index'));
        const col2 = complaintTypes.filter(o => (o.get('index') >= total / 3) && (o.get('index') < (total / 3) * 2) && o.get('visible')).sortBy(o => o.get('index'));
        const col3 = complaintTypes.filter(o => (o.get('index') >= (total / 3) * 2) && o.get('visible')).sortBy(o => o.get('index'));

        return <div className="add-edit-view-complaint">
            <div className="add-edit-view-complaint__caption">
                {this.getHeader()}
            </div>

            {
                this.props.actionType == 'EDIT' ||
                this.props.actionType == 'VIEW' ||
                this.props.actionType == 'ACTIONREQUIRED' ||
                this.props.actionType == 'ADJUSTMENT' ?
                    <ComplaintNotesReadOnly notes={this.props.notesHistory}/>
                : false
            }

            {
                this.props.actionType == 'COMPLAINT' ||
                this.props.actionType == 'ADD' ||
                this.props.actionType == 'EDIT' ||
                this.props.actionType == 'ADJUSTMENT' ?
                    <ComplaintNotes
                        notes={this.props.notesText}
                        complaintKey={this.props.complaintKey}
                        onComplaintNotesSave={this.props.onComplaintNotesSave}
                        onComplaintNotesSaveState={this.props.onComplaintNotesSaveState}
                        savingNote={this.props.savingNote}
                        actionType={this.props.actionType}
                        listOfComplaintsForWhichNoteIsRequired={this.props.listOfComplaintsForWhichNoteIsRequired}
                    />
                : false
            }

            {this.props.showErrorMessages ? this.getErrorMessage() : false}

            {isSelectMode == true ?
                <div className="">
                    {this.getComplaintTypeHeader(this.props.isChangingComplaintType, this.props.actionType)}
                    <div className="row">
                        <div className="col-md-4">
                            {this.getComplaintTypeColumn(col1, onComplaintTypeClick, intl)}
                        </div>
                        <div className="col-md-4">
                            {this.getComplaintTypeColumn(col2, onComplaintTypeClick, intl)}
                        </div>
                        <div className="col-md-4">
                            {this.getComplaintTypeColumn(col3, onComplaintTypeClick, intl)}
                        </div>
                    </div>
                </div>
                : this.props.isInitialAddMode || this.props.wasComplaintSkipped ?
                this.getSelectedComplaint()
                : false
            }
        </div>
    }
}


export default injectIntl(Complaint);