import React from 'react';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage,RawIntlProvider,injectIntl} from 'react-intl';
import classNames from 'classnames';
import { Popover, Overlay, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import './InvoiceInformationClosedPO.css';
import { INVOICE_FIELD_TYPE } from 'constants/App';
import ReactDOM from 'react-dom';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import * as helperFunctions from 'utils/HelperFunctions';
import DynamicNumber from 'react-dynamic-number';
import *  as fieldValidations from 'utils/FieldValidations';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from '../../../../shared/utils/MomentLocaleUtils';

class InvoiceInformationClosedPO extends React.Component {

    constructor(props) {
        super(props);
        moment.locale(this.props.userLocale);
        this.state = {
            format: "MM/DD/YY h:mm A",
            inputFormat: "MM/DD/YYYY",
            mode: "date",
            showError: false,
            HourMeterUpdated: false,
            OdometerErrorMessage: undefined,
            EngineHoursErrorMessage: undefined,
            NonRoadVehicleErrorMessage: undefined
        }

    }

    onDateChangeHandler (key, newDate) {

        if(newDate !== undefined) {

            if (key === "INVOICE_DATE" && moment(newDate) >= moment().subtract(20, "years") && moment(newDate) <= moment().subtract(0, "days")) {
                this.props.onInputChangeHandler(key, newDate, undefined);

            } else if (key === "REPAIR_START_DATE" && moment(newDate) >= moment().subtract(30, "days") && moment(newDate) <= moment().subtract(0, "days")) {
                this.props.onInputChangeHandler(key, newDate, undefined);

            } else if (key === "REPAIR_COMPLETION_DATE" && moment(newDate) >= moment(this.props.invoiceDetails.get('repairStartDate')) && moment(newDate) <= moment().local()) {

                this.props.onInputChangeHandler(key, newDate, undefined);

            }

        }

    }

    removeOverlay(overlayName) {
        this.refs[overlayName].hide();
    }

    validateOdometerDetails() {
            var result = helperFunctions.validateOdometerDetails(this.refs.txtOdometer,
            this.refs.txtEngineHours,
            this.state.HourMeterUpdated,
            this.props.vehicleDetailsForOdometerWarnings);

        //udpate 
        if (result.updateEngineHoursWithOdometer)
            this.refs.txtEngineHours.value = this.refs.txtOdometer.value;

        if (result.HourMeterUpdated)
            this.setState({ HourMeterUpdated: true })

        if (result.NonRoadVehicleErrorMessageUpdated)
            this.setState({ NonRoadVehicleErrorMessage: result.NonRoadVehicleErrorMessage })

        if (result.OdometerErrorMessageUpdated)
            this.setState({ OdometerErrorMessage: result.OdometerErrorMessage });

        const isErrorMessageSet = result.isErrorMessageSet;

        this.props.updateOdometerWarnings(result.isErrorMessageSet);

    }

    validateEngineHours() {
        const result = helperFunctions.validateEngineHours(this.refs.txtEngineHours, this.props.vehicleDetails);
        this.setState({ EngineHoursErrorMessage: result.EngineHoursErrorMessage });
        if (result.showOverlayEngineHours)
            this.props.updateEngineHoursWarnings(true)
    }

    onOdometerChangeHandler(key) {
        this.validateOdometerDetails();
        this.props.onInputBlurHandler(key);
    }
    onEngineHoursChangeHandler(key) {
        this.validateEngineHours()
        this.props.onInputBlurHandler(key);
    }
    onInputFocusHandler(key) {
        this.props.onInputFocusHandler(key);
    }
    render() {
       const messages = defineMessages({
            invoice_number: {
                id: 'invoice_number',
                description: 'Invoice no.',
                defaultMessage: 'Invoice no.'
            },
            invoice_date: {
                id: 'invoice_date',
                description: 'Invoice date',
                defaultMessage: 'Invoice date'
            },
            invoice_name: {
                id: 'invoice_name',
                description: 'First and last name',
                defaultMessage: 'Your Name'
            },
            repair_completion_date: {
                id: 'repair_completion_date',
                description: 'Repair completion date',
                defaultMessage: 'Repair completion date'
            },
            repair_start_date: {
                id: 'repair_start_date',
                description: 'Repair repair_start_date date',
                defaultMessage: 'Repair start date'
            },
            error_invoice_no: {
                id: 'error_invoice_no',
                description: 'Please enter Invoice No.',
                defaultMessage: 'Please enter an Invoice Number longer than 3 characters and remove any empty spaces'
            },
            error_invoice_date: {
                id: 'error_invoice_date',
                description: 'Please enter Invoice Date.',
                defaultMessage: 'Please enter Invoice Date.'
            },
            error_invoice_name: {
                id: 'error_invoice_name',
                description: 'Please enter First and last name.',
                defaultMessage: 'Please enter First and last name.'
            },
            error_invoice_completion_date: {
                id: 'error_invoice_completion_date',
                description: 'Please enter Repair Completion Date.',
                defaultMessage: 'Please enter Repair Completion Date.'
            },
            error_invoice_start_date: {
                id: 'error_invoice_start_date',
                description: 'Please enter Repair Start Date.',
                defaultMessage: 'Please enter Repair Start Date.'
            },
            error_odometer: {
                id: 'error_odometer',
                description: 'Please enter Odometer.',
                defaultMessage: 'Please enter Odometer.'
            }
            ,
            odometer_caption: {
                id: 'odometer_caption',
                description: 'Odometer',
                defaultMessage: 'Odometer'
            },
            engine_hours: {
                id: 'engine_hours',
                description: 'Hour meter (if equipped)',
                defaultMessage: 'Hour meter <br />(if equipped)'
            },
            ododmeter_Validation_Message_1: {
                id: 'ododmeter_Validation_Message_1',
                description: 'Warning: The meter entered {newOdometer} differs from the estimated meter by:{odometerDifference}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the estimated meter by:{odometerDifference}.'
            },
            ododmeter_Validation_Message_2: {
                id: 'ododmeter_Validation_Message_2',
                description: 'Warning: The meter entered exceeds the estimated meter by: {newOdometer}.',
                defaultMessage: 'Warning: The meter entered exceeds the estimated meter by: {newOdometer}.'
            },
            ododmeter_Validation_Message_3: {
                id: 'ododmeter_Validation_Message_3',
                description: 'Warning: The meter entered {newOdometer} differs from the expected meter {estimatedOdometer} for {currentDate} by:{estimatedOdometerDiff}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the expected meter {estimatedOdometer} for {currentDate} by:{estimatedOdometerDiff}.'
            },
            ododmeter_Validation_Message_4: {
                id: 'ododmeter_Validation_Message_4',
                description: 'Warning: The meter entered {newOdometer} differs from the estimated meter by: {odometerDifference}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the estimated meter by: {odometerDifference}.'
            },
            ododmeter_Validation_Message_5: {
                id: 'ododmeter_Validation_Message_5',
                description: 'Warning: This Unit is identified as EQUIPMENT. Odometer reading will be copied to Hour Meter reading based on client needs.',
                defaultMessage: 'Warning: This Unit is identified as EQUIPMENT. Odometer reading will be copied to Hour Meter reading based on client needs.'
            },
            hourmeter_Validation_Message: {
                id: 'hourmeter_Validation_Message',
                description: 'Warning: Hour meter cannot be less than last recorded value.',
                defaultMessage: 'Warning: Hour meter cannot be less than last recorded value.'
            },
            closed_po_driver_reference_number_title: {
                id: 'closed_po_driver_reference_number_title',
                description: 'title for reference number section',
                defaultMessage: '<br />Your reference#'
            },
            reference_number_validation_message: {
                id: 'reference_number_validation_message',
                description: 'reference_number_validation_message',
                defaultMessage: 'Please input your reference number'
            },
            client_po_number_caption: {
                id: 'client_po_number_caption',
                description: 'client_po_number_caption',
                defaultMessage: 'Client-provided PO# <br /> (if available)'
            },
        });

        const engineHoursErrMessagePopOver = <Popover id="popOverEngineHours" className="ErrorMessage">
              <RawIntlProvider value={this.props.intl}>
            {
                this.state.EngineHoursErrorMessage == undefined ? '' :
                    <FormattedMessage {...messages[this.state.EngineHoursErrorMessage]} />
            }
           </RawIntlProvider>
        </Popover>

        const odometerValidationErrorMessage = fieldValidations.getValidationErrorMessage(this.props.fieldValidationsData, 'ODOMETER', this.refs.txtOdometer === undefined || this.refs.txtOdometer === null || this.refs.txtOdometer.value === undefined || this.refs.txtOdometer.value === null ? '' :this.refs.txtOdometer.value );            

        const odometerFormattedMessage = () => {
            if (this.props.validationDetails.get('odometer') == false)
                return odometerValidationErrorMessage
            else if (this.props.showOdometerWarning && (this.refs.txtOdometer !== undefined && this.refs.txtOdometer.value !== undefined && this.refs.txtOdometer.value.trim().length > 0))
                return <FormattedMessage
                    values={{
                        newOdometer: this.refs.txtOdometer != undefined ? this.refs.txtOdometer.value : undefined,
                        odometerDifference: (this.refs.txtOdometer.value != '' && this.props.vehicleDetailsForOdometerWarnings.toJSON().lastOdometerReading != undefined && this.props.vehicleDetailsForOdometerWarnings.toJSON().lastOdometerReading != '') ?
                            Number(this.refs.txtOdometer.value) - Number(this.props.vehicleDetailsForOdometerWarnings.toJSON().lastOdometerReading) : '',

                        estimatedOdometer: this.props.vehicleDetailsForOdometerWarnings.toJSON().estimatedOdometer,
                        currentDate: (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear(),
                        estimatedOdometerDiff: this.props.vehicleDetailsForOdometerWarnings.toJSON().estimatedOdometer != undefined && this.props.vehicleDetailsForOdometerWarnings.toJSON().estimatedOdometer != '' ? Number(this.props.vehicleDetailsForOdometerWarnings.toJSON().estimatedOdometer) - (this.refs.txtOdometer.value == undefined ? 0 : Number(this.refs.txtOdometer.value)) : ''

                    }}
                    {...messages[this.state.OdometerErrorMessage]} />
            else
                return null
        }

        const odometerErrMessagePopOver = <Popover id="popOverOdometer" className="ErrorMessage">
            <RawIntlProvider value={this.props.intl}>
            {this.state.OdometerErrorMessage == undefined && this.props.validationDetails.get('odometer')  ? false : odometerFormattedMessage()}
                {this.state.NonRoadVehicleErrorMessage != undefined && this.state.OdometerErrorMessage != undefined ?
                    <br /> : ''}
                {this.state.NonRoadVehicleErrorMessage == undefined ? false :
                    <FormattedMessage {...messages[this.state.NonRoadVehicleErrorMessage]} />}
               </RawIntlProvider>   
            </Popover>

        const refNumberValidationErrorMessage = fieldValidations.getValidationErrorMessage(this.props.fieldValidationsData, 'REFERENCE_NO', this.refs.txtRefNumber === undefined || this.refs.txtRefNumber === null || this.refs.txtRefNumber.value === undefined || this.refs.txtRefNumber.value === null ? '' :this.refs.txtRefNumber.value );        
        const shouldRefNumberBeVisible = fieldValidations.isVisible(this.props.fieldValidationsData,'REFERENCE_NO');        
        
        const invoiceNumberValidationErrorMessage = fieldValidations.getValidationErrorMessage(this.props.fieldValidationsData, 'INVOICE_NO', this.refs.txtInvoiceNo === undefined || this.refs.txtInvoiceNo === null || this.refs.txtInvoiceNo.value === undefined || this.refs.txtInvoiceNo.value === null ? '' :this.refs.txtInvoiceNo.value );        
        const shouldInvoiceNumberBeVisible = fieldValidations.isVisible(this.props.fieldValidationsData,'INVOICE_NO');        

        const yourNameValidationErrorMessage = fieldValidations.getValidationErrorMessage(this.props.fieldValidationsData, 'YOUR_NAME', this.refs.txtInvoiceName === undefined || this.refs.txtInvoiceName === null || this.refs.txtInvoiceName.value === undefined || this.refs.txtInvoiceName.value === null ? '' :this.refs.txtInvoiceName.value );        
        const shouldYourNameBeVisible = fieldValidations.isVisible(this.props.fieldValidationsData,'YOUR_NAME');

        const repairStartDateValidationErrorMessage = fieldValidations.getValidationErrorMessage(this.props.fieldValidationsData, 'REPAIR_START_DATE', this.refs.dtRepairStart === undefined || this.refs.dtRepairStart === null || this.refs.dtRepairStart.state.value === undefined || this.refs.dtRepairStart.state.value === null ? '' :this.refs.dtRepairStart.state.value );
        const shouldrepairStartDateBeVisible = fieldValidations.isVisible(this.props.fieldValidationsData,'REPAIR_START_DATE');        
          
        const repairCompletionDateValidationErrorMessage = fieldValidations.getValidationErrorMessage(this.props.fieldValidationsData, 'REPAIR_COMPLETION_DATE', this.refs.dtRepair === undefined || this.refs.dtRepair === null || this.refs.dtRepair.state.value === undefined || this.refs.dtRepair.state.value === null ? '' :this.refs.dtRepair.state.value );
        const shouldrepairCompletionDateBeVisible = fieldValidations.isVisible(this.props.fieldValidationsData,'REPAIR_COMPLETION_DATE');        
          
        const invoiceDateValidationErrorMessage = fieldValidations.getValidationErrorMessage(this.props.fieldValidationsData, 'INVOICE_DATE', this.refs.dtPickerInvoiceDate === undefined || this.refs.dtPickerInvoiceDate === null || this.refs.dtPickerInvoiceDate.state.value === undefined || this.refs.dtPickerInvoiceDate.state.value === null ? '' :this.refs.dtPickerInvoiceDate.state.value );
        const shouldInvoiceDateBeVisible = fieldValidations.isVisible(this.props.fieldValidationsData,'INVOICE_DATE');        
          
        const shouldodometerBeVisible = fieldValidations.isVisible(this.props.fieldValidationsData,'ODOMETER');        
        
        const engineHoursValidationErrorMessage = fieldValidations.getValidationErrorMessage(this.props.fieldValidationsData, 'ENGINE_HOURS', this.refs.txtEngineHours === undefined || this.refs.txtEngineHours === null || this.refs.txtEngineHours.value === undefined || this.refs.txtEngineHours.value === null ? '' :this.refs.txtEngineHours.value );        
        const shouldEngineHOursBeVisible = fieldValidations.isVisible(this.props.fieldValidationsData,'ENGINE_HOURS');           
    

        return <div className="close-po__invoice-header-row">

            <div className='close-po__invoice-container'>

                {
                    shouldInvoiceNumberBeVisible ? 
                        <div className="close-po__invoice-row-bottom-padding">
                            <div className="close-po__invoice_field-header">
                                <FormattedMessage {...messages.invoice_number} />
                            </div>
                            <div
                                className={classNames({ 'has-error': this.state.showError && !this.props.validationDetails.get('invoiceNo') })}>
                                <Overlay
                                    show={this.props.popOverDisplayShowInvoiceNo}
                                    onHide={this.props.onInputBlurHandler.bind(this, "invoiceNo")}
                                    placement="bottom"
                                    target={props => ReactDOM.findDOMNode(this.refs.txtInvoiceNo)}
                                >

                                    <Popover id="error_popover_invoice_no" className="close-po__error_message">
                                        {invoiceNumberValidationErrorMessage}
                                    </Popover>
                                </Overlay>
                                <form>
                                    <input ref="txtInvoiceNo" type="text" tabIndex="1" maxLength="20"
                                        className={classNames("close-po__input", 'form-control')}
                                        value={this.props.invoiceDetails.get('invoiceNo')}
                                        onChange={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.INVOICE_NO, null)}
                                        onBlur={this.props.onInputBlurHandler.bind(this, "invoiceNo")}
                                    />
                                </form>

                            </div>
                        </div>
                        :false
                }

                { shouldInvoiceDateBeVisible ?
                        <div className="close-po__invoice-row-bottom-padding">
                            <div className="close-po__invoice_field-header">
                                <FormattedMessage {...messages.invoice_date} />
                            </div>
                            <div
                                className={classNames('close-po__input_invoice-date', { 'has-error': this.state.showError && !this.props.validationDetails.get('invoiceDate') })}>
                                <Overlay
                                    show={this.props.popOverDisplayShowInvoiceDate}
                                    onHide={this.props.onInputBlurHandler.bind(this, "invoiceDate")}
                                    placement="bottom"
                                    target={props => ReactDOM.findDOMNode(this.refs.dtPickerInvoiceDate)}>
                                    <Popover id="error_popover_invoice_date" className="close-po__error_message">
                                        {invoiceDateValidationErrorMessage}
                                    </Popover>
                                </Overlay>
                                <DayPickerInput
                                    ref='dtPickerInvoiceDate'
                                    inputProps={{class:'close_po__invoice_date__date_picker-input',tabIndex: "2",  onBlur: this.props.onInputBlurHandler.bind(this, "invoiceDate")} }
                                    placeholder ={'MM/DD/YYYY'}
                                    onDayChange={this.onDateChangeHandler.bind(this, INVOICE_FIELD_TYPE.INVOICE_DATE)}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    dayPickerProps={{
                                        disabledDays: {
                                            before: new Date(moment().subtract(20, "years")),
                                            after:new Date(moment().subtract(0, "days")),
                                        },
                                        selectedDays:new Date(moment(this.props.invoiceDetails.get("invoiceDate"))),
                                        locale: this.props.userLocale.substring(0,2),
                                        localeUtils: MomentLocaleUtils,
                                    }}
                                    format="MM/DD/YYYY"
                                    value={new Date(moment(this.props.invoiceDetails.get("invoiceDate")))}


                                />
                            </div>
                        </div>
                        :false
                }

                { shouldYourNameBeVisible?
                <div className="close-po__invoice-row-bottom-padding">
                    <div className="close-po__invoice_field-header">
                        <FormattedMessage {...messages.invoice_name} />
                    </div>
                    <div
                        className={classNames({ 'has-error': this.state.showError && !this.props.validationDetails.get('submittedBy') })}>
                        <Overlay
                            show={this.props.popOverDisplayShowInvoiceName}
                            onHide={this.props.onInputBlurHandler.bind(this, "submittedBy")}
                            placement="bottom"
                            target={props => ReactDOM.findDOMNode(this.refs.txtInvoiceName)}
                        >

                            <Popover id="error_popover_invoice_name" className="close-po__error_message">
                                {yourNameValidationErrorMessage}
                            </Popover>

                        </Overlay>
                        <input type="text" tabIndex="3" ref="txtInvoiceName"
                            className={classNames("close-po__input", 'form-control')}
                            value={this.props.invoiceDetails.get('submittedBy')}                            
                            onChange={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.NAME, null)}
                            onBlur={this.props.onInputBlurHandler.bind(this, "submittedBy")}
                            readOnly={this.props.invoiceDetails.get('makeSubmittedByReadOnly')}
                            disabled={this.props.invoiceDetails.get('makeSubmittedByReadOnly')}
                            
                        />

                    </div>
                </div>
                : false }
                </div>
                <div className='close-po__invoice-container'>      
                { shouldrepairStartDateBeVisible?
                <div className="close-po__invoice-row-bottom-padding">
                    <div className=" close-po__invoice_field-header">
                        <FormattedMessage {...messages.repair_start_date} />
                    </div>
                    <div
                        className={classNames('close-po__input_invoice-date', { 'has-error': this.state.showError && !this.props.validationDetails.get('repairCompletionDate') })}>
                        <Overlay
                            show={this.props.popOverDisplayShowRepairStartDate}
                            onHide={this.props.onInputBlurHandler.bind(this, "repairStartDate")}
                            placement="bottom"
                            target={props => ReactDOM.findDOMNode(this.refs.dtRepairStart)}
                        >
                            <Popover id="error_popover_repair_date" className="close-po__error_message">
                                {repairStartDateValidationErrorMessage}
                            </Popover>
                        </Overlay>
                        <DayPickerInput
                            ref='dtRepairStart'
                            inputProps={{class:'close_po__repair_date-date-picker-input',tabIndex:"4",  onBlur: this.props.onInputBlurHandler.bind(this, "repairStartDate")} }
                            placeholder ={'MM/DD/YYYY'}
                            onDayChange={this.onDateChangeHandler.bind(this, INVOICE_FIELD_TYPE.REPAIR_START_DATE)}
                            dayPickerProps={{
                                disabledDays: {
                                    before: new Date(moment().subtract(29, "days")),
                                    after:new Date(moment().subtract(0, "days")),
                                },
                                selectedDays:new Date(moment(this.props.invoiceDetails.get('repairStartDate'))),
                                locale: this.props.userLocale.substring(0,2),
                                localeUtils: MomentLocaleUtils,
                            }}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            format="MM/DD/YYYY"
                            value={new Date(moment(this.props.invoiceDetails.get('repairStartDate')))}


                        />
                    </div>
                </div>
                : false }

                { shouldrepairCompletionDateBeVisible?    
                <div className="close-po__invoice-row-bottom-padding">
                    <div className=" close-po__invoice_field-header">
                        <FormattedMessage {...messages.repair_completion_date} />
                    </div>
                    <div
                        className={classNames('close-po__input_invoice-date', { 'has-error': this.state.showError && !this.props.validationDetails.get('repairCompletionDate') })}>
                        <Overlay
                            show={this.props.popOverDisplayShowRepairCompleteDate}
                            onHide={this.props.onInputBlurHandler.bind(this, "repairCompleteDate")}
                            placement="bottom"
                            target={props => ReactDOM.findDOMNode(this.refs.dtRepair)}
                        >
                            <Popover id="error_popover_repair_date" className="close-po__error_message">
                                {repairCompletionDateValidationErrorMessage}
                            </Popover>
                        </Overlay>
                        <DayPickerInput
                            ref='dtRepair'
                            inputProps={{class:'close_po__repair_date-date-picker-input',tabIndex:"5" ,onBlur:this.props.onInputBlurHandler.bind(this, "repairCompletionDate")
                            } }
                            placeholder ={'MM/DD/YYYY'}
                            onDayChange={this.onDateChangeHandler.bind(this, INVOICE_FIELD_TYPE.REPAIR_COMPLETION_DATE)}
                           dayPickerProps={{
                                disabledDays: {
                                    before: new Date(moment(this.props.invoiceDetails.get('repairStartDate'))),
                                    after:new Date(moment()),
                                },
                               selectedDays:new Date(moment(this.props.invoiceDetails.get('repairCompletionDate'))),
                               locale: this.props.userLocale.substring(0,2),
                               localeUtils: MomentLocaleUtils,
                           }}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            format="MM/DD/YYYY"
                            value={new Date(moment(this.props.invoiceDetails.get('repairCompletionDate')))}


                        />
                    </div>
                </div>
                : false }

                { shouldodometerBeVisible?
                <div className="close-po__invoice-row-bottom-padding">
                    <div className="close-po__invoice_field-header">
                         <FormattedMessage {...messages.odometer_caption} />
                    </div>
                    <div className={classNames('close-po__input_invoice-date')}>
                        { !(this.state.OdometerErrorMessage === undefined && this.state.NonRoadVehicleErrorMessage === undefined )||(this.refs.txtOdometer !== undefined && this.refs.txtOdometer.value !== undefined && this.refs.txtOdometer.value.trim().length > 0) || this.props.validationDetails.get('odometer') === false  ?
                        <Overlay
                            show={this.props.popOverDisplayShowOdometer}
                            onHide={this.props.onInputBlurHandler.bind(this, "odometer")}
                            placement="bottom"
                            target={props => ReactDOM.findDOMNode(this.refs.txtOdometer)}>
                            {odometerErrMessagePopOver}
                        </Overlay>
                        : false
                        }
                        <input type="text" tabIndex="6" ref="txtOdometer" maxLength={'6'}
                            className={classNames("close-po__input", 'form-control')}
                            value={this.props.invoiceDetails.get('odometer')}
                            onChange={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.ODOMETER, null)}
                            onBlur={this.onOdometerChangeHandler.bind(this, 'odometer')}
                            onKeyPress={helperFunctions.isNumberKey.bind(this)}
                            onFocus={this.onInputFocusHandler.bind(this, 'odometer')}
                        />
                    </div>
                </div>
                : false }
               </div>
               <div className='close-po__invoice-container'>
                { shouldodometerBeVisible?
                <div className="close-po__invoice-row-bottom-padding">
                    <div className="close-po__invoice_field-header">
                        <FormattedMessage {...messages.engine_hours}  values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>
                    </div>
                    <div>
                        <Overlay
                            show={this.props.showEngineHoursWarning}
                            onHide={this.props.onInputBlurHandler.bind(this, "engineHours")}
                            placement="bottom"
                            target={props => ReactDOM.findDOMNode(this.refs.txtEngineHours)}>   
                            {engineHoursErrMessagePopOver}  
                        </Overlay>
                        <DynamicNumber type="text" tabIndex="7" ref="txtEngineHours" maxLength={'6'}
                            fraction={0}
                            className={classNames("close-po__input", 'form-control')}
                            value={this.props.invoiceDetails.get('engineHours')}
                            onChange={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.ENGINE_HOURS, null)}
                            onBlur={this.onEngineHoursChangeHandler.bind(this, "engineHours")}
                            onKeyPress={helperFunctions.isNumberKey.bind(this)}
                            onFocus={this.onInputFocusHandler.bind(this, 'engineHours')}
                        />
                    </div>
                </div>
                : false }

                {shouldRefNumberBeVisible ?
                <div className="close-po__invoice-row-bottom-padding">
                    <div className="close-po__invoice_field-header">
                        <FormattedMessage {...messages.closed_po_driver_reference_number_title} values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>
                    </div>
                    <div>
                        <Overlay
                            show={this.props.popOverDisplayShowRefNumber}
                            onHide={this.props.onInputBlurHandler.bind(this, "refNumber")}
                            placement="bottom"
                            target={props => ReactDOM.findDOMNode(this.refs.txtRefNumber)}
                        >

                            <Popover id="error_popover_invoice_name" className="close-po__error_message">
                                {refNumberValidationErrorMessage}
                            </Popover>

                        </Overlay>
                        <input  className={classNames("close-po__input", 'form-control')}
                                ref="txtRefNumber"
                                size="6"
                                maxLength="25"
                                tabIndex="8"
                                defaultValue={this.props.closePOVendorReferenceNo === null ? this.props.invoiceDetails.get('refNumber') : this.props.closePOVendorReferenceNo}
                                onBlur={this.props.onInputBlurHandler.bind(this, 'refNumber')}
                                onChange={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.REF_NUMBER, null)}
                        />
                    </div>
                </div>
                : false 
                }

               <div className="close-po__invoice-row-bottom-padding">
                    <div className="close-po__invoice_field-header">
                    <FormattedMessage {...messages.client_po_number_caption} values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>
                    </div>
                        <input  className={classNames("close-po__input", 'form-control')}
                                ref="txtClientRefNumber"
                                size="6"
                                maxLength="20"
                                tabIndex="8"
                                value={this.props.invoiceDetails.get('clientReferenceNo') !== null && this.props.invoiceDetails.get('clientReferenceNo') != undefined ? this.props.invoiceDetails.get('clientReferenceNo').toUpperCase() : ''}
                                onChange={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.CLIENT_REF_NO, null)}
                       
                       />
                    </div>
            </div>


        </div>;
    }


}



export default injectIntl(InvoiceInformationClosedPO);
