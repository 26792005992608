// Libraries
import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './Header.css';
import Title from './Title';
import HideDocuments from './buttons/HideDocuments';

const Header = ({ vin8 }) => {                    
    return <div>
        
        <Row className='documents__bottom_align'>
            <Col xs={12}>
                <Title vin8={vin8}/>
            </Col>            
        </Row>

    </div>
}

export default Header;