import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import './EditVendorServices.css'
import { Grid, Row, Col } from 'react-bootstrap';

class EditVendorServices extends React.Component {
    getServicesUI() {
        const columns = 3;
        const itemsPerColumn = Math.ceil(this.props.vendorServices.count() / columns);
        const servicesByColumn = [];
        servicesByColumn.push(this.props.vendorServices.take(itemsPerColumn)); // column 1
        servicesByColumn.push(this.props.vendorServices.skip(itemsPerColumn).take(itemsPerColumn)); // column 2
        servicesByColumn.push(this.props.vendorServices.skip(itemsPerColumn*2)); // column 3 - take all the remaining items
        const tabIndexGroup = this.props.tabIndexStartFrom;
        return <Row className="edit-vendor-services__row">
            {servicesByColumn.map((services, index) => (
                <Col xs={4} className="edit-vendor-services__col" key={index}>
                    {services.map(service =>
                        <div key={service.get("id")} ref="radioTerms" className="edit-vendor-services__radio" 
                                onClick={this.props.vendorServicesSelected.bind(this, service.get("isServiceOffered"), service.get("id"))} 
                                tabIndex = {tabIndexGroup+index}>
                            <Row className="bem_remove_bootstrap_margin">
                                <Col xs={2} className="edit-vendor__col">
                                    <img src={!service.get("isServiceOffered")
                                        ? "/assets/VMS_unchecked.png"
                                        : "/assets/VMS_checked.png"}
                                    />
                                </Col>
                                <Col xs={10} className="edit-vendor__col">
                                    <div className="edit-vendor-services__title">
                                        {service.get("description")}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ).valueSeq()}
                </Col>
            ))}            
        </Row>        
    }


    getMinorityUI() {
        const columns = 4;
        let filteredMinorityByCountry = [];

        if(this.props.country == "USA"){
            
            filteredMinorityByCountry = this.props.vendorMinority.filter((minority) => {
    
                return minority.get('country') === "USA";
            });
    
        }
        else{
    
            filteredMinorityByCountry= this.props.vendorMinority.filter((minority) => {
    
                return minority.get('country') === "CAN";
            });
        }

        const itemsPerColumn = Math.ceil(filteredMinorityByCountry.count() / columns);
        const minoritiesByColumn = [];
        minoritiesByColumn.push(filteredMinorityByCountry.take(itemsPerColumn)); // column 1
        minoritiesByColumn.push(filteredMinorityByCountry.skip(itemsPerColumn).take(itemsPerColumn)); // column 2
        minoritiesByColumn.push(filteredMinorityByCountry.skip(itemsPerColumn*2).take(itemsPerColumn)); // column 3
        minoritiesByColumn.push(filteredMinorityByCountry.skip(itemsPerColumn*3)); // column 4 - take all the remaining items
        const tabIndexGroup = this.props.tabIndexStartFrom+80;

        return <Row className="bem_remove_bootstrap_margin">                    
            {minoritiesByColumn.map((minorities, index) => (                
                <Col xs={3} className="edit-vendor-services__col" key={index}>
                    {minorities.map(minority =>
                        <div ref="radioTerms" className="edit-vendor-services__radio" onClick={this.props.vendorMinoritySelected.bind(this, minority.get("id"))}
                            tabIndex = {tabIndexGroup+index}
                            >
                            <Row className="bem_remove_bootstrap_margin">
                                <Col xs={2} className="edit-vendor__col">
                                    <img src={!minority.get("isServiceOffered")
                                        ? "/assets/VMS_unchecked.png"
                                        : "/assets/VMS_checked.png"}
                                    />
                                </Col>                            
                                <Col xs={10} className="edit-vendor__col">
                                    <div className="edit-vendor-services__title">
                                        {minority.get("description")}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ).valueSeq()}
                </Col>
            ))}
        </Row>
    }

    render() {
        const messages = defineMessages({
            services_view_caption: {
                id: 'services_view_caption',
                description: 'services',
                defaultMessage: 'Services'
            },
            minority_vendor_caption: {
                id: 'minority_vendor_caption',
                description: 'minority vendor caption',
                defaultMessage: 'Minority Vendor'
            },
            save_services_caption: {
                id: 'save_services_caption',
                description: 'save services caption',
                defaultMessage: 'Save changes to services'
            },
            services_Cancel: {
                id: 'services_Cancel',
                description: 'Cancel',
                defaultMessage: 'Cancel'
            },
            select_one: {
                id: 'select_one',
                description: 'Select one:',
                defaultMessage: 'Select one:'
            },
        });        
        const tabIndexGroup = this.props.tabIndexStartFrom+98;
        
        return <div>
                                                
                {this.getServicesUI()}
                
                {this.props.showVendorMinority?<hr className="edit-vendor-services__black-hr"/>:false}    

                {this.props.showVendorMinority?<div className="edit-vendor-services__view">
                    <div className="edit-vendor-services__title2">
                        <FormattedMessage {...messages.minority_vendor_caption} />
                    </div>
                </div>:false}
                
                {this.props.showVendorMinority?<div className="edit-vendor-services__select_one"> <FormattedMessage {...messages.select_one} /></div>
                :false}

                {this.props.showVendorMinority?this.getMinorityUI():false}                                                                                
                
                {
                    this.props.showFooterButtons == false ?
                        false
                        :
                        <Row className="edit-vendor-services__view2">
                            <Col xs={4}>

                                <a className="edit-contact-component__caption2"
                                    onClick={this.props.servicesCancel.bind(this)}
                                    tabIndex = {tabIndexGroup+2}>
                                    <img src="/assets/VMS_28button_delete_blue.png" />
                                    {' '}<FormattedMessage {...messages.services_Cancel} />
                                    
                                </a>
                            </Col>
                            <Col xs={8} className="text-right">
                                <a className="edit-vendor-services__caption2"
                                    onClick={this.props.onSaveVendorServices.bind(this)}
                                    tabIndex = {tabIndexGroup+1}>
                                    <FormattedMessage {...messages.save_services_caption} />{' '}
                                    <img src="/assets/VMS_28button_edit_blue.png" />
                                </a>
                            </Col>
                        </Row>
                }
            
        </div>;
    }
}

export default (EditVendorServices);
