// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
// our stuff
import CheckCircle from 'components/CheckCircle';
import * as craneInspectionEnums from 'PO/ClosePO/screens/CraneInspection/data/CraneInspectionEnums';

class CraneInspectionBoomJibBrakesType extends React.Component {

	handleBoomJibBrakesCodeClick = (name, checked) => {
		if (!checked) {
			this.props.setFormData('boomJibBrakesType', name);
		}
	}

	render() {
		const messages = defineMessages({
			crane_inspection__boomJibBrakesType_Hydraulic: {
				id: 'crane_inspection__boomJibBrakesType_Hydraulic',
				description: 'Hydraulic',
				defaultMessage: 'Hydraulic'
			},
			crane_inspection__boomJibBrakesType_Air: {
				id: 'crane_inspection__boomJibBrakesType_Air',
				description: 'Air',
				defaultMessage: 'Air'
			}
		});

		if (!this.props.formData)
			return null;

		return (
			<div>
				<Row className="crane_inspection__boomJibBrakesType">
					<Col xs={6}>
						<CheckCircle
							name={craneInspectionEnums.boomJibBrakesType.hydraulic}
							checked={this.props.formData.get('boomJibBrakesType') === craneInspectionEnums.boomJibBrakesType.hydraulic}
							message={<FormattedMessage {...messages.crane_inspection__boomJibBrakesType_Hydraulic} />}
							onClick={this.handleBoomJibBrakesCodeClick}
						/>
					</Col>

					<Col xs={6}>
						<CheckCircle
							name={craneInspectionEnums.boomJibBrakesType.air}
							checked={this.props.formData.get('boomJibBrakesType') === craneInspectionEnums.boomJibBrakesType.air}
							message={<FormattedMessage {...messages.crane_inspection__boomJibBrakesType_Air} />}
							onClick={this.handleBoomJibBrakesCodeClick}
						/>
					</Col>
				</Row>
			</div>
		)
	}
}

export default CraneInspectionBoomJibBrakesType;