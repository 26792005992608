import React from 'react';
import './RecoverPassword.css';
import { defineMessages, FormattedMessage, injectIntl,RawIntlProvider } from 'react-intl';
import * as constants from 'constants/App';
import { Popover, Overlay } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as ChangeActions from '../actions/RecoverPassword';
import ReactDOM from 'react-dom';
import Spinner from 'components/Spinner';
import { falseIfUndefined, emptyStringIfUndefined, validateEmail } from 'utils/HelperFunctions';
import FormWrapper from 'components/FormWrapper';
import {Link} from 'react-router-dom';
import TranslationIcon from 'components/SiteTranslations';
import * as sharedActions from 'shared/actions/App';
import * as CookieHelper from 'utils/CookieHelper';

class RecoverPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showErrorMessageID: '',
            showErrorMessageVendor: '',
            numberValue: '',

            partnerConnectLoginType:'', //Type of APC login - will be either Vendor Id or Email Address             
            showUserEmailErrorMessage:false, //Flag to display Email Login Error Message
            popOverDisplayAPCLogInTypeError:false //Flag to display error rmessage if the user doesn't select the login type
        };
    }

    focusId(number, event) {

        if (event.target.value.length >= event.target.maxLength && number < 5) {
            number += 1;
            document.getElementById(number).focus();

        }
    }

    componentDidMount() {
        this.props.dispatch({ type: constants.RECOVER_PASSWORD_MOUNT });
    }

    onReturnClickHandler(e) {
        e.preventDefault();
        this.props.dispatch({ type: constants.NAVIGATE_TO, path: `/login` });
    }

    validationIdHandler() {
        const { formatMessage } = this.props.intl;
        let flag = false;
        let ariId = '';

        if (this.refs.txtARIId.value == "") {
            this.props.dispatch(ChangeActions.showPopOverARIId(true));
            flag = false;
        } else {

            let suffix = this.refs.txtARIId.value.substr(this.refs.txtARIId.value.length - 2).toUpperCase();

            if (['UA', 'CC', 'MM'].includes(suffix))
                ariId = this.refs.txtARIId.value.toUpperCase();
            else
            {
                const selectedCountry = this.props.country;
                const twoLetterCountryCode = selectedCountry == 'USA' ? 'UA' : selectedCountry == 'MEX' ? 'MM' : 'CC';
                ariId = `${this.refs.txtARIId.value.toUpperCase()}${twoLetterCountryCode}`;
            }

            this.props.dispatch(ChangeActions.showPopOverARIId(false));
            this.props.dispatch(ChangeActions.saveRecoverPasswordARIId(ariId));
            flag = true;
        }
        return flag;
    }
   
    validVendorInfoHandler() {

        const { formatMessage } = this.props.intl;

        let emailFilter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        let taxIDFilter = /^([0-9]{4})$/;
        let employeeNumberFilter = /^([0-9]{4})$/;
        let businessNumberFilter = /^([0-9]{4})$/;
        let federalTaxIdFilter = /^([0-9]{4})$/;

        let emailAddress = this.refs.txtVendorEmail.value;
        let taxFileNumber = this.refs.txtTaxId.value;
        let businessNumber = this.refs.txtBusinessNumber.value;
        let federalTaxIdNumber = this.refs.txtFederalTaxId.value;
        let selectedCountry = this.props.country;
        let hasValidationError = false;

        if (selectedCountry == 'CAN') {
            if (businessNumber === '' && emailAddress === '') {
                this.props.dispatch(ChangeActions.showPopOverBusinessNumber(true));
                this.props.dispatch(ChangeActions.showPopOverEmail(true));
                hasValidationError = true;
            }
            else if (businessNumber !== '' && !businessNumberFilter.test(businessNumber)) {
                this.props.dispatch(ChangeActions.showPopOverBusinessNumber(true));
                hasValidationError = true;
            }
            else if (emailAddress !== '' && !validateEmail(emailAddress)) {
                this.props.dispatch(ChangeActions.showPopOverEmail(true));
                hasValidationError = true;
            }
            else {
                this.props.dispatch(ChangeActions.showPopOverBusinessNumber(false));
                this.props.dispatch(ChangeActions.showPopOverEmail(false));
            }
        }
        else if (selectedCountry == 'MEX') {
            if (federalTaxIdNumber === '' && emailAddress === '') {
                this.props.dispatch(ChangeActions.showPopOverFederalTaxId(true));
                this.props.dispatch(ChangeActions.showPopOverEmail(true));
                hasValidationError = true;
            }
            else if (federalTaxIdNumber !== '' && !federalTaxIdFilter.test(federalTaxIdNumber)) {
                this.props.dispatch(ChangeActions.showPopOverFederalTaxId(true));
                hasValidationError = true;
            }
            else if (emailAddress !== '' && !validateEmail(emailAddress)) {
                this.props.dispatch(ChangeActions.showPopOverEmail(true));
                hasValidationError = true;
            }
            else {
                this.props.dispatch(ChangeActions.showPopOverFederalTaxId(false));
                this.props.dispatch(ChangeActions.showPopOverEmail(false));
            }
        }
        else {
            //is both tax ID and Email empty?
            if (taxFileNumber === '' && emailAddress === '') {
                this.props.dispatch(ChangeActions.showPopOverTaxId(true));
                this.props.dispatch(ChangeActions.showPopOverEmail(true));
                hasValidationError = true;
            }
            //is ssn filled out correctly and ein empty
            else if (taxFileNumber !== '') {
                hasValidationError = true;
                if (taxIDFilter.test(taxFileNumber)) {
                    hasValidationError = false;
                } else if (employeeNumberFilter.test(taxFileNumber)) {
                    hasValidationError = false;
                }
                if (hasValidationError) {
                    this.props.dispatch(ChangeActions.showPopOverTaxId(true));
                }
            }
            else if (emailAddress !== '' && !emailFilter.test(emailAddress)) {
                this.props.dispatch(ChangeActions.showPopOverEmail(true));
                hasValidationError = true;
            }
            else {
                this.props.dispatch(ChangeActions.showPopOverTaxId(false));
                this.props.dispatch(ChangeActions.showPopOverEmail(false));
            }
        }

        if (!hasValidationError) {
            let vendorRecoverPassInfo = {
                taxId: taxFileNumber,
                email: emailAddress
            };

            if (selectedCountry == 'CAN') {
                vendorRecoverPassInfo.taxId = businessNumber;
            }
            else if (selectedCountry == 'MEX') {
                vendorRecoverPassInfo.taxId = federalTaxIdNumber;
            }

            this.props.dispatch(ChangeActions.saveRecoverPasswordPersonal(vendorRecoverPassInfo));
        }

        return !hasValidationError;
    }

    onSubmitHandler() {
        //if LoginType - either email or vendor Id is not selected then display error message
        if (this.state.partnerConnectLoginType == '') {
            this.setState({ popOverDisplayAPCLogInTypeError: true });
        }
        else if (this.state.partnerConnectLoginType == 'email'){
            //Do not display error message if login type is selected
            this.setState({ popOverDisplayAPCLogInTypeError: false });
            if (validateEmail(this.refs.txtPersonaEmail.value)) {
                this.setState({ showUserEmailErrorMessage: false });
                this.props.dispatch({ type: constants.SENDING_RECOVER_PASSWORD_INFO_PERSONA_USER, email: this.refs.txtPersonaEmail.value })
            }
            else {
                this.setState({ showUserEmailErrorMessage: true });
            }
        }
        else{
            //Do not display error message if login type is selected
            this.setState({ popOverDisplayAPCLogInTypeError: false });

            let hasValidated = this.validationIdHandler() && this.validVendorInfoHandler();

            if (hasValidated) {
                this.props.dispatch({ type: constants.SENDING_RECOVER_PASSWORD_INFORMATION });
            }
        }       
    }

    onHide() {
        this.props.dispatch({ type: constants.CLEAR_RECOVER_PASSWORD_ERROR });
    }

    onCountryChange(event) {
        const country = event.currentTarget.attributes.value.value;
        
        this.props.dispatch({ type: constants.SET_SELECTED_COUNTRY, country: country });
       
        this.props.dispatch({ type: constants.RECOVER_PASSWORD_SELECTED_COUNTRY_CHANGED });
        
        if(this.state.partnerConnectLoginType=='vendor'){
            const ariId = this.refs.txtARIId.value;
            this.props.dispatch(ChangeActions.saveRecoverPasswordARIId(ariId));  
        }      
        if(country=="MEX"){
            this.props.dispatch(sharedActions.selectLocale("es-MX"));
            CookieHelper.createCookie('vms_language_preference', JSON.stringify({
                languageLocaleCookie:"es-MX",
            }));
        }
        else
        {
            this.props.dispatch(sharedActions.selectLocale("en-US"));
            CookieHelper.createCookie('vms_language_preference', JSON.stringify({
                languageLocaleCookie:"en-US",
            }));
        }

        this.state.numberValue = '';
    }

    handleChange(e) {
        const re = /^([0-9]{1,4})$/;

        // if value is not blank, then test the regex

        if (e.target.value == '' || re.test(e.target.value)) {
            this.setState({ numberValue: e.target.value })
        }
        else {
            e.preventDefault();
        }
    }

    onAccessPartnerConnect(option){        
        this.setState({partnerConnectLoginType:option});
    }

    render() {
        const messages = defineMessages({
            email_notification_title: {
                id: 'email_notification_title',
                description: 'title for input field groups ',
                defaultMessage: 'Request your password'
            },
            recover_password_subtitle: {
                id: 'recover_password_subtitle',
                description: 'sub title of recover password',
                defaultMessage: 'Plus one of the following:'
            },
            id_issued_ari_input: {
                id: 'id_issued_ari_input',
                description: 'title for input id field',
                defaultMessage: 'Holman Issued ID (mandatory)'
            },
            identification_ssn: {
                id: 'identification_ssn',
                description: 'title for input id field for Last 4 digits of EID/Taxpayer ID',
                defaultMessage: 'Last 4 digits of EID/Taxpayer ID'
            },
            identification_email: {
                id: 'identification_email',
                description: 'title for input id field for Email address',
                defaultMessage: 'E-mail address'
            },
            return_button: {
                id: 'return_button',
                description: 'Return button',
                defaultMessage: 'Return'
            },
            email_me_button: {
                id: 'email_me_button',
                description: 'email me button text',
                defaultMessage: 'Email me'
            },
            contact_footer_1: {
                id: 'contact_footer_1',
                description: 'Contact link description',
                defaultMessage: 'Click here'
            },
            contact_footer_2: {
                id: 'contact_footer_2',
                description: 'Contact link description',
                defaultMessage: ' to send us an email with your question and we will be sure to get back to you soon'
            },
            businessNumber_last4digits: {
                id: 'businessNumber_last4digits',
                description: 'Last 4 digits of Business Number',
                defaultMessage: 'Last 4 digits of Business Number'
            },
            business_number_required: {
                id: 'business_number_required',
                description: 'Invalid Business Number. Please enter in the following format: (XXXX)',
                defaultMessage: 'Invalid Business Number. Please enter in the following format: (XXXX)'
            },
            invalid_payment_email_format: {
                id: 'invalid_payment_email_format',
                description: 'Payment email format is invalid',
                defaultMessage: 'Payment email format is invalid'
            },
            invalid_user_email_format: {
                id: 'invalid_user_email_format',
                description: 'Email format is invalid',
                defaultMessage: 'Email format is invalid'
            },
            error_message_incorrect_identification_last_4_digits: {
                id: 'error_message_incorrect_identification_last_4_digits',
                description: 'incorrect input of ein or ssn',
                defaultMessage: 'Invalid ID. Please enter in the following format: (XXXX)'
            },
            error_message_recover_id: {
                id: 'error_message_recover_id',
                description: 'forgot to input id',
                defaultMessage: 'ID issued by Holman should not be left blank'
            },
            federal_tax_id_last4digits: {
                id: 'federal_tax_id_last4digits',
                description: 'Last 4 digits of Federal Tax Id',
                defaultMessage: 'Last 4 digits of Federal Tax Id'
            },
            federal_tax_id_required: {
                id: 'federal_tax_id_required',
                description: 'Invalid Federal Tax Id. Please enter in the following format: (XXXX)',
                defaultMessage: 'Invalid Federal Tax Id. Please enter in the following format: (XXXX)'
            },
            recover_password_or_seperator: {
                id: 'recover_password_or_seperator',
                description: 'Or',
                defaultMessage: 'or'
            },
            recover_password_sub_heading:{
                id:"recover_password_sub_heading",
                description:"Please click on the appropriate flag",
                defaultMessage:"Please click on the appropriate flag"
            },
            recover_password_email_address:{
                id:"recover_password_email_address",
                description:"E-mail address ID",
                defaultMessage:"E-mail address ID "
            },
            recover_password_email_address_sub:{
                id:"recover_password_email_address_sub",
                description:"(E-mail as a user ID)",
                defaultMessage:"(E-mail as a user ID)"
            },
            recover_password_vendorId:{
                id:"recover_password_vendorId",
                description:"Holman issued ID",
                defaultMessage:"Holman issued ID "
            },
            recover_password_vendorId_sub:{
                id:"recover_password_vendorId_sub",
                description:"(i.e. 123456UA or 987654CC)",
                defaultMessage:"(i.e. 123456UA or 987654CC)"
            },
            recover_password_ARI_access_error_message:{
                id:'recover_password_ARI_access_error_message',
                description:'Please select how do you access Holman Partner Connect',
                defaultMessage:'Please select how do you access Holman Partner Connect'
            },
            recover_password_choice_sub:{
                id:"recover_password_choice_sub",
                description:"What method do you use for logging into Holman PartnerConnect?",
                defaultMessage:"What method do you use for logging into Holman PartnerConnect?"
            },
            recover_password_footer_sub:{
                id:"recover_password_footer_sub",
                description:"Please note: the email address you enter MUST match what is on file with Holman.",
                defaultMessage:"Please note: the email address you enter MUST match what is on file with Holman."
            },                
                        
        });

        if (this.props.isfetchingData) {
            return <Spinner />;
        }

        return <div>
            {
                <div className="recover-component">                    
                    <div className="recover-component__title">
                        <span>
                            <FormattedMessage {...messages.email_notification_title} />
                        </span>
                    </div>
                    <div className="recover-component__input-section">
                        <div className="recover-component__subTitles row">
                            <FormattedMessage {...messages.recover_password_sub_heading} />
                            {'  '}
                            <img className={this.props.country == 'USA' ? 'step1__country-flag-selected' : 'step1__country-flag'} src="/assets/VMS_US_Flag.jpg" title="United States"
                                value="USA" role="button" onClick={this.onCountryChange.bind(this)} />
                            {'  '}
                            <img className={this.props.country == 'CAN' ? 'step1__country-flag-selected' : 'step1__country-flag'} src="/assets/VMS_CANADA_Flag.jpg" title="CANADA"
                                value="CAN" role="button" onClick={this.onCountryChange.bind(this)} />
                            {'  '}
                            <img className={this.props.country == 'MEX' ? 'step1__country-flag-selected' : 'step1__country-flag'} src="/assets/VMS_MEXICO_Flag.jpg" title="MEXICO"
                                value="MEX" role="button" onClick={this.onCountryChange.bind(this)} />
                            <div className="recover_password_component_help_container form-inline">                                
                                <span className="recover_password_component_help_seperator">|</span>
                                <div className="recover_password_component_country">
                                    <TranslationIcon backgroundColor='blue' />
                                </div>
                            </div>
                        </div>
                        <div className="recover-component__subTitles2 row">
                            <FormattedMessage {...messages.recover_password_choice_sub} />

                         </div>
                        
                        <div className="recover-component__row recover-component__subTitles row" >
                        
                        <img src={this.state.partnerConnectLoginType == 'vendor'
                            ? "/assets/VMS_checked.png"
                            : "/assets/VMS_unchecked.png"} onClick={this.onAccessPartnerConnect.bind(this,'vendor')}
                        />
                        {' '} <span ref="div_partner_connect_access_option"><FormattedMessage {...messages.recover_password_vendorId} />  
                        <label className="recover-component__note">
                                <FormattedMessage {...messages.recover_password_vendorId_sub} />
                            </label></span>
                        
                        
                        <Overlay
                                        show={this.state.popOverDisplayAPCLogInTypeError}
                                        onHide={this.onHide.bind(this)}
                                        placement="right"
                                        target={props => ReactDOM.findDOMNode(this.refs.div_partner_connect_access_option)}
                                    >
                                        <Popover id="ARIAccessErrorMessage"
                                            className="recover-component__errorMessage">
                                                  <RawIntlProvider value={this.props.intl}>
                                            <FormattedMessage {...messages.recover_password_ARI_access_error_message} />
                                            </RawIntlProvider>
                                        </Popover>

                                    </Overlay>
                        </div>
                        <div className="row">
                                    <div className="recover-component__seperator2">
                                        <FormattedMessage {...messages.recover_password_or_seperator} />
                                    </div>
                                </div>
                        <div className="recover-component__row recover-component__subTitles row">
                        <img src={this.state.partnerConnectLoginType == 'email'
                            ? "/assets/VMS_checked.png"
                            : "/assets/VMS_unchecked.png"} onClick={this.onAccessPartnerConnect.bind(this,'email')}
                        />
                        {' '} <FormattedMessage {...messages.recover_password_email_address} />
                        <label className="recover-component__note">
                                <FormattedMessage {...messages.recover_password_email_address_sub} />
                            </label>
                        </div>

                    </div>
                    {
                        this.state.partnerConnectLoginType == 'vendor' ?
                            <div className="recover-component__input-section">
                                <div className="recover-component__subTitles row">
                                    <FormattedMessage {...messages.id_issued_ari_input} />
                                </div>
                                <div className="recover-component__text-align row">
                                    <Overlay
                                        show={this.props.popOverDisplayShowARIId}
                                        onHide={this.onHide.bind(this)}
                                        placement="right"
                                        target={props => ReactDOM.findDOMNode(this.refs.txtARIId)}
                                    >
                                        <Popover id="ARIIdErrorMessage"
                                            className="recover-component__errorMessage">
                                                 <RawIntlProvider value={this.props.intl}>
                                            <FormattedMessage {...messages.error_message_recover_id} />
                                            </RawIntlProvider>
                                        </Popover>

                                    </Overlay>
                                    <input type="text" tabIndex="1" ref="txtARIId"
                                        className="recover-component__input-id"
                                        onBlur={() => this.props.dispatch(ChangeActions.showPopOverARIId(false))}
                                        maxLength="10"
                                        autoFocus
                                    />

                                </div>

                                <div className="recover_password_subtitle">
                                    <span>
                                        <FormattedMessage {...messages.recover_password_subtitle} />
                                    </span>
                                </div>

                                <div className={this.props.country == 'USA' ? 'shown' : 'hidden'}>
                                    <div className="row">
                                        <div className="recover-component__subTitles">
                                            <div className="recover-component__ssn">
                                                <FormattedMessage {...messages.identification_ssn} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="recover-component__input-align">
                                            <Overlay
                                                show={this.props.popOverDisplayShowTaxId}
                                                onHide={this.onHide.bind(this)}
                                                placement="right"
                                                target={props => ReactDOM.findDOMNode(this.refs.txtTaxId)}
                                            >
                                                <Popover id="PersonalInformationErrorMessage"
                                                    className="recover-component__errorMessage">
                                                         <RawIntlProvider value={this.props.intl}>
                                                    <FormattedMessage {...messages.error_message_incorrect_identification_last_4_digits} />
                                                    </RawIntlProvider>
                                                </Popover>
                                            </Overlay>
                                            <input type="password" tabIndex="2" ref="txtTaxId"
                                                className="recover-component__input"
                                                value={this.state.numberValue}
                                                onChange={this.handleChange.bind(this)}
                                                onBlur={() => this.props.dispatch(ChangeActions.showPopOverTaxId(false))}
                                                autocomplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={this.props.country == 'CAN' ? 'shown' : 'hidden'}>
                                    <div className="row">
                                        <div className="recover-component__subTitles">
                                            <div className="recover-component__ssn">
                                                <FormattedMessage {...messages.businessNumber_last4digits} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="recover-component__input-align_businessNumber row" >
                                        <Overlay
                                            show={this.props.popOverDisplayShowBusinessNumber}
                                            onHide={this.onHide.bind(this)}
                                            placement="right"
                                            target={props => ReactDOM.findDOMNode(this.refs.txtBusinessNumber)}
                                        >
                                            <Popover id="PersonalInformationErrorMessage"
                                                className="recover-component__errorMessage">
                                                    <RawIntlProvider value={this.props.intl}>
                                                <FormattedMessage {...messages.business_number_required} />
                                                </RawIntlProvider>
                                            </Popover>
                                        </Overlay>
                                        <input type="password" tabIndex="2" ref="txtBusinessNumber"
                                            className="recover-component__input"
                                            value={this.state.numberValue}
                                            onChange={this.handleChange.bind(this)}
                                            onBlur={() => this.props.dispatch(ChangeActions.showPopOverBusinessNumber(false))}
                                            autocomplete="off"
                                        />
                                    </div>
                                </div>

                                <div className={this.props.country == 'MEX' ? 'shown' : 'hidden'}>
                                    <div className="row">
                                        <div className="recover-component__subTitles">
                                            <div className="recover-component__ssn">
                                                <FormattedMessage {...messages.federal_tax_id_last4digits} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="recover-component__input-align_businessNumber row" >
                                        <Overlay
                                            show={this.props.popOverDisplayShowFederalTaxId}
                                            onHide={this.onHide.bind(this)}
                                            placement="right"
                                            target={props => ReactDOM.findDOMNode(this.refs.txtFederalTaxId)}
                                        >
                                            <Popover id="PersonalInformationErrorMessage"
                                                className="recover-component__errorMessage">
                                                    <RawIntlProvider value={this.props.intl}>
                                                <FormattedMessage {...messages.federal_tax_id_required} />
                                                </RawIntlProvider>
                                            </Popover>
                                        </Overlay>
                                        <input type="password" tabIndex="2" ref="txtFederalTaxId"
                                            className="recover-component__input"
                                            value={this.state.numberValue}
                                            onChange={this.handleChange.bind(this)}
                                            onBlur={() => this.props.dispatch(ChangeActions.showPopOverFederalTaxId(false))}
                                            autocomplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="recover-component__seperator">
                                        <FormattedMessage {...messages.recover_password_or_seperator} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="recover-component__subTitles">
                                        <div className="recover-component__ssn">
                                            <FormattedMessage {...messages.identification_email} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="recover-component__input-align">
                                        <Overlay
                                            show={this.props.popOverDisplayShowEmail}
                                            onHide={this.onHide.bind(this)}
                                            placement="right"
                                            target={props => ReactDOM.findDOMNode(this.refs.txtVendorEmail)}
                                        >
                                            <Popover id="popOver_VendorEmail" className="edit_contact__errorMessage">
                                            <RawIntlProvider value={this.props.intl}>
                                                <FormattedMessage {...messages.invalid_payment_email_format} />
                                                </RawIntlProvider>
                                            </Popover>
                                        </Overlay>

                                        <input type="email" tabIndex="3" ref="txtVendorEmail"
                                            className="recover-component__input"
                                            onBlur={() => this.props.dispatch(ChangeActions.showPopOverEmail(false))}
                                            autocomplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="recover-component__subTitles2 row">
                                        <FormattedMessage {...messages.recover_password_footer_sub} />

                                    </div>
                            </div>
                            : this.state.partnerConnectLoginType == 'email' ?
                                <div className="recover-component__input-section">

                                    <div className="recover-component__subTitles row">
                                        <FormattedMessage {...messages.identification_email} />
                                    </div>

                                    <div className="recover-component__text-align row">
                                    <div className="recover-component__input-align">
                                        <Overlay
                                            show={this.state.showUserEmailErrorMessage}
                                            onHide={this.onHide.bind(this)}
                                            placement="right"
                                            target={props => ReactDOM.findDOMNode(this.refs.txtPersonaEmail)}
                                        >
                                            <Popover id="popOver_VendorEmail" className="edit_contact__errorMessage">
                                            <RawIntlProvider value={this.props.intl}>
                                                <FormattedMessage {...messages.invalid_user_email_format} />
                                                </RawIntlProvider>
                                            </Popover>
                                        </Overlay>

                                        <input type="personaEmail" tabIndex="3" ref="txtPersonaEmail"
                                            className="recover-component__input"                                           
                                            autocomplete="off"
                                        />
                                    </div>
                                    </div>
                                    <div className="recover-component__subTitles row">
                                        <FormattedMessage {...messages.recover_password_footer_sub} />

                                    </div>
                                </div>
                                : false
                    }
                    <div className="recover-component__footer">
                        <div className={this.props.isfetchingData ? "pull-left recover-component_button recover-component_button-disabled" : "pull-left recover-component_button"} 
                            role="button"
                            onClick={this.onReturnClickHandler.bind(this)}>
                            <img src="/assets/VMS_33button_back_blue.png" />
                            {' '}
                            <FormattedMessage {...messages.return_button} />
                        </div>
                        <div
                            className="pull-right recover-component_button"
                            role="button"
                            onClick={this.onSubmitHandler.bind(this)} >
                            <FormattedMessage {...messages.email_me_button} />
                            {' '}
                            <img src="/assets/VMS_33button_go_bblue.png" />
                        </div>
                    </div>
                </div>
            }
        </div>
    }
}

function select(state) {
    return {
        isfetchingData: falseIfUndefined(state.appState.getIn(['uiData', 'recoverPassword', 'isFetchingData'])),
        popOverDisplayShowARIId: falseIfUndefined(state.appState.getIn(['uiData', 'recoverPassword', 'popOver', 'isPopOverDisplayedShowARIId'])),
        popOverDisplayShowTaxId: falseIfUndefined(state.appState.getIn(['uiData', 'recoverPassword', 'popOver', 'isPopOverDisplayedShowTaxId'])),
        popOverDisplayShowBusinessNumber: falseIfUndefined(state.appState.getIn(['uiData', 'recoverPassword', 'popOver', 'isPopOverDisplayedShowBusinessNumber'])),
        popOverDisplayShowFederalTaxId: falseIfUndefined(state.appState.getIn(['uiData', 'recoverPassword', 'popOver', 'isPopOverDisplayedShowFederalTaxId'])),
        popOverDisplayShowEmail: falseIfUndefined(state.appState.getIn(['uiData', 'recoverPassword', 'popOver', 'isPopOverDisplayedShowEmail'])),
        popOverDisplayShowPersonal: falseIfUndefined(state.appState.getIn(['uiData', 'recoverPassword', 'popOver', 'isPopOverDisplayedShowPersonal'])),
        recoverPasswordApproved: falseIfUndefined(state.appState.getIn(['uiData', 'recoverPassword', 'isApprovedData'])),
        recoverPasswordPersonalError: emptyStringIfUndefined(state.appState.getIn(['uiData', 'recoverPassword', 'errorMessage', 'informationPersonalError'])),
        recoverPasswordEmailAddress: emptyStringIfUndefined(state.appState.getIn(['uiData', 'recoverPassword', 'vendorEmail'])),
        recoverPasswordErrorMessage: emptyStringIfUndefined(state.appState.getIn(['uiData', 'recoverPassword', 'errorMessage'])),
        locale: emptyStringIfUndefined(state.appState.getIn(['uiData', 'shared', 'selectedLocale'])),
        country: emptyStringIfUndefined(state.appState.getIn(['uiData', 'shared', 'selectedCountry'])),

    };
}



export default connect(select)(injectIntl(RecoverPassword));
