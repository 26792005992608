import { Map } from 'immutable';
import jwtDecode from 'jwt-decode';
import { schema, normalize } from 'normalizr';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import React from 'react';

export function nullIfUndefined(data) {
    return data === undefined ? null : data;
}

export function emptyMapIfUndefined(data) {
    return data === undefined ? Map() : data;
}

export function emptyStringIfUndefined(data) {
    return data === undefined ? '' : data;
}

export function emptyStringIfNull(data) {
    return data === null ? '' : data;
}

export function emptyStringIfNullUndefined(data) {
    return data === null || data === undefined ? '' : data;
}

export function zeroIfUndefined(data) {
    return data === undefined ? 0 : data;
}

export function emptyArrayIfUndefined(data) {
    return data === undefined ? [] : data;
}

export function emptySetIfUndefined(data) {
    return data === undefined ? new Set() : data;
}

export function falseIfNull(data) {
    return data === null ? false : data;
}


export function falseIfUndefined(data) {
    return data === undefined ? false : data;
}

export function NoIfNull(data) {
    return data === null ? "N" : data;
}

export function hasTokenExpired(token) {
    var decodedToken = jwtDecode(token);
    if ((new Date().getTime() / 1000) > decodedToken.exp) return true;
    else return false;
}

export function getTokenExpirationTimeinMinutes(token) {
    var decodedToken = jwtDecode(token);
    var timeDiff = (decodedToken.exp - (new Date().getTime() / 1000)) / 60;
    return timeDiff < 0 ? 0 : timeDiff;
}

export function getVin8FromVin(vin) {
    return vin.substr(vin.length - 8, vin.length).trim();
}


export function isAuthenticated(state) {
    if (state.appState.getIn(['serverData', 'shared', 'token']) == undefined
        || hasTokenExpired(state.appState.getIn(['serverData', 'shared', 'token'])))
        return false;
    else
        return true;
}

export function isPersonaAuthenticated(state) {
    if (state.appState.getIn(['serverData', 'shared', 'personaToken']) == undefined
        || hasTokenExpired(state.appState.getIn(['serverData', 'shared', 'personaToken'])))
        return false;
    else
        return true;
}

export function shouldTokenBeRefreshed(state, config) {
    return getTokenExpirationTimeinMinutes(state.appState.getIn(['serverData', 'shared', 'token']))
        < config;
}

export function getSelectedCountry(state) {
    return state.appState.getIn(["uiData", "shared", "selectedCountry"]);
}

export function getSelectedLanguageCode(state) {
    return state === undefined ? "en-US" : state.appState.getIn(["uiData", "shared", "selectedLocale"]);
}

export function convertUserIdToVendorId(userId, state) {
    const selectedCountry = getSelectedCountry(state);
    const twoLetterCountryCode = selectedCountry == 'USA' ? 'UA' : selectedCountry == 'MEX' ? 'MM' : 'CC';
    return `${userId}${twoLetterCountryCode}`;
}

export function getVendorId(state) {
    return state.appState.getIn(['serverData', 'shared', 'vendorId']);
}

export function getToken(state) {
    return state.appState.getIn(['serverData', 'shared', 'token']);
}

export function getPersonaToken(state) {
    return state.appState.getIn(['serverData', 'shared', 'personaToken']);
}

/*
    Gets service ata codes from the collection of PM and regulatory services       
        1. Transform api returned ata codes array into normalizable ata codes array for maintenance 
            and regulatory services
        2. Normalize the structures 
        3. Combine and return the re-modelled ata codes as array
*/
export function getServiceAtaCodesToSave(maintenanceServices, regulatoryServices) {
    const serviceSchema = new schema.Entity('serviceSchema',{} ,{ idAttribute: 'ataCode' });

    //Transform api returned ata codes array into normalizable ata codes array for maintenance service  
    let finalAtaCodes = new Array();
    for (var service in maintenanceServices) {
        finalAtaCodes = finalAtaCodes.concat(maintenanceServices[service].ataCodes.map(x => {
            return {
                'ataCode': x.code,
                'description': x.description,
                'complaintCode': 'MA99'
            };
        }));
    }

    // Now normalize it
    let ataCodesForMaintaince = normalize(finalAtaCodes, [serviceSchema]).entities[serviceSchema.key];
    ataCodesForMaintaince = ataCodesForMaintaince ? ataCodesForMaintaince : {};

    //Transform api returned ata codes array into normalizable ata codes array for maintenance service
    finalAtaCodes = new Array();
    for (var service in regulatoryServices) {
        finalAtaCodes = finalAtaCodes.concat(regulatoryServices[service].ataCodes.map(x => {
            return {
                'ataCode': x.code,
                'description': x.description,
                'complaintCode': 'MA99'
            };
        }));
    }

    // Now normalize it
    let ataCodesForRegulatory = normalize(finalAtaCodes, [serviceSchema]).entities[serviceSchema.key];
    ataCodesForRegulatory = ataCodesForRegulatory ? ataCodesForRegulatory : {};

    //Combine and return the re modelled ata codes array
    return Object.assign(ataCodesForMaintaince, ataCodesForRegulatory);
}

export function getCustomErrorObject(error) {
    if (error != undefined) {
        if (error.code != 'API-999')
            return [{ message: error.message }];
        else
            return [];
    }
    else
        return [{ message: constants.GENERIC_ERROR_MESSAGE }];
}

export function getGenericErrorObject(error) {
    if (error != undefined) {
        if (error.code == 'API-999')
            return [{ message: constants.GENERIC_ERROR_MESSAGE }];
        else
            return [];
    }
    else
        return [{ message: constants.GENERIC_ERROR_MESSAGE }];
}

// This function gets an array of error objects.
export function getErrorObject(error) {
    // Axios error handling based on this pattern: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
    
    // Error object is empty.
    if (!error) {
        return [{ message: constants.GENERIC_ERROR_MESSAGE }];
    }
    else if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */        
        if (error.response.data.error !== undefined) {
            if (error.response.data.error.code !== 'API-999' && error.response.data.error.message)
                return [{ message: error.response.data.error.message }];
            else
                return [{ message: constants.GENERIC_ERROR_MESSAGE }];
        }
        else
            return [{ message: constants.GENERIC_ERROR_MESSAGE }];
    } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        return [{ message: constants.GENERIC_ERROR_MESSAGE }];
    } else {
        // Something happened in setting up the request and triggered an Error        
        if (error.code !== 'API-999' && error.message) {
            return [{ message: error.message }];
        }        
        else {
            return [{ message: constants.GENERIC_ERROR_MESSAGE }];
        }
    }            
}

export function getUIGenericErrorMessage(error) {
    return [{
        message: 'GENERIC_UI_ERROR_MESSAGE',
        messageDetail: error.message
    }];
}

export function isNumberKey(evt) {

    var charCode = (evt.which) ? evt.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode == 46 || charCode == 8) || charCode == 44)
        evt.preventDefault();
    else {
        var len = evt.target.value.length;
        var index = evt.target.value.indexOf('.');
        if (index > 0 && charCode == 46) {
            evt.preventDefault();
        }
        if (index > 0) {
            var CharAfterdot = (len + 1) - index;
            if (CharAfterdot > 3) {
                evt.preventDefault()

            }
        }

    }

    return true;
}


export function isAlphaNumeric(e) { // Alphanumeric only
    var k;
    document.all ? k = e.keycode : k = e.which;
    if (!((k > 47 && k < 58) || (k > 64 && k < 91) || (k > 96 && k < 123) || k == 0 || k == 32))
        e.preventDefault();
}

export function isAlpha(e) {
    let regexExp = new RegExp('^[a-zA-Z]+$');
    if (!regexExp.test(e.key))
        e.preventDefault();
}

export function isAlphaSpace(e) {
    let regexExp = new RegExp('^[a-zA-Z ]+$');
    if (!regexExp.test(e.key))
        e.preventDefault();
}

export function isAlphaSpecial(e) {
    let regexExp = new RegExp('^[\' a-zA-Z0-9&-.,]+$');
    if (!regexExp.test(e.key))
        e.preventDefault();
}
export function isZipCode(e) {
    let regexExp = new RegExp('^[\'0-9-]+$');
    if (!regexExp.test(e.key))
        e.preventDefault();
}

export function isNumeric(e) {
    let regexExp = new RegExp('^[0-9]+$');
    if (!regexExp.test(e.key))
        e.preventDefault();
}

export function isLicense(e) {
    let regexExp = new RegExp('[A-Za-z0-9\\-_ \\.]');
    if (!regexExp.test(e.key))
        e.preventDefault();
}

export function isInt(n) {
    return Number(n) === n && n % 1 === 0;
}

export function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}

export function renderError(id, props, error) {
    return <FormWrapper key={id} id={id} {...props} formErrors={error} />
    }


export function validateEmail(emailAddress) {
    emailAddress = emptyStringIfUndefined(emailAddress);
    let regexExp = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regexExp.test(emailAddress);
}

//gets the formatted SSN or Employer taxId. 
//SSN format xxx-xx-xxxx
//Employer Id format xx-xxxxxxx

export function formatTaxId(ssn,employerId,bn,federalTaxId){     
    if(!(federalTaxId===undefined || federalTaxId===null || federalTaxId===''))
        return {
            taxId: federalTaxId,
            taxType:'RFC'
        }
    else if(!isNaN(bn) && bn.length==9)
        return {
            taxId: bn,
            taxType: 'BN'
        }
    else if (!isNaN(ssn) && ssn.length == 9)
        return {
            taxId: formatSSN(ssn.toString()),
            taxType: 'SSN'
        }
    else if (!isNaN(employerId) && employerId.length == 9)
        return {
            taxId: formatEmployerId(employerId.toString()),
            taxType: 'EIN'
        }
    else
        return {};
    
}


//gets the string in the SSN format xxx-xx-xxxx
export function formatSSN(ssn) {
    if (ssn.length == 9) {
        return ssn.substr(0, 3) + '-' + ssn.substr(3, 2) + '-' + ssn.substr(5, 4)
    }
    else
        return '';
}

//gets the string in the Employer Id format xx-xxxxxxx
export function formatEmployerId(employerId) {
    if (employerId.length == 9) {
        return employerId.substr(0, 2) + '-' + employerId.substr(2, 7)
    }
    else
        return '';
}

export function validateOdometerDetails(odometer, engineHours, engineHourUpdated, vehicleDetails) {
  
    const newEngineHours = engineHours !== undefined && engineHours.state !== undefined ? (engineHours.state.viewValue != '' ? Number(engineHours.state.viewValue) : undefined) : undefined;
    const currentOdometer = vehicleDetails.toJSON().lastOdometerReading != undefined ? Number(vehicleDetails.toJSON().lastOdometerReading) : undefined;
    const newOdometer = ( odometer === undefined || odometer.state === undefined || odometer.state.viewValue === undefined ) ? undefined: (odometer.state.viewValue != ''? Number(odometer.state.viewValue) : odometer.value != '' ? Number(odometer.value) : undefined);
    const estimatedOdometerMin = Number(vehicleDetails.toJSON().estimatedOdometerRangeMin);
    const estimatedOdometerMax = Number(vehicleDetails.toJSON().estimatedOdometerRangeMax);
    const estimatedOdometer = Number(vehicleDetails.toJSON().estimatedOdometer);
    const odometerDifference = Math.abs((newOdometer == undefined ? 0 : newOdometer) - (currentOdometer == undefined ? 0 : currentOdometer));
    var isErrorMessageSet = false;
    let HourMeterUpdated = false
    let OdometerErrorMessage = undefined
    let OdometerErrorMessageUpdated = false
    let NonRoadVehicleErrorMessage = undefined
    let NonRoadVehicleErrorMessageUpdated = false
    let updateEngineHoursWithOdometer = false

    //If non road vehicle and new hour meter is not given, copy the odometer value to hour meter and validate both of them
    var mesg = '';
    if (vehicleDetails.toJSON().isOffRoadVehicle == true && newEngineHours == undefined && !engineHourUpdated) {

        updateEngineHoursWithOdometer = true;
        HourMeterUpdated = true;
        NonRoadVehicleErrorMessage = 'ododmeter_Validation_Message_5';
        NonRoadVehicleErrorMessageUpdated = true;
        isErrorMessageSet = true;

        //mesg='This Unit is identified as an equipment.  Odometer reading is copied to Hour Meter reading based on client configuration.';
    }
    else {

        NonRoadVehicleErrorMessage = undefined;
        NonRoadVehicleErrorMessageUpdated = true;
    }

    var errorMessage = '';
    var currentDate = (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear();
    if (newOdometer != currentOdometer) {

        /*if new ododmeter falls between the given range, display the error message -
         WARNING: THE METER ENTERED  nvl(p_odo_meter, 0) DIFFERS FROM THE ESTIMATED METER BY:nvl(p_odo_meter, 0) - l_current_odometer*/
        //if(newOdometer>=estimatedOdometerMin&&newOdometer<=estimatedOdometerMax)


        if ((odometerDifference >= estimatedOdometerMin - (currentOdometer == undefined ? 0 : currentOdometer)) && (odometerDifference <= estimatedOdometerMax - (currentOdometer == undefined ? 0 : currentOdometer))) {
            OdometerErrorMessage = 'ododmeter_Validation_Message_1';
            OdometerErrorMessageUpdated = true;
            isErrorMessageSet = true;
            //errorMessage='Warning: The meter entered '+newOdometer.toString()+' differs from the estimated meter by:'+(newOdometer-currentOdometer).toString();

        }
            //else if((newOdometer>estimatedOdometerMax || currentOdometer==undefined)&& vehicleDetails.toJSON().countryCode=='USA'){
        else if ((odometerDifference > (estimatedOdometerMax - estimatedOdometerMin) || currentOdometer == undefined) && vehicleDetails.toJSON().countryCode == 'USA') {

            //warehouse check
            if (estimatedOdometer == null || estimatedOdometer == 0)
                //errorMessage='Warning: The meter entered exceeds the estimated meter by: '+newOdometer.toString();
                OdometerErrorMessage = 'ododmeter_Validation_Message_2';

            else {
                OdometerErrorMessage = 'ododmeter_Validation_Message_3';

                //errorMessage = 'Warning: The meter entered ' + newOdometer.toString() + ' differs from the expected meter ' +estimatedOdometer.toString() + ' for ' + currentDate + ' by:' +(estimatedOdometer-(currentOdometer==undefined?0:currentOdometer)).toString();
            }
            OdometerErrorMessageUpdated = true;
            isErrorMessageSet = true;

        }
            //else if((newOdometer>estimatedOdometerMax || currentOdometer==undefined)&& vehicleDetails.toJSON().countryCode!='USA'){
        else if ((odometerDifference > (estimatedOdometerMax - estimatedOdometerMin) || currentOdometer == undefined) && vehicleDetails.toJSON().countryCode != 'USA') {
            OdometerErrorMessage = 'ododmeter_Validation_Message_4';
            OdometerErrorMessageUpdated = true;
            isErrorMessageSet = true;
            //errorMessage='Warning: The meter entered '+ newOdometer.toString() +' differs from the estimated meter by:' +(newOdometer-currentOdometer).toString();
        }

    }
    console.log('OdometerErrorMessage:', OdometerErrorMessage);
    return {
        isErrorMessageSet: isErrorMessageSet,
        HourMeterUpdated: HourMeterUpdated,
        OdometerErrorMessage: OdometerErrorMessage,
        OdometerErrorMessageUpdated: OdometerErrorMessageUpdated,
        NonRoadVehicleErrorMessage: NonRoadVehicleErrorMessage,
        NonRoadVehicleErrorMessageUpdated: NonRoadVehicleErrorMessageUpdated,
        updateEngineHoursWithOdometer: updateEngineHoursWithOdometer,
    }
}



export function validateEngineHours(engineHours, vehicleDetails) {
    /*f the current engine hours value is not null, and the new value entered is less than the current one, then display error message. PO-466*/
    const currentEngineHours = vehicleDetails.toJSON().lastHourMeterReading != undefined ? Number(vehicleDetails.toJSON().lastHourMeterReading) : undefined;
    const newEngineHours = engineHours.state.viewValue != ''? Number(engineHours.state.viewValue) : engineHours.value != '' ? Number(engineHours.value) : undefined;
    let EngineHoursErrorMessage = undefined;
    let isValid = true;
    let showOverlayEngineHours = false;

    if (currentEngineHours != null && newEngineHours != null && newEngineHours < currentEngineHours) {
        EngineHoursErrorMessage = "hourmeter_Validation_Message";
        showOverlayEngineHours = true;
        isValid = false;
    }
    else {
        EngineHoursErrorMessage = undefined
    }

    return {
        isValid: isValid,
        EngineHoursErrorMessage: EngineHoursErrorMessage,
        showOverlayEngineHours: showOverlayEngineHours
    }
}


export function getWheelObject(tempId, tireSelected, type, newTreadDepth, oldTreadDepth, treadDepthUnit, canSelectTire, canEditTreadDepth) {

    let item = {
        tempId: tempId,
        tireSelected: tireSelected, //?
        type: type,
        newTreadDepth: newTreadDepth,
        oldTreadDepth: oldTreadDepth,
        treadDepthUnit: treadDepthUnit,
        canSelectTire: canSelectTire,
        canEditTreadDepth: canEditTreadDepth
    };

    if (!type)
        return undefined;
    else
        return item;

}


export function isNewTire(eventType) {
    return eventType === 'replaced';
}

export function getTireTempId(lineItemId, axleNumber, tirePosition) {
    return (!tirePosition ? '' : tirePosition) + "|" + (!axleNumber ? '' : axleNumber) + "|" + (!lineItemId ? '' : lineItemId)
}

export function decodedTireTempId(id) {
    if(!id) return {};
    const idSplit = id.split("|");
    return {
        tirePosition: !idSplit[0] ? null : idSplit[0],
        axleNumber: !idSplit[1] || isNaN(idSplit[1]) ? null : Number(idSplit[1]),
        lineItemId: !idSplit[2] || isNaN(idSplit[2])? null : Number(idSplit[2])
    }
}

export function addFloats(){
    let sum = 0; 
    for (var index = 0; index < arguments.length; index++) {
        const value = !arguments[index] || isNaN(arguments[index]) ? 0.00 : arguments[index];
        sum += parseFloat(value);        
    }
    return Number(sum.toFixed(2));
}

export function isCarWashVendor(serviceClassification)
{
    return serviceClassification == constants.CARWASH
}

export function getListOfComplaintsForWhichNoteIsRequired(state) {
    let listOfComplaints  = [];
    let complaints = state.appState.getIn(['uiData', 'addEdit', 'complaints']);

    if (complaints !== undefined || complaints > 0) {
        for (var key in complaints.toJSON()) {
            //if the complaint is just added and if the note is not provided, we will ask the Vendor for a note.
            if (key.includes("complaint")) {
                let noteText = state.appState.getIn(['uiData', 'addEdit', 'notes', key, 'noteText']);

                if (noteText === undefined || noteText === "") {
                    listOfComplaints.push(key);


                }
            }
        }
    }


    if (listOfComplaints  !== undefined && listOfComplaints.length !== 0) {
        return listOfComplaints ;
    } else {
        return [];
    }

}

export function validatePasswordPolicy(password) {
    if (password == undefined || password == '')
        return false;

    const expectedMinLength = 8;
    const expectedMinChars = 3;
    let isValidPolicy = true;

    if (password.length < expectedMinLength)
        isValidPolicy = false;
        
    var hasUpperCase = /[A-Z]/.test(password);
    var hasLowerCase = /[a-z]/.test(password);
    var hasNumbers = /\d/.test(password);
    var hasNonalphas = /[\!#$%&'()*+,-.\]\/:;<=>?@\\^_`{|}~[]/.test(password);

    if (!hasUpperCase)
        isValidPolicy = false;

    if (!hasLowerCase)
        isValidPolicy = false;

    if (!hasNumbers)
        isValidPolicy = false;

    if (!hasNonalphas)
        isValidPolicy = false;

    return isValidPolicy;
}

export function getFormattedPersonaUserName(firstName,lastName,length=15){
    
    let fName=emptyStringIfNullUndefined(firstName);
    let lName=emptyStringIfNullUndefined(lastName);
    if((fName+" "+lName).length<=length)
        return fName+" "+lName;
    return (fName.substr(0,1)+". "+lName).substr(0,length);
}
