import React from 'react';
import {Modal, Grid, Row, Col} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import './ConfirmRejectBox.css';

export default class ConfirmRejectBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }


    showModal() {
        this.setState({show: true});
    }

    hideModal() {
        this.setState({show: false});
    }

    onOKClick(e) {
        e.preventDefault();
        this.hideModal();

        if (this.props.onOKClickEvent != undefined && this.props.onOKClickEvent != null)
            this.props.onOKClickEvent();
    }

    onCancelClick(e) {
        e.preventDefault();
        this.hideModal();
        if (this.props.onCancelClickEvent != undefined && this.props.onCancelClickEvent != null)
            this.props.onCancelClickEvent();
    }

    render() {

        return (
            <Modal
                {...this.props}
                show={this.state.show}
                dialogClassName="modal-dialog-custom modal-content-custom">
                <Modal.Body>
                    <Grid>
                        <Row>
                            <Col md={4} lg={4}>
                                <span className="confirm_reject_box__header">
                                    {
                                        <FormattedMessage
                                            id={this.props.title.id}
                                            description={this.props.title.description}
                                            defaultMessage={this.props.title.defaultMessage}
                                        />
                                    }
                                    </span>
                            </Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                        <Row>
                            <Col md={6} lg={6}>
                                <div className="confirm_reject_box__message">
                                    {

                                        <FormattedMessage
                                            id={this.props.message.id}
                                            description={this.props.message.description}
                                            defaultMessage={this.props.message.defaultMessage}
                                        />
                                    }
                                </div>

                            </Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                        <Row>
                            <Col md={3}>
                                <div className="confirm_reject_box__links-no">
                                    {this.props.showCancelButton == true ?
                                        <a onClick={this.onCancelClick.bind(this)}>
                                            <img src="/assets/VMS_33button_kill_blue.png"/>{' '}
                                            { this.props.showCancelButton == true ?
                                                <FormattedMessage
                                                    id={this.props.cancelButtonText.id}
                                                    description={this.props.cancelButtonText.description}
                                                    defaultMessage={this.props.cancelButtonText.defaultMessage}
                                                /> : false
                                            }
                                        </a>
                                        : false}
                                </div>
                            </Col>
                            &nbsp;&nbsp;
                            <Col md={3}>
                                <div className="confirm_reject_box__links-yes">
                                    <a onClick={this.onOKClick.bind(this)}>
                                        {
                                            <FormattedMessage
                                                id={this.props.okButtonText.id}
                                                description={this.props.okButtonText.description}
                                                defaultMessage={this.props.okButtonText.defaultMessage}
                                            />
                                        }
                                        {' '}<img src="/assets/VMS_33button_go_bblue.png"/>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </Modal.Body>
            </Modal>
        );
    }
}
ConfirmRejectBox.defaultProps = {
    showCancelButton: true,
    title: 'Confirmation Message',
    okButtonText: 'Yes',
    cancelButtonText: 'No'
}

