import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import { Popover, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import './Wheel.css';

export const Wheel = (props) => {

    const selectTire = () => {
        if (props.canSelectTire)
            props.onSelectTire(props.axle, props.type, props.tempId);
    }

    const tireSelectedAndEnabled = props.tireSelected && props.canSelectTire;
    const tireSelectedButDisabled = props.tireSelected && !props.canSelectTire;
    const tireNotSelected = !props.tireSelected;
    const tireNotSelectedAndDisabled = !props.tireSelected && !props.canSelectTire;
    const isOldTreadDepthDisabled = !props.canSelectTire && props.tireSelected;

    const tireImage =
        props.tireSelected && props.readOnly ? <img src="/assets/VMS_gray_tire_2.png" />
            : tireSelectedAndEnabled ? <img src="/assets/VMS_blue_tire_2.png" role='button' />
                : tireSelectedButDisabled ? <img src="/assets/VMS_light_blue_tire_2.png" />
                    : tireNotSelected ? <img src="/assets/VMS_white_tire_2.png" role='button' />
                        : tireNotSelectedAndDisabled ? <img src="/assets/VMS_gray_tire_2.png" />
                            : false;
    let inputRefs = {};

    let placeHolderUnit = props.defaultMeasurementUnit == 'inch' ? '/32'
        : props.defaultMeasurementUnit == 'milli_meter' ? 'mm'
            : '/32'
    
    const tooltip = (toolTipText) => <Tooltip id="tooltip">{toolTipText}</Tooltip>   

    return (
        <div >
            <div className='wheel-tire-container-left' key={props.tempId}>
                <div className={classnames('wheel__tire')}
                    onClick={() => selectTire()}>
                    {tireImage}
                </div>
                <div className={classnames(!props.canSelectTire ? 'tread-depth-disabled' : false)}>
                    {
                        props.tireSelected ?

                            <div className='new-tread-depth-box'>
                                <OverlayTrigger placement = 'right' overlay={tooltip('New Tread Depth')}>
                                    <input className={'wheel__new-tire_tread-depth'}
                                        type='text'
                                        onChange={(event) => props.onChangeTreadDepth(constants.NEW_TREAD_DEPTH, props.tempId, event.target.value)}
                                        value={props.newTreadDepth}
                                        placeholder={placeHolderUnit}
                                        onKeyPress={helperFunctions.isNumeric.bind(this)} />
                                </OverlayTrigger>
                                <div>
                                </div>
                            </div>
                            : false
                    }
                </div>
                <div className={classnames(isOldTreadDepthDisabled ? 'tread-depth-disabled' : false)}>
                    <div className={classnames(!props.tireSelected ? 'old-tread-depth-box' : 'old-tread-depth-box-1')}>
                         <OverlayTrigger placement = 'right' overlay={tooltip('Old Tread Depth')}>
                        <input className={'wheel__old-tire_tread-depth'}
                            type='text'
                            onChange={(event) => props.onChangeTreadDepth(constants.OLD_TREAD_DEPTH, props.tempId, event.target.value)}
                            value={props.oldTreadDepth}
                            placeholder={placeHolderUnit}
                            onKeyPress={helperFunctions.isNumeric.bind(this)}
                            ref={input => inputRefs['old' + props.tempId] = input}
                        />
                        </OverlayTrigger>
                        <Overlay
                            show={props.showTireValidationError && props.tireSelected && !props.oldTreadDepth}
                            target={() => inputRefs['old' + props.tempId]}
                            placement={"right"}
                            container={this}>
                            <Popover id={"popOver" + 'old' + props.tempId} className="enrollment-details__error_message step1__popover">
                                {'Required'}
                            </Popover>
                        </Overlay>
                    </div>

                </div>
            </div>
        </div>
    )
};

