import React from 'react';
import ReactDOM from 'react-dom';
import Submit from 'components/Submit';
import * as constants from 'constants/App';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Table } from 'react-bootstrap';
import { Popover, Overlay } from 'react-bootstrap';
import * as helperFunctions from 'utils/HelperFunctions';
import classnames from 'classnames';
import './AuthEmailNotification.css';
import { useAppState } from 'shared/AppState/app-state';


const AuthEmailNotificationView= (props) => {
  
    const [ appState, dispatch ] = useAppState();
    
        const messages = defineMessages({
            edit_configuration_caption: {
                id: 'edit_configuration_caption',
                description: 'Edit Authorization Email Configuration',
                defaultMessage: 'Edit Authorization Email Configuration'    
            },
            registered: {
                id: 'registered',
                description: 'Registered',
                defaultMessage: 'Registered'
            },
            notRegistered: {
                id: 'notRegistered',
                description: 'Not Registered',
                defaultMessage: 'Not Registered'
            },
            currentRegistrationStatus: {
                id: 'currentRegistrationStatus',
                description: 'Current Registration Status',
                defaultMessage: 'Current Registration Status'
            },
            documents_header_name: {
                id: 'documents_header_name',
                description: 'Header for name',
                defaultMessage: 'Name'
            },
            email_auth: {
                id: 'email_auth',
                description: 'email_auth',
                defaultMessage: 'Email Address'
            },
        });

        const isRegistered = props.isRegistered != undefined ? props.isRegistered : false;
        const optOut = props.isRegistered == undefined ? false : !props.isRegistered;
        return (
            <div className='auth-email-notification__view'>
                <div className='auth-email-notification__header_view'>
                    {
                        props.title == undefined ?
                            <FormattedMessage {...messages.notification_email } />
                            : props.title
                    }
                </div>
                <div className='auth-email-notification__notification-emails_view'>
                    {props.error}
                    <div className=''>
                        <div className='auth-email-notification__notification-options_view'>
                            <div className=''>
                                <FormattedMessage {...messages.currentRegistrationStatus } />
                            </div>
                            <div className='auth-email-notification__registration-status'>
                                {
                                    isRegistered ?
                                        <FormattedMessage {...messages.registered } />
                                        : <FormattedMessage {...messages.notRegistered } />
                                }
                            </div>
                        </div>
                        <div>
                            <div className='container auth-email-notification__notification-contacts_view'>
                                <div className='row auth-email-notification__notifi'>
                                    <div className='col-sm-3 auth-email-notification__notification-contact-name-header_view' > <FormattedMessage {...messages.documents_header_name } /></div>
                                    <div className='col-sm-4 auth-email-notification__notification-contact-email-header_view' > <FormattedMessage {...messages.email_auth } /></div>
                                </div>
                                <div>
                                    {
                                        props.authorizationEmailConfig.map((item, index) => {
                                            return <div key={`authEmail${index}`} className='row auth-email-notification__notification-contact-row_view'>
                                                <div className='col-sm-3 auth-email-notification__notification-contact-name_view'>{item.name}</div>
                                                <div className='col-sm-4 auth-email-notification__notification-contact-email_view'>{item.email}</div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {appState.authorizationEmailPermissions.includes("ED") &&

                        <div className="auth-email-notification__edit-config">
                            <a className=""
                                onClick={props.onEditConfiguration != undefined ? props.onEditConfiguration : alert('no binding')}>
                                <FormattedMessage {...messages.edit_configuration_caption} />{' '}
                                <img src="/assets/VMS_28button_edit_blue.png" />
                            </a>
                        </div>
                    }
                </div>
            </div>
        );
    }

export default AuthEmailNotificationView;