import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Map, fromJS } from 'immutable';
import './LinkedVendorView.css';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import { emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError, validateEmail, zeroIfUndefined, isCarWashVendor } from 'utils/HelperFunctions';
import LinkedVendorComp from '../components/LinkedVendorComp';
import configuration from 'configuration';
import * as actions from '../../../actions/App';
import jwtDecode from 'jwt-decode';
import { useAppState } from 'shared/AppState/app-state';
import * as Api from 'utils/Api';

class LinkedVendorView extends React.Component {

    componentDidMount() {
        if (this.props.vendorAccountType === "Sub" && this.props.currentVendorId !== null && this.props.corporateVendorId !== null || (this.props.vendorAccountType === "Master" && this.props.currentVendorId !== null && this.props.corporateVendorId !== null)) {
            this.props.dispatch({ type: "FETCH_CORPORATE_VENDOR_INFORMATION" });
        }
        this.props.dispatch({ type: constants.FETCH_LINKED_VENDORS });
    };

    componentWillUnmount() {
        this.props.dispatch({ type: constants.LINKED_VENDORS_SCREEN_UNMOUNTED });

    }

    onLocationSelectHandler(vendorState, zip) {

        if (vendorState !== "") {

            this.props.dispatch({ type: constants.FETCH_LINKED_VENDORS, vendorState });
        }
        else if (zip !== "") {

            this.props.dispatch({ type: constants.FETCH_LINKED_VENDORS, zip });
        } else {

            this.props.dispatch({ type: constants.FETCH_LINKED_VENDORS });

        }

    }

    onRegisterClickHandler = (vendorId, e) => {
        e.preventDefault();
        this.props.dispatch({ type: constants.SAVE_REGISTER_LINKED_VENDOR, register: vendorId });
        this.props.dispatch({ type: constants.NAVIGATE_TO, path: '/linkedVendorAccount/Register' });

    }

    onSignInClickHandler = (subVendorId, e) => {
        e.preventDefault();
        this.props.dispatch({ type: constants.SAVE_CORPORATE_VENDOR_DETAILS, masterId: this.props.currentVendorId, subId: subVendorId });

        const fetchReviewOpenPOCount = async () => {
            // Make API call 
            const openPOCount = await Api.fetchOpenOrdersOver30DayCount(this.props.token, subVendorId);

            if (openPOCount.statistics.ordersStatistics.openOrdersOver30DaysCount > 0) {
                this.props.dispatch({ type: constants.NAVIGATE_TO, path: "/attentionNeeded?type=agedPOs" });
            } else {
                this.props.dispatch({ type: constants.NAVIGATE_TO, path: this.props.serviceClassification ? '/carWash' : '/' });
            }

        }

        if (this.props.isReviewOpenPOsUponLoginEnabled) {
            
            fetchReviewOpenPOCount();

        } else {
            this.props.dispatch({ type: constants.NAVIGATE_TO, path: this.props.serviceClassification ? '/carWash' : '/' });
        }

    }

    onSignOutClickHandler(event) {
        event.preventDefault();

        //Check if token is meant for APC user or SSO user
        const jwtToken = jwtDecode(this.props.token);
        const audien = jwtToken.aud;
        if (audien == "VMS" || jwtToken.sub === 'ARIUSER')
            this.props.dispatch({ type: constants.NAVIGATE_TO, path: '/login?signed_out=1' });
        else
            this.props.dispatch({ type: constants.NAVIGATE_TO, path: '/sso?signed_out=1' });

        this.props.dispatch(actions.signOut());
    }

    fetchMoreLinkedVendorsClicked() {
        this.props.dispatch({ type: constants.FETCH_MORE_LINKED_VENDORS_CLICK });
    }

    onForgotPasswordClickHandler = (corporateId, e) => {
        e.preventDefault();
        this.props.dispatch({ type: constants.FORGOT_CORPORATE_VENDOR_PASSWORD, corporateId });
    }

    render() {
        if (this.props.isFetchingData) {
            return <Spinner />;
        }
        const messages = defineMessages({
            help_faq_return: {
                id: 'help_faq_return',
                description: 'Return button',
                defaultMessage: 'Return'
            },
            corporate_vendor_details_title: {
                id: 'corporate_vendor_details_title',
                description: 'corporate vendor details',
                defaultMessage: 'Corporate Account'
            },

        });

        return <div>

            <div className="corporate_vendor_details__title">
                <FormattedMessage {...messages.corporate_vendor_details_title} />
            </div>

            <LinkedVendorComp
                location={this.props.country === "CAN" ? this.props.canadianProvinces : this.props.usStates}
                linkedVendors={this.props.linkedVendors}
                onLocationSelect={this.onLocationSelectHandler.bind(this)}
                onRegisterClick={this.onRegisterClickHandler.bind(this)}
                onSignInClick={this.onSignInClickHandler.bind(this)}
                vendorAccountType={this.props.vendorAccountType}
                corporateVendorId={this.props.corporateVendorId}
                businessName={this.props.masterName}
                businessCity={this.props.masterCity}
                businessState={this.props.masterState}
                businessZip={this.props.masterZip}
                businessPhone={this.props.masterPhone}
                onSignOutClick={this.onSignOutClickHandler.bind(this)}
                fetchMoreLinkedVendors={this.fetchMoreLinkedVendorsClicked.bind(this)}
                nextLinkedVendorsExist={this.props.nextLinkedVendorsExist}
                currentVendorId={this.props.currentVendorId}
            />
            {this.props.vendorAccountType === "Sub" && this.props.corporateVendorId != "" && this.props.currentVendorId == null ?
                <div className="vendor_linked_view__return"
                    role="button"
                    onClick={this.onSignOutClickHandler.bind(this)}>
                    <img src="/assets/VMS_33button_back_blue.png" />
                    {' '}
                    <FormattedMessage {...messages.help_faq_return} />
                </div>
                : false
            }
        </div>;
    }
}

function select(state) {
    return {
        country: state.appState.getIn(['uiData', 'shared', 'selectedCountry']),
        linkedVendors: emptyMapIfUndefined(state.appState.getIn(['uiData', 'vendorLinkedAccounts', 'vendorSubVendor'])),
        usStates: emptyStringIfUndefined(fromJS(state.appState.getIn(['uiData', 'shared', 'usStates']))),
        canadianProvinces: emptyStringIfUndefined(fromJS(state.appState.getIn(['uiData', 'shared', 'canadianProvinces']))),
        currentVendorId: emptyStringIfUndefined(fromJS(state.appState.getIn(['serverData', 'shared', 'vendorId']))),
        isFetchingData: falseIfUndefined(state.appState.getIn(['uiData', 'vendorLinkedAccounts', 'isFetchingData'])),
        vendorAccountType: state.appState.getIn(['serverData', 'shared', 'vendorAccountType']),
        corporateVendorId: state.appState.getIn(['serverData', 'shared', 'corporateId']),
        businessName: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessName'])),
        businessCity: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessAddress', 'city'])),
        businessState: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessAddress', 'state'])),
        businessZip: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessAddress', 'zipCode'])),
        businessPhone: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessPhone'])),
        nextLinkedVendorsExist: falseIfUndefined(state.appState.getIn(['uiData', 'vendorLinkedAccounts', 'nextLinkedVendorsExist'])),
        masterName: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorLinkedAccounts', 'corporateInformation', 'corporateVendor', 'businessName'])),
        masterCity: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorLinkedAccounts', 'corporateInformation', 'corporateVendor', 'businessAddress', 'city'])),
        masterState: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorLinkedAccounts', 'corporateInformation', 'corporateVendor', 'businessAddress', 'state'])),
        masterZip: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorLinkedAccounts', 'corporateInformation', 'corporateVendor', 'businessAddress', 'zipCode'])),
        masterPhone: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorLinkedAccounts', 'corporateInformation', 'corporateVendor', 'businessPhone'])),
        token: state.appState.getIn(['serverData', 'shared', 'token']),
        serviceClassification: isCarWashVendor(state.appState.getIn(['serverData', 'shared', 'serviceClassification']))
    };
}

const LinkedVendorMainView = connect(select)(LinkedVendorView);

const LinkedVendorViewFC = (props) => {

    const [appState, dispatch] = useAppState();

    return <LinkedVendorMainView {...props}
        isReviewOpenPOsUponLoginEnabled={appState.isReviewOpenPOsUponLoginEnabled}
    />;


}
export default LinkedVendorViewFC;
