import React from 'react';
import './Footer.css';
import MarketingText from 'components/MarketingText';
import SearchPO from 'SearchPO';
import { Route,Switch,BrowserRouter, Redirect } from 'react-router-dom';
import { isAuthenticated, hasTokenExpired } from '../shared/utils/HelperFunctions';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'

class Footer extends React.Component {

    render() {
        return <div className="row container-fluid">
            <div className="visible-lg links col-md-offset-1 col-md-1 col-lg-offset-1 col-lg-2">
                &nbsp;
            </div>
            <div className="col-xs-12 col-sm-offset-1 col-sm-11 col-md-offset-2 col-md-8 col-lg-offset-0 col-lg-8">
                <div className="">
                    <Route exact path="/login" exact render={()=>(<MarketingText selectedCountry={this.props.selectedCountry}/>)} />
                    
                {
                    //if persona token is not null and it has expired, then redirect to login Page
                    //If persona token is null, and token is null then redirect
                    //persona token is null and token is not null and expired then redirect
                    //
                }
                    {(this.props.token === undefined || hasTokenExpired(this.props.token)) && !window.location.href.includes("/terms") && !window.location.href.includes("/vendorEnrollment") && !window.location.href.includes("/registration") && !window.location.href.includes("/contact")
                    && !window.location.href.includes("/exceptions") && !window.location.href.includes("/sso") && !window.location.href.includes("/resetPassword") && !window.location.href.includes("/recoverPassword") && !window.location.href.includes("/ariuser")
                    && !window.location.href.includes("/setupPassword")
                    && !window.location.href.includes("/Persona/")
                         ? <Redirect to={{
                            pathname: '/login'
                        }} />
                        : false
                    }


                    <Route exact path="/" component={SearchPO} />
                    <Route path="/carWash" exact component={SearchPO} />
                    <Route path="/carWash/vehicle/:vehicleId" exact component={SearchPO} />
                    <Route exact path="/contact/HelpFAQ"  render={
                        (routeParams) => ((this.props.token === undefined || hasTokenExpired(this.props.token))
                            ? false
                            : <SearchPO {...routeParams} />) } />
                    <Route path="/contact/FeedbackForm" exact render={
                        (routeParams) => ((this.props.token === undefined || hasTokenExpired(this.props.token))
                            ? false
                            : <SearchPO {...routeParams} />) } />
                    
                    <Route path="/yourAccount" render={ (routeParams) =>( <SearchPO {...routeParams} />)} />
                    <Route path="/ariuser" exactly render={
                        () => false} />
                    <Route path="/vehicle/:vehicleId/documents" exact component={SearchPO} />
                    <Route path="/learnMore" exact render={
                        () => false} />
                    <Route path="/linkedVendorAccount" exact render={
                        () => false} />
                    <Route path="/recoverPassword" exact render={
                        () => false} />
                    <Route path="/resetPassword" exact render={
                        () => false} />
                   <Route path="/attentionNeeded" exact component={SearchPO}/>
                    <Route path="/terms" exact render={
                        () => false} />
                    <Route path="/exceptions" exact render={
                        () => false} />
                    <Route path="/dealerDrafts" render={ (routeParams) =>  <SearchPO {...routeParams} />}/>

                    <Route exact path="/po/search" component={SearchPO} />

                    <Route exact path="/vehicles/vin8/:vin8/:actionType"  component={SearchPO} />
                    <Route exact path="/vehicles/plate/:plate/state/:state/:actionType"  component={SearchPO} />
                    <Route exact path="/vehicles/clientId/:clientId/unitNo/:unitNo/:actionType"  component={SearchPO} />
                    <Route exact path="/vehicles/serviceNo/:serviceNo/:actionType"  component={SearchPO} />

                    <Route exact path="/po/:actionType/:trackNumber" render={(routeParams) => (
                        <Switch>
                            <Route exact path="/po/odometer/:vehicleId" component={SearchPO} />
                            <Route exact path="/po/repairHistory/:vehicleID" component={SearchPO} />
                            <Route exact path="/po/driverValidationComplaints/:vehicleId" component={SearchPO} />
                            <Route exact path="/po/complaint/:vehicleId" component={SearchPO} />
                            <Route exact path="/po/clientParameters/:vehicleID" component={SearchPO} />
                            <Route exact path="/po/actionRequired/:trackNumber" component={SearchPO} />
                            <Route exact path="/po/adjustment/:trackNumber" component={SearchPO} />
                            <Route render={() =>
                                (routeParams.match.params.actionType == 'add' || routeParams.match.params.actionType == 'edit' || routeParams.match.params.actionType == 'view' || routeParams.match.params.actionType == 'close')
                                    ? <SearchPO />
                                    : false
                            } />
                        </Switch>
                    )} />

                    
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state, props) {    
    return {
        token: state.appState.getIn(['serverData', 'shared', 'token']),
        personaToken:state.appState.getIn(['serverData','shared','personaToken']),
        serviceClassification:state.appState.getIn(['serverData','shared','serviceClassification']),
    };
}

export default withRouter(connect(mapStateToProps)(Footer));