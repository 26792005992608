// Libraries
import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './Header.css';
import Title from './Title';
import HideDocuments from './buttons/HideDocuments';

const Header = ({ displayShowButton, documentsVisible, onShowDocuments, onHideDocumentsClick }) => {        
    const messages = defineMessages({
            documents_show_documents: {
                id: 'documents_show_documents',
                description: 'Link to show documents',
                defaultMessage: 'Show documents'
            }
        });
        
    return <div>
        
        <Row className='documents__bottom_align'>
            <Col xs={6}>
                <Title />
            </Col>
			<Col xs={6}>
                <Row className='documents__show_button_pad' >
                    { !documentsVisible ?
                        <div onClick={()=>{onShowDocuments()}} role="button" className='documents__show_button'>                        
                            <FormattedMessage {...messages.documents_show_documents}/>
                            {' '}<img src="/assets/VMS_button_show_all_pos.png"/>
                        </div>
                    :
                        <HideDocuments onClick={onHideDocumentsClick}/>
                    }
                </Row>
            </Col>
        </Row>

    </div>
}

export default Header;