/**
 * Created by prboyapa on 2/8/2016.
 */
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage, FormattedNumber, FormattedDate, FormattedTime } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import './POTotals.css';
import { IntlProvider } from 'react-intl';

class POTotals extends React.Component {

    render() {
        const messages = defineMessages({
            po_totals_view_subtotals: {
                id: 'po_totals_view_subtotals',
                description: 'Subtotal',
                defaultMessage: 'Subtotal'
            },
            po_totals_view_approved: {
                id: 'po_totals_view_approved',
                description: 'Approved',
                defaultMessage: 'Approved'
            },
            po_totals_view_pending_approval: {
                id: 'po_totals_view_pending_approval',
                description: 'Pending approval',
                defaultMessage: 'Pending approval'
            }
        });

        return <div className={classNames('table-responsive',
                               {'po_totals_view': this.props.actionType == 'VIEW' || this.props.actionType == 'EDIT' || this.props.actionType == 'ADJUSTMENT'},
                               {'po_totals_view__edit': this.props.actionType == 'ADD'})}>
            <table className="custom-responsive">
                <tbody>
                <tr className="row">
                    <td className="col-sm-1">
                    </td>
                    <td className="col-sm-5 po_totals_view__line_item">
                        <FormattedMessage {...messages.po_totals_view_subtotals} />
                    </td>
                    <td className="col-sm-3 po_totals_view__line_item">
                    </td>
                    <td className="col-sm-2 po_totals_view__line_item po_totals_view__currency">
                        <IntlProvider locale="en">
                            <FormattedNumber value={this.props.approvedAmountTotal + this.props.pendingAmountTotal}
                                         style="currency"
                                         currency={this.props.selectedCurrency}/>
                        </IntlProvider>
                    </td>
                    <td className="col-sm-1">
                    </td>
                </tr>
                <tr className="row">
                    <td className="col-sm-1">
                    </td>
                    <td className="col-sm-5 po_totals_view__line_item">
                        <FormattedMessage {...messages.po_totals_view_approved} />
                    </td>
                    <td className="col-sm-3 po_totals_view__line_item">
                    </td>
                    <td className="col-sm-2 po_totals_view__line_item po_totals_view__currency">
                        <IntlProvider locale="en">
                            <FormattedNumber value={this.props.approvedAmountTotal} style="currency" currency={this.props.selectedCurrency}/>
                        </IntlProvider>
                    </td>
                    <td className="col-sm-1">
                    </td>
                </tr>
                <tr className="row">
                    <td className="col-sm-1">
                    </td>
                    <td className="col-sm-5 po_totals_view__line_item po_totals_view__line_item_end">
                        <FormattedMessage {...messages.po_totals_view_pending_approval} />
                    </td>
                    <td className="col-sm-3 po_totals_view__line_item po_totals_view__line_item_end">
                    </td>
                    <td className="col-sm-2 po_totals_view__line_item po_totals_view__line_item_end po_totals_view__currency">
                        <IntlProvider locale="en">
                            <FormattedNumber value={this.props.pendingAmountTotal} style="currency" currency={this.props.selectedCurrency}/>
                        </IntlProvider>
                    </td>
                    <td className="col-sm-1">
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    }
}



export default POTotals;