import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map, fromJS } from 'immutable';


export function uiDataVendorAccountView(state = initialAppState.getIn(['uiData', 'vendorAccount']), action) {

    switch (action.type) {
        case constants.SHOW_PASSWORD_SETTINGS:
            return state.setIn(['settingsView', 'passwordSettings', 'isEditMode'], true);
        case constants.UPDATE_ARI_ID_ERROR_MESSAGE:
            return state.setIn(['settingsView', 'passwordSettings', 'ariIdErrorMessage'], action.errorMessage);
        case constants.UPDATE_CURRENT_PASSWORD_ERROR_MESSAGE:
            return state.setIn(['settingsView', 'passwordSettings', 'currentPasswordErrorMessage'], action.errorMessage);
        case constants.UPDATE_NEW_PWD_ERROR_MESSAGE:
            return state.setIn(['settingsView', 'passwordSettings', 'newPwdErrorMessage'], action.errorMessage);
        case constants.UPDATE_CONFIRM_PWD_ERROR_MESSAGE:
            return state.setIn(['settingsView', 'passwordSettings', 'confirmPwdErrorMessage'], action.errorMessage);
        case constants.HIDE_PASSWORD_SETTINGS:
            return state.setIn(['settingsView', 'passwordSettings', 'isEditMode'], false).set('isDirty', false);
        case constants.CHANGE_PASSWORD_REQUESTED:
            return state.setIn(['settingsView', 'passwordSettings', 'isChangingPassword'], true).set('isDirty', false);
        case constants.CHANGE_PASSWORD_RESOLVED:
            return state.setIn(['settingsView', 'passwordSettings', 'isChangingPassword'], false).setIn(['settingsView', 'passwordSettings', 'isEditMode'], false).set('isDirty', false).setIn(['settingsView', 'passwordSettings', 'wasPasswordChanged'], true);
        case constants.CHANGE_PASSWORD_REJECTED:
            return state.setIn(['settingsView', 'passwordSettings', 'isChangingPassword'], false).setIn(['settingsView', 'passwordSettings', 'isEditMode'], true).setIn(['settingsView', 'passwordSettings', 'ariIdErrorMessage'], action.errorCode);
        case constants.UPDATE_PASSWORD_TO_STORE:
            return state.setIn(['settingsView', 'passwordSettings', 'ariId'], action.ariId).setIn(['settingsView', 'passwordSettings', 'password'], action.currentPassword).setIn(['settingsView', 'passwordSettings', 'newPassword'], action.newPassword);
        case constants.SHOW_ARI_ID_ERROR_MESSAGE:
            return state.setIn(['settingsView', 'passwordSettings', 'showAriIdError'], action.flag);
        case constants.SHOW_CURRENT_PWD_ERROR_MESSAGE:
            return state.setIn(['settingsView', 'passwordSettings', 'showCurrentPwdError'], action.flag);
        case constants.SHOW_NEW_PWD_ERROR_MESSAGE:
            return state.setIn(['settingsView', 'passwordSettings', 'showNewPwdError'], action.flag);
        case constants.SHOW_CONFIRM_NEW_PWD_ERROR_MESSAGE:
            return state.setIn(['settingsView', 'passwordSettings', 'showConfirmNewPwdError'], action.flag);        
        case constants.RESET_ERROR_MESSAGES:
            return state.setIn(['settingsView', 'passwordSettings', 'showAriIdError'], false).setIn(['settingsView', 'passwordSettings', 'showCurrentPwdError'], false).setIn(['settingsView', 'passwordSettings', 'showNewPwdError'], false).setIn(['settingsView', 'passwordSettings', 'showConfirmNewPwdError'], false);
        case constants.CLEAR_PASSWORD_SETTINGS:
            return state.setIn(['settingsView', 'passwordSettings', 'isEditMode'], false).setIn(['settingsView', 'passwordSettings', 'ariIdErrorMessage'], undefined).setIn(['settingsView', 'passwordSettings', 'confirmPwdErrorMessage'], undefined).setIn(['settingsView', 'passwordSettings', 'isChangingPassword'], false).setIn(['settingsView', 'passwordSettings', 'ariId'], undefined).setIn(['settingsView', 'passwordSettings', 'password'], undefined).setIn(['settingsView', 'passwordSettings', 'newPassword'], undefined).setIn(['settingsView', 'passwordSettings', 'showAriIdError'], false).setIn(['settingsView', 'passwordSettings', 'showCurrentPwdError'], false).setIn(['settingsView', 'passwordSettings', 'showNewPwdError'], false).setIn(['settingsView', 'passwordSettings', 'showConfirmNewPwdError'], false).setIn(['settingsView', 'passwordSettings', 'wasPasswordChanged'], false);
        case constants.SHOW_CONTACT_SETTINGS:
            return state.setIn(['settingsView', 'contactSettings', 'isEditMode'], true);
        case constants.HIDE_CONTACT_SETTINGS:
            return state.setIn(['settingsView', 'contactSettings', 'isEditMode'], false).set('isDirty', false).setIn(['settingsView', 'contactSettings', 'ariIdErrorMessage'], undefined);
        case constants.UPDATE_VENDOR_DETAILS:        
            return state.setIn(['settingsView', 'contactSettings', 'businessContact'], action.vendorName!=undefined?action.vendorName.toUpperCase():action.vendorName)
                .setIn(['settingsView', 'contactSettings', 'businessPhone'], action.vendorBusinessNumber)
                .setIn(['settingsView', 'contactSettings', 'businessExtension'], action.vendorExtension)
                .setIn(['settingsView', 'contactSettings', 'businessFax'], action.vendorFaxNumber)
                .setIn(['settingsView', 'contactSettings', 'contactsEmail',action.vendorId,'email'], action.vendorEmailRecipient!=undefined?action.vendorEmailRecipient.toUpperCase():action.vendorEmailRecipient)
                .setIn(['settingsView', 'contactSettings', 'contactsEmail',action.vendorId,'name'], action.vendorName!=undefined?action.vendorName.toUpperCase():action.vendorName)
                // .setIn(['settingsView', 'contactSettings', 'businessAddress', 'address1'], action.vendorStreetAddress1)
                // .setIn(['settingsView', 'contactSettings', 'businessAddress', 'city'], action.vendorCityAddress)
                // .setIn(['settingsView', 'contactSettings', 'businessAddress', 'state'], action.vendorStateAddress)
                // .setIn(['settingsView', 'contactSettings', 'businessAddress', 'zipCode'], action.vendorZipAddress)
                .setIn(['settingsView', 'contactSettings', 'businessEmail'], action.vendorEmailRecipient!=undefined?action.vendorEmailRecipient.toUpperCase():action.vendorEmailRecipient).set('isDirty', true);
        case constants.SHOW_VENDOR_NAME_ERROR:
            return state.setIn(['settingsView', 'contactSettings', 'showError', 'showVendorNameError'], action.flag, !state.getIn(['settingsView', 'contactSettings', 'showError', 'showVendorNameError'], action.flag));
        case constants.SHOW_VENDOR_BUSINESS_NUMBER_ERROR:
            return state.setIn(['settingsView', 'contactSettings', 'showError', 'showVendorBusinessNumberError'], action.flag, !state.getIn(['settingsView', 'contactSettings', 'showError', 'showVendorBusinessNumberError'], action.flag));
        case constants.SHOW_BUSINESS_EXTN_ERROR:
        return state.setIn(['settingsView', 'contactSettings', 'showError', 'showBusinessExtensionError'], action.flag, !state.getIn(['settingsView', 'contactSettings', 'showError', 'showBusinessExtensionError'], action.flag));        
        case constants.SHOW_VENDOR_FAX_NUMBER_ERROR:
            return state.setIn(['settingsView', 'contactSettings', 'showError', 'showVendorFaxNumberError'], action.flag, !state.getIn(['settingsView', 'contactSettings', 'showError', 'showVendorFaxNumberError'], action.flag));
        case constants.SHOW_VENDOR_STREET_ADDRESS1_ERROR:
            return state.setIn(['settingsView', 'contactSettings', 'showError', 'showVendorStreetAddress1Error'], action.flag, !state.getIn(['settingsView', 'contactSettings', 'showError', 'showVendorStreetAddress1Error'], action.flag));
        case constants.SHOW_VENDOR_CITY_ADDRESS_ERROR:
            return state.setIn(['settingsView', 'contactSettings', 'showError', 'showVendorCityAddressError'], action.flag, !state.getIn(['settingsView', 'contactSettings', 'showError', 'showVendorCityAddressError'], action.flag));
        case constants.SHOW_VENDOR_STATE_ADDRESS_ERROR:
            return state.setIn(['settingsView', 'contactSettings', 'showError', 'showVendorStateAddressError'], action.flag, !state.getIn(['settingsView', 'contactSettings', 'showError', 'showVendorStateAddressError'], action.flag));
        case constants.SHOW_VENDOR_ZIP_ADDRESS_ERROR:
            return state.setIn(['settingsView', 'contactSettings', 'showError', 'showVendorZipAddressError'], action.flag, !state.getIn(['settingsView', 'contactSettings', 'showError', 'showVendorZipAddressError'], action.flag));
        case constants.SHOW_VENDOR_EMAIL_ERROR:
            return state.setIn(['settingsView', 'contactSettings', 'showError', 'showVendorEmailError'], action.flag, !state.getIn(['settingsView', 'contactSettings', 'showError', 'showVendorEmailError'], action.flag));
        case constants.RESET_ERROR_MESSAGES_VENDOR:
            return state.setIn(['settingsView', 'contactSettings', 'showError'], []);
        case constants.CLEAR_CONTACT_SETTINGS:
            return state.setIn(['settingsView', 'contactSettings', 'isEditMode'], false)
            .setIn(['settingsView', 'contactSettings', 'showError', 'showVendorNameError'], false)
            .setIn(['settingsView', 'contactSettings', 'showError', 'showVendorBusinessNumberError'], false)
            .setIn(['settingsView', 'contactSettings', 'showError', 'showBusinessExtensionError'], false)            
            .setIn(['settingsView', 'contactSettings', 'showError', 'showVendorFaxNumberError'], false)
            .setIn(['settingsView', 'contactSettings', 'showError', 'showVendorStreetAddress1Error'], false)
            .setIn(['settingsView', 'contactSettings', 'showError', 'showVendorCityAddressError'], false)
            .setIn(['settingsView', 'contactSettings', 'showError', 'showVendorStateAddressError'], false)
            .setIn(['settingsView', 'contactSettings', 'showError', 'showVendorZipAddressError'], false)
            .setIn(['settingsView', 'contactSettings', 'showError', 'showVendorEmailError'], false)
            .set('isDirty', false);
        case constants.CHANGE_VENDOR_INFO_REJECTED:
            return state.set('isSubmittingContactData', false).setIn(['settingsView', 'contactSettings', 'isEditMode'], true).setIn(['settingsView', 'contactSettings', 'ariIdErrorMessage'], action.errorMessage);
        case constants.SET_VENDOR_INFORMATION:
            return state.setIn(['settingsView', 'contactSettings'], fromJS(action.vendorInformation));
        case constants.SET_VENDOR_INFORMATION_EMAIL_CONTACT:
            return state.setIn(['settingsView', 'contactSettings', 'contactsEmail'], fromJS(action.vendorContactEmail));
        case constants.VENDOR_INFORMATION_REQUESTED:
            return state.set('isFetchingData', true).set('isDirty', false);
        case constants.VENDOR_INFORMATION_REJECTED:
            return state.set('isFetchingData', false)
                        .setIn(['accountInformation','errorMessage'], action.errorMessage);
        case constants.VENDOR_INFORMATION_RESOLVED:
            return state.set('isFetchingData', false);
        case constants.SAVE_ACCOUNT_INFORMATION_ID:
            return state.setIn(['accountInformation', 'ARIId'], action.ARIId);
        case constants.SAVE_ACCOUNT_INFORMATION_PERSONAL:
            return state.setIn(['accountInformation', 'personal'], action.personal);
        case constants.POP_OVER_DISPLAY_SHOW_ARI_ID:
            return state.setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowARIId'],
                action.popOverDisplayShowARIId, !state.getIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowARIId'],
                    action.popOverDisplayShowARIId));
        case constants.POP_OVER_DISPLAY_SHOW_PERSONAL:
            return state.setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowPersonal'],
                action.popOverDisplayShowVendorPersonal, !state.getIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowPersonal'],
                    action.popOverDisplayShowVendorPersonal));
        case constants.CLEAR_ACCOUNT_INFORMATION_ERROR:
            return state.setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowARIId'], false).setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowPersonal'], false).setIn(['accountInformation', 'ARIId'], '')
                .setIn(['accountInformation', 'personal'], '').setIn(['accountInformation', 'errorMessage'], []).setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowTerms'], false);
        case constants.SEND_ACCOUNT_VERIFICATION_REJECTED:
            return state.setIn('accountInformation', 'isFetchingData', false).setIn(['accountInformation', 'errorMessage'], [{ message: action.errorMessage }]).setIn(['accountInformation', 'isRequestedRejected'], true);
        case constants.SEND_ACCOUNT_VERIFICATION_RESOLVED:
            return state.setIn(['accountInformation', 'isFetchingData'], false).setIn(['accountInformation', 'isRequestedRejected'], false);
        case constants.SEND_ACCOUNT_VERIFICATION_APPROVED:
            return state.setIn(['accountInformation', 'isApprovedData'], true).setIn(['accountInformation', 'approvedToken'], action.approvedToken);
        case constants.ACCOUNT_VERIFICATION_MOUNT:
            return state.setIn(['accountInformation', 'isApprovedData'], false);
        case constants.ERROR_ACCOUNT_INFORMATION_ID:
            return state.setIn(['accountInformation', 'errorMessage', 'informationIdError'], action.idError);
        case constants.ERROR_ACCOUNT_INFORMATION_PERSONAL:
            return state.setIn(['accountInformation', 'errorMessage', 'informationPersonalError'], action.personalError);
        case constants.SEND_ACCOUNT_VERIFICATION_REQUESTED:
            return state.setIn(['accountInformation', 'isFetchingData'], true).setIn(['accountInformation', 'errorMessage'], []).set('isDirty', false);
        case constants.AGREE_TERMS_AND_CONDITIONS:
            return state.setIn(['accountInformation', 'agreedToTerms'], action.submission, !state.getIn(['accountInformation', 'agreedToTerms'], action.submission));
        case constants.ERROR_CHECKING_ACCOUNT_INFORMATION:
            return state.setIn(['accountInformation', 'errorMessage', 'checking1Error'], action.checking1Error);
        case constants.ERROR_ROUTING_NUMBER:
            return state.setIn(['accountInformation', 'errorMessage', 'routingNumberError'], action.routingNumberError);
        case constants.ERROR_SECOND_CHECKING_ACCOUNT:
            return state.setIn(['accountInformation', 'errorMessage', 'checking2Error'], action.checking2Error);
        case constants.POP_OVER_DISPLAY_SHOW_CHECKING1:
            return state.setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowChecking1'],
                action.popOverDisplayShowChecking1, !state.getIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowChecking1'],
                    action.popOverDisplayShowChecking1));
        case constants.POP_OVER_DISPLAY_SHOW_CHECKING2:
            return state.setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowChecking2'],
                action.popOverDisplayShowChecking2, !state.getIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowChecking2'],
                    action.popOverDisplayShowChecking2));
        case constants.POP_OVER_DISPLAY_SHOW_ROUTING_NUMBER:
            return state.setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowRoutingNumber'],
                action.popOverDisplayShowRoutingNumber, !state.getIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowRoutingNumber'],
                    action.popOverDisplayShowRoutingNumber));
        case constants.SAVE_ACCOUNT_CHECKING1:
            return state.setIn(['accountInformation', 'vendorBankingInformation', 'checkingAccount1'], action.vendorAccountChecking1).set('isDirty', true);
        case constants.SAVE_ACCOUNT_CHECKING2:
            return state.setIn(['accountInformation', 'vendorBankingInformation', 'checkingAccount2'], action.vendorAccountChecking2).set('isDirty', true);
        case constants.SAVE_ACCOUNT_ROUTING_NUMBER:
            return state.setIn(['accountInformation', 'vendorBankingInformation', 'routingNumber'], action.vendorAccountRoutingNumber).set('isDirty', true);
        case constants.VENDOR_BANKING_ACCOUNT_SUCCESS:
            return state.setIn(['accountInformation', 'isEditMode'], false).setIn(['accountInformation', 'isEditModeValidation'], false).setIn(['accountInformation', 'agreedToTerms'], false).setIn(['accountInformation', 'submissionApproved'], true).set('isDirty', false);
        case constants.VENDOR_PAYMENT_EMAIL_SUCCESS:            
            return state.setIn(['accountInformation', 'isPaymentEmailEditMode'], false).setIn(['accountInformation', 'isPaymentEmailEditModeValidation'], false).setIn(['accountInformation', 'agreedToTerms'], false).set('isDirty', false)
            .setIn(['settingsView', 'contactSettings', 'contactsEmail',action.vendorId,'email'],action.paymentEmail);
        case constants.UPDATE_VENDOR_BANKING_ACCOUNT_DETAILS:
            return state.setIn(['accountInformation', 'vendorRoutingNumber'], action.vendorRoutingNumber)
                .setIn(['settingsView', 'vendorChecking1'], action.vendorChecking1)
                .setIn(['settingsView', 'vendorChecking2'], action.vendorChecking2).set('isDirty', true);
        case constants.POP_OVER_DISPLAY_SHOW_TERMS:
            return state.setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowTerms'],
                action.popOverDisplayShowTerms, !state.getIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowTerms'],
                    action.popOverDisplayShowTerms));
        case constants.SHOW_TERMS_AND_CONDITION_ERROR:
            return state.setIn(['accountInformation', 'errorMessage', 'showTermsAndConditionError'], action.termsErrorMessage);
        case constants.SHOW_ACCOUNT_VALIDATION:
            return state.setIn(['accountInformation', 'isEditModeValidation'], true);
        case constants.SHOW_PAYMENT_EMAIL_VALIDATION:
            return state.setIn(['accountInformation', 'isPaymentEmailEditModeValidation'], true);
        case "HIDE_PAYMENT_EMAIL_VALIDATION":
        return state.setIn(['accountInformation', 'isPaymentEmailEditModeValidation'], false).set('isDirty', false);
        case constants.HIDE_ACCOUNT_VALIDATION:
            return state.setIn(['accountInformation', 'isEditModeValidation'], false).set('isDirty', false);
        case 'SHOW_ACCOUNT_PAYMENT_EMAIL_EDIT':
            return state.setIn(['accountInformation', 'isPaymentEmailEditMode'], true);
        case 'HIDE_ACCOUNT_PAYMENT_EMAIL_EDIT':
            return state.setIn(['accountInformation', 'isPaymentEmailEditMode'], false).set('isDirty',false);
        case constants.SHOW_ACCOUNT_EDIT:
            return state.setIn(['accountInformation', 'isEditMode'], true);
        case constants.HIDE_ACCOUNT_EDIT:
            return state.setIn(['accountInformation', 'isEditMode'], false).set('isDirty', false);
        case constants.CLEAR_ACCOUNT_INFORMATION:
            return state.setIn(['accountInformation', 'isEditMode'], false).setIn(['accountInformation', 'isEditModeValidation'], false).setIn(['accountInformation', 'agreedToTerms'], false).setIn(['accountInformation', 'submissionApproved'], false)
                .setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowARIId'], false).setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowPersonal'], false).setIn(['accountInformation', 'ARIId'], '')
                .setIn(['accountInformation', 'personal'], '').setIn(['accountInformation', 'popOver', 'isPopOverDisplayedShowTerms'], false).set('isDirty', false)
                .setIn(['accountInformation', 'isPaymentEmailEditModeValidation'], false)
                .setIn(['accountInformation', 'isPaymentEmailEditMode'], false);
        case constants.VENDOR_SERVICES_MOUNT:
            return state.setIn(['vendorProfile', 'services'], fromJS(action.vendorServiceMap));
        case constants.FETCH_VENDOR_MINORITY:
            return state.setIn(['vendorProfile'], fromJS(action.VendorMinorityMap));
        case constants.VENDOR_MINORITY_UPDATE:
            return state.setIn(['vendorProfile', 'minorityVendor', action.vendorMinorityId, 'isServiceOffered'], true);
        case constants.SHOW_VENDOR_SERVICES_EDIT:
            return state.setIn(['vendorProfile', 'isEditMode'], true);
        case constants.HIDE_VENDOR_SERVICES_EDIT:
            return state.setIn(['vendorProfile', 'isEditMode'], false).set('isDirty', false);
        case constants.VENDOR_SERVICE_SELECTED:
            return state.setIn(['vendorProfile', 'services', action.serviceKey.toString(), 'isServiceOffered'], action.servicesSelected);
        case constants.VENDOR_MINORITY_SELECTED:
            return state.setIn(['vendorProfile', 'minorityVendor', action.minorityKey, 'isServiceOffered'], action.minoritySelected);
        case constants.VENDOR_ACCOUNT_IS_DIRTY:
            return state.set('isDirty', true);
        case constants.SET_VENDOR_BANKING_INFORMATION:
            return state.setIn(['accountInformation', 'checkingInitial'], fromJS(action.vendorBankingInformation));
        case constants.SET_VENDOR_BANKING_VERIFY_TOKEN:
            return state.setIn(['accountInformation', 'verify'], fromJS(action.verifyToken));
        case constants.UPDATE_VENDOR_TAX_INFORMATION:
            return state.setIn(['accountInformation', 'tax'], fromJS(action.vendorTaxInfo));
        case constants.MY_BUSINESS_GROWTH_REQUESTED:
            return state.set('isFetchingGrowthData', true).set('isDirty', false);
        case constants.MY_BUSINESS_GROWTH_REJECTED:
            return state.set('isFetchingGrowthData', false);
        case constants.MY_BUSINESS_GROWTH_RESOLVED:
            return state.set('isFetchingGrowthData', false).setIn(['myGrowth'], fromJS(action.vendorGrowthInfo));
        //auth email notification    
        case constants.ACCOUNT_VIEW_CLEAR_AUTH_EMAIL_NOTIFICATION:
            return state.setIn(['authorizationEmailConfig', 'contacts'], fromJS([{id:999999999, name: '', email: '', elementState: constants.ELEMENT_STATE.ADD }]))
                .setIn(['authorizationEmailConfig', 'isRegistered'], undefined)
                .setIn(['authorizationEmailConfigValidationErrs', 'contacts'], Map())
                .setIn(['authorizationEmailConfig', 'formMode'], constants.VIEW);
           case constants.ACCOUNT_VIEW_RESET_AUTH_EMAIL_NOTIFICATION:
            return state.setIn(['authorizationEmailConfig', 'contacts'], fromJS(action.configurationInitialState.contacts))
                .setIn(['authorizationEmailConfig', 'isRegistered'], action.configurationInitialState.isRegistered)
                .setIn(['authorizationEmailConfigValidationErrs', 'contacts'], Map())
                .setIn(['authorizationEmailConfig', 'formMode'], constants.VIEW);
        case constants.ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_REQUESTED:
            return state.set('isSubmittingAuthEmailConfigInfo', true)
                .setIn(['authorizationEmailConfig','authorizationEmailConfigErrormessage'], []);
        case constants.ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_RESOLVED:
            return state.set('isSubmittingAuthEmailConfigInfo', false)
                        .setIn(['authorizationEmailConfig', 'formMode'], constants.VIEW);
        case constants.ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_REJECTED:
            return state.set('isSubmittingAuthEmailConfigInfo', false)
                .setIn(['authorizationEmailConfig','authorizationEmailConfigErrormessage'], action.errorMessage);
        case constants.ACCOUNT_VIEW_UPDATE_NOTIFICATION_CONTACT:
        case constants.ACCOUNT_VIEW_REMOVE_NOTIFICATION_CONTACT:
            return state.setIn(['authorizationEmailConfig', 'contacts'], fromJS(action.updatedContacts));
        case constants.ACCOUNT_VIEW_UPDATE_EMAIL_CONFIG_REGISTRATION:
            return state.setIn(['authorizationEmailConfig', 'isRegistered'], action.isRegistered);
        case constants.ACCOUNT_VIEW_UPDATE_EMAIL_CONFIG_VALIDATION_ERRS:
            return state.setIn(['authorizationEmailConfigValidationErrs', 'contacts', action.validationErrs.name], action.validationErrs.value);
        case constants.ACCOUNT_VIEW_REMOVE_EMAIL_CONFIG_VALIDATION_ERRS:
            return state.deleteIn(['authorizationEmailConfigValidationErrs', 'contacts', action.fieldName]);
        case constants.FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_REQUESTED:
            return state.setIn(['authorizationEmailConfig', 'isFetchingAuthEmailConfigInfo'], true)
                .setIn(['authorizationEmailConfig', 'authorizationEmailConfigErrormessage'], []);
        case constants.FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_RESOLVED:
            return state.setIn(['authorizationEmailConfig', 'isFetchingAuthEmailConfigInfo'], false)
        case constants.FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_REJECTED:
            return state.setIn(['authorizationEmailConfig', 'isFetchingAuthEmailConfigInfo'], false)
                .setIn(['authorizationEmailConfig', 'authorizationEmailConfigErrormessage'], action.errorMessage);
        case constants.ACCOUNT_VIEW_EMAIL_CONFIG_FORM_MODE:
            return state.setIn(['authorizationEmailConfig', 'formMode'], action.formMode);  
        case constants.VENDOR_INFORMATION_SUBMIT_DATA_REQUESTED:
            return state.set('isSubmittingContactData', true);
        case constants.VENDOR_INFORMATION_SUBMIT_DATA_RESOLVED:
            return state.set('isSubmittingContactData', false).setIn(['settingsView', 'contactSettings', 'ariIdErrorMessage'], undefined);;            
        default:
            return state;
    }
}


export function serverDataVendorAccountView(state = initialAppState.getIn(['serverData', 'vendorAccount']), action) {

}