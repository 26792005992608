//libraries
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, FormattedNumber, injectIntl,RawIntlProvider } from 'react-intl';
import { Map } from 'immutable';
import classnames from 'classnames';
import { Grid, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IntlProvider } from 'react-intl';
//our stuff
import { LINE_ITEM_TYPE, LINE_ITEM_STATUS, FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR, ENTITY_FOR_ASSISTANCE } from 'constants/App';
import '../containers/ReviewActionRequiredItemView.css';
import './AdjustedLineItem.css';
import Notes from './Notes.js';
import { useAppState } from 'shared/AppState/app-state';
import FleetAdminContactInfo from 'shared/components/FleetAdminContactInfo';

const AdjustedLineItem = (props) => {

    const [appState, dispatch] = useAppState();

    const onAcceptClick = (lineItemId, e) => {
        props.onAcceptAdjustmentsClickHandler(lineItemId);
        props.closeModalHandler();
    }

    const { formatMessage } = props.intl;
    const messages = defineMessages({
        adjusted_line_item_view_quantity: {
            id: 'adjusted_line_item_view_quantity',
            description: 'Qty',
            defaultMessage: 'Qty'
        },
        adjusted_line_item_view_hour: {
            id: 'adjusted_line_item_view_hour',
            description: 'Hour',
            defaultMessage: 'Hour'
        },
        adjusted_line_item_view_cost: {
            id: 'adjusted_line_item_view_cost',
            description: 'Cost',
            defaultMessage: 'Cost'
        },
        adjusted_line_item_view_amount: {
            id: 'adjusted_line_item_view_amount',
            description: 'Amount',
            defaultMessage: 'Amount'
        },
        adjusted_line_item_view_submitted: {
            id: 'adjusted_line_item_view_submitted',
            description: 'Submitted',
            defaultMessage: 'Submitted'
        },
        adjusted_line_item_view_ari_adjustment: {
            id: 'adjusted_line_item_view_ari_adjustment',
            description: 'Holman Adjustment',
            defaultMessage: 'Holman Adjustment'
        },
        adjusted_line_item_view_ari_adjustment_client: {
          id: 'adjusted_line_item_view_ari_adjustment_client',
          description: 'Client Adjustment',
          defaultMessage: 'Client Adjustment'
      },
        adjusted_line_item_view_ari_created: {
            id: 'adjusted_line_item_view_ari_created',
            description: 'Created by Holman',
            defaultMessage: 'Created by Holman'
        },
        adjusted_line_item_view_cancel_po: {
            id: 'cancel_po_confirmation_title',
            description: 'Cancel PO',
            defaultMessage: 'Cancel PO'
        },
        adjusted_line_item_view_cancel_po_confirmation_no: {
            id: 'cancel_po_confirmation_no',
            description: 'No',
            defaultMessage: 'No'
        },
        adjusted_line_item_view_cancel_po_confirmation_yes: {
            id: 'cancel_po_confirmation_yes',
            description: 'Yes',
            defaultMessage: 'Yes'
        },
        adjusted_line_item_view_cancel_po_confirmation_title: {
            id: 'cancel_po_confirmation_title',
            description: 'Cancel PO',
            defaultMessage: 'Cancel PO'
        },
        adjusted_line_item_view_cancel_po_confirmation_message: {
            id: 'cancel_po_confirmation_message', description: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?',
            defaultMessage: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?'
        },
        close_dialog: {
            id: 'close_dialog',
            description: '  Close',
            defaultMessage: ' Close'
        },
        action_required_issue_note: {
            id: 'action_required_issue_note',
            description: 'If you have questions regarding these adjustments, please call \n1-866-274-1025 to discuss with a Holman technician',
            defaultMessage: 'If you have questions regarding these adjustments, please call \n1-866-274-1025 to discuss with a Holman technician'
        },
        action_required_issue_note_can: {
            id: 'action_required_issue_note_can',
            description: 'If you have questions regarding these adjustments, please call \n1-800-363-7676 to discuss with a Holman technician',
            defaultMessage: 'If you have questions regarding these adjustments, please call \n1-800-363-7676 to discuss with a Holman technician'
        },
        adjusted_line_item_status_description: {
            id: 'adjusted_line_item_status_description',
            description: '{productDescription} was adjusted by Holman',
            defaultMessage: '{productDescription} was adjusted by Holman',
        },
        adjusted_line_item_status_description_client: {
          id: 'adjusted_line_item_status_description_client',
          description: '{productDescription} was adjusted by client',
          defaultMessage: '{productDescription} was adjusted by client',
        },
        adjusted_line_item_related_line_description: {
            id: 'adjusted_line_item_related_line_description',
            description: 'Associated {associatedLineType} line will not be approved until you accept this change.',
            defaultMessage: 'Associated {associatedLineType} line will not be approved until you accept this change.'
        },
        adjusted_line_item_accept_description: {
            id: 'adjusted_line_item_accept_description',
            description: ' Accept ',
            defaultMessage: ' Accept '
        },
        adjusted_line_item_reviewed_description: {
            id: 'adjusted_line_item_reviewed_description',
            description: "You've reviewed this line",
            defaultMessage: "You've reviewed this line"
        },
        action_required_submitted_description: {
            id: 'action_required_submitted_description',
            description: 'Submitted',
            defaultMessage: 'Submitted'
        },
        action_required_note_from_ari_description: {
            id: 'action_required_note_from_ari_description',
            description: 'Note from Holman',
            defaultMessage: 'Note from Holman'
        },
        action_required_note_from_ari_description_client: {
          id: 'action_required_note_from_ari_description_client',
          description: 'Note from client',
          defaultMessage: 'Note from client'
      },
        adjusted_line_standard_ari_note: {
            id: "adjusted_line_standard_ari_note",
            description: "If you accept the adjusted price, this line will be approved.  Once all the lines on this PO are approved, you may complete the work right away. If not, the PO will be sent back to Holman and will be reviewed in the order in which it was received.",
            defaultMessage: "If you accept the adjusted price, this line will be approved.  Once all the lines on this PO are approved, you may complete the work right away. If not, the PO will be sent back to Holman and will be reviewed in the order in which it was received. "
        },
        adjusted_line_standard_ari_note_cost: {
            id: "adjusted_line_standard_ari_note_cost",
            description: "If you accept the adjusted price, this line will be approved.  Once all the lines on this PO are approved, you may complete the work right away. If not, the PO will be sent back to Holman and will be reviewed in the order in which it was received.",
            defaultMessage: "If you accept the adjusted price, this line will be approved.  Once all the lines on this PO are approved, you may complete the work right away. If not, the PO will be sent back to Holman and will be reviewed in the order in which it was received. "
        },
        adjusted_line_standard_ari_note_labor: {
            id: "adjusted_line_standard_ari_note_labor",
            description: "If you accept the adjusted hours, this line will be approved.  Once all the lines on this PO are approved, you may complete the work right away. If not, the PO will be sent back to Holman and will be reviewed in the order in which it was received.",
            defaultMessage: "If you accept the adjusted hours, this line will be approved.  Once all the lines on this PO are approved, you may complete the work right away. If not, the PO will be sent back to Holman and will be reviewed in the order in which it was received. "
        },
        adjusted_line_window_title: {
            id: "adjusted_line_window_title",
            description: "Please Review",
            defaultMessage: "Please Review"
        },
        adjusted_line_window_tooltip: {
            id: "adjusted_line_window_tooltip",
            description: "This product code has contract pricing. Price cannot be edited or displayed.",
            defaultMessage: "This product code has contract pricing. Price cannot be edited or displayed."
        }


    });

    const lineItem = props.lineItem;
    const productDescription = ( appState.isProductCodeFeatureEnabled && lineItem.get('vendorProductCode') !== undefined && lineItem.get('vendorProductCode') !== null ? lineItem.get('vendorProductCode') + ' - ' : '' ) + lineItem.get('productDescription') + ', ' + (lineItem.get('lineItemType') == LINE_ITEM_TYPE.PM ? LINE_ITEM_TYPE.PM_TEXT : lineItem.get('lineItemType').toUpperCase());
    const submittedQty = lineItem.get('previousQuantity') != null ? lineItem.get('previousQuantity') : lineItem.get('quantity'); //if previous value is null, that means Holman tech did not adjust this field
    const submittedCost = lineItem.get('previousUnitPrice') != null ? lineItem.get('previousUnitPrice') : lineItem.get('unitPrice');
    const submittedTotal = submittedQty * submittedCost;
    const ariQty = lineItem.get('quantity');
    const ariCost = lineItem.get('unitPrice');
    const ariTotal = lineItem.get('totalAmount');
    const hasAdjustment = lineItem.get('previousQuantity') != null || lineItem.get('previousUnitPrice') != null;
    const lineNotes = lineItem.get('authorizationStatusReason');
    const associatedLineTypes = props.associatedPendingLines.map(a => { return a.toUpperCase() }).join(', ');// lineItem.get('associatedPendingLineTypes');
    const pricingSource = lineItem.get('pricingSource');

    return <div className="add-new-line-items">
        <div className="row action_required_line_item__item1 add-new-line-items__close">
            <div onClick={props.closeModalHandler}>
                <img className="add-new-line-items__close-button"
                    src="/assets/VMS_33button_kill_blue.png" />
                {"  "} {<FormattedMessage {...messages.close_dialog} />}
            </div>
        </div>
        <div className='action_required_line_item__item1 add-new-line-items_step-1-2-3-container'>
            <div className="row add-new-line-items__caption">
                <FormattedMessage {...messages.adjusted_line_window_title} />
            </div>

        </div>
        {lineItem.get('reviewCompleted') ?
            <div className="action_required_line_item__item1">
                <div className="row add-new-line-items__header">
                    <FormattedMessage {...messages.adjusted_line_item_reviewed_description} />
                    <img className="adjusted_line_item__img_reviewed"
                        src="/assets/greenOK.png" />
                </div>
            </div> : false}
        <div className="add-new-line-items__search-ata-codes_border">
            <div className="action_required_line_item">

                <div className="action_required_line_item__lines">
                    <Row>
                        <Col xs={12} className="action_required_line_item__header_2 action_required_line_item__center">

                          { props.isHsbClientVehicle && props.entityForAssistance == ENTITY_FOR_ASSISTANCE.CUSTOMER ?
                            <FormattedMessage values={{ productDescription: productDescription }} {...messages.adjusted_line_item_status_description_client} />
                          :
                            <FormattedMessage values={{ productDescription: productDescription }} {...messages.adjusted_line_item_status_description} />
                          }                            

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={1}></Col>
                        <Col xs={10}>
                            <Row className="action_required_line_item">
                                <Col xs={1}></Col>

                                <Col xs={6} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage  {...messages.adjusted_line_item_view_quantity} />
                                </Col>
                                <Col xs={2} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage {...messages.adjusted_line_item_view_cost} />
                                </Col>
                                <Col xs={2} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage {...messages.adjusted_line_item_view_amount} />
                                </Col>
                                <Col xs={2}></Col>
                            </Row>

                            <Row>
                                <Col xs={1}></Col>
                                <Col xs={4} className={classnames('action_required_line_item__item',
                                    ariCost == submittedCost && ariQty == submittedQty ? 'action_required_line_item__row_bottom_last' : 'action_required_line_item__row_bottom_border')}>
                                    <FormattedMessage {...messages.action_required_submitted_description} />
                                </Col>
                                <Col xs={2}
                                    className={classnames('action_required_line_item__item', 'adjusted_line_item__number',
                                        ariCost == submittedCost && ariQty == submittedQty ? 'action_required_line_item__row_bottom_last' : 'action_required_line_item__row_bottom_border')}
                                >
                                    {submittedQty}
                                </Col>
                                <Col xs={2} className={classnames('action_required_line_item__item', 'adjusted_line_item__number',
                                    ariCost == submittedCost && ariQty == submittedQty ? 'action_required_line_item__row_bottom_last' : 'action_required_line_item__row_bottom_border')}>
                                   {appState.isHideClientPricingFeatureEnabled && pricingSource == "client_contract_pricing" ?  
                                   <OverlayTrigger 
                                                placement='right'
                                                overlay={<Tooltip id="tooltip"><RawIntlProvider value={props.intl}><FormattedMessage {...messages.adjusted_line_window_tooltip} /></RawIntlProvider></Tooltip>}
                                            >
                                                <div>N/A</div> 
                                   </OverlayTrigger>: 
                                    <IntlProvider locale="en">
                                        <FormattedNumber value={submittedCost} style="currency"
                                            currency={props.selectedCurrency} />
                                    </IntlProvider>
                                    }
                                </Col>
                                <Col xs={2} className={classnames('action_required_line_item__item', 'adjusted_line_item__number',
                                    ariCost == submittedCost && ariQty == submittedQty ? 'action_required_line_item__row_bottom_last' : 'action_required_line_item__row_bottom_border')}>
                                    {appState.isHideClientPricingFeatureEnabled && pricingSource == "client_contract_pricing" ? 
                                    <OverlayTrigger 
                                                placement='right'
                                                overlay={<Tooltip id="tooltip"><RawIntlProvider value={props.intl}><FormattedMessage {...messages.adjusted_line_window_tooltip} /></RawIntlProvider></Tooltip>}
                                            ><div>N/A</div> 
                                            </OverlayTrigger>: 
                                    <IntlProvider locale="en">
                                        <FormattedNumber value={submittedTotal} style="currency"
                                            currency={props.selectedCurrency} />
                                    </IntlProvider>
                                    }   
                                </Col>
                                <Col xs={2}></Col>
                            </Row>
                            {ariCost == submittedCost && ariQty == submittedQty ? false :
                                <Row className="action_required_line_item__ari_created_row">
                                    <Col xs={1}></Col>
                                    <Col xs={4} className="action_required_line_item__item action_required_line_item__row_bottom_last">
                                        { props.isHsbClientVehicle && props.entityForAssistance == ENTITY_FOR_ASSISTANCE.CUSTOMER ?
                                          <FormattedMessage {...messages.adjusted_line_item_view_ari_adjustment_client} />
                                        :
                                          <FormattedMessage {...messages.adjusted_line_item_view_ari_adjustment} />
                                        }                                        
                                    </Col>
                                    <Col xs={2}
                                        className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">
                                        {ariQty}

                                    </Col>
                                    <Col xs={2} className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">
                                    {appState.isHideClientPricingFeatureEnabled && pricingSource == "client_contract_pricing" ?  
                                    <OverlayTrigger 
                                                placement='right'
                                                overlay={<Tooltip id="tooltip"><RawIntlProvider value={props.intl}><FormattedMessage {...messages.adjusted_line_window_tooltip} /></RawIntlProvider></Tooltip>}
                                            ><div>N/A</div> 
                                            </OverlayTrigger>: 
                                        <IntlProvider locale="en">
                                            <FormattedNumber value={ariCost} style="currency"
                                                currency={props.selectedCurrency} />
                                        </IntlProvider>
                                    }
                                    </Col>
                                    <Col xs={2} className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">
                                    {appState.isHideClientPricingFeatureEnabled && pricingSource == "client_contract_pricing" ? 
                                     <OverlayTrigger 
                                                placement='right'
                                                overlay={<Tooltip id="tooltip"><RawIntlProvider value={props.intl}><FormattedMessage {...messages.adjusted_line_window_tooltip} /></RawIntlProvider></Tooltip>}
                                            ><div>N/A</div> 
                                            </OverlayTrigger>: 
                                        <IntlProvider locale="en">
                                            <FormattedNumber value={ariTotal} style="currency"
                                                currency={props.selectedCurrency} />
                                        </IntlProvider>
                                    }
                                    </Col>
                                    <Col xs={2}></Col>
                                </Row>
                            }
                        </Col>
                        <Col xs={1}></Col>
                    </Row>


                    <Row className="row">
                        <Col className="action_required_line_item__header_2" xs={12}>
                          { props.isHsbClientVehicle && props.entityForAssistance == ENTITY_FOR_ASSISTANCE.CUSTOMER ?
                            <FormattedMessage {...messages.action_required_note_from_ari_description_client} />
                          :
                            <FormattedMessage {...messages.action_required_note_from_ari_description} />
                          }                          
                        </Col></Row>
                    <Row>
                        <Col xs={12} className="action_required_line_item__item1">
                            <Notes text={
                                props.isPOAdjustedBySystem ?
                                    (lineItem.get('lineItemType') == LINE_ITEM_TYPE.PM || lineItem.get('lineItemType') == LINE_ITEM_TYPE.PARTS) ?
                                        formatMessage(messages.adjusted_line_standard_ari_note_cost) : formatMessage(messages.adjusted_line_standard_ari_note_labor)
                                    : lineNotes} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={10}></Col>
                        <Col xs={2} className="action_required_line_item__item">

                        </Col>
                    </Row>

                    <Row className="adjusted_line_item__header3">
                        <Col xs={12}>
                            {associatedLineTypes != null && associatedLineTypes != '' ?
                                <FormattedMessage values={{
                                    associatedLineType: associatedLineTypes
                                }} {...messages.adjusted_line_item_related_line_description} />
                                : false}
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={10}></Col>
                        <Col xs={2} className="action_required_line_item__item">

                        </Col>
                    </Row>

                </div>
            </div>
        </div>
        <div className="action_required_line_item__item add-new-line-items__save" onClick={() => onAcceptClick(lineItem.get('id'))}>
            <FormattedMessage {...messages.adjusted_line_item_accept_description} />
            <img src="/assets/VMS_33button_go_bblue.png" />

        </div>
        {
            props.isHsbClientVehicle && props.entityForAssistance == ENTITY_FOR_ASSISTANCE.CUSTOMER ? <FleetAdminContactInfo messageFor={FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR.ADJUSTED_LINEITEM}  fleetAdminContactInfo={props.fleetAdminContactInfo} fleetAdminContactInfoError={props.fleetAdminContactInfoError} /> :
            <div className="action_required__issue_note">
            {
                props.selectedCountry == 'USA' ? <FormattedMessage {...messages.action_required_issue_note} /> :
                    <FormattedMessage {...messages.action_required_issue_note_can} />
            }
            </div>
        }
    </div>
        ;
}


export default injectIntl(AdjustedLineItem);