import React from 'react';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import './ViewVehicleDetails.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from '../../../../shared/utils/MomentLocaleUtils';

class ViewVehicleDetails extends React.Component {

    constructor(props) {
        super(props);
        //const locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(this.props.userLocale);

        this.state = {
            format: "MM/DD/YY h:mm A",
            inputFormat: "MM/DD/YYYY",
            mode: "date",

        }
    }


    render() {

        const messages = defineMessages({
            dealer_draft_delivery_date_description: {
                id: 'dealer_draft_delivery_date_description',
                description: 'dealer_draft_delivery_date_description',
                defaultMessage: 'Delivery Date'
            },
            dealer_draft_license_plate_description: {
                id: 'dealer_draft_license_plate_description',
                description: 'dealer_draft_license_plate_description',
                defaultMessage: 'License Plate#'
            },
            dealer_draft_registration_date_description: {
                id: 'dealer_draft_registration_date_description',
                description: 'dealer_draft_registration_date_description',
                defaultMessage: 'Registration Expiration'
            },
            dealer_draft_Registration_state_description: {
                id: 'dealer_draft_Registration_state_description',
                description: 'dealer_draft_Registration_state_description',
                defaultMessage: 'Registration State'
            },
            dealer_draft_reg_label: {
                id: 'dealer_draft_reg_label',
                description: 'Registration information',
                defaultMessage: 'Registration information'
            }, 
            dealer_draft_temp_license_label: {
                id: 'dealer_draft_temp_license_label',
                description: 'Temp license plate #',
                defaultMessage: 'Temp license plate #'
            },
            dealer_draft_temp_exp_label: {
                id: 'dealer_draft_temp_exp_label',
                description: 'Temp registration expiration',
                defaultMessage: 'Temp registration expiration'
            },
            dealer_draft_temp_state_label: {
                id: 'dealer_draft_temp_state_label',
                description: 'Temp registration state',
                defaultMessage: 'Temp registration state'
            }
        });

        return <div className={classNames('view_details', 'view_details__edit')}>
            <table className="table-responsive">
                <tbody>
                {
                    <tr className="row">

                    <td className="col-sm-1">
                    </td>
                    <td colSpan="3" className={classNames('col-sm-10',
                        'view_details__box',)}>
                        <div className="col-sm-12 view_details__heading">
                        <div className="row view_details__title">
                        <FormattedMessage {...messages.dealer_draft_reg_label} />
                        </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-12 view_details__description">
                                <div className="col-sm-6 view_details__label">
                                    <FormattedMessage {...messages.dealer_draft_delivery_date_description} />
                                </div>

                                <div className={classNames('col-sm-6','view_details__date_edit')}>
                                    <div className='row'>
                                        <div className="col-sm-12">
                                                <div className = 'row'>
                                                    <div className = 'view_details_date__date_picker'>
                                                        <DayPickerInput
                                                            key='dtPicker1'
                                                            inputProps={{class:'view_details_date__date_picker-input',  disabled:this.props.requestStatus === "VIEW"}}
                                                            tabIndex={1}
                                                            placeholder ={'MM/DD/YYYY'}
                                                            dayPickerProps={{
                                                                disabledDays: {
                                                                    before: new Date(moment().subtract(50, "years")),
                                                                    after:new Date(moment()),
                                                                },
                                                                selectedDays:new Date(moment(this.props.newlineItem.get("deliveryDate"))),
                                                                locale: this.props.userLocale.substring(0,2),
                                                                localeUtils: MomentLocaleUtils,
                                                            }}
                                                            formatDate={formatDate}
                                                            parseDate={parseDate}
                                                            format="MM/DD/YYYY"
                                                            value={this.props.newlineItem.get("deliveryDate") !== undefined ?
                                                            new Date(moment(this.props.newlineItem.get("deliveryDate"))): 
                                                            this.props.deliveryDate != undefined ? new Date(moment(this.props.deliveryDate)) : undefined }

                                                        />
                                                    </div>
                                                </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-12 view_details__description">
                                <div className="col-sm-6 view_details__label">
                                    <FormattedMessage {...messages.dealer_draft_license_plate_description} />
                                </div>

                                <div className={classNames('col-sm-6','view_details__date_edit')}>
                                    <div className='row'>
                                        <div className="col-sm-12">
                                            <div className = 'row'>
                                                <div className = 'view_details__license'>
                                                    <div className="view_details_date__date_picker-input">{ this.props.licensePlate}</div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-12 view_details__description">
                                <div className="col-sm-6 view_details__label">
                                    <FormattedMessage {...messages.dealer_draft_registration_date_description} />
                                </div>

                                <div className={classNames('col-sm-6','view_details__date_edit')}>
                                    <div className='row'>
                                        <div className="col-sm-12">
                                            <div className = 'row'>
                                                <div className = 'view_details_date__date_picker'>
                                                    <DayPickerInput
                                                        key='dtPicker1'
                                                        inputProps={{class:'view_details_date__date_picker-input',  disabled:this.props.requestStatus === "VIEW"}}
                                                        tabIndex={1}
                                                        placeholder ={'MM/DD/YYYY'}
                                                        dayPickerProps={{
                                                            disabledDays: {
                                                                before: new Date(moment().add(1, "days")),
                                                            },
                                                            selectedDays:new Date(moment(this.props.newlineItem.get("registrationExpiration"))),
                                                            locale: this.props.userLocale.substring(0,2),
                                                            localeUtils: MomentLocaleUtils,
                                                        }}
                                                        formatDate={formatDate}
                                                        parseDate={parseDate}
                                                        format="MM/DD/YYYY"
                                                        value={this.props.newlineItem.get("registrationExpiration") !== undefined ?
                                                        new Date(moment(this.props.newlineItem.get("registrationExpiration"))) :
                                                        this.props.registrationExpiration != undefined ? new Date(moment(this.props.registrationExpiration)) : undefined}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm-12 view_details__description">
                                <div className="col-sm-6 view_details__label">
                                    <FormattedMessage {...messages.dealer_draft_Registration_state_description} />
                                </div>

                                <div className={classNames('col-sm-6','view_details__date_edit')}>
                                    <div className='row'>
                                        <div className="col-sm-12">
                                            <div className = 'row'>
                                                <div className='view_details_date__date_picker-input'>{this.props.registrationState }</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div>
                <div className='row'>
                    <div className="col-sm-12 view_details__description">
                        <div className="col-sm-6 view_details__label">
                        <FormattedMessage {...messages.dealer_draft_temp_license_label} />
                        </div>

                        <div className={classNames('col-sm-6', 'view_details__date_edit')}>
                            <div className='row'>
                                <div className="col-sm-12">
                                    <div className='row'>
                                        <div className='view_details__license'>
                                            <div className="view_details_date__date_picker-input">{this.props.tempLicensePlate}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-12 view_details__description">
                        <div className="col-sm-6 view_details__label">
                        <FormattedMessage {...messages.dealer_draft_temp_exp_label} />
                        </div>

                        <div className={classNames('col-sm-6', 'view_details__date_edit')}>
                            <div className='row'>
                                <div className="col-sm-12">
                                    <div className='row'>
                                        <div className='view_details_date__date_picker'>
                                            <DayPickerInput
                                                key='dtPicker2'
                                                inputProps={{ class: 'view_details_date__date_picker-input', disabled: this.props.requestStatus === "VIEW" }}
                                                tabIndex={1}
                                                placeholder={'MM/DD/YYYY'}
                                               dayPickerProps={{
                                                    disabledDays: {
                                                        before: new Date(moment().add(1, "days")),
                                                    },
                                                    selectedDays: new Date(moment(this.props.newlineItem.get("tempRegistrationExpDate"))),
                                                    locale: this.props.userLocale.substring(0, 2),
                                                    localeUtils: MomentLocaleUtils,
                                                }}
                                                formatDate={formatDate}
                                                parseDate={parseDate}
                                                format="MM/DD/YYYY"
                                                value={this.props.newlineItem.get("tempRegistrationExpDate") !== undefined ?
                                                    new Date(moment(this.props.newlineItem.get("tempRegistrationExpDate"))) : 
                                                    this.props.tempRegistrationExpDate != undefined ? new Date(moment(this.props.tempRegistrationExpDate)) : undefined}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-sm-12 view_details__description">
                        <div className="col-sm-6 view_details__label">
                        <FormattedMessage {...messages.dealer_draft_temp_state_label} />
                        </div>

                        <div className={classNames('col-sm-6', 'view_details__date_edit')}>
                            <div className='row'>
                                <div className="col-sm-12">
                                    <div className='row'>
                                        <div className='view_details_date__date_picker-input'>{this.props.tempRegistrationState}</div> 
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
                    </td>
                    <td className="col-sm-1">
                    </td>
                </tr>
                }

                </tbody>
            </table>
        </div>
    }
}




export default injectIntl(ViewVehicleDetails);


