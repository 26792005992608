import { call, put, select, take, cancel, fork } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import * as CookieHelper from 'utils/CookieHelper';
import jwtDecode from 'jwt-decode';
import * as fieldValidations from 'utils/FieldValidations';
import * as configuration from 'shared/configuration';
import * as Login from 'sagas/Login';

export function* fetchVendor(context, action) {
    const state = yield select();

    let ariUserToken = action.token;
    const searchVendorId = action.vendorId;

    // data request has started
    yield put({ type: constants.FETCH_SEARCH_VENDOR_REQUESTED });

    try {
        // override Holman User SSO token by On-Behalf-Of
        const token = yield call(Api.authenticateOnBehalfOfUser, searchVendorId, ariUserToken);

        yield put({ type: constants.ARIUSER__SEARCH_NEW_VENDOR, token: ariUserToken, vendorId: searchVendorId });

        //Save token and vendor Details to app state
        yield call(Login.saveAuthenticatedUserDetails, token, context, action);
    }
    catch (e) {        
        var customError = helperFunctions.getCustomErrorObject(e);
        var genericError = helperFunctions.getGenericErrorObject(e);
        var errorMesg = customError != undefined && customError.length > 0 ? customError[0].message : genericError != undefined && genericError.length > 0 ? genericError[0].message : undefined;
        yield put({ type: constants.SET_SEARCH_VENDOR_ERROR_MESSAGE, errorMessage: errorMesg });
        yield put({ type: constants.FETCH_SEARCH_VENDOR_REJECTED });
    }
}
