import React from 'react';
import {connect} from 'react-redux';
import {Map} from 'immutable';
import './MyServicesDueModal.css';
import {zeroIfUndefined} from 'utils/HelperFunctions';
import {defineMessages, FormattedMessage, FormattedDate} from 'react-intl';
import {SERVICE_STATUS} from 'constants/App';
import classnames from 'classnames';
import Submit from '../../../../../App/shared/components/Submit';
import Spinner from 'components/Spinner';
import { useAppState } from 'shared/AppState/app-state';


const MyServicesDueModal = (props) => {    

        const [appState, dispatch] = useAppState();

    const getCaption = () => {
        const messages = defineMessages({
                add_services_caption: {
                    id: 'add_services_caption',
                    description: 'This vehicle has services due',
                    defaultMessage: 'This vehicle has services due'
                },
                add_services_sub_caption: {
                    id: 'add_services_sub_caption',
                    description: 'add_services_sub_caption',
                    defaultMessage: 'The fleet manager or driver of this vehicle has been notified of this pending service.'
                },
            }
        )
        return <div >
            <div className="row my_services_due__caption2">
                <FormattedMessage {...messages.add_services_caption}/>
            </div>
            <div className="my_services_due__text_pad row">
                <div className="my_services_due__text col-sm-11">
                    <FormattedMessage {...messages.add_services_sub_caption}/>
                </div>
            </div>
        </div>
    }

    //render() {
        const messages = defineMessages({
            close_without_save_caption: {
                id: 'close_without_save_caption',
                description: 'your units',
                defaultMessage: 'Close'
            },
            due_services_caption: {
                id: 'due_services_caption',
                description: 'services due caption',
                defaultMessage: 'This vehicle has service due'
            },
            create_po_caption: {
                id: 'create_po_caption',
                description: 'create po caption',
                defaultMessage: 'Create a PO'
            },
            service_caption: {
                id: 'service_caption',
                description: 'service caption',
                defaultMessage: 'Services'
            },
            schedule_caption: {
                id: 'schedule_caption',
                description: 'schedule caption',
                defaultMessage: 'Schedule'
            },
            due_caption: {
                id: 'due_caption',
                description: 'due caption',
                defaultMessage: 'Due'
            }
        });

        if (props.isFetchingDataModal) {
            return <Spinner />;
        }

        return (
            <div>
                <div className="my_services_due-service-line-items">
                    <div className="row my_services_due-service__close">
                        <div onClick={props.onCancelServiceModalClicked}>
                            <img className="my_services_due__close-button"
                                 src="/assets/VMS_33button_kill_blue.png"/>
                            <FormattedMessage {...messages.close_without_save_caption} />
                        </div>
                    </div>
                    <div className="row">
                        {getCaption()}
                    </div>
                </div>
                <div className="my_services_due_services-table-div">
                    <table className="my_services_due_services-table">
                        <tbody>
                        <tr className="my_services_due__list-header">
                            <td>
                                <table className="my_services_due__container">
                                    <tbody>
                                    <tr>
                                        <td className="my_services_due__inline-block my_services_due__list-name">
                                            <FormattedMessage {...messages.service_caption} />
                                        </td>
                                        <td className="my_services_due__inline-block my_services_due__list-schedule">
                                            <FormattedMessage {...messages.schedule_caption} />
                                        </td>
                                        <td className="my_services_due__inline-block my_services_due__list-due">
                                            <FormattedMessage {...messages.due_caption} />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        {props.services.valueSeq().map((k) =>
                            <tr key={k.get('serviceId')} className="my_services_due__list">
                                <td >
                                    <div className={classnames('my_services_due__list-row-item',
                                        {'my_services_due__list_overdue': (k.get('serviceStatus') == SERVICE_STATUS.OVERDUE || k.get('serviceStatus') == SERVICE_STATUS.DUE)}
                                    )}>
                                        <table className="my_services_due__container">
                                            <tbody>
                                            <tr>
                                                <td className="my_services_due__inline-block my_services_due__list-name">
                                                    {k.get('serviceName')}
                                                </td>
                                                <td className="my_services_due__inline-block my_services_due__list-schedule">
                                                    {k.get('serviceSchedule')}
                                                </td>
                                                <td className="my_services_due__inline-block my_services_due__list-due">
                                                    {k.get('serviceOverDueBy')}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>

                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    {["CR","ED"].some(p => appState.poPermissions.includes(p))?
                    <div className="my_services_due__save">
                        <Submit
                            caption={<FormattedMessage {...messages.create_po_caption}/>}
                            imagePath="/assets/VMS_33button_add_blue.png"
                            onClickHandler={props.onCreatePOClick}
                        />
                    </div>:false}
                </div>
            </div>
        );
    }


export default (MyServicesDueModal);