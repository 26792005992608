// #region Imports
import React, { useState,useEffect } from "react";
import { Modal, Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage} from 'react-intl';
import Spinner from "components/Spinner";
import styled from "styled-components";
import FormWrapper from "components/FormWrapper";

// #endregion

// #region Stlyed Components
export const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;	
`;
// #endregion

// #region Component
const DeleteUserModal = ({
  show,
  onOKClickEvent,
  onCancelClickEvent,
  FormattedMessageTitle,
  FormattedMessageBody,
  showCancelButton,
  FormattedMessageCancelButtonText,
  FormattedMessageOkButtonText,
  isFetchingData,
  isFetchingDataAll
}) => {

// #region Event handlers
    function  handleOKClick() {
        if (onOKClickEvent != undefined && onOKClickEvent != null)
            onOKClickEvent();
    }

    function handleCancelClick() {
      
        if (onCancelClickEvent != undefined && onCancelClickEvent != null)
            onCancelClickEvent();
    }
    // #endregion

    // #region Render
    return (<div>
        <Modal
            show={show}
            dialogClassName="custom-modal">
            <Modal.Body>
                <Grid>
                    <Row>
                        <Col md={6} lg={6}>
                            <span className="confirm_message_box__header">
                                {
                                    <FormattedMessage
                                        id={FormattedMessageTitle.id}
                                        description={FormattedMessageTitle.description}
                                        defaultMessage={FormattedMessageTitle.defaultMessage}
                                    />
                                }
                            </span>
                        </Col>
                    </Row>
                    <Row><Col>&nbsp;</Col></Row>
                    {isFetchingData || isFetchingDataAll?

                        <SpinnerContainer><Spinner spinnerType="dark-blue" /></SpinnerContainer>
                        : <div>
                            <Row>
                                <Col md={6} lg={6}>
                                    <div className="confirm_message_box__message">
                                        {
                                            <FormattedMessage
                                                id={FormattedMessageBody.id}
                                                description={FormattedMessageBody.description}
                                                defaultMessage={FormattedMessageBody.defaultMessage}
                                            />
                                        }
                                    </div>

                                </Col>
                            </Row>
                            <Row><Col>&nbsp;</Col></Row>
                            <Row>
                                <Col md={3}>
                                    <div className="confirm_message_box__links-no">
                                        {showCancelButton == true ?
                                            <a onClick={() => { handleCancelClick(); }}>
                                                <img src="/assets/VMS_33button_kill_blue.png" />{' '}
                                                {showCancelButton == true ?
                                                    <FormattedMessage
                                                        id={FormattedMessageCancelButtonText.id}
                                                        description={FormattedMessageCancelButtonText.description}
                                                        defaultMessage={FormattedMessageCancelButtonText.defaultMessage}
                                                    /> : false
                                                }
                                            </a>
                                            : false}
                                    </div>
                                </Col>
                                &nbsp;&nbsp;
                            <Col md={3}>
                                    <div className="confirm_message_box__links-yes">
                                        <a onClick={() => { handleOKClick(); }}>
                                            {
                                                <FormattedMessage
                                                    id={FormattedMessageOkButtonText.id}
                                                    description={FormattedMessageOkButtonText.description}
                                                    defaultMessage={FormattedMessageOkButtonText.defaultMessage}
                                                />
                                            }
                                            {' '} <img src="/assets/VMS_33button_go_bblue.png" />
                                        </a>

                                    </div>

                                </Col>
                            </Row>
                        </div>
                    }
                    <Row><Col>&nbsp;</Col></Row>
                </Grid>
            </Modal.Body>
        </Modal>
        </div> );
    // #endregion
};
// #endregion
   
export default DeleteUserModal;