import * as constants from 'constants/App';
import { Map, fromJS } from 'immutable';
import moment from 'moment';
import fraInspectionComponentData from '../data/FraInspectionComponentData';
import * as fraInspectionEnums from 'PO/ClosePO/screens/FraInspection/data/FraInspectionEnums';

const componentDetails = fraInspectionComponentData;

const initialUiData = Map();
const defaultUiData = Map({
    showWindow: false,
    formData: Map({
        hasInspectionPassed: null,        
		inspectorAttested: false,
		inspectorSignature: '',                		
		inspectionDate: null,
		carrierName: '',
		unitNumber: '',
		year: '',
		make: '',
		licensePlate: '',
		railGearModel: '',
		railGearFrontSerialNumber: '',
		railGearRearSerialNumber: '',
		guideWheelLoadLeftFrontCheckOk: false,
		guideWheelLoadRightFrontCheckOk: false,
		guideWheelLoadLeftRearCheckOk: false,
		guideWheelLoadRightRearCheckOk: false,
		isAllOk: false
    }),
	formValidationMessages: Map(),
	componentDetails: Map(),
	shouldSkipFailOptions: false, 
});
const initialServerData = Map();

export function uiDataFraInspection(state = initialUiData, action) {    
    switch (action.type) {
		case constants.FRA_INSPECTION__DISPLAY:		
			let stateTemp = defaultUiData.set('showWindow', action.show);
            return stateTemp;		

        case constants.FRA_INSPECTION__RESET:
            return defaultUiData;
		case constants.FRA_INSPECTION__RESET_ERROR:
			return state.set('errorMessage', undefined);
		case constants.FRA_INSPECTION__SET_DEFAULT_VALUES:			
			return state
				.setIn(['formData', 'carrierName'], action.carrierName)
				.setIn(['formData', 'unitNumber'], action.unitNumber)
				.setIn(['formData', 'year'], action.year)
				.setIn(['formData', 'make'], action.make)
				.setIn(['formData', 'licensePlate'], action.licensePlate)
				.setIn(['componentDetails'], fromJS(componentDetails));					
			
		case constants.FRA_INSPECTION__SET_FORM_DATA: {
			let newState = state.setIn(['formData', action.field], action.value);

			if (action.field === 'isAllOk' && action.value === true) {
				newState = newState.set('componentDetails', state.get('componentDetails').map((component) => (
					component.set('componentResultCode', fraInspectionEnums.componentResultCode.PASS)
				)))
				.setIn(['formData', 'guideWheelLoadLeftFrontCheckOk'], true)
				.setIn(['formData', 'guideWheelLoadRightFrontCheckOk'], true)
				.setIn(['formData', 'guideWheelLoadLeftRearCheckOk'], true)
				.setIn(['formData', 'guideWheelLoadRightRearCheckOk'], true);
			}
			
			return newState;
		}
        case constants.FRA_INSPECTION__SET_VALIDATION_MESSAGE:
            return state.setIn(['formValidationMessages', action.field], action.message);
        case constants.FRA_INSPECTION__RESET_VALIDATION_MESSAGES:
            return state.set('formValidationMessages', Map());

		case constants.FRA_INSPECTION__SET_COMPONENT_DETAILS: {
			let newState = state.setIn(['componentDetails', action.componentId, action.field], action.value);

			if (action.value === false) {
				newState = newState.setIn(['formData', 'isAllOk'], false);
			}

			if (action.componentId == 204 && action.value === true) //user clicked PASS for guide wheel load				
			{
				newState = newState
					.setIn(['formData', 'guideWheelLoadLeftFrontCheckOk'], true)
					.setIn(['formData', 'guideWheelLoadRightFrontCheckOk'], true)
					.setIn(['formData', 'guideWheelLoadLeftRearCheckOk'], true)
					.setIn(['formData', 'guideWheelLoadRightRearCheckOk'], true);
			}

			return newState;
		}
		case constants.FRA_INSPECTION__SKIP_FAIL_OPTIONS:
			return state.set('shouldSkipFailOptions', true);

		case constants.FRA_INSPECTION__SAVE_CONFIRMATION_REQUESTED:
			return state
				.set('errorMessage', undefined)
				.set('isSaving', true);
		case constants.FRA_INSPECTION__SAVE_CONFIRMATION_REJECTED:
			return state
				.set('errorMessage', action.errorMessage)
				.set('isSaving', false);
		case constants.FRA_INSPECTION__SAVE_CONFIRMATION_RESOLVED:
			return state.set('isSaving', false);

		case constants.FRA_INSPECTION__SAVE_FORM_REQUESTED:
			return state
				.set('errorMessage', undefined)
				.set('isSaving', true);
		case constants.FRA_INSPECTION__SAVE_FORM_REJECTED:
			return state
				.set('errorMessage', action.errorMessage)
				.set('isSaving', false);
		case constants.FRA_INSPECTION__SAVE_FORM_RESOLVED:
			return state.set('isSaving', false);

        default:
            return state;
    }
}

export function serverDataFraInspection(state = initialServerData, action) {
    switch (action.type) {                               
        default:
            return state;
    }
}