import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import './VehicleInformation.css';
import LinkToUrl from 'components/LinkToUrl';
import * as HelperFunctions from 'utils/HelperFunctions';
import * as constants from 'constants/App';
import { Link } from 'react-router-dom';
import configuration from 'configuration';
import * as sharedActions from 'actions/App';
import { falseIfUndefined, emptyStringIfUndefined } from 'utils/HelperFunctions';

class VehicleInformation extends React.Component {

    downLoadPMInformation = (e) => {        
        let pmUrl = `${configuration.serviceURL}v1/Vehicles/${this.props.vehicleDetails.get('id')}/pmInformation?reportType=Pdf&token=${this.props.token}`;
        this.props.dispatch(sharedActions.downLoadRemoteFile('pmInformation', pmUrl,this.props.selectedLocale));
    }

    render() {
        //Get data from app state (reducer store)
        var firstOfVin8 = '';
        var lastOfVin8 = '';
        /* this split is to highlight the last 8 chars of vin number as vin8 */
        var vehicleDetails = this.props.vehicleDetails.toJSON();
        var vinNumber = vehicleDetails.vin;
        if (vinNumber != undefined) {
            lastOfVin8 = vinNumber.slice(9, vinNumber.length);
            firstOfVin8 = vinNumber.slice(0, 9);
        }
        
        //check to display linked vendor ID and address when a corporate vendor is searching and viewing its linked accounts PO's
        var corporateLoggedIn = this.props.vendorAccountType === "Master"  && this.props.loggedInVendorId !== this.props.poVendorId;
        
        const messages = defineMessages({
            vehicle_details_vin_no: {
                id: 'vehicle_details_vin_no',
                description: 'VIN',
                defaultMessage: 'VIN'
            },
            vehicle_details_client: {
                id: 'vehicle_details_client',
                description: 'Client',
                defaultMessage: 'Client'
            },
            vehicle_details_ari_unit_no: {
                id: 'vehicle_details_ari_unit_no',
                description: 'Holman unit no.',
                defaultMessage: 'Holman unit no.'
            },
            vehicle_details_engin_type: {
                id: 'vehicle_details_engin_type',
                description: 'Engine type',
                defaultMessage: 'Engine type'
            },
            vehicle_details_engin_hours: {
                id: 'vehicle_details_engin_hours',
                description: 'Hour meter',
                defaultMessage: 'Hour meter'
            },
            vehicle_details_vehicle: {
                id: 'vehicle_details_vehicle',
                description: 'Vehicle',
                defaultMessage: 'Vehicle'
            },
            vehicle_details_plate: {
                id: 'vehicle_details_plate',
                description: 'Plate',
                defaultMessage: 'Plate'
            },
            vehicle_details_color: {
                id: 'vehicle_details_color',
                description: 'Color',
                defaultMessage: 'Color'
            },
            vehicle_details_mileage: {
                id: 'vehicle_details_mileage',
                description: 'Mileage',
                defaultMessage: 'Mileage'
            },
            vehicle_details_other: {
                id: 'vehicle_details_other',
                description: 'Other',
                defaultMessage: 'Other'
            },

            vehicle_details_documents: {
                id: 'vehicle_details_documents',
                description: 'Vehicle Documents',
                defaultMessage: 'Vehicle Documents'
            },
            vehicle_details_parameters: {
                id: 'vehicle_details_parameters',
                description: 'Client Documents',
                defaultMessage: 'Client Documents'
            },
            vehicle_details_po_number: {
                id: 'vehicle_details_po_number',
                description: 'PO no.',
                defaultMessage: 'PO no.'
            },
            vehicle_details_unavailable: {
                id: 'vehicle_details_unavailable',
                description: 'Unavailable',
                defaultMessage: 'Unavailable'
            },
            vehicle_documents: {
                id: 'vehicle_documents',
                description: 'Vehicle Documents',
                defaultMessage: 'Vehicle Documents'
            },
            repair_history_of_vehicle: {
                id: 'repair_history_of_vehicle',
                description: 'Repair History of Vehicle',
                defaultMessage: 'Repair History of Vehicle'
            },
            client_parameters_of_vehicle: {
                id: 'client_parameters_of_vehicle',
                description: 'client general and vehicle parameters info',
                defaultMessage: 'Client Parameters'
            },
            pm_sheets: {
                id: 'pm_sheets',
                description: 'PM information pdf ',
                defaultMessage: 'PM Information'
            },
            ari_issued_id: {
                id: 'ari_issued_id',
                description: 'Holman Issued ID',
                defaultMessage: 'Holman Issued ID'
            },
            vendor_location: {
                id: 'vendor_location',
                description: 'Location',
                defaultMessage: 'Location'
            }
        });

        return <div className="row">
            <div className="col-md-12 vehicle_info_col_label__left_padding">
                <div className="col-sm-6">
                    {this.props.actionType == 'CLOSE' || this.props.actionType == 'EDIT' ? false
                        :
                        <div className={classNames('row',
                            { 'vehicle_info_col__hide': (vehicleDetails.PONumber == undefined) },
                            { 'vehicle_info_col__show': (vehicleDetails.PONumber != undefined) })
                        }>
                            <div className="col-sm-5 vehicle_info_col__label">
                                <FormattedMessage {...messages.vehicle_details_po_number} />
                            </div>
                            <div className="col-sm-7 vehicle_info_col__val">
                                {vehicleDetails.PONumber > 0 || vehicleDetails.PONumber != "" || vehicleDetails.PONumber != undefined ? vehicleDetails.PONumber :
                                    <FormattedMessage {...messages.vehicle_details_unavailable} />}
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-sm-5 vehicle_info_col__label">
                            <FormattedMessage {...messages.vehicle_details_vin_no} />
                        </div>
                        <div className="col-sm-7 vehicle_info_col__val">{firstOfVin8}
                            <span className="vehicle_info_col__val_highlight">{lastOfVin8}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5 vehicle_info_col__label">
                            <FormattedMessage {...messages.vehicle_details_client} />
                        </div>
                        <div className="col-sm-7 vehicle_info_col__val">
                            {
                                (vehicleDetails.clientId != undefined && vehicleDetails.clientName != undefined) ?
                                    <div> {vehicleDetails.clientName} {' ('}
                                        {vehicleDetails.clientId} {')'}
                                    </div>
                                    : false
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5 vehicle_info_col__label">
                            <FormattedMessage {...messages.vehicle_details_ari_unit_no} />
                        </div>
                        <div className="col-sm-7 vehicle_info_col__val">
                            {vehicleDetails.ariUnitNo}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5 vehicle_info_col__label">
                            <FormattedMessage {...messages.vehicle_details_engin_type} />
                        </div>
                        <div className="col-sm-7 vehicle_info_col__val">
                            {vehicleDetails.engineType == null || vehicleDetails.engineType == ''
                                ? <FormattedMessage {...messages.vehicle_details_unavailable} />
                                : vehicleDetails.engineType}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5 vehicle_info_col__label">
                            <FormattedMessage {...messages.vehicle_details_engin_hours} />
                        </div>
                        <div className="col-sm-7 vehicle_info_col__val">
                            {vehicleDetails.lastHourMeterReading == undefined || vehicleDetails.lastHourMeterReading == ''
                                ? <FormattedMessage {...messages.vehicle_details_unavailable} />
                                : vehicleDetails.lastHourMeterReading}
                        </div>
                    </div>
                    {corporateLoggedIn && this.props.poVendorId != null && this.props.poVendorId != "" && this.props.loggedInVendorId != ""?
                        <div className="row">
                            <div className="col-sm-5 vehicle_info_col__label">
                            <FormattedMessage {...messages.ari_issued_id} />
                        </div>
                            <div className="col-sm-7 vehicle_info_col__val">
                                {this.props.poVendorId}
                            </div>
                        </div>
                        : false}
                </div>
                <div className="col-sm-6 vehicle_info_col__spacing">
                    <div className="row">
                        <div className="col-sm-5 vehicle_info_col__label">
                            <FormattedMessage {...messages.vehicle_details_vehicle} />
                        </div>
                        <div className="col-sm-7 vehicle_info_col__val">
                            {vehicleDetails.year} {vehicleDetails.make} {vehicleDetails.model}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5 vehicle_info_col__label">
                            <FormattedMessage {...messages.vehicle_details_plate} />
                        </div>
                        <div className="col-sm-7 vehicle_info_col__val">
                            {
                                vehicleDetails.licensePlate == undefined || vehicleDetails.licensePlate == ''
                                    ? <FormattedMessage {...messages.vehicle_details_unavailable} />
                                    : vehicleDetails.licensePlate}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5 vehicle_info_col__label">
                            <FormattedMessage {...messages.vehicle_details_color} />
                        </div>
                        <div className="col-sm-7 vehicle_info_col__val">
                            {
                                vehicleDetails.color == null || vehicleDetails.color == ''
                                    ? <FormattedMessage {...messages.vehicle_details_unavailable} />
                                    : vehicleDetails.color}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5 vehicle_info_col__label">
                            <FormattedMessage {...messages.vehicle_details_mileage} />
                        </div>
                        <div className="col-sm-7 vehicle_info_col__val">
                            {vehicleDetails.lastOdometerReading == undefined || vehicleDetails.lastOdometerReading == '' ? <FormattedMessage {...messages.vehicle_details_unavailable} /> : vehicleDetails.lastOdometerReading}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5 vehicle_info_col__label">
                            <FormattedMessage {...messages.vehicle_details_other} />
                        </div>
                        <div className="col-sm-7 vehicle_info_col__val">
                             	<Link to={`/vehicle/${this.props.vehicleDetails.get('id')}/documents`}>
									<FormattedMessage {...messages.vehicle_details_documents} />
								</Link>                             
                            <div>
                                <Link to={'/po/repairHistory/' + this.props.vehicleDetails.get('id')}>
                                    <FormattedMessage {...messages.repair_history_of_vehicle} />
                                </Link>
                            </div>
                            <div>
                                <Link to={'/po/clientParameters/' + this.props.vehicleDetails.get('id')}>
                                    <FormattedMessage {...messages.client_parameters_of_vehicle} />
                                </Link>
                            </div>
                            <div>
                                <div className="vehicle_info__pm_info">
                                    <a className={this.props.isFetchingData ? 'vehicle_info_disable__pm_info' : false}
                                       onClick={this.downLoadPMInformation.bind(this)}>
                                        <FormattedMessage {...messages.pm_sheets} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {corporateLoggedIn && this.props.businessAddress != null && this.props.businessAddress != undefined ?
                                <div className="row">
                                    <div className="col-sm-5 vehicle_info_col__label">
                                    <FormattedMessage {...messages.vendor_location} />
                                     </div>
                                    <div className="col-sm-7 vehicle_info_col__val">
                                    {this.props.businessAddress.get('address1') + " | " + this.props.businessAddress.get('city') + ", " + this.props.businessAddress.get('state') + " " + this.props.businessAddress.get('zipCode')}
                                    </div>
                                </div>
                                : false}
                </div>
            </div>
        </div>;
    }
}

function select(state,props) {

    return {
        token: state.appState.getIn(['serverData', 'shared', 'token']),
        isFetchingData: falseIfUndefined(state.appState.getIn(['uiData', 'shared', 'componentsFetchingDataFromAPI', 'pmInformation']) == 'pmInformation'),
        errorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage']),
        selectedLocale: state.appState.getIn(['uiData', 'shared', 'selectedLocale']),
        vendorAccountType: state.appState.getIn(['serverData', 'shared', 'vendorAccountType']),
        poVendorId: props.actionType != undefined ? (props.actionType.toUpperCase() === "VIEW" ? emptyStringIfUndefined(state.appState.getIn(['serverData','view','vendor','id'])) : props.actionType.toUpperCase() === "ADD" || props.actionType.toUpperCase() === "EDIT" ? emptyStringIfUndefined(state.appState.getIn(['uiData', 'addEdit','vendor','id'])): "") : "",
        businessAddress:  props.actionType != undefined ? ( props.actionType.toUpperCase() === "VIEW" ? state.appState.getIn(['serverData', 'view','vendor','businessAddress']) : props.actionType.toUpperCase() === "ADD" || props.actionType.toUpperCase() === "EDIT" ? state.appState.getIn(['uiData', 'addEdit','vendor','businessAddress']) : null) : null ,
        loggedInVendorId: state.appState.getIn(['serverData', 'shared','vendorId'])  
    };
}


export default connect(select)(VehicleInformation);
