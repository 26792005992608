import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import ServiceLineItem from './ServiceLineItem';
import './ServiceLineItems.css';

class ServiceLineItems extends React.Component {

    getHeader() {
        const messages = defineMessages({
            service_header_caption: {
                id: 'service_header_caption',
                description: 'Service ',
                defaultMessage: 'Service ',
            },
            total_part_header_caption: {
                id: 'total_part_header_caption',
                description: 'Parts',
                defaultMessage: 'Parts',
            },
            total_labor_header_caption: {
                id: 'total_labor_header_caption',
                description: 'Labor',
                defaultMessage: 'Labor',
            },
            total_amount_header_caption: {
                id: 'total_amount_header_caption',
                description: 'Total',
                defaultMessage: 'Total',
            },
        });
        return <tr className="service-line-items__row service-line-items__header">
            <th className="service-line-items__description_header">
                <FormattedMessage {...messages.service_header_caption} />
            </th>
            <th className="service-line-items__cost_header">
                <FormattedMessage {...messages.total_part_header_caption} />
            </th>
            <th className="service-line-items__cost_header">
                <FormattedMessage {...messages.total_labor_header_caption} />
            </th>
            <th className="service-line-items__total_header">
                <FormattedMessage {...messages.total_amount_header_caption} />
            </th>
        </tr>;
    }

    getRows() {
        return this.props.serviceATAList.valueSeq().map((item)=>
                <ServiceLineItem ataCode={item}
                                 handleChange={this.props.handleChange}
                                 key={item.get('productCode')}
                />
        )
    }

    render() {
        return (
            <div className="table-responsive">
                <table>
                    <thead>
                    {this.getHeader()}
                    </thead>
                    <tbody>
                    {this.getRows()}
                    </tbody>
                </table>

            </div>
        );
    }
}
;


export default ServiceLineItems;

