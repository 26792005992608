// #region Imports
import React, { useEffect, useState, useCallback } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import Submit from '../../../../../../shared/components/Submit';
import './TiresStepAddLine.css';
import ClientParametersComponent from 'components/ClientParametersComponent';
import { connect } from "react-redux";
import AddNewLineItemsSelector from '../selectors/AddNewLineItemsView';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import TireSpecifications from 'TireSpecs/TireSpecifications';
import TireDetails from 'TireSpecs/TireDetails';
import VehicleFrame from 'TireSpecs/VehicleFrame';
import { Map } from 'immutable';
// #endregion

// #region Styled Components
// #endregion

// #region Component
const TiresStepAddLine = (props) => {

    // #region State
    const [showTireValidationError, setShowTireValidationError] = useState(false);
    // #endregion

    // #region Ref
    // #endregion  

    // #region Callback
    // #endregion  

    // #region Event handlers
    const validateTireDetails = () => {
        const errorMessages = defineMessages({
            tire_width_required: {
                id: 'tire_width_required',
                description: 'Please enter Tire width',
                defaultMessage: 'Please enter Tire width'
            },
            aspect_ratio_required: {
                id: 'aspect_ratio_required',
                description: 'Please enter Aspect ratio',
                defaultMessage: 'Please enter Aspect ratio'
            },
            rim_diameter_required: {
                id: 'rim_diameter_required',
                description: 'Please enter Rim diameter',
                defaultMessage: 'Please enter Rim diameter'
            },
            tire_manufacturer_required: {
                id: 'rim_diameter_required',
                description: 'Please select Tire manufacturer',
                defaultMessage: 'Please select Tire manufacturer'
            },
            tire_model_required: {
                id: 'tire_model_required',
                description: 'Please enter Tire model',
                defaultMessage: 'Please enter Tire model'
            },
            tire_reason_required: {
                id: 'tire_reason_required',
                description: 'Please select a reason for replacement',
                defaultMessage: 'Please select a reason for replacement'
            },
            old_tire_tread_depth_required: {
                id: 'old_tire_tread_depth_required',
                description: 'Please enter the old tire tread depth for the replaced tire.',
                defaultMessage: 'Please enter the old tire tread depth for the replaced tire.'

            },
            qty_must_match_with_tire_selection_counter: {
                id: 'qty_must_match_with_tire_selection_counter',
                description: 'Number of tires selected for replacement does not match the quantity defined in the line item. Please click return and correct the quantity.',
                defaultMessage: 'You must CLICK ON THE TIRE(s) in the diagram that corresponds to the position you are replacing. The tire will turn blue once you have clicked on it. Press RETURN to correct your entry.'

            },
            qty_must_match_with_tire_selection_counter_custom_message: {
                id: 'qty_must_match_with_tire_selection_counter',
                description: 'Number of tires selected for replacement does not match the quantity defined in the line item. Please select ',
                defaultMessage: 'Number of tires selected for replacement does not match the quantity defined in the line item. Please select '
            },
            qty_must_match_end_message: {
                id: 'qty_must_match_end_message',
                description: ' tire(s).',
                defaultMessage: ' tire(s).'
            }
        })

        let isFormValid = true;
        let validationErrs;
        const tireDetails = props.tireDetails;
        const fieldsToValidate = [
            { name: constants.TIRE_DETAILS.TIRE_WIDTH, propName: 'tireWidth', errMessage: errorMessages.tire_width_required },
            { name: constants.TIRE_DETAILS.ASPECT_RATIO, propName: 'tireAspectRatio', errMessage: errorMessages.aspect_ratio_required },
            { name: constants.TIRE_DETAILS.RIM_DIAMETER, propName: 'tireRimDiameter', errMessage: errorMessages.rim_diameter_required },
            { name: constants.TIRE_DETAILS.MANUFACTURER, propName: 'defaultManufacturer', errMessage: errorMessages.tire_manufacturer_required },
            { name: constants.TIRE_DETAILS.MODEL, propName: 'defaultModel', errMessage: errorMessages.tire_model_required },
            { name: constants.TIRE_DETAILS.REASON, propName: 'defaultReason', errMessage: errorMessages.tire_reason_required },
        ];


        if (props.anyTireSelected) {
            for (var index = 0; index < fieldsToValidate.length; index++) {
                var element = fieldsToValidate[index];
                if (!tireDetails[element.propName]) {
                    isFormValid = false;
                    validationErrs = {
                        name: element.name,
                        value: element.errMessage
                    }
                    props.dispatch({ type: constants.UPDATE_TIRE_DETAILS_VALIDATION_ERRS, validationErrs })
                }
            }

            for (var index = 0; index < props.selectedTires.length; index++) {
                var tire = props.selectedTires[index];
                if (!tire.treadDepth) {
                    isFormValid = false;
                    validationErrs = {
                        name: tire.tempId,
                        value: errorMessages.old_tire_tread_depth_required
                    }
                    props.dispatch({ type: constants.UPDATE_TIRE_DETAILS_VALIDATION_ERRS, validationErrs })
                }
            }
        }

        const isCorrectionTypeReplace = props.correctionType == constants.CORRECTION_TYPE.REPLACE;
        const isLineItemTypePart = !props.currentLineItemType ? true : props.currentLineItemType == constants.LINE_ITEM_TYPE.PART;
        if (!props.tiresSelectedMatchesWithQty && isCorrectionTypeReplace && isLineItemTypePart) {

            isFormValid = false;
            let errorMessage = [];
            if (props.showTiresInViewEdit)
                errorMessage = [{
                    message: errorMessages.qty_must_match_with_tire_selection_counter_custom_message.defaultMessage
                        + props.currentLineItemQty
                        + errorMessages.qty_must_match_end_message.defaultMessage
                }]
            else
                errorMessage = [{ message: errorMessages.qty_must_match_with_tire_selection_counter.defaultMessage }]

            props.dispatch({ type: constants.ADD_LINE_ITEM_QTY_MISMATCH_ERR, errorMessage })
        }


        return isFormValid;
    }

    /*Adds line item data to PO and save Tire details*/
    const onAddLineItemToPOHandler = () => {

        if (!validateTireDetails())
            setShowTireValidationError(true);
        else {
            setShowTireValidationError(false);

            // Let parent know that the current step is done
            props.onContinue();
        }
    }

    /* Continue link clicked */
    const onContinueClickHandler = () => {
        props.onContinue();
    }

    /*Close window*/
    const onCloseWindowHandler = () => {
        props.onClose();
    }

    const onChangeTireDetailsHandler = (inputType, inputValue) => {

        const tireDetail = {
            type: inputType,
            inputValue: inputValue
        }
        props.dispatch({ type: constants.UPDATE_TIRE_DETAILS, tireDetail: tireDetail });
    }

    const onRemoveTireDetailsValidationErrorHandler = (fieldName) => {
        props.dispatch({ type: constants.REMOVE_TIRE_DETAILS_VALIDATION_ERROR, fieldName })
    }

    const onAddWheelHandler = (axlePosition) => {
        let axles = props.rearAxles;
        let axle = axles.find(x => x.axlePosition === axlePosition);
        const unit = props.defaultTreadDepthUnit;

        if (!axle)
            return;

        const dataForNewWheel = {
            unit: unit,
            axlePosition: axlePosition
        }

        props.dispatch({ type: constants.ADD_NEW_WHEEL, dataForNewWheel: dataForNewWheel })
    }

    const onRemoveAxleHandler = () => {
        let currentAxles = props.axles;
        const axleToRemove = props.axleCountForSelectedLineItem;

        if (axleToRemove > 1) {
            props.dispatch({ type: constants.REMOVE_AXLE, axleToRemove: axleToRemove })
        }
    }

    const onAddAxleHandler = () => {
        const currentNumberOfAxles = props.axleCountForSelectedLineItem + 1;
        let currentAxles = props.axles;
        const maxAxles = props.maxAxlesAllowed;
        const unit = props.defaultTreadDepthUnit;

        let newAxles = [];

        if (currentNumberOfAxles < maxAxles) {
            const dataForNewWheel = {
                unit: unit,
                axlePosition: currentNumberOfAxles
            }

            props.dispatch({ type: constants.ADD_NEW_AXLE, dataForNewWheel: dataForNewWheel })
        }
    }

    const onRemoveBrakeAxleHandler = () => {
        let brakeAxles = props.brakesInfo.brakeAxlesFromState;
        const currentNumberOfAxles = props.brakesInfo.brakeAxleCountForSelectedLineItem;

        if (currentNumberOfAxles > 1) {
            brakeAxles = brakeAxles.filter(x => !(x.axleNumber == currentNumberOfAxles && x.component == props.brakesInfo.componentType));
            props.dispatch({ type: constants.ADD_NEW_BRAKE_AXLE, dataForNewBrake: brakeAxles })
        }
    }

    const onSelectTireHandler = (axlePosition, wheelPosition, id) => {
        props.dispatch({ type: constants.UPDATE_TIRE_SELECTION, tireIdToBeUpdated: id })
    }

    const onChangeTreadDepthHandler = (isTireNew, tireId, value) => {
        const treadDepthData = {
            tireId: tireId,
            isTireNew: isTireNew === constants.NEW_TREAD_DEPTH,
            treadDepthValue: value
        }

        props.dispatch({ type: constants.UPDATE_TREAD_DEPTH, treadDepthData: treadDepthData })
    }

    const onChangeMeasurementUnitHandler = (value) => {
        props.dispatch({ type: constants.UPDATE_TREAD_DEPTH_MEASUREMENT_UNIT, defaultTreadDepthUnit: value })
    }

    // #endregion  

    // #region Effects
    // #endregion

    // #region International messages  
    const messages = defineMessages({
        close_dialog: {
            id: 'close_dialog',
            description: '  Close',
            defaultMessage: 'Close'
        },
        add_line_item_to_po: {
            id: 'add_line_item_to_po',
            description: 'Add line item to PO ',
            defaultMessage: 'Add line item to PO '
        },
        parameters_header: {
            id: 'parameters_header',
            description: 'Header for ata parameters ',
            defaultMessage: 'Parameters '
        },
        empty_parameters: {
            id: 'empty_parameters',
            description: 'Description when parameters are empty',
            defaultMessage: 'None at this time'
        },
        add_new_line_item_caption: {
            id: 'add_new_line_item_caption',
            description: 'Add new line item caption',
            defaultMessage: 'Add {complaint_description} items'
        },
        continue_link: {
            id: 'continue_link',
            description: 'Continue',
            defaultMessage: 'Continue'
        },
        return: {
            id: 'return',
            description: 'Return',
            defaultMessage: 'Return'
        },
        items_added: {
            id: 'items_added',
            description: 'items_added',
            defaultMessage: 'Items added to PO:'
        },
        tires_replaced: {
            id: 'tires_replaced',
            description: 'tires_replaced',
            defaultMessage: 'Click on the Tires being replaced'
        },
        tires_tread: {
            id: 'tires_tread',
            description: 'tires_tread',
            defaultMessage: 'Tires tread depth'
        },
        new_tires: {
            id: 'new_tires',
            description: 'new_tires',
            defaultMessage: 'New Tire Details'
        },
    });
    // #endregion

    // #region variables
    const tireDetailContainterWidthClass = 'add-new-line-items__vehicle-frame__20';
    const vehicleFrameContainerWidthClass = 'add-new-line-items__tire-details__80';
    const titleForTiresSelection = <FormattedMessage {...messages.tires_replaced} />;
    const titleWhenTiresNotSelectable = <FormattedMessage {...messages.tires_tread} />;
    const { formatMessage } = props.intl;
    const canAddAxle = !props.rearAxles || props.rearAxles.length < 8;
    const canRemoveAxle = !!props.rearAxles && props.rearAxles.length > 1;

    let productCodeParams = props.ataParametersSorted == undefined ? '' :
        props.ataParametersSorted.map((param) => {
            return <ClientParametersComponent key={param.get('id')}
                description={param.get('description')}
            />
        });

    const returnLink = <div className=""
        role="button"
        onClick={() => props.onReturn()}>
        <img src="/assets/VMS_33button_back_blue.png" />
        {' '}
        <FormattedMessage {...messages.return} />
    </div>

    const addLineItemToPoLink = <Submit
        caption={<FormattedMessage {...messages.add_line_item_to_po} />}
        imagePath="/assets/VMS_33button_add_blue.png"
        onClickHandler={onAddLineItemToPOHandler}
        showSpinner={props.isCreatingLineItems}
        spinnerType="blue" />

    const continueLink = <Submit
        caption={<FormattedMessage {...messages.continue_link} />}
        imagePath="/assets/VMS_33button_add_blue.png"
        onClickHandler={onContinueClickHandler}
        spinnerType="blue" />
    //#endregion    

    // #region Render
    return (
        <div>
            <FormWrapper key='lineItemQtyMismatchErrMessage'
                id="lineItemQtyMismatchErrMessage" {...props}
                formErrors={props.lineItemQtyMismatchErrMessage} />
            <div className="add-new-line-items">
                <div className="row add-new-line-items__close">
                    <div onClick={onCloseWindowHandler}>
                        <img className="add-new-line-items__close-button"
                            src="/assets/VMS_33button_kill_blue.png" />
                        {"  "} {<FormattedMessage {...messages.close_dialog} />}
                    </div>
                </div>

                <div className='add-new-line-items_step-1-2-3-container'>
                    <div className="row add-new-line-items__caption">
                        <div>{props.complaintCode.get('id') !== undefined ?
                            <FormattedMessage
                                values={{
                                    complaint_description:
                                        <FormattedMessage
                                            id={props.complaintCode.get('id')}
                                            description={props.complaintCode.get('description')}
                                            defaultMessage={props.complaintCode.get('description')} />
                                }}
                                {...messages.add_new_line_item_caption} />
                            : false}
                        </div>
                    </div>

                    {props.itemsAddedToPo != undefined && props.itemsAddedToPo.size > 0 ?
                        <div className="add-new-line-items__recenly-added-ata-codes">
                            <div className="add-new-line-items__recenly-added-ata-codes-header"><FormattedMessage {...messages.items_added} /></div>
                            {
                                props.itemsAddedToPo.map(item => {
                                    return <div>{item.get('productDescription')}</div>;
                                }).valueSeq()}
                        </div>
                        : false
                    }

                    <div className="add-new-line-items__step-4-5-container">
                        <div key='div-container-spec'>
                            <TireSpecifications>
                                <div className={classnames('tire-specification', !props.showTireManufacturingDetails ? 'space-around' : false)}>
                                    <div className={vehicleFrameContainerWidthClass}>
                                        <VehicleFrame 
                                            stepNumber={props.stepNumber}
                                            title={props.showTireManufacturingDetails ? props.readOnly ? titleWhenTiresNotSelectable : titleForTiresSelection : titleWhenTiresNotSelectable}
                                            frontAxle={props.frontAxle}
                                            rearAxles={props.rearAxles}
                                            spareWheel={props.spareWheel}
                                            onAddWheel={onAddWheelHandler}
                                            onRemoveWheel={onRemoveAxleHandler}
                                            onAddAxle={onAddAxleHandler}
                                            canAddAxle={canAddAxle}
                                            onRemoveAxle={onRemoveBrakeAxleHandler}
                                            canRemoveAxle={canRemoveAxle}
                                            onChangeTreadDepth={onChangeTreadDepthHandler}
                                            onSelectTire={onSelectTireHandler}
                                            onChangeMeasurementUnit={onChangeMeasurementUnitHandler}
                                            defaultTreadDepthUnit={props.defaultTreadDepthUnit}
                                            showTireValidationError={showTireValidationError}
                                            readOnly={false}
                                            canShowUnitChangeConfirmation={props.tireSpecifications != undefined && props.tireSpecifications.size > 0}
                                        />
                                    </div>
                                    {
                                        props.showTireManufacturingDetails ?
                                            <div td className={tireDetailContainterWidthClass}>
                                                {
                                                    <TireDetails title={formatMessage(messages.new_tires)}
                                                        exampleText={'eg. 195/65R15.5'}
                                                        manufacturers={props.tireDetails.allManufacturers}
                                                        reasons={props.tireDetails.allReasons}
                                                        defaultManufacturer={props.tireDetails.defaultManufacturer}
                                                        defaultModel={props.tireDetails.defaultModel}
                                                        defaultReason={props.tireDetails.defaultReason}
                                                        tireWidth={props.tireDetails.tireWidth}
                                                        tireAspectRatio={props.tireDetails.tireAspectRatio}
                                                        tireRimDiameter={props.tireDetails.tireRimDiameter}
                                                        onChangeDetails={onChangeTireDetailsHandler}
                                                        validationErrors={props.tireDetailsValidationErrs}
                                                        onRemoveTireDetailsValidationErr={onRemoveTireDetailsValidationErrorHandler}
                                                    />
                                                }
                                            </div>
                                            : false
                                    }

                                </div>
                            </TireSpecifications></div></div>

                </div>
                <div className="row">
                    <div className={classnames('col-sm-5 submit add-new-line-items__return')}>
                        {
                            returnLink
                        }
                    </div>

                    <div className={classnames('col-sm-7 add-new-line-items__save',
                        !props.enableAddLineItemToPo || props.isCreatingLineItems ? 'add-new-line-items__save__disabled' : false)}>
                        {
                            addLineItemToPoLink
                        }
                    </div>
                </div>
                <div className="row add-new-line-parameters">
                    <div className="add-new-line-parameters__header">
                        <FormattedMessage {...messages.parameters_header} />
                    </div>
                    <div>
                        {productCodeParams.size === 0 ?
                            <p className="add-new-line-empty__description">
                                <FormattedMessage {...messages.empty_parameters} />
                            </p>
                            :
                            <div> {productCodeParams} </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
// #endregion

function mapToStateProps(state, props) {
    return {
        ...AddNewLineItemsSelector(state, props),
        addNewLineItemsErrorMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'errorMessage']),
        lineItemQtyMismatchErrMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'lineItemQtyMismatchErr']) || [],
        tireDetailsValidationErrs: state.appState.getIn(['uiData', 'addNewLineItemsView', 'tireDetails', 'validationErrs']) || Map(),
        isSavingTireInfo: state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'isSaving']) || false,
        tireErrorMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'errorMessage'])
    }
}
export default connect(mapToStateProps)(injectIntl(TiresStepAddLine));