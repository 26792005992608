import React, { useEffect, useState, createRef } from 'react';
import { defineMessages, FormattedMessage, FormattedNumber, RawIntlProvider, IntlProvider, injectIntl } from 'react-intl';
import { Map } from 'immutable';
import classNames from 'classnames';
import { Popover, Overlay } from 'react-bootstrap';
import { LINE_ITEM_TYPE, LINE_ITEM_STATUS } from 'constants/App';
import './LineItem.css';
import Spinner from 'components/Spinner';
import { useAppState } from 'shared/AppState/app-state';
import DynamicNumber from 'react-dynamic-number';
import tireProductCodes from 'data/TireProductCodes';
import brakesProductCodes from 'data/BrakesProductCodes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const LineItem = (props) => {
    const [quantity, setQuantity] = useState('');
    const [unitPrice, setUnitPrice] = useState('');
    const [costDisplayRefs, setCostDisplayRefs] = useState([]);
    const [ appState, dispatch ] = useAppState();


    useEffect(() => {

        // add or remove refs
        // https://stackoverflow.com/questions/54633690/how-can-i-use-multiple-refs-for-an-array-of-elements-with-hooks
        setCostDisplayRefs(costDisplayRefs => (
          Array(props.lineItems.length).fill().map((_, i) => costDisplayRefs[i] || createRef())
        ));
      }, [props.lineItems.length]);

    const onDeleteLineItemHandler = (lineItemKey, event) => {
        event.preventDefault();
        props.onDeleteLineItemClick(lineItemKey)
    }

    const onEditLineItemHandler = (lineItemKey, quantityParam, unitPriceParam, event) => {
        event.preventDefault();
        props.onEditLineItemClick(lineItemKey)

        // Store current quantity and unitPrice in local state
        setQuantity(quantityParam)
        setUnitPrice(unitPriceParam)
    }

    const onUpdateLineItemHandler = (lineItemKey, event) => {
        event.preventDefault();

        const tempQuantity = props.actionType.toUpperCase() === 'DEALERDRAFT' ? 1 : Number(quantity).toFixed(2);
        const tempUnitPrice = Number(unitPrice).toFixed(2);

        let lineItem = Map({
            lineItemKey: lineItemKey,
            quantity: tempQuantity,
            unitPrice: tempUnitPrice,
            totalAmount: tempQuantity * tempUnitPrice
        });

        props.onUpdateLineItemClick(lineItem)


    }

    const onCancelUpdateLineItemHandler = (lineItemKey, event) => {
        event.preventDefault();

        props.onCancelUpdateLineItemClick(lineItemKey)
    }

    const onOpenTiresInfoHandler = (lineItemId, complaintCode, productCode, qty, unitPrice, correctionType) => {
        props.onOpenTiresInfo(lineItemId, complaintCode, productCode, qty, unitPrice, correctionType);
    }

    const getLineItemFirstColumnHeader = (lineItemType) => {

        const messages = defineMessages({
            line_item_view_approved_items: {
                id: 'line_item_view_approved',
                description: 'Approved line Items',
                defaultMessage: 'Approved line Items'
            },
            line_item_view_pending_items: {
                id: 'line_item_view_pending',
                description: 'Pending line Items',
                defaultMessage: 'Pending line Items'
            },
            line_item_view_rejected_items: {
                id: 'line_item_view_rejected_items',
                description: 'Rejected line Items',
                defaultMessage: 'Rejected line Items'
            }
        });

        switch (lineItemType) {
            case LINE_ITEM_STATUS.APPROVED:
                return <FormattedMessage {...messages.line_item_view_approved_items} />;
            case LINE_ITEM_STATUS.PENDING:
                return <FormattedMessage {...messages.line_item_view_pending_items} />;
            case LINE_ITEM_STATUS.REJECTED:
                return <FormattedMessage {...messages.line_item_view_rejected_items} />;
        }
    }

    const messages = defineMessages({
        line_item_view_quantity: {
            id: 'line_item_view_quantity',
            description: 'Qty',
            defaultMessage: 'Qty'
        },
        line_item_view_hour: {
            id: 'line_item_view_hour',
            description: 'Hour',
            defaultMessage: 'Hour'
        },
        line_item_view_cost: {
            id: 'line_item_view_cost',
            description: 'Cost',
            defaultMessage: 'Cost'
        },
        line_item_view_amount: {
            id: 'line_item_view_amount',
            description: 'Amount',
            defaultMessage: 'Amount'
        },
        contract_pricing_cannot_be_edited_displayed_message: {
            id: 'contract_pricing_cannot_be_edited_displayed_message',
            description: 'This product code has contract pricing. Price cannot be edited or displayed.',
            defaultMessage: 'This product code has contract pricing. Price cannot be edited or displayed.'
        },
        missing_product_code_icon_title: {
            id: 'missing_product_code_icon_title',
            description: 'Click to add missing Product Code',
            defaultMessage: 'Click to add missing Product Code'
        },
    });

    const onMissingProductCodeIconClick = (lineItemId, event) => {
        props.onMissingProductCodeIconClick(props.complaintCode,lineItemId)
    }

    const renderMissingProductCodeIcon = (lineItem, actionType) => {
        if ((actionType == 'ADD' || actionType == 'EDIT') && appState.isMissingProductCodeWorkFlowEnabled && (lineItem.get('vendorProductCode') == undefined || lineItem.get('vendorProductCode') == null))
            return <div>
                <a onClick={(event) => onMissingProductCodeIconClick(lineItem.get('lineItemId'), event)}>
                    <img src="/assets/missing_product_code_red.png"
                        title={props.intl.formatMessage({
                            ...messages.missing_product_code_icon_title
                        })}
                    />
                </a>
            </div>
        else
            return;
    }

    return <div className='line_Item table-responsive'>
        <table className={classNames({ 'line_item__table': props.lineItemType == LINE_ITEM_STATUS.APPROVED },
            { 'line_item__table_pending': props.lineItemType != LINE_ITEM_STATUS.APPROVED })}>
            <thead>
                <tr className="row">
                    <th className="col-sm-5 line_item__header">
                        {getLineItemFirstColumnHeader(props.lineItemType)}
                    </th>
                    <th className="col-sm-1 line_item__header  line_item__currency">
                        {props.hideCostInformation == true ? false :
                            props.complaintCode != 'DG99'
                                ? <FormattedMessage {...messages.line_item_view_quantity} />
                                : <FormattedMessage {...messages.line_item_view_hour} />

                        }
                    </th>

                    <th className="col-sm-2 line_item__header  line_item__currency">
                        {props.hideCostInformation == true ? false :
                            <FormattedMessage {...messages.line_item_view_cost} />
                        }
                    </th>

                    <th className="col-sm-2 line_item__header line_item__currency">
                        {props.hideCostInformation == true ? false :
                            <FormattedMessage {...messages.line_item_view_amount} />
                        }
                    </th>

                    <th className="col-sm-1 line_item__header">

                    </th>
                    <th className="col-sm-1 line_item__header">

                    </th>
                    <th className="col-sm-1 line_item__header">

                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    props.lineItems.map((lineItem, lineItemKey) =>
                        <tr className={classNames('row',
                            { 'line_item__item-rejected': lineItem.get('approvalStatus') === LINE_ITEM_STATUS.REJECTED })}
                            key={lineItem.get('lineItemId')}>
                            <td className='col-sm-6 line_item__item'>
                                {lineItem.get('lineItemType') === "dealer_draft" ? lineItem.get('productDescription') :
                                    appState.isProductCodeFeatureEnabled && lineItem.get('vendorProductCode') !== undefined && lineItem.get('vendorProductCode') !== null  ? lineItem.get('vendorProductCode') + ' - ' + lineItem.get('productDescription') + ', ' + (lineItem.get('lineItemType') == LINE_ITEM_TYPE.PM ? LINE_ITEM_TYPE.PM_TEXT : lineItem.get('lineItemType').toUpperCase())
                                        : lineItem.get('productDescription') + ', ' + (lineItem.get('lineItemType') == LINE_ITEM_TYPE.PM ? LINE_ITEM_TYPE.PM_TEXT : lineItem.get('lineItemType').toUpperCase())
                                }
                            </td>

                            <td className="col-sm-1 line_item__item  line_item__currency">


                                {
                                    /* Show this text box only for Create and Edit PO; hide it for View PO*/
                                    props.actionType.toUpperCase() == 'VIEW' ||
                                        props.actionType.toUpperCase() == 'ADJUSTMENT' || props.actionType.toUpperCase() === 'DEALERDRAFT' ||
                                        (props.actionType.toUpperCase() != 'VIEW' && props.actionType.toUpperCase() != 'ADJUSTMENT' && props.actionType.toUpperCase() !== 'DEALERDRAFT' &&
                                            (lineItem.get('isBeingEdited') == undefined || lineItem.get('isBeingEdited') == false)) ?
                                        props.hideCostInformation == true ? false : lineItem.get('quantity')
                                        :
                                        <div>
                                            <DynamicNumber
                                                onChange={(evt, modelValue, viewValue) => setQuantity(modelValue)}
                                                className="line_item__input-box"
                                                separator={'.'}
                                                integer={6}
                                                fraction={2}
                                                positive={true}
                                                negative={false}
                                                thousand={false}
                                                value={lineItem.get('isBeingEdited') == true ? quantity : lineItem.get('quantity')}
                                            />
                                        </div>
                                }
                            </td>


                            <td className="col-sm-2 line_item__item  line_item__currency" ref={costDisplayRefs[lineItemKey]}>
                                {
                                    /* Show this text box only for Create and Edit PO; hide it for View PO*/
                                    props.actionType.toUpperCase() == 'VIEW' ||
                                        (props.actionType.toUpperCase() != 'VIEW' &&
                                            (lineItem.get('isBeingEdited') == undefined || lineItem.get('isBeingEdited') == false)) ?
                                        props.hideCostInformation == true ? false :
                                            lineItem.get('pricingSource') == "client_contract_pricing" ?
                                                <OverlayTrigger placement='right'
                                                    overlay={<Tooltip><RawIntlProvider value={props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_displayed_message} /></RawIntlProvider></Tooltip>}
                                                >
                                                    <span class="line_item__item-price-hidden">N/A</span>
                                                </OverlayTrigger>
                                                :
                                            <IntlProvider locale="en">
                                                <FormattedNumber value={lineItem.get('unitPrice')} style="currency"
                                                    currency={props.selectedCurrency} />
                                            </IntlProvider>

                                        :
                                        <div>
                                            <span>$</span>
                                            <DynamicNumber
                                                onChange={(evt, modelValue, viewValue) => setUnitPrice(modelValue)}
                                                className="line_item__input-box"
                                                separator={'.'}
                                                integer={6}
                                                fraction={2}
                                                positive={true}
                                                negative={props.actionType.toUpperCase() === 'DEALERDRAFT'}
                                                thousand={false}
                                                value={lineItem.get('isBeingEdited') == true ? unitPrice : lineItem.get('unitPrice')}
                                            />
                                        </div>
                                }
                                {!!lineItem.get('costError') &&
                                    <Overlay
                                        show={!!lineItem.get('costError')}
                                        target={costDisplayRefs[lineItemKey]}
                                        placement="bottom">
                                        <Popover id={'lineItemPoOver' + lineItem.get('lineItemId')} className='line_item__item__overlay'>
                                            {lineItem.get('costError')}
                                        </Popover>
                                    </Overlay>
                                }
                            </td>

                            <td className="col-sm-2 line_item__item line_item__currency">
                                {props.hideCostInformation == true ? false :
                                    lineItem.get('pricingSource') == "client_contract_pricing" ?
                                        <OverlayTrigger placement='right'
                                            overlay={<Tooltip><RawIntlProvider value={props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_displayed_message} /></RawIntlProvider></Tooltip>}
                                        >
                                            <span class="line_item__item-price-hidden">N/A</span>
                                        </OverlayTrigger>
                                    : <IntlProvider locale="en">
                                        <FormattedNumber value={lineItem.get('totalAmount')} style="currency"
                                            currency={props.selectedCurrency} />
                                    </IntlProvider>}
                            </td>

                            <td className="col-sm-1 line_item__item line_item__currency">
                                {
                                    (lineItem.get('isBeingUpdated') || lineItem.get('isBeingDeleted'))
                                        ? <Spinner />
                                        :
                                        /* Show these button only for Create and Edit PO for pending line items; hide it for View PO.
                                           Also show for adjustment/approved lines. 
                                           Also check if PreventLineEditFeature is Enabled or not
                                        */
                                        ((
                                            (
                                                ((props.actionType.toUpperCase() == 'ADD' || props.actionType.toUpperCase() == 'EDIT')
                                                    && lineItem.get('approvalStatus') == LINE_ITEM_STATUS.PENDING)
                                                ||
                                                (props.actionType.toUpperCase() == 'ADJUSTMENT'
                                                    && lineItem.get('approvalStatus') == LINE_ITEM_STATUS.APPROVED)
                                                ||
                                                (props.actionType.toUpperCase() == 'DEALERDRAFT'
                                                    && lineItem.get('approvalStatus') == LINE_ITEM_STATUS.APPROVED)
                                            )
                                            && (props.lineItemIdBeingEdited === undefined || props.lineItemIdBeingEdited === lineItem.get('lineItemId'))
                                            && (!appState.isPreventLineEditFeatureEnabled)
                                        ) ?

                                            lineItem.get('isBeingEdited') == undefined || lineItem.get('isBeingEdited') == false
                                                ?
                                                <div>
                                                    <a onClick={(event) => onEditLineItemHandler(lineItem.get('lineItemId'), lineItem.get('quantity'), lineItem.get('unitPrice'), event)}>
                                                        <span className="glyphicon glyphicon-edit glyphicon-lg"></span>
                                                    </a>
                                                </div>
                                                :
                                                <div>
                                                    <a onClick={(event) => onUpdateLineItemHandler(lineItem.get('lineItemId'), event)}>
                                                        <img src="/assets/VMS_28button_edit_blue.png" />
                                                    </a>
                                                </div>
                                            : 
                                            renderMissingProductCodeIcon(lineItem, props.actionType.toUpperCase())
                                       )
                                }
                            </td>

                            <td className="line_item__item line_item__currency">
                                {
                                    (tireProductCodes.includes(lineItem.get('productCode'))
                                        || brakesProductCodes.findIndex(x => x.productCode == lineItem.get('productCode')) > -1)
                                        &&
                                        (
                                            /* Show these button only for Create and Edit PO; hide it for View PO*/
                                            props.actionType.toUpperCase() == 'ADD' ||
                                            props.actionType.toUpperCase() == 'EDIT' ||
                                            props.actionType.toUpperCase() === 'DEALERDRAFT' ||
                                            props.actionType.toUpperCase() == 'VIEW'

                                        )
                                        && !lineItem.get('isBeingEdited')
                                        ?
                                        <div className='line-item__tire-info'>
                                            {
                                                <a onClick={() => onOpenTiresInfoHandler(
                                                    lineItem.get('lineItemId'),
                                                    props.complaintCode,
                                                    lineItem.get('productCode'),
                                                    lineItem.get('quantity'),
                                                    lineItem.get('unitPrice'),
                                                    lineItem.get('correctionType'))}>
                                                    <img src="/assets/VMS_28button_tire_info.png" />
                                                </a>
                                            }
                                        </div>
                                        : false
                                }
                            </td>

                            {props.actionType.toUpperCase() === 'DEALERDRAFT' ?
                                <td className="col-sm-1 line_item__item line_item__currency">
                                    {
                                        lineItem.get('isBeingUpdated') || lineItem.get('isBeingDeleted')
                                            ? false
                                            :
                                            lineItem.get('isBeingEdited') === true ?

                                                <a onClick={(event) => onCancelUpdateLineItemHandler(lineItem.get('lineItemId'),event)}>
                                                    <span className="glyphicon glyphicon-ban-circle glyphicon-lg"></span>
                                                </a>

                                                : false

                                    }

                                </td>

                                :
                                <td className="col-sm-1 line_item__item line_item__currency">
                                    {
                                        lineItem.get('isBeingUpdated') || lineItem.get('isBeingDeleted')
                                            ? false
                                            :

                                            /* Show these button only for Create and Edit PO; hide it for View PO*/
                                            props.actionType.toUpperCase() == 'ADD' ||
                                                props.actionType.toUpperCase() == 'EDIT' && lineItem.get('approvalStatus') !== LINE_ITEM_STATUS.REJECTED ||
                                                props.actionType.toUpperCase() == 'ADJUSTMENT' && lineItem.get('approvalStatus') !== LINE_ITEM_STATUS.REJECTED ?
                                                (lineItem.get('isBeingEdited') == undefined || lineItem.get('isBeingEdited') == false) ?
                                                    <a onClick={(event) => onDeleteLineItemHandler(lineItem.get('lineItemId'),event)}>
                                                        <img src="/assets/VMS_28button_delete_blue.png" />
                                                    </a>
                                                    :
                                                    <a onClick={(event) => onCancelUpdateLineItemHandler(lineItem.get('lineItemId'),event)}>
                                                        <span className="glyphicon glyphicon-ban-circle glyphicon-lg"></span>
                                                    </a>
                                                : false
                                    }

                                </td>
                            }
                        </tr>
                    ).valueSeq()
                }
            </tbody>
        </table>
    </div>

}

export default injectIntl(LineItem);