import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { defineMessages, FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl';
import { Map } from 'immutable';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PO_AUTHORIZATION_STATUS, PO_PAYMENT_STATUS, SEARCH_FILTER_TYPE, SEARCH_DURATION_TYPE } from 'constants/App';
import './POList.css';
import moment from 'moment';
import { IntlProvider } from 'react-intl';
import Features from 'components/Features';
import CannotEditClaimBox from 'AddEditView/CannotEditClaimBox';
import { useAppState } from 'shared/AppState/app-state';

const POList = (props) => {

    const [appState, dispatch] = useAppState();

    const format= "MM/DD/YY";

    const onReturnClickHandler = (e) => {
        e.preventDefault();
        window.history.back();
    }

    const getTooltip= (toolTipText) => {
        return <Tooltip id="tooltip"> {toolTipText} </Tooltip>;
    }

    const getVehicle= (vin8, vehicleYear, vehicleMake, vehicleModel) => {

        let vehicleDetails = (vehicleYear == null ? '' : vehicleYear)
            + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel);

        return <OverlayTrigger
            placement='right'
            key={vin8}
            overlay={getTooltip(vehicleDetails)}
        >
            <div key={vin8}>
                {
                    vehicleDetails.substring(0, 4)
                }
            </div>
        </OverlayTrigger>

    }

    const getFilteredRecords = (poList, showAll) => {
        var filteredRecords = poList.sortBy(po => new Date(po.get('createdDate'))).reverse();

        if (showAll == false && filteredRecords.count() > 0)
            filteredRecords = filteredRecords.take(10);

        return filteredRecords;
    }

    const getCaption= (type) => {
        const messages = defineMessages({
            search_po_service_in_progress_caption: {
                id: 'search_po_service_in_progress_caption',
                description: 'Caption for PO In Progress PO List',
                defaultMessage: 'Service in progress'
            },
            search_po_payment_in_progress_caption: {
                id: 'search_po_payment_in_progress_caption',
                description: 'Caption for Payment In Progress PO List',
                defaultMessage: 'Payment in progress'
            },
            search_po_paid_caption: {
                id: 'search_po_paid_caption',
                description: 'Caption for Paid PO List',
                defaultMessage: 'Paid'
            },
            search_po_work_completed_caption: {
                id: 'search_po_work_completed_caption',
                description: 'Caption for work completed PO List',
                defaultMessage: 'Work completed'
            }
        });

        
        switch (type) {
            case PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED:
                return <FormattedMessage {...messages.search_po_service_in_progress_caption} />;
            case PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS:
                return <FormattedMessage {...messages.search_po_payment_in_progress_caption} />;
            case PO_PAYMENT_STATUS.PAID:
                return <FormattedMessage {...messages.search_po_paid_caption} />;   
            case PO_AUTHORIZATION_STATUS.WORK_COMPLETED:
                return <FormattedMessage {...messages.search_po_work_completed_caption} />;                
                                 
        }
    }

    const getHeaders= (type, searchFilters) => {
        const messages = defineMessages({
            search_po_vehicle_column_header: {
                id: 'search_po_vehicle_column_header',
                description: 'Header for Vehicle Column',
                defaultMessage: 'Vehicle'
            },
            search_po_po_number_column_header: {
                id: 'search_po_po_number_column_header',
                description: 'Header for PO Number Column',
                defaultMessage: 'PO no.'
            },
            search_po_po_date_column_header: {
                id: 'search_po_po_date_column_header',
                description: 'Header for PO date Column',
                defaultMessage: 'PO date'
            },
            search_po_po_status_column_header: {
                id: 'search_po_po_status_column_header',
                description: 'Header for PO status Column',
                defaultMessage: 'Status'
            },
            search_po_payment_no_column_header: {
                id: 'search_po_payment_no_column_header',
                description: 'Header for Payment no. Column',
                defaultMessage: 'Payment no.'
            },
            search_po_invoice_no_column_header: {
                id: 'search_po_invoice_no_column_header',
                description: 'Header for Invoice no. Column',
                defaultMessage: 'Invoice no.'
            },
            search_po_po_payment_requested_column_header: {
                id: 'search_po_po_payment_requested_column_header',
                description: 'Header for Payment requested Column',
                defaultMessage: 'Payment requested'
            },
            search_po_po_payment_date_column_header: {
                id: 'search_po_po_payment_date_column_header',
                description: 'Header for Payment date Column',
                defaultMessage: 'Payment date'
            },
            search_po_po_total_amount_column_header: {
                id: 'search_po_po_total_amount_column_header',
                description: 'Header for Amount Column',
                defaultMessage: 'Amount'
            }
        });
        return <thead>
            <tr>
                {
                    searchFilters != undefined && searchFilters.get('searchFor') != SEARCH_FILTER_TYPE.VIN8
                        ?
                        <th className="po-list__header-cell
                            po-list__header-cell_align-left">
                        < FormattedMessage {...messages.search_po_vehicle_column_header} />
            </th>
            : false
        }
            <th className="po-list__header-cell
                po-list__header-cell_align-left">
            < FormattedMessage {...messages.search_po_po_number_column_header } />
            </th >

        {
            type == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED || type == PO_AUTHORIZATION_STATUS.WORK_COMPLETED
            ? <th className="po-list__header-cell
                po-list__header-cell_align-left">
                < FormattedMessage {...messages.search_po_po_date_column_header } />
            </th >
            : false
        }

        {
            type == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED
                ? <th className="po-list__header-cell
                    po-list__header-cell_align-left">
                < FormattedMessage {...messages.search_po_po_status_column_header } />
            </th >
                : false
        }
        {
            searchFilters != undefined && searchFilters.get('searchFor') != SEARCH_FILTER_TYPE.VIN8 && type != PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED
            && type != PO_AUTHORIZATION_STATUS.WORK_COMPLETED
                ? <th className="po-list__header-cell
                    po-list__header-cell_align-left">
                < FormattedMessage {...messages.search_po_payment_no_column_header } />
            </th >
                : false
        }

        {
            type != PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED && type != PO_AUTHORIZATION_STATUS.WORK_COMPLETED
                ? <th className="po-list__header-cell
                    po-list__header-cell_align-left">
                < FormattedMessage {...messages.search_po_invoice_no_column_header } />
            </th >
                : false
        }
        {
            type == PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS
                ? <th className="po-list__header-cell
                    po-list__header-cell_align-left">
                < FormattedMessage {...messages.search_po_po_payment_requested_column_header } />
            </th >
                : false
        }
        {
            type == PO_PAYMENT_STATUS.PAID
                ? <th className="po-list__header-cell
                    po-list__header-cell_align-left">
                < FormattedMessage {...messages.search_po_po_payment_date_column_header } />
            </th >
                : false
        }
        {
            type != PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED
                ? <th className="po-list__header-cell
                    po-list__header-cell_align-right">
                < FormattedMessage {...messages.search_po_po_total_amount_column_header } />
            </th >
                : false
        }
        {
            //create empty header cell
            type == PO_AUTHORIZATION_STATUS.WORK_COMPLETED || type == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED
            || type == PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS
                ? <td className={classnames('po-list__cell',
                    'po-list__cell_align-right'
                )}></td>
                : false
        }

        </tr >
        </thead> 
    }

    const getHeadersClientPO= (type, searchFilters) => {
        const messages = defineMessages({
            search_po_vehicle_column_header: {
                id: 'search_po_vehicle_column_header',
                description: 'Header for Vehicle Column',
                defaultMessage: 'Vehicle'
            },
            search_po_client_name_column_header: {
                id: 'search_po_client_name_column_header',
                description: 'Header for client name Column',
                defaultMessage: 'Client Name'
            },
            search_po_full_vin_column_header: {
                id: 'search_po_full_vin_column_header',
                description: 'Header for Vin Column',
                defaultMessage: 'Full Vin. No'
            },
            search_po_client_id_amount_column_header: {
                id: 'search_po_client_id_amount_column_header',
                description: 'Header for client id Column',
                defaultMessage: 'Client ID'
            }
        });
        return <thead>
           <tr>
                <th className="po-list__header-cell
                            po-list__header-cell_align-left">
                        < FormattedMessage {...messages.search_po_client_name_column_header} />
            </th>
            <th className="po-list__header-cell
                            po-list__header-cell_align-left">
                        < FormattedMessage {...messages.search_po_full_vin_column_header} />
            </th>
            <th className="po-list__header-cell
                            po-list__header-cell_align-left">
                        < FormattedMessage {...messages.search_po_client_id_amount_column_header} />
            </th>
            <th className="po-list__header-cell
                            po-list__header-cell_align-left">
                        < FormattedMessage {...messages.search_po_vehicle_column_header} />
            </th>

            {
            //create empty header cell
            type == PO_AUTHORIZATION_STATUS.WORK_COMPLETED || type == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED
            || type == PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS
                ? <td className={classnames('po-list__cell',
                    'po-list__cell_align-right'
                )}></td>
                : false
            }
           </tr>
        </thead> 
    }

    const getRows= (type, filteredRecords, searchFilters,readOnlyPOMode) => {
        const today = new Date();

        const messages = defineMessages({
            search_po_none: {
                id: 'search_po_none',
                description: 'Text for no PO no.',
                defaultMessage: 'None'
            },
            search_po_po_view_link_caption: {
                id: 'search_po_po_view_link_caption',
                description: 'Text for PO View link',
                defaultMessage: 'VIEW'
            },
            search_po_today: {
                id: 'search_po_today',
                description: 'Text for po date column when po date is Today',
                defaultMessage: 'Today'
            },
            search_po_no_pos_caption: {
                id: 'search_po_no_pos_caption',
                description: 'Caption to display if no POs are available for display on Shop View',
                defaultMessage: 'None at this time'
            },
            insurance_edit_check_message: {
                id: 'insurance_edit_check_message',
                description: 'insurance_edit_check_message',
                defaultMessage: "If you need to revise an estimate on claim-related events please email a supplement and supporting documentation to <a href='mailto:claims@holman.com'>claims@holman.com</a>"
            },
            insurance_edit_check_ok: {
                id: 'insurance_edit_check_ok',
                description: 'insurance_edit_check_ok',
                defaultMessage: 'Ok'
            }   
        });


        return <tbody>
            {
                filteredRecords.count() == 0 ?
                    <tr>
                        <td colSpan="6" className="po-list__cell po-list__cell_align-center">
                            <FormattedMessage {...messages.search_po_no_pos_caption} />
                        </td>
                    </tr >
                    :
                    filteredRecords.valueSeq().map(po =>
                        <tr key={po.get('id')}>

                            {
                                searchFilters != undefined && searchFilters.get('searchFor') != SEARCH_FILTER_TYPE.VIN8
                                    ? <td className={
                                        classnames('po-list__cell')
                                    }>
                                        {
                                            getVehicle(po.get('vehicleVIN8'), po.get('vehicleYear'), po.get('vehicleMake'), po.get('vehicleModel'))
                                        }
                                    </td >
                                    : false
                            }


                            <td className={classnames('po-list__cell',
                            )}>
                                {po.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.APPROVED || type == PO_AUTHORIZATION_STATUS.WORK_COMPLETED || po.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.WORK_COMPLETED
                                    ? po.get('poNumber')
                                    : <FormattedMessage {...messages.search_po_none} />
                                }
                            </td>
                            {
                                type == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED || type == PO_AUTHORIZATION_STATUS.WORK_COMPLETED
                                    ? <td className={classnames('po-list__cell',
                                        'po-list__cell_align-left'
                                    )}>
                                        {

                                            today.toDateString() == new Date(po.get('poDate')).toDateString()
                                                ? <FormattedMessage {...messages.search_po_today} />
                                                : moment(po.get('createdDate')).format(format)

                                        }
                                    </td>
                                    : false
                            }
                            {type == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED && type != PO_AUTHORIZATION_STATUS.WORK_COMPLETED
                                ? <td className={classnames('po-list__cell',
                                )}>
                                    <FormattedMessage {...friendlyAuthorizationStatus(po.get('authorizationStatus')) } />
                                </td>
                                : false
                            }
                            {searchFilters != undefined && searchFilters.get('searchFor') != SEARCH_FILTER_TYPE.VIN8 && type != PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED
                                && type != PO_AUTHORIZATION_STATUS.WORK_COMPLETED
                                ? <td className={classnames('po-list__cell',
                                )}>
                                    {po.get('paymentNumber')}
                                </td>
                                : false
                            }
                            {type != PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED && type != PO_AUTHORIZATION_STATUS.WORK_COMPLETED
                                ? <td className={classnames('po-list__cell',
                                )}>
                                    {po.get('invoiceNo')}
                                </td>
                                : false
                            }
                            {type == PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS&& type != PO_AUTHORIZATION_STATUS.WORK_COMPLETED
                                ? <td className={classnames('po-list__cell',
                                )}>
                                    {
                                        po.get('paymentRequestedDate') == undefined || po.get('paymentRequestedDate') == null
                                            ? false
                                            : moment(po.get('paymentRequestedDate')).format(format)

                                    }
                                </td>
                                : false
                            }
                            {type == PO_PAYMENT_STATUS.PAID && type != PO_AUTHORIZATION_STATUS.WORK_COMPLETED
                                ? <td className={classnames('po-list__cell',
                                )}>
                                    {
                                        po.get('paymentDate') == undefined || po.get('paymentDate') == null
                                            ? false
                                            : moment(po.get('paymentDate')).format(format)

                                    }

                                </td>
                                : false
                            }
                            {type != PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED
                                ? <td className={classnames('po-list__cell',
                                    'po-list__cell_align-right'
                                )}>
                                    <IntlProvider locale="en">
                                        <FormattedNumber value={po.get('totalAmount')}
                                        style="currency"
                                        currency={props.selectedCurrency}
                                        minimumFractionDigits={2} />
                                    </IntlProvider>
                                </td>
                                : false
                            }
                            < td className={
                                classnames('po-list__cell',
                                    'po-list__cell_left-margin',
                                    'po-list__cell_edit-action',
                                )}>
                                {
                                    po.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL
                                        ?
                                        <Link to={`/PO/actionRequired/${po.get('id')}`}>
                                            <FormattedMessage {...messages.search_po_po_view_link_caption} />
                                        </Link>
                                        :
                                        po.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.APPROVED &&
                                            po.get('paymentStatus') == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED
                                            && props.allowedToEditPO && !readOnlyPOMode && appState.poPermissions.includes('ED')
                                            ? 
                                            po.get('hasAssociatedClaim') ? 
                                            <Link to={`/PO/view/${po.get('id')}`}>
                                            <FormattedMessage {...messages.search_po_po_view_link_caption} />
                                            </Link>
                                        :
                                            <Link to={`/PO/edit/${po.get('id')}`}>
                                                <FormattedMessage {...messages.search_po_po_view_link_caption} />
                                            </Link>
                                            :
                                            <Link to={`/PO/view/${po.get('id')}`}>
                                                <FormattedMessage {...messages.search_po_po_view_link_caption} />
                                            </Link>
                                }
                            </td >
                        </tr >
                    )
            }
        </tbody>
    }


    const getRowsClientPO= (type, filteredRecords, searchFilters,readOnlyPOMode) => {
        const today = new Date();

        const messages = defineMessages({
            search_po_po_view_link_caption: {
                id: 'search_po_po_view_link_caption',
                description: 'Text for PO View link',
                defaultMessage: 'VIEW'
            },
            search_po_no_pos_caption: {
                id: 'search_po_no_pos_caption',
                description: 'Caption to display if no POs are available for display on Shop View',
                defaultMessage: 'None at this time'
            }
        });

        return <tbody>
            {
                filteredRecords.count() == 0 ?
                    <tr>
                        <td colSpan="6" className="po-list__cell po-list__cell_align-center">
                            <FormattedMessage {...messages.search_po_no_pos_caption} />
                        </td>
                    </tr >
                    :
                    filteredRecords.valueSeq().map(po =>
                          
                        <tr key={po.get('poNumber')}>
                           <td className={classnames('po-list__cell',
                            )}>
                               {po.get('clientName')}
                                
                            </td>
                            <td className={classnames('po-list__cell',
                            )}>
                                {po.get('vin')}
                                
                            </td>
                            <td className={classnames('po-list__cell',
                            )}>

                                {po.get('clientId')}
                            </td>
                            <td className={classnames('po-list__cell',
                            )}>
                                    {po.get('ariUnitNo')}
                                
                            </td>
                            < td className={
                                classnames('po-list__cell',
                                    'po-list__cell_left-margin',
                                    'po-list__cell_edit-action',
                                )}>
                                {
                                    po.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL
                                        ?
                                        <Link to={`/PO/actionRequired/${po.get('id')}`}>
                                            <FormattedMessage {...messages.search_po_po_view_link_caption} />
                                        </Link>
                                        :
                                        po.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.APPROVED &&
                                            po.get('paymentStatus') == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED
                                            && props.allowedToEditPO && !readOnlyPOMode && appState.poPermissions.includes('ED')
                                            ? 
                                            po.get('hasAssociatedClaim') ? 
                                            <Link to={`/PO/view/${po.get('id')}`}>
                                            <FormattedMessage {...messages.search_po_po_view_link_caption} />
                                            </Link>
                                        :
                                            <Link to={`/PO/edit/${po.get('id')}`}>
                                                <FormattedMessage {...messages.search_po_po_view_link_caption} />
                                            </Link>
                                            :
                                            <Link to={`/PO/view/${po.get('id')}`}>
                                                <FormattedMessage {...messages.search_po_po_view_link_caption} />
                                            </Link>
                                }
                            </td >
                        </tr>
                       
              
                    )
            }
        </tbody>
    }
    const friendlyAuthorizationStatus= (authorizationStatus) => {
        const messages = defineMessages({
            search_po_waiting_for_ari_approval: {
                id: 'search_po_waiting_for_ari_approval',
                description: 'Friendly text for waiting_for_ari_approval status',
                defaultMessage: 'Waiting for Holman approval'
            },
            search_po_waiting_for_ari_approval_mex: {
                id: 'search_po_waiting_for_ari_approval_mex',
                description: 'Friendly text for waiting_for_ari_approval status',
                defaultMessage: 'Waiting for ARIZA approval'
            },
            search_po_waiting_for_client_approval: {
                id: 'search_po_waiting_for_client_approval',
                description: 'Friendly text for waiting_for_client_approval status',
                defaultMessage: 'Waiting for client approval'
            },
            search_po_waiting_for_vendor_approval: {
                id: 'search_po_waiting_for_vendor_approval',
                description: 'Friendly text for waiting_for_vendor_approval status',
                defaultMessage: 'Waiting for vendor approval'
            },
            search_po_approved: {
                id: 'search_po_approved',
                description: 'Friendly text for approved status',
                defaultMessage: 'Approved'
            }, 
            new_work_assignment: {
                id: 'new_work_assignment',
                description: 'Friendly text for new work assignment status',
                defaultMessage: 'New Work Assignment'
            }, 
        });

        switch (authorizationStatus) {
            case PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL:
                return messages.search_po_waiting_for_ari_approval;
            case PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL:
                return messages.search_po_waiting_for_client_approval;
            case PO_AUTHORIZATION_STATUS.APPROVED:
                return messages.search_po_approved;
            case PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL:
                return messages.search_po_waiting_for_vendor_approval;
            case PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT:
                return messages.new_work_assignment;                
            default:
                return messages.search_po_waiting_for_ari_approval;
        }
    }

    const getFooter= (pos, showAll) => {

        const messages = defineMessages({
            show_all_waiting_pos: {
                id: 'show_all_waiting_pos',
                description: 'Link at bottom of PO List to show all POs',
                defaultMessage: 'Show all {poCount} purchase orders'
            },
            hide_older_pos: {
                id: 'hide_older_pos',
                description: 'Link at bottom of PO List to hide older approved POs',
                defaultMessage: 'Hide older purchase orders'
            }
        });

        // Don't show footer if we have 10 or less records
        if (pos.count() <= 10)
            return undefined;
        // If we are not showing all, show button to show all
        else if (!showAll) {
            return <div role="button" className="active-po-list__footer">
                <FormattedMessage values={{ poCount: pos.count() }}
                    {...messages.show_all_waiting_pos} />
                {' '}<img src="/assets/VMS_button_show_all_pos.png" />
            </div>;
        }
        else {
            return <div role="button" className="active-po-list__footer">
                <FormattedMessage {...messages.hide_older_pos} />
                {' '}<img src="/assets/VMS_button_hide_older_pos.png" />
            </div>;
        }
    }

    
        const { type, pos, footerClickHandler, showAll, searchFilters} = props;
        const filteredRecords = getFilteredRecords(pos, showAll);

        return <Features render={restrictedFeatures => (<div className="po-list table-responsive">
            <div className={classnames('po-list__caption',
                'float-left'
            )}>
                {getCaption(type)}
            </div>
            <table className="po-list__table" width="100%">
                {
                    //Header
                    searchFilters != undefined && searchFilters.get('searchFor') === SEARCH_FILTER_TYPE.CLIENT_REF_NO
                    ?
                    getHeadersClientPO(type, searchFilters) 
                    :
                    getHeaders(type, searchFilters)
                }
                
                {
                //Date Rows 
                searchFilters != undefined && searchFilters.get('searchFor') === SEARCH_FILTER_TYPE.CLIENT_REF_NO
                ?
                       getRowsClientPO(type, filteredRecords, searchFilters,restrictedFeatures.readOnlyPOMode)
                 :
                    getRows(type, filteredRecords, searchFilters,restrictedFeatures.readOnlyPOMode)
                 
                }
            </table>
            <div onClick={footerClickHandler.bind(this)}>
                {getFooter(pos, showAll)}
            </div>
            </div>)}/>;
    
}



export default POList;