const videos = {
    playerLocation: "https://content.jwplatform.com/libraries/kPVNgryJ.js",
    quick_start: {
        videoLocation: "http://content.jwplatform.com/videos/hdxQJ5fr-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/hdxQJ5fr-720.jpg",
        title: "Quick Start"
    },
    quick_start_mex: {
        videoLocation: "http://content.jwplatform.com/videos/AavajDDr-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/AavajDDr-720.jpg",
        title: "Quick Start"
    },
    quick_start_lube: {
        videoLocation: "http://content.jwplatform.com/videos/Q7RNUxWk-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/Q7RNUxWk-720.jpg",
        title: "Quick Start for Lube Vendors"
    },
    username_and_password: {
        videoLocation: "https://content.jwplatform.com/videos/AqKQpRdU-8TXbYriy.mp4",
        imageLocation: "https://content.jwplatform.com/thumbs/AqKQpRdU-720.jpg"
    },
    explanation_of_po_types: {
        videoLocation: "http://content.jwplatform.com/videos/ndzIPNC5-yE1lo0L7.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/ndzIPNC5-1280.jpg"
    },
    your_action_is_required: {
        videoLocation: "http://content.jwplatform.com/videos/sgMKuykA-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/sgMKuykA-720.jpg"
    },
    approved_for_service: {
        videoLocation: "http://content.jwplatform.com/videos/I2HVT6Af-yE1lo0L7.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/I2HVT6Af-1280.jpg"
    },
    waiting_for_ari_client_approval: {
        videoLocation: "http://content.jwplatform.com/videos/hy7k9O2y-yE1lo0L7.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/hy7k9O2y-1280.jpg"
    },
    payment_in_progress: {
        videoLocation: "http://content.jwplatform.com/videos/FWPnHQYy-yE1lo0L7.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/FWPnHQYy-1280.jpg"
    },
    search_for_my_pos: {
        videoLocation: "http://content.jwplatform.com/videos/EbinS3W1-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/EbinS3W1-720.jpg"
    },
    searching_for_vehicle: {
        videoLocation: "http://content.jwplatform.com/videos/aoOzhs7C-yE1lo0L7.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/aoOzhs7C-1280.jpg"
    },
    close_po: {
        videoLocation: "http://content.jwplatform.com/videos/xOSo4VoL-yE1lo0L7.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/xOSo4VoL-1280.jpg"
    },
    build_po: {
        videoLocation: "http://content.jwplatform.com/videos/zCaoe6Wx-yE1lo0L7.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/zCaoe6Wx-1280.jpg"
    },
    edit_po: {
        videoLocation: "http://content.jwplatform.com/videos/e6qVziJi-yE1lo0L7.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/e6qVziJi-1280.jpg"
    },
    help: {
        videoLocation: "http://content.jwplatform.com/videos/bQKLDray-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/bQKLDray-720.jpg"
    },
    contact: {
        videoLocation: "http://content.jwplatform.com/videos/2lkqFo3U-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/2lkqFo3U-720.jpg"
    },
    cancel_po: {
        videoLocation: "http://content.jwplatform.com/videos/1BLAOYAe-yE1lo0L7.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/1BLAOYAe-1280.jpg"
    },
    search_tips: {
        videoLocation: "http://content.jwplatform.com/videos/GG3RNuxK-8TXbYriy.mp4",
        imageLocation: "https://assets-jpcust.jwpsrv.com/thumbs/GG3RNuxK-720.jpg"
    },
    vehicle_information: {
        videoLocation: "http://content.jwplatform.com/videos/3yegknm8-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/3yegknm8-720.jpg"
    },
    brakes: {
        videoLocation: "http://content.jwplatform.com/videos/fHnzhebL-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/fHnzhebL-720.jpg"
    },
    tires: {
        videoLocation: "http://content.jwplatform.com/videos/jQkYqiU4-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/jQkYqiU4-720.jpg"
    },
    enrollement: {
        videoLocation: "http://content.jwplatform.com/videos/kr5KzgHx-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/kr5KzgHx-720.jpg"
    },
    new_supplier_registration: {
        videoLocation: "http://content.jwplatform.com/videos/OL0KJho4-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/OL0KJho4-720.jpg"
    },
    dealer_draft: {
        videoLocation: "http://content.jwplatform.com/videos/yrhQYBHz-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/yrhQYBHz-720.jpg"
    },
    car_wash_pos: {
        videoLocation: "http://content.jwplatform.com/videos/DlC1jNIG-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/DlC1jNIG-720.jpg"
    },
    work_completed_pos: {
        videoLocation: "http://content.jwplatform.com/videos/yKYoiU2Y-8TXbYriy.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/yKYoiU2Y-720.jpg"
    },
    adv_search_ata: {
        videoLocation: "https://content.jwplatform.com/videos/HeNdShOp-yE1lo0L7.mp4",
        imageLocation: "https://content.jwplatform.com/thumbs/HeNdShOp-1280.jpg"
    },
    registration_information: {
        videoLocation: "https://cdn.jwplayer.com/videos/8ISQQjWS-yE1lo0L7.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/8ISQQjWS-1280.jpg"
    }


};

export default videos;