import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import './NavigationBar.css';
import {connect} from 'react-redux';
import styled from 'styled-components';
import * as actions from '../actions/App';
import * as constants from 'constants/App';
import * as GamificationFunctions from 'utils/Gamification';
import TranslationIcon from 'components/SiteTranslations';
import jwtDecode from 'jwt-decode';
import UserAccountMenu from 'UserAccountMenu';
import { useAppState } from 'shared/AppState/app-state';

function NavigationBar({
    vendorName, 
    businessAddress,
    vendorId
}) {
    const [ appState ] = useAppState();  

    const messages = defineMessages({
        your_account_link: {
            id: 'your_account',
            description: 'Text for Your Account Link in header',
            defaultMessage: 'Your Account'
        },
        sign_out_link: {
            id: 'sign_out',
            description: 'Text for Sign Out Link in header',
            defaultMessage: 'Sign Out'
        },
        
        
        corporate_account_link: {
            id: 'corporate_account_link',
            description: 'Text for corporate Account Link in header',
            defaultMessage: 'Corporate Account'
        },            
        persona_header_text:{
            id:'persona_header_text',
            description:"Holman PartnerConnect",
            defaultMessage:'Holman PartnerConnect'
        }
    });

    return <div className='navigation-bar'>            

        <div css={`
            display: flex;
            padding-left: 15px;                
        `}>
            <div css={`                
                flex: 2 1 auto; 
                display: flex; 
                padding-bottom: 2rem;                 
            `}>
              <div 
                css={`                                                                            
                  padding-right: 50px;                  
                  min-width:200px;
                `}
                className="hidden-lg" 
              >
                <img                     
                  className="navbar-brand navigation-bar__logo hidden-lg" 
                  src="/assets/Logo-Primary-PMS.png" 
                />     
              </div>

              <div>                
                <div className="navigation-bar__caption">
                  {
                    vendorName != null && vendorName != undefined
                      ? vendorName 
                      : <FormattedMessage {...messages.persona_header_text} />
                  }
                </div>

                { 
                  businessAddress != null && businessAddress != undefined
                    ? <div className="navigation-bar__nav-link">{businessAddress.get('address1') + " | " + businessAddress.get('city') + ", " + businessAddress.get('state') + " " + businessAddress.get('zipCode')}</div>
                    : false
                }    
              </div>                                           
            </div>

            <div css={`
                align-self: flex-end;
            `}>
                <UserAccountMenu vendorId={vendorId}/>
            </div>

            <div css={`
                align-self: flex-end;
                padding-bottom: 1rem;
                padding-left: 4px;
            `}>
                <TranslationIcon backgroundColor='grey'/>
            </div>

        </div>
        
    </div>    
}


function select(state) {        
    return {                
        vendorName: state.appState.getIn(['serverData', 'shared', 'vendorName']),                                        
        businessAddress: state.appState.getIn(['serverData','shared','businessAddress']),
        vendorId: state.appState.getIn(["serverData", "shared", "vendorId"]),
        
    };
}



export default connect(select)(NavigationBar);