/**
 * Created by prboyapa on 2/29/2016.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Map} from 'immutable';
import {defineMessages, FormattedMessage} from 'react-intl';
import Title from 'components/Title';
import Complaints from './Complaints';
import Services from './Services';
import Diagnosis  from './Diagnosis';
import {LINE_ITEM_STATUS, PO_PAYMENT_STATUS, LINE_ITEM_TYPE} from  'constants/App';
import './ApprovedLineItems.css';

class ApprovedLineItems extends React.Component {

    render() {
        const messages = defineMessages({
            approved_items: {
                id: 'approved_items',
                description: 'Approved line items',
                defaultMessage: 'Approved line items'
            },
            line_items: {
                id: 'line_items',
                description: 'Line items',
                defaultMessage: 'Line items'
            }
        });

        /* Filter the data based on the complaint code for Complaints component */
        const nonMaintenanceComplaints = this.props.complaints.valueSeq().filter(co => co.get('code') != 'MA99' && co.get('code') != 'DG99' && co.get('code') != 'SA01').toMap();
        const nonMaintenanceLineItems = this.props.lineItems.valueSeq().filter(li => li.get('complaintCode') != 'MA99' && li.get('complaintCode') != 'DG99' && li.get('complaintCode') != 'SA01').toMap();

        /* Filter the line items data based on the complaint code for Services and Diagnosis components */
        const maintenanceLineItems = this.props.lineItems.valueSeq()
            .filter(li => li.get('complaintCode') == 'MA99'
            && !(li.get('approvalStatus') == LINE_ITEM_STATUS.REJECTED
            || li.get('lineItemType') == LINE_ITEM_TYPE.TAX || li.get('lineItemType') == LINE_ITEM_TYPE.CANADA_SALES_TAX)).toMap();

        const diagnosisLineItems = this.props.lineItems.valueSeq().filter(li => li.get('complaintCode') == 'DG99').toMap();
        
        return <div className="approved_line_items">
            {
                maintenanceLineItems.count() > 0 || nonMaintenanceLineItems.count() > 0
                    ?
                    <div className="approved_line_items__title">
                        <Title caption={this.props.actionType == 'CLOSE' || this.props.paymentStatus == PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS
                                            ? <FormattedMessage {...messages.line_items}/>
                                            : <FormattedMessage {...messages.approved_items}/>}/>
                    </div>
                    : false
            }

            {
                /*Check if complaints available, render the complaints; otherwise, render nothing*/
                nonMaintenanceComplaints.count() > 0 ?
                 <Complaints complaints={nonMaintenanceComplaints}
                 notes={this.props.nonMaintenanceNotes}
                 lineItems={nonMaintenanceLineItems}
                 hideCostInformation = {this.props.hideCostInformation}
                 selectedCurrency={this.props.selectedCurrency}
                             listOfComplaintsForWhichNoteIsRequired={this.props.listOfComplaintsForWhichNoteIsRequired}
                    />
                    : false
            }

            {
                //Check if complaints available, render the Diagnosis line; otherwise, render nothing
                diagnosisLineItems.count() > 0 ?
                    <Diagnosis lineItems={diagnosisLineItems}
                                hideCostInformation = {this.props.hideCostInformation}
                               selectedCurrency={this.props.selectedCurrency}
                    />
                    : false
            }

            {
                /*Check if complaints available, render the PM Services line; otherwise, render nothing*/
                maintenanceLineItems.count() > 0 ?
                    <Services lineItems={maintenanceLineItems}
                              notesHistory={this.props.maintenanceNotesHistory}
                              serviceDescription={this.props.servicesDescription == undefined || this.props.servicesDescription == null ? '' : this.props.servicesDescription}
                              hideCostInformation = {this.props.hideCostInformation}
                              selectedCurrency={this.props.selectedCurrency}
                              listOfComplaintsForWhichNoteIsRequired={this.props.listOfComplaintsForWhichNoteIsRequired}
                    />
                    
                    : false
            }
        </div>
    }
}



export default ApprovedLineItems;