// #region Imports
import React, { useEffect, useState, useCallback } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import './BrakesStepViewEditLine.css';
import { connect } from "react-redux";
import AddNewLineItemsSelector from '../selectors/AddNewLineItemsView';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import { Map } from 'immutable';
import * as purchaseOrderViewEvents from '../../../events/AddEditView';
import Brakes from 'Brakes/Brakes';
// #endregion

// #region Styled Components
// #endregion

// #region Component
const BrakesStepViewEditLine = (props) => {

    // #region State
    const [showBrakesValidationError, setShowBrakesValidationError] = useState(false);
    // #endregion

    // #region Ref
    // #endregion  

    // #region Callback
    // #endregion  

    // #region Event handlers
    const cleanUp = () => {
         //cancel update line item event if popup was opened as part of edit tire info
         const lineItem = props.poDetail;
         if (!!lineItem)
             purchaseOrderViewEvents.onCancelUpdateLineItemClickHandler(lineItem.get('lineItemKey'), props.dispatch);

        props.dispatch({ type: constants.CLEAR_LINE_ITEM_ID_FOR_TIRES });     
    }

    const getBrakeModel = (id, vehicleId, lineItemId, axleNumber, position, measurement, component, unitOfMeasurement, eventType,
        maxDrumDiameter, minRotorThickness) => {
        return {
            id: id,
            vehicleId: vehicleId,
            lineItemId: lineItemId,
            axleNumber: axleNumber,
            position: position,
            measurement: measurement,
            component: component,
            unitOfMeasurement: unitOfMeasurement,
            eventType: eventType,
            createdBy: "string",
            createdOn: "2017-05-24T18:00:59.718Z",
            maxDrumDiameter: maxDrumDiameter,
            minRotorThickness: minRotorThickness
        }
    }

    const onSaveBrakeChangesHandler = () => {

        //clear errors 
        props.dispatch({ type: constants.CLEAR_BRAKES_VALIDATION_ERRS });

        if (!validateBrakeDetails()) {
            setShowBrakesValidationError(true);
            return false;
        }

        const lineItem = props.poDetail;
        /* No need to patch pricing details when editing brake measurements */
       // purchaseOrderViewEvents.onUpdateLineItemClickHandler(lineItem, props.dispatch);

        props.dispatch({ type: constants.SAVE_BRAKES_DETAILS });
        onCloseWindowHandler();
        props.dispatch({ type: constants.FETCH_BRAKES_SPECIFICATIONS, lineItemId: undefined, mode: 'edit' });
        setShowBrakesValidationError(false);

        cleanUp();
    }

    const validateMaxMinValuesForBrakes = () => {
        const errorMessages = defineMessages({
            max_min_spec_not_met: {
                id: 'max_min_spec_not_met',
                description: 'The entered value exceeds min/max specifications',
                defaultMessage: 'The entered value exceeds min/max specifications'
            },
            max_min_spec_required: {
                id: 'max_min_spec_required',
                description: 'Min/Max specification is required',
                defaultMessage: 'Min/Max specification is required'
            },
        })
        let isFormValid = true;
        let validationErrs;
        let brakeAxles = props.brakesInfo.brakeAxlesFromState.filter(x => x.component == props.brakesInfo.componentType);
        const isDrum = props.brakesInfo.componentType == constants.BRAKE_COMPONENT_TYPE.DRUM;
        const isRotor = props.brakesInfo.componentType == constants.BRAKE_COMPONENT_TYPE.ROTOR;
        const isFrontProductCode = props.brakesInfo.isFrontAxleProduct;
        const isRearProductCode = props.brakesInfo.isRearAxleProduct;
        const correctionTypeIsRepair = props.correctionType == constants.CORRECTION_TYPE.REPAIR;

        for (var index = 0; index < brakeAxles.length; index++) {
            const element = brakeAxles[index];

            const isMeasurementNaN = isNaN(element.measurement) || !element.measurement;
            const isMaxDrumDiameterNaN = isNaN(element.maxDrumDiameter) || !element.maxDrumDiameter;
            const isMinRotorThicknessNaN = isNaN(element.minRotorThickness) || !(element.minRotorThickness);

            //check for max thickness is not null if measuremment is there 
            if (isDrum &&
                ((!isMeasurementNaN && isMaxDrumDiameterNaN))
            ) {
                isFormValid = false;
                validationErrs = {
                    name: 'maxDia' + element.axleNumber,
                    value: errorMessages.max_min_spec_required
                }
                props.dispatch({ type: constants.UPDATE_BRAKES_VALIDATION_ERRS, validationErrs })
            }

            //check min thickness
            if (isRotor &&
                ((!isMeasurementNaN && isMinRotorThicknessNaN))
            ) {
                isFormValid = false;
                validationErrs = {
                    name: 'minThick' + element.axleNumber,
                    value: errorMessages.max_min_spec_required
                }
                props.dispatch({ type: constants.UPDATE_BRAKES_VALIDATION_ERRS, validationErrs })
            }

            const maxDiameter = isNaN(element.maxDrumDiameter) ? 0 : Number(element.maxDrumDiameter);
            const minThickness = isNaN(element.minRotorThickness) ? 0 : Number(element.minRotorThickness);
            const measurement = isNaN(element.measurement) ? 0 : Number(element.measurement);


            //check max diameter 
            if (isDrum && correctionTypeIsRepair && measurement > maxDiameter) {
                isFormValid = false;
                validationErrs = {
                    name: element.axleNumber + element.position,
                    value: errorMessages.max_min_spec_not_met
                }
                props.dispatch({ type: constants.UPDATE_BRAKES_VALIDATION_ERRS, validationErrs })
            }

            //check min thickness
            if (isRotor && correctionTypeIsRepair && measurement < minThickness) {
                isFormValid = false;
                validationErrs = {
                    name: element.axleNumber + element.position,
                    value: errorMessages.max_min_spec_not_met
                }
                props.dispatch({ type: constants.UPDATE_BRAKES_VALIDATION_ERRS, validationErrs })
            }

        }
        return isFormValid;
    }

    const validateAxleValuesForBrakes = () => {
        const errorMessages = defineMessages({
            front_axle_spec_required: {
                id: 'front_axle_spec_required',
                description: 'At least one front axle value must be filled',
                defaultMessage: 'At least one front axle value must be filled'
            },
            rear_axle_spec_required: {
                id: 'rear_axle_spec_required',
                description: 'At least one rear axle value must be filled',
                defaultMessage: 'At least one rear axle value must be filled'
            },
        })
        let isFormValid = true;
        let validationErrs;
        let brakeAxles = props.brakesInfo.brakeAxlesFromState.filter(x => x.component == props.brakesInfo.componentType);
        const isFrontProductCode = props.brakesInfo.isFrontAxleProduct;
        const isRearProductCode = props.brakesInfo.isRearAxleProduct;

        const frontAxleValuesExist = brakeAxles.findIndex(x => x.axleNumber == 1 && x.measurement != null && Number(x.measurement) > -1) > -1;
        const rearAxleValuesExist = brakeAxles.findIndex(x => x.axleNumber > 1 && x.measurement != null && Number(x.measurement) > -1) > -1;

        if (isFrontProductCode && !frontAxleValuesExist) {
            isFormValid = false;
            validationErrs = {
                name: 'axle1',
                value: errorMessages.front_axle_spec_required
            }
            props.dispatch({ type: constants.UPDATE_BRAKES_VALIDATION_ERRS, validationErrs })
        }

        if (isRearProductCode && !rearAxleValuesExist) {
            isFormValid = false;
            validationErrs = {
                name: 'axle2',
                value: errorMessages.rear_axle_spec_required
            }
            props.dispatch({ type: constants.UPDATE_BRAKES_VALIDATION_ERRS, validationErrs })
        }
        return isFormValid;
    }

    const validateBrakeDetails = () => {
        const isMaxMinValuesValid = validateMaxMinValuesForBrakes();
        const isAxleValuesValid = validateAxleValuesForBrakes();
        return isMaxMinValuesValid && isAxleValuesValid;
    }

    /*Close window*/
    const onCloseWindowHandler = () => {
        cleanUp();
        props.onClose();
    }
    // #endregion  

    // #region Effects
    // #endregion

    // #region International messages  
    const messages = defineMessages({
        brake_rotor: {
            id: 'brake_rotor',
            description: 'brake_rotor',
            defaultMessage: 'Brake Rotor measurements required'
        },
        brake_drum: {
            id: 'brake_drum',
            description: 'brake_drum',
            defaultMessage: 'Brake Drum measurements required'
        },
        brake_lining: {
            id: 'brake_lining',
            description: 'brake_lining',
            defaultMessage: 'Brake lining thickness required'
        },
    });

    const onAddBrakeAxleHandler = () => {
        const currentNumberOfAxles = props.brakesInfo.brakeAxleCountForSelectedLineItem;
        const maxAxles = props.brakesInfo.maxBrakeAxlesAllowed;
        const unit = props.brakesInfo.defaultBrakeMeasurementUnit;
        let brakeAxles = props.brakesInfo.brakeAxlesFromState;
        const vehicleId = '';   //these are not needed while creating a brake item at the moment but might be needed later on
        const lineItemId = '';//these are not needed while creating a brake item at the moment but might be needed later on
        const eventType = props.correctionType == constants.CORRECTION_TYPE.REPLACE ?
            constants.EVENT_TYPE.REPLACED
            : props.correctionType == constants.CORRECTION_TYPE.REPAIR ?
                constants.EVENT_TYPE.REPAIRED
                : constants.EVENT_TYPE.REPAIRED
            ;
        let newAxles = [];

        if (currentNumberOfAxles < maxAxles) {

            const leftBrake = getBrakeModel(null, vehicleId, lineItemId, currentNumberOfAxles + 1, constants.BRAKE_POSITIONS.LEFT, null,
                props.brakesInfo.componentType, props.brakesInfo.defaultBrakeMeasurementUnit, eventType, null);

            const rightBrake = getBrakeModel(null, vehicleId, lineItemId, currentNumberOfAxles + 1, constants.BRAKE_POSITIONS.RIGHT, null,
                props.brakesInfo.componentType, props.brakesInfo.defaultBrakeMeasurementUnit, eventType, null);

            const dataForNewBrake = [leftBrake, rightBrake];

            brakeAxles = brakeAxles.concat(dataForNewBrake);
            props.dispatch({ type: constants.ADD_NEW_BRAKE_AXLE, dataForNewBrake: brakeAxles })
        }
    }

    const onRemoveBrakeAxleHandler = () => {
        let brakeAxles = props.brakesInfo.brakeAxlesFromState;
        const currentNumberOfAxles = props.brakesInfo.brakeAxleCountForSelectedLineItem;

        if (currentNumberOfAxles > 1) {
            brakeAxles = brakeAxles.filter(x => !(x.axleNumber == currentNumberOfAxles && x.component == props.brakesInfo.componentType));
            props.dispatch({ type: constants.ADD_NEW_BRAKE_AXLE, dataForNewBrake: brakeAxles })
        }
    }

    const onChangeBrakeThicknessHandler = (axleNumber, position, value) => {
        setShowBrakesValidationError(false);

        let brakeAxles = props.brakesInfo.brakeAxlesFromState;
        const eventType = props.correctionType == constants.CORRECTION_TYPE.REPLACE ?
            constants.EVENT_TYPE.REPLACED
            : props.correctionType == constants.CORRECTION_TYPE.REPAIR ?
                constants.EVENT_TYPE.REPAIRED
                : constants.EVENT_TYPE.EXISTING
            ;

        brakeAxles = brakeAxles.reduce((p, c) => {
            if (c.axleNumber == axleNumber
                && (position == constants.BRAKE_POSITIONS.MAX_DRUM_DIAMETER || position == constants.BRAKE_POSITIONS.MIN_PAD_THICKNESS)
                && c.component == props.brakesInfo.componentType) {

                if (position == constants.BRAKE_POSITIONS.MAX_DRUM_DIAMETER)
                    c.maxDrumDiameter = value
                if (position == constants.BRAKE_POSITIONS.MIN_PAD_THICKNESS)
                    c.minRotorThickness = value


                c.eventType = eventType;
            }
            else if (c.axleNumber == axleNumber && c.position == position && c.component == props.brakesInfo.componentType) {
                c.measurement = value

                c.eventType = eventType;
            }
            return p.concat(c)
        }, [])

        props.dispatch({ type: constants.ADD_NEW_BRAKE_AXLE, dataForNewBrake: brakeAxles })
    }

    const onChangeMeasurementUnitForBrakesHandler = (value) => {

        props.dispatch({ type: constants.SAVE_BRAKES_MEASUREMENT_UNIT, defaultUnit: value });

        //update unit 
        let brakeAxles = props.brakesInfo.brakeAxlesFromState;

        brakeAxles = brakeAxles.reduce((p, c) => {
            c.unitOfMeasurement = value
            return p.concat(c)
        }, []);

        props.dispatch({ type: constants.ADD_NEW_BRAKE_AXLE, dataForNewBrake: brakeAxles })

    }
    // #endregion

    // #region variables
    const rotorImage = props.brakesInfo.brakeImages.isRotor ? <div ><img className='rotor-image' src="/assets/vms_Brake_Rotor.png" /></div> : false;
    const shoeImage = props.brakesInfo.brakeImages.isShoe ? <div ><img className='shoe-image' src="/assets/vms_brake_shoe.png" /></div> : false;
    const padImage = props.brakesInfo.brakeImages.isPad ? <div><img className='pad-image' src="/assets/vms_Brake_pad.png" /></div> : false;
    const drumImage = props.brakesInfo.brakeImages.isDrum ? <div ><img className='drum-image' src="/assets/vms_brake_drum.png" /></div> : false;

    const brakeImage =
        !rotorImage ?
            !shoeImage ?
                !padImage ?
                    !drumImage ?
                        false
                        : drumImage
                    : padImage
                : shoeImage
            : rotorImage

    const brakesTitle = props.brakesInfo.brakeImages.isRotor ? <FormattedMessage {...messages.brake_rotor} />
        : props.brakesInfo.brakeImages.isDrum ? <FormattedMessage {...messages.brake_drum} />
            : props.brakesInfo.brakeImages.isShoe || props.brakesInfo.brakeImages.isPad ? <FormattedMessage {...messages.brake_lining} />
                : false
    //#endregion    

    // #region Render
    return (
        <div>
            <FormWrapper key='addNewLineItemsErrorMessage'
                id="addNewLineItemsErrorMessage" {...props}
                formErrors={props.addNewLineItemsErrorMessage} />
            <FormWrapper key='lineItemQtyMismatchErrMessage'
                id="lineItemQtyMismatchErrMessage" {...props}
                formErrors={props.lineItemQtyMismatchErrMessage} />
            <Brakes
                stepNumber={props.stepNumber}
                title={brakesTitle}
                showCloseLink={true}
                ataParametersSorted={props.ataParametersSorted}
                showParameters={true}
                onSaveBrakeChanges={onSaveBrakeChangesHandler}
                isSavingBrakeInfo={false}
                onCloseWindow={onCloseWindowHandler}
                readOnly={props.readOnly}
                brakeComponentImage={brakeImage}
                defaultMeasurementUnit={props.brakesInfo.defaultBrakeMeasurementUnit}
                showSave={true}
                onChangeMeasurementUnit={onChangeMeasurementUnitForBrakesHandler}
                canAddAxle={props.brakesInfo.canAddBrakesAxle}
                canRemoveAxle={props.brakesInfo.canRemoveBrakesAxle}
                onAddAxle={onAddBrakeAxleHandler}
                onRemoveAxle={onRemoveBrakeAxleHandler}
                onChangeBrakeThickness={onChangeBrakeThicknessHandler}
                componentType={props.brakesInfo.componentType}
                brakeAxles={props.brakesInfo.brakeAxles}
                showValidationError={showBrakesValidationError}
                validationErrors={props.brakesValidationErrors}
                canShowUnitChangeConfirmation={props.brakesInfo.brakeAxlesFromState != undefined && props.brakesInfo.brakeAxlesFromState.length > 0}
            />
        </div>
    );
}
// #endregion

function mapToStateProps(state, props) {
    return {
        ...AddNewLineItemsSelector(state, props),
        addNewLineItemsErrorMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'errorMessage']),
        brakesErrorMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'brakes', 'errorMessage']),
        lineItemQtyMismatchErrMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'lineItemQtyMismatchErr']) || [],
        brakesValidationErrors: state.appState.getIn(['uiData', 'addNewLineItemsView', 'brakes', 'validationErrs']) || Map(),
        defaultBrakesMeasurementUnit: state.appState.getIn(['uiData', 'addNewLineItemsView', 'brakes', 'defaultBrakeMeasurementUnit']) || 'milli_meter'
    }
}
export default connect(mapToStateProps)(injectIntl(BrakesStepViewEditLine));