import { call, put, select, take, cancel, fork } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import configuration from 'configuration';
import * as constants from 'constants/App';
import { zeroIfUndefined, emptyMapIfUndefined,getSelectedLanguageCode } from 'utils/HelperFunctions';
import { Schema, arrayOf, normalize } from 'normalizr';
import * as helperFunctions from 'utils/HelperFunctions';
import { Map } from 'immutable';
import moment from 'moment';
import * as bitInspectionEnums from 'PO/ClosePO/screens/BitInspection/data/BitInspectionEnums';

export function* windowShown(context, action) {
    try {
        const state = yield select();                
        		
		const vehicleDetails = state.appState.getIn(['serverData', 'close', 'vehicleDetails']);		
        const uiVehicleDetails = state.appState.getIn(['uiData', 'close', 'invoiceDetails']);		

		yield put({ type: constants.BIT_INSPECTION__SET_DEFAULT_VALUES, 
			carrierName: vehicleDetails.get('clientName'),
			unitNumber: vehicleDetails.get('ariUnitNo'),		
			year: vehicleDetails.get('year'),		
			make: vehicleDetails.get('make'),				
			licensePlate: vehicleDetails.get('licensePlate'),
            assetType: vehicleDetails.get('assetType') ,
            mileage : uiVehicleDetails.get('odometer')
		});				
    }
    catch (e) {
        yield put({
            type: constants.FETCH_CLOSE_PO_DATA_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.FETCH_CLOSE_PO_DATA_REQUESTED,
            errorObject: e
        });
    }

}

export function* saveConfirmation(context, action) {
    try {
		yield put({type: constants.BIT_INSPECTION__SAVE_CONFIRMATION_REQUESTED});
        const state = yield select();
        
        const token = helperFunctions.getToken(state);
        
        const poNumber = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'id']);
		const assetType = state.appState.getIn(['serverData', 'close', 'vendor', 'assetType']);	        
        
        let bitInspectionData = state.appState.getIn(['uiData', 'bitInspection', 'formData']).toJSON();
        let otherComponentId = null;

        switch(assetType)
        {
            case 'trailer':
                otherComponentId = 121;
                break;
            case 'bus':
                otherComponentId = 190;
                break;
            default:
                otherComponentId = 91;
                break;					
        }
        
		const componentDetails = state.appState.getIn(['uiData', 'bitInspection', 'componentDetails'])
			.map(component => {
				return component.set('componentResultCode', component.get('id') == otherComponentId ? bitInspectionEnums.componentResultCode.NA : bitInspectionEnums.componentResultCode.PASS)
            });
            
		const componentDetailsJSON = componentDetails.toList().toJSON();
        const vendorLanguage = helperFunctions.getSelectedLanguageCode(state);
       
        // Set defaults
        bitInspectionData['id'] = 0;        
        bitInspectionData['componentDetails'] = componentDetailsJSON;
		bitInspectionData['vehicleOdometer'] = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'odometer']);

        yield call(Api.createBitInspection, poNumber, bitInspectionData, token,vendorLanguage);

		yield put({type: constants.BIT_INSPECTION__DISPLAY, show: false});

        // Continue where request payment left off.
		yield put({type: constants.CLOSE_PO_CLICKED, poNumber});
		
		yield put({type: constants.BIT_INSPECTION__SAVE_CONFIRMATION_RESOLVED});
    }
    catch (e) {
        yield put({
            type: constants.BIT_INSPECTION__SAVE_CONFIRMATION_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.BIT_INSPECTION__SAVE_CONFIRMATION_REQUESTED,
            errorObject: e
        });
    } 
}

export function* saveForm(context, action) {
    try {
		yield put({type: constants.BIT_INSPECTION__SAVE_FORM_REQUESTED});

        const state = yield select();        
        const token = helperFunctions.getToken(state);        
        const poNumber = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'id']);        
		let bitInspectionData = state.appState.getIn(['uiData', 'bitInspection', 'formData']).toJSON();				
		const componentDetails = state.appState.getIn(['uiData', 'bitInspection', 'componentDetails']);
		const componentDetailsJSON = componentDetails.toList().toJSON();
        const vendorLanguage = helperFunctions.getSelectedLanguageCode(state);
       
        // Set defaults
        bitInspectionData['id'] = 0;        
        bitInspectionData['componentDetails'] = componentDetailsJSON;
		bitInspectionData['vehicleOdometer'] = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'odometer']);

        yield call(Api.createBitInspection, poNumber, bitInspectionData, token,vendorLanguage);

		yield put({type: constants.BIT_INSPECTION__DISPLAY, show: false});
		
        // Continue where request payment left off.
		yield put({type: constants.CLOSE_PO_CLICKED, poNumber});
		
		yield put({type: constants.BIT_INSPECTION__SAVE_FORM_RESOLVED});
    }
    catch (e) {
        window.scrollTo(0, 0);
        yield put({
            type: constants.BIT_INSPECTION__SAVE_FORM_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.FETCH_CLOSE_PO_DATA_REQUESTED,
            errorObject: e
        });
    }  
}
