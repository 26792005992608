import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

class OldBrowserWarning extends React.Component {

    get_browser() {
        var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: 'IE', version: (tem[1] || '') };
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\bOPR|Edge\/(\d+)/)
            if (tem != null) { return { name: 'Opera', version: tem[1] }; }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
        return {
            name: M[0],
            version: M[1]
        };
    }

    isOldBrowser() {
        var browser = this.get_browser();

        return (browser.name.toLowerCase() === "chrome" && browser.version <= 48) ||
            (browser.name.toLowerCase() === "firefox" && browser.version <= 50);
    }

    render() {

        const messages = defineMessages({
            old_browser_warning: {
                id: 'old_browser_warning',
                description: 'Old browser warning',
                defaultMessage: 'Old browser warning'
            }
        });

        return this.isOldBrowser() ? <div><FormattedMessage {...messages.old_browser_warning} />
        </div>
            : false;
    }

}

export default OldBrowserWarning;