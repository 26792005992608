/**
 * Created by prboyapa on 4/6/2016.
 */
/**
 * Created by prboyapa on 4/6/2016.
 */
import React from 'react';
import {Map} from 'immutable';
import PurchaseOrderBasicDetails from 'AddEditView/PurchaseOrderBasicDetails';
import PurchaseOrderTitle from '../../AddEditView/components/PurchaseOrderTitle';
import POTotals  from './POTotals';
import AddServiceLineItemsView from '../../AddEditView/screens/AddServiceLineItemsView/containers/AddServiceLineItemsView';
import ApprovedLineItems from './ApprovedLineItems';
import FeeMessage from './FeeMessage';
import { PO_AUTHORIZATION_STATUS, PO_PAYMENT_STATUS } from 'constants/App';
import RepairNotes from 'AddEditView/RepairNotes';

class PaidPurchaseOrders extends React.Component {

    constructor(props) {
        super(props);
        this.displayName = 'PaidPurchaseOrders';
    }

    render() {
        let maintenanceNotesHistory = this.props.notesHistory
            .filter(note=>note.get('complaintCode')=='MA99');
        let ariPayNotesHistory = this.props.notesHistory
            .filter(note=>note.get('category')=='ARIPAY');
        return <div>

            {this.props.actionType == 'view'
                ? false
                : <AddServiceLineItemsView />
            }

            <PurchaseOrderTitle paymentStatus={this.props.paymentStatus}
                                authorizedStatus={this.props.authorizedStatus}
                                actionType={this.props.actionType}
                                poNumber={this.props.poNumber}
                                approvedPOTotal={this.props.approvedAmountTotal}
                                bankAccountNo={this.props.bankAccountNo}
                                taxAmount={this.props.taxAmount}
                                paymentInformation={this.props.paymentInformation}
                                paymentDate={this.props.paymentDate}
                                selectedCurrency={this.props.selectedCurrency}
                                hideCostInformation={!this.props.canShow}
                                features={this.props.features}
            />

            <PurchaseOrderBasicDetails vehicleDetails={this.props.vehicleDetails}
                                       actionType={this.props.actionType}
                                       client={this.props.client}
                                       paymentStatus={this.props.paymentStatus}
                                       authorizedStatus={this.props.authorizedStatus}
                                       vendorAccountType={this.props.vendorAccountType}
                                       poVendorId={this.props.poVendorId}
                                       businessAddress={this.props.businessAddress}
                                       loggedInVendorId={this.props.loggedInVendorId}
                                          />
            
            {
                this.props.vehicleDetails.get('isHsbClientVehicle') &&
                this.props.actionType == 'view' &&
                this.props.notesHistory !== undefined && this.props.notesHistory.filter(n => n.get('category') == 'CLIENT_VENDOR').size > 0 &&
                this.props.poNumber !== undefined ? <RepairNotes notesHistory={this.props.notesHistory} /> : false
            }

            <ApprovedLineItems complaints={this.props.complaints}
                               nonMaintenanceNotes={this.props.notesHistory}
                               maintenanceNotesHistory={maintenanceNotesHistory}
                               lineItems={this.props.lineItems}
                               paymentStatus={this.props.paymentStatus}
                               authorizedStatus={this.props.authorizedStatus}
                               actionType={this.props.actionType.toUpperCase()}
                               hideCostInformation={!this.props.canShow}
                               selectedCurrency={this.props.selectedCurrency}
                               listOfComplaintsForWhichNoteIsRequired={this.props.listOfComplaintsForWhichNoteIsRequired}
            />

            {
                this.props.canShow == true ?
                    /*Render the PO Totals if lines or available or not */
                    <POTotals approvedAmountTotal={this.props.approvedAmountTotal}
                              pendingAmountTotal={this.props.pendingAmountTotal}
                              actionType={this.props.actionType.toUpperCase()}
                              taxAmount={this.props.taxAmount}
                              selectedCurrency={this.props.selectedCurrency}
                    />
                    :false
            }

            {(this.props.paymentStatus == PO_PAYMENT_STATUS.PAID
                || this.props.paymentStatus == PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS) &&
              
               <FeeMessage feeInfo={this.props.feeInfo} 
                           noteText={ariPayNotesHistory}
               />
            } 

        </div>
    }
}



export default PaidPurchaseOrders;