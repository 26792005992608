// #region Imports
import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Api from "utils/Api";
import * as constants from "constants/App";
import styled from "styled-components";
import Spinner from "components/Spinner";
import ReactPaginate from "react-paginate";
import FormWrapper from "components/FormWrapper";
import * as helperFunctions from "utils/HelperFunctions";
import EmailConfirmation from "common/EmailConfirmation";
import DeleteUserModal from "common/DeleteUserModal";
import {
  PageHeader,
  Subhead,
  ThirdLevelSubhead
} from "DesignSpecs/components/FontGuide";
import { PageLayout, PageLayoutClear } from "DesignSpecs/components/Layouts";
import {
  Label,
  FormTextGray,
  Input,
  InputFeedback,
  Image,
  SubmitImage,
  IconImage,
  Row,
  Col
} from "DesignSpecs/components/FormComponents";

import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import Pagination from "common/Pagination";
// #endregion

// #region Styled Components
const NoResultsText = styled.div`
    font-size: 16px;
	  font-family: "Gotham SSm A", "Gotham SSm B";
   	color: #000000;
    font-style: normal;
    font-weight: 300;
    padding-left: 11em;
`;

const ClearImageStyled = styled.img`
  display: block;
  position: absolute;
  top: 2px;
  right: 3px; 
  cursor: pointer;                                
  width: 22px;
  height: 22px;
`;
// #endregion

// #region Component
const Users = ({
  token,
  personaUserId,
  intl,
  dispatch,
  routeParams
}) => {
  // #region State
  const loadingStates = { loading: "loading", loaded: "loaded", error: "error" };
  const { formatMessage } = intl;
  const [loadingState, setLoadingState] = useState(loadingStates.loading);
  const [searchResultLoadingState, setSearchResultLoadingState] = useState(
    loadingStates.loaded
  );
  const [manageUserLoadingState, setManageUserLoadingState] = useState(
    loadingStates.loaded
  );
  const [error, setError] = useState();
  const [users, setUsers] = useState();
  const [pageCount, setPageCount] = useState();
  const [userSearchKeyWord, setUserSearchKeyword] = useState("");
  const [forcePage, setForcePage] = useState();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [userCount, setUserCount] = useState();
  const pageSize = 20;
  const [userId, setUserId] = useState();
  const [personaManageUserErrorMessage, setPersonaManageUserErrorMessage] = useState();
  const [showEmailConfirmation, setEmailConfirmation] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [showDeleteUserConfirmationDialog, setDeleteUserConfirmationDialogVisible] = useState(false);
  const [deleteUserInformation, setDeleteUserInformation] = useState(null);
  const [isDeletingUser, setIsDeletingUser] = useState(null);
  const [isDeleteUserError, setDeleteUserError] = useState(null);
  // #endregion

// #region Event handlers
  function handlePageClick(data) {
    setPersonaManageUserErrorMessage(null);
    setCurrentPageNumber(data.selected);
  }

  function onSearchClick(value) {
    setPersonaManageUserErrorMessage(null);
    setCurrentPageNumber(0);
    setUserSearchKeyword(value);
  }

  function deleteUserClicked(userId) {
    setPersonaManageUserErrorMessage(null);
    setUserId(userId);
    setDeleteUserConfirmationDialogVisible(true);
  }
  function handleDeleteUser() {

    setDeleteUserInformation({ adminUserId : personaUserId, deletedUser: userId });

    if (!isDeletingUser)
      setDeleteUserConfirmationDialogVisible(false);
  }
  async function deleteUserAsync() {

    try {
    
      await Api.DeleteUser(
        deleteUserInformation.adminUserId,
        deleteUserInformation.deletedUser,
        token
      );

    } catch (error) {

      if (error !== undefined && error.code === 'API-004')
        setDeleteUserError(error);

      setIsDeletingUser(false);
    }


    setIsDeletingUser(false);

  }
  async function Fetch() {
    let didCancel = false;

    const fetchData = async () => {
      try {
        setLoadingState(loadingStates.loading);

        const [userResult] = await Promise.all([
          Api.fetchUsers(
            personaUserId,
            currentPageNumber == undefined ? 0 : currentPageNumber,
            pageSize,
            userSearchKeyWord,
            token
          )
        ]);

        if (!didCancel) {
          setUserCount(userResult.userCount);
          setUsers(userResult.users);
          setPageCount(Math.ceil(userResult.userCount / pageSize));
          setLoadingState(loadingStates.loaded);
        }
      } catch (e) {
        if (!didCancel) {
          setLoadingState(loadingStates.error);
          setPersonaManageUserErrorMessage(helperFunctions.getCustomErrorObject(e));
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }


  async function sendUserInvitationLink(userId, firstName, lastName, emailAddress) {
    setPersonaManageUserErrorMessage(null);
    const fetchData = async () => {

      await Promise.all([
        Api.sendUserInvitationLink(
          userId,
          personaUserId,
          emailAddress,
          token
        )
      ]);
      setUserId(userId);
      setFirstName(firstName);
      setLastName(lastName);
      setEmailAddress(emailAddress);
      setEmailConfirmation(true);

    }
    fetchData();
  }

  const handleSubmit = useCallback(data => { });
 // #endregion

 // #region Effects
  useEffect(
    () => {
      if ((!isDeletingUser))
      Fetch();
      
    },
    [userSearchKeyWord, currentPageNumber,isDeletingUser]
  );

  //if deleted user api errors
  useEffect(
    () => {
      if (isDeleteUserError != null)
      setPersonaManageUserErrorMessage(helperFunctions.getCustomErrorObject(isDeleteUserError));
    },
    [isDeleteUserError]
  );

  useEffect(() => {

    if (deleteUserInformation !== null) {

      try {

        setIsDeletingUser(true);

        deleteUserAsync();

      }
      catch (ex) {

        setDeleteUserError(ex)
        setIsDeletingUser(false);

      }

    }

  }, [deleteUserInformation]);

// #endregion

// #region International messages
  const messages = defineMessages({
    manage_users__title: {
      id: "manage_users__title",
      description: "Manage users",
      defaultMessage: "Manage users"
    },

    manage_users_name: {
      id: "manage_users_name",
      description: "Name",
      defaultMessage: "Name"
    },

    manage_users_email: {
      id: "manage_users_email",
      description: "Email",
      defaultMessage: "Email"
    },

    manage_users_resend_invite_btn: {
      id: "manage_users_resend_invite_btn",
      description: "Send invite",
      defaultMessage: "Send invite"
    },
    manage_users__no_search_results: {
      id: "manage_users__no_search_results",
      description: "No users matching the search criteria found",
      defaultMessage: "No users matching the search criteria found"
    },
    manage_users__pagination_results_caption: {
      id: "manage_users__pagination_results_caption",
      description: "Showing results {first}-{last} of {userCount}",
      defaultMessage: "Showing results {first}-{last} of {userCount}"
    },
    manage_users__pagination_results_search_caption: {
      id: "manage_users__pagination_results_search_caption",
      description: " for the search term",
      defaultMessage: " for the search term"
    },    
    manage_users__search_text_place_holder: {
      id: "manage_users__search_text_place_holder",
      description: "Search for user",
      defaultMessage: "Search for user"
    },
    manage_users__search_previous: {
      id: "manage_users__search_previous",
      description: "previous",
      defaultMessage: "previous"
    },
    manage_users__search_next: {
      id: "manage_users__search_next",
      description: "next",
      defaultMessage: "next"
    },
    add_user__title: {
      id: "add_user__title",
      description: "Set up new user",
      defaultMessage: "Set up new user"
    },
    manage_users__search_no_users_text: {
      id: "manage_users__search_no_users_text",
      description: "No results found for this search criteria.",
      defaultMessage: "No results found for this search criteria."
    },
    manage_users__no_users_to_manage: {
      id: "manage_users__no_users_to_manage",
      description: "You are not currently managing any users.",
      defaultMessage: "You are not currently managing any users."
    },
    email_confirmation_title: {
      id: "email_confirmation_title",
      description: "Email Confirmation",
      defaultMessage: "Email Confirmation"
    },
    email_confirmation_message: {
      id: "email_confirmation_message",
      description: "An invitation link has been sent to {firstName} {lastName}.",
      defaultMessage: "An invitation link has been sent to {firstName} {lastName}."
    },
    email_confirmation_ok: {
      id: "email_confirmation_ok",
      description: "Ok",
      defaultMessage: "Ok"
    },
    manage_users_no_users_text: {
      id: "manage_users_no_users_text",
      description: "You have no users to manage.",
      defaultMessage:  "You have no users to manage."
    },
    delete_user__title: {
      id: "delete_user__title",
      description: "Delete user",
      defaultMessage: "Delete user"
    },
    delete_user__message: {
      id: "delete_user__message",
      description: "Are you sure you want to delete this user? Doing so will remove the user from Holman PartnerConnect and their access to any stores will be removed.",
      defaultMessage: "Are you sure you want to delete this user? Doing so will remove the user from Holman PartnerConnect and their access to any stores will be removed."
    },
    delete_user_yes: {
      id: "delete_user_yes",
      description: "Yes",
      defaultMessage: "Yes"
    },
    delete_user_no: {
      id: "delete_user_no",
      description: "No",
      defaultMessage: "No"
    },
    delete_user: {
      id: "delete_user",
      description: "Delete User",
      defaultMessage: "Delete User"
    },
    user_profile: {
      id: "user_profile",
      description: "User profile",
      defaultMessage: "User profile"
    }
  });
// #endregion

// #region Render
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          userSearchKeyWord: "",
          UserList: []
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSearchClick(values.userSearchKeyWord);
        }}
      >

        {props => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              
              {<EmailConfirmation
                title={messages.email_confirmation_title}
                message={messages.email_confirmation_message}
                okButtonText={messages.email_confirmation_ok}
                onOKClickEvent={() => { setEmailConfirmation(false); }}
                show={showEmailConfirmation}
                firstName={firstName}
                lastName={lastName}
              />}
              {<DeleteUserModal
                FormattedMessageTitle={messages.delete_user__title}
                FormattedMessageBody={messages.delete_user__message}
                FormattedMessageOkButtonText={messages.delete_user_yes}
                FormattedMessageCancelButtonText={messages.delete_user_no}
                onOKClickEvent={handleDeleteUser}
                onCancelClickEvent={() => { setDeleteUserConfirmationDialogVisible(false); }}
                showCancelButton={true}
                show={showDeleteUserConfirmationDialog}
                isFetchingData={isDeletingUser}
              />}
              <div>

                <PageLayout>
                  <FormWrapper
                    key="manageUserErrorMessage"
                    id="manageUserErrorMessage"
                    formErrors={personaManageUserErrorMessage}
                  />
                  <Subhead>
                    <FormattedMessage {...messages.manage_users__title} />

                  </Subhead>                  

                  <div css={`
                    display: flex;
                    justify-content: flex-end;
                  `}>                        
                    <div css={`
                      position: relative;
                    `}>

                      <Input
                        id="txtUserSearch"
                        type="text"
                        value={values.userSearchKeyWord}
                        onChange={e => {
                          setFieldValue(
                            "userSearchKeyWord",
                            e.target.value,
                            true
                          );


                        }}
                        placeholder={formatMessage(
                          messages.manage_users__search_text_place_holder
                        )}
                        onBlur={handleBlur}
                        error={errors.userSearchKeyWord}
                        touched={touched.userSearchKeyWord}
                      />
                      
                      {!!values.userSearchKeyWord &&
                        <ClearImageStyled 
                          src='/assets/VMS_33button_kill_clear_inline.png'                           
                          onClick={()=>{
                            setUserSearchKeyword('');
                            setCurrentPageNumber(0); 
                            setFieldValue("userSearchKeyWord", '', true);                                
                          }}
                        />
                      }
                    </div>
                    
                    <div>
                      &nbsp;
                      <span style={{ display: "inline-block" }}>
                        {searchResultLoadingState ==
                          loadingStates.loading
                          ? <div
                            style={{
                              display: "inline-block",
                              width: "25px",
                              height: "25px",
                              position: "inherit",
                              "vertical-align": "top"
                            }}
                          >
                            <Spinner />
                          </div>
                          : <SubmitImage
                            type="image"
                            src="/assets/VMS_search_button_blue.png"
                            alt="Submit"
                            onclick={handleSubmit}
                          />}
                      </span>
                      <div>
                        {errors.userSearchKeyWord &&
                          touched.userSearchKeyWord &&
                          <InputFeedback>
                            {errors.userSearchKeyWord}
                          </InputFeedback>}
                      </div>
                    </div>
                  </div>

                  <Row>
                    <Col />
                    <Col padTop="2em" width="15.3em">
                      <Link to={`/Persona/Users/add`}>
                        <Image src="/assets/VMS_33button_add_Clear background.png" />{' '}
                        <b><FormattedMessage {...messages.add_user__title}/></b>
                      </Link>
                    </Col>
                  </Row>
                  <br />
                  {loadingState == loadingStates.loading
                    ? <Spinner />
                    :<div> { users == null || users.length == 0
                      ? false

                      : <div style={{
                        "paddingTop": "15px"
                      }}>
                        <Row>
                          <FormTextGray>
                            <FormattedMessage
                              values={{
                                first: (currentPageNumber * pageSize) + 1,
                                last: (currentPageNumber * pageSize) + users.length,
                                userCount:userCount
                              }}
                              {...messages.manage_users__pagination_results_caption}
                            />
                            {userSearchKeyWord != undefined && userSearchKeyWord != '' ?
                              <span>
                                <span>
                                  <FormattedMessage                                  
                                    {...messages.manage_users__pagination_results_search_caption}
                                  />                                
                                </span>
                                {' "'}
                                <span css={`
                                  color: ${({theme})=>theme.color.primaryBlue}
                                `}>
                                  {userSearchKeyWord}
                                </span>
                                {'"'}
                              </span> : false
                            }

                          </FormTextGray>
                    
                        </Row>
                        </div>
                        }
                        <br />
                        <Row fontWeight="bold">
                          <Col width="22rem">
                            <Label>
                              <FormattedMessage
                                {...messages.manage_users_name}
                              />

                            </Label>
                          </Col>

                          <Col width="9rem">
                            <Label>
                              <FormattedMessage {...messages.manage_users_resend_invite_btn} />
                            </Label>
                          </Col>
                          <Col width="10rem">
                            <Label>
                            <FormattedMessage {...messages.delete_user} />
                            </Label>
                          </Col>
                          <Col width="13rem">
                            <Label>
                            <FormattedMessage {...messages.user_profile} />
                            </Label>
                          </Col>
                        </Row>

                        <Row>
                          <Col width="100%">
                            <div
                              style={{
                                "borderTop": "2px solid black",
                                width: "100%"
                              }}
                            />

                          </Col>
                        </Row>

                      { (users == null || users.length == 0) && userSearchKeyWord === ''
                        ? <div>
                            <NoResultsText>
                              <FormattedMessage                                
                                  {...messages.manage_users__no_users_to_manage}
                              />
                            </NoResultsText>
                        </div>
                        : users == null || users.length == 0
                        ? <div>
                          <NoResultsText>
                            <span>
                              <FormattedMessage                                
                                {...messages.manage_users__search_no_users_text}
                              />                            
                            </span>
                          </NoResultsText>
                          
                        </div>
                        : !!users &&
                        users.map(users => {
                          return (
                            <div
                              key={users.id}
                              style={{
                                "borderBottom": "1px solid grey",
                                width: "100%",
                                "paddingTop": "5px"
                              }}
                            >
                              <Row>

                                  <Col width="25em">
                                      {users.firstName} &nbsp;
                                        {users.lastName}
                                    <br />
                                    {users.emailAddress}
                                  </Col>

                                  <Col width="12em" padTop="5px">
                                    <IconImage
                                      src="/assets/Email_Icon.png"
                                      onClick={() => {
                                        sendUserInvitationLink(users.id, users.firstName, users.lastName, users.emailAddress);
                                      }}

                                    />

                                  </Col>
                                  <Col width="12em" padTop="5px">
                                    <IconImage
                                     onClick={() => { deleteUserClicked(users.id); }}
                                      src="/assets/Delete_Button_Light_Blue.png"
                                    />
                                  </Col>
                                  <Col width="9em" padTop="5px">
                                  <Link to={`Users/${users.id}`}>
                                    <IconImage
                                      src="/assets/Multiple User icons.png"
                                    />
                                    </Link>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}

                        <Row>
                          <Col width="28rem" />
                          <Col width="38rem">
                            {userCount <= 20 ? false :
                              <ReactPaginate
                                previousLabel={formatMessage(
                                  messages.manage_users__search_previous
                                )}
                                nextLabel={formatMessage(
                                  messages.manage_users__search_next
                                )}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                disableInitialCallback={true}
                                forcePage={currentPageNumber}
                              />
                            }
                          </Col>
                          <Col width="15rem" />
                        </Row>
                      </div>}
                </PageLayout>

              </div>
                
            </form>
          );
        }}

      </Formik>

    </div>
  );
  // #endregion
};
// #endregion

// #region Props
const mapStateToProps = state => {
  return {
    token: state.appState.getIn(["serverData", "shared", "personaToken"]),
    personaUserId: state.appState.getIn([
      "serverData",
      "shared",
      "personaUserId"
    ])
  };
};
// #endregion

export default connect(mapStateToProps)(injectIntl(Users));
