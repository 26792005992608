import React from 'react';
import ReactDOM from 'react-dom';
import { defineMessages, FormattedMessage ,injectIntl,RawIntlProvider} from 'react-intl';
import { Link } from 'react-router-dom';
import './Request.css';
import Submit from 'components/Submit';
import * as constants from 'constants/App';
import { Popover, Overlay } from 'react-bootstrap';
import * as helperFunctions from 'utils/HelperFunctions';
import Recaptcha from '../../../../shared/components/Recaptcha';
import classnames from "classnames";


class Request extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formValidated: false,   //whether form is validated or not 
            currentFocusField: []   //array of fields that have recieved focus event
        }
    }
    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }
    onChangePhone(number, type, inputName, event) {
        this.props.onRequestInfoChange(inputName, event);
        if (event.target.value.length >= 3) {
            number += 1;
            document.getElementById((type + number).toString()).focus();
        }
    }

    onFieldFocus(fieldName) {
        let updatedFocusFields = this.state.currentFocusField.concat(fieldName);
        this.setState({ currentFocusField: updatedFocusFields });
    }

    isFieldValid(fieldName) {
        return (!this.state.currentFocusField.includes(fieldName) && this.state.formValidated
            && this.props.isRequestFormValid.inValidFields.includes(fieldName));
    }
    renderValidationOverlay(fieldName, message, placement) {
        return <Overlay
        show={this.isFieldValid(fieldName)}
        target={() => ReactDOM.findDOMNode(this.refs[fieldName])}
        placement={placement != undefined ? placement : "right"}
        container={this}>
            <Popover id={"popOver" + fieldName} className="enrollment-request__error_message">
            <RawIntlProvider value={this.props.intl}>
                <FormattedMessage {...message} />
                </RawIntlProvider>
            </Popover>
        </Overlay>
            }
    onSubmit() {
                this.setState({ formValidated: true, currentFocusField: [] });
                if (this.props.isRequestFormValid.isFormValid) {
                    this.props.onSubmit();
                }
            }
    render() {
        const messages = defineMessages({
            header: {
                id: 'header',
                description: 'Supplier Enrollment Request',
                defaultMessage: 'Supplier Enrollment Request'
            },
            physical_address: {
                id: 'physical_address',
                description: 'Physical address',
                defaultMessage: 'Business Name & Address'
            },
            business_name: {
                id: 'business_name',
                description: 'Business Name',
                defaultMessage: 'Business Name'
            },
            address_1: {
                id: 'address_1',
                description: 'Address 1',
                defaultMessage: 'Address 1'
            },
            address_2: {
                id: 'address_2',
                description: 'Address 2',
                defaultMessage: 'Address 2'
            },
            city: {
                id: 'city',
                description: 'City',
                defaultMessage: 'City'
            },
            state: {
                id: 'state',
                description: 'State',
                defaultMessage: 'State'
            },
            zip: {
                id: 'zip',
                description: 'Zip',
                defaultMessage: 'Zip'
            },
            country: {
                id: 'country',
                description: 'Country',
                defaultMessage: 'Country'
            },
            contact_information: {
                id: 'contact_information',
                description: 'Contact Information',
                defaultMessage: 'Contact Information'
            },
            name: {
                id: 'name',
                description: 'Name',
                defaultMessage: 'Name'
            },
            phone: {
                id: 'phone',
                description: 'Phone',
                defaultMessage: 'Phone'
            },
            ext: {
                id: 'ext',
                description: 'Ext.',
                defaultMessage: 'Ext.'
            },
            email: {
                id: 'email',
                description: 'Email',
                defaultMessage: 'Email'
            },
            reenter_email: {
                id: 'reenter_email',
                description: 'Re-enter email',
                defaultMessage: 'Re-enter email'
            },
            fax: {
                id: 'fax',
                description: 'Fax (optional)',
                defaultMessage: 'Fax (optional)'
            },
            referral_code: {
                id: 'referral_code',
                description: 'Referral Code',
                defaultMessage: 'Referral Code'
            },
            referral_code_note: {
                id: 'referral_code_note',
                description: 'If you have a referral code enter it here',
                defaultMessage: 'If you have a referral code enter it here'
            },
            cancel: {
                id: 'cancel',
                description: 'Cancel',
                defaultMessage: 'Cancel'
            },
            submit: {
                id: 'submit',
                description: 'Submit',
                defaultMessage: 'Submit'
            },
            supplier_enrollment_subheader:{
                id: 'supplier_enrollment_subheader',
                description: 'supplier_enrollment_subheader',
                defaultMessage: 'Already a Holman Supplier? Please return to the main page to sign in with your ID and Password'
            },
            province:{
                id:'province',
                description:'Province',
                defaultMessage:'Province'
            },
            postalCode:{
                id:'postalCode',
                description:'Postal Code',
                defaultMessage:'Postal Code'
            },
            select_country:{
                id: 'select_country',
                description: 'select_country',
                defaultMessage: 'Select Country'
            },
            select_country_usa:{
                id: 'select_country_usa',
                description: 'select_country_usa',
                defaultMessage: 'USA'
            },
            select_country_canada:{
                id: 'select_country_canada',
                description: 'select_country_canada',
                defaultMessage: 'Canada'
            },
            select_state:{
                id: 'select_state',
                description: 'select_state',
                defaultMessage: 'Select State'
            },
            select_providence:{
                id: 'select_providence',
                description: 'select_providence',
                defaultMessage: 'Select Province'
            },
            recaptcha_title: {
                id: 'recaptcha_title',
                description: 'recaptcha_title',
                defaultMessage: 'Please click checkbox below, this is a security measure to avoid spam.'
            },
        })
        const errorMessages = defineMessages({
            business_name_required: {
                id: 'business_name_required',
                description: 'Please enter Business name',
                defaultMessage: 'Please enter Business name'
            },
            address1_required: {
                id: 'address1_required',
                description: 'Please enter Address 1',
                defaultMessage: 'Please enter Address 1'
            },
            address2_required: {
                id: 'address2_required',
                description: 'Please enter Address 2',
                defaultMessage: 'Please enter Address 2'
            },
            city_required: {
                id: 'city_required',
                description: 'Please enter City',
                defaultMessage: 'Please enter City'
            },
            state_required: {
                id: 'state_required',
                description: 'Please select a State',
                defaultMessage: 'Please select a State'
            },
            zip_required: {
                id: 'zip_required',
                description: 'Please enter Zip',
                defaultMessage: 'Please enter Zip code of 5 or 9 digits'
            },
            postalCode_required:{
                id:'postalCode_required',
                description:'Please enter a valid Postal Code in the format: XXX XXX',
                defaultMessage:'Please enter a valid Postal Code in the format: XXX XXX'
            },
            country_required: {
                id: 'country_required',
                description: 'Please select a Country',
                defaultMessage: 'Please select a Country'
            },
            contact_name_required: {
                id: 'contact_name_required',
                description: 'Please enter Contact name',
                defaultMessage: 'Please enter Contact name'
            },
            phone_area_required: {
                id: 'phone_area_required',
                description: 'Please enter Phone',
                defaultMessage: 'Please enter Phone'
            },
            phone_prefix_required: {
                id: 'phone_prefix_required',
                description: 'Please enter Phone',
                defaultMessage: 'Please enter Phone'
            },
            phone_suffix_required: {
                id: 'phone_suffix_required',
                description: 'Please enter Phone number',
                defaultMessage: 'Please enter Phone number'
            },
            email_required: {
                id: 'email_required',
                description: 'Please enter an email address (example: email@domain.com)',
                defaultMessage: 'Please enter an email address (example: email@domain.com)'
            },
            re_enter_email_required: {
                id: 're_enter_email_required',
                description: 'Please re-enter correct email address',
                defaultMessage: 'Please re-enter correct email address'
            },
            fax_required:{
                id: 'fax_required',
                description: 'Please enter valid Fax number',
                defaultMessage: 'Please enter valid Fax number'
            },
            province_required:{
                id: 'province_required',
                description: 'Please select a Province',
                defaultMessage: 'Please select a Province'
            },
                        
        })

        const {formatMessage} = this.props.intl;

        return (
            <div>               
                
                <div className='enrollment-request__header' >
                    <FormattedMessage {...messages.header} />
                </div>
                <div className="enrollment-request__subheader">
                    <FormattedMessage {...messages.supplier_enrollment_subheader}/>
                </div>
                <div className='enrollment-request__physical-address'>
                    {this.props.error}
                    <div className='enrollment-request__sub-header'>
                        <FormattedMessage {...messages.physical_address } />
                    </div>
                    <div className='enrollment-request__input-group'>
                        <label ><FormattedMessage {...messages.business_name } /></label>
                        <input type="text"
        ref='businessName'
        className='enrollment-request__block enrollment-request__input'
        onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.BUSINESS_NAME)}
        onFocus={this.onFieldFocus.bind(this, 'businessName')}
        onKeyPress={helperFunctions.isAlphaSpecial.bind(this)} />
    {this.renderValidationOverlay('businessName', errorMessages.business_name_required)}
</div>

<div className='enrollment-request__input-group'>
    <label ><FormattedMessage {...messages.country } /></label>
    <select className='enrollment-request__block enrollment-request__input' ref='country'
        onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.COUNTRY)}
        onFocus={this.onFieldFocus.bind(this, 'country')} 
        value={this.props.enrollmentRequestInfo.get('country')} >
        <option key="select_country" value="">{formatMessage(messages.select_country)}</option>
        <option key="USA" value="USA" >{formatMessage(messages.select_country_usa)}</option>
        <option key="CAN" value="CAN" >{formatMessage(messages.select_country_canada)}</option>
    </select>
        {this.renderValidationOverlay('country', errorMessages.country_required)}

        </div>

        <div className='enrollment-request__input-group' >
            <label  ><FormattedMessage {...messages.address_1 } /></label>
            <input type="text"
        ref='address1'
        className='enrollment-request__block enrollment-request__input'
        onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.ADDRESS_1)}
        onFocus={this.onFieldFocus.bind(this, 'address1')}
        onKeyPress={helperFunctions.isAlphaNumeric.bind(this)}
    />
    {this.renderValidationOverlay('address1', errorMessages.address1_required)}

</div>
<div className='enrollment-request__input-group'>
    <label ><FormattedMessage {...messages.address_2 } /></label>
    <input type="text" ref='address2' className='enrollment-request__block enrollment-request__input'
        onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.ADDRESS_2)}
        onFocus={this.onFieldFocus.bind(this, 'address2')}
        onKeyPress={helperFunctions.isAlphaNumeric.bind(this)}
    />
</div>
<div className='row '>
    <div className='col-sm-2 enrollment-request__input-group enrollment-request__input-group-city'>
        <label ><FormattedMessage {...messages.city } /></label>
        <input type="text" ref='city' className='enrollment-request__block enrollment-request__input enrollment-request__input-city'
        onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.CITY)}
        onFocus={this.onFieldFocus.bind(this, 'city')}
        onKeyPress={helperFunctions.isAlphaSpace.bind(this)} />
    {this.renderValidationOverlay('city', errorMessages.city_required)}

</div>
<div className='col-sm-2 enrollment-request__input-group enrollment-request__input-group-state'>
    <label >{this.props.enrollmentRequestInfo.get('country')=='CAN'?
        <FormattedMessage {...messages.province } />:<FormattedMessage {...messages.state } />}</label>
    <select ref='state' className='enrollment-request__block  enrollment-request__input enrollment-request__input-state'
                                onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.STATE)}
    onFocus={this.onFieldFocus.bind(this, 'state')} >
    {this.props.enrollmentRequestInfo.get('country')=='CAN'?<option key="select_state" value="" default>{formatMessage(messages.select_providence)}</option>:
    <option key="select_state" value="" default>{formatMessage(messages.select_state)}</option>}
        {
            this.props.enrollmentRequestInfo.get('country')=='CAN'?
            this.props.canadianProvinces.map((item) => {
                return <option key={item.abbreviation} value={item.abbreviation}>{item.name}</option>
            }):
            this.props.usStates.map((item) => {
                return <option key={item.abbreviation} value={item.abbreviation}>{item.abbreviation}</option>
            })

        }
        </select>
        {this.renderValidationOverlay('state',this.props.enrollmentRequestInfo.get('country')=='CAN'?errorMessages.province_required:errorMessages.state_required)}

        </div>
        <div className='col-sm-3 enrollment-request__input-group enrollment-request__input-group-zip'>
            <label >{this.props.enrollmentRequestInfo.get('country')=='CAN'?<FormattedMessage {...messages.postalCode } />:<FormattedMessage {...messages.zip } />}</label>
            <input type="text" ref='zip' className='enrollment-request__block enrollment-request__input enrollment-request__input-zip'
    onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.ZIP)}
    onFocus={this.onFieldFocus.bind(this, 'zip')}
        onKeyPress={this.props.enrollmentRequestInfo.get('country')=='CAN'? helperFunctions.isAlphaNumeric.bind(this): helperFunctions.isZipCode.bind(this)}
        maxLength={this.props.enrollmentRequestInfo.get('country')=='CAN'?'7':'10'} />                            
    {this.renderValidationOverlay('zip',this.props.enrollmentRequestInfo.get('country')=='CAN'?errorMessages.postalCode_required:errorMessages.zip_required)}
                            

</div>
</div>
                    
<div>

</div>

</div>
<div className='enrollment-request__contact-information'>
    <div className='enrollment-request__sub-header'><FormattedMessage {...messages.contact_information } /></div>
    <div className='row'>
        <div className='col-sm-6 enrollment-request__input-group'>
            <label className='enrollment-request__label'><FormattedMessage {...messages.name } /></label>
            <input type="text" ref='contactName' className='enrollment-request__block enrollment-request__input'
        onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.CONTACT_NAME)}
        onFocus={this.onFieldFocus.bind(this, 'contactName')}
        onKeyPress={helperFunctions.isAlphaSpace.bind(this)} />
    {this.renderValidationOverlay('contactName', errorMessages.contact_name_required, 'top')}

</div>
<div className=''>
    <div className='col-sm-5 enrollment-request__phone'>
        <label ><FormattedMessage {...messages.phone } /></label>
        <div className='enrollment-request__block  enrollment-request__input'>
            <input type="text" ref='phoneArea' id="phone1" maxLength="3" className='enrollment-request__input-area-code'
        onChange={this.onChangePhone.bind(this, 1, 'phone', constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.PHONE_AREA)}
        onFocus={this.onFieldFocus.bind(this, 'phoneSuffix')}
        onKeyPress={helperFunctions.isNumeric.bind(this)}
    />-
    <input type="text" ref='phonePrefix' id="phone2" maxLength="3" className='enrollment-request__input-3-chars'
        onChange={this.onChangePhone.bind(this, 2, 'phone', constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.PHONE_PREFIX)}
        onFocus={this.onFieldFocus.bind(this, 'phoneSuffix')}
        onKeyPress={helperFunctions.isNumeric.bind(this)}
    />-
    <input type="text" ref='phoneSuffix' id="phone3" maxLength="4" className='enrollment-request__input-4-chars'
        onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.PHONE_SUFFIX)}
        onFocus={this.onFieldFocus.bind(this, 'phoneSuffix')}
        onKeyPress={helperFunctions.isNumeric.bind(this)}
    />
    {this.renderValidationOverlay('phoneSuffix', errorMessages.phone_area_required)}
</div>
</div>
<div className='col-sm-1 enrollment-request__ext'>
    <div>
        <label><FormattedMessage {...messages.ext } /></label>
        <input type="text" className='enrollment-request__block enrollment-request__input-4-chars enrollment-request__input-ext'
        onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.EXTENSION)}
        onKeyPress={helperFunctions.isNumeric.bind(this)}
        maxLength = {'5'}
    />
</div>
</div>
</div>
</div>
<div className='row'>
    <div className='col-sm-6 enrollment-request__input-group'>
        <label ><FormattedMessage {...messages.email } /></label>
        <input type="text" ref='email' className='enrollment-request__block enrollment-request__input'
        onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.EMAIL)}
        onFocus={this.onFieldFocus.bind(this, 'email')} />
    {this.renderValidationOverlay('email', errorMessages.email_required)}
</div>

<div className='col-sm-5 enrollment-request__input-group enrollment-request__phone'>
    <label className='enrollment-request__label'><FormattedMessage {...messages.fax } /></label>
    <input type="text" id="fax1" maxLength="3" className='enrollment-request__input-area-code'
        onChange={this.onChangePhone.bind(this, 1, 'fax', constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.FAX_AREA)}
        onFocus={this.onFieldFocus.bind(this, 'faxSuffix')}
        onKeyPress={helperFunctions.isNumeric.bind(this)} />-
                <input type="text" id="fax2" maxLength="3" className='enrollment-request__input-3-chars'
        onChange={this.onChangePhone.bind(this, 2, 'fax', constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.FAX_PREFIX)}
        onFocus={this.onFieldFocus.bind(this, 'faxSuffix')}
        onKeyPress={helperFunctions.isNumeric.bind(this)} />-
                <input type="text" ref='faxSuffix' id="fax3" maxLength="4" className='enrollment-request__input-4-chars'
        onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.FAX_SUFFIX)}
        onFocus={this.onFieldFocus.bind(this, 'faxSuffix')}
        onKeyPress={helperFunctions.isNumeric.bind(this)} />
        {this.renderValidationOverlay('faxSuffix', errorMessages.fax_required)}
                                
</div>
</div>
<div className='row'>
    <div className='col-sm-6 enrollment-request__input-group'>
        <label ><FormattedMessage {...messages.reenter_email } /></label>
        <input type="text" ref='reEnterEmail' className='enrollment-request__block enrollment-request__input'
        onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.RE_ENTER_EMAIL)}
        onFocus={this.onFieldFocus.bind(this, 'reEnterEmail')} />
    <Overlay
        show={this.isFieldValid('reEnterEmail')}
        target={() => ReactDOM.findDOMNode(this.refs.reEnterEmail)}
        placement="right"
        container={this}>
                                <Popover id={"popOverreEnterEmail"} className="enrollment-request__error_message">
                                <RawIntlProvider value={this.props.intl}>
                                    <FormattedMessage {...errorMessages.re_enter_email_required} />
                                    </RawIntlProvider>
                                </Popover>
                            </Overlay>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 enrollment-request__input-group">
                            <label ><FormattedMessage {...messages.referral_code } /></label>
                            <input type="text" className='enrollment-request__block enrollment-request__referral-code'
                                onChange={this.props.onRequestInfoChange.bind(this, constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.REFERRAL_CODE)} />
                        </div>
                        <div className="col-sm-6 ">
                            <label className='enrollment-request__referral_code_note'><FormattedMessage {...messages.referral_code_note } /></label>
                        </div>

                    </div>
                </div>
                {typeof window !== 'undefined'
                && typeof window.grecaptcha !== 'undefined'
                && typeof window.grecaptcha.render === 'function' ?
                    <div className='enrollment-request__contact-recaptcha'>
                        <div className="row">
                            <div className="enrollment-request__g-recaptcha-outer">
                                <label>
                                    <FormattedMessage {...messages.recaptcha_title} /></label>
                                <Recaptcha reCaptchaKey={this.props.reCaptchaKey}
                                           reCaptchaVerificationToken={this.props.reCaptchaVerificationToken}/>
                            </div>
                        </div>
                    </div>
                : false }
                <div className='enrollment-request__footer'>
                    <div className='row'>
                        <div className='col-sm-8'>
                            <div className="enrollment-request__cancel"
                                role="button">
                                <Link to="/login"><img src="/assets/VMS_28button_kill_clear.png" />
                        {' '}<FormattedMessage {...messages.cancel} /></Link>
                            </div>
                        </div>
                        <div className='col-sm-4 enrollment-request__submit'>
                            <Submit caption={<FormattedMessage {...messages.submit} />}
                                imagePath="/assets/VMS_33button_go_bblue.png"
                                onClickHandler={this.onSubmit.bind(this)} />
                            </div>
                        </div>
                    </div>

                </div>
        );
                        }
};
export default injectIntl(Request);