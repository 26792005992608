// libraries
import React from 'react';
import {connect} from 'react-redux';
import {Map} from 'immutable';
// our stuff
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import BusyLayout from '../components/BusyLayout';
import PageLayout from '../components/PageLayout';
import Header from '../components/Header';
import CurrentDocumentsStep from './CurrentDocumentsStep';
import UploadStep from './UploadStep';
import BrowseStep from './BrowseStep';
import FormWrapper from 'components/FormWrapper';

class VehicleDocuments extends React.Component {                    
    constructor(props) {
        super(props);
        this.state = {
            fileForUpload: undefined
        };
    }  

    componentDidMount() {        
        this.props.onLoading();
    }

    componentWillUnmount() {        
        this.props.onUnloading();
    }      

    onDrop(acceptedFiles) {            
        if (acceptedFiles && acceptedFiles.length>0)
        {            
            this.setState({
                // have to save file in state (Redux cannot store files)
                fileForUpload: acceptedFiles[0] // just grab first file (only allow 1 at a time)
            });
        }
    }    

    render() {
        if (this.props.isBusy) 
        {
            return <BusyLayout><Spinner/></BusyLayout>;
        }        

        return <div>
        
            <PageLayout whiteBackground={false}>

                <div>                        
                    
                    <Header vin8={this.props.vin8} />
                    
					<FormWrapper key='vehicleDocumentErrorMessage' id="error_message" {...this.props} formErrors={this.props.vehicleDocumentErrorMessage || this.props.errorMessage} />

					<div>

						{ this.props.step === constants.VEHICLE_DOCUMENT_STEPS.DOCUMENTS ?
			
							<CurrentDocumentsStep/>

						: this.props.step === constants.VEHICLE_DOCUMENT_STEPS.BROWSE ?

							<BrowseStep onDrop={this.onDrop.bind(this)}/>

						: this.props.step === constants.VEHICLE_DOCUMENT_STEPS.UPLOAD ?

							<UploadStep file={this.state.fileForUpload}/>

						: false }

					</div>
                    
                </div>                            

            </PageLayout>

        </div>        
    }
}

const mapStateToProps = (state, props) => {    
    return {
        isBusy: state.appState.getIn(['uiData', 'vehicleDocuments', 'isBusy']),
        allowToggleDocumentVisible: state.appState.getIn(['uiData', 'vehicleDocuments', 'allowToggleDocumentVisible']),
        step: state.appState.getIn(['uiData', 'vehicleDocuments', 'step']),                
		vin8: (state.appState.getIn(['serverData', 'vehicleDocuments', 'vehicle', 'vin']) || '').slice(-8),                
		errorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage']),
		vehicleDocumentErrorMessage: state.appState.getIn(['uiData', 'vehicleDocuments', 'serverError']),
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoading: () => {                        
            const vehicleId = props.vehicleId;
            
            dispatch({type: constants.VEHICLE_DOCUMENTS_LOADING, vehicleId});
            
            if (props.vehicleId!==undefined)                
                dispatch({type: constants.FETCH_VEHICLE_DOCUMENTS, vehicleId});
            
        },
        onUnloading: () => {                        
            dispatch({type: constants.VEHICLE_DOCUMENTS_UNLOADING})
        },
        onShowDocumentsClick: () => {
            if (props.vehicleId===undefined)
            {
                const vehicleId = props.vehicleId;
                dispatch({type: constants.FETCH_VEHICLE_DOCUMENTS, vehicleId});
            }

            dispatch({type: constants.SHOW_VEHICLE_DOCUMENTS, visible: true})
        },
        onHideDocumentsClick: () => {
            dispatch({type: constants.SHOW_VEHICLE_DOCUMENTS, visible: false})
        },        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDocuments);