import React from 'react';
import './MarketingText.css';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import * as constants from 'constants/App';
import { connect } from 'react-redux';
import LoginHeaderSelector from '../selectors/LoginHeader';
import Help from 'components/Help';
import {withRouter} from 'react-router-dom'

class MarketingText extends React.Component {

    componentDidMount() {
        this.props.dispatch({ type: constants.FETCH_BULLETIN_MESSAGE });
    }



    render() {
        const messages = defineMessages({
            marketing_message: {
                id: 'marketing_message',
                description: 'fleet management marketing message',
                defaultMessage: 'Holman has revolutionized fleet management with technology that enables organizations around the world to realize new levels of efficiency and value by leveraging the power of data and analysis.'
            },
            learn_more_caption: {
                id: 'learn_more_caption',
                description: 'Learn More Link',
                defaultMessage: ' Learn more'

            },
            recover_password_text: {
                id: 'recover_password_text',
                description: 'Forgot password link',
                defaultMessage: 'Forgot your password?'

            }, help_link: {
                id: 'help_link',
                description: 'Text for Help Link in header',
                defaultMessage: 'Help'
            },
            contact_link: {
                id: 'contact_link',
                description: 'Text for Contact Link in header',
                defaultMessage: 'Contact'
            },
            enroll_link: {
                id: 'enroll_link',
                description: 'Text for Enroll Link in header',
                defaultMessage: 'Enroll'

            },
            not_enroll_text: {
                id: 'not_enroll_text',
                description: 'Text for Enroll Link in header',
                defaultMessage: 'Not a Holman Partner? '

            },
            recently_enrolled: {
                id: 'recently_enrolled',
                description: 'Recently enrolled?',
                defaultMessage: 'Recently enrolled?'
            },
            register_here: {
                id: 'register_here',
                description: 'register_here',
                defaultMessage: 'Register Here'
            },
        });

        return <div className="marketing-text">
            <div className="marketing-text__note">
                <FormattedMessage {...messages.marketing_message} />
                {this.props.primaryBulletin !== undefined ?
                    this.props.primaryBulletin.map((param) =>
                    <div className="driver-validation__text" key={param.get('id')}>{param.get('title')}{' '}
                        <Link
                            to={`/learnMore/${param.get('id')}`} >
                            <FormattedMessage {...messages.learn_more_caption} />
                        </Link>
                    </div>
                    ).valueSeq()
                    : false
                }
            </div>
            <div className='marketing-text__links'>
            {this.props.selectedCountry=='MEX'?false:
                <div className="row marketing-interactive__link-enrol">
                    <Link className="marketing-interactive__link"
                        to='/vendorEnrollment/request'  >
                        <FormattedMessage {...messages.not_enroll_text} />   <FormattedMessage {...messages.enroll_link } />{' '}<img src="/assets/VMS_28button_go_clear.png" /></Link>
                        <Help videoId="enrollement"  whiteSpaceAtBottomHeight="0.7em"/>
                </div>
            }
                <div className='row marketing-interactive__footer-links'>
                    <div className="col-sm-4 marketing-interactive__link-control_help_contact">
                        <Link className="marketing-interactive__link"
                            to='/contact/HelpFAQ'  ><FormattedMessage {...messages.help_link} /></Link>
                        {' '} | {' '}
                        <Link className="marketing-interactive__link"
                            to="/contact/FeedbackForm"  ><FormattedMessage {...messages.contact_link} /></Link>

                    </div>
                    <div className='col-sm-8 marketing-interactive__link-note'>
                        
                        <Link className="marketing-interactive__link-register"
                            to='/registration/accountInfo'><FormattedMessage {...messages.recently_enrolled } />
                            {' '}<FormattedMessage {...messages.register_here } /></Link>
                            {this.props.selectedCountry=='MEX'?false:<Help videoId="new_supplier_registration"  whiteSpaceAtBottomHeight="0.7em"/>
                            }
                    </div>
                    
                </div>
            </div>
        </div>
    }
}

export default withRouter(connect(LoginHeaderSelector)(MarketingText));