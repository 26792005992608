// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import moment from 'moment';
// our stuff
import './VehicleComponent.css';
import CheckCircle from 'components/CheckCircle';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from '../../../../../../shared/utils/MomentLocaleUtils';

import * as dotInspectionEnums from 'PO/ClosePO/screens/DotInspection/data/DotInspectionEnums';

class VehicleComponent extends React.Component {				
	state = {dateKey: 1};
	
	handleComponentResultCodeClick = (name, checked) => {
		if (!checked)
			this.props.onComponentDetailsChanged(this.props.componentId, 'componentResultCode', name);
		else
			this.props.onComponentDetailsChanged(this.props.componentId, 'componentResultCode', null);
	}

	handleComponentPassedClick = (name, checked) => {				
		if (!checked)
			this.props.onComponentDetailsChanged(this.props.componentId, 'hasComponentPassed', true);
		else
			this.props.onComponentDetailsChanged(this.props.componentId, 'hasComponentPassed', null);
	}

	handleComponentFailedClick = (name, checked) => {		
		if (!checked)
			this.props.onComponentDetailsChanged(this.props.componentId, 'hasComponentPassed', false);
		else
			this.props.onComponentDetailsChanged(this.props.componentId, 'hasComponentPassed', null);
	}

	handleRepairedDateChange = (newDate) => {				
		if (newDate !== undefined && moment(newDate) >= moment(moment().subtract(1, "years")) && moment(newDate).subtract(12, "hours") <= moment().subtract(0, "days")) {		
            this.props.onComponentDetailsChanged(this.props.componentId, 'repairedDate', newDate);
		} else {						
			this.props.onComponentDetailsChanged(this.props.componentId, 'repairedDate', null);
		}		
	}

	handleRepairedDateBlur = (event) => {		
		this.resetDateIfInvalid(event.target.value);
	}

	resetDateIfInvalid = (inputDate)=> {

		// This code uses a hack to cause RepairedDate to rerender onBlur when an invalid date is entered.		
		// Use caution when editing code.
		// There is a bug in ReactDayPicker. The following describes the bug:		
		//https://github.com/gpbl/react-day-picker/issues/815

		let newDate = inputDate;		

		setTimeout(
			() => {				
				if (newDate !== undefined && moment(newDate) >= moment(moment().subtract(1, "years")) && moment(newDate).subtract(12, "hours") <= moment().subtract(0, "days")) {		
					//nothing
				} else {	
					if (!this.refs.repairedDateRef.state.showOverlay) {																
						this.setState({dateKey: this.state.dateKey + 1});
					}
				}
			}, 300
		);
	}			

	render() {			
		if (!this.props.componentDetails && (showPass || showFail))
			return null;

		return (
			<tr>
				<td className='dot_inspection_vehicle_component__cell' tabIndex='0'>					
					<CheckCircle
						name={dotInspectionEnums.componentResultCode.PASS}
						className='dot_inspection_vehicle_component__check_circle'
						checked={this.props.componentDetails.get('componentResultCode') === dotInspectionEnums.componentResultCode.PASS}
						onClick={this.handleComponentResultCodeClick}
					/>
				</td>
				<td className='dot_inspection_vehicle_component__cell' tabIndex='0'>					
					<CheckCircle
						name={dotInspectionEnums.componentResultCode.NA}
						className='dot_inspection_vehicle_component__check_circle'
						checked={this.props.componentDetails.get('componentResultCode') === dotInspectionEnums.componentResultCode.NA}
						onClick={this.handleComponentResultCodeClick}
					/>
				</td>
				<td className='dot_inspection_vehicle_component__cell' tabIndex='0'>
					<CheckCircle
						name={dotInspectionEnums.componentResultCode.FAIL}
						className='dot_inspection_vehicle_component__check_circle'
						checked={this.props.componentDetails.get('componentResultCode') === dotInspectionEnums.componentResultCode.FAIL}
						onClick={this.handleComponentResultCodeClick}
					/>
				</td>
				<td className='dot_inspection_vehicle_component__cell'>
				<div onBlur={this.handleRepairedDateBlur}>
					<DayPickerInput                        
						ref='repairedDateRef'
						inputProps={{class: 'dot_inspection_vehicle_component__repaired_date'}}
						tabIndex={1}						
						key={this.state.dateKey}
						placeholder="MM/DD"                        
						onDayChange={this.handleRepairedDateChange}                        
                        dayPickerProps={{
                            disabledDays: {
                                before: new Date(moment(moment().subtract(1, "years").add(1, "days"))),
								after:new Date(moment().subtract(0, "days"))
							},
							selectedDays:this.props.componentDetails.get('repairedDate') !== null ?
								new Date(moment(this.props.componentDetails.get('repairedDate')))
								: undefined
                        }}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="MM/DD"
						value={this.props.componentDetails.get('repairedDate') !== null ?
							new Date(moment(this.props.componentDetails.get('repairedDate')))
							: undefined
						}
					/></div>										

				</td>
				<td className='dot_inspection_vehicle_component__cell'>
					{this.props.componentName}
				</td>
			</tr>
		)
    }
}

export default VehicleComponent;