import React from 'react';
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ReactDoM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import Submit from '../../../../shared/components/Submit';
import ClientParametersComponent from 'components/ClientParametersComponent';
import './AddDealerDraftLineItem.css';
import DynamicNumber from 'react-dynamic-number';

class AddDealerDraftLineItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step123ContainerOffsetWidth: 0,
            step123ContainerOffsetHeight: 0,
        }
    }

    onClickHandler(){
        this.refs.serviceLineItems.selectedIndex = 0;
    }

    render() {
        const messages = defineMessages({
            close_dialog: {
                id: 'close_dialog',
                description: '  Close',
                defaultMessage: 'Close'
            },
            add_service_item: {
                id: 'add_service_item',
                description: 'add_service_item',
                defaultMessage: 'Add line item'
            },
            parameters_header: {
                id: 'parameters_header',
                description: 'Header for ata parameters ',
                defaultMessage: 'Parameters '
            },
            empty_parameters: {
                id: 'empty_parameters',
                description: 'Description when parameters are empty',
                defaultMessage: 'None at this time'
            },
            add_new_service_item_caption: {
                id: 'add_new_service_item_caption',
                description: 'add_new_service_item_caption',
                defaultMessage: 'Add Service items'
            },
            return: {
                id: 'return',
                description: 'Return',
                defaultMessage: 'Return'
            },
            cost_details: {
                id: 'cost_details',
                description: 'cost',
                defaultMessage: 'Cost'
            },

        });

        const addLineItemToPoLink = <Submit
            caption={<FormattedMessage {...messages.add_service_item} />}
            imagePath="/assets/VMS_33button_add_blue.png"
            showSpinner={this.props.isCreatingLineItems}
            onClickHandler={this.props.onAddLineItemToRequest}
            spinnerType="blue" />



        return (
            <div>
                <div className="add-dealer-new-line-items">
                    <div className="row add-dealer-new-line-items__close">
                        <div onClick={this.props.onCloseWindow}>
                            <img className="add-new-line-items__close-button"
                                 src="/assets/VMS_33button_kill_blue.png" />
                            {"  "} {<FormattedMessage {...messages.close_dialog} />}
                        </div>
                    </div>
                        <div className={classnames('add-dealer-new-line-items_step-1-2-3-container')}
                             ref={element => this.step123Container = element}>
                            <div className="row add-dealer-new-line-items__caption">
                                <div>
                                    <FormattedMessage {...messages.add_new_service_item_caption} />

                                </div>
                            </div>
                            {this.props.itemsAddedToPo != undefined && this.props.itemsAddedToPo.size > 0 ?
                                <div className="add-dealer-new-line-items__recenly-added-ata-codes">
                                    <div className="add-dealer--new-line-items__recenly-added-ata-codes-header">Items added to PO:</div>
                                    {
                                        this.props.itemsAddedToPo.map(item => {
                                            return <div>{item.get('productDescription')}</div>;
                                        }).valueSeq()}
                                </div>
                                : false
                            }
                            <div className="row">
                                <table className="add-dealer-new-line-items__details table-responsive">
                                    <tbody>
                                    <tr >
                                        <td className="add-dealer-new-line-items__search-border">
                                            <div className="add-dealer-new-line-items__step-numbers-1">
                                                <img src="/assets/VMS_28button_1_clear.png" />
                                            </div>
                                            <table className="add-dealer-new-line-items__ata-codes">
                                                <tbody>
                                                <tr >
                                                    <td >
                                                        <div className="add-dealer-new-line-items__search">
                                                            <select  onClick={this.props.onClickChange.bind(this, "LINE_ITEM_KEY")} ref='serviceLineItems' className="add-dealer-new-line-items__select">
                                                                <option key="selectLineItem" value="" default>Select</option>
                                                                {this.props.productDetails.valueSeq().map((item) => {
                                                                    return <option key={item.get("id")} id={item.get("id")}
                                                                                   value={[item.get("id"),item.get("description")]}>{item.get("description").toUpperCase()}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td className={classnames('add-dealer-new-line-items__border')}>
                                            <div className="add-dealer-new-line-items__step-numbers-2">
                                                <img src="/assets/VMS_28button_2_clear.png" />
                                            </div>
                                            <table className="add-dealer-new-line-items__ata-codes">
                                                <tbody>
                                                <tr >
                                                    <td >
                                                        <div className="form-group col-xs-8 add-dealer-new-line-items__cost">
                                                            <FormattedMessage {...messages.cost_details} />
                                                            <DynamicNumber
                                                                className="correction-type__input-medium"
                                                                onChange={this.props.handleChange.bind(this, "COST")}
                                                                separator={'.'}
                                                                integer={6}
                                                                fraction={2}
                                                                positive={true}
                                                                negative={true}
                                                                thousand={false}
                                                                value={this.props.newlineItem.get("cost") !== undefined ? this.props.newlineItem.get("cost") : ""}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    {this.props.requestStatus === "VIEW" ? false :
                        <div className="row">

                            <div className={classnames('col-sm-6 add-dealer-new-line-items__save',
                                !this.props.enableAddLineItemToPo ? 'add-dealer-new-line-items__save__disabled' : false)}
                                 onClick={this.onClickHandler.bind(this)}
                            >
                                {
                                    addLineItemToPoLink
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
};



export default AddDealerDraftLineItem;
