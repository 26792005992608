import React, { Component, isValidElement } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './Notification.css';
import * as constants from 'constants/App';
import cx from 'classnames';

const noop = () => { };

export function isValidDelay(val) {
    return typeof val === 'number' && !isNaN(val) && val > 0;
}

export function objectValues(obj) {
    return Object.keys(obj).map(key => obj[key]);
}

function withRequired(fn) {
    fn.isRequired = function (props, propName, componentName) {
        const prop = props[propName];

        if (typeof prop === 'undefined') {
            return new Error(`The prop ${propName} is marked as required in 
        ${componentName}, but its value is undefined.`);
        }

        fn(props, propName, componentName);
    };
    return fn;
}

export const falseOrDelay = withRequired((props, propName, componentName) => {
    const prop = props[propName];

    if (prop !== false && !isValidDelay(prop)) {
        return new Error(`${componentName} expect ${propName} 
        to be a valid Number > 0 or equal to false. ${prop} given.`);
    }

    return null;
});

export const falseOrElement = withRequired((props, propName, componentName) => {
    const prop = props[propName];

    if (prop !== false && !isValidElement(prop)) {
        return new Error(`${componentName} expect ${propName} 
        to be a valid react element or equal to false. ${prop} given.`);
    }

    return null;
});

export function CloseButton({ closeNotification, type, ariaLabel }) {
    return (
        <button
            className={`notification__close-button notification__close-button--${type}`}
            type="button"
            onClick={closeNotification}
            aria-label={ariaLabel}
        >
            ✖
    </button>
    );
}

CloseButton.propTypes = {
    closeNotification: PropTypes.func,
    arialLabel: PropTypes.string
};

CloseButton.defaultProps = {
    ariaLabel: 'close'
};

const notificationId = `notification`;

class Notification extends Component {

    static propTypes = {
        position: PropTypes.oneOf(objectValues(constants.POSITION)),
        autoClose: falseOrDelay,
        closeButton: falseOrElement,
        render: PropTypes.node.isRequired,
        showProgressBar: PropTypes.bool,
        closeOnClick: PropTypes.bool,
        className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        progressClassName: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        progressStyle: PropTypes.object,
        type: PropTypes.oneOf(objectValues(constants.TYPE)),
        in: PropTypes.bool,
        onExited: PropTypes.func,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        ariaLabel: PropTypes.string,
        closeNotification: PropTypes.func.isRequired,
        id: PropTypes.string,
        showCloseButton: PropTypes.bool,
    };

    static defaultProps = {
        type: constants.TYPE.DEFAULT,
        in: true,
        position: constants.POSITION.TOP_CENTER,
        autoClose: 5000,
        showProgressBar: false,
        closeOnClick: false,
        className: null,
        progressClassName: null,
        progressStyle: null,
        role: 'alert',
        onOpen: noop,
        onClose: noop,
        id: notificationId,
        showCloseButton: false
    };

    state = {
        show: true,
        isRunning: true
    };

    flag = {
        canCloseOnClick: true
    };

    ref = null;

    componentDidMount() {
        this.props.onOpen(this.props.render.props);
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        this.props.onClose(this.props.render.props);
    }

    removeNotification() {
        event.preventDefault();
        this.setState({ show: false });
    }

    render() {
        const {
            render,
            autoClose,
            closeOnClick,
            type,
            showProgressBar,
            transition: Transition,
            position,
            onExited,
            className,
            progressClassName,
            progressStyle,
            role,
            showCloseButton
        } = this.props;

        const notificationProps = {
            className: cx(
                'notification',
                `notification--${type}`,
                className
            )
        };

        const progressClassNames = cx(
            'notification__progress-bar',
            `notification__progress-bar--${type}`,
            progressClassName
        );
        const updatedProgressStyle = {
            ...progressStyle,
            animationDuration: `${autoClose}ms`,
            animationPlayState: this.state.isRunning ? 'running' : 'paused',
            opacity: showProgressBar ? 1 : 0
        };

        let closeNotification = this.props.closeNotification;

        if (closeNotification == undefined) {
            closeNotification = () => this.removeNotification();
        }

        if (closeOnClick) {
            notificationProps.onClick = () => this.flag.canCloseOnClick && closeNotification();
        }

        let closeButton = this.props.closeButton;
        if(showCloseButton && !closeButton)
        {
            closeButton = <CloseButton closeNotification={closeNotification} type={this.props.type} />;
        }

        return (this.state.show && (
            <div
                {...notificationProps}
                ref={ref => (this.ref = ref)}
            >
                <div id={this.props.id}
                    {...this.props.in && { role: role }}
                    className='notification__body'
                >
                    {render}
                </div>
                {showCloseButton && closeButton && closeButton}
                {(autoClose && showProgressBar) && (
                    <div className={progressClassNames}
                        type={type}
                        style={updatedProgressStyle}
                        onAnimationEnd={closeNotification}
                    />
                )}
            </div>)
        );
    }
}

export default Notification;