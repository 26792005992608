import React from 'react';
import ReactDOM from 'react-dom';
import {Overlay, Popover} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Map} from 'immutable';
import {defineMessages, FormattedMessage} from 'react-intl';
import LineItems from './../../screens/PO/AddEditView/components/LineItems';
import './Diagnosis.css';
import classNames from 'classnames';
import * as helperFunctions from 'utils/HelperFunctions';

class Diagnosis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            diagnosisSelected: this.props.isSelected != undefined ? this.props.isSelected : false,
            disabled: this.props.isSelected != undefined && this.props.isSelected ? true : false,
            showErrorMessage: true
        }

    }

    onDiagnosisRadioClick(event) {
        event.preventDefault();
        if (this.state.diagnosisSelected) {
            this.setState({diagnosisSelected: false});
            this.refs.txtHours.value = '';
            this.refs.txtRate.value = '';
            this.refs.txtHours.disabled = true;
            this.refs.txtRate.disabled = true;
            this.props.onSaveDiagnosisDetails(this.refs.txtHours.value, this.refs.txtRate.value, false);
        }
        else {
            this.setState({diagnosisSelected: true});
            this.refs.txtHours.disabled = false;
            this.refs.txtRate.disabled = false;
            this.refs.txtRate.value = this.props.laborRate;
            this.refs.txtHours.value = 0.5;
            this.refs.txtHours.focus();
            this.props.onSaveDiagnosisDetails(this.refs.txtHours.value, this.refs.txtRate.value, true);
        }
    }

    static resetMaxValue(maxLength, event) {
        event.preventDefault();

        if (event.target.value != undefined) {
            if (!isNaN(event.target.value) && Number(event.target.value < 0))
                event.target.value = '0';
            else {
                if (Number(event.target.value) > Number(event.target.max)) {
                    event.target.value = event.target.value.slice(0, event.target.max.indexOf('.')) + '.' + event.target.value.slice(event.target.max.indexOf('.'), event.target.max.indexOf('.') + 3);
                }
                if(event.target.value.split(".").length>1)
                event.target.value =event.target.value.split(".")[0]+"."+ event.target.value.split(".")[1].slice(0,2);
            }
        }
    }

    saveInputValue(event) {
        event.preventDefault();
        this.props.onSaveDiagnosisDetails(this.refs.txtHours.value, this.refs.txtRate.value, this.state.diagnosisSelected);

    }

    render() {
        const messages = defineMessages({
            diagnosis_view_caption: {
                id: 'diagnosis_view_caption',
                description: 'Diagnosis',
                defaultMessage: 'Diagnosis'
            },
            diagnosis_view_caption1: {
                id: 'diagnosis_view_caption1',
                description: ' Request approval for diagnostic charge: ',
                defaultMessage: ' Request approval for diagnostic charge: '
            },
            diagnosis_view_caption2: {
                id: 'diagnosis_view_caption2',
                description: 'hour(s) at ',
                defaultMessage: 'hour(s) at '
            }
        });

        return <div className={classNames({'container-fluid diagnosis_update': this.props.editMode},
            {'container-fluid diagnosis_view': !this.props.editMode})}>
            {this.props.actionType == 'ADD' || this.props.actionType == 'VIEW' || this.props.actionType == 'EDIT' ?
                <div className="row diagnosis_view__caption">
                    <div className="col-sm-12">
                        <FormattedMessage {...messages.diagnosis_view_caption} />
                    </div>
                </div>
                : false
            }

            <div className="row">
                <div className="col-sm-12">

                    {!this.props.editMode ?
                        <div>
                            <LineItems lineItems={this.props.lineItems}
                                       actionType={this.props.actionType}
                                       complaintCode="DG99"
                                       isServiceComplaint={true}
                                       onDeleteLineItemClick={this.props.onDeleteLineItemClick}
                                       onEditLineItemClick={this.props.onEditLineItemClick}
                                       onUpdateLineItemClick={this.props.onUpdateLineItemClick}
                                       onMissingProductCodeIconClick={this.props.onMissingProductCodeIconClick}
                                       onCancelUpdateLineItemClick={this.props.onCancelUpdateLineItemClick}
                                       hideCostInformation={this.props.hideCostInformation}
                                       lineItemIdBeingEdited={this.props.lineItemIdBeingEdited}
                                       onOpenTiresInfo = {this.props.onOpenTiresInfo}
                                       selectedCurrency={this.props.selectedCurrency}
                            />
                        </div>
                        : false
                        /*

                        <div className="diagnosis_view__description">


                        <hr className="diagnosis_update__hr_thick"/>
                        <img className="diagnosis_view__radio" src={this.state.diagnosisSelected
                        ? "/assets/VMS_checked.png"
                        : "/assets/VMS_unchecked.png"}

                        onClick={this.props.isReadOnly ? false : this.onDiagnosisRadioClick.bind(this)}/>
                        {' '} <FormattedMessage {...messages.diagnosis_view_caption1}/>
                        {' '}

                        <input className="diagnosis_add__input-box_hours" type="number"
                        ref="txtHours"
                        cols="6"
                        size="10"
                        onChange={this.saveInputValue.bind(this)}
                        min="0"
                        max="999.99"
                        />

                        {' '} <FormattedMessage {...messages.diagnosis_view_caption2}/>
                        {' '}

                        <span>$ </span>


                        <input className="diagnosis_add__input-box_rate" type="number"
                        ref="txtRate"
                        cols="9"
                        size="12"
                        onChange={this.saveInputValue.bind(this)}
                        min="0"
                        max="999999.99"

                        />

                        <hr className="diagnosis_update__hr_thin"/>
                        </div>
                    */
                        }

                </div>
            </div>
        </div>;
    }
}



export default Diagnosis;