// #region Imports
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import Spinner from "components/Spinner";
import Permissions from "screens/App/PersonaRoutes/common/Permissions";
import FormWrapper from "components/FormWrapper";
import * as helperFunctions from "utils/HelperFunctions";
import * as Api from "utils/Api";
import { useAppState } from 'shared/AppState/app-state';
// #endregion

// #region Styled Components
const ModalStyled = styled(Modal).attrs({
  dialogClassName: "childStyle"
})`
  .childStyle {
    width: 900px;
}`;

const ModalBody=styled.div`  
  padding: 10px 30px 30px 30px;
  font-family: 'Gotham SSm A', 'Gotham SSm B';
`;

const ModalMainHeader=styled.div`
  font-size: 42px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;font-weight: 300;
  color: #5587B7;
  padding-top: 15px;
  padding-left: 0px;
  line-height: normal;
  margin-bottom: -10px;
`;

const ModalBodyTable=styled.div`
    border: solid rgb(140, 170, 202);
    border-top-width: 1px;
    border-right-width: 2px;    
    border-bottom-width: 1px;
    border-left-width: 2px;    
    margin-top: 3rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-right:1rem;
`;

const LargeTitleStyled = styled.div`
text-align: left;
font-family: "Gotham SSm A", "Gotham SSm B";
color: black;
font-style: normal;
font-size: 16px;
padding-top: 2rem;
`;

const SpinnerContainer = styled.div`	
  display: inline-block;

  & .spinner {
    display: inline-block;
    width: 25px;
    height: 25px;
    position: inherit;
    vertical-align: top;
    margin-left: 5px;
    border-width: 5px;
  }
`;

const ButtonRowStyled = styled.div`	
  display: flex;
  justify-content: space-between;
`;

const ButtonStyled = styled.div`
  cursor: ${({disable}) => disable ? `not-allowed` : `pointer`};
  padding-top: 10px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  font-weight: 700;
  color: #5587b7;
`

const ModalCloseStyled = styled.div`
  padding-top: 10px;
  font-family: "Gotham SSm A", "Gotham SSm B";font-style: normal;font-weight: 500;
  color: #0f4e95;
  cursor: pointer;
`;
// #endregion

// #region Component
const ModifyUserPermissions = ({ routeParams, intl: { formatMessage } }) => {  
  // #region State  
  const loadingStates = { loading: "loading", loaded: "loaded", error: "error" };
  const [loadingState, setLoadingState] = useState(loadingStates.loading);
  const [pageErrors, setPageErrors] = useState();
  const [selectedPermissions, setSelectedPermissions] = useState([]);  
  const [limitToThesePermissions, setLimitToThesePermissions] = useState([]);
  const [mainError, setMainError] = useState('');
  const [saving, setSaving] = useState(false);  
  const [appState] = useAppState();  
  const { personaUserId, personaToken } = appState;
  const {userId, storeId} = routeParams.match.params;
  const {user, vendor} = routeParams.location.state;
  // #endregion

  // #region Ref
  const alertBoxRef = useRef(null);
  // #endregion

  // #region Event handlers    
  const handlePermissionChange = (newSelectedPermissions) => {    
    setPageErrors();
    setSelectedPermissions(newSelectedPermissions);
  }
  
  const handleSave = () => {
    
    const postData = async () => {						
      try {
        setSaving(true);
        
        let response = await Api.createPermissions(userId, selectedPermissions, storeId, personaToken);        
        
        setSaving(false);
        routeParams.history.goBack();    
      } 
      catch (error) {        
        setPageErrors(helperFunctions.getErrorObject(error));
        setSaving(false);
      }
    }

    if (!saving && selectedPermissions.length > 0)
      postData();    
  }

  const handlePermissionsError = (error) => {        
    /*if (error === 'error_admin_cannot_change_permission') {            
      setPageErrors([{message: formatMessage(messages['permissions_step__error_admin_cannot_change_permission'])}]);
    }    

    else {
      // These errors are not handled.
    } */   
    window.scrollTo(0, 0);
  }
  // #endregion

  // #region Effects
  // Description: fetch user's permissions.
  useEffect(
    () => {
      let didCancel = false;

      const fetchData = async () => {        
        if (!appState.personaUser || !appState.personaUser.id)
          return;
        
        try {                       
          setLoadingState(loadingStates.loading);
          const [permissions, currentUsersPermissions] = await Promise.all([
            Api.fetchPermissions(
              userId,
              storeId,
              personaToken
            ),
            Api.fetchPermissions(
              appState.personaUser.id,
              storeId,
              personaToken
            )
          ]);                

          if (!didCancel) {  
            setSelectedPermissions(permissions);        
            setLimitToThesePermissions(currentUsersPermissions);
            setLoadingState(loadingStates.loaded);
          }
        } catch (e) {
          if (!didCancel) {
            setLoadingState(loadingStates.error);
            setPageErrors(helperFunctions.getCustomErrorObject(e));         
          }
        }
      };
  
      fetchData();
  
      return () => {
        didCancel = true;
      };           
    }, [userId, appState.personaUser]
  );  
  // #endregion

  // #region International messages  
  const messages = defineMessages({    
    close_dialog: {
      id: "close_dialog",
      description: "  Close",
      defaultMessage: "Close"
    },
    modify_user_permissions__grant_permissions_title: {
      id: "modify_user_permissions__grant_permissions_title",
      description: "Modify permissions",
      defaultMessage: "Modify permissions"
    },    
    modify_user_permissions__return: {
      id: "modify_user_permissions__return",
      description: "Return",
      defaultMessage: "Return"
    },
    modify_user_permissions__save_changes: {
      id: "modify_user_permissions__save_changes",
      description: "Save changes",
      defaultMessage: "Save changes"
    },    
    modify_user_permissions__store: {
      id: "modify_user_permissions__store",
      description: "Store",
      defaultMessage: "Store"
    },    
  });

  //modify_user_permissions__grant_permissions_title

  // #endregion

  // #region Render helpers
  const renderCloseButton = () => (
    <ModalCloseStyled role='button' onClick={() => {
      routeParams.history.goBack();
    }}>
      <img src="/assets/VMS_33button_kill_blue.png" />
      {"  "} {<FormattedMessage {...messages.close_dialog} />}
    </ModalCloseStyled>
  );

  const renderReturnButton = () => (
    <ButtonStyled
      role="button"
      onClick={() => {
        routeParams.history.goBack();
      }}
    >
      <img src="/assets/VMS_33button_back_blue2.png" />
      {" "}
      <FormattedMessage
        {...messages.modify_user_permissions__return}
      />
    </ButtonStyled>
  );

  const renderSaveButton = () => (
    <ButtonStyled
      role="button"
      disable={saving || selectedPermissions.length === 0}
      onClick={handleSave}
    >
      <FormattedMessage {...messages.modify_user_permissions__save_changes} />
      {" "}
      <img src="/assets/VMS_33button_go_bblue.png" />
    </ButtonStyled>
  );
  // #endregion  

  // #region Render
  return (    
    <ModalStyled		  
      show={true}
      backdrop="static"
      keyboard={false}          
    >                     
      <ModalBody>          
        
        <FormWrapper formErrors={pageErrors} />

        <ButtonRowStyled>        
          {renderCloseButton()}
          <div></div>
        </ButtonRowStyled>          

        <ModalMainHeader>
          <FormattedMessage {...messages.modify_user_permissions__grant_permissions_title} />
        </ModalMainHeader>

        <ModalBodyTable>

          <LargeTitleStyled>                            
            {user.firstName} {user.lastName}
          </LargeTitleStyled>

          <LargeTitleStyled>                            
            <FormattedMessage {...messages.modify_user_permissions__store} /> {vendor.id}
            {vendor.storeNumber && ' / '} {vendor.storeNumber}
          </LargeTitleStyled>

          <hr />

          {loadingState == loadingStates.loading
          ? <Spinner />
          :
            <Permissions                               
              selectedPermissions={selectedPermissions}              
              limitToThesePermissions={limitToThesePermissions}
              onPermissionChange={handlePermissionChange}
              onError={handlePermissionsError}          
            />
          }

          <br />

        </ModalBodyTable>
        
        <ButtonRowStyled>
          {renderReturnButton()}
          <div>
            {renderSaveButton()}
            {saving && 
              <SpinnerContainer><Spinner spinnerType="blue" manualPositioning /></SpinnerContainer> 
            }
          </div>
        </ButtonRowStyled>

      </ModalBody>                
    </ModalStyled>    
  );
};
// #endregion

export default injectIntl(ModifyUserPermissions);