import React from 'react';
import {Map} from 'immutable';
import './ServiceLineItem.css';
import {LINE_ITEM_TYPE, SERVICE_LINE_ITEM_DETAILS_TYPE} from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import DynamicNumber from 'react-dynamic-number';
import {ButtonGroup, Button, Tooltip, OverlayTrigger} from 'react-bootstrap';

class ServiceLineItem extends React.Component {

    getTooltip(toolTipText) {
        return <Tooltip id="tooltip"> {toolTipText} </Tooltip>;
    }

    getATACodes(productCode, description) {

        return <OverlayTrigger placement='right' key={productCode} overlay={this.getTooltip(description)}>

            <div key={productCode}>
                {description.length > 20 ? description.substr(0, 19) + '...'
                    : description

                }

            </div>

        </OverlayTrigger>

    }


    render() {


        return (
            <tr className="service-line-item__row" key={this.props.ataCode.get('productCode')}>
                <td>
                    <div className="service-line-item__ata-description">
                        {this.getATACodes(this.props.ataCode.get("productCode"), this.props.ataCode.get("description"))}
                    </div>
                </td>
                <td >
                    <DynamicNumber
                        className="service-line-item__cost-input form-control"
                        onChange={this.props.handleChange.bind(this, LINE_ITEM_TYPE.PART, this.props.ataCode.get('productCode'))}
                        separator={'.'}
                        integer={6}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        placeholder={"$"}
                        value={this.props.ataCode.get('partCost') !== undefined ?  this.props.ataCode.get('partCost').toString().match(/^-?\d+(?:\.\d{0,2})?/) : 0}
                    />
                </td>
                <td >
                    <DynamicNumber
                        className="service-line-item__cost-input form-control"
                        onChange={this.props.handleChange.bind(this, LINE_ITEM_TYPE.LABOR, this.props.ataCode.get('productCode'))}
                        separator={'.'}
                        integer={6}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        placeholder={"$"}
                        value={this.props.ataCode.get('laborRate') !== undefined ? this.props.ataCode.get('laborRate').toString().match(/^-?\d+(?:\.\d{0,2})?/) : 0}
                    />
                </td>
                <td >
                    <DynamicNumber
                        className="service-line-item__cost-input form-control"
                        separator={'.'}
                        integer={6}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        value={this.props.ataCode.get('total') !== undefined ?  this.props.ataCode.get('total').toString().match(/^-?\d+(?:\.\d{0,2})?/) : 0}
                        onChange={this.props.handleChange.bind(this, SERVICE_LINE_ITEM_DETAILS_TYPE.TOTAL, this.props.ataCode.get('productCode'))}
                        placeholder={"$"}
                    />
                </td>
            </tr>
        );
    }
}
;



export default ServiceLineItem;
