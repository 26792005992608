import React from 'react';
import  ReactDoM from 'react-dom'
import {connect} from 'react-redux';
import {Map} from 'immutable';
import {defineMessages, FormattedMessage} from 'react-intl';
import ServiceLineItems from './ServiceLineItems';
import Submit from '../../../../../../../App/shared/components/Submit';
import './AddServiceLineItems.css';
import ClientParametersComponent from 'components/ClientParametersComponent';

class AddServiceLineItems extends React.Component {

    render() {

        const messages = defineMessages({
            save_and_add_caption: {
                id: 'save_and_add_caption',
                description: 'Save and add additional line items',
                defaultMessage: 'Save and add additional line items'
            },
            close_without_save: {
                id: 'close_without_save',
                description: 'Close without saving',
                defaultMessage: 'Close without saving'
            },
            save: {
                id: 'save',
                description: 'Save ',
                defaultMessage: 'Save '
            },
            add_pm_caption: {
                id: 'add_pm_caption',
                description: 'Add PM items',
                defaultMessage: 'Add PM items'
            },
            parameters_header_services: {
                id: 'parameters_header_services',
                description: 'Header for ata parameters ',
                defaultMessage: 'Client Vehicle Service Rules'
            },
            empty_parameters: {
                id: 'empty_parameters',
                description: 'Description when parameters are empty',
                defaultMessage: 'None at this time'
            },
            coupon_header_services: {
                id: 'coupon_header_services',
                description: 'coupon_header_services',
                defaultMessage: 'Coupon Schedule'
            },
            pm_sheet_header: {
                id: 'pm_sheet_header',
                description: 'pm_sheet_header',
                defaultMessage: 'Schedule'
            },
            pm_sheets: {
                id: 'pm_sheets',
                description: 'PM information pdf ',
                defaultMessage: 'PM Information'
            }
        });

        let productCodeParams = this.props.ataAndReferencePmParametersDescription == undefined ? '' :
            this.props.ataAndReferencePmParametersDescription.map((param) => {

                return <ClientParametersComponent
                    description={param.get('description')}
                />
            }).valueSeq();

        let referenceRuleParams = this.props.referenceRulePmParametersDescription == undefined ? '' :
            this.props.referenceRulePmParametersDescription.map((param) => {

                return <ClientParametersComponent
                    description={param.get('description')}
                />
            }).valueSeq();

        return (
            <div className="add-service-line-items">
                <div className="row add-service-line-items__close-without-save">
                    <div onClick={this.props.closeWithoutSave}>
                        <img className="add-service-line-items__close-button"
                             src="/assets/VMS_33button_kill_blue.png"/>
                        {<FormattedMessage {...messages.close_without_save} />}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 add-service-line-items__caption">
                        <FormattedMessage {...messages.add_pm_caption} />
                    </div>
                </div>
                <ServiceLineItems serviceATAList={this.props.serviceATACodes}
                                  handleChange={this.props.handleChange}/>
                <div className="row">
                    <div className="col-md-12 add-service-line-items__save">
                        <Submit
                            caption={<FormattedMessage {...messages.save}/>}
                            imagePath="/assets/VMS_33button_add_blue.png"
                            onClickHandler={this.props.saveServiceLineItems}
                            showSpinner={this.props.parentProps.isCreatingLineItems}
                            spinnerType='blue'/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 add-service-line-items__save">
                        <Submit
                            caption={<FormattedMessage {...messages.save_and_add_caption} />}
                            imagePath="/assets/VMS_33button_add_blue.png"
                            onClickHandler={this.props.saveAndAddServiceLineItems}/>
                    </div>
                </div>
                <div className="add-service-line-parameters__header">
                    <FormattedMessage {...messages.parameters_header_services}/>
                </div>
                <div>
                    { productCodeParams.size === 0 ?
                        <p className="add-service-line-empty__description">
                            <FormattedMessage {...messages.empty_parameters}/>
                        </p>
                        :
                        <div> {productCodeParams} </div>
                    }
                </div>
                <div className="add-service-line-parameters__header">
                    <FormattedMessage {...messages.coupon_header_services}/>
                </div>
                <div>
                    { referenceRuleParams.size === 0 ?
                        <p className="add-service-line-empty__description">
                            <FormattedMessage {...messages.empty_parameters}/>
                        </p>
                        :
                        <div> {referenceRuleParams} </div>
                    }
                </div>
                <div>
                    <div className="add-service-line-parameters__header">
                        <FormattedMessage {...messages.pm_sheet_header}/>
                    </div>
                    <div >
                        <a className={this.props.isFetchingData ? 'vehicle_info_disable__pm_info' : false}
                           onClick={this.props.downLoadPMInformation.bind(this)}>
                            <FormattedMessage {...messages.pm_sheets} />
                        </a>
                    </div>
                </div>
            </div>

        );
    }
}
;


export default AddServiceLineItems;
