// #region Imports
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { defineMessages, FormattedMessage,injectIntl } from "react-intl";
import * as Api from "utils/Api";
import * as helperFunctions from "utils/HelperFunctions";
import FormWrapper from "components/FormWrapper";
import Spinner from "components/Spinner";
import { Subhead } from "DesignSpecs/components/FontGuide";
import { PageLayout } from "DesignSpecs/components/Layouts";
import { useAppState } from 'shared/AppState/app-state';
import EmailConfirmation from "common/EmailConfirmation";
// #endregion

// #region Styled Components
const PageStyled = styled.div`
  font-weight: 500;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  color: ${props => props.theme.color.div};
  font-size: 16px;
`;

const InstructionsStyled = styled.div`
  padding-top: 1rem;
  line-height: 4rem;
  padding-bottom: 1rem;    
`;

const InputGroupStyled = styled.div`
  padding-top: 1rem;  
  padding-bottom: 1rem;
`;

const Input = styled.input`				
	border-radius: 1px;
    height: 25px;
    padding-left: 12px;
	width: 18rem;
	border: 1px solid #ccc;
    &:-ms-input-placeholder{
        color: grey;
    }
	&:focus {	
		border-color: #66afe9;
		outline: 0;
		-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
		box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
	}

	${props => props.error && props.touched && `				
		border-color: red;		
	`};
`;

const InputFeedback = styled.div`				
	color: red;		
	font-family: "Gotham SSm A", "Gotham SSm B";
	font-size: 11px;
`;

const HR = styled.hr`
  border-top: 1px solid #e1e4ec;
`;

const ButtonRowStyled = styled.div`	
  display: flex;
  justify-content: space-between;
`;

const ButtonStyled = styled.div`
  cursor: ${({disable}) => disable ? `not-allowed` : `pointer`};
  padding-top: 10px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  font-weight: 700;
  color: #5587b7;
`;

const BackButtonStyled = styled.div`
  cursor: pointer;
  margin-top: 22rem;
  margin-bottom: 1rem;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  font-weight: 700;
  color: #5587b7;
`;


// #endregion

// #region Component
const AddUsers = ({ routeParams, intl: {formatMessage} }) => {    
  // #region State
  const [ mainError, setMainError ] = useState('');  
  const [ appState ] = useAppState();
  const { personaUserId, personaToken } = appState;
  const [showEmailConfirmation,setShowEmailConfirmation]=useState(false);
  const [userId, setUserId]=useState();
  const [existingUser, setExistingUser]=useState(false); // When adding a user that already exists, set this flag to true.
  // #endregion

  // #region Ref
  // #endregion  

  // #region Event handlers
  const handleSubmit = (values, { setSubmitting, resetForm }) => {          
    setMainError('');
      const postData = async () => {						
        try {
          let user = {          
            firstName: values.firstName,
            lastName: values.lastName,
            emailAddress: values.emailAddress
          };

          user.id = await Api.createNewUser(personaUserId, user, personaToken);
          setUserId(user.id);
          await Api.sendUserInvitationLink(
            user.id,
            undefined,
            values.emailAddress,
            personaToken
          );
          setSubmitting(false);
          setShowEmailConfirmation(true);
        } 
        catch (error) {        
          if (error !== undefined && error.code === 'API-002') {
            const existingUser = JSON.parse(error.message.substring(error.message.indexOf('{"existingUser":'))).existingUser;
            Api.sendUserInvitationLink(
              existingUser.id,
              undefined,
              existingUser.emailAddress,
              personaToken
            );
            setSubmitting(false);
            setUserId(existingUser.id);
            setExistingUser(true);
            setShowEmailConfirmation(true);            
          }
          else {          
            setMainError(helperFunctions.getErrorObject(error));
          }        
          setSubmitting(false);
        }
      }
      postData();
    
    };
   
  // #endregion

  // #region Effects
  // #endregion

  // #region International messages  
  const messages = defineMessages({
    close_dialog: {
      id: "close_dialog",
      description: "  Close",
      defaultMessage: "Close"
    },  
    add_users_to_store_add_new_users_caption: {
      id: "add_users_to_store_add_new_users_caption",
      description: "Set up new user",
      defaultMessage: "Set up new user"
    },
    add_users_to_store_add_new_user_caption2: {
      id: "add_users_to_store_add_new_user_caption2",
      description: "To set up a new user, complete the fields below and click create user.  Once the user is created, an email with instructions to access Holman PartnerConnect will be sent.",
      defaultMessage: "To set up a new user, complete the fields below and click create user.  Once the user is created, an email with instructions to access Holman PartnerConnect will be sent."
    },        
    add_new_user_create_user: {
      id: "add_new_user_create_user",
      description: "Create user",
      defaultMessage: "Create user"
    },
    add_new_user_first_name_placeHolder: {
      id:"add_new_user_first_name_placeHolder",
      description:"Enter FirstName",
      defaultMessage:"Enter first name"
    },
    add_new_user_last_name_placeHolder: {
      id:"add_new_user_last_name_placeHolder",
      description:"Enter LastName",
      defaultMessage:"Enter last name"
    },
    add_new_user_email_placeHolder: {
      id:"add_new_user_email_placeHolder",
      description:"Enter Email",
      defaultMessage:"Enter email"
    },
    add_new_user__email_invalid: {
      id:"add_new_user__email_invalid",
      description:"Validation error message for invalid email",
      defaultMessage:"Invalid email address format"
    },    
    add_new_user__email_invalid_required: {
      id:"add_new_user__email_invalid_required",
      description:"Message to indicate email address is required",
      defaultMessage:"Email address is required"
    },
    add_users_to_store_return: {
      id: "add_users_to_store_return",
      description: "Return",
      defaultMessage: "Return"
    },
    add_users__back: {
      id: "add_users__back",
      description: "Back to all users",
      defaultMessage: "Back to all users"
    },
    create_new_users__user_already_exists: {
      id: "create_new_users__user_already_exists",
      description: "This user already exists.",
      defaultMessage: "This user already exists."
    },
    email_confirmation_title: {
      id: "email_confirmation_title",
      description: "Email Confirmation",
      defaultMessage: "Email Confirmation"
    },
    email_confirmation_message: {
      id: "email_confirmation_message",
      description: "An invitation link has been sent to {firstName} {lastName}.",
      defaultMessage: "An invitation link has been sent to {firstName} {lastName}."
    },
    email_confirmation_ok: {
      id: "email_confirmation_ok",
      description: "Ok",
      defaultMessage: "Ok"
    }
  });
  // #endregion

  // #region Render helpers    
  // #endregion  

  // #region Render
  return (    
    <PageStyled>
      <PageLayout>

        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: "",
            lastName: "",
            emailAddress: ""            
          }}
          onSubmit={handleSubmit}                    
          validationSchema={Yup.object().shape({
            emailAddress: Yup.string().email('add_new_user__email_invalid').required("add_new_user__email_invalid_required"),
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required")
          })}
        >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue                   
        }) => {            
          return (
            <form onSubmit={handleSubmit}>                          

              <FormWrapper formErrors={mainError}/>                                             

              <Subhead>          
                <FormattedMessage {...messages.add_users_to_store_add_new_users_caption} />                             
              </Subhead>

              <InstructionsStyled>                                    
                <FormattedMessage
                  {...messages.add_users_to_store_add_new_user_caption2}
                />                                      
              </InstructionsStyled>
              
              <InputGroupStyled>
                <Input
                  id="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.firstName}
                  touched={touched.firstName}
                  placeholder={formatMessage(messages.add_new_user_first_name_placeHolder)}
                />
                <div>
                  {errors.firstName &&
                    touched.firstName &&
                    <InputFeedback>{errors.firstName}</InputFeedback>}
                </div>
              </InputGroupStyled>
              
              <InputGroupStyled>
                <Input
                  id="lastName"
                  type="text"
                  placeholder={formatMessage(messages.add_new_user_last_name_placeHolder)}
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName}
                  touched={touched.lastName}
                />
                <div>
                  {errors.lastName &&
                    touched.lastName &&
                    <InputFeedback>{errors.lastName}</InputFeedback>}
                </div>
              </InputGroupStyled>
              
              <InputGroupStyled>
                <Input
                  id="emailAddress"
                  type="text"
                  onChange={handleChange}
                  value={values.emailAddress}
                  placeholder={formatMessage(messages.add_new_user_email_placeHolder)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.emailAddress}
                  touched={touched.emailAddress}
                />
                <div>
                  {errors.emailAddress &&
                    touched.emailAddress &&
                    <InputFeedback>
                      <FormattedMessage
                        {...messages[errors.emailAddress]}
                      />                        
                    </InputFeedback>}
                </div>
              </InputGroupStyled>
              
              
              { isSubmitting ? <Spinner /> 
              :
                <ButtonRowStyled>
                  <ButtonStyled role="button" onClick={() => {                    
                    handleSubmit();
                  }}>                  
                    <img src="/assets/VMS_33button_add_Clear background.png" />
                    {" "}
                    <FormattedMessage {...messages.add_new_user_create_user} />                  
                  </ButtonStyled>
                  <div></div>
                </ButtonRowStyled>
              }                            

              <ButtonRowStyled>
                <BackButtonStyled>
                  <Link to={`/Persona/Users`}>                  
                    <img src="/assets/VMS_33button_back_blue2.png" />
                    {' '}
                    <FormattedMessage {...messages.add_users__back} />          
                  </Link>
                </BackButtonStyled>
                <div></div>
              </ButtonRowStyled>

              {<EmailConfirmation
                title={messages.email_confirmation_title}
                message={messages.email_confirmation_message}
                okButtonText={messages.email_confirmation_ok}
                onOKClickEvent={() => {
                  setShowEmailConfirmation(false);                 
                  handleReset();  
                  if (existingUser)
                    routeParams.history.push(
                      `/Persona/Users/${userId}?emailAddress=${values.emailAddress}` +
                      `&firstName=${values.firstName}&lastName=${values.lastName}`);
                  else
                    routeParams.history.push(`/Persona/Users/${userId}`);
              }}
                show={showEmailConfirmation}
                firstName={values.firstName}
                lastName={values.lastName}
              />}

            </form>
          );
        }}
        </Formik>

      </PageLayout>

    </PageStyled>    
  );
};
// #endregion

export default injectIntl(AddUsers);