import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import { Popover, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import './TireDetails.css';
import classnames from 'classnames';

const TireDetails = (props) => {
    let fieldsFocusedAfterValidation = [];
    let inputRefs = {};
    const { manufacturers,
        reasons,
        defaultManufacturer,
        defaultModel,
        defaultReason,
        tireWidth,
        tireAspectRatio,
        tireRimDiameter,
        title,
        onChangeDetails,
        validationErrors,
        onRemoveTireDetailsValidationErr,
        intl} = props;

    const allManufacturersOptions = manufacturers.map((item) => {
        return <option key={item.value} value={item.name}>{item.name}</option>
    })

    const allReasonsOptions = reasons.map(item => {        
        return <option key={item.value} value={item.name}>{intl.formatMessage({id: "reason_" + item.value, defaultMessage: item.name})}</option>
    })

    const onFieldFocus = (fieldName) => {
        fieldsFocusedAfterValidation.concat(fieldName);
        onRemoveTireDetailsValidationErr(fieldName);
    }

    const renderValidationOverlay = (fieldName, placement) => {
        let message = validationErrors.get(fieldName);
        let showError = message != undefined && !fieldsFocusedAfterValidation.includes(fieldName);
        if (message != undefined)
            return <Overlay
                show={showError}
                target={() => inputRefs[fieldName]}
                placement={placement || "right"}
                container={this}>
                <Popover id={"popOver" + fieldName} className="enrollment-details__error_message step1__popover">
                <RawIntlProvider value={props.intl}>
                    <FormattedMessage {...message} />
                    </RawIntlProvider>
                </Popover>
            </Overlay>
        else
            return false;
    }

    const tooltip = (toolTipText) => <Tooltip id="tooltip">{toolTipText}</Tooltip>;

    const onChangeRimDiameter = (fieldName, value) => {
        let regexExp = new RegExp('^[0-9]{1,3}(\.[0-9]{0,1})?$');
        let allowInt = true;
        let allowDec = true;
        if (!value)
            onChangeDetails(fieldName, value);
        else
        {
            //below hack makes sure more than 3 digits before decimal are not allowed.
            //The regex '^[0-9]{1,3}(\.[0-9]{0,1})?$' given above does not prevent whole numbers more than 3
            const digits = value.split('.')

            if(!!digits[0] && digits[0].length > 2)
            {
                allowInt = false;
            }

            if(!!digits[1] && digits[1].length > 1)
            {
                allowDec = false;
            }

            if(allowInt && allowDec)     
                onChangeDetails(fieldName, value);
            }
        



    }

    const {formatMessage} = props.intl;

    const messages = defineMessages({
        model: {
            id: 'model',
            description: 'model',
            defaultMessage: 'Model'
        },
    });
    return (
        <div className='tire-details__container'>
            {
                !props.editOnly ?
                    <div className='tire-details__step-number-5'>
                        <img src="/assets/VMS_28button_5_clear.png" />
                    </div>

                    : false
            }
            <div className='tire-details__title'>{props.title}</div>
            <div className='tire-details__example-text'>{props.exampleText}</div>
            <div className='tire-details__group-panel'>
                <div className='tire-details__measurements'>
                    <OverlayTrigger placement='right' overlay={tooltip('Tire Width')}>
                        <input type='text'
                            className='tire-details__tire-width'
                            placeholder={0}
                            value={tireWidth}
                            maxLength={3}
                            ref={input => inputRefs[constants.TIRE_DETAILS.TIRE_WIDTH] = input}
                            onChange={(event) => onChangeDetails(constants.TIRE_DETAILS.TIRE_WIDTH, event.target.value)}
                            onKeyPress={helperFunctions.isNumeric.bind(this)}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.TIRE_WIDTH)}
                        />
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.TIRE_WIDTH, 'left')}

                    /
                    <OverlayTrigger placement='right' overlay={tooltip('Aspect Ratio')}>
                        <input type="text"
                            className='tire-details__tire-aspect-ratio'
                            placeholder={0}
                            value={tireAspectRatio}
                            maxLength={2}
                            ref={input => inputRefs[constants.TIRE_DETAILS.ASPECT_RATIO] = input}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.ASPECT_RATIO)}
                            onChange={(event) => onChangeDetails(constants.TIRE_DETAILS.ASPECT_RATIO, event.target.value)}
                            onKeyPress={helperFunctions.isNumeric.bind(this)} />
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.ASPECT_RATIO, 'top')}

                    R
                    <OverlayTrigger placement='right' overlay={tooltip('Rim Diameter')}>
                        <input type="text"
                            className='tire-details__tire-rim-diameter'
                            placeholder={0}
                            value={tireRimDiameter}
                            ref={input => inputRefs[constants.TIRE_DETAILS.RIM_DIAMETER] = input}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.RIM_DIAMETER)}
                            onChange={(event) => onChangeRimDiameter(constants.TIRE_DETAILS.RIM_DIAMETER, event.target.value)}
                            onKeyPress={helperFunctions.isNumberKey.bind(this)} />
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.RIM_DIAMETER, 'bottom')}
                </div>
                <div >
                    <OverlayTrigger placement='right' overlay={tooltip('Tire Manufacturer')}>
                        <select name='allManufacturers'
                            className={classnames('tire-details__manufacturers', defaultManufacturer || 'tire-details__disabled-text')}
                            onChange={(event) => onChangeDetails(constants.TIRE_DETAILS.MANUFACTURER, event.target.value)}
                            value={defaultManufacturer || 'placeholder'}
                            ref={input => inputRefs[constants.TIRE_DETAILS.MANUFACTURER] = input}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.MANUFACTURER)}>
                            <option className={'tire-details__disabled-text'} key={'placeholderM'} value={'placeholder'} disabled></option>
                            {allManufacturersOptions}
                        </select>
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.MANUFACTURER, 'left')}

                </div>
                <div >
                    <OverlayTrigger placement='right' overlay={tooltip('Tire Model')}>
                        <input type="text"
                            className='tire-details__model'
                            placeholder={formatMessage(messages.model)}
                            value={defaultModel}
                            maxLength={50}
                            ref={input => inputRefs[constants.TIRE_DETAILS.MODEL] = input}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.MODEL)}
                            onChange={(event) => onChangeDetails(constants.TIRE_DETAILS.MODEL, event.target.value)} />
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.MODEL, 'left')}

                </div>
                <div >
                    <OverlayTrigger placement='right' overlay={tooltip('Reason For Replacement')}>
                        <select name='allReasons'
                            className={classnames('tire-details__reason', defaultReason || 'tire-details__disabled-text')}
                            onChange={(event) => onChangeDetails(constants.TIRE_DETAILS.REASON, event.target.value)}
                            value={defaultReason || 'placeholder'}
                            ref={input => inputRefs[constants.TIRE_DETAILS.REASON] = input}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.REASON)}>
                            <option className={'tire-details__disabled-text'} key={'placeholderR'} value={'placeholder'} disabled></option>
                            {allReasonsOptions}
                        </select>
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.REASON, 'left')}

                </div>
            </div>
        </div>
    );

};


export default injectIntl(TireDetails);