// #region Imports
import React, { useState, useEffect, useRef } from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import * as Api from "utils/Api";
import useDebounce from "../Common/useDebounce"
import Spinner from 'components/Spinner';
import {  
  ModalClose,
  ModalMainHeader,  
  ModalBodyTable,
  ButtonRowStyled,
  ButtonStyled
} from "DesignSpecs/components/FormComponents";
import SelectedUsers from "./SelectedUsers";
import FormWrapper from "components/FormWrapper";
import * as helperFunctions from "utils/HelperFunctions";
// #endregion

// #region Styled Components
const Main = styled.div`	
  padding-bottom: 1rem;
`;

const Header = styled.div`
	text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const Dropdown = styled.div`
  align-content: center; 
  padding-bottom: 1rem;
`;

const SearchInput = styled.input`
	width: 550px;   
  font-family: "Gotham SSm A", "Gotham SSm B";
  padding: 3px 2px 2px 2px;
  border: 1px solid #5587b7;
`;

const SearchIcon = styled.input`
	height: 25px;
  width: 25px;
  margin-left: 1rem;
  vertical-align: bottom;
`;

const SearchResultsOuterBox = styled.div`
  background: rgba(13, 78, 149, 0.28);
`;

const SearchResults = styled.div`
	position: absolute;
	margin-top: 5px;
	width: 550px;
	min-height: 40px;
	max-height: 350px;
	border-radius: 2px;
	box-shadow: 5px 5px 10px 10px rgba(153, 143, 143, 0.42);
	overflow: auto;	
	background: #FFFFFF;
	z-index: 1000;
	border: 2px solid rgb(140, 170, 202);
`;

const SpinnerContainer = styled.div`	
  display: flex;
  justify-content: center;
  align-items: center;
	height: 200px;	
`;

const NoResultsStyled = styled.div`  
  height: 200px;
  padding: 1rem;
`;

const NoResultsTitleStyled = styled.div`  
  font-size: 2em;
  padding-bottom: 2rem;    
`;

const UserRow = styled.div`
	width: 100%;
	height: 50px;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 10px;	
	font-family: "Gotham SSm A", "Gotham SSm B";
	display: flex;
	align-items: center;
  cursor: pointer;
	background-color: ${props => props.alternateRow ? props.theme.color.grayBackground : `inherit`};
`;

const CheckCircleColumn = styled.div`	
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;	
`;

const UserDetailsColumn = styled.div`	
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 1rem;
	padding-right: 0px;	
`;

const HR = styled.hr`
  border-top: 1px solid #e1e4ec;
`;

const TitleStyled = styled.div`
	text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;
// #endregion

// #region Component
const SearchUsers = ({
  selectedUsers,
  token,
  personaUserId,
  routeParams,  
  intl: { formatMessage },
  onUserSelect,
  onRemoveUser,
  onAddUsers,
  onContinue  
}) => {    
  // #region State
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);  // https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
  const [searchingUsers, setSearchingUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [pageErrors, setPageErrors] = useState();
  // #endregion

  // #region Ref
  const dropDownNode = useRef();      
  const firstSearchResultNode = useRef();
  // #endregion

  // #region Effects
  // Handle user clicking outside dropdown.
  useEffect(() => {	
    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }	
	
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);  

  // Search for users.
  useEffect(() => {	  
    let didCancel = true;
    if (debouncedSearchTerm === undefined 
      || debouncedSearchTerm === ''
      || debouncedSearchTerm.length < 3) {
      setUsers([]);
      setDropdownVisible(false);
    } 
    else {	  	
      const fetchData = async () => {						
        try {
          setDropdownVisible(true);
          setSearchingUsers(true);				
          setPageErrors();
          const response = await Api.fetchUsers(personaUserId, 0, 100, debouncedSearchTerm, token, 'emailAddress');
          const users = response.userCount === 0 ? [] : response.users;
          
          setSearchingUsers(false);

          if (didCancel) {
            setDropdownVisible(true);					
            setUsers(users);
          }
        } 
        catch (error) {
          setPageErrors(helperFunctions.getErrorObject(error));
          setSearchingUsers(false);
        }
      }
      fetchData();
    }
	
    return () => {
	    didCancel = false;
    };
  }, [debouncedSearchTerm, personaUserId, token]);
  // #endregion

  // #region Event handlers
  const handleClickOutside = e => {    
    if (dropDownNode.current.contains(e.target)) {
      // inside click      
	}
	else {
		// outside click
		setDropdownVisible(false);
	}
  };

  const handleSearchTermChange = (e) => {    
    if (e.target.value == "") {      
      setDropdownVisible(false);
	}
	
	setSearchTerm(e.target.value);
  }

  const handleSearchClick = () => {
    if (searchTerm != "" && searchTerm != undefined && searchTerm.length >= 3) {
		setDropdownVisible(true);
	}
  }

  const handleSearchKeyDown = e => {    
    switch (e.key) {
      case "ArrowDown":
      case "Enter":
        if (firstSearchResultNode.current !== null) {
          firstSearchResultNode.current.focus();
          e.preventDefault();
        }
        if (!!e.target.value && e.target.value.length >= 3)
          setDropdownVisible(true);
        break;      
    }
  }

  const handleUserKeyDown = (index, user) => e => {    
    switch (e.key) {
      case "ArrowDown":
        if (e.target.nextSibling !== undefined)
          e.target.nextSibling.focus();
        break;

      case "ArrowUp":
        if (e.target.previousElementSibling !== undefined)
          e.target.previousElementSibling.focus();
        break;

      case "Enter":
	      onUserSelect(user);
        break;

      case "Escape":
	      setDropdownVisible(false);
        break;
    }
  }

  const handleUserClick = (user) => {        
    onUserSelect(user);
  }
  // #endregion

  // #region International messages
  const messages = defineMessages({    
    close_dialog: {
      id: "close_dialog",
      description: "  Close",
      defaultMessage: "Close"
    },                
    add_users_to_store_search_caption: {
      id: "add_users_to_store_search_caption",
      description: "Search for users",
      defaultMessage: "Search for users"
    },
    search_users_add_user_text1: {
      id: "search_users_add_user_text1",
      description: "User not found label",
      defaultMessage: "User not found? No problem."
    },
    search_users_add_user_text2: {
      id: "search_users_add_user_text2",
      description: "Set up new user link",
      defaultMessage: "You can set up a new user here"
    },
    search_users_caption: {
      id: "search_users_caption",
      description: "Find and select users to grant access",
      defaultMessage: "Find and select users to grant access"
    },
    search_users_search_placeholder: {
      id: "search_users_search_placeholder",
      description: "Search for users",
      defaultMessage: "Search by email address"
    },
    select_users_caption: {
      id: "select_users_caption",
      description: "Selected users",
      defaultMessage: "Selected users"
    },
    add_users_to_store_continue: {
      id: "add_users_to_store_continue",
      description: "Finish selecting users and continue",
      defaultMessage: "Finish selecting users and continue"
    },
    add_users_to_store__no_user_found: {
      id: "add_users_to_store__no_user_found",
      description: "No user found",
      defaultMessage: "No user found"
    },
  });
  // #endregion

  // #region Render helpers
  const renderCloseButton = () => (    
    <ModalClose role='button' onClick={() => {
      routeParams.history.goBack();
    }}>
      <img src="/assets/VMS_33button_kill_blue.png"/>
      {"  "} {<FormattedMessage {...messages.close_dialog} />}
    </ModalClose>
  );

  function renderSearchResults() {        
    return (
      <SearchResultsOuterBox>
        <SearchResults>          

          {searchingUsers ?
            <SpinnerContainer>
              <Spinner spinnerType="blue" manualPositioning={true} />
            </SpinnerContainer>

          : users === undefined || users.length === 0            
          ? <NoResultsStyled>
              <NoResultsTitleStyled>
                <FormattedMessage {...messages.add_users_to_store__no_user_found} />
              </NoResultsTitleStyled>
              <div>
                <FormattedMessage {...messages.search_users_add_user_text1} />
              </div>
              <div>
                <a onClick={()=>onAddUsers({emailAddress: searchTerm})}>
                  <FormattedMessage {...messages.search_users_add_user_text2} />
                </a>
              </div>
            </NoResultsStyled>

          : users.map((user, index) => {
            let refProp = {};
            if (index===0) refProp['ref'] = firstSearchResultNode; // Get reference to first node.
            return (              
            <UserRow
              key={user.id}				  
              tabIndex={index + 1}
              alternateRow={index % 2 === 0}
              {...refProp}
              onKeyDown={handleUserKeyDown(index, user)}
              onClick={()=>handleUserClick(user)}
            > 
              <CheckCircleColumn>
                <img
                  height='15px'
                  width='15px'
                  src={selectedUsers.findIndex(a => a.id == user.id) == -1
                    ? "/assets/VMS_unchecked.png"
                    : "/assets/VMS_checked.png"                  
                  }
                />
              </CheckCircleColumn>

              <UserDetailsColumn>
                {user.firstName}{' '}{user.lastName}<br />
                {user.emailAddress}                      
              </UserDetailsColumn>
            </UserRow>
          )})}
          
        </SearchResults>
      </SearchResultsOuterBox>
    );
  }
  
  const renderContinueButton = () => (
    <ButtonStyled
      role="button"
      onClick={() => {if (selectedUsers.length>0) onContinue()}}
      disable={selectedUsers.length===0}
    >
      <FormattedMessage {...messages.add_users_to_store_continue} />
      {" "}
      <img src="/assets/VMS_33button_go_bblue.png" />
    </ButtonStyled>
  );
  // #endregion  

  // #region Render
  return (
    <Main>   

      <FormWrapper
        key="pageErrors"
        id="pageErrors"          
        formErrors={pageErrors}
      /> 

      <ButtonRowStyled>        
        {renderCloseButton()}
        <div></div>
      </ButtonRowStyled>                 
      

      <ModalMainHeader>
        <FormattedMessage
          {...messages.add_users_to_store_search_caption}
        />        
      </ModalMainHeader>

      <ModalBodyTable>
        
        <Header>
          <FormattedMessage {...messages.search_users_caption} />
        </Header>      

        <Dropdown ref={dropDownNode} >        
          <SearchInput                       
            type="text"
            placeholder={formatMessage(
              messages.search_users_search_placeholder
            )}
            autocomplete="off"
            value={searchTerm}
            tabIndex="0"
            onChange={handleSearchTermChange}
            onKeyDown={handleSearchKeyDown}
          />
          <SearchIcon            
            type="image"
            src="/assets/multi-user-icon.png"
            alt="Search"
            onClick={handleSearchClick}				
          />        

          {dropdownVisible ? renderSearchResults() : false}

        </Dropdown>
              
        <div>
          <div>
            <FormattedMessage
              {...messages.search_users_add_user_text1}
            />
          </div>
          <div>
            <a onClick={onAddUsers}>
              <FormattedMessage {...messages.search_users_add_user_text2}/>
            </a>
          </div>
        </div>
      
        <HR />

        <TitleStyled>          
          <FormattedMessage {...messages.select_users_caption} />          
        </TitleStyled>   

        <SelectedUsers
          selectedUsers={selectedUsers}
          allowRemovingUser={true}
          onRemoveUser={onRemoveUser}
        />
                    
      </ModalBodyTable>
      
      <ButtonRowStyled>
        <div></div>
        {renderContinueButton()}
      </ButtonRowStyled>                 

    </Main>
  );  
  // #endregion
};
// #endregion

export default injectIntl(SearchUsers);
