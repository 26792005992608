import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import POList from '../components/POList';
import SearchPOTitle from '../components/SearchPOTitle';

import { PO_PAYMENT_STATUS, SEARCH_FILTER_TYPE, SEARCH_DURATION_TYPE, PO_AUTHORIZATION_STATUS,CARWASH } from 'constants/App';
import * as searchPOActions from '../actions/SearchPO';
import Spinner from 'components/Spinner';
import SearchPOSelector from '../selectors/SearchPO';
import './SearchPO.css';
import configuration from 'configuration';
import VehicleInformation from 'components/VehicleInformation';
import * as helperFunctions from 'utils/HelperFunctions';
import Features from 'components/Features';

class SearchPO extends React.Component {

    constructor(props) {
        super(props);
    }

    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }

    getTitle(searchFilters) {
        const messages = defineMessages({
            my_purchase_order: {
                id: 'my_purchase_order',
                description: 'my_purchase_order',
                defaultMessage: 'My Purchase Orders'
            },
            my_purchase_order_dynamic: {
                id: 'my_purchase_order_dynamic',
                description: 'my_purchase_order_dynamic',
                defaultMessage: 'My {ordersTypeText} for last {days} days'
            },
            my_purchase_order_dynamic_search: {
                id: 'my_purchase_order_dynamic_search',
                description: 'my_purchase_order_dynamic_search',
                defaultMessage: 'My {ordersTypeText} for {searchText}'
            },
            payments:{
                id: 'payments',
                description: 'payments',
                defaultMessage: 'Payments'

            },
            invoices:{
                id: 'invoices',
                description: 'invoices',
                defaultMessage: 'Invoices'

            },
            purchase_order:{
                id: 'purchase_order',
                description: 'purchase_order',
                defaultMessage: 'Purchase Orders'

            }
        })

        if (searchFilters != undefined || searchFilters != null) {
            let ordersTypeText = searchFilters.searchFor == SEARCH_FILTER_TYPE.PAYMENT ? <FormattedMessage {...messages.payments}/> :
                searchFilters.searchFor == SEARCH_FILTER_TYPE.INVOICE ? <FormattedMessage {...messages.invoices}/>
                    :<FormattedMessage {...messages.purchase_order}/>;

            if (searchFilters.searchText.length > 0 && searchFilters.days == SEARCH_DURATION_TYPE.DURATION)
                return <FormattedMessage values={{ordersTypeText : ordersTypeText,searchText:searchFilters.searchText}}
        {...messages.my_purchase_order_dynamic_search}/> ;

    else if (searchFilters.searchText.length == 0 && searchFilters.days != SEARCH_DURATION_TYPE.DURATION) {
        let days = '0';
        if (searchFilters.days == SEARCH_DURATION_TYPE.LAST_60_DAYS)
            days = '60';
        else if (searchFilters.days == SEARCH_DURATION_TYPE.LAST_120_DAYS)
            days = '120';

        return <FormattedMessage values={{ordersTypeText : ordersTypeText,days:days}}
        {...messages.my_purchase_order_dynamic}/> ;
    }
}
else
            return  <FormattedMessage {...messages.my_purchase_order}/>;
}

render() {
    const messages = defineMessages({
        return: {
            id: 'return',
            description: 'return',
            defaultMessage: 'Return'
        },

    });

    let searchFilters = this.props.searchFilters.toJSON();
    const serviceInProgressPOs = this.props.pos.filter(o => o.get('paymentStatus') == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED && o.get('authorizationStatus') != PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT
                                                            && o.get('authorizationStatus') != PO_AUTHORIZATION_STATUS.WORK_COMPLETED);
    const paymentInProgressPOs = this.props.pos.filter(o => o.get('paymentStatus') == PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS);
    const workCompletedPOs = this.props.pos.filter(o => o.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.WORK_COMPLETED);
    const paidPOs = this.props.pos.filter(o => o.get('paymentStatus') == PO_PAYMENT_STATUS.PAID);        
        
    const carWashVendor = this.props.serviceClassification == CARWASH;

    return  <div>

        <SearchPOTitle caption={<div>{this.getTitle(searchFilters)} </div>} />

        {searchFilters.searchFor == SEARCH_FILTER_TYPE.VIN8
            ? <div className="search_po_vehicle_info">
                <VehicleInformation vehicleDetails={this.props.vehicleDetails}
    client={this.props.client}
    actionType="SearchPO" />
</div>
            : false
}

{searchFilters.searchFor == SEARCH_FILTER_TYPE.VIN8 || searchFilters.searchFor == SEARCH_FILTER_TYPE.PURCHASEORDER
|| searchFilters.searchFor == SEARCH_FILTER_TYPE.CLIENT_REF_NO
    ? <POList
        pos={serviceInProgressPOs}
    type={PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED}
    showAll={this.props.showAllServiceInProgressPOs}
    footerClickHandler={() => this.props.dispatch(searchPOActions.toggleShowAllServiceInProgressPOs())}
    searchFilters={this.props.searchFilters}
    selectedCurrency={this.props.selectedCurrency}
allowedToEditPO = {!carWashVendor}
/>
: false
}

{(searchFilters.searchFor == SEARCH_FILTER_TYPE.VIN8 || searchFilters.searchFor == SEARCH_FILTER_TYPE.PURCHASEORDER || searchFilters.searchFor == SEARCH_FILTER_TYPE.CLIENT_REF_NO)
 && this.props.isWorkCompletionFeatureEnabled
    ? <POList
        pos={workCompletedPOs}
    type={PO_AUTHORIZATION_STATUS.WORK_COMPLETED}                    
    showAll={this.props.showAllPaymentInProgressPOs}
    footerClickHandler={() => this.props.dispatch(searchPOActions.toggleShowAllPaymentInProgressPOs())}
    searchFilters={this.props.searchFilters}
    selectedCurrency={this.props.selectedCurrency}
/>
                : false
}

{searchFilters.searchFor == SEARCH_FILTER_TYPE.VIN8 || searchFilters.searchFor == SEARCH_FILTER_TYPE.PURCHASEORDER || searchFilters.searchFor == SEARCH_FILTER_TYPE.CLIENT_REF_NO
    ? <POList
        pos={paymentInProgressPOs}
    type={PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS}
    showAll={this.props.showAllPaymentInProgressPOs}
    footerClickHandler={() => this.props.dispatch(searchPOActions.toggleShowAllPaymentInProgressPOs())}
    searchFilters={this.props.searchFilters}
    selectedCurrency={this.props.selectedCurrency}
/>
                : false
}

            <POList
pos={paidPOs}
type={PO_PAYMENT_STATUS.PAID}
showAll={this.props.showAllPaidPOs}
footerClickHandler={() => this.props.dispatch(searchPOActions.toggleShowAllPaidPOs())}
searchFilters={this.props.searchFilters}
selectedCurrency={this.props.selectedCurrency}
/>

<div className='search-po__return'>
    <a onClick={this.onReturnClickHandler.bind(this)}>
        <img src="/assets/VMS_33button_back_blue.png" />
        {' '} <FormattedMessage {...messages.return}/>
    </a>
</div>

</div>;
}
}


function mapStateToProps(state, props){
    return {
        serviceClassification: state.appState.getIn(['serverData', 'shared', 'serviceClassification']),
        paymentType: state.appState.getIn(['serverData', 'shared', 'paymentType']),
        isWorkCompletionFeatureEnabled: state.appState.getIn(['uiData', 'shared', 'features','workCompletion']),
        ...SearchPOSelector(state, props),
    }
    }



export default connect(mapStateToProps)(SearchPO);