//libraries
import React from 'react';
import { defineMessages, FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { Map } from 'immutable';
import classNames from 'classnames';
import { Grid, Row, Col } from 'react-bootstrap';
import { IntlProvider } from 'react-intl';
//our stuff
import { LINE_ITEM_TYPE, LINE_ITEM_STATUS, FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR, ENTITY_FOR_ASSISTANCE } from 'constants/App';
import '../containers/ReviewActionRequiredItemView.css';
import './AdjustedLineItem.css';
import Notes from './Notes.js';
import { useAppState } from 'shared/AppState/app-state';
import FleetAdminContactInfo from 'shared/components/FleetAdminContactInfo';

const RejectedLineItem = (props) => {

    const [appState, dispatch] = useAppState();

    const onAcceptClick = (lineItemId, e) => {
        props.onAcceptRejectionClickHandler(lineItemId);
        props.closeModalHandler();
    }

    const messages = defineMessages({
        adjusted_line_item_view_quantity: {
            id: 'adjusted_line_item_view_quantity',
            description: 'Qty',
            defaultMessage: 'Qty'
        },
        adjusted_line_item_view_hour: {
            id: 'adjusted_line_item_view_hour',
            description: 'Hour',
            defaultMessage: 'Hour'
        },
        adjusted_line_item_view_cost: {
            id: 'adjusted_line_item_view_cost',
            description: 'Cost',
            defaultMessage: 'Cost'
        },
        adjusted_line_item_view_amount: {
            id: 'adjusted_line_item_view_amount',
            description: 'Amount',
            defaultMessage: 'Amount'
        },
        action_required_submitted_description: {
            id: 'action_required_submitted_description',
            description: 'Submitted',
            defaultMessage: 'Submitted'
        },
        close_dialog: {
            id: 'close_dialog',
            description: '  Close',
            defaultMessage: 'Close'
        },
        action_required_issue_note: {
            id: 'action_required_issue_note',
            description: 'If you have questions regarding these adjustments, please call \n1-866-274-1025 to discuss with a Holman technician',
            defaultMessage: 'If you have questions regarding these adjustments, please call \n1-866-274-1025 to discuss with a Holman technician'
        },
        rejected_line_item_status_description: {
            id: 'rejected_line_item_status_description',
            description: '{productDescription} was rejected by Holman',
            defaultMessage: '{productDescription} was rejected by Holman'
        },
        rejected_line_item_status_description_client: {
          id: 'rejected_line_item_status_description_client',
          description: '{productDescription} was rejected by client',
          defaultMessage: '{productDescription} was rejected by client'
      },
        action_required_issue_note_can: {
            id: 'action_required_issue_note_can',
            description: 'If you have questions regarding these adjustments, please call \n1-800-363-7676 to discuss with a Holman technician',
            defaultMessage: 'If you have questions regarding these adjustments, please call \n1-800-363-7676 to discuss with a Holman technician'
        },
        action_required_note_from_ari_description: {
            id: 'action_required_note_from_ari_description',
            description: 'Note from Holman',
            defaultMessage: 'Note from Holman'
        },
        rejected_line_payment_not_authorized_description: {
            id: 'rejected_line_payment_not_authorized_description',
            description: 'NO PAYMENT WILL BE AUTHORIZED FOR THIS LINE',
            defaultMessage: 'NO PAYMENT WILL BE AUTHORIZED FOR THIS LINE'
        },
        action_required_note_from_ari_description: {
            id: 'action_required_note_from_ari_description',
            description: 'Note from Holman',
            defaultMessage: 'Note from Holman'
        },        
        authorization_status_reason_client: {
          id: 'authorization_status_reason_client',
          description: 'Note from client',
          defaultMessage: 'Note from client'
        },
        rejected_line_item_accept_description: {
            id: 'rejected_line_item_accept_description',
            description: ' Accept ',
            defaultMessage: ' Accept '
        },
        rejected_line_item_reviewed_description: {
            id: "rejected_line_item_reviewed_description",
            description: "You've reviewed this line",
            defaultMessage: "You've reviewed this line"
        },
        rejected_line_window_title: {
            id: "rejected_line_window_title",
            description: "Please Review",
            defaultMessage: "Please Review"
        },
        auto_negotiated_note_US: {
            id: 'auto_negotiated_note_US',
            description: 'If you do not accept these changes, you must call Holman at \n1-866-274-1025',
            defaultMessage: 'If you do not accept these changes, you must call Holman at \n1-866-274-1025'
        },
        auto_negotiated_note_CAN: {
            id: 'auto_negotiated_note_CAN',
            description: 'If you do not accept these changes, you must call Holman at \n1-800-363-7676',
            defaultMessage: 'If you do not accept these changes, you must call Holman at \n1-800-363-7676'
        }
    });


    const lineItem = props.lineItem;
    const productDescription = ( appState.isProductCodeFeatureEnabled && lineItem.get('vendorProductCode') !== undefined && lineItem.get('vendorProductCode') !== null ? lineItem.get('vendorProductCode') + ' - ' : '' ) + lineItem.get('productDescription') + ', ' + (lineItem.get('lineItemType') == LINE_ITEM_TYPE.PM ? LINE_ITEM_TYPE.PM_TEXT : lineItem.get('lineItemType').toUpperCase());
    const submittedQty = lineItem.get('previousQuantity') != null ? lineItem.get('previousQuantity') : lineItem.get('quantity'); //if previous value is null, that means Holman tech did not adjust this field
    const submittedCost = lineItem.get('previousUnitPrice') != null ? lineItem.get('previousUnitPrice') : lineItem.get('unitPrice');
    const submittedTotal = submittedQty * submittedCost;
    const ariQty = lineItem.get('quantity');
    const ariCost = lineItem.get('unitPrice');
    const ariTotal = lineItem.get('totalAmount');
    const hasAdjustment = lineItem.get('previousQuantity') != null || lineItem.get('previousUnitPrice') != null;
    const lineNotes = lineItem.get('authorizationStatusReason');    
    
    
    return <div className="add-new-line-items">
        <div className="row action_required_line_item__item1 add-new-line-items__close">
            <div onClick={props.closeModalHandler}>
                <img className="add-new-line-items__close-button"
                    src="/assets/VMS_33button_kill_blue.png" />
                {"  "} {<FormattedMessage {...messages.close_dialog} />}
            </div>
        </div>
        <div className='action_required_line_item__item1 add-new-line-items_step-1-2-3-container'>
            <div className="row add-new-line-items__caption">
                <FormattedMessage {...messages.rejected_line_window_title} />
            </div>
        </div>
        {lineItem.get('reviewCompleted') ?
            <div className="action_required_line_item__item1">
                <div className="row action_required_line_item__header">
                    <FormattedMessage {...messages.rejected_line_item_reviewed_description} />
                    <img className="adjusted_line_item__img_reviewed"
                        src="/assets/greenOK.png" />
                </div>
            </div> : false}
        <div className="add-new-line-items__search-ata-codes_border">
            <div className="action_required_line_item">

                <div className="action_required_line_item__lines">
                    <Row>
                        <Col xs={12} className="action_required_line_item__header_2 action_required_line_item__center">

                          { props.isHsbClientVehicle && props.entityForAssistance == ENTITY_FOR_ASSISTANCE.CUSTOMER ?
                            <FormattedMessage values={{ productDescription: productDescription }} {...messages.rejected_line_item_status_description_client} />
                          :
                            <FormattedMessage values={{ productDescription: productDescription }} {...messages.rejected_line_item_status_description} />
                          }

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={1}></Col>
                        <Col xs={10}>
                            <Row className="action_required_line_item">
                                <Col xs={2}></Col>

                                <Col xs={5} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage  {...messages.adjusted_line_item_view_quantity} />
                                </Col>
                                <Col xs={2} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage {...messages.adjusted_line_item_view_cost} />
                                </Col>
                                <Col xs={2} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage {...messages.adjusted_line_item_view_amount} />
                                </Col>
                                <Col xs={2}></Col>
                            </Row>

                            <Row>
                                <Col xs={2}></Col>
                                <Col xs={3} className="action_required_line_item__item action_required_line_item__row_bottom_last">
                                    <FormattedMessage {...messages.action_required_submitted_description} /></Col>
                                <Col xs={2}
                                    className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">
                                    {submittedQty}
                                </Col>
                                <Col xs={2} className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">
                                    <IntlProvider locale="en">
                                        <FormattedNumber value={submittedCost} style="currency"
                                            currency={props.selectedCurrency} />
                                    </IntlProvider>

                                </Col>
                                <Col xs={2} className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">
                                    <IntlProvider locale="en">
                                        <FormattedNumber value={submittedTotal} style="currency"
                                            currency={props.selectedCurrency} />
                                    </IntlProvider>
                                </Col>
                                <Col xs={2}></Col>
                            </Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                    <Row className="row">
                        <Col xs={12} className="action_required_line_item__item action_required_line_item__header_red action_required_line_item__center">
                            <FormattedMessage {...messages.rejected_line_payment_not_authorized_description} />
                        </Col>
                    </Row>

                    <Row className="row">
                        <Col className="action_required_line_item__header_2" xs={12}>
                          {props.isHsbClientVehicle && props.entityForAssistance == ENTITY_FOR_ASSISTANCE.CUSTOMER ?
                            <FormattedMessage {...messages.authorization_status_reason_client} />  
                          :
                            <FormattedMessage {...messages.action_required_note_from_ari_description} />  
                          }
                          
                        </Col></Row>
                    <Row>
                        <Col xs={12} className="action_required_line_item__item1">
                            <Notes text={lineNotes} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={10}></Col>
                        <Col xs={2} className="action_required_line_item__item">

                        </Col>
                    </Row>

                </div>
            </div>
        </div>
        <div className="action_required_line_item__item add-new-line-items__save" onClick={() => onAcceptClick(lineItem.get('id'))}>
            <FormattedMessage {...messages.rejected_line_item_accept_description} />
            <img src="/assets/VMS_33button_go_bblue.png" />

        </div>
        {
        props.isHsbClientVehicle && props.entityForAssistance == ENTITY_FOR_ASSISTANCE.CUSTOMER ? <FleetAdminContactInfo messageFor={FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR.REJECTED_LINEITEM}  fleetAdminContactInfo={props.fleetAdminContactInfo} fleetAdminContactInfoError={props.fleetAdminContactInfoError} /> :
            <div className="action_required__issue_note-angry">
            {
                props.selectedCountry == 'USA' ? <FormattedMessage {...messages.auto_negotiated_note_US} /> :
                    <FormattedMessage {...messages.auto_negotiated_note_CAN} />
            }
            </div>
        }
        
    </div>
}


export default RejectedLineItem;