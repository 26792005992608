// Libraries
import React from 'react'
import classNames from 'classnames';
// Our stuff
import './CheckButton.css';

const CheckButton = (props) => {    
    return (
        <div className={classNames('check_button', {'check_button--selected': props.isSelected})}
            role='button'
            onClick={!props.isSelected ? ()=>props.onSelect(props.value) : ()=>props.onUnselect(props.value)}>
            
            {props.displayText}        

        </div>
    )
}



CheckButton.defaultProps = {
  isSelected: 'false'
};

export default CheckButton;