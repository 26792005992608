/**
 * Created by prboyapa on 4/6/2016.
 */
import React from 'react';
import ReactDoM from 'react-dom'
import {connect} from 'react-redux';
import {Map} from 'immutable';
import {defineMessages} from 'react-intl';
import PurchaseOrderBasicDetails from './PurchaseOrderBasicDetails';
import PurchaseOrderTitle from '../../screens/PO/AddEditView/components/PurchaseOrderTitle';
import Complaints from './Complaints';
import AddComplaint from 'AddEditView/AddComplaint';
import POTotals  from '../../screens/PO/AddEditView/components/POTotals';
import Services from './Services';
import Diagnosis  from './Diagnosis';
import EstimatedCompletionDate from './EstimatedCompletionDate';
import RequestApproval from './RequestApproval';
import AddServiceLineItemsView from '../../screens/PO/AddEditView/screens/AddServiceLineItemsView/containers/AddServiceLineItemsView';
import LineItemWizard from '../../screens/PO/AddEditView/screens/AddNewLineItemsView/containers/LineItemWizard';
import VehicleTitle from '../../screens/PO/ComplaintView/components/ComplaintVehicleTitle';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';
import Features from 'components/Features';
import { useAppState } from 'shared/AppState/app-state';
import RepairNotes from './RepairNotes';

const AddEditViewPurchaseOrders = (props) => {

    const [appState, dispatch] = useAppState();

    const displayName = 'AddEditViewPurchaseOrders';

   
        const messages = defineMessages({
            return: {
                id: 'return',
                description: 'Return',
                defaultMessage: 'Return'
            }
        });

        /* Filter the data based on the complaint code for Complaints component */
        const nonMaintenanceComplaints = props.complaints.filter(co => co.get('code') != 'MA99' && co.get('code') != 'DG99' && co.get('code') != 'SA01');
        const nonMaintenanceLineItems = props.lineItems.valueSeq().filter(li => li.get('complaintCode') != 'MA99' && li.get('complaintCode') != 'DG99' && li.get('complaintCode') != 'SA01').toMap();
        const disableMaintenanceComplaintType = props.lineItems.count(li => li.get('complaintCode') == 'MA99') > 0
            || props.complaints.count(co => co.get('code') == 'MA99') > 0;


        /* Filter the line items data based on the complaint code for Services components */
        const maintenanceComplaints = props.complaints.valueSeq().filter(co => co.get('code') == 'MA99').toMap();
        const maintenanceLineItems = props.lineItems.valueSeq().filter(li => li.get('complaintCode') == 'MA99').toMap();
        const maintenanceComplaintKey = props.complaints.filter(co => co.get('code') == 'MA99').first() != undefined ? props.complaints.filter(co => co.get('code') == 'MA99').keys().next().value : undefined;
        const maintenanceComplaint = props.complaints.filter(co => co.get('code') == 'MA99').toMap();
        const maintenanceNotesText = maintenanceComplaintKey != undefined && props.notes != undefined ? props.notes.getIn([maintenanceComplaintKey, 'noteText']) : undefined;
        const maintenanceNotesTextHistory =  emptyMapIfUndefined(props.notesHistory).filter(notesHistory => notesHistory.get('complaintCode') === 'MA99') ;
      
        /* Filter the line items data based on the complaint code for Diagnosis components */
        const diagnosisLineItems = props.actionType == 'add' ?
            emptyMapIfUndefined(props.lineItems.valueSeq().filter(li => li.get('complaintCode') == 'DG99').toMap())
            :
            props.lineItems.valueSeq().filter(li => li.get('complaintCode') == 'DG99').toMap();

        /* TODO: po status is nowhere to identify as of now, so we need to address it once we integrate with the services */
        return <Features render={restrictedFeatures => ( <div>
            {
                props.actionType == 'view'
                    ? false
                    : <AddServiceLineItemsView onClearServices = {props.onClearServices}/>
            }
            
            <LineItemWizard
                isTireReadOnly = {props.isTireReadOnly}
                isTireProductCode = {props.isTireProductCode}
                isBrakesProductCode = {props.isBrakesProductCode}
            />
            
            {props.actionType == 'add'
                ? <VehicleTitle caption={<div>{props.vehicleDetails.get('year') + ' ' +
                    props.vehicleDetails.get('make') + ' ' +
                    props.vehicleDetails.get('model') } </div>}/>
                :

                <PurchaseOrderTitle paymentStatus={props.paymentStatus}
                    authorizedStatus={props.authorizedStatus}
                    actionType={props.actionType}
                    poNumber={props.poNumber}
                    approvedPOTotal={props.approvedAmountTotal}
                    bankAccountNo={props.bankAccountNo}
                    paymentInformation={props.paymentInformation}
                    paymentDate={props.paymentDate}
                    taxAmount={props.taxAmount}
                    selectedCurrency={props.selectedCurrency}
                    hideCostInformation = {!props.canShow}
                />
            }

            <PurchaseOrderBasicDetails vehicleDetails={props.vehicleDetails}
                actionType={props.actionType.toUpperCase() }
                client={props.client}
                paymentStatus={props.paymentStatus}
                authorizedStatus={props.authorizedStatus}/>
            {
                props.vehicleDetails.get('isHsbClientVehicle') &&
                (props.actionType == 'edit' || props.actionType == 'view') &&
                props.notesHistory !== undefined && props.notesHistory.filter(n => n.get('category') == 'CLIENT_VENDOR').size > 0 &&
                props.poNumber !== undefined ? <RepairNotes notesHistory={props.notesHistory} /> : false
            }
            {
                /*Check if complaints available, render the PM Services line; otherwise, render nothing*/
                maintenanceLineItems.count() > 0 || maintenanceComplaints.count() > 0 ?
                    <Services lineItems={maintenanceLineItems}
                              actionType={props.actionType.toUpperCase() }
                              serviceDescription={props.servicesDescription == undefined || props.servicesDescription == null ? '' : props.servicesDescription}
                              onAddLineItemsClick={props.onAddLineItemsMaintenanceClick}
                              onMissingProductCodeIconClick={props.onMissingProductCodeIconClick}
                              onAddLineItemsMaintenanceClick={props.onAddLineItemsClick}
                              onServiceDescriptionSave={props.onServiceDescriptionSave}
                              onDeleteLineItemClick={props.onDeleteLineItemClick}
                              onEditLineItemClick={props.onEditLineItemClick}
                              onUpdateLineItemClick={props.onUpdateLineItemClick}
                              onCancelUpdateLineItemClick={props.onCancelUpdateLineItemClick}
                              hideCostInformation={!props.canShow}
                              complaintKey={maintenanceComplaintKey}
                              notesText={maintenanceNotesText}
                              notesTextHistory={maintenanceNotesTextHistory}
                              onComplaintNotesSave={props.onComplaintNotesSave}
                              onComplaintNotesSaveState={props.onComplaintNotesSaveState}
                              hideAddLineItemsForServices ={true} /*{props.actionType == 'add' ? true : false}*/
                              anyRemainingAtaCodesToBeAdded = {props.anyRemainingAtaCodesToBeAdded}
                              lineItemIdBeingEdited={props.lineItemIdBeingEdited}
                              onOpenTiresInfo = {props.onOpenTiresInfo}
                              selectedCurrency={props.selectedCurrency}
                              listOfComplaintsForWhichNoteIsRequired={props.listOfComplaintsForWhichNoteIsRequired}
                    />
                    : false
            }
            {
                /*Check if complaints available, render the complaints; otherwise, render nothing*/
                nonMaintenanceComplaints.count() > 0 ?
                    <Complaints complaints={nonMaintenanceComplaints}
                        notes={props.notes}
                        notesHistory={props.notesHistory}
                        complaintTypes={props.complaintTypes}
                        disableMaintenanceComplaintType={disableMaintenanceComplaintType}
                        canAddAnotherComplaint={props.canAddAnotherComplaint}
                        lineItems={nonMaintenanceLineItems}
                        showErrorMessages={false}
                        actionType={props.actionType.toUpperCase() }
                        isInitialAddMode={false}
                        onAddAnotherComplaintClick={props.onAddAnotherComplaintClick}
                        onComplaintTypeClick={props.onComplaintTypeClick}
                        onChangeComplaintClick={props.onChangeComplaintClick}
                        onAddLineItemsClick={props.onAddLineItemsClick}
                        onMissingProductCodeIconClick={props.onMissingProductCodeIconClick}
                        onAddLineItemsMaintenanceClick={props.onAddLineItemsMaintenanceClick}
                        onDeleteLineItemClick={props.onDeleteLineItemClick}
                        onEditLineItemClick={props.onEditLineItemClick}
                        onUpdateLineItemClick={props.onUpdateLineItemClick}
                        onCancelUpdateLineItemClick={props.onCancelUpdateLineItemClick}
                        hideCostInformation = {!props.canShow}
                        creatingOrEditingComplaintForComplaintKey={props.creatingOrEditingComplaintForComplaintKey}
                        skippingComplaintForComplaintKey={props.skippingComplaintForComplaintKey}
                        onComplaintNotesSave={props.onComplaintNotesSave}
                        onComplaintNotesSaveState={props.onComplaintNotesSaveState}
                        lineItemIdBeingEdited={props.lineItemIdBeingEdited}
                        onOpenTiresInfo = {props.onOpenTiresInfo}
                        selectedCurrency={props.selectedCurrency}
                        listOfComplaintsForWhichNoteIsRequired={props.listOfComplaintsForWhichNoteIsRequired}
                        />
                    : false
            }

            {
                /*Check if complaints available, render the Diagnosis line; otherwise, render nothing*/
                diagnosisLineItems.count() > 0 ?
                    <Diagnosis lineItems={diagnosisLineItems}
                        actionType={props.actionType.toUpperCase() }
                        onDeleteLineItemClick={props.onDeleteLineItemClick}
                        onEditLineItemClick={props.onEditLineItemClick}
                        onUpdateLineItemClick={props.onUpdateLineItemClick}
                        onCancelUpdateLineItemClick={props.onCancelUpdateLineItemClick}
                        hideCostInformation={!props.canShow}
                        lineItemIdBeingEdited={props.lineItemIdBeingEdited}
                         selectedCurrency={props.selectedCurrency}
                    />
                    : false
            }

            {
                props.actionType != 'view' ?
                    <AddComplaint 
                        onAddAnotherComplaintClick={props.onAddAnotherComplaintClick}
                        canAddAnotherComplaint={props.canAddAnotherComplaint} />
                : false 
            }

            {
                /*Render the PO Totals if lines or available or not */
                props.canShow == true ?
                    <POTotals approvedAmountTotal={props.approvedAmountTotal}
                        pendingAmountTotal={props.pendingAmountTotal}
                        actionType={props.actionType.toUpperCase() }
                        selectedCurrency={props.selectedCurrency}
                        taxAmount={props.taxAmount}
                    />
                    : false

            }

            {
                /*Render the Estimated date for all actions */
                props.canShow == true ?
                    <EstimatedCompletionDate estimatedCompletionDate={props.estimatedCompletionDate}
                        actionType={props.actionType.toUpperCase() }
                        userLocale={props.userLocale}
                        onDateChangeEvent={props.onDateChangeEvent}
                        purchaseOrder={props.purchaseOrder}
                        isEstimateDateValid = {props.isEstimateDateValid}
                        onUpdateEstimatedCompletionDate={props.onUpdateEstimatedCompletionDate}

                        />
                    : false

            }

            {
                /* Request approval renders the action link to perform actions
                 to raise request to approval or close po or view po */
                (props.canShow == true) || 
                (props.isCarWashVendor &&  props.actionType == 'view') ?
                    <RequestApproval estimatedCompletionDate={props.estimatedCompletionDate}
                        actionType={props.actionType.toUpperCase() }
                        onSavePurchaseOrderDetails={props.onSavePurchaseOrderDetails}
                        onUpdatePurchaseOrderDetails={props.onUpdatePurchaseOrderDetails}
                        onCancelPOEvent={props.onCancelPOEvent}
                        isDirtyPO={props.isDirtyPO}
                        lineItemsCount={props.lineItems.count() }
                        displayErrorMessage={props.displayErrorMessage}
                        canShow={props.canShow}
                        isRequestingApproval={props.isRequestingApproval}
                        authorizedStatus={props.authorizedStatus}
                        onUpdateEstimatedCompletionDate={props.onUpdateEstimatedCompletionDate}
                        requestApprovalDisable={props.requestApprovalDisable}
                        allowedToClosePO = {props.allowedToClosePO && !restrictedFeatures.readOnlyPOMode && appState.poPermissions.includes('CL')}
                        allowedToCancelPO = {props.allowedToCancelPO && !restrictedFeatures.readOnlyPOMode && appState.poPermissions.includes('VD')}
                        lineItems={props.lineItems}
                    />
                    : false
            }
        </div>)}/>;
    
}


export default AddEditViewPurchaseOrders;