import React from "react";
import {
  defineMessages,
  FormattedMessage,
  injectIntl,RawIntlProvider
} from "react-intl";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import "./Details.css";
import Submit from "components/Submit";
import * as constants from "constants/App";
import classnames from "classnames";
import { Popover, Overlay } from "react-bootstrap";
import * as helperFunctions from "utils/HelperFunctions";
import VendorTermsAndConditions
  from "../../../../shared/components/VendorTermsAndConditions";

  import DynamicNumber from 'react-dynamic-number';

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValidated: false, //whether form is validated or not
      fieldsFocusedAfterValidation: [] //array of fields that have recieved focus event
    };
  }

  toggleTermsAndConditions(inputName, isSelected, event) {
    this.onFieldFocus("termsReadAndAgreed"); //to hide validation message on focus

    this.props.onRequestInfoChange(inputName, isSelected);
  }

  laborRateCheckHandler(inputName, event) {
    

    
    switch(inputName){
      case constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.BODY_SHOP_RATE: this.onFieldFocus("bodyShopRate"); break;

case constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.MOBILE_RATE: this.onFieldFocus("mobileOnsiteRate"); break; 

case constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.EQUIPMENT_RATE : this.onFieldFocus("equipmentAddRate"); break; 

case constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.LIGHT_DUTY_RATE : this.onFieldFocus("lightDutyRate"); break; 

case constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.MEDIUM_DUTY_RATE: this.onFieldFocus("mediumDutyRate"); break;  

case constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.HEAVY_DUTY_RATE: this.onFieldFocus("heavyDutyRate"); break;  

case constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.TRAILER_RATE : this.onFieldFocus("trailerRate"); break;  

default: this.onFieldFocus("ariStandardRate"); break;
    }
         //to hide validation message on focus

        this.props.onRequestInfoChange(inputName, event);
    }

  selectTaxClassification(
    inputName,
    thisElementTaxClassification,
    isSelected,
    taxClassificationInState,
    event
  ) {
    this.onFieldFocus("selectedTaxClassification"); //to hide validation message on focus

    if (thisElementTaxClassification != taxClassificationInState) {
      event.target.src = isSelected
        ? "/assets/VMS_checked.png"
        : "/assets/VMS_unchecked.png";
      event.target.value = thisElementTaxClassification;
      this.props.onRequestInfoChange(inputName, event);
    }
  }

  onChangeSSN(number, type, inputName, event) {
    this.props.onRequestInfoChange(inputName, event);
    if (event.target.value.length >= event.target.maxLength) {
      if (number == "1" || number == "2") {
        number += 1;
        document.getElementById((type + number).toString()).focus();
      }
    }
  }

  onChangeEmployerId(number, type, inputName, event) {
    this.props.onRequestInfoChange(inputName, event);
    if (number == "1" && event.target.value.length >= event.target.maxLength) {
      number += 1;
      document.getElementById((type + number).toString()).focus();
    }
  }

  onChangeBusinessNumber(inputName, event) {
    this.props.onRequestInfoChange(inputName, event);
  }

  //Adds the field to the list of focused fields
  onFieldFocus(fieldName) {
    let updatedFocusFields = this.state.fieldsFocusedAfterValidation.concat(
      fieldName
    );
    this.setState({ fieldsFocusedAfterValidation: updatedFocusFields });
  }

  //Gets if the field is valid
  isFieldValid(fieldName) {
    return (
      !this.state.fieldsFocusedAfterValidation.includes(fieldName) &&
      this.state.formValidated &&
      this.props.formValidationDetails.inValidFields.includes(fieldName)
    );
  }

  renderValidationOverlay(fieldName, message, placement) {
    return (
      <Overlay
        show={this.isFieldValid(fieldName)}
        target={() => ReactDOM.findDOMNode(this.refs[fieldName])}
        placement={placement != undefined ? placement : "right"}
        container={this}
      >
        <Popover
          id={"popOver" + fieldName}
          className="enrollment-details__error_message"
        >
           <RawIntlProvider value={this.props.intl}>
          <FormattedMessage {...message} />
          </RawIntlProvider>
        </Popover>
      </Overlay>
    );
  }

  onSubmit() {
    this.setState({ formValidated: true, fieldsFocusedAfterValidation: [] });
    if (this.props.formValidationDetails.isFormValid) {
      this.props.onSubmit();
    }
  }
  
  getTaxTypes() {

    return <div>{this.props.federalTaxClassifications.map((item) => {
      return(
      <li className="enrollment-details__tax-list">
        <img
          ref={item.get("code")}
          src={
            item.get("code") ==
              this.props.enrollmentDetailsInfo.get(
                "selectedTaxClassification"
              )
              ? "/assets/VMS_checked.png"
              : "/assets/VMS_unchecked.png"
          }
          onClick={this.selectTaxClassification.bind(
            this,
            constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME
              .SELECTED_TAX_CLASSIFICATION,
            item.get("code"),
            !(item.get("code") ==
              this.props.enrollmentDetailsInfo.get(
                "selectedTaxClassification"
              )),
            this.props.enrollmentDetailsInfo.get(
              "selectedTaxClassification"
            )
          )}
        />
        <label className="">{item.get("description")}</label>
      </li>
      );
    }).valueSeq()} </div>;
}
  render() {
    const messages = defineMessages({
      supplier_enrollment: {
        id: "supplier_enrollment",
        description: "Supplier Enrollment",
        defaultMessage: "Supplier Enrollment Request"
      },
      terms_and_conditions: {
        id: "terms_and_conditions",
        description: "Terms and conditions",
        defaultMessage: "Terms and conditions"
      },
      all_officials_sub_heading: {
        id: "all_officials_sub_heading",
        description: "All official Holman service facilities:",
        defaultMessage: "All official Holman service facilities:"
      },
      read_and_agree: {
        id: "read_and_agree",
        description: "I have read and agree to the terms and conditions indicated above.",
        defaultMessage: "I have read and agree to the terms and conditions indicated above."
      },
      accepted_by: {
        id: "accepted_by",
        description: "Accepted by:",
        defaultMessage: "Accepted by:"
      },
      tax_information: {
        id: "tax_information",
        description: "Tax information",
        defaultMessage: "Tax information"
      },
      legal_name: {
        id: "legal_name",
        description: "Legal business name",
        defaultMessage: "Legal business name"
      },
      legal_name_note: {
        id: "legal_name_note",
        description: "(as detailed in tax records)",
        defaultMessage: "(as detailed in tax records)"
      },
      contact_name: {
        id: "contact_name",
        description: "Contact name",
        defaultMessage: "Contact name"
      },
      business_dba_required: {
        id: "business_dba_required",
        description: "Business name (DBA)",
        defaultMessage: "Business name (DBA)"
      },
      tax_classification: {
        id: "tax_classification",
        description: "Federal tax classification",
        defaultMessage: "Federal tax classification"
      },
      ssn: {
        id: "ssn",
        description: "Social Security no.",
        defaultMessage: "Social Security no."
      },
      employer_id: {
        id: "employer_id",
        description: "Employer ID no.",
        defaultMessage: "Employer ID no."
      },
      client_referral: {
        id: "client_referral",
        description: "If you were referred to Holman by one of our clients, please provide their name",
        defaultMessage: "If you were referred to Holman by one of our clients, please provide their name"
      },
      liability_tax_classification: {
        id: "liability_tax_classification",
        description: "Enter the tax classification (C=C corp, S=S corp, P=Partnership)",
        defaultMessage: "Enter the tax classification (C=C corp, S=S corp, P=Partnership)"
      },
      cancel: {
        id: "cancel",
        description: "Cancel",
        defaultMessage: "Cancel"
      },
      submit: {
        id: "submit",
        description: "Submit",
        defaultMessage: "Submit"
      },
      service_classification: {
        id: "service_classification",
        description: "business_classification",
        defaultMessage: "Business Classification"
      },
      maintenance_general_classification: {
        id: "maintenance_general_classification",
        description: "maintenance_general_classification",
        defaultMessage: "General Repair & Maintenance"
      },
      other_classification: {
        id: "other_classification",
        description: "other_classification",
        defaultMessage: "Car Wash or Detail-Only"
      },
      businessNumber: {
        id: "businessNumber",
        description: "Business Number",
        defaultMessage: "Business Number"
      },
        labor_rate: {
            id: 'labor_rate:',
            description: 'labor_rate',
            defaultMessage: 'Labor Rates'
        },
        optional_rates: {
            id: 'optional_rates:',
            description: 'optional_rates',
            defaultMessage: 'Optional rates'
        },
        body_shop_rate: {
            id: 'body_shop_rate:',
            description: 'body_shop_rate',
            defaultMessage: 'Body Shop Rate'
        },
        mobile_onsite_rate: {
            id: 'mobile_onsite_rate:',
            description: 'mobile_onsite_rate',
            defaultMessage: 'Mobile/Onsite Rate'
        },
        equipment_add_rate: {
            id: 'equipment_add_rate:',
            description: 'equipment_add_rate',
            defaultMessage: 'Equipment/Add-on Rate'
        },
        light_duty: {
            id: 'light_duty:',
            description: 'light_duty',
            defaultMessage: 'Light Duty'
        },
        medium_duty: {
            id: 'medium_duty:',
            description: 'medium_duty',
            defaultMessage: 'Medium Duty'
        },
        heavy_duty: {
            id: 'heavy_duty:',
            description: 'heavy_duty',
            defaultMessage: 'Heavy Duty'
        },
        trailer: {
            id: 'trailer:',
            description: 'trailer',
            defaultMessage: 'Trailer'
        },
        ari_standard_rate: {
            id: 'ari_standard_rate:',
            description: 'ari_standard_rate',
            defaultMessage: 'Holman Standard Rate (required)'
        },
    });

    const errorMessages = defineMessages({
      term_must_be_selected: {
        id: "term_must_be_selected",
        description: "Please read and agree to the Terms and Conditions",
        defaultMessage: "Please read and agree to the Terms and Conditions"
      },
      terms_accepted_by_required: {
        id: "terms_accepted_by_required",
        description: "Initials Required",
        defaultMessage: "Initials Required"
      },
      business_dba_required: {
        id: "business_dba_required",
        description: "Please enter a value for Business DBA",
        defaultMessage: "Please enter a value for Business DBA"
      },
      tax_classification_required: {
        id: "tax_classification_required",
        description: "Please choose a federal tax classification",
        defaultMessage: "Please choose a federal tax classification"
      },
      liability_tax_invalid_value: {
        id: "liability_tax_invalid_value",
        description: "Please enter a valid value for Liability Tax Classification",
        defaultMessage: "Please enter a valid value for Liability Tax Classification"
      },
      ein_required: {
        id: "ein_required",
        description: "Please either provide SSN or Employer Id",
        defaultMessage: "Please either provide SSN or Employer Id"
      },
      legal_name_required: {
        id: "legal_name_required",
        description: "Please provide a value for Legal Name",
        defaultMessage: "Please provide a value for Legal Name"
      },
      contact_name_required: {
        id: "contact_name_required",
        description: "Please provide a value for Contact Name",
        defaultMessage: "Please provide a value for Contact Name"
      },
      businessNumber_required: {
        id: "businessNumber_required",
        description: "Please provide a value for Business Number",
        defaultMessage: "Please provide a value for Business Number"
      },
        laborRate_required: {
        id: "laborRate_required",
        description: "laborRate_required",
        defaultMessage: "Please enter a valid labor rate of $9.99 or more."
        }
    });

    return (
      <div className="enrollment-details">
        {this.props.error}
        <div className="enrollment-details__header">
          <FormattedMessage {...messages.supplier_enrollment} />
        </div>
        <div className="enrollment-details__terms">
          <div className="enrollment-details__sub-header">
            <FormattedMessage {...messages.terms_and_conditions} />
          </div>
          <div className="enrollment-details__conditions-list">
            <FormattedMessage {...messages.all_officials_sub_heading} />
          </div>
          <div className="enrollment-details__conditions-list">
            <VendorTermsAndConditions
              ref="termsReadAndAgreed"
              termsAndConditions={this.props.termsAndConditions}
              toggleAcceptedFlag={this.props.enrollmentDetailsInfo.get(
                "termsReadAndAgreed"
              )}
              onAcceptedTerms={this.toggleTermsAndConditions.bind(
                this,
                constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME
                  .TERMS_READ_AND_AGREED,
                !this.props.toggleAcceptedFlag
              )}
              isFetchingTermsAndConditions={
                this.props.isFetchingTermsAndConditions
              }
            />
            {this.renderValidationOverlay(
              "termsReadAndAgreed",
              errorMessages.term_must_be_selected,
              "bottom"
            )}
          </div>
        </div>
        <div className="enrollment-details__tax-information">
          <div className="enrollment-details__sub-header">
            <FormattedMessage {...messages.tax_information} />
          </div>
          <div className="row">
            <div className="col-sm-6 enrollment-details__input-group">
              <label className="enrollment-details__legal">
                <FormattedMessage {...messages.legal_name} />
              </label>
              <label className="enrollment-details__legal_note">
                <FormattedMessage {...messages.legal_name_note} />
              </label>
              <input
                type="text"
                ref="businessName"
                className="enrollment-details__block enrollment-details__input"
                onChange={this.props.onRequestInfoChange.bind(
                  this,
                  constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.BUSINESS_NAME
                )}
                onFocus={this.onFieldFocus.bind(this, "businessName")}
                onKeyPress={helperFunctions.isAlphaSpecial.bind(this)}
              />
              {this.renderValidationOverlay(
                "businessName",
                errorMessages.legal_name_required,
                "top"
              )}

            </div>
            <div className="col-sm-6 enrollment-details__input-group">
              <label className="">
                <FormattedMessage {...messages.contact_name} />
              </label>
              <input
                type="text"
                ref="contactName"
                className="enrollment-details__block enrollment-details__input"
                value={this.props.enrollmentDetailsInfo.get("contactName")}
                onChange={this.props.onRequestInfoChange.bind(
                  this,
                  constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.CONTACT_NAME
                )}
                onFocus={this.onFieldFocus.bind(this, "contactName")}
                onKeyPress={helperFunctions.isAlphaSpace.bind(this)}
              />
              {this.renderValidationOverlay(
                "contactName",
                errorMessages.contact_name_required,
                "top"
              )}
            </div>
          </div>
          <div className="enrollment-details__input-group">
            <label className="">
              <FormattedMessage {...messages.business_dba_required} />
            </label>
            <input
              type="text"
              ref="businessNameDba"
              className="enrollment-details__block enrollment-details__input"
              value={this.props.enrollmentDetailsInfo.get("businessNameDba")}
              onChange={this.props.onRequestInfoChange.bind(
                this,
                constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.BUSINESS_NAME_DBA
              )}
              onFocus={this.onFieldFocus.bind(this, "businessNameDba")}
              onKeyPress={helperFunctions.isAlphaSpecial.bind(this)}
            />
            {this.renderValidationOverlay(
              "businessNameDba",
              errorMessages.business_dba_required,
              "right"
            )}
          </div>

          {this.props.country == "CAN"
            ? false
            : <div className="enrollment-details__input-group">
                <div><FormattedMessage {...messages.tax_classification} /></div>
                <div className="navbar">
                  <ul
                    ref="selectedTaxClassification"
                    className="enrollment-details__tax-group"
                  >
                    {this.getTaxTypes()}
                    <li
                      className={classnames(
                        "enrollment-details__tax-list",
                        this.props.isLiabilitySelected
                          ? "enrollment-details__liability-show"
                          : "enrollment-details__liability-hide"
                      )}
                    >
                      <label className="enrollment-details__liability">
                        <FormattedMessage
                          {...messages.liability_tax_classification}
                        />
                      </label>
                      <input
                        type="text"
                        ref="liabilityTaxClassification"
                        className="enrollment-details__input-2-chars_Upper"
                        maxLength="1"
                        onChange={this.props.onRequestInfoChange.bind(
                          this,
                          constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME
                            .LIABILITY_TAX_CLASSIFICATION
                        )}
                        onFocus={this.onFieldFocus.bind(
                          this,
                          "liabilityTaxClassification"
                        )}
                      />
                      {this.renderValidationOverlay(
                        "liabilityTaxClassification",
                        errorMessages.liability_tax_invalid_value,
                        "bottom"
                      )}

                    </li>
                  </ul>
                  {this.renderValidationOverlay(
                    "selectedTaxClassification",
                    errorMessages.tax_classification_required,
                    "left"
                  )}

                </div>
              </div>}

          {this.props.country == "CAN"
            ? <div
                id="taxGST"
                className="row enrollment-details__ssn-employer-id"
              >
                <div className="col-sm-5 enrollment-details__input-group">
                  <label>
                    <FormattedMessage {...messages.businessNumber} />
                  </label>
                  <div className="enrollment-details__block">
                    <input
                      type="password"
                      id="txtGST"
                      maxLength="9"
                      className="enrollment-details__input enrollment-details__input-9-chars"
                      onChange={this.onChangeBusinessNumber.bind(
                        this,
                        constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME
                          .BUSINESS_NUMBER
                      )}
                      onKeyPress={helperFunctions.isNumeric.bind(this)}
                    />
                  </div>
                </div>

              </div>
            : <div id="ein" className="row enrollment-details__ssn-employer-id">
                <div className="col-sm-5 enrollment-details__input-group">
                  <label><FormattedMessage {...messages.ssn} /></label>
                  <div className="enrollment-details__block ">
                    <input
                      type="password"
                      id="ssn1"
                      maxLength="3"
                      className="enrollment-details__input enrollment-details__input-3-chars"
                      onChange={this.onChangeSSN.bind(
                        this,
                        1,
                        "ssn",
                        constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.SSN_AREA
                      )}
                      onKeyPress={helperFunctions.isNumeric.bind(this)}
                    />
                    -
                    <input
                      type="password"
                      id="ssn2"
                      maxLength="2"
                      className=" enrollment-details__input enrollment-details__input-2-chars"
                      onChange={this.onChangeSSN.bind(
                        this,
                        2,
                        "ssn",
                        constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.SSN_GROUP
                      )}
                      onKeyPress={helperFunctions.isNumeric.bind(this)}
                    />
                    -
                    <input
                      type="password"
                      id="ssn3"
                      maxLength="4"
                      className="enrollment-details__input enrollment-details__input-4-chars"
                      onChange={this.onChangeSSN.bind(
                        this,
                        3,
                        "ssn",
                        constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.SSN_SERIAL
                      )}
                      onKeyPress={helperFunctions.isNumeric.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-sm-2 enrollment-details__or">OR</div>
                <div className="col-sm-6 enrollment-details__block enrollment-details__input-group">
                  <label><FormattedMessage {...messages.employer_id} /></label>
                  <div className="enrollment-details__block ">
                    <input
                      type="password"
                      id="employerId1"
                      maxLength="2"
                      className=" enrollment-details__input enrollment-details__input-2-chars"
                      onChange={this.onChangeEmployerId.bind(
                        this,
                        1,
                        "employerId",
                        constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME
                          .EMPLOYER_ID_FIRST_PART
                      )}
                      onKeyPress={helperFunctions.isNumeric.bind(this)}
                    />
                    -
                    <input
                      type="password"
                      id="employerId2"
                      maxLength="7"
                      className="enrollment-details__input enrollment-details__input-7-chars"
                      onChange={this.onChangeEmployerId.bind(
                        this,
                        2,
                        "employerId",
                        constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME
                          .EMPLOYER_ID_SECOND_PART
                      )}
                      onKeyPress={helperFunctions.isNumeric.bind(this)}
                    />
                  </div>
                </div>
              </div>}
          <Overlay
            show={
              this.isFieldValid("ein") ||
                (this.props.country == "CAN" &&
                  this.isFieldValid("businessNumber"))
            }
            target={() =>
              (this.props.country == "CAN"
                ? document.getElementById("txtGST")
                : document.getElementById("employerId2"))}
            placement="right"
            container={this}
          >
            <Popover
              id={"popOverein"}
              className="enrollment-details__error_message"
            >
               <RawIntlProvider value={this.props.intl}>
              {this.props.country == "CAN"
                ? <FormattedMessage
                    {...errorMessages.businessNumber_required}
                  />
                : <FormattedMessage {...errorMessages.ein_required} />}
                </RawIntlProvider>
            </Popover>
          </Overlay>
        </div>
        <div className="enrollment-details__business-class">
          <div className="enrollment-details__sub-header">
            <FormattedMessage {...messages.service_classification} />
          </div>
          <div className="row">
            <div
              className="enrollment-details__business_labels"
              onClick={this.props.onServiceClassificationChange.bind(
                this,
                false
              )}
            >
              <img
                src={
                  this.props.isServiceClassification
                    ? "/assets/VMS_unchecked.png"
                    : "/assets/VMS_checked.png"
                }
              />
              <label>
                <FormattedMessage
                  {...messages.maintenance_general_classification}
                />
              </label>
            </div>
            <div
              className="enrollment-details__business_labels"
              onClick={this.props.onServiceClassificationChange.bind(
                this,
                true
              )}
            >
              <img
                src={
                  !this.props.isServiceClassification
                    ? "/assets/VMS_unchecked.png"
                    : "/assets/VMS_checked.png"
                }
              />
              <label>
                <FormattedMessage {...messages.other_classification} />
              </label>
            </div>
          </div>
        </div>
          <div className="enrollment-details__labor_rate_info">
              <div className="enrollment-details__labor_rate-sub-header">
                  <FormattedMessage {...messages.labor_rate} />
              </div>
              <div className="">
                  <div className="enrollment-details__labor_rate-business_labels">
                      <FormattedMessage {...messages.ari_standard_rate} />
                  </div>
                  {/*
                  <input
                      type="number"
                      ref="ariStandardRate"
                      className="enrollment-details__block enrollment-details__req-input"
                      onChange={this.laborRateCheckHandler.bind(this, constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.VENDOR_LABOR_RATE)}
                  />*/
                  }

                  <DynamicNumber
                        className="enrollment-details__block enrollment-details__req-input"
                        ref="ariStandardRate"                        
                        onChange={this.laborRateCheckHandler.bind(this, constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.VENDOR_LABOR_RATE)}                        
                        separator={'.'}
                        integer={3}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        value={this.props.enrollmentDetailsInfo.get("ariStandardRate")==undefined?'':this.props.enrollmentDetailsInfo.get("ariStandardRate")}
                    />
                  {this.renderValidationOverlay("ariStandardRate", errorMessages.laborRate_required, "right")}
              </div>
              <div className="enrollment-details__labor_rate-sub-header-optional">
                  <FormattedMessage {...messages.optional_rates} />
              </div>
              <div className="row ">
                  <div className="enrollment-details__labor_rate-business_rates">
                      <div className="enrollment-details__labor_rate-business_labels">
                          <FormattedMessage {...messages.body_shop_rate} />
                      </div>
                      {/*<input
                          type="number"
                          ref="bodyShopRate"
                          className="enrollment-details__block enrollment-details__input-labor"
                          onChange={this.props.onRequestInfoChange.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.BODY_SHOP_RATE)}
                      />*/
                      }
                      <DynamicNumber
                        className="enrollment-details__block enrollment-details__input-labor"
                        ref="bodyShopRate"                              
                        onChange={this.laborRateCheckHandler.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.BODY_SHOP_RATE)}
                        separator={'.'}
                        integer={3}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        value={this.props.enrollmentDetailsInfo.get("bodyShopRate")==undefined || this.props.enrollmentDetailsInfo.get("bodyShopRate")==null?"":this.props.enrollmentDetailsInfo.get("bodyShopRate")}
                        
                        
                    />
                    {this.renderValidationOverlay("bodyShopRate", errorMessages.laborRate_required, "bottom")}
                  </div>
                  <div className="enrollment-details__labor_rate-business_rates">
                      <div className="enrollment-details__labor_rate-business_labels">
                          <FormattedMessage {...messages.mobile_onsite_rate} />
                      </div>
                      {/*
                      <input
                          type="number"
                          ref="mobileOnsiteRate"
                          className="enrollment-details__block enrollment-details__input-labor"
                          onChange={this.props.onRequestInfoChange.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.MOBILE_RATE)}
                      />*/}

                      <DynamicNumber
                        className="enrollment-details__block enrollment-details__input-labor"
                        ref="mobileOnsiteRate"                        
                        onChange={this.laborRateCheckHandler.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.MOBILE_RATE)}
                        separator={'.'}
                        integer={3}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        value={this.props.enrollmentDetailsInfo.get("mobileRate")==undefined?'':this.props.enrollmentDetailsInfo.get("mobileRate")}
                    />
                    {this.renderValidationOverlay("mobileOnsiteRate", errorMessages.laborRate_required, "bottom")}
                  </div>
                  <div className="enrollment-details__labor_rate-business_rates">
                      <div className="enrollment-details__labor_rate-business_labels">
                          <FormattedMessage {...messages.equipment_add_rate} />
                      </div>
                      {/*<input
                          type="number"
                          ref="equipmentAddRate"
                          className="enrollment-details__block enrollment-details__input-labor"
                          onChange={this.props.onRequestInfoChange.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.EQUIPMENT_RATE)}
                      />*/}
                       <DynamicNumber
                        className="enrollment-details__block enrollment-details__input-labor"
                        ref="equipmentAddRate"                        
                        onChange={this.laborRateCheckHandler.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.EQUIPMENT_RATE)}
                        separator={'.'}
                        integer={3}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        value={this.props.enrollmentDetailsInfo.get("equipmentRate")==undefined?'':this.props.enrollmentDetailsInfo.get("equipmentRate")}
                    />
                    {this.renderValidationOverlay("equipmentAddRate", errorMessages.laborRate_required, "bottom")}
                  </div>
              </div>
              <div className="row ">
                  <div className="enrollment-details__labor_rate-business_rates">
                      <div className="enrollment-details__labor_rate-business_labels">
                          <FormattedMessage {...messages.light_duty} />
                      </div>
                      {/*<input
                          type="number"
                          ref="lightDutyRate"
                          className="enrollment-details__block enrollment-details__input-labor"
                          onChange={this.props.onRequestInfoChange.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.LIGHT_DUTY_RATE)}
                      />*/}
                      <DynamicNumber
                        className="enrollment-details__block enrollment-details__input-labor"
                        ref="lightDutyRate"                        
                        onChange={this.laborRateCheckHandler.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.LIGHT_DUTY_RATE)}                        
                        separator={'.'}
                        integer={3}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        value={this.props.enrollmentDetailsInfo.get("lightDutyRate")==undefined?'':this.props.enrollmentDetailsInfo.get("lightDutyRate")}
                    />
                    {this.renderValidationOverlay("lightDutyRate", errorMessages.laborRate_required, "bottom")}
                  </div>
                  <div className="enrollment-details__labor_rate-business_rates">
                      <div className="enrollment-details__labor_rate-business_labels">
                          <FormattedMessage {...messages.medium_duty} />
                      </div>
                      {/*
                      <input
                          type="number"
                          ref="mediumDutyRate"
                          className="enrollment-details__block enrollment-details__input-labor"
                          onChange={this.props.onRequestInfoChange.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.MEDIUM_DUTY_RATE)}
                      />*/}

                      <DynamicNumber
                        className="enrollment-details__block enrollment-details__input-labor"
                        ref="mediumDutyRate"                        
                        onChange={this.laborRateCheckHandler.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.MEDIUM_DUTY_RATE)}
                        separator={'.'}
                        integer={3}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        value={this.props.enrollmentDetailsInfo.get("mediumDutyRate")==undefined?'':this.props.enrollmentDetailsInfo.get("mediumDutyRate")}
                    />
                    {this.renderValidationOverlay("mediumDutyRate", errorMessages.laborRate_required, "bottom")}
                  </div>
                  <div className="enrollment-details__labor_rate-business_rates">
                      <div className="enrollment-details__labor_rate-business_labels">
                          <FormattedMessage {...messages.heavy_duty} />
                      </div>
                      {/*
                      <input
                          type="number"
                          ref="heavyDutyRate"
                          className="enrollment-details__block enrollment-details__input-labor"
                          onChange={this.props.onRequestInfoChange.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.HEAVY_DUTY_RATE)}
                      />*/}
                      <DynamicNumber
                        className="enrollment-details__block enrollment-details__input-labor"
                        ref="heavyDutyRate"                        
                        onChange={this.laborRateCheckHandler.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.HEAVY_DUTY_RATE)}
                        separator={'.'}
                        integer={3}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        value={this.props.enrollmentDetailsInfo.get("heavyDutyRate")==undefined?'':this.props.enrollmentDetailsInfo.get("heavyDutyRate")}
                    />
                    {this.renderValidationOverlay("heavyDutyRate", errorMessages.laborRate_required, "bottom")}
                  </div>
                  <div className="enrollment-details__labor_rate-business_rates">
                      <div className="enrollment-details__labor_rate-business_labels">
                          <FormattedMessage {...messages.trailer} />
                      </div>
                     {/* <input
                          type="number"
                          ref="trailerRate"
                          className="enrollment-details__block enrollment-details__input-labor"
                          onChange={this.props.onRequestInfoChange.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.TRAILER_RATE)}
                      />*/}
                      <DynamicNumber
                        className="enrollment-details__block enrollment-details__input-labor"
                        ref="trailerRate"                        
                        onChange={this.laborRateCheckHandler.bind(this,constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.TRAILER_RATE)}
                        separator={'.'}
                        integer={3}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        value={this.props.enrollmentDetailsInfo.get("trailerRate")==undefined?'':this.props.enrollmentDetailsInfo.get("trailerRate")}
                    />
                    {this.renderValidationOverlay("trailerRate", errorMessages.laborRate_required, "bottom")}
                  </div>
              </div>
          </div>
        <div className="enrollment-details__client-referral">
          <label><FormattedMessage {...messages.client_referral} /></label>
          <input
            type="text"
            className="row enrollment-details__input"
            onChange={this.props.onRequestInfoChange.bind(
              this,
              constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME
                .CLIENT_REFERENCE_NAME
            )}
          />
        </div>
        <div className="enrollment-request__footer">
          <div className="row">
            <div className="col-sm-8">
              <div className="enrollment-request__cancel" role="button">
                <Link to="/login">
                  <img src="/assets/VMS_28button_kill_clear.png" />
                  {" "}<FormattedMessage {...messages.cancel} />
                </Link>
              </div>

            </div>
            <div className="col-sm-4 enrollment-request__submit">
              <Submit
                caption={<FormattedMessage {...messages.submit} />}
                imagePath="/assets/VMS_33button_go_bblue.png"
                onClickHandler={this.onSubmit.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(Details);
