import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import * as Components from '../components';
import {PO_AUTHORIZATION_STATUS,PO_PAYMENT_STATUS} from 'constants/App';
import * as ShopViewActions from '../actions/ShopView';
import Spinner from 'components/Spinner';
import ShopViewSelector from '../selectors/ShopView';
import './ShopView.css';
import { Link } from 'react-router-dom';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import {falseIfUndefined } from 'utils/HelperFunctions';

const ShopView = props => {

    useEffect(
        () => {
            props.dispatch(ShopViewActions.shopViewLoaded());

            props.dispatch({type: constants.FETCH_BULLETIN_MESSAGE});

            return () => {
                props.dispatch(ShopViewActions.shopViewUnLoaded());
            }
        },
        [props.vendorId, props.token] // If Holman User logs in, it is required to reload ShopView while switching one vendor to another
    );    
    
    if (props.isfetchingData) {
        return <Spinner />;
    }
    const messages = defineMessages({
        shopview_no_pos_caption: {
            id: 'shopview_no_pos_caption',
            description: 'Caption to display if no POs are available for display on Shop View',
            defaultMessage: 'No Open Purchase Orders'
        },
        learn_more_caption:{
            id:'learn_more_caption',
            description:'Learn More Link',
            defaultMessage:' Learn more'

        }
    });
    if (props.activePOs.count() == 0) {

        return <div>
            {props.primaryBulletin !== undefined ?
                props.primaryBulletin.map((param) =>
                    <div className="shop-view__bulletin__message_title" key={param.get('id')}>
                        <div
                            className="shop-view__bulletin__message_text"> {param.get('title')}{' '}
                            <Link
                                to={`/learnMore/${param.get('id')}`}><FormattedMessage {...messages.learn_more_caption}/></Link>
                        </div>
                        < hr className="shop-view__hr"/>
                    </div>
                )
                :  false
            }
            {props.secondaryBulletin !== undefined ?
                props.secondaryBulletin.map((param) =>
                    <div className="shop-view__bulletin__message_title" key={param.get('id')}>
                        <div
                            className="shop-view__bulletin__message_text">
                            <FormattedMessage id='alert_message_title' defaultMessage={param.get('title')}/>
                            {param.get("message") === null ? false :
                                <Link
                                    to={`/learnMore/${param.get('id')}`}><FormattedMessage {...messages.learn_more_caption}/></Link>
                            }
                        </div>
                        <hr className="shop-view__hr"/>
                    </div>
                )
                :  false
            }
            <div className="shop-view__caption">
                <FormattedMessage {...messages.shopview_no_pos_caption}/>
            </div>
        </div>

    }

    const vendorActionNeededPOs = props.activePOs.filter(o => o.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL || o.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED);
    const approvedPOs = props.activePOs.filter(o => o.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.APPROVED && o.get('paymentStatus') == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED);
    const waitingForDriverValidation = props.activePOs.filter(o => o.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.WAITING_FOR_DRIVER_VALIDATION && o.get('paymentStatus') == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED);
    const waitingForARIApprovalPOs = props.activePOs.filter(o => o.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL && o.get('paymentStatus') == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED);
    const waitingForClientApprovalPOs = props.activePOs.filter(o => o.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL && o.get('paymentStatus') == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED);        
    const paymentInProgressPOs = props.activePOs.filter(o => o.get('paymentStatus') == PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS);                                    
    
    const newWorkAssignment = props.activePOs.filter(o => o.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT && o.get('paymentStatus') == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED);
    const workCompleted = props.activePOs.filter(o => o.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.WORK_COMPLETED && o.get('paymentStatus') != PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS);

    return <div>
        {props.primaryBulletin !== undefined ?
            props.primaryBulletin.map((param) =>
                <div className="shop-view__bulletin__message_title" key={param.get('id')}>
                    <div
                        className="shop-view__bulletin__message_text"> {param.get('title')}
                        <Link
                            to={`/learnMore/${param.get('id')}`}><FormattedMessage {...messages.learn_more_caption}/></Link>
                    </div>
                    < hr className="shop-view__hr"/>
                </div>
            )
            :  false
        }
        {props.secondaryBulletin !== undefined ?
            props.secondaryBulletin.map((param) =>
                <div className="shop-view__bulletin__message_title" key={param.get('id')}>
                    <div
                        className="shop-view__bulletin__message_text">
                        <FormattedMessage id='alert_message_title' defaultMessage={param.get('title')}/>
                        {param.get("message") === null ? false :
                            <Link
                            to={`/learnMore/${param.get('id')}`}><FormattedMessage {...messages.learn_more_caption}/></Link>
                        }
                    </div>
                    <hr className="shop-view__hr"/>
                </div>
            )
            :  false
        }
        <FormWrapper key='shopViewErrorMessage'
                        id="shopViewErrorMessage" {...props}
                        formErrors={props.shopViewErrorMessage} />

        {vendorActionNeededPOs.count() > 0 ?
            <Components.ActivePOList
                activePOs={vendorActionNeededPOs}
                type={PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL}
                showAll={props.showAllVendorActionNeededPOs}
                footerClickHandler={()=>props.dispatch(ShopViewActions.toggleShowAllVendorActionNeededPOs())}
                selectedCurrency={props.selectedCurrency}
                selectedCountry={props.country}
            />
            : null }

        <Components.ActivePOList
            activePOs={newWorkAssignment}
            type={PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT}
            showAll={props.showAllNewWorkAssignment}
            footerClickHandler={()=>props.dispatch(ShopViewActions.toggleShowAllNewWorkAssignment())}
            selectedCurrency={props.selectedCurrency}
            selectedCountry={props.country}
        />
        <Components.ActivePOList
            activePOs={approvedPOs}
            type={PO_AUTHORIZATION_STATUS.APPROVED}
            showAll={props.showAllApprovedPOs}
            footerClickHandler={()=>props.dispatch(ShopViewActions.toggleShowAllApprovedPOs())}
            vendorId={props.vendorId}
            selectedCurrency={props.selectedCurrency}
            selectedCountry={props.country}
        />
        <Components.ActivePOList
            activePOs={waitingForDriverValidation}
            type={PO_AUTHORIZATION_STATUS.WAITING_FOR_DRIVER_VALIDATION}
            showAll={props.showAllWaitingForDriverValidation}
            footerClickHandler={()=>props.dispatch(ShopViewActions.toggleShowAllWaitingForDriverValidationPOs())}
            selectedCurrency={props.selectedCurrency}
            selectedCountry={props.country}
        />

        <Components.ActivePOList
            activePOs={waitingForARIApprovalPOs}
            type={PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL}
            showAll={props.showAllWaitingForARIApprovalPOs}
            footerClickHandler={()=>props.dispatch(ShopViewActions.toggleShowAllWaitingForARIApprovalPOs())}
            selectedCurrency={props.selectedCurrency}
            selectedCountry={props.country}
        />
        <Components.ActivePOList
            activePOs={waitingForClientApprovalPOs}
            type={PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL}
            showAll={props.showAllWaitingForClientAuthorizationPOs}
            footerClickHandler={()=>props.dispatch(ShopViewActions.toggleShowAllWaitingForClientApprovalPOs())}
            selectedCurrency={props.selectedCurrency}
            selectedCountry={props.country}
        />
        {props.isWorkCompletionFeatureEnabled?
            <Components.ActivePOList
                activePOs={workCompleted}
                type={PO_AUTHORIZATION_STATUS.WORK_COMPLETED}
                showAll={props.showAllWorkCompleted}
                footerClickHandler={()=>props.dispatch(ShopViewActions.toggleShowAllWorkCompleted())}
                selectedCurrency={props.selectedCurrency}
                selectedCountry={props.country}
            />
            :false
        }
        <Components.PIPPOList
            activePOs={paymentInProgressPOs}
            selectedCurrency={props.selectedCurrency}
            allowWorkCompletion = {props.isWorkCompletionFeatureEnabled}
        />
    </div>    
}

function mapStateToProps(state, props) {
    return {
        ...ShopViewSelector(state, props),
        shopViewErrorMessage: state.appState.getIn(['uiData','shared','errorMessage']),
        vendorId: state.appState.getIn(['serverData','shared','vendorId']),
        token: state.appState.getIn(['serverData','shared','token']),
        showAllWaitingForDriverValidation: falseIfUndefined(state.appState.getIn(['uiData', 'shopView','showAllWaitingForDriverValidationPOs'])),
        selectedCurrency:state.appState.getIn(['uiData', 'shared','selectedCurrency']),
        paymentType: state.appState.getIn(['serverData', 'shared', 'paymentType']),
        isWorkCompletionFeatureEnabled: state.appState.getIn(['uiData', 'shared', 'features','workCompletion']),
        country: state.appState.getIn(['uiData', 'shared', 'selectedCountry']),
    };
}


export default connect(mapStateToProps)(ShopView);
