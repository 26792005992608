// #region Imports
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import * as Api from "utils/Api";
import Spinner from "components/Spinner";
import { useAppState } from 'shared/AppState/app-state';
import moment from 'moment';

// #endregion

// #region Styled Components
const Title = styled.div`
  font-size: 42px;
  font-family: "Gotham SSm A", "Gotham SSm B";font-style: normal;font-weight: 300;
  text-align: center;
  padding: 20px 40px .5em 40px;
  border-bottom: 2px solid #ffffff;
  color: #ed1d30;
`;

const AttentionNeededNote = styled.div`
  padding: 1em 2em 1em 2em;
  margin: 2em 0 1em 0;
  border: 2px solid #5b656e;
  font-size: 16px;
  font-family: "Gotham SSm A", "Gotham SSm B";font-style: normal;font-weight: 300;
  text-align: left;
  `;

const NoteBorder = styled.div`
    border: 2px solid #dce2f3;
    background: #ffffff;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 3em;
    `;

const LinkStyle = styled.div`
    float: right;
  `;

const DayCount = styled.div`
    color: red;
  `;

const Styles = styled.div`

        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 0px solid #ddd;
       
      table {
        padding-top: 2em;
        padding-left: 0.5em;
        border-collapse:separate;
        border-spacing:0 0.35em;
      },
        th {
          font-family: "Gotham SSm A", "Gotham SSm B";font-style: normal;font-weight: 700;
          border-bottom: 1px solid grey;
          color:#5b656e;
          font-size: 0.9em;
          white-space: nowrap;
          text-align: left;
          padding-left: .8em;
         
        },
        td {
          font-family: "Gotham SSm A", "Gotham SSm B";font-style: normal;font-weight: 300;
          color:#5b656e;
          font-size: 15px;
          text-align: left;
          padding: 0.4em 0.8em;   
          border-bottom: 1px solid grey;
          padding-top: 1em;
        },
        tr {
          border-bottom: none;

        }
      
  `;

const SpinnerContainer = styled.div`	
  margin-top: 24em;
`;

const PoListFooter = styled.div`
  text-align: right;
  padding-top: 1em;
  font-family: "Gotham SSm A", "Gotham SSm B";font-style: normal;font-weight: 500;
  color: #5587b7;
`;

// #endregion

// #region International messages
const messages = defineMessages({
  no_aged_pos_dialog: {
    id: "no_aged_pos_dialog",
    description: 'Caption to display if no POs are available for display on Aged PO list',
    defaultMessage: 'None at this Time'
  },
  aged_po_title: {
    id: "aged_po_title",
    description: 'Your attention needed',
    defaultMessage: 'Your attention needed'
  },
  aged_po_messaging: {
    id: "aged_po_messaging",
    description: 'Messaging for aged PO area',
    defaultMessage: 'Your shop has open purchase orders that are more than 30 days old. Please complete and close these purchase orders at your earliest convenience. Holman will not process or pay POs that are greater than 120 days old.'
  },
  shopview_link: {
    id: "shopview_link",
    description: 'Go to Shop View',
    defaultMessage: 'Go to Shop View'
  },
  created_date_column: {
    id: "created_date_column",
    description: 'Created date',
    defaultMessage: 'Created date'
  },
  vin_column: {
    id: "vin_column",
    description: 'VIN',
    defaultMessage: 'VIN'
  },
  po_column: {
    id: "po_column",
    description: 'PO no.',
    defaultMessage: 'PO no.'
  },
  ref_column: {
    id: "ref_column",
    description: 'Your Ref #',
    defaultMessage: 'Your Ref #'
  },
  amount_column: {
    id: "amount_column",
    description: 'Amount',
    defaultMessage: 'Amount'
  },
  edit_link: {
    id: "edit_link",
    description: 'EDIT',
    defaultMessage: 'EDIT'
  },
  close_link: {
    id: "close_link",
    description: 'CLOSE',
    defaultMessage: 'CLOSE'
  },
  aged_po_date: {
    id: "aged_po_date",
    description: 'days ago',
    defaultMessage: 'days ago'
  },
});
// #endregion

// #region Component
const AgedPOs = ({
  dispatch: reduxDispatch,
  token,
  vendorId,
  intl: { formatMessage }
}) => {

  // #region State
  const loadingStates = { loading: "loading", loaded: "loaded", error: "error" };
  const [loadingState, setLoadingState] = useState(loadingStates.loading);
  const [appState, dispatch] = useAppState();
  const [agedPOList, setAgedPOList] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [fullPOList, setFullPOList] = useState([]);
  // #endregion

  // #region UseEffect
  useEffect(() => {
    const fetchAgedPOs = async () => {
      try {
        setLoadingState(loadingStates.loading);
        let shortedOpenPOList;
        // Make API call 
        const openPOList = await Api.fetchOpenedPOsForVendor(vendorId, token);

        const sortedOpenPOList = openPOList.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));

        if (showAll == false && sortedOpenPOList.length > 0) {
          shortedOpenPOList = sortedOpenPOList.slice(0, 10);
          setAgedPOList(shortedOpenPOList);
        }

        setFullPOList(sortedOpenPOList);

        setLoadingState(loadingStates.loaded);
      }
      catch (e) {
        setLoadingState(loadingStates.error);
      }
    };
    fetchAgedPOs();
  }, [dispatch, token, vendorId]);
  // #endregion

  // #region Event handlers 
  const getFooter = (pos, showAll) => {

    const messages = defineMessages({
      show_all_waiting_pos: {
        id: 'show_all_waiting_pos',
        description: 'Link at bottom of PO List to show all POs',
        defaultMessage: 'Show all {poCount} purchase orders'
      },
      hide_older_pos: {
        id: 'hide_older_pos',
        description: 'Link at bottom of PO List to hide older approved POs',
        defaultMessage: 'Hide older purchase orders'
      }
    });

    // Don't show footer if we have 10 or less records
    if (pos.length <= 10)
      return undefined;
    // If we are not showing all, show button to show all
    else if (showAll) {
      window.scrollTo(0, 0);
      return <PoListFooter role="button">
        <FormattedMessage values={{ poCount: pos.length }}
          {...messages.show_all_waiting_pos} />
        {' '}<img src="/assets/VMS_button_show_all_pos.png" />
      </PoListFooter>;
    }
    else {
      return <PoListFooter role="button">
        <FormattedMessage {...messages.hide_older_pos} />
        {' '}<img src="/assets/VMS_button_hide_older_pos.png" />
      </PoListFooter>;
    }
  }


  const footerClickHandler = (fullPOList, showAll) => {
    let shortedOpenPOList;

    setShowAll(showAll => !showAll);

    if (!showAll) {
      shortedOpenPOList = fullPOList.slice(0, 10);
      setAgedPOList(shortedOpenPOList);

    } else {
      setAgedPOList(fullPOList);

    }
  }
  // #endregion

  // #region Render
  return (
    <div>
      {loadingState === loadingStates.loading
        ?
        <tr>
          <td colSpan="6" >
            <SpinnerContainer><Spinner /></SpinnerContainer>
          </td>
        </tr>
        :
        <div>
          <Title id="AttentionNeededNoteHeader"><FormattedMessage {...messages.aged_po_title} /></Title>

          <NoteBorder>
            <AttentionNeededNote><FormattedMessage {...messages.aged_po_messaging} /></AttentionNeededNote>
            <LinkStyle>
              <Link id="ShopViewAttentionNeedNav" to={"/"}>
                <FormattedMessage {...messages.shopview_link} />
                {' '}<img src="/assets/VMS_33button_go_bblue.png" />
              </Link>

            </LinkStyle>
          </NoteBorder>
          <Styles>
            <table width="100%">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage {...messages.created_date_column} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.vin_column} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.po_column} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.ref_column} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.amount_column} />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {(agedPOList.length === 0)
                  ?
                  <tr>
                    <td >
                    </td>
                    <td >
                    </td>
                    <td colSpan="6" >
                      <FormattedMessage {...messages.no_aged_pos_dialog} />
                    </td>
                  </tr>
                  :
                  agedPOList.map(po =>
                    <tr key={po.id} >
                      <td >
                        {po.createdDate != null ? moment(po.createdDate).format("MM/DD/YYYY") : po.createdDate}
                        <br />
                        <DayCount>
                          {(Math.round(new Date(Date.now() - new Date(po.createdDate))) / (1000 * 60 * 60 * 24)).toFixed(0)} <FormattedMessage {...messages.aged_po_date} />
                        </DayCount>
                      </td>
                      <td >
                        {po.vehicleVIN8}
                      </td>
                      <td>
                        {po.poNumber}
                      </td>
                      <td>
                        {po.vendorReferenceNo}
                      </td>
                      <td >
                        ${po.totalAmount.toFixed(2)}
                      </td>
                      {appState.personaToken === undefined ?
                        <td>
                          <Link to={`/PO/edit/${po.poNumber}`}>
                            <FormattedMessage {...messages.edit_link} />
                          </Link>
                          <br />
                          <Link to={`/PO/close/${po.poNumber}`}>
                            <FormattedMessage {...messages.close_link} />
                          </Link>
                        </td>
                        :
                        <td>
                          {appState.poPermissions.includes('ED') ?
                            <div>
                              <Link to={`/PO/edit/${po.poNumber}`}>
                                <FormattedMessage {...messages.edit_link} />
                              </Link>
                              <br />
                            </div>
                            : false
                          }
                          {
                            appState.poPermissions.includes('CL') ?
                              <div>
                                <Link to={`/PO/close/${po.poNumber}`}>
                                  <FormattedMessage {...messages.close_link} />
                                </Link>
                              </div>
                              : false}
                        </td>
                      }
                    </tr>

                  )
                }
              </tbody>
            </table>
            <div onClick={() => { footerClickHandler(fullPOList, !showAll) }}>
              {getFooter(fullPOList, !showAll)}
            </div>

          </Styles>
        </div>
      }
    </div >

  );
  // #endregion
};
// #endregion

export default injectIntl(AgedPOs);