//libraries
import React, { useState, useRef } from 'react';
import { defineMessages, FormattedMessage, FormattedNumber,injectIntl,RawIntlProvider } from 'react-intl';
import { Map } from 'immutable';
import classNames from 'classnames';
import {Grid, Row, Col, OverlayTrigger , Tooltip} from 'react-bootstrap';
import { IntlProvider } from 'react-intl';
//our stuff
import {LINE_ITEM_TYPE, LINE_ITEM_STATUS} from 'constants/App';
import './AdjustedLineItems.css';
import './AuthorizationStatusReason.css'
import ConfirmMessageBox from 'AddEditView/ConfirmMessageBox';
import ReviewActionRequiredView from '../screens/ReviewActionRequiredItemView/containers/ReviewActionRequiredItemView.js';
import { useAppState } from 'shared/AppState/app-state';
import * as constants from 'constants/App';
import ForwardForFurtherReview from 'components/ForwardForFurtherReview';

// #region Component
function ActionRequiredLineItems({
    lineItems, 
    showReviewPendingErrors,
    selectedCurrency,
    selectedCountry,
    isPOAdjustedBySystem,
    allLinesRejected,
    isClientAuthorizationRequired,
    needMoreNotesFlag,
    associatedPendingLines,
    onAcceptAdjustmentsClickHandler,
    onAcceptRejectionClickHandler,
    onAcceptNewLineClickHandler,
    onSubmitVendorNotesClickHandler,
    onSubmitClickHandler,
    onCancelPOClickEvent,
    onRejectAdjustments,
    intl,
    authorizationStatus,
    routeParams,
    dispatch,
    isHsbClientVehicle,
    entityForAssistance,
    fleetAdminContactInfo,
    fleetAdminContactInfoError
}) {
    // #region State
    const [showModalActionRequired, setShowModalActionRequired] = useState(false);
    const [showModalLineItemId, setShowModalLineItemId] = useState(null);
    const [ appState ] = useAppState();
    // #endregion

    // #region Ref
    const cancelPOShowConfirmWindowRef = useRef(null);
    const forwardPOShowConfirmWindowRef = useRef(null);
    // #endregion

    // #region Event handlers        
    const onCancelPOHandler = (e) => {
        e.preventDefault();
        cancelPOShowConfirmWindowRef.current.showModal();
    }
    
    const convertToTitleCase = (strMessage) => {
        if (strMessage == undefined || strMessage.length <= 0) return;

        
            if(strMessage==undefined) return str;
             
            strMessage = strMessage.toLowerCase().replace(/[^-.'\s]+/g, function (word) {
                return word.replace(/^./, function (first) {
                    return first.toUpperCase();
                });
            });

            //example with LOF
            let ignore = [{ word: 'LOF', regex: 'LOF\\s' }];

            for (let i in ignore) {
                let re = new RegExp(ignore[i].regex, 'gi');
                strMessage = strMessage.replace(re, ignore[i].word);
            }
            return strMessage;
    }

    const onReviewClick = (lineItemId,e) => {
        setShowModalActionRequired(true);
        setShowModalLineItemId(lineItemId);        
    }

    const closeModalHandler = (e) => {
        setShowModalActionRequired(false);        
    }

    const onForwardPOHandler = (e) => {
        e.preventDefault();
        forwardPOShowConfirmWindowRef.current.showModal();
    }
    // #endregion

    // #region Effects
    // #endregion

    // #region International messages          
    const messages = defineMessages({
        adjusted_line_item_view_quantity: {
            id: 'adjusted_line_item_view_quantity',
            description: 'Qty',
            defaultMessage: 'Qty'
        },
        adjusted_line_item_view_hour: {
            id: 'adjusted_line_item_view_hour',
            description: 'Hour',
            defaultMessage: 'Hour'
        },
        adjusted_line_item_view_cost: {
            id: 'adjusted_line_item_view_cost',
            description: 'Cost',
            defaultMessage: 'Cost'
        },
        adjusted_line_item_view_amount: {
            id: 'adjusted_line_item_view_amount',
            description: 'Amount',
            defaultMessage: 'Amount'
        },
        adjusted_line_item_view_submitted: {
            id: 'adjusted_line_item_view_submitted',
            description: 'Submitted',
            defaultMessage: 'Submitted'
        },
        adjusted_line_item_view_ari_adjustment: {
            id: 'adjusted_line_item_view_ari_adjustment',
            description: 'Holman Adjustment',
            defaultMessage: 'Holman Adjustment'
        },
        adjusted_line_item_view_ari_created: {
            id: 'adjusted_line_item_view_ari_created',
            description: 'Created by Holman',
            defaultMessage: 'Created by Holman'
        },
        adjusted_line_item_view_accept_adjustments: {
            id: 'adjusted_line_item_view_accept_adjustments',
            description: 'Accept adjustment and continue repair',
            defaultMessage: 'Accept adjustment and continue repair'
        },
        adjusted_line_item_view_accept_adjustments_client_auth: {
            id: 'adjusted_line_item_view_accept_adjustments_client_auth',
            description: 'Accept adjustment to start client authorization process',
            defaultMessage: 'Accept adjustment to start client authorization process'
        },
        adjusted_line_item_view_cancel_po: {
            id: 'cancel_po_confirmation_title',
            description: 'Cancel PO',
            defaultMessage: 'Cancel PO'
        },
        adjusted_line_item_view_cancel_po_confirmation_no: {
            id: 'cancel_po_confirmation_no',
            description: 'No',
            defaultMessage: 'No'
        },
        adjusted_line_item_view_cancel_po_confirmation_yes: {
            id: 'cancel_po_confirmation_yes',
            description: 'Yes',
            defaultMessage: 'Yes'
        },
        adjusted_line_item_view_cancel_po_confirmation_title: {
            id: 'cancel_po_confirmation_title',
            description: 'Cancel PO',
            defaultMessage: 'Cancel PO'
        },
        adjusted_line_item_view_cancel_po_confirmation_message: {
            id: 'cancel_po_confirmation_message',description: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?',
            defaultMessage: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?'
        },
        adjusted_line_item_view_accept_intial_adjustment_message:{
            id:'accept_intial_adjustment_message',
            description:'Accept adjustment and continue repair',
            defaultMessage:'Accept adjustment and continue repair'
        },
        adjusted_line_item_view_reject_intial_adjustment_message:{
            id:'reject_intial_adjustment_message',
            description:'Reject adjustment and forward for further review',
            defaultMessage:'Reject adjustment and forward for further review'
        },
        adjusted_line_item_view_submit:{
            id:'adjusted_line_item_view_submit',
            description:'Submit',
            defaultMessage:'Submit'
        },
        action_required_po_submit:{
            id:"action_required_po_submit",
            description:'Submit',
            defaultMessage:'Submit'
        },
        action_required_line_item_view_note:{
            id:'action_required_line_item_view_note',
            description:'Review the following lines',
            defaultMessage: 'Review the following lines'
        },
        action_required_submit_note_all_rejected:{
            id:'action_required_submit_note_all_rejected',
            description:'This PO will be cancelled once you review and accept the rejected lines, and click Submit',
            defaultMessage:'This PO will be cancelled once you review and accept the rejected lines, and click Submit'
        },
        action_required_submit_note_client_auth:{
            id:'action_required_submit_note_client_auth',
            description:'This PO will be sent to Client Authorization onve you review and accept the changes, and click Submit',
            defaultMessage:'This PO will be sent to Client Authorization onve you review and accept the changes, and click Submit'
        },
        action_required_submit_note_need_notes:{
            id:'action_required_submit_note_need_notes',
            description:'This PO will be sent to Holman Approval once you review and accept the changes, and click Submit',
            defaultMessage:'This PO will be sent to Holman Approval once you review and accept the changes, and click Submit'
        },
        action_required_submit_note_po_approved:{
            id:'action_required_submit_note_po_approved',
            description:'This PO will be approved once you review and accept the changes, and click submit',
            defaultMessage:'This PO will be approved once you review and accept the changes, and click submit'
        },
        action_required_line_item_review:{
            id:"action_required_line_item_review",
            description:"Review ",
            defaultMessage:"Review "
        },
        action_required_line_item_review_complete:{
            id:"action_required_line_item_review_complete",
            description:"This line is reviewed",
            defaultMessage:"This line is reviewed"
        },
        action_required_line_item_review_pending:{
            id:"action_required_line_item_review_pending",
            description:'This line is not reviewed',
            defaultMessage:'This line is not reviewed'
        },
        action_required_line_status_need_notes:{
            id:"action_required_line_status_need_notes",
            description:"Notes Required",
            defaultMessage:"Notes Required"
        }, 
        action_required_line_status_adjusted:{
            id:"action_required_line_status_adjusted",
            description:"Adjusted",
            defaultMessage:"Adjusted"
        },
        action_required_line_status_rejected:{
            id:"action_required_line_status_rejected",
            description:"Rejected",
            defaultMessage:"Rejected"
        },
        action_required_line_status_created:{
            id:"action_required_line_status_created",
            description:"Created by Holman",
            defaultMessage:"Created by Holman"
        },
        action_required_line_status_pending:{
            id:"action_required_line_status_pending",
            description:"Pending Approval",
            defaultMessage:"Pending Approval"
        },
        forward_for_further_review: {
            id: 'action_required_forward_for_further_review',
            description: 'Forward for further review',
            defaultMessage: 'Forward for further review'
        },
    });
    // #endregion

    // #region Render
    return <div className='adjusted_line_item'>
        

        <div className="adjusted_line_item__lines">
        
        <div className='adjusted_line_item__header4'>
        <FormattedMessage {...messages.action_required_line_item_view_note }/>
            </div>
            

        
            {lineItems.valueSeq().map((lineItem, index) => {                    

                const productDescription = ( appState.isProductCodeFeatureEnabled && lineItem.get('vendorProductCode') !== undefined && lineItem.get('vendorProductCode') !== null ? lineItem.get('vendorProductCode') + ' - ' : '' ) + lineItem.get('productDescription') + ', ' + (lineItem.get('lineItemType') == LINE_ITEM_TYPE.PM ? LINE_ITEM_TYPE.PM_TEXT : lineItem.get('lineItemType').toUpperCase());
                const submittedQty = lineItem.get('previousQuantity') != null ? lineItem.get('previousQuantity') : lineItem.get('quantity'); //if previous value is null, that means Holman tech did not adjust this field
                const submittedCost = lineItem.get('previousUnitPrice') != null ? lineItem.get('previousUnitPrice') : lineItem.get('unitPrice');
                const submittedTotal = submittedQty * submittedCost;
                const ariQty = lineItem.get('quantity');
                const ariCost = lineItem.get('unitPrice');
                const ariTotal = lineItem.get('totalAmount');
                const hasAdjustment = lineItem.get('previousQuantity') != null || lineItem.get('previousUnitPrice') != null;
                const lineNotes = lineItem.get('authorizationStatusReason');
                const associatedPendingLinesFiltered=associatedPendingLines.filter(a => a.get('productCode')==lineItem.get('productCode')).map((lineItem) =>{return lineItem.get('lineItemType')});                

                /* display line created by Holman test_completed*/                    
                    
                        return <div 
                                key={lineItem.get('lineItemId')}>
                
                            <Row className={
                                classNames('adjusted_line_item__row_group', 
                                {'adjusted_line_item__row_group--last' : index + 1 == lineItems.count() },
                                {'adjusted_line_item__row_group--first' : index==0})}>                                                                  
                                <Col xs={6} 
                                className="adjusted_line_item__item">
                                    {convertToTitleCase(productDescription)}
                                </Col>
                                <Col xs={3} className="adjusted_line_item__item">
                                    {/*lineItem.get('approvalStatus') == LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES?
                                    'Waiting for Vendor Notes':
                                    lineItem.get('approvalStatus') == LINE_ITEM_STATUS.APPROVED?
                                    lineItem.get('createdBy') == 'Holman'? 'Created by Holman' :
                                    'Adjusted' :
                                    lineItem.get('approvalStatus') == LINE_ITEM_STATUS.REJECTED?
                                    'Rejected':'Pending for Approval'*/}

                                    {lineItem.get('ActionRequiredStatus') == LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES?
                                    <FormattedMessage {...messages.action_required_line_status_need_notes}/>:
                                    lineItem.get('ActionRequiredStatus') == LINE_ITEM_STATUS.REJECTED?
                                    <FormattedMessage {...messages.action_required_line_status_rejected}/>:
                                    lineItem.get('ActionRequiredStatus') == 'CREATED_BY_ARI' ? <FormattedMessage {...messages.action_required_line_status_created}/> :
                                    lineItem.get('ActionRequiredStatus') == 'ADJUSTED'? <FormattedMessage {...messages.action_required_line_status_adjusted}/> :                                        
                                    <FormattedMessage {...messages.action_required_line_status_pending}/>}
                                </Col>
                                <Col xs={3} className="adjusted_line_item__item adjusted_line_item__accept_right">
                                    
                                                                    
                                    <a onClick={()=>{onReviewClick(lineItem.get('lineItemId'))}} ><b>
                                    <FormattedMessage {...messages.action_required_line_item_review}/>
                                    </b>
                                    <img className="adjusted_line_item__img" src="/assets/VMS_button_return_right.png"/>                                        
                                    </a>
                                    
                                    
                                {
                                    lineItem.get('reviewCompleted') ?
                                    
                                    <OverlayTrigger
                                        overlay={<Tooltip><RawIntlProvider value={intl}><FormattedMessage {...messages.action_required_line_item_review_complete}/></RawIntlProvider></Tooltip>}
                                        placement="right"
                                        delayShow={300}
                                        delayHide={150}
                                        >
                                        <img className="adjusted_line_item__img1" src="/assets/greenOK.png" 
                                        onClick={()=>{onReviewClick(lineItem.get('lineItemId'))}}/>
                                        </OverlayTrigger>
                                                                        
                                    :
                                    
                                    showReviewPendingErrors?
                                    <OverlayTrigger
                                        overlay={<Tooltip><RawIntlProvider value={intl}><FormattedMessage {...messages.action_required_line_item_review_pending}/></RawIntlProvider></Tooltip>}
                                        placement="right"
                                        delayShow={300}
                                        delayHide={150}
                                        >
                                        <img className="adjusted_line_item__img1" src="/assets/red_warning.png"
                                        onClick={()=>{onReviewClick(lineItem.get('lineItemId'))}}/>
                                        </OverlayTrigger>
                                    
                                    :<img className="adjusted_line_item__img1" />
                                    
                                
                                }
                                
                                                                    
                                </Col>                                    
                            </Row>
                            {showModalActionRequired && showModalLineItemId==lineItem.get('lineItemId')? 
                            <ReviewActionRequiredView lineItem={lineItem} 
                            associatedPendingLines={associatedPendingLinesFiltered}
                            showModalActionRequired={showModalActionRequired} closeModalHandler={closeModalHandler} selectedCurrency={selectedCurrency} 
                            onAcceptAdjustmentsClickHandler={onAcceptAdjustmentsClickHandler}
                                        onAcceptRejectionClickHandler={onAcceptRejectionClickHandler}
                                        onAcceptNewLineClickHandler={onAcceptNewLineClickHandler}
                                        onSubmitVendorNotesClickHandler={onSubmitVendorNotesClickHandler}
                                        selectedCountry={selectedCountry}
                            isPOAdjustedBySystem={isPOAdjustedBySystem}
                            isHsbClientVehicle={isHsbClientVehicle}
                            entityForAssistance={entityForAssistance}
                            fleetAdminContactInfo={fleetAdminContactInfo} 
                            fleetAdminContactInfoError={fleetAdminContactInfoError} 
                            /> : false}
                            
                    </div>
                    
            })}

        </div>
        <div className='adjusted_line_item__header3'>
        {allLinesRejected? <FormattedMessage {...messages.action_required_submit_note_all_rejected}/> :
            isClientAuthorizationRequired?<FormattedMessage {...messages.action_required_submit_note_client_auth}/> :
            needMoreNotesFlag? <FormattedMessage {...messages.action_required_submit_note_need_notes}/> :
            <FormattedMessage {...messages.action_required_submit_note_po_approved}/>}
            </div>
        
        <div className="adjusted_line_item__accept" >
            <a onClick={onSubmitClickHandler}>
                <FormattedMessage {...messages.action_required_po_submit}/>
                {' '} <img src="/assets/VMS_33button_go_bblue.png"/>
            </a>
        </div>
        { isHsbClientVehicle &&
            entityForAssistance == constants.ENTITY_FOR_ASSISTANCE.CUSTOMER &&
            !isPOAdjustedBySystem &&
            authorizationStatus !== constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED ? <div className="adjusted_line_item__cancel">
            <ForwardForFurtherReview
                ref={forwardPOShowConfirmWindowRef}
                routeParams={routeParams}
                dispatch={dispatch}
            />
            <a onClick={(e) => { onForwardPOHandler(e); }}>
                <FormattedMessage {...messages.forward_for_further_review} />
                {' '} <img src="/assets/VMS_28button_go_clear.png" />
            </a>
        </div>
            : false
        }
        {isPOAdjustedBySystem ?            
            <div className="adjusted_line_item__accept">
            <a onClick={onRejectAdjustments}>
                <FormattedMessage {...messages.adjusted_line_item_view_reject_intial_adjustment_message}/>
                {' '} <img src="/assets/VMS_33button_go_bblue.png"/>
                
            </a>
        </div>  
        :false
        }

        {appState.poPermissions.includes('VD') &&
        <div className="adjusted_line_item__cancel">
            <ConfirmMessageBox ref={cancelPOShowConfirmWindowRef}
                                                    title={messages.adjusted_line_item_view_cancel_po_confirmation_title}
                                                    message={messages.adjusted_line_item_view_cancel_po_confirmation_message}
                                                    okButtonText={messages.adjusted_line_item_view_cancel_po_confirmation_yes}
                                                    cancelButtonText={messages.adjusted_line_item_view_cancel_po_confirmation_no}
                                                    onOKClickEvent={onCancelPOClickEvent}
                                                    onCancelClickEvent={null}/>
            <a onClick={(e)=>{onCancelPOHandler(e)}}>
                <FormattedMessage {...messages.adjusted_line_item_view_cancel_po }/>
                {' '} <img src="/assets/VMS_28button_delete_blue.png"/>
            </a>
        </div>
        }

    </div>
}
// #endregion

export default injectIntl(ActionRequiredLineItems);