import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Map, fromJS} from 'immutable';
import './VehicleDetails.css';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import {
    emptyMapIfUndefined,
    emptyStringIfUndefined,
    falseIfUndefined,
    emptyStringIfNull,
    renderError,
    validateEmail,
    zeroIfUndefined
} from 'utils/HelperFunctions';


class VehicleDetails extends React.Component {

    componentDidMount() {

    };


    render() {

        //Get data from app state (reducer store)
        var firstOfVin8 = '';
        var lastOfVin8 = '';
        /* this split is to highlight the last 8 chars of vin number as vin8 */
        var vehicleDetails = this.props.vehicleDetails.toJSON();
        var vinNumber = vehicleDetails.vin;
        if (vinNumber != undefined) {
            lastOfVin8 = vinNumber.slice(9, vinNumber.length);
            firstOfVin8 = vinNumber.slice(0, 9);
        }


        const messages = defineMessages({
            vendor_details_title_car_wash: {
                id: 'vendor_details_title_car_wash',
                description: 'vendor_details_title_car_wash',
                defaultMessage: 'Car wash service'
            },
            vehicle_details_vin_no: {
                id: 'vehicle_details_vin_no',
                description: 'VIN',
                defaultMessage: 'VIN'
            },
            vehicle_details_client: {
                id: 'vehicle_details_client',
                description: 'Client',
                defaultMessage: 'Client'
            },
            vehicle_details_ari_unit_no: {
                id: 'vehicle_details_ari_unit_no',
                description: 'Holman unit no.',
                defaultMessage: 'Holman unit no.'
            },
            vehicle_details_vehicle: {
                id: 'vehicle_details_vehicle',
                description: 'Vehicle',
                defaultMessage: 'Vehicle'
            },
            vehicle_details_plate: {
                id: 'vehicle_details_plate',
                description: 'Plate',
                defaultMessage: 'Plate'
            },
        });

        return <div>
            <div className="car_wash_details__title">
                <FormattedMessage {...messages.vendor_details_title_car_wash } />
            </div>
                <div className="car_wash_details">
                    <div className="row">
                        <div className="col-md-12 car_wash_details__left_padding">
                            <div className="col-sm-6">
                                <div className="row">
                                    <div className="col-sm-5 car_wash_details__label">
                                        <FormattedMessage {...messages.vehicle_details_vin_no} />
                                    </div>
                                    <div className="col-sm-7 car_wash_details__val">{firstOfVin8}
                                        <span className="car_wash_details__val_highlight">{lastOfVin8}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-5 car_wash_details__label">
                                        <FormattedMessage {...messages.vehicle_details_client} />
                                    </div>
                                    <div className="col-sm-7 car_wash_details__val">
                                        {
                                            (vehicleDetails.clientId !== undefined && vehicleDetails.clientName !== undefined) ?
                                                <div> {vehicleDetails.clientName} {' ('}
                                                    {vehicleDetails.clientId} {')'}
                                                </div>
                                                : false
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-5 car_wash_details__label">
                                        <FormattedMessage {...messages.vehicle_details_ari_unit_no} />
                                    </div>
                                    <div className="col-sm-7 car_wash_details__val">
                                        {vehicleDetails.ariUnitNo}
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-6 car_wash_details__spacing">
                                <div className="row">
                                    <div className="col-sm-3 car_wash_details__label">
                                        <FormattedMessage {...messages.vehicle_details_vehicle} />
                                    </div>
                                    <div className="col-sm-8 car_wash_details__val">
                                        {vehicleDetails.year} {vehicleDetails.make} {vehicleDetails.model}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3 car_wash_details__label">
                                        <FormattedMessage {...messages.vehicle_details_plate} />
                                    </div>
                                    <div className="col-sm-8 car_wash_details__val">
                                        {vehicleDetails.licensePlate}

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
        </div>;
    }
}


export default (VehicleDetails);

