import {Map} from 'immutable';

const vendorServices = Map(
    {
        "minorityVendor":Map( {
            "FA":Map( {
                "id": "FA",
                "description": "Female African American",
                "isServiceOffered": false,
                "country":"USA"
            }),
            "FC":Map( {
                "id": "FC",
                "description": "Female Caucasian",
                "isServiceOffered": false,
                "country":"USA"
            }),
            "FH":Map( {
                "id": "FH",
                "description": "Female Hispanic American",
                "isServiceOffered": false,
                "country":"USA"
            }),
            "FN":Map( {
                "id": "FN",
                "description": "Female Native American",
                "isServiceOffered": false,
                "country":"USA"
            }),
            "FS":Map( {
                "id": "FS",
                "description": "Female Asian American",
                "isServiceOffered": false,
                "country":"USA"
            }),
            "MA":Map( {
                "id": "MA",
                "description": "Male African American",
                "isServiceOffered": false,
                "country":"USA"
            }),
            "MH":Map( {
                "id": "MH",
                "description": "Male Hispanic American",
                "isServiceOffered": false,
                "country":"USA"
            }),
            "MN":Map( {
                "id": "MN",
                "description": "Male Native American",
                "isServiceOffered": false,
                "country":"USA"
            }),
            "MS":Map( {
                "id": "MS",
                "description": "Male Asian American",
                "isServiceOffered": false,
                "country":"USA"
            }),
            "ABC":Map( {
                "id": "ABC",
                "description": "Aboriginal",
                "isServiceOffered": false,
                "country":"CAN"
            }),
            "AWA":Map( {
                "id": "AWA",
                "description": "Arab/West Asian",
                "isServiceOffered": false,
                "country":"CAN"
            }),
            "BLA":Map( {
                "id": "BLA",
                "description": "Black",
                "isServiceOffered": false,
                "country":"CAN"
            }),
            "HLA":Map( {
                "id": "HLA",
                "description": "Hispanic/Latin American",
                "isServiceOffered": false,
                "country":"CAN"
            }),
            "ASN":Map( {
                "id": "ASN",
                "description": "Asian",
                "isServiceOffered": false,
                "country":"CAN"
            }),
            "SAN":Map( {
                "id": "SAN",
                "description": "South Asian",
                "isServiceOffered": false,
                "country":"CAN"
            }),
            "CFE":Map( {
                "id": "CFE",
                "description": "Caucasian Female",
                "isServiceOffered": false,
                "country":"CAN"
            }),
            "LQ2":Map( {
                "id": "LQ2",
                "description": "LGBTQ2+",
                "isServiceOffered": false,
                "country":"CAN"
            }),
            "PNS":Map( {
                "id": "PNS",
                "description": "NA/Prefer Not to Say",
                "isServiceOffered": false,
                "country":"CAN"
            }),
            "AB":Map( {
                "id": "AB",
                "description": "Not Applicable",
                "isServiceOffered": false,
                "country":"USA"
            })

        })
    }
);

export default (vendorServices);