// #region Imports
import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { defineMessages, FormattedMessage,injectIntl } from "react-intl";
import * as Api from "utils/Api";
import * as helperFunctions from "utils/HelperFunctions";
import {
  ModalClose,
  ModalMainHeader,  
  ModalBodyTable,
  ButtonRowStyled,
  ButtonStyled,  
  Input,
  InputFeedback
} from "DesignSpecs/components/FormComponents";
import {
  BodyCopyStyled,
} from "DesignSpecs/components/FontGuide";
import Spinner from "components/Spinner";
import SelectedUsers from "./SelectedUsers";
import FormWrapper from "components/FormWrapper";
// #endregion

// #region Styled Components
const InstructionsStyled = styled.div`
  padding-top: 1rem;
  line-height: 4rem;
  padding-bottom: 1rem;    
`;

const InputGroupStyled = styled.div`
  padding-top: 1rem;  
  padding-bottom: 1rem;
`;

const HR = styled.hr`
  border-top: 1px solid #e1e4ec;
`;

const Title = styled.div`
	text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;
// #endregion

// #region Component
const AddNewUser = ({ 
  initialUser,
  intl, 
  token,
  personaUserId,  
  routeParams,
  selectedUsers,  
  onAddNewUserClick, 
  onRemoveUser,
  onReturn,
  onContinue 
}) => {
  const {formatMessage} = intl;  

  // #region State
  const [mainError, setMainError] = useState('');
  // #endregion

  // #region Ref
  // #endregion

  // #region Effects
  // #endregion

  // #region Event handlers
  const handleFormikSubmit = (values, { setSubmitting, resetForm }) => {          
    setMainError('');

    // If this user was already selected, don't add again!    
    if (selectedUsers.some(users => users.emailAddress.toUpperCase() === values.emailAddress.toUpperCase())) {
      resetForm({ firstName: "", lastName: "", emailAddress: "" });
      setSubmitting(false);
      return;
    }

    const postData = async () => {						
      try {
        let user = {          
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.emailAddress
        };

        user.id = await Api.createNewUser(personaUserId, user, token);
        Api.sendUserInvitationLink(
          user.id,
          undefined,
          values.emailAddress,
          token
        );
        onAddNewUserClick({
          id: user.id,
          firstName: values.firstName,
          lastName: values.lastName,
          emailAddress: values.emailAddress
        });
        resetForm({ firstName: "", lastName: "", emailAddress: "" });       
        setSubmitting(false);                    
      } 
      catch (error) {                

        // User already exists.  Return this user like they were just added.
        if (error !== undefined && error.code === 'API-002' && !!error.message) {          
          const existingUser = JSON.parse(error.message.substring(error.message.indexOf('{"existingUser":'))).existingUser;
          
          Api.sendUserInvitationLink(
            existingUser.id,
            undefined,
            existingUser.emailAddress,
            token
          );
          onAddNewUserClick({
            id: existingUser.id,
            firstName: existingUser.firstName,
            lastName: existingUser.lastName,
            emailAddress: existingUser.emailAddress
          });
          resetForm({ firstName: "", lastName: "", emailAddress: "" });
          setSubmitting(false);                    
        }
        else {          
          setMainError(helperFunctions.getErrorObject(error));
        }

        setSubmitting(false);
      }
    }
    postData();
  };
  // #endregion

  // #region International messages
  const messages = defineMessages({
    close_dialog: {
      id: "close_dialog",
      description: "  Close",
      defaultMessage: "Close"
    },  
    add_users_to_store_add_new_users_caption: {
      id: "add_users_to_store_add_new_users_caption",
      description: "Set up new user",
      defaultMessage: "Set up new user"
    },
    add_users_to_store_add_new_user_caption2: {
      id: "add_users_to_store_add_new_user_caption2",
      description: "To set up a new user, complete the fields below and click create user.  Once the user is created, an email with instructions to access Holman PartnerConnect will be sent.",
      defaultMessage: "To set up a new user, complete the fields below and click create user.  Once the user is created, an email with instructions to access Holman PartnerConnect will be sent."
    },    
    add_new_user_create_user: {
      id: "add_new_user_create_user",
      description: "Create user",
      defaultMessage: "Create user"
    },
    add_new_user_first_name_placeHolder:{
      id:"add_new_user_first_name_placeHolder",
      description:"Enter FirstName",
      defaultMessage:"Enter first name"
    },
    add_new_user_last_name_placeHolder:{
      id:"add_new_user_last_name_placeHolder",
      description:"Enter LastName",
      defaultMessage:"Enter last name"
    },
    add_new_user_email_placeHolder:{
      id:"add_new_user_email_placeHolder",
      description:"Enter Email",
      defaultMessage:"Enter email"
    },
    add_new_user__email_invalid:{
      id:"add_new_user__email_invalid",
      description:"Validation error message for invalid email",
      defaultMessage:"Invalid email address format"
    },    
    add_new_user__email_invalid_required:{
      id:"add_new_user__email_invalid_required",
      description:"Message to indicate email address is required",
      defaultMessage:"Email address is required"
    },
    add_users_to_store_return: {
      id: "add_users_to_store_return",
      description: "Return",
      defaultMessage: "Return"
    },    
    select_users_caption: {
      id: "select_users_caption",
      description: "Selected users",
      defaultMessage: "Selected users"
    },
    add_users_to_store_add_new_user__continue: {
      id: "add_users_to_store_add_new_user__continue",
      description: "Continue button",
      defaultMessage: "Continue"
    },
    add_users_to_store_add_new_user__dirty_warning: {
      id: "add_users_to_store_add_new_user__dirty_warning",
      description: "Warning message displayed to user when they try to leave screen with unsaved changes.",
      defaultMessage: "This screen has unsaved changes. Any new users you created will be saved, but permissions will not be. Click 'Close' to discard changes."
    },
  });
  // #endregion

  // #region Render helpers
  const renderCloseButton = () => (
    <ModalClose role='button' onClick={() => {
      routeParams.history.goBack();      
    }}>
      <img src="/assets/VMS_33button_kill_blue.png"/>
      {"  "} {<FormattedMessage {...messages.close_dialog} />}
    </ModalClose>
  );

  const renderReturnButton = () => (
    <ButtonStyled
      role="button"
      onClick={onReturn}
    >
      <img src="/assets/VMS_33button_back_blue2.png" />
      {" "}
      <FormattedMessage
        {...messages.add_users_to_store_return}
      />
    </ButtonStyled>
  );
  
  const renderContinueButton = () => (
    <ButtonStyled
      role="button"
      onClick={onContinue}
    >
      <FormattedMessage {...messages.add_users_to_store_add_new_user__continue} />
      {" "}
      <img src="/assets/VMS_33button_go_bblue.png" />
    </ButtonStyled>
  );
  // #endregion  

  // #region Render
  return (
    <div>            
        
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: "",
          lastName: "",
          emailAddress: "",
          ...initialUser
        }}
        onSubmit={handleFormikSubmit}
        validationSchema={Yup.object().shape({
          emailAddress: Yup.string().email('add_new_user__email_invalid').required("add_new_user__email_invalid_required"),
          firstName: Yup.string().required("Required"),
          lastName: Yup.string().required("Required")
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue
          } = props;
          return (
            <form onSubmit={handleSubmit}>                          

              <FormWrapper formErrors={mainError}/>                  

              <ButtonRowStyled>        
                {renderCloseButton()}
                <div></div>
              </ButtonRowStyled>              

              <ModalMainHeader>          
                <FormattedMessage {...messages.add_users_to_store_add_new_users_caption} />                             
              </ModalMainHeader>
              
              <ModalBodyTable>                

                <InstructionsStyled>                  
                  <BodyCopyStyled>
                    <FormattedMessage
                      {...messages.add_users_to_store_add_new_user_caption2}
                    />                    
                  </BodyCopyStyled>
                </InstructionsStyled>
                
                <InputGroupStyled>
                  <Input
                    id="firstName"
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName}
                    touched={touched.firstName}
                    placeholder={formatMessage(messages.add_new_user_first_name_placeHolder)}
                  />
                  <div>
                    {errors.firstName &&
                      touched.firstName &&
                      <InputFeedback>{errors.firstName}</InputFeedback>}
                  </div>
                </InputGroupStyled>
                
                <InputGroupStyled>
                  <Input
                    id="lastName"
                    type="text"
                    placeholder={formatMessage(messages.add_new_user_last_name_placeHolder)}
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                  <div>
                    {errors.lastName &&
                      touched.lastName &&
                      <InputFeedback>{errors.lastName}</InputFeedback>}
                  </div>
                </InputGroupStyled>
                
                <InputGroupStyled>
                  <Input
                    id="emailAddress"
                    type="text"
                    onChange={handleChange}
                    value={values.emailAddress}
                    placeholder={formatMessage(messages.add_new_user_email_placeHolder)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.emailAddress}
                    touched={touched.emailAddress}
                  />
                  <div>
                    {errors.emailAddress &&
                      touched.emailAddress &&
                      <InputFeedback>
                        <FormattedMessage
                          {...messages[errors.emailAddress]}
                        />                        
                      </InputFeedback>}
                  </div>
                </InputGroupStyled>
                
                
                { isSubmitting ? <Spinner spinnerType='blue' /> 
                :
                  <ButtonStyled role="button" onClick={() => {                    
                    handleSubmit();
                  }}>                  
                    <img src="/assets/VMS_28button_add_blue.png" />
                    {" "}
                    <FormattedMessage {...messages.add_new_user_create_user} />                  
                  </ButtonStyled>
                }

                <HR />

                <Title>
                  <FormattedMessage {...messages.select_users_caption} />          
                </Title>

                <SelectedUsers
                  selectedUsers={selectedUsers}
                  allowRemovingUser={true}
                  onRemoveUser={onRemoveUser}
                />
                                        
              </ModalBodyTable>
              
              <ButtonRowStyled>
                {renderReturnButton()}
                {renderContinueButton()}                
              </ButtonRowStyled>

            </form>
          );
        }}

      </Formik>
    </div>
  );
  // #endregion
};
// #endregion

export default injectIntl(AddNewUser);
