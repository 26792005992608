import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage,injectIntl} from 'react-intl';
import {Map, fromJS} from 'immutable';
import './CarWashView.css';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import { emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError,validateEmail,zeroIfUndefined } from 'utils/HelperFunctions';
import PaymentInProgress from '../components/PaymentInProgress';
import * as CarWashViewActions from '../actions/CarWashView';
import CarWashViewSelector from '../selectors/CarWashView';
import ConfirmMessageBox from 'AddEditView/ConfirmMessageBox';
import POList from '../../../screens/PO/ShopView/components/ActivePOList';
import { PO_AUTHORIZATION_STATUS, PO_PAYMENT_STATUS } from 'constants/App';
import * as ShopViewActions from "../../PO/ShopView/actions/ShopView";
import { Link } from 'react-router-dom';

class CarWashView extends React.Component {

    componentDidMount() {

        this.props.dispatch(CarWashViewActions.carWashFetchClientNumbers());
        this.props.dispatch(CarWashViewActions.carWashFetchOrderDetails());
        this.props.dispatch(CarWashViewActions.carWashClearPOCreationDetails());
        this.props.dispatch({type: constants.FETCH_BULLETIN_MESSAGE});

       };

    onCancelClick() {

        this.deleteLineConfirmWindow.showModal();
    }
    onOkClickHandler() {

        this.props.dispatch(CarWashViewActions.carWashClearPOCreationDetails());

    }

    onInputChangeHandler(key, value, e) {


        let poDetails = Map({
            key: key,
            value: e != undefined && e.target.value != undefined ? e.target.value : value,
            taxType: e != undefined && e.target.attributes['data-taxType'] != undefined ? e.target.attributes['data-taxType'].value : '',

        });

            this.props.dispatch(CarWashViewActions.carWashSavePODetails(poDetails));
            this.props.dispatch(CarWashViewActions.carWashFormIsDirty());

    }


    componentWillUnmount() {

        this.props.dispatch(CarWashViewActions.carWashClearPOCreationDetails());
    }



    render() {

        const messages = defineMessages({
            help_faq_return: {
                id: 'help_faq_return',
                description: 'Return button',
                defaultMessage: 'Return'
            },
            car_wash_confirmation_title: {
                id: 'car_wash_confirmation_title',
                description: 'Cancel Request',
                defaultMessage: 'Cancel Request'
            },
            car_wash_confirmation_message: {
                id: 'car_wash_confirmation_message',
                description: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?',
                defaultMessage: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?'
            },
            car_wash_confirmation_no: {
                id: 'car_wash_confirmation_no',
                description: 'No',
                defaultMessage: 'No'
            },
            car_wash_confirmation_yes: {
                id: 'car_wash_confirmation_yes',
                description: 'Yes',
                defaultMessage: 'Yes'
            },
            unsaved_data: {
                id: 'unsaved_data',
                description: 'unsaved_data',
                defaultMessage: "You have unsaved changes that will be lost if you decide to continue. Click OK to continue or Cancel to stay on the same page."
            },learn_more_caption:{
                id:'learn_more_caption',
                description:'Learn More Link',
                defaultMessage:' Learn more'

            }

        });
        const {formatMessage} = this.props.intl;

        const waitingInProgressPOs = this.props.activePOs.filter(o => o.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL);
        const paymentInProgressPOs=this.props.activePOs.filter(a=>a.get('paymentStatus')==PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS);
        const approvedPO = this.props.activePOs.filter(o => o.get('authorizationStatus') == PO_AUTHORIZATION_STATUS.APPROVED && o.get('paymentStatus') == PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED);

        if (this.props.isFetchingData) {
            return <Spinner />;
        }

        return <div>
            {this.props.primaryBulletin !== undefined ?
                this.props.primaryBulletin.map((param) =>
                    <div className="shop-view__bulletin__message_title">
                        <div
                            className="shop-view__bulletin__message_text"> {param.get('title')}
                            <Link
                                to={`/learnMore/${param.get('id')}`}><FormattedMessage {...messages.learn_more_caption}/></Link>
                        </div>
                        < hr className="shop-view__hr"/>
                    </div>
                )
                :  false
            }
            {this.props.secondaryBulletin !== undefined ?
                this.props.secondaryBulletin.map((param) =>
                    <div className="shop-view__bulletin__message_title">
                        <div
                            className="shop-view__bulletin__message_text">
                            <FormattedMessage id='alert_message_title' defaultMessage={param.get('title')}/>
                            {param.get("message") === null ? false :
                                <Link
                                    to={`/learnMore/${param.get('id')}`}><FormattedMessage {...messages.learn_more_caption}/></Link>
                            }
                        </div>
                        <hr className="shop-view__hr"/>
                    </div>
                )
                :  false
            }
           <PaymentInProgress
                status={"WAITING"}
                activePOs={waitingInProgressPOs}
                showAll={this.props.showAllWaiting}
                footerClickHandler={() => this.props.dispatch(CarWashViewActions.carWashToggleShowAllVendorActionNeededApproval())}
                selectedCurrency={this.props.selectedCurrency}
            />

            <POList
                activePOs={approvedPO}
                type={PO_AUTHORIZATION_STATUS.APPROVED}
                showAll={this.props.showAllApprovedPOs}
                footerClickHandler={()=>this.props.dispatch(ShopViewActions.toggleShowAllApprovedPOs())}
                vendorId={this.props.vendorId}
                selectedCurrency={this.props.selectedCurrency}
                ShowEditLinkForApprovedPOs={this.props.ShowEditLinkForApprovedPOs}
                selectedCountry={this.props.country}

            />

            <PaymentInProgress
                activePOs={paymentInProgressPOs}
                footerClickHandler={() => this.props.dispatch(CarWashViewActions.carWashToggleShowAllVendorActionNeededPIP())}
                showAll={this.props.showAllPIP}
                selectedCurrency={this.props.selectedCurrency}
            />
            <ConfirmMessageBox
                ref={r => this.deleteLineConfirmWindow = r}
                title={messages.car_wash_confirmation_title}
                message={messages.car_wash_confirmation_message}
                okButtonText={messages.car_wash_confirmation_yes}
                cancelButtonText={messages.car_wash_confirmation_no}
                onOKClickEvent={() => this.onOkClickHandler()}
                onCancelClickEvent={null}
            />
        </div>;
    }
}

function select(state) {
    return {
        isFetchingData: falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','isFetchingData'])),
        canadianProvinces: emptyStringIfUndefined(fromJS(state.appState.getIn(['uiData', 'shared', 'canadianProvinces']))),
        clientNumbers: emptyMapIfUndefined(state.appState.getIn(['uiData','carWashAccount','vendorClientNumbers'])),
        activePOs:emptyMapIfUndefined(state.appState.getIn(['uiData','carWashAccount','orderSearchDetails'])),
        showAllPIP: falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','showAllVendorActionNeededPIP'])),
        vehicleDetails:emptyMapIfUndefined(state.appState.getIn(['uiData','carWashAccount','vehicleDetails'])),
        poFormFlag:falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','poFormFlag'])),
        carWashServiceData:falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','carWashServiceTypeSelection'])),
        poDetails:emptyMapIfUndefined(state.appState.getIn(['uiData','carWashAccount','poDetails'])),
        userLocale:state.appState.getIn(['uiData', 'shared', 'selectedLocale']),
        serviceDateError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','serviceDateError']),
        invoiceNumberError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','invoiceNoError']),
        regularWashMaxUnitPrice:state.appState.getIn(['serverData', 'carWashAccount', 'vehicleDetails','parameterDetails','regularWashMaxUnitPrice']),
        detailWashMaxUnitPrice:state.appState.getIn(['serverData', 'carWashAccount', 'vehicleDetails','parameterDetails','detailWashMaxUnitPrice']),
        carWashCostError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','carWashCostError']),
        detailCarWashCostError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','detailCarWashCostError']),
        taxAmountError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','taxAmountError']),
        odometerError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','odometerError']),
        estimatedOdometerRangeMin:state.appState.getIn(['uiData', 'carWashAccount', 'vehicleDetails','estimatedOdometerRangeMin']),
        estimatedOdometerRangeMax:state.appState.getIn(['uiData', 'carWashAccount', 'vehicleDetails','estimatedOdometerRangeMax']),
        taxCanada:emptyMapIfUndefined(state.appState.getIn(['uiData','carWashAccount','poDetails','taxes'])),
        canadianTaxError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','taxQSTError']),
        createPOErrorMessage: state.appState.getIn(['uiData','carWashAccount','creatingPOError']),
        isFormDirty:state.appState.getIn(['uiData','carWashAccount','isDirty']) || false,
        showAllWaiting: falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','showAllVendorActionNeededApproval'])),
        serviceClassification:state.appState.getIn(['serverData','shared','serviceClassification']),
        selectedCurrency:state.appState.getIn(['uiData', 'shared','selectedCurrency']),
        applicableTaxTypes:state.appState.getIn(['uiData', 'carWashAccount','applicableTaxTypes']) || undefined,
        vendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
        showAllApprovedPOs:emptyMapIfUndefined(state.appState.getIn(['uiData',  'shopView','showAllApprovedPOs'])),
        ShowEditLinkForApprovedPOs: window.location.href.includes("/carWash"),
        country: state.appState.getIn(['uiData', 'shared', 'selectedCountry']),
        ...CarWashViewSelector(state)
    };
}

export default  connect(select)(injectIntl(CarWashView));

