import React from "react";
import {
  defineMessages,
  FormattedMessage
} from "react-intl";
import "./VendorAccountVerification.css";
import VendorAccountEdit from "./EditVendorAccountInformation";
import EditPaymentEmailInformation from "./EditPaymentEmailInformation";
import { Popover, Overlay } from "react-bootstrap";
import FormWrapper from "components/FormWrapper";
import ReactDOM from "react-dom";
import TermsAndConditions
  from "../../../../shared/components/VendorTermsAndConditions";

class VendorAccountVerification extends React.Component {  
  focusId(number, event) {
    this.props.onBlurChange.bind(this);
    if (event.target.value.length >= event.target.maxLength && number < 5) {
      number += 1;
      document.getElementById(number).focus();
    }
  }

  onContinueCheckHandler() {
    this.props.onBlurChange.bind(this);
    if (this.props.country == "CAN")
      this.props.onEditAccountClick(
        this.refs.txtARIId.value,
        "",
        "",
        "",
        this.refs.txtVendorID4.value,
        this.refs.txtVendorID5.value
      );
    else
      this.props.onEditAccountClick(
        this.refs.txtARIId.value,
        this.refs.txtVendorID1.value,
        this.refs.txtVendorID2.value,
        this.refs.txtVendorID3.value,
        this.refs.txtVendorID4.value,
        this.refs.txtVendorID5.value
      );
  }

  onHide() {
    this.props.dispatch({ type: constants.CLEAR_ACCOUNT_INFORMATION_ERROR });
  }

  render() {
    const messages = defineMessages({
      account_verify_caption: {
        id: "account_verify_caption",
        description: "Title for verify section",
        defaultMessage: "Account Verification Required"
      },
      account_verify_sub_caption: {
        id: "account_verify_sub_caption",
        description: "sub title for verify section",
        defaultMessage: "All changes to a bank account registration requires re-entry of your name and Tax Id. " +
          "Please allow one full business day for completion of a banking change and always verify your account number prior to submitting an on-line invoice."
      },
      account_verify_sub_caption_for_payment_email: {
        id: "account_verify_sub_caption_for_payment_email",
        description: "sub title for verify section",
        defaultMessage: "All changes to the Email Recipient field requires re-entry of your name and Tax ID. " 
      },
      contact_name_caption: {
        id: "contact_name_caption",
        description: "Title for contact name",
        defaultMessage: "Contact name"
      },
      employer_id_no_caption_CAN: {
        id: "employer_id_no_caption_CAN",
        description: "Title for GST No",
        defaultMessage: "Business Number"
      },
      social_security_caption: {
        id: "social_security_caption",
        description: "Title for ssn ",
        defaultMessage: "Social Security no."
      },
      employer_id_no_caption: {
        id: "employer_id_no_caption",
        description: "Title for eid",
        defaultMessage: "Employer ID no."
      },
      terms_caption: {
        id: "terms_caption",
        description: "Title for terms and condition",
        defaultMessage: "Terms and Conditions"
      },
      terms_conditions_text: {
        id: "terms_conditions_text",
        description: "section for terms and condition",
        defaultMessage: "1) Authorizes Holman to make payment directly to the bank account you will be providing <br/> " +
          "2) Consents to pay the bank handling fee as agreed with Holman during your enrollment process <br/> " +
          "3) Acknowledges that the origination of all transactions to the account must comply with U.S. law <br/> " +
          "4) **Will inform Holman of any changes, including account and business location at least 5 business days prior to the change**<br/> " +
          "5)  Acknowledges that Holman may reproduce an invoice evidencing transactions under this agreement"
      },
      radio_button_agree_caption: {
        id: "radio_button_agree_caption",
        description: "radio button",
        defaultMessage: "I have read and agree to the terms and conditions indicated above."
      },
      cancel_verify_caption: {
        id: "cancel_verify_caption",
        description: "Title for cancel button",
        defaultMessage: "Cancel"
      },
      continue_verify_caption: {
        id: "continue_verify_caption",
        description: "Title for continue button",
        defaultMessage: "Continue"
      },
      terms_and_condition_error_message: {
        id: "terms_and_condition_error_message",
        description: "error if submitted and terms not accepted",
        defaultMessage: "Please agree to the terms and conditions in order to continue."
      },
      termsHeader: {
        id: "termsHeader",
        description: "termsHeader",
        defaultMessage: 'General Terms and Conditions ("Holman Terms")'
      }
    });
    const tabIndexGroup = this.props.tabIndexStartFrom;

    return (
      <div className="account-verify">
        {!this.props.editModeAccount && !this.props.editModePaymentEmail
          ? <div>
              <FormWrapper
                key="accountErrorMessage"
                id="account-generic-error"
                {...this.props}
                formErrors={this.props.accountErrorMessage}
              />
              <div className="account-verify__view row">
                <div className="col-sm-7  account-verify__title">
                  <FormattedMessage {...messages.account_verify_caption} />
                </div>
              </div>
              <div className="account-verify__view row">
                <div className="account-verify__text">
                 {this.props.isPaymentEmailEditModeValidation?<FormattedMessage {...messages.account_verify_sub_caption_for_payment_email}/> :  <FormattedMessage {...messages.account_verify_sub_caption} />
                 }
                </div>
              </div>
              <div className="account-verify__input-section">
                <div className="account-verify__view3 row">
                  <div className="account-verify__subTitles">
                    <FormattedMessage {...messages.contact_name_caption} />
                  </div>
                </div>
                <div className="account-verify__view3 row">
                  <div className="account-verify__text-align">
                    <Overlay
                      show={this.props.popOverDisplayShowARIId}
                      onHide={this.onHide.bind(this)}
                      placement="right"
                      target={props => ReactDOM.findDOMNode(this.refs.txtARIId)}
                    >
                      <Popover
                        id="ARIIdErrorMessage"
                        className="account-verify__errorMessage"
                      >
                        {this.props.accountIdError}
                      </Popover>

                    </Overlay>
                    {this.props.personaUserName!=null?
                    <input
                      type="text"
                      tabIndex={tabIndexGroup + 1}
                      ref="txtARIId"
                      className="account-verify__input-id"
                      maxLength="30"
                      value={this.props.personaUserName.substring(0,30)}
                      readOnly={true}
                      disabled={true}
                    />:
                    <input
                      type="text"
                      tabIndex={tabIndexGroup + 1}
                      ref="txtARIId"
                      className="account-verify__input-id"
                      maxLength="30"
                      autoFocus
                    />
                    }
                  </div>
                </div>
                <div className="account-verify__view2 row">
                  <div className="account-verify__subTitles">
                    {this.props.country == "USA"
                      ? <div className="account-verify__ssn">
                          <FormattedMessage
                            {...messages.social_security_caption}
                          />
                        </div>
                      : ""}
                    <div className="account-verify__ein">
                      {this.props.country == "USA"
                        ? <FormattedMessage
                            {...messages.employer_id_no_caption}
                          />
                        : <FormattedMessage
                            {...messages.employer_id_no_caption_CAN}
                          />}
                    </div>
                  </div>
                </div>
                <div className="account-verify__view3 row">
                  <div className="account-verify__input-align">

                    {this.props.country == "USA"
                      ? <div className="account-verify__input-align">

                          <input
                            type="password"
                            tabIndex={tabIndexGroup + 2}
                            ref="txtVendorID1"
                            pattern="[0-9]*"
                            className="account-verify__input"
                            maxLength="3"
                            onChange={this.focusId.bind(this, 1)}
                            id="1"
                          />
                          {" "}
                          -

                          <input
                            type="password"
                            tabIndex={tabIndexGroup + 3}
                            ref="txtVendorID2"
                            className="account-verify__input-pad"
                            maxLength="2"
                            onChange={this.focusId.bind(this, 2)}
                            id="2"
                          />
                          {" "}
                          -

                          <input
                            type="password"
                            tabIndex={tabIndexGroup + 4}
                            ref="txtVendorID3"
                            className="account-verify__input-pad"
                            maxLength="4"
                            onChange={this.focusId.bind(this, 3)}
                            id="3"
                          />

                          <text className="account-verify__or">OR</text>
                        </div>
                      : undefined}
                    <div className="account-verify__input-align">
                      <input
                        type="password"
                        tabIndex={tabIndexGroup + 5}
                        ref="txtVendorID4"
                        className="account-verify__input-pad"
                        maxLength="2"
                        onChange={this.focusId.bind(this, 4)}
                        id="4"
                      />
                      {" "}
                      -
                      <Overlay
                        show={this.props.popOverDisplayShowPersonal}
                        onHide={this.onHide.bind(this)}
                        placement="right"
                        target={props =>
                          ReactDOM.findDOMNode(this.refs.txtVendorID5)}
                      >
                        <Popover
                          id="PersonalInformationErrorMessage"
                          className="account-verify__errorMessage"
                        >
                          {this.props.accountPersonalError}
                        </Popover>

                      </Overlay>
                      <input
                        type="password"
                        tabIndex={tabIndexGroup + 6}
                        ref="txtVendorID5"
                        className="account-verify__input-pad2"
                        maxLength="7"
                        onChange={this.focusId.bind(this, 5)}
                        id="5"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="account-verify__view4 row">
                  <div className="account-verify__subTitles2">
                    <FormattedMessage {...messages.termsHeader} />
                  </div>
                </div>
                <div className="account-verify__view4 row">
                  <div className="account-verify__text2">
                    <TermsAndConditions
                      ref="termsReadAndAgreed"
                      termsAndConditions={this.props.termsAndConditions}
                      toggleAcceptedFlag={this.props.termsAndConditionAccept}
                      isFetchingTermsAndConditions={
                        this.props.isFetchingTermsAndConditions
                      }
                      onAcceptedTerms={this.props.agreedToTermsAndConditions.bind(
                        this,
                        true
                      )}
                    />
                  </div>
                </div>
                <div className="account-verify__view3 row">
                  <Overlay
                    show={this.props.popOverDisplayShowTerms}
                    onHide={this.onHide.bind(this)}
                    placement="bottom"
                    target={props =>
                      ReactDOM.findDOMNode(this.refs.termsReadAndAgreed)}
                  >
                    <Popover
                      id="PersonalInformationErrorMessage"
                      className="account-verify__errorMessage"
                    >
                      {this.props.termsError}
                    </Popover>

                  </Overlay>

                </div>
              </div>

              <div className="account-verify__save-padding">
                <div className="account-verify__view2 row">
                  <div className="col-sm-10">

                    <img src="/assets/VMS_28button_delete_blue.png" />{" "}
                    <a
                      className="account-verify__caption"
                      onClick={this.props.onCancelAccountClick.bind(this)}
                      tabIndex={tabIndexGroup + 9}
                    >
                      <FormattedMessage {...messages.cancel_verify_caption} />

                    </a>

                  </div>
                  <div className="account-verify__caption">
                    <a
                      onClick={this.onContinueCheckHandler.bind(this)}
                      tabIndex={tabIndexGroup + 8}
                    >
                      <FormattedMessage {...messages.continue_verify_caption} />
                      {" "}
                      <img src="/assets/VMS_28button_edit_clear.png" />
                    </a>

                  </div>
                </div>
              </div>
            </div>
          : this.props.editModePaymentEmail
              ? <EditPaymentEmailInformation
                  paymentEmail={this.props.paymentEmail}
                  //onSavePaymentEmail={this.props.onSavePaymentEmail }
                  onCancelAccountClick={this.props.onCancelAccountClick.bind(this)}
                />
              : <VendorAccountEdit
                  popOverDisplayShowChecking1={
                    this.props.popOverDisplayShowChecking1
                  }
                  popOverDisplayShowChecking2={
                    this.props.popOverDisplayShowChecking2
                  }
                  popOverDisplayShowRoutingNumber={
                    this.props.popOverDisplayShowRoutingNumber
                  }
                  checking1Error={this.props.checking1Error}
                  checking2Error={this.props.checking2Error}
                  routingNumberError={this.props.routingNumberError}
                  onCancelAccountClick={this.props.onCancelAccountClick.bind(
                    this
                  )}
                  onSaveBankingAccount={this.props.onSaveBankingAccount}
                  onBlurChange={this.props.onBlurChange}
                  tabIndexStartFrom={this.props.tabIndexStartFrom + 50}
                  country={this.props.country}
                />}
      </div>
    );
  }
}

export default VendorAccountVerification;
