import React, { useState } from 'react';
import './ComplaintNotesReadOnly.css';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';
import lodash from 'lodash';
import {Map,fromJS} from 'immutable';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import { useAppState } from 'shared/AppState/app-state';

const ComplaintNotesReadOnly = (props) => {
    const [ appState, dispatch ] = useAppState();
    const [showAll, setshowAll] = useState(false);

    const showMoreClickHandler = (e)=> {
        setshowAll(true);
    }

    const showLessClickHandler= (e)=> {
        setshowAll(false);
    }


        const showMore=props.intl.formatMessage({id: 'complaint_notes_readonly_show_more',description: '...show More',
        defaultMessage: '...show more'});
        const showLess=props.intl.formatMessage({id: 'complaint_notes_readonly_show_less', description: '...show less',
        defaultMessage: '...show less'});
        const yourNote=props.intl.formatMessage({id: 'complaint_notes_readonly_your_note', description: 'Your Note: ',
        defaultMessage: 'Your Note: '});
        const ariNote=props.intl.formatMessage({id: 'complaint_notes_readonly_ari_note', description: 'Holman: ',
        defaultMessage: 'Holman: '});

        const lineItems=lodash.groupBy(props.notes.filter(a=>a.get('category')=='LINE').toJSON(),'lineItemId');
        //Keeps track of the number of lines displayed
        var lineCount=0;
        //Indicates if the ShowMore link is displayed or not
        //Show more link is displyed only when this flag value is true
        //Once the link is displayed, the falg is again made 'false' durin the next line count check
        //This enables the show more link to be displayed only once
        var flag=true && !showAll;
        var maxLineCount=10;

        return <div className="complaint-notes-read-only">
            {
                <div>
                {
                emptyMapIfUndefined(props.notes).filter(li=>li.get('category')!='LINE').map((note) =>{                                        
                return <div key={note.get('id')} className="complaint-notes-read-only_complaintNote">                    
                            {note.get('createdBy')==note.get('vendorId')? yourNote : ariNote}
                            {note.get('noteText').split("\n").map((line,index) => {                                                    
                                                            if(lineCount>maxLineCount) flag=false;
                                                            else lineCount=lineCount+1;
                                                        return   lineCount > maxLineCount && !showAll ? flag? 
                                                        <a className="complaint-notes-read-only_lineItem_link" onClick={showMoreClickHandler.bind(this)}>
                                                        {showMore}</a>:false
                                                        :
                                                            index==0 ? line : <div key={note.get('id') + '-'+index} >{line}</div>
                                                        }
                                                )}
                    
                        </div>}).valueSeq()
                    }
              
                {
                    
                     <div>
                    {
                        lodash.map(lineItems,(lineItemNotesGroup)=>{ 
                            if (lineCount>maxLineCount) flag=false;
                            else lineCount=lineCount+1;
                                                
                            
                       return lineCount>maxLineCount && !showAll ? flag? <div className="complaint-notes-read-only_lineItem_link">
                            <a className="complaint-notes-read-only_lineItem_link" onClick={showMoreClickHandler.bind(this)}>{showMore}</a>
                            </div>
                            : false:
                       <div key={lineItemNotesGroup[0].lineItemId} className="complaint-notes-read-only_lineItem">                            
                            {appState.isProductCodeFeatureEnabled ? lineItemNotesGroup[0].productDescription : lineItemNotesGroup[0].productDescription }
                            {lodash.map(lodash.orderBy(lineItemNotesGroup,['id'],['asc']) ,(notes)=>{ 
                                if (lineCount>maxLineCount) flag=false; 
                                else lineCount=lineCount+1;       
                                               
                                return lineCount>maxLineCount && !showAll ? flag ? <div className="complaint-notes-read-only_lineItem_link"> 
                                <a className="complaint-notes-read-only_lineItem_link" onClick={showMoreClickHandler.bind(this)}>{showMore}</a>
                                </div>:false
                                : 
                                <div className="complaint-notes-read-only_lineItem_note">
                                       {notes.createdBy==notes.vendorId?yourNote : ariNote}
                                        {notes.noteText.split("\n").map((line,index) => {
                                             if (lineCount>maxLineCount) flag=false; 
                                            else lineCount=lineCount+1;                                            
                                                        return lineCount>maxLineCount && !showAll ? flag ? <div className="complaint-notes-read-only_lineItem_link"> 
                                                            <a className="complaint-notes-read-only_lineItem_link" onClick={showMoreClickHandler.bind(this)}>{showMore}</a></div>:false:
                                        index==0 ? line : <div key={notes.lineItemId+ '-' + index }>{line}</div>}
                                        )}
                                        </div>
                                
                            })
                            }
                            
                        </div>
                    
                        
                        })
                    }
                    </div>
                    
                }
               {
                   showAll?
                   <div className="complaint-notes-read-only_lineItem_link"> 
                   <a className="complaint-notes-read-only_lineItem_link" onClick={showLessClickHandler.bind(this)}>{showLess}</a></div>:false
               
               }

                </div>
            }
        </div>

}


export default injectIntl(ComplaintNotesReadOnly);