import { call, put, select } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import jwtDecode from 'jwt-decode';

export function* sendResetPassword(action) {
    try {
        yield put({ type: constants.RESET_PASSWORD_REQUESTED });

        const state = yield select();        
        const personalInfo = state.appState.getIn(['uiData', 'resetPassword', 'personal']);

        const jwtToken = jwtDecode(personalInfo.requestId);
        const vendorId = jwtToken.id;
        
        var vendorPasswordInformation = {
            'newPassword': personalInfo.newPassword
        };
        
        if(action.isPersonaUser)
            yield call(Api.resetPasswordForPersonaUser, vendorId, vendorPasswordInformation, personalInfo.requestId);
        else
            yield call(Api.resetPassword, vendorId, vendorPasswordInformation, personalInfo.requestId);


        yield put({ type: constants.RESET_PASSWORD_APPROVED });
        yield put({ type: constants.RESET_PASSWORD_RESOLVED });
        yield put({ type: constants.NAVIGATE_TO, path: '/resetPassword/confirmation/success' });
    }
    catch (e) {
        var customError = helperFunctions.getCustomErrorObject(e);
        var genericError = helperFunctions.getGenericErrorObject(e);
        var errorMesg = customError != undefined && customError.length > 0 ? customError[0].message : genericError != undefined && genericError.length > 0 ? genericError[0].message : undefined;
        yield put({ type: constants.RESET_PASSWORD_REJECTED, errorMessage: errorMesg });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.RESET_PASSWORD_REQUESTED, errorObject: e });
        
        if (e.code == 'API-140') {
            yield put({ type: constants.NAVIGATE_TO, path: '/resetPassword/confirmation/invalid' });
        }
        else if (e.code == 'API-998') {
            yield put({ type: constants.NAVIGATE_TO, path: '/resetPassword/confirmation/invalid' });
        }
        else if (e.code == 'API-142'){
            yield put({ type: constants.SHOW_RESET_PASSWORD_ERROR_MESSAGE });
        }
        else {
            yield put({ type: constants.NAVIGATE_TO, path: '/resetPassword/confirmation/failed' });
        }
    }
}
