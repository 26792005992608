import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import './LoginContent.css';
import { connect } from 'react-redux';
import LoginHeaderSelector from '../selectors/LoginHeader';
import FormWrapper from 'components/FormWrapper';
import ReactJWPlayer from 'react-jw-player';
import videos from 'data/videos';
import OldBrowserWarning from 'components/OldBrowserWarning';
import {withRouter} from 'react-router-dom'
import queryString from 'query-string'

class LogInContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hasUserSignedOut: false,
            showVideo: false
        }
    }

    componentDidMount() {

        const values = queryString.parse(this.props.location.search);

        if (this.props.location.search != null && values.signed_out != undefined)
            this.setState({ hasUserSignedOut: true });
        else
            this.setState({ hasUserSignedOut: false });
    }

    showVideo(event) {
        event.preventDefault();
        this.setState({ showVideo: true });
    }

    render() {
        const messages = defineMessages({
            signed_out: {
                id: 'signed_out',
                description: 'You have successfully signed out.',
                defaultMessage: 'You have successfully signed out.'
            },
            quick_start_guide: {
                id: 'quick_start_guide',
                description: 'quick_start_guide',
                defaultMessage: 'Click Here for a Quick Video Training Guide'
            }
        });

        if (!this.state.showVideo)
            return <div>
                <FormWrapper key='loginErrorMessage' id="loginErrorMessage" {...this.props}
                    formErrors={this.props.genericErrorMessage} />

                <div className="login-header-new-video text-center" onClick={this.showVideo.bind(this)}>
                    <i className="fa fa-youtube-play login-header-new-video-icon" aria-hidden="true"></i>
                    <a className="login-header-new-video-link" href=""><FormattedMessage {...messages.quick_start_guide} /></a>
                </div>

                {this.state.hasUserSignedOut ?
                    <div className="login-header-signed-out text-center" ref="SignedOutMessage">
                        <span><FormattedMessage {...messages.signed_out} /></span>
                    </div>
                    : false
                }
                
                <div css='width: 100%;'>                                      
                      <img src="/assets/PartnerConnect_LoginScreenBanner.jpg" class="img-responsive" />                    
                </div>

            </div>
        else
            return <div>
                <FormWrapper key='loginErrorMessage' id="loginErrorMessage" {...this.props}
                    formErrors={this.props.genericErrorMessage} />


                <div className="login-header-new-video text-center">
                        <i className="fa fa-youtube-play login-header-new-video-icon" aria-hidden="true"></i>
                        <span className="login-header-new-video-link" href=""><FormattedMessage {...messages.quick_start_guide} /></span>
                        <span className="login-header-new-video-error"><OldBrowserWarning /></span>
                </div>

                {
                    this.state.hasUserSignedOut ?
                        <div className="login-header-signed-out text-center" ref="SignedOutMessage">
                            <span><FormattedMessage {...messages.signed_out} /></span>
                        </div>
                        : false
                }

                <ReactJWPlayer
                playerId={this.props.selectedCountry=="MEX"?"quick_start_mex": "quick_start"}
                file={this.props.selectedCountry=="MEX"?videos.quick_start_mex.videoLocation: videos.quick_start.videoLocation}
                playerScript={videos.playerLocation}
                image={this.props.selectedCountry=="MEX"?videos.quick_start_mex.imageLocation: videos.quick_start.imageLocation}
                isAutoPlay={true}
            />
            </div >;
    }
}


export default withRouter(connect(LoginHeaderSelector)(LogInContent));