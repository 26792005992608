// #region Imports
import React, { useState, useEffect, useRef } from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import * as Api from "utils/Api";
import useDebounce from "Common/useDebounce"
import Spinner from 'components/Spinner';
import {
    ModalClose,
    ModalMainHeader,
    ModalBodyTable,
    ButtonRowStyled,
    ButtonStyled,
    Row,
    FormBodyText
} from "DesignSpecs/components/FormComponents";
import FormWrapper from "components/FormWrapper";
import * as helperFunctions from "utils/HelperFunctions";
import ClientParametersComponent from 'components/ClientParametersComponent';
import StepNumber from "StepNumber";
import * as addNewLineItemsViewActions from '../actions/AddNewLineItemsView';
import * as constants from 'constants/App';
import { Map } from 'immutable';
import {connect} from 'react-redux';
// #endregion

// #region Styled Components
const Main = styled.div`	
  padding-bottom: 1rem;
`;

const Header = styled.div`
  text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const ParametersHeader = styled.div`
  text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-top: 2rem;
  padding-bottom: 0;
`;

const NewlyAddedItemsHeader = styled.div`
  text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-top: 2rem;
  padding-bottom: 0;
`;

const Dropdown = styled.div`
  align-content: center; 
  padding-bottom: 1rem;
`;

const SearchInput = styled.input`
	width: 550px;   
  font-family: "Gotham SSm A", "Gotham SSm B";
  padding: 3px 2px 2px 2px;
  border: 1px solid #5587b7;
`;

const SearchIcon = styled.input`
  height: 25px;
  width: 25px;
  margin-left: 1rem;
  vertical-align: bottom;
`;

const SearchResultsOuterBox = styled.div`
  background: rgba(13, 78, 149, 0.28);
`;

const SearchResults = styled.div`
	position: absolute;
	margin-top: 5px;
	width: 550px;
	min-height: 40px;
	max-height: 350px;
	border-radius: 2px;
	box-shadow: 5px 5px 10px 10px rgba(153, 143, 143, 0.42);
	overflow: auto;	
	background: #FFFFFF;
	z-index: 1000;
	border: 2px solid rgb(140, 170, 202);
`;

const SpinnerContainer = styled.div`	
  display: flex;
  justify-content: center;
  align-items: center;
	height: 200px;	
`;

const NoResultsStyled = styled.div`  
  height: 200px;
  padding: 1rem;
`;

const NoResultsTitleStyled = styled.div`  
  font-size: 2em;
  padding-bottom: 2rem;    
`;

const ProductCodeRow = styled.div`
	width: 100%;
	height: 50px;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 10px;	
	font-family: "Gotham SSm A", "Gotham SSm B";
	display: flex;
	align-items: center;
    cursor: pointer;
	background-color: ${props => props.alternateRow ? props.theme.color.grayBackground : `inherit`};
`;

const ProductCodeDetailsColumn = styled.div`	
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 1rem;
	padding-right: 0px;	
`;

// #endregion

// #region Component
const ProductCodeSearchStep = ({
    dispatch: reduxDispatch,
    token,
    vehicleId,
    languageCode,
    complaintCode,
    ataParametersSorted,
    intl: { formatMessage },
    onProductCodeSelect,
    selectedProductCode,
    onContinue,
    onClose,
    stepNumber,
    itemsAddedToPo,
    vendorId,
    addNewLineItemsErrorMessage,
    lineItemMissingProductCode,
    missingProductCodeInformationStepNeeded
}) => {
    // #region State
    const [searchTerm, setSearchTerm] = useState();
    const debouncedSearchTerm = useDebounce(searchTerm, 500);  // https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
    const [searchingProductCodes, setSearchingProductCodes] = useState(false);
    const [productCodes, setProductCodes] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [pageErrors, setPageErrors] = useState();
    // #endregion

    // #region Ref
    const dropDownNode = useRef();
    const firstSearchResultNode = useRef();
    const searchInput = useRef();
    // #endregion

    // #region Effects
    // Save the selected product code for rest of the steps to use
    useEffect(() => {
        if (selectedProductCode != undefined) {
            let selectedATACode = Map({
                type: constants.UPDATE_ATA_CODE_TYPE.SEARCHED_ATA_CODE,
                ataCode: selectedProductCode.ProductCode,
                vendorProductCode: selectedProductCode.vendorProductCode,
                vendorProductDescription: selectedProductCode.vendorProductDescription,
            });

            reduxDispatch(addNewLineItemsViewActions.updateATACode(selectedATACode));
            reduxDispatch({ type: constants.ATA_PARAMETERS_SCREEN_LOADED, productCode: selectedProductCode.ProductCode });

            // TODO
            let temp = selectedProductCode;
            temp.correctionAndRepairTypesRestricted = selectedProductCode.correctionAndRepairTypesRestricted;
            temp.code = selectedProductCode.ProductCode;
            temp.description = selectedProductCode.vendorProductDescription;
            temp.laborUnitPrice=selectedProductCode.laborUnitPrice;
            temp.partUnitPrice=selectedProductCode.partUnitPrice;
            temp.partPricingSource=selectedProductCode.partPricingSource;
            temp.laborPricingSource=selectedProductCode.laborPricingSource;

            reduxDispatch({ type: constants.LOAD_SEARCHED_ATA_CODES_DATA, serviceResult: [temp] });
        }
    }, [selectedProductCode]);

    useEffect(() => {
        if(selectedProductCode != undefined)
            searchInput.current.value = `${selectedProductCode.vendorProductCode} - ${selectedProductCode.vendorProductDescription}`;
        else
            searchInput.current.value = '';
    },[selectedProductCode]);

    // Handle user clicking outside dropdown.
    useEffect(() => {
        if (dropdownVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownVisible]);

    // Search for product codes.
    useEffect(() => {
        let didCancel = true;   
        if (debouncedSearchTerm === undefined
            || debouncedSearchTerm === ''
            || debouncedSearchTerm.length < 3) {
            setProductCodes([]);
            setDropdownVisible(false);
        }
        else {
            const fetchData = async () => {
                try {
                    setDropdownVisible(true);
                    setSearchingProductCodes(true);
                    setPageErrors();
                    const response = await Api.getVendorProductCodesBySearchKey(vehicleId, vendorId, debouncedSearchTerm, token,languageCode)
                    const productCodes = response.length === 0 ? [] : response;

                    setSearchingProductCodes(false);

                    // If the search box is empty - user cleared out the search term    
                    if (didCancel && searchInput.current.value!= "") {
                        setDropdownVisible(true);
                        setProductCodes(productCodes);
                    }
                    else
                    {
                        setProductCodes([]);
                        setDropdownVisible(false);
                    }
                }
                catch (error) {
                    setPageErrors(helperFunctions.getErrorObject(error));
                    setSearchingProductCodes(false);
                }
            }
            fetchData();
        }

        return () => {
            didCancel = false;
        };
    }, [debouncedSearchTerm, token, vehicleId,languageCode]);
    // #endregion

    // #region Event handlers
    const handleClickOutside = e => {
        if (dropDownNode.current.contains(e.target)) {
            // inside click      
        }
        else {
            // outside click
            setDropdownVisible(false);
        }
    };

    const handleSearchTermChange = (e) => {
        // reset product code that was previosuly selected    
        onProductCodeSelect();

        if (e.target.value == "") {
            setDropdownVisible(false);
        }

        setSearchTerm(e.target.value.trim());
    }

    const handleSearchClick = () => {
        if (searchTerm != "" && searchTerm != undefined && searchTerm.length >= 3) {
            setDropdownVisible(true);
        }
    }

    const handleSearchKeyDown = e => {
        switch (e.key) {
            case "ArrowDown":
            case "Enter":
                if (firstSearchResultNode?.current !== undefined && firstSearchResultNode.current !== null) {
                    firstSearchResultNode.current.focus();
                    e.preventDefault();
                }
                if (!!e.target.value && e.target.value.length >= 3)
                    setDropdownVisible(true);
                break;
        }
    }

    const handleProductCodeKeyDown = (index, productCode) => e => {
        switch (e.key) {
            case "ArrowDown":
                if (e.target.nextSibling !== undefined)
                    e.target.nextSibling.focus();
                break;

            case "ArrowUp":
                if (e.target.previousElementSibling !== undefined)
                    e.target.previousElementSibling.focus();
                break;

            case "Enter":
                handleProductCodeSelected(productCode);
                break;

            case "Escape":
                setDropdownVisible(false);
                break;
        }
    }

    const handleProductCodeClick = (productCode) => {
        handleProductCodeSelected(productCode);
    }

    const handleProductCodeSelected = (productCode) => {
        setDropdownVisible(false);

        // let parent know 
        onProductCodeSelect(productCode);
    }

    const highlightWord = (word) => {
        const escapeRegExp = (str = '') => (
            str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
          );
        let result = '';
        for (let wordSection of word.split(' ')) {
            for (let term of searchTerm.split(' ')) {
                let termRegex = new RegExp(`(${escapeRegExp(term)})`, 'i');
                let match = termRegex.exec(wordSection);
                if (match != undefined && match[1]) {
                    wordSection = wordSection.replace(termRegex, `<span class="ata-search-keywork-highlight" >${match[1]}</span>`);
                    break;
                }
            }
            result += wordSection + ' ';
        }
        return result;
    }
    // #endregion

    // #region International messages
    const messages = defineMessages({
        close_dialog: {
            id: "close_dialog",
            description: "  Close",
            defaultMessage: "Close"
        },
        search_product_code_caption: {
            id: "search_product_code_caption",
            description: "Find and select product code",
            defaultMessage: "Find and select product code"
        },
        search_product_code_placeholder: {
            id: "search_product_code_placeholder",
            description: "Search by product code or description",
            defaultMessage: "Search by product code or description"
        },
        product_code_search_continue: {
            id: "product_code_search_continue",
            description: "Continue",
            defaultMessage: "Continue"
        },
        product_code_search_none_found: {
            id: "product_code_search_none_found",
            description: "No results found",
            defaultMessage: "No results found"
        },
        add_new_line_item_caption: {
            id: 'add_new_line_item_caption',
            description: 'Add new line item caption',
            defaultMessage: 'Add {complaint_description} items'
        },
        empty_parameters: {
            id: 'empty_parameters',
            description: 'Description when parameters are empty',
            defaultMessage: 'None at this time'
        },
        parameters_header: {
            id: 'parameters_header',
            description: 'Header for ata parameters ',
            defaultMessage: 'Parameters '
        },
        items_added: {
            id: 'items_added',
            description: 'items_added',
            defaultMessage: 'Items added to PO:'
        }, 
        missing_product_code: {
            id: 'missing_product_code',
            description: 'Missing product code',
            defaultMessage: ' for <line_description></line_description>',
            values: { line_description: data => <b>{lineItemMissingProductCode}</b> }
        },
    });
    // #endregion

    // #region Render helpers
    const renderCloseButton = () => (
        <ModalClose role='button' onClick={() => {
            onClose();
        }}>
            <img src="/assets/VMS_33button_kill_blue.png" />
            {"  "} {<FormattedMessage {...messages.close_dialog} />}
        </ModalClose>
    );

    function renderSearchResults() {
        return (<>
            <SearchResultsOuterBox>
                <SearchResults>

                    {searchingProductCodes ?
                        <SpinnerContainer>
                            <Spinner spinnerType="blue" manualPositioning={true} />
                        </SpinnerContainer>

                        : productCodes === undefined || productCodes.length === 0
                            ? <NoResultsStyled>
                                <NoResultsTitleStyled>
                                    <FormattedMessage {...messages.product_code_search_none_found} />
                                </NoResultsTitleStyled>
                            </NoResultsStyled>

                            : productCodes.map((productCode, index) => {
                                let refProp = {};
                                if (index === 0) refProp['ref'] = firstSearchResultNode; // Get reference to first node.
                                return (
                                    <ProductCodeRow
                                        key={productCode.vendorProductCode}
                                        tabIndex={index + 1}
                                        alternateRow={index % 2 === 0}
                                        {...refProp}
                                        onKeyDown={handleProductCodeKeyDown(index, productCode)}
                                        onClick={() => handleProductCodeClick(productCode)}
                                    >
                                        <ProductCodeDetailsColumn>
                                            <span dangerouslySetInnerHTML={{ __html: highlightWord(`${productCode.vendorProductCode} - ${productCode.vendorProductDescription}`) }}></span>                              
                                        </ProductCodeDetailsColumn>
                                    </ProductCodeRow>
                                )
                            })}

                </SearchResults>
            </SearchResultsOuterBox>
            </>
        );
    }

    const renderContinueButton = () => (
        <ButtonStyled
            role="button"
            onClick={() => {if (selectedProductCode !== undefined) onContinue()}}
            disable={selectedProductCode === undefined}
        >
            <FormattedMessage {...messages.product_code_search_continue} />
            {" "}
            <img src="/assets/VMS_33button_go_bblue.png" />
        </ButtonStyled>
    );

    const productCodeParams = ataParametersSorted == undefined ? '' :
        ataParametersSorted.map((param) => {
            return <ClientParametersComponent key={param.get('id')}
                description={param.get('description')}
            />
        });

    const renderParameters = () => (
        <>
        <Row>
            <ParametersHeader>
                <FormattedMessage {...messages.parameters_header} />
            </ParametersHeader>
        </Row>
        <Row> 
            <FormBodyText>
            {productCodeParams.size === 0 ?
                <p>
                    <FormattedMessage {...messages.empty_parameters} />
                </p>
                :
                <>{productCodeParams}</>
            }
            </FormBodyText>
        </Row>  
        </> 
    );

    // #endregion  

    // #region Render
    return (
        <Main>

            <FormWrapper
                key="pageErrors"
                id="pageErrors"
                formErrors={pageErrors}
            />

            <ButtonRowStyled>
                {renderCloseButton()}
                <div></div>
            </ButtonRowStyled>


            <ModalMainHeader>
                {complaintCode.get('id') !== undefined ?
                    <FormattedMessage
                        values={{
                            complaint_description:
                                <FormattedMessage
                                    id={complaintCode.get('id')}
                                    description={complaintCode.get('description')}
                                    defaultMessage={complaintCode.get('description')} />
                        }}
                        {...messages.add_new_line_item_caption} />
                    : false}
            </ModalMainHeader>

            {itemsAddedToPo != undefined && itemsAddedToPo.size > 0 ?
                <>
                    <NewlyAddedItemsHeader>
                        <FormattedMessage {...messages.items_added} /></NewlyAddedItemsHeader>
                    {
                        itemsAddedToPo.map(item => {
                            return <div>{item.get('productDescription')}</div>;
                        }).valueSeq()}
                </>
                : false
            }


            <ModalBodyTable>
                <StepNumber number={stepNumber}/>
                
                <Header>
                    <FormattedMessage {...messages.search_product_code_caption} />
                    {missingProductCodeInformationStepNeeded ? <FormattedMessage {...messages.missing_product_code} /> : false}
                </Header>
           
                <Dropdown ref={dropDownNode} >
                    <SearchInput
                        id="product_code_step_search_input"
                        ref={searchInput}
                        type="text"
                        placeholder={formatMessage(
                            messages.search_product_code_placeholder
                        )}
                        autocomplete="off"
                        tabIndex="0"
                        onChange={handleSearchTermChange}
                        onKeyDown={handleSearchKeyDown}
                    />
                    <SearchIcon
                        type="image"
                        src="/assets/vms_search_glass.png"
                        alt="Search"
                        onClick={handleSearchClick}
                    />

                    {selectedProductCode != undefined ?
                        <SearchIcon
                            type="image"
                            src="/assets/VMS_33button_kill_clear_inline.png"
                            alt="Clear Search"
                            onClick={() => onProductCodeSelect()}
                        /> : false}

                    {dropdownVisible ? renderSearchResults() : false}

                </Dropdown>

                <Row></Row>
                <Row></Row>
                <Row></Row>
            </ModalBodyTable>

            <ButtonRowStyled>
                <div></div>
                {renderContinueButton()}
            </ButtonRowStyled>

            {renderParameters()}

        </Main>
    );
    // #endregion
};
// #endregion

export default connect()(injectIntl(ProductCodeSearchStep));