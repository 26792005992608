import { Map, fromJS } from 'immutable';
import * as sharedActions from 'actions/App';
import * as constants from 'constants/App';
import * as api from 'utils/CallAPI';
import * as VMSCookie from 'utils/CookieHelper';
import jwtDecode from 'jwt-decode';
import * as configuration from 'configuration';

export function signInClicked(userId,password,createCookieOnSuccess) {
    return {type: constants.SIGN_IN_CLICKED, userId,password,createCookieOnSuccess}
}

export function clearLoginErrorMessage() {
    return {type: constants.CLEAR_LOGIN_ERROR_MESSAGE}
}
