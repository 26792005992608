// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import moment from 'moment';

import './VehicleComponent.css';
import CheckCircle from 'components/CheckCircle';
import * as craneInspectionEnums from 'PO/ClosePO/screens/CraneInspection/data/CraneInspectionEnums';

class VehicleComponent extends React.Component {
	handleDeficienciesTextChange = (event) => {
		this.props.onComponentDetailsChanged(this.props.componentId, 'componentFailureReasons', event.target.value);
	}

	handleComponentResultCodeClick = (name, checked) => {
		if (!checked)
			this.props.onComponentDetailsChanged(this.props.componentId, 'componentResultCode', name);
		else
			this.props.onComponentDetailsChanged(this.props.componentId, 'componentResultCode', null);
	}

	render() {
		const { showPass = true, showFail = true } = this.props;

		const messages = defineMessages({
			crane_inspection__componentResultCode_pass: {
				id: 'crane_inspection__componentResultCode_pass',
				description: 'Pass',
				defaultMessage: 'Pass'
			},
			crane_inspection__componentResultCode_fail: {
				id: 'crane_inspection__componentResultCode_fail',
				description: 'Fail',
				defaultMessage: 'Fail'
			},
			crane_inspection__componentResultCode_na: {
				id: 'crane_inspection__componentResultCode_na',
				description: 'N/A',
				defaultMessage: 'N/A'
			}
		});


		if (!this.props.componentDetails && (showPass || showFail))
			return null;

		return (
			<div className='crane-inspection-vehicle-component-flex-row'>
				<div className='crane-inspection-vehicle-component-flex-col'>
					{this.props.componentName}
				</div>
				<div className='crane-inspection-vehicle-component-flex-col' tabIndex='0'>
					{showPass &&
						<CheckCircle
							name={craneInspectionEnums.componentResultCode.PASS}
							className='crane_inspection_vehicle_component__check_circle'
							checked={this.props.componentDetails.get('componentResultCode') === craneInspectionEnums.componentResultCode.PASS}
							onClick={this.handleComponentResultCodeClick}
						/>
					}
				</div>
				<div className='crane-inspection-vehicle-component-flex-col' tabIndex='0'>
					{showFail &&
						<CheckCircle
							name={craneInspectionEnums.componentResultCode.FAIL}
							className='crane_inspection_vehicle_component__check_circle'
							checked={this.props.componentDetails.get('componentResultCode') === craneInspectionEnums.componentResultCode.FAIL}
							onClick={this.handleComponentResultCodeClick}
						/>
					}
				</div>
				<div className='crane-inspection-vehicle-component-flex-col' tabIndex='0'>
					{showFail &&
						<CheckCircle
							name={craneInspectionEnums.componentResultCode.NA}
							className='crane_inspection_vehicle_component__check_circle'
							checked={this.props.componentDetails.get('componentResultCode') === craneInspectionEnums.componentResultCode.NA}
							onClick={this.handleComponentResultCodeClick}
						/>
					}
				</div>
				<div className='crane-inspection-vehicle-component-flex-col'>
					<input
						id='deficiencies'
						className='crane_inspection_vehicle_component__deficiencies_text'
						maxLength='100'
						onBlur={this.handleDeficienciesTextChange}
					/>
				</div>
			</div>
		)
	}
}

export default VehicleComponent;