import ReactPaginate from "react-paginate";
import React from "react";
import styled from "styled-components";
import {defineMessages, FormattedMessage} from 'react-intl';
import {
    Label,
    Input,
    InputFeedback,
    Image,
    InputImage,
    Row,
    Col, LabelGrey
  } from "DesignSpecs/components/FormComponents";

  const LeftDiv = styled.div`
    align: left;
    float: left;
	flex-wrap: wrap;
	justify-content: space-around;		
`;

const RightDiv = styled.div`
align: right;
float: right;
	flex-wrap: wrap;
	justify-content: space-around;
`;




const Pagination = ({ initialPage, forcePage, pageCount, showMessage, messageText, handlePageClick }) => {

    const messages = defineMessages({
        pagination_prev_text: {
            id: 'pagination_prev_text',
            description: 'Previous',
            defaultMessage: 'Previous'
        },
        pagination_next_text: {
            id: 'pagination_next_text',
            description: 'Next',
            defaultMessage: 'Next'
        },});

  return (
    <Row>
      <Col width="100%">     
       
        <RightDiv>
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            initialPage={initialPage==null || initialPage==undefined ? 0:initialPage}
            forcePage={forcePage==null?false:forcePage}
          />
        </RightDiv>
      </Col>
    </Row>
  );
};

export default Pagination;
