import React,{Fragment} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import * as constants from 'constants/App';
import './VendorContactInformation.css';
import {emptyStringIfUndefined} from 'utils/HelperFunctions';
import { useAppState } from 'shared/AppState/app-state';

const VendorContactInformation = (props) => {

        const [ appState, dispatch ] = useAppState();

        const messages = defineMessages({
            name_information_title: {
                id: 'name_information_title',
                description: 'Title for name section',
                defaultMessage: 'Business Name'
            },
            position_information_title: {
                id: 'position_information_title',
                description: 'Title for position section',
                defaultMessage: 'Position'
            },
            edit_contact_information: {
                id: 'edit_contact_information',
                description: 'Button for editing contact information',
                defaultMessage: 'Edit contact information settings'
            },
            vendor_business_phone_number: {
                id: 'vendor_business_phone_number',
                description: 'vendor business number title',
                defaultMessage: 'Business'
            },
            vendor_extension_number: {
                id: 'vendor_extension_number',
                description: 'vendor extension number title',
                defaultMessage: 'Extension'
            },
            vendor_fax_number: {
                id: 'vendor_fax_number',
                description: 'vendor fax number title',
                defaultMessage: 'Fax'
            },
            contact_information_title: {
                id: 'contact_information_title',
                description: 'Title for contact information section',
                defaultMessage: 'Contact Information'
            },
            location_title: {
                id: 'location_title',
                description: 'Title for location section',
                defaultMessage: 'Location'
            },
            contact_title: {
                id: 'contact_title',
                description: 'Title for contact section',
                defaultMessage: 'Contact Name'
            },
            email_recipient_title: {
                id: 'email_recipient_title',
                description: 'Title for email section',
                defaultMessage: 'Payment Email Recipient'
            }


        });

        return <div>
            <div className="vendor-contact-info__view row">
                <div className="col-sm-8  vendor-contact-component__title3">
                    <FormattedMessage {...messages.contact_information_title}/>
                </div>
            </div>
            <div className="vendor-contact-component__view row">
                <div className="col-sm-3 vendor-contact-component__title">
                    <FormattedMessage {...messages.name_information_title}/>
                </div>
            </div>
            <div className="vendor-contact-component__view-row2 row">
                <div className="col-sm-6 vendor-contact-component__text">
                    {props.businessName}
                </div>
            </div>
            <div className="vendor-contact-component__view row">
                <div className="col-sm-3 vendor-contact-component__title">
                    <FormattedMessage {...messages.location_title}/>
                </div>
            </div>
            <div className="vendor-contact-component__view2">
                <div className="vendor-contact-component__view row">
                    <div className="vendor-contact-component__title2">
                        {props.businessAddress}
                    </div>
                </div>
                <div className="vendor-contact-component__view-row2 row">
                    <div className="vendor-contact-component__title2">
                        {props.businessCity}, {props.businessState} {props.businessZip}
                    </div>
                </div>
            </div>
            <div className="vendor-contact-component__view row">
                <div className="col-sm-3 vendor-contact-component__title">
                    <FormattedMessage {...messages.contact_title}/>
                </div>
            </div>
            <div className="vendor-contact-component__view-row2 row">
                <div className="col-sm-6 vendor-contact-component__text">
                    {props.contactNamePayment}
                </div>
            </div>
            <div className="vendor-contact-component__view row">
                <div className="col-sm-3 vendor-contact-component__title">
                    <FormattedMessage {...messages.vendor_business_phone_number}/>
                </div>
                <div className="col-sm-3 vendor-contact-component__title">
                    <FormattedMessage {...messages.vendor_extension_number}/>
                </div>
            </div>
            <div className="vendor-contact-component__view-row2 row">
                <div className="vendor-contact-component__text">
                    {props.businessPhone.substring(0, 3)}-{props.businessPhone.substring(3, 6)}-{props.businessPhone.substring(6, 10)}
                </div>
                <div className="col-sm-1 vendor-contact-info__extension-spacing"/>
                <div className="vendor-contact-component__text4">
                    {props.businessExtension}
                </div>
            </div>
            <div className="vendor-contact-component__view row">
                <div className="col-sm-3 vendor-contact-component__title">
                    <FormattedMessage {...messages.vendor_fax_number}/>
                </div>
            </div>
            <div className="vendor-contact-component__view-row2 row">
            {                        
                props.businessFax!=undefined && props.businessFax.length > 0 ?

                <div className="col-sm-3 vendor-contact-component__text">
                    {props.businessFax.substring(0, 3)}-{props.businessFax.substring(3, 6)}-{props.businessFax.substring(6, 10)}                            
                </div>
                :false
            }
            </div>
            {(props.isLinkedVendor || props.isSSOUser || props.country=='MEX') &&
            <Fragment>
            <div className="vendor-contact-component__view row">
                <div className="col-sm-5 vendor-contact-component__title">
                    <FormattedMessage {...messages.email_recipient_title}/>
                </div>
            </div>
            <div className="vendor-contact-component__view-row2 row">
                <div className="col-sm-6 vendor-contact-component__text">
                     {props.contactEmailPayment}
                </div>
            </div>
            </Fragment>
            }
            {appState.accountSettingPermissions.includes("ED") && 
            <div className="vendor-contact-component__view3 row">

                    <a className="vendor-contact-component__caption3"
                       onClick={props.onChangeContactClick.bind(this)}>
                        <FormattedMessage {...messages.edit_contact_information}/>{' '}
                        <img src="/assets/VMS_28button_edit_blue.png"/>
                    </a>


            </div>
            }
            <hr className="vendor-contact-info__hr"/>
        </div>
    }



export default(VendorContactInformation);