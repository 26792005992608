import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Map} from 'immutable';
import './Units.css';
import {emptyMapIfUndefined, falseIfUndefined} from 'utils/HelperFunctions';
import Submit from 'components/Submit';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import classnames from 'classnames';
import { useAppState } from 'shared/AppState/app-state';

const Units = (props) => {    

    const [appState, dispatch] = useAppState();


    const showModalHandler=(id)=>{

        props.onRejectClickHandler(id)
    }

    const getTooltip=(toolTipText)=> {
        return <Tooltip id="tooltip"> {toolTipText} </Tooltip>;
    }

    const getVehicle=(id, vehicleYear, vehicleMake, vehicleModel)=> {

        return <OverlayTrigger
            placement='right'
            key={id}
            overlay={getTooltip((vehicleYear == null ? '' : vehicleYear)
                + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel))}
        >
            <div key={id}>
                {(vehicleYear + ' ' + vehicleMake + ' ' + vehicleModel).length > 10
                    ? ((vehicleYear == null ? '' : vehicleYear)
                    + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel)).substr(0, 9) + '...'
                    : ((vehicleYear == null ? '' : vehicleYear)
                    + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel))
                }
            </div>
        </OverlayTrigger>

    }

    const getCustomer=(id, clientName)=> {

        return <OverlayTrigger
            placement='right'
            key={id}
            overlay={getTooltip((clientName == null ? '' : clientName))}
        >
            <div key={id}>
                {(clientName).length > 10
                    ? ((clientName == null ? '' : clientName)).substr(0, 9) + '...'
                    : ((clientName == null ? '' : clientName))
                }
            </div>
        </OverlayTrigger>

    }


    
        const messages = defineMessages({
            my_units_service_due: {
                id: 'my_units_service_due',
                description: 'service due caption',
                defaultMessage: 'Services due'
            },
            my_units_email_driver: {
                id: 'my_units_email_driver',
                description: 'email driver caption',
                defaultMessage: 'Email driver'
            },
            my_units_client_name: {
                id: 'my_units_client_name',
                description: 'client name caption',
                defaultMessage: 'Customer name'
            },
            my_units_vehicle: {
                id: 'my_units_vehicle',
                description: 'year caption',
                defaultMessage: 'Vehicle'
            },

            my_units_vin8: {
                id: 'my_units_vin8',
                description: 'vin8 caption',
                defaultMessage: 'VIN no.'
            },
            my_units_reject_unit: {
                id: 'my_units_reject_unit',
                description: 'reject caption',
                defaultMessage: 'Reject unit'
            },
            show_more: {
                id: 'show_more',
                description: 'Show more',
                defaultMessage: 'Show more'
            },
        });

        return <div>
                    <div className="my_units__table_pad">
                        <table className="my_units__table">
                            <thead>
                            <tr >
                                <th className="my_units__header my_units__table_text2 col-sm-1">
                                    <FormattedMessage {...messages.my_units_service_due}/>
                                </th>
                                {/* <th className="my_units_hp__header my_units_hp__table_text">
                                 <FormattedMessage {...messages.my_units_email_driver}/>
                                 </th>*/}
                                <th className="my_units__header my_units__table_text">
                                    <FormattedMessage {...messages.my_units_client_name}/>
                                </th>
                                <th className="my_units__header my_units__table_text">
                                    <FormattedMessage {...messages.my_units_vehicle}/>
                                </th>
                                <th className="my_units__header my_units__table_text">
                                    <FormattedMessage {...messages.my_units_vin8}/>
                                </th>
                                <th className="my_units__header my_units__table_text3  col-sm-1">
                                  {["CR","ED"].some(p => appState.poPermissions.includes(p))?
                                    <FormattedMessage {...messages.my_units_reject_unit}/>
                                    :false}
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            { props.units.valueSeq().map((units) =>
                                <tr key={ units.get('id')}>
                                    <td className="my_units__item3">
                                        <a onClick={units.get('pmStatus') === "OverDue" || units.get('pmStatus') === "Approaching" || units.get('pmStatus') === "Due" ? props.onServiceDueClickHandler.bind(this, units.get('id'), units.get('vin8')) : false} >
                                            <img className={classnames(units.get('pmStatus') === "OverDue" || units.get('pmStatus') === "Approaching" || units.get('pmStatus') === "Due"
                                                ?"my_units__service_img" : "my_units__service_img disabled")}
                                                 src={units.get('pmStatus') === "OverDue" || units.get('pmStatus') === "Approaching" || units.get('pmStatus') === "Due"
                                                     ?units.get('pmStatus') === "OverDue" || units.get('pmStatus') === "Due" ? "/assets/Overdue_Service1.png" : "/assets/Overdue_Service2.png" : "/assets/Overdue_Service3.png"}/>
                                        </a>
                                    </td>
                                    {/* <td className="my_units_hp__item">
                                     <a onClick={onEmailClickHandler.bind(this)}>
                                     <img className={units.get('emailed') ? "" : "my_units_hp__email_img disabled"}
                                     src="/assets/VMS_34button_mail_blue.png"/>
                                     </a>
                                     </td>
                                     */}
                                    <td className="my_units__item">
                                        {getCustomer(units.get('id'), units.get('clientName'))}
                                    </td>
                                    <td className="my_units__item">
                                        {getVehicle(units.get('id'), units.get('year'), units.get('make'), units.get('model'))}
                                    </td>
                                    <td className="my_units__item">
                                        {units.get('vin8') }
                                    </td>
                                    <td className="my_units__item2">
                                        {["CR","ED"].some(p => appState.poPermissions.includes(p))?
                                        <a
                                           onClick={showModalHandler.bind(this,units.get('id'))}>
                                            <img src="/assets/VMS_28button_delete_blue.png"/>
                                        </a>:false}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <div className="my_units__show-more row form-group">
                        <div className="col-sm-8"/>
                        <div className="col-sm-3">
                            {
                                props.nextUnitsExist == false  ?
                                    false
                                    :
                                    <div className="my_units__show-more-caption">
                                        <Submit caption={<FormattedMessage {...messages.show_more}/>}
                                                imagePath="/assets/VMS_33button_go_bblue_down.png"
                                                onClickHandler={props.fetchMoreUnitsClicked.bind(this)}/>
                                    </div>
                            }
                        </div>
                    </div>
                {/* Could be used for future use
                <div>
                    <div className="my_units__table_pad">
                        <table className="my_units__table">
                            <thead>
                            <tr>
                                <th className="my_units__header my_units__table_text">
                                    <FormattedMessage {...messages.my_units_client_name}/>
                                </th>
                                <th className="my_units__header my_units__table_text">
                                    <FormattedMessage {...messages.my_units_vehicle}/>
                                </th>
                                <th className="my_units__header my_units__table_text">
                                    <FormattedMessage {...messages.my_units_vin8}/>
                                </th>
                            </tr>
                            </thead>

                            <tbody>

                            { props.units.valueSeq().map((units) =>
                                <tr key={ units.get('id')}>
                                    <td className="my_units__item">
                                        {getCustomer(units.get('id'), units.get('clientName'))}
                                    </td>
                                    <td className="my_units__item">
                                        {getVehicle(units.get('id'), units.get('year'), units.get('make'), units.get('model'))}
                                    </td>
                                    <td className="my_units__item">
                                        {units.get('vin8') }
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <div className="my_units__show-more row form-group">
                        <div className="col-sm-8"/>
                        <div className="col-sm-3">
                            {
                                props.nextUnitsExist == false ?
                                    false
                                    :
                                    <div className="my_units__show-more-caption">
                                        <Submit caption={<FormattedMessage {...messages.show_more}/>}
                                                imagePath="/assets/VMS_33button_go_bblue_down.png"
                                                onClickHandler={props.fetchMoreUnitsClicked.bind(this)}/>
                                    </div>
                            }
                        </div>
                    </div>
                </div>*/}
        </div>;
    }



export default (Units);