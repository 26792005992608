//libraries
import React, { useState, useRef } from 'react';
import { defineMessages, FormattedMessage, RawIntlProvider, injectIntl, IntlProvider, FormattedNumber } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import './AdditionalVehicleDetails.css';
import { Popover, Overlay } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import * as helperFunctions from 'utils/HelperFunctions';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from '../../../../shared/utils/MomentLocaleUtils';
import { Formik } from "formik";
import * as Yup from "yup";
import FormWrapper from "components/FormWrapper";
import Spinner from "components/Spinner";
import Help from 'components/Help';


// #region Component
function AdditionalVehicleDetails({ userLocale,
    usStates,
    tempLicensePlate,
    tempRegistrationState,
    licensePlate,
    registrationState,
    intl,
    whoWillLicense,
    registrationExpiration,
    deliveryDate,
    tempRegistrationExpDate,
    requestStatus,
    vendorCheckingAccount,
    subTotal,
    selectedCurrency,
    onReturnClickHandler,
    onDeleteResetClick,
    onPaymentRequestedHandler,
    vendorId }) {


    // #region Ref
    const noRegisterDetailsSelectionShowErrorRef = useRef();
    const registerDetailsSelectionShowErrorRef = useRef();
    const deliveryDateShowErrorRef = useRef();
    const licenseDateShowErrorRef = useRef();
    const tempDateShowErrorRef = useRef();
    const paymentInformationAcceptedShowErrorRef = useRef();
    // #endregion

    // #region Event handlers     
    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        
        let paymentInformation = {
            deliveryDate: values.deliveryDate != undefined ? values.deliveryDate : undefined,
            licensePlate: values.licensePlate,
            licenseDate: values.licenseDate != undefined ? values.licenseDate : undefined,
            licenseState: values.licenseState,
            tempPlate: values.tempPlate,
            tempDate: values.tempDate != undefined ? values.tempDate : undefined,
            tempState: values.tempState,
            vendorId: vendorId

        };

        onPaymentRequestedHandler(paymentInformation);
        setSubmitting(false);

    };

    // #endregion   

    // #region International message

    const messages = defineMessages({


        dealer_draft_delivery_date_description: {
            id: 'dealer_draft_delivery_date_description',
            description: 'dealer_draft_delivery_date_description',
            defaultMessage: 'Delivery date'
        },
        dealer_draft_license_plate_description: {
            id: 'dealer_draft_license_plate_description',
            description: 'dealer_draft_license_plate_description',
            defaultMessage: 'License plate#'
        },
        dealer_draft_registration_date_description: {
            id: 'dealer_draft_registration_date_description',
            description: 'dealer_draft_registration_date_description',
            defaultMessage: 'Registration expiration'
        },
        dealer_draft_Registration_state_description: {
            id: 'dealer_draft_Registration_state_description',
            description: 'dealer_draft_Registration_state_description',
            defaultMessage: 'Registration state'
        },
        dealer_draft_reg_no_selection_label: {
            id: 'dealer_draft_reg_no_selection_label',
            description: 'None of the above',
            defaultMessage: 'None of the above'
        },
        dealer_draft_reg_label: {
            id: 'dealer_draft_reg_label',
            description: 'Registration information',
            defaultMessage: 'Registration information'
        },
        dealer_draft_temp_license_label: {
            id: 'dealer_draft_temp_license_label',
            description: 'Temp license plate #',
            defaultMessage: 'Temp license plate #'
        },
        dealer_draft_temp_exp_label: {
            id: 'dealer_draft_temp_exp_label',
            description: 'Temp registration expiration',
            defaultMessage: 'Temp registration expiration'
        },
        dealer_draft_temp_state_label: {
            id: 'dealer_draft_temp_state_label',
            description: 'Temp registration state',
            defaultMessage: 'Temp registration state'
        },
        dealer_draft_initial_license_label: {
            id: 'dealer_draft_initial_license_label',
            description: 'Initial licensing issued',
            defaultMessage: 'Initial licensing issued'
        },
        dealer_draft_temp_label: {
            id: 'dealer_draft_temp_label',
            description: 'Temp Tag issued',
            defaultMessage: 'Temp Tag issued'
        },
        dealer_draft_reg_details_label: {
            id: 'dealer_draft_reg_details_label',
            description: 'Registration details',
            defaultMessage: 'Registration details'
        },
        dealer_draft_reg_details_error_label: {
            id: 'dealer_draft_reg_details_error_label',
            description: 'Please complete all fields below',
            defaultMessage: 'Please select one of the fields below'
        },
        dealer_draft_reg_details_select_label: {
            id: 'dealer_draft_reg_details_select_label',
            description: 'Select one',
            defaultMessage: 'Select one'
        },
        dealer_draft_no_selection_error_label: {
            id: 'dealer_draft_no_selection_error_label',
            description: 'dealer_draft_no_selection_error_label',
            defaultMessage: 'Our records indicate that your dealership is to complete initial licensing. Before proceeding, please contact us at delivery@holman.com or 800-257-7781 for further assistance.'
        },
        dealer_draft_delivery_date_error_label: {
            id: 'dealer_draft_delivery_date_error_label',
            description: 'Please select a date',
            defaultMessage: 'Please select a date'
        },
        dealer_draft_delivery_date_invalid_error_label: {
            id: 'dealer_draft_delivery_date_invalid_error_label',
            description: 'Please provide a past date',
            defaultMessage: 'Please provide a past date'
        },
        dealer_draft_reg_date_invalid_error_label: {
            id: 'dealer_draft_reg_date_invalid_error_label',
            description: 'Please provide a future date',
            defaultMessage: 'Please provide a future date'
        }, dealer_draft_confirm_payment: {
            id: 'dealer_draft_confirm_payment',
            description: 'dealer_draft_confirm_payment',
            defaultMessage: 'Confirm the payment will be credited to account ending in {checkingAccount}'
        },
        dealer_draft_payment_accepted_error: {
            id: 'dealer_draft_payment_accepted_error',
            description: 'dealer_draft_payment_accepted_error',
            defaultMessage: 'Please confirm payment before requesting approval.'
        }, return: {
            id: 'return',
            description: 'Return',
            defaultMessage: 'Return'
        },
        request_payment: {
            id: 'request_payment',
            description: 'request_payment',
            defaultMessage: 'Request Payment '
        },
        restore_request: {
            id: 'restore_request',
            description: 'restore_request',
            defaultMessage: 'Restore request'
        },

    });
    // #endregion   

    // #region Render
    return <div>
        <Formik
            enableReinitialize={true}
            initialValues={{
                deliveryDate: deliveryDate !== null ?
                    new Date(moment(deliveryDate)) : undefined,
                licensePlate: licensePlate != null ? licensePlate : '',
                licenseDate: registrationExpiration !== null ?
                    new Date(moment(registrationExpiration)) : undefined,
                licenseState: registrationState != null ? registrationState : '',
                tempPlate: tempLicensePlate != null ? tempLicensePlate : '',
                tempDate: tempRegistrationExpDate !== null ?
                    new Date(moment(tempRegistrationExpDate)) : undefined,
                tempState: tempRegistrationState != null ? tempRegistrationState : '',
                registrationInformationSelection: null,
                paymentAccepted: '',
                noRegisterDetailsError: null,
                deliveryDateRange: null,
                licenseDateRange: null,
                tempDateRange: null
            }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object().shape({
                deliveryDate: Yup.string().required("Required").test("is-valid-date", "Invalid", function (value){
                    let d = new Date(value);
                    return d >= new Date(moment().subtract(50, "years").endOf('D')) && d <= new Date(moment().endOf('D')) || value === null || value === undefined
                    ? true
                    : this.createError({
                        path: 'deliveryDateRange',
                    }) 
                }).nullable(),
                registrationInformationSelection: Yup.string().required("Required"),
                paymentAccepted: Yup.string().required("Required"),
                licensePlate: Yup.string().when('registrationInformationSelection', {
                    is: 'I',
                    then: Yup.string().required("Required")
                }),
                licenseDate: Yup.string().when('registrationInformationSelection', {
                    is: 'I',
                    then: Yup.string().required("Required").test("is-valid-date", "Invalid", function (value){
                        let d = new Date(value);
                        return d < new Date(moment().endOf('D')) && moment(value).isValid()
                        ? this.createError({
                            path: 'licenseDateRange',
                        }) 
                        : true
                    }).nullable()
                }),
                licenseState: Yup.string().when('registrationInformationSelection', {
                    is: 'I',
                    then: Yup.string().required("Required")
                }),
                tempPlate: Yup.string().when('registrationInformationSelection', {
                    is: 'T',
                    then: Yup.string().required("Required")
                }),
                tempDate: Yup.string().when('registrationInformationSelection', {
                    is: 'T',
                    then: Yup.string().required("Required").test("is-valid-date", "Invalid", function (value){
                        let d = new Date(value);
                        return d < new Date(moment().endOf('D')) && moment(value).isValid()
                        ? this.createError({
                            path: 'tempDateRange',
                        }) 
                        : true
                    }).nullable()
                }),
                tempState: Yup.string().when('registrationInformationSelection', {
                    is: 'T',
                    then: Yup.string().required("Required")
                }),
                noRegisterDetailsError: Yup.string().when('registrationInformationSelection', {
                    is: (registrationInformationSelection) => registrationInformationSelection === 'N' && whoWillLicense === "DL",
                    then: Yup.string().required("Required")
                }).nullable()


            })}
        >
            {({
                values,
                touched,
                errors,
                handleSubmit,
                setFieldValue,
                isSubmitting,
            }) => {
                return (
                    <form id='my-form' onSubmit={handleSubmit}>

                        <FormWrapper />
                        <div className="additional-Vehicle_Details">
                            <div className="additional-Vehicle-Details__caption">
                                <FormattedMessage {...messages.dealer_draft_reg_label} />
                                <Help videoId="registration_information"  whiteSpaceAtBottomHeight="0.7em"/>
                            </div>
                            <div className='row'>
                                <div className="col-sm-12 additional-Vehicle_Details__description">
                                    <div className="col-sm-2 additional-Vehicle_Details__label">
                                        <FormattedMessage {...messages.dealer_draft_delivery_date_description} />
                                    </div>

                                    <div className={classNames('col-sm-6', 'additional-Vehicle_Details__date_edit')}>
                                        <div className='row'>
                                            <div className="col-sm-12">
                                                <div className='row'>
                                                    <Overlay
                                                        show={errors.deliveryDateRange && touched.deliveryDateRange}
                                                        target={() => ReactDOM.findDOMNode(deliveryDateShowErrorRef.current)}
                                                        placement="right"

                                                    >
                                                        <Popover id="popOverDeliveryDate" className="additional-Vehicle_Details__error_message">
                                                            <RawIntlProvider value={intl}>
                                                                <FormattedMessage {...messages.dealer_draft_delivery_date_invalid_error_label} />
                                                            </RawIntlProvider>
                                                        </Popover>
                                                    </Overlay>
                                                    <div className='additional-Vehicle_Details__date_picker' ref={deliveryDateShowErrorRef}>
                                                        <DayPickerInput
                                                            id="deliveryDate"
                                                            error={errors.deliveryDate}
                                                            touched={touched.deliveryDate}
                                                            key='dtPicker1'
                                                            inputProps={{ class: 'additional-Vehicle_Details_date__date_picker-input', disabled: requestStatus === "VIEW" }}
                                                            tabIndex={1}
                                                            placeholder={'MM/DD/YYYY'}
                                                            onDayChange={(newDate) => {
                                                                setFieldValue(
                                                                    "deliveryDate",
                                                                    newDate != undefined ? newDate : null,
                                                                    true
                                                                );
                                                            }}
                                                            dayPickerProps={{
                                                                disabledDays: {
                                                                    before: new Date(moment().subtract(50, "years")),
                                                                    after: new Date(moment()),
                                                                },
                                                                selectedDays: new Date(moment(values.deliveryDate)),
                                                                locale: userLocale.substring(0, 2),
                                                                localeUtils: MomentLocaleUtils,
                                                            }}
                                                            formatDate={formatDate}
                                                            parseDate={parseDate}
                                                            format="MM/DD/YYYY"
                                                            value={values.deliveryDate != null ? new Date(moment(values.deliveryDate)) : null}

                                                        />
                                                        <div className="additional-Vehicle_Details__error_message2" >
                                                            {errors.deliveryDate &&
                                                                touched.deliveryDate &&
                                                                errors.deliveryDate}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <Overlay
                                    show={errors.registrationInformationSelection && touched.registrationInformationSelection}
                                    target={() => ReactDOM.findDOMNode(registerDetailsSelectionShowErrorRef.current)}
                                    placement="right"

                                >
                                    <Popover id="popOverInformationSelection" className="additional-Vehicle_Details__error_message" style={{ 'margin-left': '-28em', }}>
                                        <RawIntlProvider value={intl}>
                                            <FormattedMessage {...messages.dealer_draft_reg_details_error_label} />
                                        </RawIntlProvider>
                                    </Popover>
                                </Overlay>
                                <div className="col-sm-12 additional-Vehicle_Details__description">
                                    <div className="col-sm-6 additional-Vehicle_Details__label" ref={registerDetailsSelectionShowErrorRef}>
                                        <FormattedMessage {...messages.dealer_draft_reg_details_select_label} />
                                    </div>
                                </div>
                                <div className="additional-Vehicle_Details_update__radio">
                                    <img
                                        onClick={() => { setFieldValue("registrationInformationSelection", 'I', true) }}
                                        src={values.registrationInformationSelection === "I"
                                            ? "/assets/VMS_checked.png"
                                            : "/assets/VMS_unchecked.png"}
                                    />
                                    {' '} <FormattedMessage {...messages.dealer_draft_initial_license_label} />

                                </div>
                                <div className="additional-Vehicle_Details_update__radio">
                                    <img
                                        onClick={() => { setFieldValue("registrationInformationSelection", 'T', true) }}
                                        src={values.registrationInformationSelection === "T"
                                            ? "/assets/VMS_checked.png"
                                            : "/assets/VMS_unchecked.png"}
                                    />
                                    {' '} <FormattedMessage {...messages.dealer_draft_temp_label} />

                                </div>
                                <div ref={noRegisterDetailsSelectionShowErrorRef}>
                                    <Overlay
                                        show={errors.noRegisterDetailsError}
                                        target={() => ReactDOM.findDOMNode(noRegisterDetailsSelectionShowErrorRef.current)}
                                        placement="right"

                                    >
                                        <Popover id="popOverNoSelection" className="additional-Vehicle_Details__error_message" style={{ 'margin-left': '-59em', 'margin-top': '-.5em', 'max-width': '55em' }}>
                                            <RawIntlProvider value={intl}>
                                                <FormattedMessage {...messages.dealer_draft_no_selection_error_label} />
                                            </RawIntlProvider>
                                        </Popover>
                                    </Overlay>
                                    <div className="additional-Vehicle_Details_update__radio">
                                        <img
                                            onClick={() => { setFieldValue("registrationInformationSelection", 'N', true) }}
                                            src={values.registrationInformationSelection === "N"
                                                ? "/assets/VMS_checked.png"
                                                : "/assets/VMS_unchecked.png"}
                                        />
                                        {' '}  <FormattedMessage {...messages.dealer_draft_reg_no_selection_label} />

                                    </div>
                                </div>
                            </div>

                            {values.registrationInformationSelection === "I" ?
                                <div>
                                    <div className='row'>
                                        <div className="col-sm-12 additional-Vehicle_Details__description">
                                            <div className="col-sm-6 additional-Vehicle_Details__label">
                                                <FormattedMessage {...messages.dealer_draft_reg_details_label} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 additional-Vehicle_Details__description">
                                            <div className="col-sm-3 additional-Vehicle_Details__sub-label">
                                                <FormattedMessage {...messages.dealer_draft_license_plate_description} />
                                            </div>

                                            <div className={classNames('col-sm-6', 'additional-Vehicle_Details__date_edit')}>
                                                <div className='row'>
                                                    <div className="col-sm-12">
                                                        <div className='row'>
                                                            <div className='additional-Vehicle__license'>
                                                                <input type="text" maxLength={10}
                                                                    onKeyPress={helperFunctions.isLicense.bind(this)}
                                                                    className="additional-Vehicle_Details_date__date_picker-input"
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            "licensePlate",
                                                                            e.target.value,
                                                                            true
                                                                        );
                                                                    }}
                                                                    value={values.licensePlate}
                                                                    id="licensePlate"
                                                                    error={errors.licensePlate}
                                                                    touched={touched.licensePlate}
                                                                />
                                                                <div className="additional-Vehicle_Details__error_message2" >
                                                                    {errors.licensePlate &&
                                                                        touched.licensePlate &&
                                                                        errors.licensePlate}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-12 additional-Vehicle_Details__description">
                                            <div className="col-sm-3 additional-Vehicle_Details__sub-label">
                                                <FormattedMessage {...messages.dealer_draft_registration_date_description} />
                                            </div>

                                            <div className={classNames('col-sm-6', 'additional-Vehicle_Details__date_edit')}>
                                                <div className='row'>
                                                    <div className="col-sm-12">
                                                        <div className='row'>
                                                            <Overlay
                                                                show={errors.licenseDateRange && touched.licenseDateRange}
                                                                target={() => ReactDOM.findDOMNode(licenseDateShowErrorRef.current)}
                                                                placement="right"

                                                            >
                                                                <Popover id="popOverLicenseDate" className="additional-Vehicle_Details__error_message">
                                                                    <RawIntlProvider value={intl}>
                                                                        <FormattedMessage {...messages.dealer_draft_reg_date_invalid_error_label} />
                                                                    </RawIntlProvider>
                                                                </Popover>
                                                            </Overlay>
                                                            <div className='additional-Vehicle_Details__date_picker' ref={licenseDateShowErrorRef}>
                                                                <DayPickerInput
                                                                    key='dtPicker1'
                                                                    inputProps={{ class: 'additional-Vehicle_Details_date__date_picker-input', disabled: requestStatus === "VIEW" }}
                                                                    tabIndex={1}
                                                                    placeholder={'MM/DD/YYYY'}
                                                                    onDayChange={(newDate) => {
                                                                        setFieldValue(
                                                                            "licenseDate",
                                                                            newDate != undefined ? newDate : null,
                                                                            true
                                                                        );
                                                                    }}
                                                                    dayPickerProps={{
                                                                        disabledDays: {
                                                                            before: new Date(moment().add(1, "days")),
                                                                        },
                                                                        selectedDays: new Date(moment(values.licenseDate)),
                                                                        locale: userLocale.substring(0, 2),
                                                                        localeUtils: MomentLocaleUtils,
                                                                    }}
                                                                    formatDate={formatDate}
                                                                    parseDate={parseDate}
                                                                    format="MM/DD/YYYY"
                                                                    value={values.licenseDate != null ? new Date(moment(values.licenseDate)) : null}
                                                                    id="licenseDate"
                                                                    error={errors.licenseDate}
                                                                    touched={touched.licenseDate}
                                                                />
                                                                <div className="additional-Vehicle_Details__error_message2" >
                                                                    {errors.licenseDate &&
                                                                        touched.licenseDate &&
                                                                        errors.licenseDate}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-12 additional-Vehicle_Details__description">
                                            <div className="col-sm-3 additional-Vehicle_Details__sub-label">
                                                <FormattedMessage {...messages.dealer_draft_Registration_state_description} />
                                            </div>

                                            <div className={classNames('col-sm-6', 'additional-Vehicle_Details__date_edit')}>
                                                <div className='row'>
                                                    <div className="col-sm-12">
                                                        <div className='row'>

                                                            <select
                                                                id="licenseState"
                                                                error={errors.licenseState}
                                                                touched={touched.licenseState}
                                                                className='additional-Vehicle_Details_date__date_picker-input'
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        "licenseState",
                                                                        e.target.value,
                                                                        true
                                                                    );
                                                                }}  >
                                                                {values.licenseState != '' ? <option key="select_state1" value="" default>{values.licenseState}</option>
                                                                    : <option key="select_state" value="" default>Select State</option>}
                                                                {usStates.map((item) => {
                                                                    return <option key={item.get('abbreviation')}
                                                                        value={item.get('abbreviation')}>{item.get('abbreviation')}</option>
                                                                })

                                                                }

                                                            </select>
                                                            <div className="additional-Vehicle_Details__error_message2" >
                                                                {errors.licenseState &&
                                                                    touched.licenseState &&
                                                                    errors.licenseState}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : false}

                            {values.registrationInformationSelection === "T" ?
                                <div>
                                    <div className='row'>
                                        <div className="col-sm-12 additional-Vehicle_Details__description">
                                            <div className="col-sm-6 additional-Vehicle_Details__label">
                                                <FormattedMessage {...messages.dealer_draft_reg_details_label} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 additional-Vehicle_Details__description">
                                            <div className="col-sm-3 additional-Vehicle_Details__sub-label">
                                                <FormattedMessage {...messages.dealer_draft_temp_license_label} />
                                            </div>

                                            <div className={classNames('col-sm-6', 'additional-Vehicle_Details__date_edit')}>
                                                <div className='row'>
                                                    <div className="col-sm-12">
                                                        <div className='row'>
                                                            <div className='additional-Vehicle__license'>
                                                                {requestStatus === "VIEW" ? <div className="additional-Vehicle_Details_date__date_picker-input">{tempLicensePlate}</div> :
                                                                    <input type="text" maxLength={10}
                                                                        onKeyPress={helperFunctions.isLicense.bind(this)}
                                                                        className="additional-Vehicle_Details_date__date_picker-input"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "tempPlate",
                                                                                e.target.value,
                                                                                true
                                                                            );
                                                                        }}
                                                                        value={values.tempPlate}
                                                                        id="tempPlate"
                                                                        error={errors.tempPlate}
                                                                        touched={touched.tempPlate}

                                                                    />
                                                                }
                                                                <div className="additional-Vehicle_Details__error_message2" >
                                                                    {errors.tempPlate &&
                                                                        touched.tempPlate &&
                                                                        errors.tempPlate}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-12 additional-Vehicle_Details__description">
                                            <div className="col-sm-3 additional-Vehicle_Details__sub-label">
                                                <FormattedMessage {...messages.dealer_draft_temp_exp_label} />
                                            </div>

                                            <div className={classNames('col-sm-6', 'additional-Vehicle_Details__date_edit')}>
                                                <div className='row'>
                                                    <div className="col-sm-12">
                                                        <div className='row'>
                                                            <Overlay
                                                                show={errors.tempDateRange && touched.tempDateRange}
                                                                target={() => ReactDOM.findDOMNode(tempDateShowErrorRef.current)}
                                                                placement="right"

                                                            >
                                                                <Popover id="popOverTempDate" className="additional-Vehicle_Details__error_message">
                                                                    <RawIntlProvider value={intl}>
                                                                        <FormattedMessage {...messages.dealer_draft_reg_date_invalid_error_label} />
                                                                    </RawIntlProvider>
                                                                </Popover>
                                                            </Overlay>
                                                            <div className='additional-Vehicle_Details__date_picker' ref={tempDateShowErrorRef}>
                                                                <DayPickerInput
                                                                    key='dtPicker2'
                                                                    inputProps={{ class: 'additional-Vehicle_Details_date__date_picker-input', disabled: requestStatus === "VIEW" }}
                                                                    tabIndex={1}
                                                                    placeholder={'MM/DD/YYYY'}
                                                                    onDayChange={(newDate) => {
                                                                        setFieldValue(
                                                                            "tempDate",
                                                                            newDate != undefined ? newDate : null,
                                                                            true
                                                                        );
                                                                    }}
                                                                    dayPickerProps={{
                                                                        disabledDays: {
                                                                            before: new Date(moment().add(1, "days")),
                                                                        },
                                                                        selectedDays: new Date(moment(values.tempDate)),
                                                                        locale: userLocale.substring(0, 2),
                                                                        localeUtils: MomentLocaleUtils,
                                                                    }}
                                                                    formatDate={formatDate}
                                                                    parseDate={parseDate}
                                                                    format="MM/DD/YYYY"
                                                                    value={values.tempDate != null ? new Date(moment(values.tempDate)) : null}
                                                                    id="tempDate"
                                                                    error={errors.tempDate}
                                                                    touched={touched.tempDate}
                                                                />
                                                                <div className="additional-Vehicle_Details__error_message2" >
                                                                    {errors.tempDate &&
                                                                        touched.tempDate &&
                                                                        errors.tempDate}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-12 additional-Vehicle_Details__description">
                                            <div className="col-sm-3 additional-Vehicle_Details__sub-label">
                                                <FormattedMessage {...messages.dealer_draft_temp_state_label} />
                                            </div>

                                            <div className={classNames('col-sm-6', 'additional-Vehicle_Details__date_edit')}>
                                                <div className='row'>
                                                    <div className="col-sm-12">
                                                        <div className='row'>

                                                            <select
                                                                id="tempState"
                                                                error={errors.tempState}
                                                                touched={touched.tempState}
                                                                className='additional-Vehicle_Details_date__date_picker-input'
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        "tempState",
                                                                        e.target.value,
                                                                        true
                                                                    );
                                                                }}>
                                                                {values.tempState != '' ? <option key="select_state1" value="" default>{values.tempState}</option>
                                                                    : <option key="select_state" value="" default>Select State</option>}
                                                                {usStates.map((item) => {
                                                                    return <option key={item.get('abbreviation')}
                                                                        value={item.get('abbreviation')}>{item.get('abbreviation')}</option>
                                                                })

                                                                }
                                                            </select>
                                                            <div className="additional-Vehicle_Details__error_message2" >
                                                                {errors.tempState &&
                                                                    touched.tempState &&
                                                                    errors.tempState}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : false}
                        </div>
                        <div>
                            <hr className="request_approval__hr" />


                            <div className='row'>
                                <div className="request_approval__pop-label">
                                    <Overlay
                                        show={errors.paymentAccepted && touched.paymentAccepted}
                                        target={() => ReactDOM.findDOMNode(paymentInformationAcceptedShowErrorRef.current)}
                                        placement="right"

                                    >
                                        <Popover id="popOverPaymentAccepted" className="request_approval__error_message" style={{ 'margin-left': '-7em', 'margin-top': '.5em', 'max-width': '35em' }}>
                                            <RawIntlProvider value={intl}>
                                                <FormattedMessage {...messages.dealer_draft_payment_accepted_error} />
                                            </RawIntlProvider>
                                        </Popover>
                                    </Overlay>
                                    <div className="col-md-8 request_approval__restore" ref={paymentInformationAcceptedShowErrorRef}>
                                        <img onClick={() => { setFieldValue("paymentAccepted", 'P', true) }}
                                            src={values.paymentAccepted === 'P'
                                                ? "/assets/VMS_checked.png"
                                                : "/assets/VMS_unchecked.png"}
                                        />
                                        {' '} <FormattedMessage
                                            values={{ checkingAccount: vendorCheckingAccount }} {...messages.dealer_draft_confirm_payment} />

                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-6 request_approval__restore"
                                    role="button"
                                    onClick={onDeleteResetClick}>
                                    <img src="/assets/VMS_28button_edit_clear.png" />
                                    {' '}
                                    <FormattedMessage {...messages.restore_request} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 request_approval__return"
                                    role="button"
                                    onClick={onReturnClickHandler}>
                                    <img src="/assets/VMS_33button_back_blue.png" />
                                    {' '}
                                    <FormattedMessage {...messages.return} />
                                </div>

                                <div className={classNames(
                                    { 'col-sm-6 request_approval__payment': !isSubmitting },
                                    { 'col-sm-6 request_approval__payment-disabled': isSubmitting })}>
                                    <a className="request_approval__btn" onClick={() => { handleSubmit(); }}>
                                        <FormattedMessage {...messages.request_payment} />
                                        <IntlProvider locale="en">
                                            <FormattedNumber value={subTotal} style="currency"
                                                currency={selectedCurrency} />
                                        </IntlProvider>
                                        {' '}<img src="/assets/VMS_33button_go_bblue.png" />
                                    </a>
                                    <div className="request_approval-spinner inline-block">
                                        {isSubmitting ?
                                            <Spinner spinnerType="dark-blue" />
                                            : false
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                );
            }}
        </Formik>
    </div>

    // #endregion
}



// #endregion
export default injectIntl(AdditionalVehicleDetails);
