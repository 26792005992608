import { call, select, cancel, fork, take,takeLatest,takeEvery } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as ShopView from 'sagas/ShopView';
import * as Login from 'sagas/Login';
import * as SearchPO from 'sagas/SearchPO';
import * as AddEditView from 'sagas/AddEditView';
import * as ClosePO from 'sagas/ClosePO';
import * as dotInspectionSagas  from 'PO/ClosePO/screens/DotInspection/sagas/DotInspectionSagas';
import * as bitInspectionSagas  from 'PO/ClosePO/screens/BitInspection/sagas/BitInspectionSagas';
import * as fraInspectionSagas  from 'PO/ClosePO/screens/FraInspection/sagas/FraInspectionSagas';
import * as craneInspectionSagas  from 'PO/ClosePO/screens/CraneInspection/sagas/CraneInspectionSagas';
import * as ActionRequired from 'sagas/ActionRequired';
import * as OdometerView from 'sagas/OdometerView';
import * as driverValidationComplaintsSagas from 'PO/DriverValidationComplaints/sagas/DriverValidationComplaints';
import * as ComplaintView from 'sagas/ComplaintView';
import * as RepairHistoryView from 'sagas/RepairHistoryView';
import * as FeedbackForm from 'sagas/FeedbackForm';
import * as ClientParametersView from 'sagas/ClientParametersView';
import * as AddNewLineItemsView from 'sagas/AddNewLineItemsView';
import * as AddServiceLineItemsView from 'sagas/AddServiceLineItemsView';
import * as VendorAccount from '../../screens/YourAccount/VendorAccountInformation/sagas/VendorAccountView';
import * as RecoverPassword from 'sagas/RecoverPassword';
import * as VendorEnrollmentRequestView from '../../screens/YourAccount/VendorEnrollmentRequest/sagas/VendorEnrollmentRequestView';
import * as VendorEnrollmentView from '../../screens/YourAccount/VendorEnrollment/sagas/VendorEnrollmentView';
import * as vendorDocumentsSagas from 'YourAccount/VendorDocuments/sagas/VendorDocuments';
import * as vehicleDocumentsSagas from 'Vehicle/VehicleDocuments/sagas/VehicleDocuments';
import * as adjustmentSagas  from 'PO/Adjustment/sagas/Adjustment';
import * as MyUnitsView from '../../screens/YourAccount/MyUnits/sagas/MyUnitsView';
import * as RegistrationView from '../../screens/YourAccount/Registration/sagas/RegistrationView';
import * as VendorLinkedAccountsView from '../../screens/CorporateAccount/sagas/LinkedVendorView';
import * as VendorLinkedRegister from '../../screens/CorporateAccount/LinkedVendorRegister/sagas/LinkedVendorRegister';
import * as CarWashView from '../../screens/CarWash/sagas/CarWashView';
import * as DealerDraftDealerRequests from '../../screens/DealerDrafts/DealerRequests/sagas/DealerRequestsView';
import * as DealerDraftRequestsApproval from '../../screens/DealerDrafts/RequestsApproval/sagas/RequestsApprovalView';
import * as TermsAndConditions from '../../screens/Home/TermsAndConditions/sagas/TermsView';
import * as VehicleSearchCreatePOView from '../VehicleSearchCreatePO/sagas/VehicleSearchCreatePO';
import * as ResetPassword from 'sagas/ResetPassword';
import configuration from 'configuration';
import * as ARIUser from 'sagas/ARIUser';

function* saveErrorInformation(action) {
    try {
        //Disable/Enable logging APC UI actions or app state details to the database.
        if(configuration.enableVMSLogging)
        {
            const state = yield select();
            // For now, save the error object instead of stack trace. Generating a stack trace is making UI slow
            yield call(Api.saveErrorInfo, action.errorObject, state, action.actionName, 'ERROR');
        }
    }
    catch (e) {
        // absorb errors
    }
}

function* navigateTo(context, action) {
    yield call(context.history.push,action.path);
}


function* rootSaga(context) {
     // Login  
    yield takeLatest(constants.SIGN_IN_CLICKED, Login.authenticate, context);
    yield takeLatest("PERSONA_ACCESS_STORE_CLICKED",Login.authenticateStoreAccessforPersonaUser,context);
    yield takeLatest(constants.SSO_USER_SIGNED_IN, Login.saveSSOUserDetails, context);
    yield takeLatest(constants.FETCH_TERMS_STATUS, Login.fetchTermsAndConditionStatus, context);
    yield takeLatest("FETCH_IS_DEALERDRAFT", Login.fetchDealerDraftStatus, context);
    yield takeLatest(constants.FETCH_TERMS_TEXT, Login.fetchTermsAndConditionText, context);
    yield takeLatest(constants.ACCEPTED_TERMS_AND_CONDITIONS_SUBMISSION, Login.acceptedTermsAndConditions, context);
    
    // Shop View
    yield takeLatest(constants.SHOP_VIEW_LOADED, ShopView.pollShopViewData);

    // Search For POs by
    yield takeLatest(constants.SEARCH_FOR_POS_CLICKED, SearchPO.searchForPOsBy, context);
    yield takeLatest(constants.SEARCH_FOR_PO_BY_VIN8_AND_VEHICLEID, SearchPO.searchForPOByVin8AndVehicleId, context);

    //yield takeLatest(constants.SEARCH_VENDOR_POS_LOADED, SearchPO.fetchVendorPosForAVehicle);

    // Add Edit or View PO
    yield takeEvery(constants.ADD_SERVICE_LINE_ITEM_CLICKED, AddEditView.addServiceLineItem);
    yield takeEvery(constants.ADD_NEW_LINE_ITEM_CLICKED, AddEditView.addNewLineItemHandler);
    yield takeEvery(constants.UPDATE_LINE_ITEM_CLICKED, AddEditView.updateLineItemHandler)
    yield takeEvery(constants.DELETE_LINE_ITEM_CLICKED, AddEditView.deleteLineItem);
    yield takeEvery(constants.ESTIMATED_COMPLETION_DATE_CLICKED, AddEditView.estimatedCompletionDateChanged);
    yield takeLatest(constants.PO_DETAILS_SCREEN_LOADED, AddEditView.fetchPOData, context);
    yield takeLatest(constants.REQUEST_APPROVAL_CLICKED, AddEditView.requestApprovalClicked, context);
    yield takeEvery(constants.SAVE_COMPLAINT_NOTES_CLICKED, AddEditView.saveComplaintNotesHandler);
    yield takeEvery(constants.DUE_SERVICES_DATA_LOADED, AddEditView.fetchDataForDueServices);
    yield takeEvery(constants.CANCEL_PO_REQUESTED, AddEditView.cancelPo, context);
    yield takeEvery(constants.REQUEST_APPROVAL_OR_CLOSEPO_CLICKED, AddEditView.onRequestApprovalOrClosePOHandler, context);
    yield takeEvery(constants.SAVE_TIRE_DETAILS, AddEditView.saveTireSpecifications);
    yield takeEvery(constants.SAVE_NEW_LINE_ITEM_WITH_TIRE_SPECS, AddEditView.saveNewLineItemWithTireSpecs, context);
    yield takeEvery(constants.FETCH_TIRE_SPECIFICATIONS, AddEditView.fetchTireSpecification, context);
    yield takeEvery(constants.UPDATE_TREAD_DEPTH_MEASUREMENT_UNIT, AddEditView.updateTreadDepthMeasurementUnit, context);
    yield takeEvery(constants.ADD_NEW_AXLE, AddEditView.addNewAxle, context);
    yield takeEvery(constants.REMOVE_AXLE, AddEditView.removeAxle, context);
    yield takeEvery(constants.ADD_NEW_WHEEL, AddEditView.addNewWheel, context);
    yield takeEvery(constants.REMOVE_WHEEL, AddEditView.removeWheel, context);
    yield takeEvery(constants.UPDATE_TREAD_DEPTH, AddEditView.updateTreadDepth, context);
    yield takeEvery(constants.UPDATE_TIRE_SELECTION, AddEditView.updateTireSelection, context);
    yield takeEvery('SEND_LABOR_RATE_CHANGE_REQUEST', AddEditView.sendLaborRateChangeRequest, context);
    //


    // Close PO
    yield takeLatest(constants.CLOSE_PO_SCREEN_LOADED, ClosePO.fetchClosePOData, context);
    yield takeLatest(constants.CLOSE_PO_CLICKED, ClosePO.closePO, context);

    // Dot Inspection
	yield takeLatest(constants.DOT_INSPECTION__SET_DEFAULT_VALUES, dotInspectionSagas.setDefaultValues, context);
	yield takeLatest(constants.DOT_INSPECTION__SAVE_CONFIRMATION, dotInspectionSagas.saveConfirmation, context);
	yield takeLatest(constants.DOT_INSPECTION__SAVE_FORM, dotInspectionSagas.saveForm, context);
	
	// Bit Inspection
	yield takeLatest(constants.BIT_INSPECTION__WINDOW_SHOWN, bitInspectionSagas.windowShown, context);
	yield takeLatest(constants.BIT_INSPECTION__SAVE_CONFIRMATION, bitInspectionSagas.saveConfirmation, context);
	yield takeLatest(constants.BIT_INSPECTION__SAVE_FORM, bitInspectionSagas.saveForm, context);
	
	// Fra Inspection
	yield takeLatest(constants.FRA_INSPECTION__WINDOW_SHOWN, fraInspectionSagas.windowShown, context);
	yield takeLatest(constants.FRA_INSPECTION__SAVE_CONFIRMATION, fraInspectionSagas.saveConfirmation, context);
    yield takeLatest(constants.FRA_INSPECTION__SAVE_FORM, fraInspectionSagas.saveForm, context);

	// Crane Inspection
	yield takeLatest(constants.CRANE_INSPECTION__WINDOW_SHOWN, craneInspectionSagas.windowShown, context);
	yield takeLatest(constants.CRANE_INSPECTION__SAVE_CONFIRMATION, craneInspectionSagas.saveConfirmation, context);
    yield takeLatest(constants.CRANE_INSPECTION__SAVE_FORM, craneInspectionSagas.saveForm, context);

    // Action Required
    yield takeLatest(constants.ACTION_REQUIRED_SCREEN_LOADED, ActionRequired.fetchData, context);
    //yield takeLatest(constants.ACKNOWLEDGE_LINE_ITEMS, ActionRequired.acknowledgeLineItems, context);
    yield takeLatest(constants.ACTION_REQUIRED_CANCEL_PO_CLICKED, ActionRequired.cancelPO, context);
    yield takeLatest(constants.REJECT_PO_ADJUSTMENTS, ActionRequired.rejectPOAdjustments, context);
    //yield takeLatest(constants.ACTION_REQUIRED_SUBMIT_VENDOR_NOTES, ActionRequired.submitVendorNotes, context);
    yield takeLatest('ACTION_REQUIRED_SUBMIT_PO', ActionRequired.submitPOAcknowledgements, context);
    
    // Odometer screen
    yield takeLatest(constants.ODOMETER_SCREEN_LOADED, OdometerView.fetchDataForOdometerScreen);
    yield takeLatest(constants.CREATE_PO_CLICKED, OdometerView.createPOClicked, context);

    // Driver validation complaints screen    
    yield takeLatest(constants.DRIVER_VALIDATION_COMPLAINTS_SCREEN_LOADING, driverValidationComplaintsSagas.fetchData, context);
    yield takeEvery(constants.DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP, driverValidationComplaintsSagas.goToNextStep, context);

    // Complaint screen
    yield takeLatest(constants.COMPLAINT_SCREEN_LOADED, ComplaintView.fetchDataForComplaintScreen);
    yield takeEvery(constants.CREATE_OR_EDIT_COMPLAINT_CLICKED, ComplaintView.createOrEditComplaint);
    yield takeEvery(constants.SKIP_COMPLAINT_CLICKED, ComplaintView.skipComplaint);
    yield takeEvery(constants.GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN, ComplaintView.goToNextStepClickedOnComplaintScreen, context);

    // Repair History
    yield takeLatest(constants.REPAIR_HISTORY_SCREEN_LOADED, RepairHistoryView.repairHistoryScreenFlow);

    // Feedback form
    yield takeEvery(constants.SEND_FEEDBACK_FORM_CLICKED, FeedbackForm.sendClickFeedbackFormRequest);

    yield takeEvery(constants.SAVE_ERROR_INFORMATION, saveErrorInformation);
    yield takeEvery(constants.NAVIGATE_TO, navigateTo, context);

    //client parameters
    yield takeEvery(constants.CLIENT_PARAMETERS_SCREEN_LOADED, ClientParametersView.receiveClientParametersVehicle);

    //ata parameters
    yield takeEvery(constants.ATA_PARAMETERS_SCREEN_LOADED, AddNewLineItemsView.receiveAtaParameters);

    //ata pm parameters
    yield takeEvery(constants.ATA_PM_PARAMETERS_SCREEN_LOADED, AddServiceLineItemsView.receivePmAtaParameters);

    // Add new line item
    yield takeEvery(constants.SEARCHED_ATA_CODES_DATA_LOADED, AddNewLineItemsView.fetchATACodesBySearchKey);

    //bulletin message
    yield takeEvery(constants.FETCH_BULLETIN_MESSAGE, ShopView.receiveBulletinMessage);

    //Save Password
    yield takeEvery(constants.SAVE_PASSWORD, VendorAccount.changePasswordClicked);

    //Save Vendor Contact Information
    yield takeEvery(constants.UPDATE_VENDOR_DETAILS, VendorAccount.changeVendorInformationClicked);

    //Fetches vendor current profile information
    yield takeEvery(constants.FETCH_VENDOR_INFORMATION, VendorAccount.fetchVendorInformation);

    //Fetches vendor current tax information
    yield takeEvery(constants.FETCH_VENDOR_TAX_INFORMATION, VendorAccount.fetchVendorTaxInformation);

    //Fetches vendors current banking information
    yield takeEvery(constants.FETCH_VENDOR_BANKING_INFORMATION, VendorAccount.fetchVendorBankingAccountInformation);

    //Verifies banking info
    yield takeEvery(constants.FETCH_VENDOR_VERIFY_INFORMATION, VendorAccount.verifyVendorInformation);
    //Saves Vendors Banking information
    yield takeEvery(constants.UPDATE_VENDOR_BANKING_ACCOUNT_DETAILS, VendorAccount.changeVendorBankingAccountInformation);
    //Saves vendor's Payment Email Information
    yield takeEvery(constants.UPDATE_VENDOR_PAYMENT_EMAIL_DETAILS, VendorAccount.changeVendorPaymentEmailInformation);
    

    //Fetches vendors current services
    yield takeEvery(constants.FETCH_VENDOR_SERVICES, VendorAccount.fetchVendorServices);

    //Saves services
    yield takeEvery(constants.SAVE_VENDOR_SERVICES, VendorAccount.changeVendorServicesClicked);

    //fetch auth email notification info
    yield takeEvery(constants.FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION, VendorAccount.fetchAuthEmailNotificationConfig, context);
    //save auth email notification
    yield takeEvery(constants.ACCOUNT_VIEW_SAVE_EMAIL_AUTH_NOTIFICATION, VendorAccount.accountViewSaveAuthEmailNotificationConfiguration, context);
    //save and fetch notification 
    yield takeEvery(constants.ACCOUNT_VIEW_SAVE_AND_FETCH_EMAIL_AUTH_NOTIFICATION, VendorAccount.saveAndFetchEmailNotificationConfig, context);

    //recover Password
    yield takeEvery(constants.SENDING_RECOVER_PASSWORD_INFORMATION, RecoverPassword.recoverPasswordInformation)
    yield takeEvery(constants.SENDING_RECOVER_PASSWORD_INFO_PERSONA_USER, RecoverPassword.recoverPasswordInformationForPersonaUser)
    

    //vendor enrollment
    yield takeEvery(constants.SUBMIT_ENROLLMENT_REQUEST_INFO, VendorEnrollmentRequestView.submitVendorEnrollmentRequest, context)
    yield takeEvery(constants.SUBMIT_ENROLLMENT_DETAIL_INFO, VendorEnrollmentView.submitVendorEnrollmentDetails, context)

    // Vendor Documents
    yield takeLatest(constants.FETCH_VENDOR_DOCUMENTS, vendorDocumentsSagas.fetchData);
	yield takeLatest(constants.UPLOAD_VENDOR_DOCUMENT, vendorDocumentsSagas.uploadDocument);
	
	// Vehicle Documents
    yield takeLatest(constants.FETCH_VEHICLE_DOCUMENTS, vehicleDocumentsSagas.fetchData);
    yield takeLatest(constants.UPLOAD_VEHICLE_DOCUMENT, vehicleDocumentsSagas.uploadDocument);

    // Adjustment    
    yield takeLatest(constants.FETCH_ADJUSTMENT_DATA, adjustmentSagas.fetchData, context);
    yield takeEvery(constants.ADJUSTMENT_UPDATE_LINE_ITEM_CLICKED, adjustmentSagas.updateLineItem, context);
    yield takeEvery(constants.ADJUSTMENT_SAVE_COMPLAINT_NOTES_CLICKED, adjustmentSagas.saveComplaintNotes);
    yield takeEvery(constants.ADJUSTMENT_DELETE_LINE_ITEM, adjustmentSagas.deleteLineItem);

    //fetch vendor units
    yield takeEvery(constants.FETCH_VENDOR_UNITS, MyUnitsView.myUnitsScreenFlow);

    //send vendor email
    yield takeEvery(constants.EMAIL_VENDOR_UNITS, MyUnitsView.emailVendorUnitClicked);

    //reject vendor units
    yield takeEvery(constants.REJECT_VENDOR_UNITS, MyUnitsView.rejectVendorUnitClicked);

    //reject vendor units
    yield takeEvery(constants.FETCH_VENDOR_BUSINESS_GROWTH, VendorAccount.vendorBusinessGrowthInformation);

    //fetch vendor units
    yield takeEvery(constants.FETCH_VENDOR_OTHER_UNITS, MyUnitsView.myOtherUnitsScreenFlow);

    yield takeLatest("SEARCH_VEHICLE_FOR_MY_UNITS_CLICKED", MyUnitsView.fetchVehicleForMyUnitsLookup);

    //registration 
    yield takeEvery(constants.SUBMIT_ACCOUNT_INFO, RegistrationView.submitAccountInfo, context)
    yield takeEvery(constants.SUBMIT_BANK_INFO, RegistrationView.submitBankInfo, context)
    yield takeEvery(constants.FETCH_VENDOR_SERVICES_FOR_REGISTRATION, RegistrationView.fetchVendorServicesForRegistration, context)
    yield takeEvery(constants.SAVE_VENDOR_SERVICES_FOR_REGISTRATION, RegistrationView.submitVendorServices, context)  //
    yield takeEvery(constants.SAVE_EMAIL_AUTH_NOTIFICATION, RegistrationView.saveAuthEmailNotificationConfiguration, context)
    yield takeEvery("FETCH_TERMS_TEXT_REGISTER", RegistrationView.fetchTermsAndConditionText, context)
    yield takeEvery("ACCEPTED_TERMS_AND_CONDITIONS_SUBMISSION_REGISTER", RegistrationView.acceptedTermsAndConditions, context)

    //fetch linked vendors
    yield takeEvery(constants.FETCH_LINKED_VENDORS, VendorLinkedAccountsView.linkedVendorScreenFlow);

    yield takeEvery(constants.SUBMIT_LINKED_VENDOR_REGISTER, VendorLinkedRegister.submitVendorLinkedEnrollmentRequest, context);

    yield takeEvery("FETCH_CORPORATE_VENDOR_INFORMATION", VendorLinkedAccountsView.fetchCorporateVendorInformation);

    yield takeEvery("FORGOT_CORPORATE_VENDOR_PASSWORD", VendorLinkedAccountsView.forgotCorporateVendorPassword, context);

    //car wash
    yield takeEvery("FETCH_CAR_WASH_CLIENT_NUMBERS", CarWashView.fetchClientNumbers);
    yield takeEvery("FETCH_CAR_WASH_DETAILS", CarWashView.fetchCarWashDetails,context);

    yield takeEvery("FETCH_ORDER_DETAILS", CarWashView.fetchingOrderDetails);
    yield takeEvery("FETCH_CAR_WASH_PARAMETERS", CarWashView.fetchCarWashParameterDetails,context);
    yield takeEvery("CREATE_CAR_WASH_PO", CarWashView.createCarWashPOSubmit,context);

    //dealer drafts
    yield takeEvery("FETCH_DEALER_DRAFT_DEALER_REQUESTS", DealerDraftDealerRequests.fetchingDealerDraftDealerRequests);
    yield takeEvery("FETCH_REQUEST_NO_DETAILS", DealerDraftRequestsApproval.fetchingRequestsNoDetails,context);
    yield takeEvery("DEALER_DRAFT_UPDATE_LINE_ITEM_CLICKED", DealerDraftRequestsApproval.updateLineItem);
    yield takeEvery("DEALER_DRAFT_DELETE_LINE_ITEM", DealerDraftRequestsApproval.deleteLineItem);
    yield takeEvery("FETCH_DEALER_DRAFT_PRODUCT_DETAILS", DealerDraftRequestsApproval.fetchingDealerDraftProductDetails);
    yield takeEvery("DEALER_DRAFT_ADD_NEW_LINE_ITEM_CLICKED", DealerDraftRequestsApproval.dealerDraftAddNewLineItemHandler);
    yield takeEvery("DEALER_DRAFT_REQUEST_PAYMENT", DealerDraftRequestsApproval.dealerDraftRequestPayment, context);
    yield takeEvery("DEALER_DRAFT_REQUEST_RESET", DealerDraftRequestsApproval.dealerDraftRequestReset);


    //brakes 
    yield takeEvery(constants.SAVE_NEW_LINE_ITEM_WITH_BRAKE_SPECS, AddEditView.saveNewLineItemWithBrakes, context);
    yield takeEvery(constants.FETCH_BRAKES_SPECIFICATIONS, AddEditView.fetchBrakesSpec, context);
    yield takeEvery(constants.SAVE_BRAKES_DETAILS, AddEditView.saveBrakesInfo, context);

    //terms&conditions
    yield takeEvery("TERMS_AND_CONDITIONS_STATIC", TermsAndConditions.fetchTermsAndConditions, context);

    //createPOSearch
    yield takeEvery("CREATE_PO_SEARCH_CLICK", VehicleSearchCreatePOView.createPOSearch, context);
    yield takeEvery("FETCH_CLIENT_NUMBERS", VehicleSearchCreatePOView.fetchClientNumbers, context);

    //reset Password
    yield takeEvery(constants.RESET_PASSWORD_REQUEST, ResetPassword.sendResetPassword);

    //Holman User
    yield takeEvery(constants.ARIUSER_SEARCH_VENDOR, ARIUser.fetchVendor, context);
}

export default rootSaga;  