import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import './SSORedirect.css';
import { connect } from 'react-redux';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import queryString from 'query-string'

class SSORedirect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           ssoValues: queryString.parse(this.props.routeParams.location.search)
        }

    }
     componentDidMount() {

         if (this.state.ssoValues.token !== undefined) {
            this.props.dispatch({
                type: constants.SSO_USER_SIGNED_IN,
                token:  this.state.ssoValues.token
            });
        }
    }

    render() {

        const messages = defineMessages({
            redirect_sso_user: {
                id: 'redirect_sso_user',
                description: 'Redirecting to dashboard...',
                defaultMessage: 'Redirecting to dashboard...'
            },
            signout_sso_user: {
                id: 'signout_sso_user',
                description: 'Thank you...',
                defaultMessage: 'You have been logged out of Holman PartnerConnect. It is now safe to close your browser.'
            }
        });
       return <div>
            <div className="row sso_redirect_redirect_text">
                {
                    this.state.ssoValues.token !== undefined ?
                   <div> 
                       <FormattedMessage {...messages.redirect_sso_user}/> <Spinner /> 
                    </div>
                    :  this.state.ssoValues.signed_out !== undefined
                    ? <div> <FormattedMessage {...messages.signout_sso_user}/>
                    </div>
                    : false
                    }
            
            </div>
        </div>;
    }
}



function mapStateToProps(state, props) {
    return {
        token: state.appState.getIn(['serverData', 'shared', 'token'])
    };
}

export default connect(mapStateToProps)(SSORedirect);