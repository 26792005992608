import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import './VehicleInformation.css';

class VehicleInformation extends React.Component {

    render() {
        //Get data from app state (reducer store)
        var firstOfVin8 = '';
        var lastOfVin8 = '';
        /* this split is to highlight the last 8 chars of vin number as vin8 */
        var vehicleDetails = this.props.vehicleDetails.toJSON();
        var vinNumber = vehicleDetails.vin;
        if (vinNumber != undefined) {
            lastOfVin8 = vinNumber.slice(9, vinNumber.length);
            firstOfVin8 = vinNumber.slice(0, 9);
        }

        //check to display linked vendor ID and address when a corporate vendor is searching and viewing its linked accounts PO's
        var corporateLoggedIn = this.props.vendorAccountType === "Master"  && this.props.loggedInVendorId !== this.props.poVendorId;

        const messages = defineMessages({
            po_actions_vehicle_details_client: {
                id: 'po_actions_vehicle_details_client',
                description: 'Client',
                defaultMessage: 'Client'
            },
            po_actions_vehicle_details_ari_unit_no: {
                id: 'po_actions_vehicle_details_ari_unit_no',
                description: 'Holman unit no.',
                defaultMessage: 'Holman unit no.'
            },
            po_actions_vehicle_details_vehicle: {
                id: 'po_actions_vehicle_details_vehicle',
                description: 'Vehicle',
                defaultMessage: 'Vehicle'
            },
            po_actions_vehicle_details_mileage: {
                id: 'po_actions_vehicle_details_mileage',
                description: 'Mileage',
                defaultMessage: 'Mileage'
            },
            po_actions_vehicle_details_vin_no: {
                id: 'po_actions_vehicle_details_vin_no',
                description: 'VIN',
                defaultMessage: 'VIN'
            },
            ari_issued_id: {
                id: 'ari_issued_id',
                description: 'Holman Issued ID',
                defaultMessage: 'Holman Issued ID'
            },
            vendor_location: {
                id: 'vendor_location',
                description: 'Location',
                defaultMessage: 'Location'
            }
        });

        return <div className="row">
            <div className="col-md-12 vehicle_info2_col_label__left_padding">
                <div className="col-sm-6">
                    <div className="row">
                        <div className="col-sm-5 vehicle_info2_col__label">
                            <FormattedMessage {...messages.po_actions_vehicle_details_vin_no}/>
                        </div>
                        <div className="col-sm-7 vehicle_info2_col__val">{firstOfVin8}
                            <span>{lastOfVin8}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5 vehicle_info2_col__label">
                            <FormattedMessage {...messages.po_actions_vehicle_details_client}/>
                        </div>
                        <div
                            className="col-sm-7 vehicle_info2_col__val">
                            {
                                (vehicleDetails.clientId != undefined && vehicleDetails.clientName != undefined) ?
                                    <div> {vehicleDetails.clientName} {' ('}
                                        {vehicleDetails.clientId} {')'}
                                    </div>
                                    : false
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5 vehicle_info2_col__label">
                            <FormattedMessage {...messages.po_actions_vehicle_details_ari_unit_no}/>
                        </div>
                        <div className="col-sm-7 vehicle_info2_col__val">
                            {vehicleDetails.ariUnitNo}
                        </div>
                    </div>
                    {corporateLoggedIn && this.props.poVendorId != null && this.props.loggedInVendorId != ""?
                        <div className="row">
                            <div className="col-sm-5 vehicle_info2_col__label">
                            <FormattedMessage {...messages.ari_issued_id}/>
                        </div>
                            <div className="col-sm-7 vehicle_info2_col__val">
                                {this.props.poVendorId}
                            </div>
                        </div>
                        : false}
                </div>
                <div className="col-sm-6">
                    <div className="row">
                        <div className="col-sm-5 vehicle_info2_col__label">
                            <FormattedMessage {...messages.po_actions_vehicle_details_vehicle}/>
                        </div>
                        <div className="col-sm-7 vehicle_info2_col__val">
                            {vehicleDetails.year} {vehicleDetails.make} {vehicleDetails.model}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5 vehicle_info2_col__label">
                            <FormattedMessage {...messages.po_actions_vehicle_details_mileage}/>
                        </div>
                        <div className="col-sm-7 vehicle_info2_col__val">
                            {vehicleDetails.lastOdometerReading}
                        </div>
                    </div>
                    {corporateLoggedIn && this.props.businessAddress != null && this.props.businessAddress != undefined ?
                                <div className="row">
                                    <div className="col-sm-5 vehicle_info2_col__label">
                                    <FormattedMessage {...messages.vendor_location}/>
                                     </div>
                                    <div className="col-sm-7 vehicle_info2_col__val">
                                    {this.props.businessAddress.get('address1') + " | " + this.props.businessAddress.get('city') + ", " + this.props.businessAddress.get('state') + " " + this.props.businessAddress.get('zipCode')}
                                    </div>
                                </div>
                                : false}
                </div>
            </div>
        </div>
    }
}


export default VehicleInformation;
