// #region Imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Api from "utils/Api";
import * as constants from "constants/App";
import styled from "styled-components";
import Spinner from "components/Spinner";
import ReactPaginate from "react-paginate";
import RemoveAccessModal from "common/RemoveAccessModal";
import FormWrapper from "components/FormWrapper";
import * as helperFunctions from "utils/HelperFunctions";
import {
  Subhead
} from "DesignSpecs/components/FontGuide";
import { PageLayout } from "DesignSpecs/components/Layouts";
import {
  Input,
  InputFeedback,
  Image,
  SubmitImage,
  Row,
  Col
} from "DesignSpecs/components/FormComponents";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import Pagination from "common/Pagination";
import useRemoveStoreAccess from "common/useRemoveStoreAccess";

// #endregion

// #region Styled Components
export const Label = styled.div`
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    color: black;
    font-size: 14px;
    white-space: nowrap;
`;

export const RowLabel = styled.div`
    font-weight: bold;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    color: black;
    font-size: 14px;
    white-space: nowrap;
`;

const NameHeader = styled.div`
	display: flex;
	flex-wrap: wrap;
  justify-content: space-around;	
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  color: black;
  font-size: 16px;
  white-space: nowrap;	
`;

const FieldGroup = styled.div`
	color: black;
  valign:bottom;
`;

const NoResultsText = styled.div`
    font-size: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B";
    color: #000000;
    font-style: normal;
    font-weight: 300;
    padding-left: 9em;
`;

const ClearImageStyled = styled.img`
  display: block;
  position: absolute;
  top: 2px;
  right: 3px; 
  cursor: pointer;                                
  width: 22px;
  height: 22px;
`;

export const FormTextGray=styled.div`
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
	  font-family: "Gotham SSm A", "Gotham SSm B";
    color: ${props => props.theme.color.ariGray};
    font-style: normal;
    font-weight: 300;
    padding-left: 0px;
    width: 27em;
`;

const ReturnLinkStyled = styled.div`
  padding-left: 3px;
  color: #5587b7;
  cursor: pointer;
  font-weight: 500;

  &:hover {	
    color: #0f4e95;
  }
`;
// #endregion

// #region Component
const StoreProfile = ({
  token,
  adminUserId,
  intl,
  dispatch,
  routeParams
}) => {

  // #region State
  const loadingStates = { loading: "loading", loaded: "loaded", error: "error" };
  const { formatMessage } = intl;
  const [loadingState, setLoadingState] = useState(loadingStates.loading);
  const [searchResultLoadingState, setSearchResultLoadingState] = useState(
    loadingStates.loaded
  );
  const [storeInformation, setStoreInformation] = useState();
  const [userSearchKeyWord, setUserSearchKeyword] = useState("");
  const [pageCount, setPageCount] = useState();
  const [userCount, setUserCount] = useState();
  const [users, setUsers] = useState();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const pageSize = 20;
  const [showRemoveAccessConfirmationDialog, setRemoveAccessConfirmationDialogVisible] = useState(false);
  const [userIdForWhichAccessIsToBeRemoved, setUserIdForWhichAccessIsToBeRemoved] = useState('');
  const [personaStoreProfileErrorMessage, setPersonaStoreProfileErrorMessage] = useState();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [storeId] = useState(routeParams.match.params.storeId);
  const [setRemoveAccessInformation, isAccessBeingRemoved, isRemoveStoreAccessError ] = useRemoveStoreAccess(token);
  // #endregion

  // #region Event handlers
  function handlePageClick(data) {
    setCurrentPageNumber(data.selected);
  }

  function onSearchClick(value) {
    setCurrentPageNumber(0);
    setUserSearchKeyword(value);
  }

  async function Fetch() {


    const fetchData = async () => {
      try {
        setLoadingState(loadingStates.loading);

        const [usersResult] = await Promise.all([
          Api.fetchStoreUsers(
            adminUserId,
            storeId,
            token,
            currentPageNumber == undefined ? 0 : currentPageNumber,
            pageSize,
            userSearchKeyWord
          )
        ]);

        const [storeInformationResult] = await Promise.all([
          Api.fetchStoreInformation(
            storeId,
            token
          )
        ]);

        setUserCount(usersResult.userCount);
        setUsers(usersResult.users);
        setStoreInformation(storeInformationResult);
        setPageCount(Math.ceil(usersResult.userCount / pageSize));
        setLoadingState(loadingStates.loaded);


      } catch (e) {

        setLoadingState(loadingStates.error);
        setPersonaStoreProfileErrorMessage(helperFunctions.getCustomErrorObject(e));

      }
    };

    fetchData();
    
  }

  function handleRemoveStoreAccess() {

       setRemoveAccessInformation({userId: userIdForWhichAccessIsToBeRemoved, storeId});
       
       if(!isAccessBeingRemoved)
       setRemoveAccessConfirmationDialogVisible(false);

  }


  function removeStoreAccessClicked(userId, firstName, lastName) {
    setUserIdForWhichAccessIsToBeRemoved(userId);
    setFirstName(firstName);
    setLastName(lastName);
    setAddress(storeInformation[0].businessAddress.address1)
    setRemoveAccessConfirmationDialogVisible(true);

  }


  // #endregion

  // #region Effects
  useEffect(
    () => {
     
     if(!isAccessBeingRemoved)
      Fetch();
    },
    [currentPageNumber,isAccessBeingRemoved,userSearchKeyWord]
  );

  useEffect(
    () => {
      setPersonaStoreProfileErrorMessage(isRemoveStoreAccessError);
    },
    [isRemoveStoreAccessError]
  );

  // #endregion

  // #region International messages
  const messages = defineMessages({
    store_profile__title: {
      id: "store_profile__title",
      description: "Store profile",
      defaultMessage: "Store profile"
    },

    store_profile_name: {
      id: "store_profile_name",
      description: "Name",
      defaultMessage: "Name"
    },

    store_profile_email_address: {
      id: "store_profile_email_address",
      description: "Email address",
      defaultMessage: "Email address"
    },

    store_profile__pagination_results_caption: {
      id: "store_profile__pagination_results_caption",
      description: "Showing results {first}-{last} of {userCount}",
      defaultMessage: "Showing results {first}-{last} of {userCount}"
    },
    store_profile__search_previous: {
      id: "store_profile__search_previous",
      description: "previous",
      defaultMessage: "previous"
    },
    store_profile__search_next: {
      id: "store_profile__search_next",
      description: "next",
      defaultMessage: "next"
    },
    add_users_to_store__add_user_title: {
      id: "add_users_to_store__add_user_title",
      description: "Add user to this store",
      defaultMessage: "Add user to this store"
    },
    store_profile__admin: {
      id: "store_profile__admin",
      description: "Administrator",
      defaultMessage: "Administrator"
    },
    store_profile__remove_access: {
      id: "store_profile__remove_access",
      description: "Remove access",
      defaultMessage: "Remove access"
    },
    store_profile__back: {
      id: "store_profile__back",
      description: "Back to manage stores",
      defaultMessage: "Back to manage stores"
    },
    store_profile_no_users_text: {
      id: "store_profile_no_users_text",
      description: "There are no users who have access to this store.",
      defaultMessage: "There are no users who have access to this store."
    },
    remove_access__title: {
      id: "remove_access__title",
      description: "Remove user access",
      defaultMessage: "Remove user access"
    },
    remove_access_message: {
      id: "remove_access_message",
      description: "Are you sure you want to remove {firstName} {lastName}'S access for {vendorId} at {address}? Click yes to continue. Click no to cancel.",
      defaultMessage: "Are you sure you want to remove {firstName} {lastName}'S access for {vendorId} at {address}? Click yes to continue. Click no to cancel."
    },
    remove_access_yes: {
      id: "remove_access_yes",
      description: "Yes",
      defaultMessage: "Yes"
    },
    remove_access_no: {
      id: "remove_access_no",
      description: "No",
      defaultMessage: "No"
    },
    store_profile__search_text_place_holder: {
      id: "store_profile__search_text_place_holder",
      description: "Search for user",
      defaultMessage: "Search for user"
    },
    store_profile__pagination_results_search_caption: {
      id: "store_profile__pagination_results_search_caption",
      description: " for the search term",
      defaultMessage: " for the search term"
    },
    return: {
      id: "return",
      description: "Return button",
      defaultMessage: "Return"
    },  
    store_profile_search_no_users_text: {
      id: "store_profile_search_no_users_text",
      description: "No results found for this search criteria.",
      defaultMessage: "No results found for this search criteria."
    }, 
  });
  // #endregion

  // #region Render
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          userSearchKeyWord: "",
          UserList: []
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSearchClick(values.userSearchKeyWord);
        }}

      >

        {props => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              {<RemoveAccessModal
                FormattedMessageTitle={messages.remove_access__title}
                FormattedMessageBody={messages.remove_access_message}
                FormattedMessageOkButtonText={messages.remove_access_yes}
                FormattedMessageCancelButtonText={messages.remove_access_no}
                onOKClickEvent={handleRemoveStoreAccess}
                onCancelClickEvent={() => { setRemoveAccessConfirmationDialogVisible(false); }}
                showCancelButton={true}
                show={showRemoveAccessConfirmationDialog}
                vendorId={storeId}
                firstName={firstName}
                lastName={lastName}
                address={address}
                isFetchingData={isAccessBeingRemoved}
              />}                              
              <div>

                <PageLayout>
                  <FormWrapper
                    key="storeProfileErrorMessage"
                    id="storeProfileErrorMessage"
                    formErrors={personaStoreProfileErrorMessage}
                  />
                  <Subhead>
                    <FormattedMessage {...messages.store_profile__title} />

                  </Subhead>
                  <Row>

                    <Col >

                      <NameHeader>
                        {storeInformation == null
                          ? false
                          :
                          <FieldGroup>
                            {storeInformation.map(store => {
                              return (<div key="userText">
                                <Label>{store.businessName}</Label>
                                <Label>{store.id} {store.storeNumber != "" ? 
                                   "/ " + store.storeNumber : false
                                   }</Label>
                                <Label>{store.businessAddress.address1}</Label>
                                <Label>{store.businessAddress.city}{", "}{store.businessAddress.state}{" "}{store.businessAddress.zipCode}</Label>
                                <Label> {store.businessPhone.substr(0, 3) +
                                  "-" +
                                  store.businessPhone.substr(
                                    3,
                                    3
                                  ) +
                                  "-" +
                                  store.businessPhone.substr(6, 4)}</Label>

                              </div>
                              )
                            })}
                          </FieldGroup>
                        }

                      </NameHeader>
                    </Col>
                  </Row>
                  <div css={`
                    display: flex;
                    justify-content: flex-end;
                  `}>                        
                    <div css={`
                      position: relative;
                    `}>

                      <Input
                        id="txtUserSearch"
                        type="text"
                        value={values.userSearchKeyWord}
                        onChange={e => {
                          setFieldValue(
                            "userSearchKeyWord",
                            e.target.value,
                            true
                          );


                        }}
                        placeholder={formatMessage(
                          messages.store_profile__search_text_place_holder
                        )}
                        onBlur={handleBlur}
                        error={errors.userSearchKeyWord}
                        touched={touched.userSearchKeyWord}
                      />
                      
                      {!!values.userSearchKeyWord &&
                        <ClearImageStyled 
                          src='/assets/VMS_33button_kill_clear_inline.png'                           
                          onClick={()=>{
                            setUserSearchKeyword('');
                            setCurrentPageNumber(0); 
                            setFieldValue("userSearchKeyWord", '', true);                                
                          }}
                        />
                      }
                    </div>
                    
                    <div>
                      &nbsp;
                      <span style={{ display: "inline-block" }}>
                        {searchResultLoadingState ==
                          loadingStates.loading
                          ? <div
                            style={{
                              display: "inline-block",
                              width: "25px",
                              height: "25px",
                              position: "inherit",
                              "vertical-align": "top"
                            }}
                          >
                            <Spinner />
                          </div>
                          : <SubmitImage
                            type="image"
                            src="/assets/VMS_search_button_blue.png"
                            alt="Submit"
                            onclick={handleSubmit}
                          />}
                      </span>
                      <div>
                        {errors.userSearchKeyWord &&
                          touched.userSearchKeyWord &&
                          <InputFeedback>
                            {errors.userSearchKeyWord}
                          </InputFeedback>}
                      </div>
                    </div>
                  </div>
                  <br />
                  {loadingState == loadingStates.loading
                    ? <Spinner />
                    : <div> {users == null || users.length == 0
                      ?
                      <div>
                        <Row>
                          <Col/>
                          <Col style={{ paddingRight: "1.5em" }}>
                            <Link
                              to={`/Persona/Stores/${storeId}/users/add`}
                            >
                              <Image src="/assets/VMS_33button_add_Clear background.png" />
                              <b> <FormattedMessage {...messages.add_users_to_store__add_user_title} /></b>
                            </Link>
                          </Col>

                        </Row>

                      </div>
                      : <div>
                        <Row>
                          <FormTextGray>
                            <FormattedMessage
                              values={{
                                first: (currentPageNumber * pageSize) + 1,
                                last: (currentPageNumber * pageSize) + users.length,
                                userCount: userCount
                              }}
                              {...messages.store_profile__pagination_results_caption}
                            />
                            {userSearchKeyWord != undefined && userSearchKeyWord != '' ?
                            <span>
                              <span>
                                <FormattedMessage                                  
                                  {...messages.store_profile__pagination_results_search_caption}
                                />                                
                              </span>
                              {' "'}
                              <span css={`
                                color: ${({theme})=>theme.color.primaryBlue};
                              `}>
                                {userSearchKeyWord}
                              </span>
                              {'"'}
                            </span> : false
                          }

                          </FormTextGray>


                          <Col style={{ paddingRight: "1.2em" }}>
                            <Link
                              to={`/Persona/Stores/${storeId}/users/add`}
                            >
                              <Image src="/assets/VMS_33button_add_Clear background.png" />
                              <b> <FormattedMessage {...messages.add_users_to_store__add_user_title} /></b>
                            </Link>
                          </Col>


                        </Row> </div>}
                      <br />
                      <Row fontWeight="bold">
                        <Col width="20em">
                          <RowLabel>
                            <FormattedMessage
                              {...messages.store_profile_name}
                            />

                          </RowLabel>
                        </Col>

                        <Col width="10em">
                          <RowLabel>
                            <FormattedMessage {...messages.store_profile__admin} />
                          </RowLabel>
                        </Col>
                        <Col width="13em">
                          <RowLabel>
                            <FormattedMessage {...messages.store_profile__remove_access} />
                          </RowLabel>
                        </Col>
                      </Row>

                      <Row>
                        <Col width="100%">
                          <div
                            style={{
                              "borderTop": "2px solid black",
                              width: "100%"
                            }}
                          />

                        </Col>
                      </Row>

                      {(users == null || users.length == 0) && userSearchKeyWord === ""
                        ? <div>
                          <Row>
                            <NoResultsText>
                              <FormattedMessage {...messages.store_profile_no_users_text} />
                            </NoResultsText>
                          </Row>      
                        </div>
                        :                  
                        users == null || users.length == 0
                        ? <div>
                          <Row>
                            <NoResultsText>
                              <FormattedMessage {...messages.store_profile_search_no_users_text} />
                            </NoResultsText>
                          </Row>         
                        </div>
                        : !!users &&
                        users.map(user => {
                          return (
                            <div
                              key={user.id}
                              style={{
                                "borderBottom": "1px solid grey",
                                width: "100%",
                                "paddingTop": "5px"
                              }}
                            >
                              <Row>

                                <Col width="18em">
                                  <Link to={`/Persona/Users/${user.id}`}>
                                    {user.firstName} {user.lastName}
                                  </Link>
                                  <br />
                                  {user.emailAddress}
                                </Col>
                                <Col width="10em" padTop="5px">

                                  {user.isAdmin ? "YES" : "NO"}

                                </Col>
                                <Col width="10em" padTop="5px">
                                  <Image
                                    onClick={() => {
                                      removeStoreAccessClicked(user.id, user.firstName, user.lastName);
                                    }}
                                    src="/assets/Remove_Access_Light_Blue.png"

                                  />
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      {users == null || users.length == 0
                        ? false :
                        <Row>
                          <Col width="28rem" />
                          <Col width="38rem">
                            {userCount <= 20 ? false :
                              <ReactPaginate
                                previousLabel={formatMessage(
                                  messages.store_profile__search_previous
                                )}
                                nextLabel={formatMessage(
                                  messages.store_profile__search_next
                                )}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                disableInitialCallback={true}
                                forcePage={currentPageNumber}
                              />
                            }
                          </Col>
                          <Col width="15rem" />
                        </Row>}
                    </div>}
                </PageLayout>

                <Row>
                  <Col width="35rem" padLeft="3em" padBottom="3em" padTop={userCount <= 20 ? "11em" : "1em"}>
                    <ReturnLinkStyled onClick={routeParams.history.goBack}>
                      <Image src="/assets/VMS_33button_back_blue2.png" />
                      <b style={{ "paddingLeft": "5px" }}><FormattedMessage {...messages.return} /></b>
                    </ReturnLinkStyled>
                  </Col>
                </Row>
              </div>                
            </form>
          );
        }}

      </Formik>

    </div>
  );
  // #endregion
};
// #endregion

// #region Props
const mapStateToProps = (state, props) => {

  return {
    token: state.appState.getIn(["serverData", "shared", "personaToken"]),
    adminUserId: state.appState.getIn([
      "serverData",
      "shared",
      "personaUserId"
    ])
  };
};
// #endregion

export default connect(mapStateToProps)(injectIntl(StoreProfile));
