import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import './ContactInformationComponent.css';


class ContactInformationComponent extends React.Component {

    getContact(countryCode, login) {
        const messages = defineMessages({
            website_assistance_header: {
                id: 'website_assistance_header',
                description: 'website_assistance_header',
                defaultMessage: 'For questions related to your existing account including payments, or PartnerConnect website assistance please call <b>888-683-8327</b>, and select option #1 '
            },
            canadian_website_assistance_header: {
                id: 'canadian_website_assistance_header',
                description: 'Header for website assistance',
                defaultMessage: 'For questions related to your existing account including payments, or PartnerConnect website assistance please call <b>1-866-416-6542</b>, and select option #1 '
            },
            contact_form_header: {
                id: 'contact_form_header',
                description: 'contact_form_header',
                defaultMessage: 'To create a new supplier account, please use the link provided on the main webpage.'
            },
            po_assistance_header_anony: {
                id: 'po_assistance_header_anony',
                description: 'po_assistance_header_anony',
                defaultMessage: 'For assistance with an existing purchase order or to reach a Holman call center technician, please call one of the following: <br>USA<b>  1-866-274-1025</b></br> Canada<b>  1-800-363-7676</b></br> Mexico<b>  1-800-002-7492</b>'
            },
            po_assistance_header_anony_MEX:{
                id: 'po_assistance_header_anony_MEX',
                description: 'po_assistance_header_anony_MEX',
                defaultMessage: 'For assistance with an existing purchase order or to reach a Holman call center technician, please call : 1-800-002-7492'
            }

            
        });

        
            return <div className="container__width">
                <div className="row contact_form__header">
                    <FormattedMessage {...messages.contact_form_header}/>
                </div>                
                <div className="contact-text_wrapper-hr">
                    <hr className="hr__contact-information"/>
                </div>
                <div className="contact-text_wrapper2">
                    <div className="row contact-information__website-assistance">
                        <FormattedMessage {...messages.po_assistance_header_anony}  values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>
                    </div>
                </div>
            </div>;
        
    }


render()
{
    return <div>
        {this.getContact(this.props.country, this.props.login)}
    </div>;


}


}


export default ContactInformationComponent;