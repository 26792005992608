import React from 'react';
import { defineMessages, FormattedMessage,injectIntl } from 'react-intl';
import { Overlay, Popover} from 'react-bootstrap';
import * as actions from '../actions/Login';
import * as VMSCookie from 'utils/CookieHelper';
import { connect } from 'react-redux';
import './LoginHeader.css';
import Spinner from 'components/Spinner';
import * as sharedActions from '../../../../shared/actions/App';
import LoginHeaderSelector from '../selectors/LoginHeader';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import FormWrapper from 'components/FormWrapper';
import Help from 'components/Help';
import TranslationIcon from 'components/SiteTranslations';
import * as CookieHelper from 'utils/CookieHelper';
import {withRouter} from 'react-router-dom'

class LogInHeader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userIdStyle: undefined,
            passwordStyle: undefined,
            rememberMe: (VMSCookie.readCookie('vms_vendorId') == null || VMSCookie.readCookie('vms_vendorId') == '' ) ? false : true,
            vendorId: (VMSCookie.readCookie('vms_vendorId') == null || VMSCookie.readCookie('vms_vendorId') == '') ? '' : JSON.parse(VMSCookie.readCookie('vms_vendorId')).vendorId,
            showErrorMessage: false,
            className: "login-header__input_box",
            languageLocaleCookie:(VMSCookie.readCookie('vms_language_preference') == null || VMSCookie.readCookie('vms_language_preference') == '' ) ? "en-US" : JSON.parse(VMSCookie.readCookie('vms_language_preference')).languageLocaleCookie,
        }
    }

    onLogInClick(event) {
        event.preventDefault();

        const userId = this.refs.txtUserId.value;
        const password = this.refs.txtPassword.value;

        if (userId != '' && password != '') {

            this.props.dispatch(actions.signInClicked(userId.trim(),password,this.refs.rememberMe.checked));

        }

    }
    componentDidUpdate(prevProps) {
        // Queue a new request to load data
        if (this.props.errorMessage != prevProps.errorMessage) {
            if (!!this.props.errorMessage)
                this.setState({
                    showErrorMessage: true
                });
            else {
                this.setState({
                    showErrorMessage: false
                });
            }
        }
        
    }
    onRememberMeChange() {
        this.setState({rememberMe: this.refs.rememberMe.checked});
    }

    onHide(){

        this.props.dispatch(actions.clearLoginErrorMessage()) ;

    }

    validatePassword() {

        return true;
    }

    validateUserId() {

        return true;

    }



    onFlagChange(event) {
        
        const country = event.currentTarget.attributes.value.value;
        this.props.dispatch(sharedActions.setSelectedCountry(country));
        if(country==='MEX'){
        this.props.dispatch(sharedActions.selectLocale('es-MX'));
        CookieHelper.createCookie('vms_language_preference', JSON.stringify({
            languageLocaleCookie:'es-MX',
        }));
        } else if(this.state.languageLocaleCookie=='es-MX'){
            this.props.dispatch(sharedActions.selectLocale('en-US'));
        CookieHelper.createCookie('vms_language_preference', JSON.stringify({
            languageLocaleCookie:'en-US',
        }));
        }
    }

    componentDidMount() {
        if (VMSCookie.readCookie('vms_vendorId') != null && VMSCookie.readCookie('vms_vendorId') != '') {
            this.props.dispatch(sharedActions.setSelectedCountry(JSON.parse(VMSCookie.readCookie('vms_vendorId')).selectedCountry));
        }
        if(VMSCookie.readCookie('vms_language_preference') !== null && VMSCookie.readCookie('vms_language_preference') !== '' ) {
            this.props.dispatch(sharedActions.selectLocale(this.state.languageLocaleCookie));
        }
    }

    render() {
        const {formatMessage} = this.props.intl;
        const messages = defineMessages({
            login_id: {
                id: 'login_id',
                description: 'ID',
                defaultMessage: 'ID'
            },
            password: {
                id: 'password',
                description: 'Password',
                defaultMessage: 'Password'
            },
            rememberMe: {
                id: 'rememberMe',
                description: 'Remember me',
                defaultMessage: 'Remember me'
            },
            selectCountry: {
                id: 'selectCountry',
                description: 'Select your country',
                defaultMessage: 'Select your country'
            },
            headerCaption: {
                id: 'headerCaption',
                description: 'Supplier sign in',
                defaultMessage: 'Supplier sign in'
            },
            recover_password_text:{
                id:'recover_password_text',
                description:'Forgot password link',
                defaultMessage:'Forgot your password?'

            }
        });

        return <div className='login'>

            <div className="login-header">
                <form className="form-inline">

                    <div className="form-group login-header__logo-caption">

                    <img className="login-header__logo"src="/assets/Logo-Primary-PMS.png"/>
                        
                    </div>
                    <div className="form-group login-header__id-password-group">
                        <div>

                            <div className="form-group">

                                    <label
        className="login-header__input_label_small"><FormattedMessage {...messages.selectCountry} /></label>

</div>


<div className="form-group">

        <img ref="imgUS" src="/assets/VMS_US_Flag.jpg" className={classnames(
        {'login-image-selected': this.props.selectedCountry=='USA'},
        {'none':this.props.selectedCountry!='USA'}
                            ) } title="United States" role="button" value="USA" onClick={this.onFlagChange.bind(this)}/>
                            {" "}


                                </div>


                                <div className="form-group">

                                        <img ref="imgCanada" className={classnames(
                                {'login-image-selected': this.props.selectedCountry=='CAN'},
                                {'none':this.props.selectedCountry!='CAN'}
                            ) }
                                    src="/assets/VMS_CANADA_Flag.jpg" role="button" value="CAN" title="Canada"
                                    onClick={this.onFlagChange.bind(this)}/>                            
                       </div>
                       <div className="form-group">

                           <img ref="imgMexico" className={classnames(
                           {'login-image-selected': this.props.selectedCountry=='MEX'},
                           {'none':this.props.selectedCountry!='CAN'}
                                    ) }
    src="/assets/VMS_MEXICO_Flag.jpg" role="button" value="MEX" title="Mexico"
    onClick={this.onFlagChange.bind(this)}/>                            
        </div>
                                
   <div className="form-group">
   <span className="login-header__nav-link visible-lg">|</span>
   </div>
   <div className="form-group">
       <TranslationIcon backgroundColor='grey' />
   </div>
</div>

<div>
   <div className="form-group">
       <div>
           <div className="form-group">
               {this.props.errorMessage == undefined ? false :
                   <Overlay
                       show={this.props.errorMessage==undefined?false:true}
    onHide={this.onHide.bind(this)}
        placement="bottom"
        target={props=>ReactDOM.findDOMNode(this.refs.txtUserId)}
        container={this}
        rootClose
    >
        <Popover id="popOver" className="ErrorMessage">
            {this.props.errorMessage}
        </Popover>
    </Overlay>
    }
                                        <input type="text"
                                               onBlur={this.validateUserId.bind(this)}
                                            cols="6"
                                            className="login-header__input_box2 form-control"
                                            size="10"
                                            maxLength="50"
                                            ref="txtUserId"
                                            defaultValue={this.state.vendorId}
                                            placeholder={"ID"}
                                            autoFocus


                                     />
                                 </div>

                                 <div className="form-group login-header__password-group">
                                     <div><input type="password"
                                            className="login-header__input_box form-control"
                                            size="12"
                                            ref="txtPassword"
                                            onBlur={this.validatePassword.bind(this)}
                                            cols="10"
                                            maxLength="24"
                                            placeholder={formatMessage(messages.password)}

                                />
                                </div>
                            </div>



                        </div>
                    </div>


                    <div className="form-group login-header__submit">

                        <div className="login-header__submit-btn">
                            <div>
                                <input type="image" src="/assets/VMS_33button_go_bblue.png" role="button"
                                            onClick={this.onLogInClick.bind(this)}/>
                                 </div>
                                 <div className="login-header__input_label"><span>&nbsp;</span></div>
                             </div>
                                            {this.props.isfetchingData
                                                ? <div className="login-header__submit-spinner">
                                                    <div className="login-header__spinner-container">
                                                        <Spinner />
                                                    </div>
                                                    <div className="login-header__input_label"><span>&nbsp;</span></div>
                                                </div>

                                                : false
                                            }

                                            </div>
                                            <div>
                                            <div className=" login-header__remember_me_checkbox">

                                                <input type="checkbox"
                                            tabIndex="-1"
                                            ref="rememberMe"
                                            checked={this.state.rememberMe}
                                            onChange={this.onRememberMeChange.bind(this)}
                                            className="login-header__remember_me_checkbox"/>
                                     <label className="login-header__remember_me"><FormattedMessage {...messages.rememberMe} /></label>
                                 </div>
                                 </div>
                                 <div className="login-header__input_label_password">
                                 <Link className="login-header__input_label_password" to='/recoverPassword' >
                                     <FormattedMessage {...messages.recover_password_text}/>
                                 </Link>
                                     {this.props.selectedCountry=="MEX"?false:<Help videoId="username_and_password"/>}
                                 </div>
                             </div>


                         </div>
                     </form>
                 </div>

             </div>;
                                        }
}


export default connect(LoginHeaderSelector)(injectIntl(LogInHeader));