// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
// our stuff
import './FraInspectionResultConfirmation.css';
import RailGearInformation from './RailGearInformation';
import FraResult from './FraResult';
import Spinner from 'components/Spinner';

class FraInspectionResultConfirmation extends React.Component {                    
    render() {
		const messages = defineMessages({
			fra_inspection__title: {
                id: 'fra_inspection__title',
                description: 'FRA inspection title',
                defaultMessage: 'FRA inspection'
			},			
			fra_inspection__edit_form: {
                id: 'fra_inspection__edit_form',
                description: 'View/edit form button',
                defaultMessage: 'View/Edit FRA inspection form'
            },                
            fra_inspection__save_continue: {
                id: 'fra_inspection__save_continue',
                description: 'Save and continue button',
                defaultMessage: 'Save and continue'
            },             
		});

        return (
            <div>
                <div className='fra_inspection_result_confirmation__title'>                
					<FormattedMessage {...messages.fra_inspection__title} />					
				</div>

                <div className='fra_inspection_result_confirmation__outer_box'>

					<RailGearInformation 
						formData={this.props.formData}
						setFormData={this.props.setFormData}
						formValidationMessages={this.props.formValidationMessages}
					/>					

                    <FraResult 
                        formData={this.props.formData}
						userLocale={this.props.userLocale}
						poCreationDate={this.props.poCreationDate}
                        setFormData={this.props.setFormData}
                        formValidationMessages={this.props.formValidationMessages}
                        onInspectionFailClick={this.props.onConfirmationFailClick}
						onInspectionDateChangeHandler={this.props.onInspectionDateChangeHandler}
                    />

					<div className='row fra_inspection_result_confirmation__row'>

						<div className='col-xs-offset-6 col-xs-6 fra_inspection_result_confirmation__col'>

							<div 
								className='fra_inspection_result_confirmation__edit_form_button' 							
								onClick={this.props.onEditFraFormClick}
							>
								<FormattedMessage {...messages.fra_inspection__edit_form}/>                        
							</div>

						</div>

					</div>

                </div>          

                <div className='fra_inspection_result_confirmation__footer'>        

					<div className='row fra_inspection_result_confirmation__row'>

						<div className='col-xs-offset-6 col-xs-6 fra_inspection_result_confirmation__col'>
						
							{ this.props.formData.get('hasInspectionPassed') !== false && 
								<div className='fra_inspection_result_confirmation__save_button' 
									onClick={this.props.onSaveConfirmationClick} 
								>
									<FormattedMessage {...messages.fra_inspection__save_continue}/>{' '}
									<img src='/assets/VMS_33button_add_blue.png' />
									<div className='fra_inspection_result_confirmation__spinner'>
										{this.props.isSaving &&
											<Spinner spinnerType='blue' />										
										}
									</div>
								</div>                        
							}

						</div>

					</div>                					

				</div>
            </div>
        );
    }
}

export default FraInspectionResultConfirmation;