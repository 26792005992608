import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import LineItems from './LineItems';
import './Diagnosis.css';

class Diagnosis extends React.Component {
    render() {
        const messages = defineMessages({
            po_actions_diagnosis_view_caption: {
                id: 'po_actions_diagnosis_view_caption',
                description: 'Diagnosis',
                defaultMessage: 'Diagnosis'
            }
        });

        return <div className="adjust_po_diagnosis_view">
            <div className="row adjust_po_diagnosis_view__caption">
                <div className="col-sm-12">
                    <FormattedMessage {...messages.po_actions_diagnosis_view_caption} />
                </div>
            </div>

            <LineItems lineItems={this.props.lineItems}
                       actionType={this.props.actionType}
                       complaintCode="DG99"
                       isServiceComplaint={true}
                       hideCostInformation = {this.props.hideCostInformation}
                        onOpenTiresInfo = {this.props.onOpenTiresInfo}
                       selectedCurrency={this.props.selectedCurrency}
                       />
        </div>;
    }
}



export default Diagnosis;