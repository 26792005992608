import React from 'react';
import  ReactDoM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Spinner from 'components/Spinner';
import moment from 'moment';
import {Modal, Button} from 'react-bootstrap';
import AddServiceLineItems from '../components/AddServiceLineItems';
import  * as addServiceLineItemsViewActions from '../actions/AddServiceLineItemsView';
import * as addNewLineItemsViewActions from '../../../screens/AddNewLineItemsView/actions/AddNewLineItemsView';
import AddServiceLineItemsViewSelector from '../selectors/AddServiceLineItemsView';
import * as constants from 'constants/App';
import * as sharedActions from '../../../../../../shared/actions/App';
import FormWrapper from 'components/FormWrapper';
import { falseIfUndefined } from 'utils/HelperFunctions';
import configuration from 'configuration';

class AddServiceLineItemsView extends React.Component {

    constructor(props) {
        super(props);
    }

    downLoadPMInformation = (e) => {
        let pmUrl = `${configuration.serviceURL}v1/Vehicles/${this.props.vehicleDetails.get('id')}/pmInformation?reportType=Pdf&token=${this.props.token}`;

        this.props.dispatch(sharedActions.downLoadRemoteFile('pmInformation', pmUrl));
    }


    componentDidMount(){
        this.props.dispatch({type: constants.ATA_PM_PARAMETERS_SCREEN_LOADED,productCode: this.props.serviceATAList});
    }
    
    /*Gets ATA code description for a given ATA code
     * Takes
     *  ataCode: ATA code
     * Returns string of ata code description*/
    getATACodeDescription(ataCode) {

        return this.props.ataCodes.getIn([ataCode,'description']);

    }

    /*Gets the complaint code for a given ATA code
     * Takes
     *  ataCode: ATA code
     * Returns string of complaint code*/
    getATAComplaintCode(ataCode) {
        /*Return MA99 because this is a PM PO*/
        return 'MA99';
    }

    onSaveServiceLineItems() {
        this.props.dispatch({ type: constants.ADD_SERVICE_LINE_ITEM_CLICKED });

    }

    /* Saves the populated ATA codes, clears the temporarily saved ATA codes and shows PO line item modal*/
    onSaveAndAddServiceLineItems() {
       
        this.onSaveServiceLineItems();
        this.props.dispatch(addNewLineItemsViewActions.addComplaintCode('MA99'));
        this.props.dispatch(addNewLineItemsViewActions.LoadFrequentlyUsedATACode(this.displayName, this.props.vehicleDetails.get('id'), 'MA99'));

        this.props.dispatch(addNewLineItemsViewActions.toggleShowWindow());


    }

    /* Handles key change event for cost and labor fields on Modal AddServiceLineItems
     * Takes
     *   inputType: either PART or LABOR
     *   ataCode: unique key for the field which will be ATA code
     *   e: event arguments*/
    onHandleChangeForAddServiceLineItems(inputType, ataCode, e,modelValue,viewValue) {

        //compose line item (or the ATA code)
        const serviceLineItems = Map({
            lineItemValue: viewValue,
            lineItemKey: ataCode,
            lineItemType: inputType,
        });
        //remove or add line item (part or labor) depending on the field value
        this.props.dispatch(addServiceLineItemsViewActions.addServiceLineItem(serviceLineItems));
    }

    /* Close (or hide) the modal and remove the temporarily saved service ata codes*/
    closeModalAddServiceLineItemsWithoutSave() {
        this.clearCosts();
        this.props.dispatch(addServiceLineItemsViewActions.toggleShowWindow());
        this.props.onClearServices();
    }

    /* Removes temporarily saved ata codes with part and labor cost*/
    clearCosts() {
            this.props.dispatch(addServiceLineItemsViewActions.removeServiceATACode(Map()));
    }

    render() {
        return (
            <Modal show={this.props.showAddServiceLineItemsWindow}
                   backdrop="static"
                   onHide={()=>this.props.dispatch(addServiceLineItemsViewActions.toggleShowWindow())}>
                <Modal.Body>
                    <FormWrapper key='addServiceLineItemsErrorMessage' 
                         id="addServiceLineItemsErrorMessage" {...this.props} 
                         formErrors={this.props.addServiceLineItemsErrorMessage} />
                    
                    <AddServiceLineItems
                        serviceATACodes={this.props.serviceATAList}
                        saveServiceLineItems={this.onSaveServiceLineItems.bind(this)}
                        saveAndAddServiceLineItems={this.onSaveAndAddServiceLineItems.bind(this)}
                        parameterList={this.props.parameterList}
                        parentProps = {this.props}
                        handleChange={this.onHandleChangeForAddServiceLineItems.bind(this)}
                        closeWithoutSave={this.closeModalAddServiceLineItemsWithoutSave.bind(this)}
                        ataAndReferencePmParametersDescription={this.props.referencePmParametersDescription}
                        referenceRulePmParametersDescription={this.props.referenceRulePmParametersDescription}
                        isFetchingData={this.props.isFetchingData}
                        downLoadPMInformation={this.downLoadPMInformation.bind(this)}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        ...AddServiceLineItemsViewSelector(state, props),      
        addServiceLineItemsErrorMessage: state.appState.getIn(['uiData','addServiceLineItemsView', 'errorMessage']),
        token: state.appState.getIn(['serverData', 'shared', 'token']),
        isFetchingData: falseIfUndefined(state.appState.getIn(['uiData', 'shared', 'componentsFetchingDataFromAPI', 'pmInformation']) == 'pmInformation'),

    };
}
export default connect(mapStateToProps)(AddServiceLineItemsView);
