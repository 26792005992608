import React, { useState, useEffect, useImperativeHandle } from "react";
import { Modal } from "react-bootstrap";
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from "styled-components";
import {
    ButtonRowStyled,
    ModalBody,
    ModalClose,  
    ModalMainHeader
} from "DesignSpecs/components/FormComponents";
import { useAppState } from 'shared/AppState/app-state';
import { sendOrderToCustomer } from 'utils/Api';
import * as constants from 'constants/App';
import { getCustomErrorObject } from 'utils/HelperFunctions';

const ModalStyled = styled(Modal).attrs({
    dialogClassName: "childStyle"
})`
.childStyle {
width: 900px;
}`;

const ModalBodyStyled = styled(ModalBody)`
    padding: 10px 1em 10px 0px
`;

const InputStyled = styled.textarea`
    width: 100%;
    height: 15rem;
    padding: 1em;
    resize: none;
`;

const ModalContent = styled.div`
    padding-left: 15px;
    margin-bottom: 30px;
`;

const BoldCaption = styled.div`
  text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-top: 2rem;
  padding-bottom: 1rem;
`;

const NotesInputContainer = styled.div`
	margin-top: 5px;
    margin-bottom: 10px;
	border-radius: 2px;
	background: #FFFFFF;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
	border: 2px solid rgb(140, 170, 202);`;

const ForwardForFurtherReview = React.forwardRef(({
    to,
    correspondingNoteId,
    routeParams,
    dispatch
}, ref) => {
    const [showForwardForFurtherReviewModal, setShowForwardForFurtherReviewModal] = useState(false);
    const [note, setNote] = useState('');
    const [noteTemp, setNoteTemp] = useState('');
    const [appState] = useAppState();
    const token = appState.token;
    const poNumber = routeParams.match.params.trackNumber;

    useImperativeHandle(ref, () => ({
        showModal() {
            setShowForwardForFurtherReviewModal(true);
        }
    }));

    useEffect(() => {
        (async () => {
            try {
                if (note != '') {
                    await sendOrderToCustomer(poNumber, {
                        to: constants.SEND_ORDER_NOTES_TO.CUSTOMER,
                        noteText: note,
                        correspondingNoteId
                    }, token);

                    routeParams.history.goBack();
                }
            }
            catch (e) {
                dispatch({ type: constants.ACTION_REQUIRED_SUBMIT_CLIENT_VENDOR_NOTES_REJECTED, errorMessage: getCustomErrorObject(e) });
            }
        })()
    }, [note]);

    const messages = defineMessages({
        close_dialog: {
            id: "close_dialog",
            description: "Close",
            defaultMessage: "Close"
        },
        forward_for_further_review_caption: {
            id: "forward_for_further_review_caption",
            description: "Forward for further review",
            defaultMessage: "Forward for further review"
        },
        forward_for_further_review_text0: {
            id: "forward_for_further_review_text0",
            description: "Please enter a note in the box below explaining why you would like the client to review this PO.",
            defaultMessage: "Please enter a note in the box below explaining why you would like the client to review this PO."
        },
        forward_for_further_review_text1: {
            id: "forward_for_further_review_text1",
            description: "Please provide your response below",
            defaultMessage: "Please provide your response below"
        },
        forward_for_further_review_submit_note: {
            id: "forward_for_further_review_submit_note",
            description: "Submit note",
            defaultMessage: "Submit note"
        }
    });

    function hideModal() {
        setShowForwardForFurtherReviewModal(false);
    }

    function onCloseClick(e) {
        e.preventDefault();
        hideModal();
    }

    function handleChange(e) {
        setNoteTemp(e.target.value)
    }

    function onSubmitClick(e) {
        e.preventDefault();
        setNote(noteTemp);
        hideModal();
    }

    return (
        <ModalStyled show={showForwardForFurtherReviewModal} backdrop="static" keyboard={false}>
            <ModalContent>
                <ButtonRowStyled>
                    <ModalClose role='button' onClick={onCloseClick}>
                        <img src="/assets/VMS_33button_kill_blue.png" />{' '}
                        <FormattedMessage {...messages.close_dialog} />
                    </ModalClose>
                    <div></div>
                </ButtonRowStyled>
                <ModalMainHeader>
                    <FormattedMessage {...messages.forward_for_further_review_caption} />
                </ModalMainHeader>
                <BoldCaption>
                    <FormattedMessage {...messages.forward_for_further_review_text0} />
                </BoldCaption>
                <ModalBodyStyled>
                    <NotesInputContainer>
                        <BoldCaption>
                            <FormattedMessage {...messages.forward_for_further_review_text1} />
                        </BoldCaption>
                        <InputStyled value={noteTemp} onChange={handleChange} />
                    </NotesInputContainer>
                    <ButtonRowStyled>
                        <div></div>
                        <ModalClose role='button' onClick={onSubmitClick}>
                            <FormattedMessage {...messages.forward_for_further_review_submit_note} />
                            {' '}<img src="/assets/VMS_33button_go_bblue.png" />
                        </ModalClose>
                    </ButtonRowStyled>
                </ModalBodyStyled>
            </ModalContent>
        </ModalStyled>);
});

export default ForwardForFurtherReview;