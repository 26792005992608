import { call, put, select } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import { isAuthenticated } from 'utils/HelperFunctions';
import VendorMinorityMap from 'reducers/VendorServices';


export function* submitAccountInfo(context) {
    try {
        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });
        yield put({ type: constants.SUBMIT_STEP_ACCOUNT_INFO_REQUESTED });
        // Read data from app state
        const state = yield select();
        const token = helperFunctions.getToken(state);
        const formData = state.appState.getIn(['uiData', 'registrationView', 'formData']);
        const selectedCountry= formData.get(constants.FORM_DATA.COUNTRY);
        const accountInfoData = {
            ariId: formData.get(constants.FORM_DATA.ARI_ID),
            taxId: formData.get(constants.FORM_DATA.TAX_ID),
            taxIdType: formData.get(constants.FORM_DATA.TAX_ID_TYPE),
            password: formData.get(constants.FORM_DATA.PASSWORD),
        }
        const response = yield call(Api.submitAccountInfo, accountInfoData, token);
        yield put({ type: "FETCH_TERMS_TEXT_REGISTER" });
        if(selectedCountry=='MEX')
            yield call(context.history.push, '/registration/bankingInfo');
    else
        yield call(context.history.push, '/registration/vendorTermsAndConditions');
        

    }
    catch (e) {
        yield put({ type: constants.SUBMIT_STEP_ACCOUNT_INFO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    }
}


export function* submitBankInfo(context) {
    try {
        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });
        yield put({ type: constants.SUBMIT_BANK_INFO_REQUESTED });
        // Read data from app state
        const state = yield select();
        const formData = state.appState.getIn(['uiData', 'registrationView', 'formData']);
        const ssn = helperFunctions.emptyStringIfUndefined(formData.get(constants.FORM_DATA.SSN_AREA) + formData.get(constants.FORM_DATA.SSN_GROUP) + formData.get(constants.FORM_DATA.SSN_SERIAL));
        const employerId = helperFunctions.emptyStringIfUndefined(formData.get(constants.FORM_DATA.EMPLOYER_ID_FIRST_PART) + formData.get(constants.FORM_DATA.EMPLOYER_ID_SECOND_PART));
        const businessNumber=helperFunctions.emptyStringIfUndefined(formData.get(constants.FORM_DATA.BUSINESS_NUMBER));
        const federalTaxId=helperFunctions.emptyStringIfUndefined(formData.get(constants.FORM_DATA.FEDERAL_TAX_ID));
        const formattedTaxId = helperFunctions.formatTaxId(ssn,employerId,businessNumber,federalTaxId);

        const accountType = state.appState.getIn(['serverData','shared','vendorAccountType']);
        const country=state.appState.getIn(['uiData', 'shared', 'selectedCountry']);// formData.get(constants.FORM_DATA.COUNTRY);
        
        const bankInfo = {
            vendorId: formData.get(constants.FORM_DATA.ARI_ID) +(country=='MEX'?'MM': country=='CAN'?'CC':'UA'),  //default the country to USA as adian registration is not in scope
            password: formData.get(constants.FORM_DATA.PASSWORD),
            bankAccountType: 'MAINTENANCE', //default bank account type
            routingNumber: country=='MEX'?null: country=='CAN'? ('0'+ formData.get(constants.FORM_DATA.INSTITUTE_ID)+formData.get(constants.FORM_DATA.TRANSIT_ID)): formData.get(constants.FORM_DATA.ROUTING_NUMBER),
            accountNumber:country=='MEX'?null: formData.get(constants.FORM_DATA.CHECKING_ACC_NUMBER),
            paymentEmail: formData.get(constants.FORM_DATA.PAYMENT_EMAIL).toUpperCase(),
            taxId: btoa(formattedTaxId.taxId),
            taxIdType: formattedTaxId.taxType,
            accountType: 'Individual'    
        }

        const response = yield call(Api.submitBankInfo, bankInfo);
        
        
        //navigate to the next step 
        yield call(context.history.push,'/registration/serviceProfile');
        yield put({ type: constants.SUBMIT_BANK_INFO_RESOLVED });
    }
    catch (e) {
        //redirect user to the registration page if the user is already registered
        if (e.code == 'API-067' || e.code == 'API-068') {
            yield call(context.history.push,'/registration/accountInfo');
            yield put({ type: constants.SUBMIT_STEP_ACCOUNT_INFO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });      
            yield put({ type: constants.SUBMIT_BANK_INFO_RESOLVED });   //marking banking info as resolved because the user will fill the info from start                  
        }
        else {
            yield put({ type: constants.SUBMIT_BANK_INFO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        }
    }
}

export function* fetchVendorServicesForRegistration(context) {

    try {
        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });
        yield put({ type: constants.FETCH_SERVICE_REQUESTED });
        const state = yield select();
        yield put({ type: constants.FETCH_VENDOR_MINORITY_FOR_REGISTRATION, VendorMinorityMap });
        const formData = state.appState.getIn(['uiData', 'registrationView', 'formData']);
        const country=state.appState.getIn(['uiData', 'shared', 'selectedCountry']);//formData.get(constants.FORM_DATA.COUNTRY)==undefined?'USA':formData.get(constants.FORM_DATA.COUNTRY);
        //Get valid token to fetch vendor services
        let vendorId = formData.get(constants.FORM_DATA.ARI_ID) + (country=='MEX'?'MM':country=='CAN'?'CC':'UA');
        let pwd = formData.get(constants.FORM_DATA.PASSWORD);
        var token = yield call(Api.authenticateVendor, vendorId, pwd,state);
        if(jwtDecode(token).hasOwnProperty("passwordExpirationDate"))
        {
            token = jwtDecode(token).token;
        }

        const vendorName = jwtDecode(token).vname;
        const vendorAccountType = jwtDecode(token).sub;
        const corporateId = jwtDecode(token).corporateId;        
        //Update country
        yield put({type: constants.SET_SELECTED_COUNTRY, country: country});        

        yield put({ type: constants.SAVE_SIGN_IN_DETAILS, vendorId: vendorId, vendorName, token,vendorAccountType,corporateId });
        
        //---------------------------------------------------

        const vendorInfo = {
            vendorId: vendorId,
            vendorCountry: country,//state.appState.getIn(['uiData', 'shared', 'selectedCountry']),
            vendorLocale: state.appState.getIn(['uiData', 'shared', 'selectedLocale'])
        }
        const vendorServiceMap = yield call(Api.fetchVendorServiceInformationForRegistration, token, vendorInfo);

        yield put({ type: constants.LOAD_VENDOR_SERVICES_FOR_REGISTRATION, vendorServiceMap: vendorServiceMap.vendorService });
        yield put({ type: constants.FETCH_SERVICE_RESOLVED });
    }
    catch (e) {
        yield put({ type: constants.FETCH_SERVICE_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    }
}

export function* submitVendorServices(context) {

    try {        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });
        yield put({ type: constants.SUBMIT_SERVICE_INFO_REQUESTED });
        const state = yield select();
        let vendorServices = state.appState.getIn(['uiData', 'registrationView', 'vendorServiceMap', 'services']);
        let vendorMinority = state.appState.getIn(['uiData', 'registrationView', 'vendorMinorityMap', 'minorityVendor']);

        yield call(Api.sendVendorServicesForRegistration, state, helperFunctions.getToken(state), vendorServices, vendorMinority);
        yield call(context.history.push,'/registration/authEmailNotification');
        yield put({ type: constants.SUBMIT_SERVICE_INFO_RESOLVED });
        
    }
    catch (e) {
        yield put({ type: constants.SUBMIT_SERVICE_INFO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    }
}

export function* saveAuthEmailNotificationConfiguration(context) {
    try {
        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });
        yield put({ type: constants.SUBMIT_AUTH_EMAIL_CONFIG_INFO_REQUESTED });

        const state = yield select();
        const formData = state.appState.getIn(['uiData', 'registrationView', 'formData']);

        const userId = formData.get(constants.FORM_DATA.ARI_ID);
        const password = formData.get(constants.FORM_DATA.PASSWORD);

        const authEmailConfigData = state.appState.getIn(['uiData', 'registrationView', 'authorizationEmailConfig']);
        const vendorId = helperFunctions.getVendorId(state);
        const isRegitered = authEmailConfigData.get('isRegistered');
        //SAVE ISREGISTERED
        const isRegisteredData = [{
            "op": "replace",
            "path": "consentToReceiveAuthorizationEmail",
            "value": isRegitered
        }]
        yield call(Api.saveAuthEmailNotificationConfigurationIsRegistered, vendorId, isRegisteredData, helperFunctions.getToken(state));

        const contacts = state.appState.getIn(['uiData', 'registrationView', 'authorizationEmailConfig', 'contacts']);
        
        //SAVE CONTACTS
        for (var index = 0; index < contacts.length; index++) {
            var contact = contacts[index];
            const notificationEmailConfig = {
                name: contact.name,
                email: contact.email,
                notifyApprovals: true,
                notifyPayments: false
            }
            yield call(Api.saveAuthEmailNotificationConfigurationEmails, vendorId, notificationEmailConfig, helperFunctions.getToken(state));
        }
    
        yield put({ type: constants.SIGN_IN_CLICKED, userId, password, createCookieOnSuccess: false });

        

        // navigate user to your account
        yield call(context.history.push,'/yourAccount');

        yield put({ type: constants.SUBMIT_AUTH_EMAIL_CONFIG_INFO_RESOLVED });
    }
    catch (e) {
        yield put({ type: constants.SUBMIT_AUTH_EMAIL_CONFIG_INFO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });

    }
}

export function* fetchTermsAndConditionText() {

    try{

        yield put({ type:  constants.FETCH_TERMS_AND_CONDITIONS_REQUESTED });

        const vendorTermsAndConditions =   yield call(Api.getTermsAndConditionText);

        yield put({ type: constants.SAVE_VENDOR_TERMS_AND_CONDITIONS,vendorTermsAndConditions });

        yield put({ type: constants.FETCH_TERMS_AND_CONDITIONS_RESOLVED });
    }catch(e){
        yield put({ type: constants.FETCH_TERMS_AND_CONDITIONS_REJECTED });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage:  helperFunctions.getGenericErrorObject(e) });

    }

}

export function* acceptedTermsAndConditions(context,action) {

    try{
        yield call(context.history.push,'/registration/bankingInfo');

    }catch(e){
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage:  helperFunctions.getGenericErrorObject(e) });

    }

}
