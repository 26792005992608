import { call, put, select } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import * as CookieHelper from 'utils/CookieHelper';
import jwtDecode from 'jwt-decode';
import * as fieldValidations from 'utils/FieldValidations';
import {fromJS} from 'immutable';

import * as sharedActions from 'screens/App/shared/actions/App';

export function* authenticateAPCPersonaUser(context, action) {
    // Get state object 
    const state = yield select();
    const selectedCountry=state.appState.getIn(['uiData', 'shared', 'selectedCountry']);
    
    // Inform application that data request has started
    yield put({ type: constants.SIGN_IN_DATA_REQUESTED });

    try {

        // Authenticate Vendor
        const token = yield call(Api.authenticatePersonaUser, action.userId, action.password, state);
    
        const personaToken=jwtDecode(token).token;
        const adminUser=jwtDecode(personaToken).adminUser;
        const personaUserId=jwtDecode(personaToken).id;
        

    yield put({type:"SAVE_PERSONA_SIGN_IN_DETAILS",personaToken:personaToken,
adminUser:adminUser,personaUserId:personaUserId});

        //Get User Information
        const personaUser= yield call(Api.fetchUserInformation,personaUserId,personaToken);
       
        yield put ({type:"SAVE_PERSONA_SIGN_IN_USER_DETAILS",personaUser:fromJS(personaUser[0])});
   
    // Log event of user signing in.
    yield call(Api.logUserSignedIn, personaUserId);

    // HolmanURLCheck    
    yield call(Api.checkHolmanURL,personaUserId);
  
   if(personaUser[0].defaultStoreId==null){
    if (jwtDecode(personaToken).termsAccepted === false) {
        yield call(context.history.push, `/personaTermsAndConditions?returnUrl=Persona/AccessStores`); 
    }
    else {
        yield call(context.history.push,'/Persona/AccessStores');
    }
    //Fetching user terms and Conditions   
    yield put({
        type: constants.FETCH_TERMS_STATUS,
        userId: action.userId,
        personaUserId,
        token : personaToken
    });
   }
   else  
    {
        if (jwtDecode(personaToken).termsAccepted === false) {
            yield call(context.history.push, `/personaTermsAndConditions?returnUrl=Persona/AccessStores`); 
            yield put({
                type: constants.FETCH_TERMS_STATUS,
                userId: action.userId,
                personaUserId,
                token : personaToken
            });
        }
        else {
            yield put({type: "PERSONA_ACCESS_STORE_CLICKED",
            storeId:personaUser[0].defaultStoreId});
        }
    } 
    
    }
    catch (e) {
        var customError = helperFunctions.getCustomErrorObject(e);
        yield put({ type: constants.FETCH_SIGN_IN_DATA_REJECTED, errorMessage: customError != undefined && customError.length > 0 ? customError[0].message : undefined });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SIGN_IN_DATA_REQUESTED, errorObject: e });
    }
}

export function* authenticate(context, action) {
    
    const userId = action.userId;
    const userIsAPCPersonaUser = helperFunctions.validateEmail(userId);
    if (userIsAPCPersonaUser)
        yield call(authenticateAPCPersonaUser,context, action);
    else
       yield call(authenticateVendor,context, action);
}

export function* authenticateVendor(context, action) {

    // Get state object 
    const state = yield select();
    let vendorId = '';
    let vendorDetails = '';
    const selectedCountry=state.appState.getIn(['uiData', 'shared', 'selectedCountry']);
    //If user selected country flag then use the input else format it with the country code.
    let suffix = action.userId.substr(action.userId.length - 2).toUpperCase();
    if (['UA', 'CC','MM'].includes(suffix)) 
        vendorId = action.userId.toUpperCase();
    else
        vendorId = helperFunctions.convertUserIdToVendorId(action.userId.toUpperCase(), state);

    // Inform application that data request has started
    yield put({ type: constants.SIGN_IN_DATA_REQUESTED });

    try {        

        // Authenticate Vendor
        const token = yield call(Api.authenticateVendor, vendorId, action.password,state);

        //Save token to app state.
        yield call(saveAuthenticatedUserDetails, token, context, action);

        if (['MM'].includes(suffix) || selectedCountry=='MEX')
        {
            yield put({type:"SET_SITE_READONLY_MODE", flag:true});     
            yield put({type:constants.SELECT_LOCALE,locale:'es-MX'});
        }   
        
        
    }
    catch (e) {
        var customError = helperFunctions.getCustomErrorObject(e);
        yield put({ type: constants.FETCH_SIGN_IN_DATA_REJECTED, errorMessage: customError != undefined && customError.length > 0 ? customError[0].message : undefined });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SIGN_IN_DATA_REQUESTED, errorObject: e });
    }
}

export function* fetchTermsAndConditionStatus(context, action) {

    try {
        const state = yield select();
        let termsAndConditionsFlag;
        const personaUserId= helperFunctions.emptyStringIfUndefined(state.appState.getIn(["serverData","shared","personaUserId"]));

        yield put({ type: constants.FETCH_TERMS_STATUS_REQUESTED });
      
        if(personaUserId != ""){

            termsAndConditionsFlag= yield call(Api.getUserTermsAcceptanceStatus, personaUserId, action.token);

        }else{

        let suffix = action.vendorId.substr(action.vendorId.length - 2).toUpperCase();    
       
        if(['MM'].includes(suffix)) termsAndConditionsFlag='Y';
        else termsAndConditionsFlag= yield call(Api.getVendorTermsAcceptanceStatus, action.vendorId, action.token);        

        }

        yield put({ type: constants.SAVE_TERMS_AND_CONDITIONS_STATUS, termsAndConditionsFlag: termsAndConditionsFlag.latestTermsAndConditionsAccepted });                

        yield put({ type: constants.FETCH_TERMS_STATUS_RESOLVED });

        if (termsAndConditionsFlag.latestTermsAndConditionsAccepted !== "Y") {

            yield put({ type: constants.FETCH_TERMS_TEXT,userId :personaUserId,token: action.token });

        }


    } catch (e) {
        yield put({ type: constants.FETCH_TERMS_STATUS_REJECTED });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });

    }

}

export function* fetchTermsAndConditionText(context, action) {

    try {
        const state = yield select();
        const personaUserId= action.userId;
        const languageLocale= state.appState.getIn(['uiData', 'shared', 'selectedLocale']);
        
        let vendorTermsAndConditions;

        yield put({ type: constants.FETCH_TERMS_AND_CONDITIONS_REQUESTED });
 
        if(personaUserId != "")

        vendorTermsAndConditions = yield call(Api.getUserTermsAndConditionText,action.token,languageLocale);

        else 

         vendorTermsAndConditions = yield call(Api.getTermsAndConditionText);

        yield put({ type: constants.SAVE_VENDOR_TERMS_AND_CONDITIONS, vendorTermsAndConditions });

        yield put({ type: constants.FETCH_TERMS_AND_CONDITIONS_RESOLVED });
    } catch (e) {
        yield put({ type: constants.FETCH_TERMS_AND_CONDITIONS_REJECTED });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });

    }

}




export function* acceptedTermsAndConditions(context, action) {

    try {
        const state = yield select();
        const vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
        const personaUserId= helperFunctions.emptyStringIfUndefined(state.appState.getIn(["serverData","shared","personaUserId"]));
        const languageLocale= state.appState.getIn(['uiData', 'shared', 'selectedLocale']);
        
        yield put({ type: constants.FETCH_TERMS_AND_CONDITIONS_ACCEPTED_REQUESTED });
 
        if (personaUserId != "") {

            yield call(Api.updateUserAcceptStatus, personaUserId,languageLocale, helperFunctions.getPersonaToken(state));            
            const refreshedToken = yield call(Api.refreshPersonaToken, helperFunctions.getPersonaToken(state), state);
            yield put({ type: "SET_PERSONA_TOKEN", personaToken: refreshedToken });
        }
        else {

            yield call(Api.updateVendorAcceptStatus, vendorId, helperFunctions.getToken(state));                                            
            const refreshedToken = yield call(Api.refreshToken, helperFunctions.getToken(state));            
            yield put({ type: "SET_TOKEN", token: refreshedToken });
        }        

        if (personaUserId != "") {
            const defaultStoreId = state.appState.getIn(["serverData","shared","personaUser","defaultStoreId"]);
            if(!!defaultStoreId){                      
                yield put({type: "PERSONA_ACCESS_STORE_CLICKED",
                    storeId:defaultStoreId});            
            } 
        }
                
        if (action.returnUrl)
            yield call(context.history.replace, action.returnUrl);        

        yield put({ type: constants.FETCH_TERMS_AND_CONDITIONS_ACCEPTED_RESOLVED });

    } catch (e) {                
        yield put({ type: constants.FETCH_TERMS_AND_CONDITIONS_ACCEPTED_REJECTED });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });

    }

}

export function* fetchDealerDraftStatus(context, action) {

    try {

        const state = yield select();

        const dealerDraft = yield call(Api.dealerDraftStatus, state, action.token);

        yield put({ type: "SAVE_DEALER_DRAFT_DETAILS", dealerDraft: dealerDraft.isDealer });


    } catch (e) {
        yield put({ type: "SAVE_DEALER_DRAFT_DETAILS", dealerDraft: false });

    }

}

export function* saveSSOUserDetails(context, action) {
    try {
        yield call(saveAuthenticatedUserDetails, action.token, context, null, true);
    } catch (e) {

        var customError = helperFunctions.getCustomErrorObject(e);
        yield put({
            type: constants.FETCH_SIGN_IN_DATA_REJECTED,
            errorMessage: customError != undefined && customError.length > 0 ? customError[0].message : undefined
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.SIGN_IN_DATA_REQUESTED,
            errorObject: e
        });
    }
}

export function* authenticateStoreAccessforPersonaUser(context, action){
    // Get state object 
    
    const state = yield select();
    const personaToken=state.appState.getIn(['serverData', 'shared', 'personaToken']);
    const personaUserId=state.appState.getIn(['serverData', 'shared', 'personaUserId']);
    
    // Inform application that data request has started
    yield put({ type: constants.SIGN_IN_DATA_REQUESTED });

    try {

        // Authenticate Vendor
        const storeAccessToken = yield call(Api.getStoreAccessTokenForPersonaUser, personaUserId, action.storeId, personaToken);

        yield call(saveAuthenticatedUserDetails,storeAccessToken.token, context, action);

        yield put({type: "PERSONA_USER_STORE_ACCESS_RESOLVED"});

    }
    catch (e) {        
        yield put({ type: "PERSONA_USER_STORE_ACCESS_REJECTED", errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        //yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SIGN_IN_DATA_REQUESTED, errorObject: e });
    }
    
}


export function* saveAuthenticatedUserDetails(token, context, action,isSSOUser=false) {    

    const state = yield select();
    let vendorId = '';
    let vendorDetails = '';

    // save vendor details in app state
    
    const isPersonaUser=jwtDecode(token).aud === 'ARIEXTERNALSIGNON';
    const isARIUser =isPersonaUser?false: jwtDecode(token).sub === 'ARIUSER';

    /* Token of /app/apc/token endpoint for NonPersonaUser login looks like
    {
        "id": vendorId,
        "token": vendor token,
        "passwordExpirationDate": vendor_profile.password_expiry_date
    }
    */
   
    if (!isPersonaUser && !isARIUser && jwtDecode(token).hasOwnProperty("passwordExpirationDate")) {
        yield put({
            type: constants.SAVE_PASSWORD_EXPIRY_DATE,
            passwordExpirationDate: jwtDecode(token).passwordExpirationDate
        });

        token = jwtDecode(token).token;
    }

    const jwtToken = jwtDecode(token);
    var vendorName, vendorAccountType, corporateId, id, audien;

    //const personaStorePermissions;
    
    audien=jwtToken.aud;
    if(isARIUser){
        vendorName=jwtToken.obo.vname;
        vendorAccountType=jwtToken.obo.sub;
        corporateId=jwtToken.obo.corporateId;
        id=jwtToken.obo.id;        
    }
    else if(isPersonaUser) //Persona User
    {
        vendorName=jwtToken.vendor.vname;        
        vendorAccountType=jwtToken.vendor.type;        
        id=jwtToken.vendor.id;      
        corporateId=null;  
        //personaUserPermissions=jwtToken.vendor.permissions;
    }
    else{
        vendorName=jwtToken.vname;
        vendorAccountType=jwtToken.sub;
        corporateId=jwtToken.corporateId;
        id=jwtToken.id;    
    }

    vendorId = id != null && id != "" ? id : corporateId;

    // if userId already has country code  // TODO when Mexican vendors are added to VMS, add 'MM' to array
    let suffix = vendorId.substr(vendorId.length - 2).toUpperCase();
    if (['UA', 'CC','MM'].includes(suffix)) {        
        let country = suffix === 'UA' ? 'USA' : suffix === 'CC' ? 'CAN':'MEX';
        yield put({ type: constants.SET_SELECTED_COUNTRY, country });
    }

    yield put({
        type: constants.SAVE_SIGN_IN_DETAILS,
        vendorId: id,
        vendorName,
        token,
        vendorAccountType,
        corporateId,
        isSSOUser
    });

    // If user is APC user save vendor id in cookie otherwise no cookie for SSO user.
    if (audien == "VMS") {
        if (action.createCookieOnSuccess)
            CookieHelper.createCookie('vms_vendorId', JSON.stringify({
                vendorId: action.userId,
                selectedCountry: helperFunctions.getSelectedCountry(state)
            }));
        else
            CookieHelper.eraseCookie('vms_vendorId');
    }

    
    if (vendorAccountType === "Individual" || vendorAccountType === "Master" || vendorAccountType == 'Sub') {
        vendorDetails = yield call(Api.fetchVendorDetails, vendorId, token);
    }

    yield put({
        type: "SAVE_VENDOR_TYPE",
        serviceClassification: vendorDetails.serviceClassification
    });
    
    yield put({
        type: "SAVE_VENDOR_ADDRESS",
        businessAddress: vendorDetails.businessAddress
    });

    yield put({ type: "VENDOR_PAYMENT_STATUS", paymentType: vendorDetails.paymentType });

    //fetchVendorLaborRateInformation
    const laborRateDetails=yield call(Api.fetchVendorLaborRateInformation, vendorId, token);
    
    yield put({
        type:  "SAVE_VENDOR_LABOR_RATE_DETAILS",
        laborRateDetails: laborRateDetails
    });

    
    /***Field validations functionality*/

    // Step 1 - Is work completion feature enabled?
    let isWorkCompletionFeatureEnabled = yield call(Api.fetchIsWorkCompletionFeatureEnabled, vendorId, token);
    yield put({
        type: constants.SAVE_IS_WORK_COMPLETION_FEATURE_ENABLED,
        isWorkCompletionFeatureEnabled: isWorkCompletionFeatureEnabled
    });

    // Step 2 - Get field validations 
    if (isWorkCompletionFeatureEnabled === true) {
        let fieldValidationsData = yield call(Api.fetchFieldValidations, vendorId, token);
        if (fieldValidationsData !== undefined) {
            yield put({
                type: constants.SAVE_FIELD_VALIDATIONS,
                fieldValidations: fieldValidationsData
            });
        }
    }

    /***Field validations*/
    


    const personaUserHasCourtesyDeliveryPermissions = isPersonaUser && ( jwtToken.vendor.permissions.CD ? ["VW","CL"].some(p=>jwtToken.vendor.permissions.CD.includes(p)) : false );
    if ( !isPersonaUser || personaUserHasCourtesyDeliveryPermissions )
    {
        yield put({
            type: "FETCH_IS_DEALERDRAFT",
            token
        });
    }

    // Log event of user signing in.
    yield call(Api.logUserSignedIn, vendorId);

    // HolmanURLCheck
    yield call(Api.checkHolmanURL,vendorId);    

    let targetUrl;
    const vendorFeatures = yield call(Api.fetchVendorFeatures,vendorId, token);

    //Master vendors , individual shop vendors and registered linked vendors
    if (vendorAccountType === "Master" || vendorAccountType === "Individual" || id !== null) {

         //Car Wash Vendor
        if (vendorDetails.serviceClassification === "CARWASH") {
            targetUrl = '/carWash';

        } else {

            if (vendorFeatures.includes('review_open_pos_upon_login')) {

               let agedPOCount = yield call(Api.fetchOpenOrdersOver30DayCount, token, vendorId);

                if (agedPOCount.statistics.ordersStatistics.openOrdersOver30DaysCount > 0) 

                    targetUrl = '/attentionNeeded?type=agedPOs';
                 else 
                    targetUrl = '/';    
            }else{
                targetUrl = '/'; 
            }
        }
     }
            //unregistered linked vendors
        else {

                targetUrl = '/linkedVendorAccount';
        }
        


    if (jwtToken.termsAccepted === false && id !== null) {
        //Fetching vendors terms and Conditions
        yield put({
            type: constants.FETCH_TERMS_STATUS,
            vendorId,
            token
        });

        yield call(context.history.push, `/vendorTermsAndConditions?returnUrl=${targetUrl}`);         
    }
    else {        
        yield call(context.history.push, targetUrl);
    }
    
    // Inform application that data request has started
    yield put({
        type: isARIUser ? constants.FETCH_SEARCH_VENDOR_RESOLVED : constants.FETCH_SIGN_IN_DATA_RESOLVED
    });    
}