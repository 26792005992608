import React from 'react';
import { defineMessages, FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl';
import { Map } from 'immutable';
import './RepairHistory.css';
import {Link} from 'react-router-dom';
import Submit from 'components/Submit';
import LinkToUrl from 'components/LinkToUrl';
import classnames from 'classnames';
import moment from 'moment';

class RepairHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            format: "MM/DD/YY",
        }
    }
    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }

    render() {

        const messages = defineMessages({
            line_item_view_date: {
                id: 'line_item_view_date',
                description: 'Date',
                defaultMessage: 'Date'
            },
            line_item_view_service: {
                id: 'line_item_view_service',
                description: 'Service',
                defaultMessage: 'Service'
            },
            line_item_view_odometer: {
                id: 'line_item_view_odometer',
                description: 'Odometer',
                defaultMessage: 'Odometer'
            },
            line_item_view_supplier: {
                id: 'line_item_view_supplier',
                description: 'Supplier',
                defaultMessage: 'Supplier'
            },
            line_view_link_caption: {
                id: 'line_view_link_caption',
                description: 'Text for PO View link',
                defaultMessage: 'VIEW'
            },
            no_records_message: {
                id: 'no_records_message',
                description: 'No recent services for this vehicle',
                defaultMessage: 'No recent services for this vehicle'
            },
            repair_history_of_vehicle: {
                id: 'repair_history_of_vehicle',
                description: 'Repair History of Vehicle',
                defaultMessage: 'Repair History of Vehicle'
            },
            show_more: {
                id: 'show_more',
                description: 'Show More',
                defaultMessage: 'Show More'
            },
            return: {
                id: 'return',
                description: 'Return',
                defaultMessage: 'Return'
            }
        });

        return <div>
            <table className='repair-history-list__table'>
                <thead>
                    <tr >
                        <th className="repair-history-list__header">
                            <FormattedMessage {...messages.line_item_view_date}/>
                        </th>
                        <th className="repair-history-list__header  repair-history-list__text">
                            <FormattedMessage {...messages.line_item_view_service}/>
                        </th>
                        <th className="repair-history-list__header repair-history-list__text">
                            <FormattedMessage {...messages.line_item_view_odometer}/>
                        </th>
                        <th className="repair-history-list__header repair-history-list__text">
                            <FormattedMessage {...messages.line_item_view_supplier}/>
                        </th>
                        <th className="repair-history-list__header">
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {
                        this.props.lineItems.size == 0 ?
                            <tr><td colSpan="5" className="col-sm-12 repair-history-list__item"><div className="line_item__item_align-center">
                                <FormattedMessage {...messages.no_records_message}/>
                            </div></td></tr>
                            :
                            this.props.lineItems.valueSeq().map((lineItem) =>
                                <tr key={ lineItem.get('id') }>
                                    <td className="repair-history-list__item repair-history-list__text-date repair-history-list__text-date">
                                        {
                                           moment(lineItem.get('createdDate')).format(this.state.format)

                                        }
                                    </td>
                                    <td className="repair-history-list__item  repair-history-list__text repair-history-list__text-description">
                                        {lineItem.get('complaintsDescription') }
                                    </td>
                                    <td className="repair-history-list__item  repair-history-list__text repair-history-list__text-odometer">
                                        {
                                            lineItem.get('vehicleOdometer')
                                        }
                                    </td>
                                    <td className="repair-history-list__item repair-history-list__text repair-history-list__text-vendor-name">
                                        {
                                            lineItem.get('vendorName')
                                        }
                                    </td>
                                    <td className="repair-history-list__item repair-history-list__text repair-history-list__text-view-link">
                                        {
                                            /* Show these button only for Create and Edit PO; hide it for View PO*/
                                            <Link to={`/PO/view/${lineItem.get('id')}`}>
                                                <FormattedMessage {...messages.line_view_link_caption}/>
                                            </Link>
                                        }
                                    </td>
                                </tr>

                            )
                    }
                </tbody>
            </table>
            <div className="row">
                    <div className="col-md-6 return-history__return"
                         role="button"
                         onClick={this.onReturnClickHandler.bind(this)}>
                        <img src="/assets/VMS_33button_back_blue.png"/>
                        {' '}
                        <FormattedMessage {...messages.return}/>
                    </div>
                <div className="col-md-6">
                    {
                        this.props.showMoreLink == undefined || !this.props.showMoreLink ?
                            false
                            :
                            <div className={classnames(this.props.nextPageExist == undefined || !this.props.nextPageExist ?
                                'repair-history__show-more_disabled'
                                : 'repair-history__show-more') }>
                                <Submit caption={<FormattedMessage {...messages.show_more}/>}
                                    imagePath="/assets/VMS_33button_go_bblue_down.png"
                                    onClickHandler={this.props.fetchRepairHistory}/>
                            </div>
                    }
                </div>
            </div>



        </div>;
    }
}


export default RepairHistory;