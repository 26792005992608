// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
// our stuff
import './BitInspectionResultConfirmation.css';
import BitResult from './BitResult';
import Spinner from 'components/Spinner';

class BitInspectionResultConfirmation extends React.Component {                    
    render() {
		const messages = defineMessages({
			bit_inspection__title: {
                id: 'bit_inspection__title',
                description: 'BIT inspection title',
                defaultMessage: 'BIT inspection'
			},
			bit_inspection__truck: {
                id: 'bit_inspection__truck',
                description: 'BIT inspection title truck',
                defaultMessage: 'truck'
			},
			bit_inspection__bus: {
                id: 'bit_inspection__bus',
                description: 'BIT inspection title bus',
                defaultMessage: 'bus'
			},
			bit_inspection__trailer: {
                id: 'bit_inspection__trailer',
                description: 'BIT inspection title trailer',
                defaultMessage: 'trailer'
			},
			bit_inspection__edit_form: {
                id: 'bit_inspection__edit_form',
                description: 'View/edit form button',
                defaultMessage: 'View/Edit BIT inspection form'
            },                
            bit_inspection__save_continue: {
                id: 'bit_inspection__save_continue',
                description: 'Save and continue button',
                defaultMessage: 'Save and continue'
            },             
		});

        return (
            <div>
                <div className='bit_inspection_result_confirmation__title'>                
					<FormattedMessage {...messages.bit_inspection__title} />
					{' - '}
					{ this.props.assetType === 'trailer' ?
						<FormattedMessage {...messages.bit_inspection__trailer} />
					: this.props.assetType === 'bus' ?
						<FormattedMessage {...messages.bit_inspection__bus} />
					: 
						<FormattedMessage {...messages.bit_inspection__truck} />
					}
				</div>

                <div className='bit_inspection_result_confirmation__outer_box'>

                    <BitResult 
                        formData={this.props.formData}
                        setFormData={this.props.setFormData}
                        formValidationMessages={this.props.formValidationMessages}
						userLocale={this.props.userLocale}
						poCreationDate={this.props.poCreationDate}
                        onInspectionFailClick={this.props.onConfirmationFailClick}
						onInspectionDateChangeHandler={this.props.onInspectionDateChangeHandler}
                    />

					<div className='row bit_inspection_result_confirmation__row'>

						<div className='col-xs-offset-6 col-xs-6 bit_inspection_result_confirmation__col'>

							<div 
								className='bit_inspection_result_confirmation__edit_form_button' 							
								onClick={this.props.onEditBitFormClick}
							>
								<FormattedMessage {...messages.bit_inspection__edit_form}/>                        
							</div>

						</div>

					</div>

                </div>          

                <div className='bit_inspection_result_confirmation__footer'>        

					<div className='row bit_inspection_result_confirmation__row'>

						<div className='col-xs-offset-6 col-xs-6 bit_inspection_result_confirmation__col'>
						
							{ this.props.formData.get('hasInspectionPassed') !== false && 
								<div className='bit_inspection_result_confirmation__save_button' 
									onClick={this.props.onSaveConfirmationClick} 
								>
									<FormattedMessage {...messages.bit_inspection__save_continue}/>{' '}
									<img src='/assets/VMS_33button_add_blue.png' />
									<div className='bit_inspection_result_confirmation__spinner'>
										{this.props.isSaving &&
											<Spinner spinnerType='blue' />										
										}
									</div>
								</div>                        
							}

						</div>

					</div>                					

				</div>
            </div>
        );
    }
}

export default BitInspectionResultConfirmation;