import { call, put, select, take, cancel, fork } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import configuration from 'configuration';
import * as constants from 'constants/App';
import { zeroIfUndefined, emptyMapIfUndefined,getSelectedLanguageCode } from 'utils/HelperFunctions';
import { Schema, arrayOf, normalize } from 'normalizr';
import * as helperFunctions from 'utils/HelperFunctions';
import { Map } from 'immutable';
import moment from 'moment';
import * as dotInspectionEnums from 'PO/ClosePO/screens/DotInspection/data/DotInspectionEnums';

export function* setDefaultValues(context, action) {
    try {
        const state = yield select();                
        
		const poNumber = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'id']);
		const vehicleDetails = state.appState.getIn(['serverData', 'close', 'vehicleDetails']);		
		const vendor = state.appState.getIn(['serverData', 'close', 'vendor']);
		const inspectorSignature = state.appState.getIn(['uiData', 'dotInspection', 'formData', 'inspectorSignature']);		
		
		yield put({type: constants.DOT_INSPECTION__SET_FORM_DATA, field: 'reportNumber', value: poNumber });
		yield put({type: constants.DOT_INSPECTION__SET_FORM_DATA, field: 'fleetUnitNumber', value: vehicleDetails.get('ariUnitNo') });
		yield put({type: constants.DOT_INSPECTION__SET_FORM_DATA, field: 'motorCarrierOperator', value: vehicleDetails.get('clientName') });
		yield put({type: constants.DOT_INSPECTION__SET_FORM_DATA, field: 'motorCarrierAddressCityStateZip', value: 
			[vehicleDetails.getIn(['clientBusinessAddress', 'address2']), ', ', 
			vehicleDetails.getIn(['clientBusinessAddress', 'address3']), ', ',
			vehicleDetails.getIn(['clientBusinessAddress', 'address4']), ', ',
			vehicleDetails.getIn(['clientBusinessAddress', 'city']), ', ',
			vehicleDetails.getIn(['clientBusinessAddress', 'state']), ', ',
			vehicleDetails.getIn(['clientBusinessAddress', 'zipCode'])].join('')
		});
		yield put({type: constants.DOT_INSPECTION__SET_FORM_DATA, field: 'vehicleIdentification', value: vehicleDetails.get('vin') });		
		yield put({type: constants.DOT_INSPECTION__SET_FORM_DATA, field: 'vehicleType', value: 
			(vehicleDetails.get('assetType') || '').toUpperCase() === 'TRAILER' ? 'TRAILER' : 'TRUCK' 
		});
		yield put({type: constants.DOT_INSPECTION__SET_FORM_DATA, field: 'repairingVendor', value: vendor.get('businessName') });		
		yield put({type: constants.DOT_INSPECTION__SET_FORM_DATA, field: 'vendorAddressCityStateZip', value: 
			[vendor.getIn(['businessAddress', 'address1']), ', ' ,			
			vendor.getIn(['businessAddress', 'city']), ', ',
			vendor.getIn(['businessAddress', 'state']), ', ',
			vendor.getIn(['businessAddress', 'zipCode'])].join('')
		});
		

    }
    catch (e) {
        yield put({
            type: constants.FETCH_CLOSE_PO_DATA_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.FETCH_CLOSE_PO_DATA_REQUESTED,
            errorObject: e
        });
    }

}

export function* saveConfirmation(context, action) {
    try {
		yield put({type: constants.DOT_INSPECTION__SAVE_CONFIRMATION_REQUESTED});
        const state = yield select();
        
        const token = helperFunctions.getToken(state);
        
        const poNumber = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'id']);

        const componentDetails = createPassedVehicleComponents();
        
        let dotInspectionData = state.appState.getIn(['uiData', 'dotInspection', 'formData']).toJSON();
        const vendorLanguage = helperFunctions.getSelectedLanguageCode(state);
        // Set defaults
        dotInspectionData['id'] = 0;
        dotInspectionData['inspectorName'] = dotInspectionData['inspectorSignature'];
        dotInspectionData['inspectorQualified'] = dotInspectionData['inspectorAttested'];
        dotInspectionData['componentDetails'] = componentDetails;

        yield call(Api.createDotInspection, poNumber, dotInspectionData, token,vendorLanguage);

		yield put({type: constants.DOT_INSPECTION__DISPLAY, show: false});

        // Continue where request payment left off.
		yield put({type: constants.CLOSE_PO_CLICKED, poNumber});
		
		yield put({type: constants.DOT_INSPECTION__SAVE_CONFIRMATION_RESOLVED});
    }
    catch (e) {
        yield put({
            type: constants.DOT_INSPECTION__SAVE_CONFIRMATION_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.DOT_INSPECTION__SAVE_CONFIRMATION_REQUESTED,
            errorObject: e
        });
    } 
}

// Create all 50 vehicle components with pass status;
function createPassedVehicleComponents() {
    let components = Array.apply(null, new Array(50));

    return components.map((comp, index)=> (
        {
            id: index+1,
            componentResultCode: dotInspectionEnums.componentResultCode.PASS,
            repairedDate: null,                       
        }
    ));    
}

export function* saveForm(context, action) {
    try {
		yield put({type: constants.DOT_INSPECTION__SAVE_FORM_REQUESTED});

        const state = yield select();        
        const token = helperFunctions.getToken(state);        
        const poNumber = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'id']);        
		let dotInspectionData = state.appState.getIn(['uiData', 'dotInspection', 'formData']).toJSON();		
		
		const componentDetails = state.appState.getIn(['uiData', 'dotInspection', 'componentDetails'])
			.map((componentDetail)=>(!!componentDetail.get('repairedDate')                               // if date entered
				? componentDetail.set('repairedDate', moment(componentDetail.get('repairedDate')).utc()) // convert date
				: componentDetail)                                                                       // else do nothing
			);
		const componentDetailsJSON = componentDetails.toList().toJSON();
        const vendorLanguage = helperFunctions.getSelectedLanguageCode(state);
       
        // Set defaults
        dotInspectionData['id'] = 0;        
        dotInspectionData['componentDetails'] = componentDetailsJSON;

        yield call(Api.createDotInspection, poNumber, dotInspectionData, token,vendorLanguage);

		yield put({type: constants.DOT_INSPECTION__DISPLAY, show: false});
		
        // Continue where request payment left off.
		yield put({type: constants.CLOSE_PO_CLICKED, poNumber});
		
		yield put({type: constants.DOT_INSPECTION__SAVE_FORM_RESOLVED});
    }
    catch (e) {
        window.scrollTo(0, 0);
        yield put({
            type: constants.DOT_INSPECTION__SAVE_FORM_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.FETCH_CLOSE_PO_DATA_REQUESTED,
            errorObject: e
        });
    }  
}
