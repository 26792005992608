import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import './VehicleSearchResult.css';
import Spinner from 'components/Spinner';
import {falseIfUndefined, emptyMapIfUndefined, emptyStringIfUndefined} from 'utils/HelperFunctions';
import classnames from "classnames";
import Submit from 'components/Submit';

class MultiSearchVehicleView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            vehicleSelected: '',
            vehicleId: '',
            ariUnitNo:'',
            clientId:'',
            showSpinner:false,
        };
    }


    onVehicleSelectedHandler(vehicleSelected, key,ariUnitNo,clientId, event) {
        event.preventDefault();
        this.setState({
            vehicleSelected: vehicleSelected,
            vehicleId: key,
            ariUnitNo: ariUnitNo,
            clientId:clientId
        });

    }

    onSubmit() {

        this.props.onNextStep(this.state.ariUnitNo,this.state.clientId,this.state.vehicleId,this);
    }

    render() {
        const messages = defineMessages({
            multi_client_name: {
                id: 'multi_client_name',
                description: 'multi_client_name',
                defaultMessage: 'Client Name'
            },
            multi_full_vin: {
                id: 'multi_full_vin',
                description: 'multi_full_vin',
                defineMessages: 'Full Vin. No',
            },
            multi_year: {
                id: 'multi_year',
                description: 'multi_year',
                defineMessages: 'Year',
            },
            multi_make: {
                id: 'multi_make',
                description: 'multi_make',
                defineMessages: 'Make',
            },
            multi_model: {
                id: 'multi_model',
                description: 'multi_model',
                defineMessages: 'Model',
            }, submit: {
                id: "submit",
                description: "Submit",
                defaultMessage: "Submit"
            },
        });


        return <div>
            <div className="submit_multi_vehicle__table_pad">
                <table className="submit_multi_vehicle__table">
                    <thead>
                    <tr>
                        <th className="submit_multi_vehicle__header submit_multi_vehicle__table_text2 col-sm-1"/>
                        <th className="submit_multi_vehicle__header submit_multi_vehicle__table_text">
                            <FormattedMessage {...messages.multi_client_name}/>
                        </th>
                        <th className="submit_multi_vehicle__header submit_multi_vehicle__table_text">
                            <FormattedMessage {...messages.multi_full_vin}/>
                        </th>
                        <th className="submit_multi_vehicle__header submit_multi_vehicle__table_text">
                            <FormattedMessage {...messages.multi_year}/>
                        </th>
                        <th className="submit_multi_vehicle__header submit_multi_vehicle__table_text3  col-sm-1">
                            <FormattedMessage {...messages.multi_make}/>
                        </th>
                        <th className="submit_multi_vehicle__header submit_multi_vehicle__table_text3  col-sm-1">
                            <FormattedMessage {...messages.multi_model}/>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {this.props.vehicleDetails.valueSeq().map((details) =>
                        <tr key={details.get('id')}>
                            <td className="my_units__item3">
                                <div className="submit_multi_vehicle__radio"
                                     onClick={this.onVehicleSelectedHandler.bind(this, !this.state.vehicleSelected, details.get('id'),details.get('ariUnitNo'),details.get('clientId'))}>
                                    <img key={details.get('id')}
                                         src={!this.state.vehicleSelected && this.state.vehicleId === "" && this.state.vehicleSelected === ""
                                             ? "/assets/VMS_unchecked.png"
                                        :this.state.vehicleId === details.get('id') ? "/assets/VMS_checked.png" : "/assets/VMS_unchecked.png"}
                                    />
                                </div>
                            </td>
                            <td className="submit_multi_vehicle__item">
                                {details.get('clientName')}
                            </td>
                            <td className="submit_multi_vehicle__item">
                                {details.get('vin')}
                            </td>
                            <td className="submit_multi_vehicle__item">
                                {details.get('year')}
                            </td>
                            <td className="submit_multi_vehicle__item2">
                                {details.get('make')}
                            </td>
                            <td className="submit_multi_vehicle__item2">
                                {details.get('model')}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>

            <div className={classnames('submit_multi_vehicle__submit',
                {'submit_multi_vehicle__disable': this.state.vehicleSelected === ""})}>
                <Submit caption={<FormattedMessage {...messages.submit}/>}
                        imagePath="/assets/VMS_33button_go_bblue.png"
                        onClickHandler={this.onSubmit.bind(this)}/>
            </div>


        </div>

    }
}

export default (MultiSearchVehicleView);