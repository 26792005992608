import {Map, fromJS} from 'immutable';
import * as constants from 'constants/App';
import * as sharedActions from 'actions/App';
import * as configuration from 'configuration';
import {normalize, Schema, arrayOf} from 'normalizr';
import moment from 'moment';


export function closeScreenPOLoaded(poNumber) {
    return {type: constants.CLOSE_PO_SCREEN_LOADED, poNumber}
}

export function closePOClicked(poNumber) {
    return {type: constants.CLOSE_PO_CLICKED, poNumber}
}

export function resetInvoiceDetails() {
    return {type: constants.RESET_INVOICE_DETAILS};
}

export function saveInvoiceDetails(poInvoiceDetails) {
    return {type: constants.SAVE_INVOICE_DETAILS, poInvoiceDetails};
}

export function showPopOverInvoiceNo(popOverDisplayShowInvoiceNo) {

    return {type: constants.POP_OVER_DISPLAY_SHOW_INVOICE, popOverDisplayShowInvoiceNo};
}


export function showPopOverInvoiceDate(popOverDisplayShowInvoiceDate) {

    return {type: constants.POP_OVER_DISPLAY_SHOW_INVOICE_DATE, popOverDisplayShowInvoiceDate};
}


export function showPopOverInvoiceName(popOverDisplayShowInvoiceName) {

    return {type: constants.POP_OVER_DISPLAY_SHOW_INVOICE_NAME, popOverDisplayShowInvoiceName};
}

export function showPopOverRepairCompleteDate(popOverDisplayShowRepairCompleteDate) {

    return {type: constants.POP_OVER_DISPLAY_SHOW_REPAIR_COMPLETE_DATE, popOverDisplayShowRepairCompleteDate};
}


export function showPopOverRepairStartDate(popOverDisplayShowRepairStartDate) {

    return {type: constants.POP_OVER_DISPLAY_SHOW_REPAIR_START_DATE, popOverDisplayShowRepairStartDate};
}

export function showPopOverOdometer(popOverDisplayShowOdometer) {
    return {type: constants.POP_OVER_DISPLAY_SHOW_ODOMETER, popOverDisplayShowOdometer};
}

export function showPopOverEngineHours(popOverDisplayShowEngineHours) {
    return {type: constants.POP_OVER_DISPLAY_SHOW_ENGINE_HOURS, popOverDisplayShowEngineHours};    
}

export function showPopOverPaymentCredit(popOverDisplayShowPaymentCredit) {

    return {type: constants.POP_OVER_DISPLAY_SHOW_PAYMENT_CREDIT, popOverDisplayShowPaymentCredit};
}


export function showPopOverWorkComplete(popOverDisplayShowWorkComplete) {

    return {type: constants.POP_OVER_DISPLAY_SHOW_WORK_COMPLETE, popOverDisplayShowWorkComplete};
}

export function showPopOverStandard(popOverShowStandard){
    
    return {type:constants.POP_OVER_DISPLAY_SHOW_STANDARD,popOverShowStandard}
}

export function showPopOverTax(popOverDisplayShowTax) {

    return {type: constants.POP_OVER_DISPLAY_SHOW_TAX, popOverDisplayShowTax};
}

export function showPopOverTaxExe(popOverDisplayShowTaxExe) {

    return {type: "POP_OVER_DISPLAY_SHOW_TAX_EXE", popOverDisplayShowTaxExe};
}


export function showPopOverTaxCanada(popOverDisplayShowTaxCanada) {

    return {type: constants.POP_OVER_DISPLAY_SHOW_TAX_CANADA, popOverDisplayShowTaxCanada};
}

export function setTaxErrorMessage(taxErrorMessage){

    return {type : constants.TAX_ERROR_MESSAGE,taxErrorMessage}
}

export function showOdometerWarnings(showWarning) {
    return {type: constants.SHOW_CLOSE_PO_ODOMETER_WARNING, showWarning};
}

export function showEngineHoursWarnings(showWarning) {
    return {type: constants.SHOW_CLOSE_PO_ENGINE_HOURS_WARNING, showWarning};
}

export function showPopOverRefNumber(popOverDisplayShowRefNumber) {

    return {type: "POP_OVER_DISPLAY_SHOW_REF_NUMBER", popOverDisplayShowRefNumber};
}