// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import * as i18n from '../../../config/i18n';
// Our stuff
import * as Components from '../components';
import { ThemeProvider } from "styled-components";
import { theme } from "DesignSpecs/data/theme";
import ErrorBoundry from '../components/ErrorBoundry';
import './App.css';
import * as sharedActions from '../shared/actions/App';
import * as CookieHelper from '../shared/utils/CookieHelper';
import queryString from 'query-string'
import jwtDecode from 'jwt-decode';
import TLSWarning from 'components/TLSWarning';
//import OnlineStatus from 'components/OnlineStatus';

import { AppStateProvider } from "../shared/AppState/app-state";
import appReducer, { initialState } from "../shared/AppState/appReducer";

class App extends React.Component {
    constructor(props) {
        super(props);

        let params = queryString.parse(this.props.location.search);
        let country = params != null && params.country != '' ? params.country : '';

        this.state = {
            country: country
        }
    }

    componentDidMount() {
        if (this.state.country !== '') {
            let languageLocale = '';

            if(this.state.country=='USA' || this.state.country=='CAN' || this.state.country=='MEX')
            this.props.dispatch(sharedActions.setSelectedCountry(this.state.country));

            if (this.state.country === 'MEX') {
                languageLocale = 'es-MX';
            }

            if (languageLocale) {
                this.props.dispatch(sharedActions.selectLocale(languageLocale));
                CookieHelper.createCookie('vms_language_preference', JSON.stringify({ languageLocaleCookie: languageLocale }));
            }
        }
    }

    render() {

        const selectedLocale = this.props.languageLocale;
        const isARIUser = this.props.token !== undefined && jwtDecode(this.props.token).sub === 'ARIUSER';

        const isValidPathToDisplayTLSWarning = window.location.href.toLowerCase().includes('/login') || window.location.href.toLowerCase().includes('/tlswarning/learnmore') ? false : true;
            
        return <IntlProvider key={selectedLocale} locale={selectedLocale} messages={i18n[selectedLocale.substring(0, 2)]}>
            <ThemeProvider theme={theme}>
            <ErrorBoundry>
            <AppStateProvider reducer={appReducer} initialState={{...initialState, personaToken: this.props.personaToken}}> 
            
                <div>
                    <Components.Error />
                    {
                       isValidPathToDisplayTLSWarning && <TLSWarning />
                    }
                    {
                        isARIUser && !window.location.href.includes('/ariuser') ? <Components.SearchVendor /> : false
                    }
                    {/*<OnlineStatus />*/}
                    {this.props.token !== undefined || this.props.personaToken !== undefined &&
                        <Components.SessionExpiration show={false} history={this.props.history} personaToken={this.props.personaToken} />
                    }
                    <Components.Header>
                        {this.props.header}
                    </Components.Header>

                    <Components.Content sideBar={this.props.sideBar} body={this.props.body} />

                    <Components.Footer selectedCountry={this.props.selectedCountry}>
                        {this.props.footer}
                    </Components.Footer>
                </div>                        
            </AppStateProvider>
            </ErrorBoundry>
            </ThemeProvider>
            </IntlProvider>;
    }
}

// Which props do we want to inject, given the global state?
function select(state) {
    return {
        errorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage']),
        token: state.appState.getIn(['serverData', 'shared', 'token']),
        personaToken: state.appState.getIn(['serverData', 'shared', 'personaToken']),
        languageLocale: state.appState.getIn(['uiData', 'shared', 'selectedLocale']) || "en-US",
        selectedCountry: state.appState.getIn(['uiData', 'shared', 'selectedCountry'])
    };
}

export default connect(select)(App);
