// #region Imports
import React, { useEffect, useState, useCallback } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import './TiresStepViewEditLine.css';
import { connect } from "react-redux";
import AddNewLineItemsSelector from '../selectors/AddNewLineItemsView';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import { Map } from 'immutable';
import Tires from '../components/Tires';
import * as purchaseOrderViewEvents from '../../../events/AddEditView';

// #endregion

// #region Styled Components
// #endregion

// #region Component
const TiresStepViewEditLine = (props) => {

    // #region State
    const [showTireValidationError, setShowTireValidationError] = useState(false);
    // #endregion

    // #region Ref
    // #endregion  

    // #region Callback
    // #endregion  

    // #region Event handlers
    const cleanUp = () => {
       
        //cancel update line item event if popup was opened as part of edit tire info
        const lineItem = props.poDetail;
        if (!!lineItem)
            purchaseOrderViewEvents.onCancelUpdateLineItemClickHandler(lineItem.get('lineItemKey'), props.dispatch);

        props.dispatch({ type: constants.CLEAR_LINE_ITEM_ID_FOR_TIRES });     
    }

    const onSaveTireChangesHandler = () => {

        if (!validateTireDetails()) {
            setShowTireValidationError(true);
            return false;
        }

        const lineItem = props.poDetail;
        /* No need to patch pricing details when editing tire measurements */
        //purchaseOrderViewEvents.onUpdateLineItemClickHandler(lineItem, props.dispatch);

            props.dispatch({ type: constants.SAVE_TIRE_DETAILS });
            onCloseWindowHandler();
            props.dispatch({ type: constants.FETCH_TIRE_SPECIFICATIONS, lineItemId: undefined, mode: 'edit' });
            setShowTireValidationError(false);

        cleanUp();
    }

    const validateTireDetails = () => {
        const errorMessages = defineMessages({
            tire_width_required: {
                id: 'tire_width_required',
                description: 'Please enter Tire width',
                defaultMessage: 'Please enter Tire width'
            },
            aspect_ratio_required: {
                id: 'aspect_ratio_required',
                description: 'Please enter Aspect ratio',
                defaultMessage: 'Please enter Aspect ratio'
            },
            rim_diameter_required: {
                id: 'rim_diameter_required',
                description: 'Please enter Rim diameter',
                defaultMessage: 'Please enter Rim diameter'
            },
            tire_manufacturer_required: {
                id: 'rim_diameter_required',
                description: 'Please select Tire manufacturer',
                defaultMessage: 'Please select Tire manufacturer'
            },
            tire_model_required: {
                id: 'tire_model_required',
                description: 'Please enter Tire model',
                defaultMessage: 'Please enter Tire model'
            },
            tire_reason_required: {
                id: 'tire_reason_required',
                description: 'Please select a reason for replacement',
                defaultMessage: 'Please select a reason for replacement'
            },
            old_tire_tread_depth_required: {
                id: 'old_tire_tread_depth_required',
                description: 'Please enter the old tire tread depth for the replaced tire.',
                defaultMessage: 'Please enter the old tire tread depth for the replaced tire.'

            },
            qty_must_match_with_tire_selection_counter: {
                id: 'qty_must_match_with_tire_selection_counter',
                description: 'Number of tires selected for replacement does not match the quantity defined in the line item. Please click return and correct the quantity.',
                defaultMessage: 'You must CLICK ON THE TIRE(s) in the diagram that corresponds to the position you are replacing. The tire will turn blue once you have clicked on it. Press RETURN to correct your entry.'

            },
            qty_must_match_with_tire_selection_counter_custom_message: {
                id: 'qty_must_match_with_tire_selection_counter',
                description: 'Number of tires selected for replacement does not match the quantity defined in the line item. Please select ',
                defaultMessage: 'Number of tires selected for replacement does not match the quantity defined in the line item. Please select '
            },
            qty_must_match_end_message: {
                id: 'qty_must_match_end_message',
                description: ' tire(s).',
                defaultMessage: ' tire(s).'
            }
        })

        let isFormValid = true;
        let validationErrs;
        const tireDetails = props.tireDetails;
        const fieldsToValidate = [
            { name: constants.TIRE_DETAILS.TIRE_WIDTH, propName: 'tireWidth', errMessage: errorMessages.tire_width_required },
            { name: constants.TIRE_DETAILS.ASPECT_RATIO, propName: 'tireAspectRatio', errMessage: errorMessages.aspect_ratio_required },
            { name: constants.TIRE_DETAILS.RIM_DIAMETER, propName: 'tireRimDiameter', errMessage: errorMessages.rim_diameter_required },
            { name: constants.TIRE_DETAILS.MANUFACTURER, propName: 'defaultManufacturer', errMessage: errorMessages.tire_manufacturer_required },
            { name: constants.TIRE_DETAILS.MODEL, propName: 'defaultModel', errMessage: errorMessages.tire_model_required },
            { name: constants.TIRE_DETAILS.REASON, propName: 'defaultReason', errMessage: errorMessages.tire_reason_required },
        ];


        if (props.anyTireSelected) {
            for (var index = 0; index < fieldsToValidate.length; index++) {
                var element = fieldsToValidate[index];
                if (!tireDetails[element.propName]) {
                    isFormValid = false;
                    validationErrs = {
                        name: element.name,
                        value: element.errMessage
                    }
                    props.dispatch({ type: constants.UPDATE_TIRE_DETAILS_VALIDATION_ERRS, validationErrs })
                }
            }

            for (var index = 0; index < props.selectedTires.length; index++) {
                var tire = props.selectedTires[index];
                if (!tire.treadDepth) {
                    isFormValid = false;
                    validationErrs = {
                        name: tire.tempId,
                        value: errorMessages.old_tire_tread_depth_required
                    }
                    props.dispatch({ type: constants.UPDATE_TIRE_DETAILS_VALIDATION_ERRS, validationErrs })
                }
            }
        }

        const isCorrectionTypeReplace = props.correctionType == constants.CORRECTION_TYPE.REPLACE;
        const isLineItemTypePart = !props.currentLineItemType ? true : props.currentLineItemType == constants.LINE_ITEM_TYPE.PART;
        if (!props.tiresSelectedMatchesWithQty && isCorrectionTypeReplace && isLineItemTypePart) {

            isFormValid = false;
            let errorMessage = [];
            if (props.showTiresInViewEdit)
                errorMessage = [{
                    message: errorMessages.qty_must_match_with_tire_selection_counter_custom_message.defaultMessage
                        + props.currentLineItemQty
                        + errorMessages.qty_must_match_end_message.defaultMessage
                }]
            else
                errorMessage = [{ message: errorMessages.qty_must_match_with_tire_selection_counter.defaultMessage }]

            props.dispatch({ type: constants.ADD_LINE_ITEM_QTY_MISMATCH_ERR, errorMessage })
        }


        return isFormValid;
    }

    /*Close window*/
    const onCloseWindowHandler = () => {
        cleanUp();
        props.onClose();
    }

    const onChangeTireDetailsHandler = (inputType, inputValue) => {

        const tireDetail = {
            type: inputType,
            inputValue: inputValue
        }
        props.dispatch({ type: constants.UPDATE_TIRE_DETAILS, tireDetail: tireDetail });
    }

    const onRemoveTireDetailsValidationErrorHandler = (fieldName) => {
        props.dispatch({ type: constants.REMOVE_TIRE_DETAILS_VALIDATION_ERROR, fieldName })
    }

    const onAddWheelHandler = (axlePosition) => {
        let axles = props.rearAxles;
        let axle = axles.find(x => x.axlePosition === axlePosition);
        const unit = props.defaultTreadDepthUnit;

        if (!axle)
            return;

        const dataForNewWheel = {
            unit: unit,
            axlePosition: axlePosition
        }

        props.dispatch({ type: constants.ADD_NEW_WHEEL, dataForNewWheel: dataForNewWheel })
    }

    const onRemoveAxleHandler = () => {
        let currentAxles = props.axles;
        const axleToRemove = props.axleCountForSelectedLineItem;

        if (axleToRemove > 1) {
            props.dispatch({ type: constants.REMOVE_AXLE, axleToRemove: axleToRemove })
        }
    }

    const onAddAxleHandler = () => {
        const currentNumberOfAxles = props.axleCountForSelectedLineItem + 1;
        let currentAxles = props.axles;
        const maxAxles = props.maxAxlesAllowed;
        const unit = props.defaultTreadDepthUnit;

        let newAxles = [];

        if (currentNumberOfAxles < maxAxles) {
            const dataForNewWheel = {
                unit: unit,
                axlePosition: currentNumberOfAxles
            }

            props.dispatch({ type: constants.ADD_NEW_AXLE, dataForNewWheel: dataForNewWheel })
        }
    }

    const onSelectTireHandler = (axlePosition, wheelPosition, id) => {
        props.dispatch({ type: constants.UPDATE_TIRE_SELECTION, tireIdToBeUpdated: id })
    }

    const onChangeTreadDepthHandler = (isTireNew, tireId, value) => {
        const treadDepthData = {
            tireId: tireId,
            isTireNew: isTireNew === constants.NEW_TREAD_DEPTH,
            treadDepthValue: value
        }

        props.dispatch({ type: constants.UPDATE_TREAD_DEPTH, treadDepthData: treadDepthData })
    }

    const onChangeMeasurementUnitHandler = (value) => {
        props.dispatch({ type: constants.UPDATE_TREAD_DEPTH_MEASUREMENT_UNIT, defaultTreadDepthUnit: value })
    }

    // #endregion  

    // #region Effects
    // #endregion

    // #region International messages  
    const messages = defineMessages({
        close_dialog: {
            id: 'close_dialog',
            description: '  Close',
            defaultMessage: 'Close'
        },
        add_line_item_to_po: {
            id: 'add_line_item_to_po',
            description: 'Add line item to PO ',
            defaultMessage: 'Add line item to PO '
        },
        parameters_header: {
            id: 'parameters_header',
            description: 'Header for ata parameters ',
            defaultMessage: 'Parameters '
        },
        empty_parameters: {
            id: 'empty_parameters',
            description: 'Description when parameters are empty',
            defaultMessage: 'None at this time'
        },
        add_new_line_item_caption: {
            id: 'add_new_line_item_caption',
            description: 'Add new line item caption',
            defaultMessage: 'Add {complaint_description} items'
        },
        continue_link: {
            id: 'continue_link',
            description: 'Continue',
            defaultMessage: 'Continue'
        },
        return: {
            id: 'return',
            description: 'Return',
            defaultMessage: 'Return'
        },
        items_added: {
            id: 'items_added',
            description: 'items_added',
            defaultMessage: 'Items added to PO:'
        },
        tires_replaced: {
            id: 'tires_replaced',
            description: 'tires_replaced',
            defaultMessage: 'Click on the Tires being replaced'
        },
        tires_tread: {
            id: 'tires_tread',
            description: 'tires_tread',
            defaultMessage: 'Tires tread depth'
        },
        new_tires: {
            id: 'new_tires',
            description: 'new_tires',
            defaultMessage: 'New Tire Details'
        },
    });

    const onRemoveWheelHandler = (axlePosition) => {
        let axles = props.rearAxles;
        let axle = axles.find(x => x.axlePosition === axlePosition);
        const unit = props.defaultTreadDepthUnit;

        if (!axle)
            return;

        props.dispatch({ type: constants.REMOVE_WHEEL, axlePosition: axlePosition })
    }
    // #endregion

    // #region variables
    const spareWheel = props.spareWheel;
    const frontAxle = props.frontAxle;
    const rearAxles = props.rearAxles;

    const canAddAxle = !rearAxles || rearAxles.length < 8;
    const canRemoveAxle = !!rearAxles && rearAxles.length > 1;
    const defaultTreadDepthUnit = props.defaultTreadDepthUnit;
    //#endregion    

    // #region Render
    return (
        <div>
            <FormWrapper key='addNewLineItemsErrorMessage'
                id="addNewLineItemsErrorMessage" {...props}
                formErrors={props.addNewLineItemsErrorMessage} />
            <FormWrapper key='lineItemQtyMismatchErrMessage'
                id="lineItemQtyMismatchErrMessage" {...props}
                formErrors={props.lineItemQtyMismatchErrMessage} />
            <Tires
                ataParametersSorted={props.ataParametersSorted}
                onSaveTireChanges={onSaveTireChangesHandler}
                onCloseWindow={onCloseWindowHandler}
                onReturnToPrevStep={() => { }}
                frontAxle={frontAxle}
                rearAxles={rearAxles}
                spareWheel={spareWheel}
                onAddWheel={onAddWheelHandler}
                onRemoveWheel={onRemoveWheelHandler}
                canAddAxle={canAddAxle}
                onAddAxle={onAddAxleHandler}
                canRemoveAxle={canRemoveAxle}
                onRemoveAxle={onRemoveAxleHandler}
                onChangeTreadDepth={onChangeTreadDepthHandler}
                onSelectTire={onSelectTireHandler}
                onChangeMeasurementUnit={onChangeMeasurementUnitHandler}
                defaultTreadDepthUnit={defaultTreadDepthUnit}
                tireManufacturers={props.tireDetails.allManufacturers}
                reasonsForReplacingTires={props.tireDetails.allReasons}
                defaultTireManufacturer={props.tireDetails.defaultManufacturer}
                defaultTireModel={props.tireDetails.defaultModel}
                defaultReasonForReplacingTire={props.tireDetails.defaultReason}
                defaultTireWidth={props.tireDetails.tireWidth}
                defaultTireAspectRatio={props.tireDetails.tireAspectRatio}
                defaultTireRimDiameter={props.tireDetails.tireRimDiameter}
                onChangeTireDetails={onChangeTireDetailsHandler}
                tireDetailsValidationErrs={props.tireDetailsValidationErrs}
                onRemoveTireDetailsValidationErr={onRemoveTireDetailsValidationErrorHandler}
                showTireManufacturingDetails={props.showTireManufacturingDetails}
                isSavingTireInfo={props.isSavingTireInfo}
                showTireValidationError={showTireValidationError}
                readOnly={props.readOnly || false}
                canShowUnitChangeConfirmation={props.tireSpecifications != undefined && props.tireSpecifications.size > 0}
            />
        </div>
    );
}
// #endregion

function mapToStateProps(state, props) {
    return {
        ...AddNewLineItemsSelector(state, props),
        addNewLineItemsErrorMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'errorMessage']),
        lineItemQtyMismatchErrMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'lineItemQtyMismatchErr']) || [],
        tireDetailsValidationErrs: state.appState.getIn(['uiData', 'addNewLineItemsView', 'tireDetails', 'validationErrs']) || Map(),
        isSavingTireInfo: state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'isSaving']) || false,
        tireErrorMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'errorMessage'])
    }
}
export default connect(mapToStateProps)(injectIntl(TiresStepViewEditLine));