import React from 'react';
import ReactDoM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage, RawIntlProvider, injectIntl } from 'react-intl';
import './CorrectionDetails.css';
import * as helperFunctions from 'utils/HelperFunctions';
import DynamicNumber from 'react-dynamic-number';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class CorrectionDetailsRepair extends React.Component {
    renderInputBoxWithNALabel() {
        const messages = defineMessages({
            contract_pricing_cannot_be_edited_displayed_message: {
                id: 'contract_pricing_cannot_be_edited_displayed_message',
                description: 'This product code has contract pricing. Price cannot be edited or displayed.',
                defaultMessage: 'This product code has contract pricing. Price cannot be edited or displayed.'
            },
        });

        return <OverlayTrigger placement='right'
            overlay={<Tooltip><RawIntlProvider value={this.props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_displayed_message} /></RawIntlProvider></Tooltip>}
        >
            <input
                disabled={true}
                className="correction-type__input-readonly"
                value="N/A"
            />
        </OverlayTrigger>
    }

    renderLaborRateInputBoxWithConditionalOverlay(laborRate, showOverlay) {
        const messages = defineMessages({
            contract_pricing_cannot_be_edited_message: {
                id: 'contract_pricing_cannot_be_edited_message',
                description: 'This product code has contract pricing. Price cannot be edited.',
                defaultMessage: 'This product code has contract pricing. Price cannot be edited.'
            },
        });

        const laborRateInputBox = <DynamicNumber
            disabled={!this.props.laborCostEditable}
            className={this.props.laborCostEditable ? "correction-type__input-medium" : "correction-type__input-readonly"}
            onChange={this.props.handleChange.bind(this, "RATE")}
            separator={'.'}
            integer={6}
            fraction={2}
            positive={true}
            negative={false}
            thousand={false}
            value={laborRate}
        />;

        if (showOverlay)
            return <OverlayTrigger placement='right'
                overlay={<Tooltip><RawIntlProvider value={this.props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_message} /></RawIntlProvider></Tooltip>}
            >
                {laborRateInputBox}
            </OverlayTrigger>;
        else
            return laborRateInputBox
    }

    render() {

        let laborRate = this.props.currentLaborRate === 0 ? "" : this.props.currentLaborRate.toString().match(/^-?\d+(?:\.\d{0,2})?/);
        let laborHours = this.props.currentLaborHours === 0 ? "" : this.props.currentLaborHours.toString().match(/^-?\d+(?:\.\d{0,2})?/);
        let laborTotal = this.props.correctionDetailsTotal.get('laborTotal').toFixed(2);


        const messages = defineMessages({
            repair_hours: {
                id: 'repair_hours',
                description: 'Hours',
                defaultMessage: 'Hours'
            },
            repair_rate: {
                id: 'repair_rate',
                description: 'Rate',
                defaultMessage: 'Rate'
            },
            repair_amount: {
                id: 'repair_amount',
                description: 'Amount',
                defaultMessage: 'Amount'
            },
            repair_hide_labor: {
                id: 'repair_hide_labor',
                description: 'Labor for {newProductDescription} should be included in {existingProductDescription}',
                defaultMessage: 'Labor for {newProductDescription} should be included in {existingProductDescription}'
            },
            repair_labor_cost: {
                id: 'repair_labor_cost',
                description: 'Lab Cost',
                defaultMessage: 'Lab Cost'
            },
            repair_labor_qty: {
                id: 'repair_labor_qty',
                description: 'Qty',
                defaultMessage: 'Qty'
            },
        });
        return (
            <div className="correction-type__repair">
                {
                    !this.props.hideLaborInputFields.size > 0 ?
                        <div className="col-lg-13">
                            <div className="row">
                                <div className="form-group_small col-xs-3 ">
                                {this.props.isFlatRateFeatureEnabled ? <FormattedMessage {...messages.repair_labor_qty} /> : <FormattedMessage {...messages.repair_hours} />}
                                    <DynamicNumber
                                        className="correction-type__input-small"
                                        onChange={this.props.handleChange.bind(this, "HOURS")}
                                        separator={'.'}
                                        integer={3}
                                        fraction={this.props.QtyShouldBeWholeNumber ? 0 : 2}
                                        positive={true}
                                        negative={false}
                                        thousand={false}
                                        value={laborHours}
                                    />
                                </div>
                                <div className="form-group col-xs-4 ">
                                {this.props.isFlatRateFeatureEnabled ? <FormattedMessage {...messages.repair_labor_cost} /> : <FormattedMessage {...messages.repair_rate} />}
                                    {this.props.laborCostHidden ?
                                        this.renderInputBoxWithNALabel()
                                        :
                                        this.renderLaborRateInputBoxWithConditionalOverlay(laborRate, !this.props.laborCostEditable)
                                    }
                                </div>
                                <div className="form-group col-xs-4 ">
                                    <FormattedMessage {...messages.repair_amount} />
                                    {this.props.laborCostHidden ?
                                        this.renderInputBoxWithNALabel()
                                        :
                                        <DynamicNumber
                                            disabled
                                            className="correction-type__input-readonly"
                                            separator={'.'}
                                            integer={6}
                                            fraction={2}
                                            positive={true}
                                            negative={false}
                                            thousand={false}
                                            value={laborTotal}

                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div>
                                <FormattedMessage values={{
                                    newProductDescription: this.props.hideLaborInputFields.get('newProductDescription'),
                                    existingProductDescription: this.props.hideLaborInputFields.get('existingProductDescription')
                                }} {...messages.repair_hide_labor} />
                            </div>
                        </div>
                }
            </div>
        );
    }
}
;

export default injectIntl(CorrectionDetailsRepair);