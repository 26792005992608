import React from 'react';
import {Map} from 'immutable';
import {defineMessages, FormattedMessage, FormattedNumber,RawIntlProvider,injectIntl} from 'react-intl';
import classNames from 'classnames';
import {Overlay, Popover} from 'react-bootstrap';
import './BankingInformationClosedPO.css';
import {INVOICE_FIELD_TYPE, LINE_ITEM_TYPE} from 'constants/App';
import moment from 'moment';
import ReactDOM from 'react-dom';
import * as helperFunctions from 'utils/HelperFunctions';
import { IntlProvider } from 'react-intl';

class BankingInformationClosedPO extends React.Component {

    constructor(props) {
        super(props);        
        moment.locale(this.props.userLocale);
        this.state = {
            showError: false
        }

    }

    render() {
        const messages = defineMessages({
            payment_confirmation_text: {
                id: 'payment_confirmation_text',
                description: 'Confirm the payment will be credited to account ending in {bankAccountNo}',
                defaultMessage: 'Confirm the payment will be credited to account ending in {bankAccountNo}'
            },
            reproduce_invoice_text:{
                id: 'reproduce_invoice_text',
                description: 'Confirm that Holman may reproduce an invoice evidencing this transaction',
                defaultMessage: 'Confirm that Holman may reproduce an invoice evidencing this transaction'
            },
            work_details_text: {
                id: 'work_details_text',
                description: 'Confirm that work detailed in the purchase order is fully completed',
                defaultMessage: 'Confirm that work detailed in the purchase order is fully completed'
            },
            error_payment_confirmation: {
                id: 'error_payment_confirmation',
                description: 'Please confirm the account number.',
                defaultMessage: 'Please confirm the account number.'
            },
            error_work_details: {
                id: 'error_work_details',
                description: 'Please confirm the work is complete.',
                defaultMessage: 'Please confirm the work is complete.'
            },
            error_reproduce_invoice: {
                id: 'error_reproduce_invoice',
                description: 'Please confirm that Holman may reproduce an invoice.',
                defaultMessage: 'Please confirm that Holman may reproduce an invoice.'
            },
            subtotal: {
                id: 'subtotal',
                description: 'Subtotal',
                defaultMessage: 'Subtotal'
            },
            total: {
                id: 'total',
                description: 'Total',
                defaultMessage: 'Total'
            },
            tax: {
                id: 'tax',
                description: 'tax',
                defaultMessage: 'Tax'
            }
        });

        const closePoTotal = helperFunctions.addFloats(this.props.subTotal, this.props.taxTotal);

        return <div className="row">
            <div className="col-sm-11 close-po__field-header">
                <div className="row close-po__subtotal">
                    <div className="col-sm-6 close-po__field-header">
                        <FormattedMessage {...messages.subtotal} />
                    </div>
                    <div className="col-sm-6 close-po__field-value">
                        <IntlProvider locale="en">
                            <FormattedNumber value={Number(this.props.subTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0])}
                                         style="currency"
                                         currency={this.props.selectedCurrency}/>
                        </IntlProvider>
                    </div>
                </div>
                <div className="close-po-banking__hr-control">
                    <hr className="close-po-banking__hr-style"/>
                </div>
                <div className="row close-po__subtotal">
                    <div className="col-sm-6 close-po__field-header">
                        <FormattedMessage {...messages.tax} />
                    </div>
                    <div className="col-sm-6 close-po__field-value">
                        <IntlProvider locale="en">
                            <FormattedNumber
                            value={Number(this.props.taxTotal.toString())}
                            style="currency"
                            currency={this.props.selectedCurrency}/>
                        </IntlProvider>

                    </div>
                </div>
                <div className="close-po-banking__hr-control">
                    <hr className="close-po-banking__hr-style"/>
                </div>
                <div className="row close-po__total">
                    <div className="col-sm-6 close-po__field-header close-po__header-total">
                        {<FormattedMessage {...messages.total} />}
                    </div>
                    <div className="col-sm-6 close-po__field-value">
                        <IntlProvider locale="en">
                            <FormattedNumber value={closePoTotal}
                                         style="currency"
                                         currency={this.props.selectedCurrency}/>
                        </IntlProvider>
                    </div>
                </div>

                { this.props.showBankAccountRadioButton? <div>
                    <div className="close-po-banking__hr-control">
                        <hr className="close-po-banking__hr-style"/>
                    </div>
                    <div className="close-po__radio confirm-control">
                        <Overlay
                            show={this.props.popOverDisplayShowPaymentCredit}
                            onHide={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.IS_CREDIT_PAYMENT, !this.props.invoiceDetails.get('isPaymentCreditToAccount'))}
                            placement="left"
                            target={props=>ReactDOM.findDOMNode(this.refs.btnCredit)}

                        >


                            <Popover  id="error_popover_payment_confirm" className="close-po__error_message">
                                <RawIntlProvider value={this.props.intl}>
                                <FormattedMessage {...messages.error_payment_confirmation} />
                                </RawIntlProvider>
                            </Popover>

                        </Overlay>
                        <div tabIndex="14" ref="btnCredit"
                             className={classNames('close-po__radio__banking-control', {'has-error': this.state.showError && !this.props.validationDetails.get('isPaymentCreditToAccount')})}
                             onClick={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.IS_CREDIT_PAYMENT, !this.props.invoiceDetails.get('isPaymentCreditToAccount'))}
                        >
                            <img src={this.props.invoiceDetails.get('isPaymentCreditToAccount') == true
                                ? "/assets/VMS_checked.png"
                                : "/assets/VMS_unchecked.png"}
                            />
                            {' '} <FormattedMessage
                            values={{bankAccountNo: this.props.vendor.get('bankAccountNumber')}}
                            {...messages.payment_confirmation_text}/>
                        </div>

                    </div>
                </div>
                    : false
                }
                
                <div className="close-po-banking__hr-control">
                    <hr className="close-po-banking__hr-style"/>
                </div>
                
                <div className="close-po__radio confirm-control">
                    <Overlay
                        show={this.props.popOverDisplayShowWorkComplete}
                        onHide={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.IS_WORK_COMPLETED, !this.props.invoiceDetails.get('isWorkCompleted'))}
                        placement="left"
                        target={props=>ReactDOM.findDOMNode(this.refs.btnComplete)}

                    >
                        <Popover  id="error_popover_work_details" className="close-po__error_message">
                            <RawIntlProvider value={this.props.intl}>
                            <FormattedMessage {...messages.error_work_details} />
                            </RawIntlProvider>
                        </Popover>

                    </Overlay>
                    <div tabIndex="15" ref="btnComplete"
                         className={classNames('close-po__radio__banking-control  ', {'has-error': this.state.showError && !this.props.validationDetails.get('isWorkCompleted')})}
                         onClick={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.IS_WORK_COMPLETED, !this.props.invoiceDetails.get('isWorkCompleted'))}
                    >
                        <img src={this.props.invoiceDetails.get('isWorkCompleted') == true
                            ? "/assets/VMS_checked.png"
                            : "/assets/VMS_unchecked.png"}
                        />
                        {' '} <FormattedMessage {...messages.work_details_text}/>

                    </div>

                </div>
                
                
                <div className="close-po-banking__hr-control">
                    <hr className="close-po-banking__hr-style"/>
                </div>
                
                {this.props.showInvoiceRadioButton?
                <div className="close-po__radio confirm-control">
                    <Overlay
                        show={this.props.popOverDisplayShowStandard}
                        onHide={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.IS_INVOICE_REPRODUCIBLE, !this.props.invoiceDetails.get('isInvoiceReproducible'))}
                        placement="left"
                        target={props=>ReactDOM.findDOMNode(this.refs.btnStandard)}

                    >
                        <Popover  id="error_popover_reproduce_invoice" className="close-po__error_message">
                             <RawIntlProvider value={this.props.intl}>
                            <FormattedMessage {...messages.error_reproduce_invoice} />
                            </RawIntlProvider>
                        </Popover>

                    </Overlay>
                    <div tabIndex="15" ref="btnStandard"
                         className={classNames('close-po__radio__banking-control  ', {'has-error': this.state.showError && !this.props.validationDetails.get('isInvoiceReproducible')})}
                         onClick={this.props.onInputChangeHandler.bind(this, INVOICE_FIELD_TYPE.IS_INVOICE_REPRODUCIBLE, !this.props.invoiceDetails.get('isInvoiceReproducible'))}
                    >
                        <img src={this.props.invoiceDetails.get('isInvoiceReproducible') == true
                            ? "/assets/VMS_checked.png"
                            : "/assets/VMS_unchecked.png"}
                        />
                        {' '} <FormattedMessage {...messages.reproduce_invoice_text}/>

                    </div>

                </div>
                :''}
                {this.props.showInvoiceRadioButton?
                <div className="close-po-banking__hr-control">
                    <hr className="close-po-banking__hr-style"/>
                </div>
                :''}
                            </div>
        </div>;


    }


}



export default injectIntl(BankingInformationClosedPO);