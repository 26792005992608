import { call, put, select, take, cancel, fork,delay } from 'redux-saga/effects'
import { Map, fromJS } from 'immutable';
import * as Api from 'utils/Api';
import configuration from 'configuration';
import * as constants from 'constants/App';
import { diagnosisDetailsSelector, complaintsSelector } from 'selectors/ComplaintView';
import { emptyMapIfUndefined, emptyArrayIfUndefined } from 'utils/HelperFunctions';
import { Schema, arrayOf, normalize } from 'normalizr';
import moment from 'moment';
import * as helperFunctions from 'utils/HelperFunctions';

const emptyMap = Map();

export function* fetchData() {
    try {
        const state = yield select();
        const token = helperFunctions.getToken(state);
        const vehicleId = state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']);
        const complaintTypes = state.appState.getIn(['serverData', 'shared', 'complaintTypes']);
        const vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

        // Inform application that data is being fetched.
        yield put({ type: constants.FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_REQUESTED });
        const availableVehicleComplaintsJSON = yield call(Api.fetchAvailableVehicleComplaints, vehicleId, vendorId, token);

        // Update description to APC friendly names.
        let availableVehicleComplaints = fromJS(availableVehicleComplaintsJSON);
        availableVehicleComplaints = availableVehicleComplaints.map(comp => {
            let matchingComplaintType = complaintTypes.filter(complaintType => (complaintType.get('code') === comp.get('complaintCode')));
            if (matchingComplaintType.count() === 0)
                return comp;
            else
                return comp.set('description', matchingComplaintType.first().get('description'));
        });

        // Prepare data.
        let data = {
            availableVehicleComplaints
        };

        yield put({ type: constants.FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_RESOLVED, ...data });
    }
    catch (e) {
        yield put({ type: constants.FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    }
}

export function* goToNextStep(context, action) {
    try {
        yield put({ type: constants.DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_REQUESTED });

        const state = yield select();
        const token = helperFunctions.getToken(state);
        const vehicleId = state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']);

        const selectedAvailabeComplaints =
            (state.appState.getIn(['serverData', 'driverValidationComplaints', 'availableVehicleComplaints']) || emptyMap)
                .filter(comp => !!comp.get('isSelected'));


        if (selectedAvailabeComplaints.count() > 0) {
            let ids = selectedAvailabeComplaints.map(comp => comp.get('vehicleComplaintId')).toArray();
            for (let id of ids) {
                yield call(addComplaintToPo, id);
                yield call(delay, 100);
            }
        }

        yield call(context.history.push,`/po/complaint/${vehicleId}`);

        yield put({ type: constants.DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_RESOLVED });
    }
    catch (e) {
        yield put({ type: constants.DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    }
}

function* addComplaintToPo(vehicleComplaintId) {
    const state = yield select();
    const complaintCode = state.appState.getIn(['serverData', 'driverValidationComplaints', 'availableVehicleComplaints', vehicleComplaintId.toString(), 'complaintCode']);
    const description = state.appState.getIn(['serverData', 'driverValidationComplaints', 'availableVehicleComplaints', vehicleComplaintId.toString(), 'description']);

    // Create complaint in store
    let nextComplaintId = emptyMapIfUndefined(state.appState.getIn(["uiData", "addEdit", "complaints"])).count() + 1;
    let newComplaintObj = {};
    let complaintKey = 'complaint' + nextComplaintId;
    newComplaintObj[complaintKey] = Map({
        index: nextComplaintId,
        title: "Complaint " + nextComplaintId,
        code: complaintCode,
        description,
        isSelectMode: true, // will be toggled to false later
        wasComplaintSkipped: false,
        isComplaintOpen: false
    });
    let newComplaint = new Map(newComplaintObj);
    yield put({ type: constants.ADD_EDIT_VIEW_ADD_COMPLAINT, newComplaint });

    // Create complaint in DB
    yield put({
        type: constants.CREATE_OR_EDIT_COMPLAINT_CLICKED,
        complaintKey,
        code: complaintCode,
        description
    });
}