import React from 'react';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import { Popover } from 'react-bootstrap';
import { SplitButton, MenuItem } from 'react-bootstrap';

import './CraneManufactureInformation.css';
import * as craneInspectionEnums from 'PO/ClosePO/screens/CraneInspection/data/CraneInspectionEnums';
import CraneInspectionHoistSystemBrakesType from './CraneInspectionHoistSystemBrakesType';
import CraneInspectionBoomJibBrakesType from './CraneInspectionBoomJibBrakesType';

class CraneManufactureInformation extends React.Component {

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.formValidationMessages.count() === 0 && this.props.formValidationMessages.count() > 0) {

			if (!!this.props.formValidationMessages.get('craneManufacturer')) {
				this.craneManufacturerRef.scrollIntoView(false);

			} else if (!!this.props.formValidationMessages.get('craneCapacity')) {
				this.craneCapacityRef.scrollIntoView(false);
			} else if (!!this.props.formValidationMessages.get('craneSerialNumber')) {
				this.craneSerialNumberRef.scrollIntoView(false);
			} else if (!!this.props.formValidationMessages.get('craneModelNumber')) {
				this.craneModelNumberRef.scrollIntoView(false);
			} else if (!!this.props.formValidationMessages.get('interval')) {
				this.intervalRef.scrollIntoView(false);
			} else if (!!this.props.formValidationMessages.get('intervalOther')) {
				this.intervalOtherRef.scrollIntoView(false);
			} else if (!!this.props.formValidationMessages.get('hoistSystemBrakesType')) {
				this.hoistSystemBrakesTypeRef.scrollIntoView(false);
			} else if (!!this.props.formValidationMessages.get('boomJibBrakesType')) {
				this.boomJibBrakesTypeRef.scrollIntoView(false);
			}
		}
	}

	handleChange = (event) => {
		this.props.setFormData(event.currentTarget.id, event.currentTarget.value);
	};

	handleIntervalClick = (eventKey, event) => {
		this.props.setFormData('interval', eventKey);
	};

	getIntervalTitle() {
		const messages = defineMessages({
			crane_inspection__interval_annual: {
				id: 'crane_inspection__interval_annual',
				description: 'Annual',
				defaultMessage: 'Annual'
			},
			crane_inspection__interval_quadrennial: {
				id: 'crane_inspection__interval_quadrennial',
				description: 'Quadrennial',
				defaultMessage: 'Quadrennial'
			},
			crane_inspection__interval_other: {
				id: 'crane_inspection__interval_other',
				description: 'Other',
				defaultMessage: 'Other'
			},
			crane_inspection__interval_default: {
				id: 'crane_inspection__interval_default',
				description: 'Default',
				defaultMessage: '&nbsp;'
			}
		});

		var selectedInterval = this.props.formData.get('interval') != null ? this.props.formData.get('interval') : 'DEFAULT';

		switch (selectedInterval) {
			case craneInspectionEnums.interval.annual:
				return <FormattedMessage {...messages.crane_inspection__interval_annual} />;
			case craneInspectionEnums.interval.quadrennial:
				return <FormattedMessage {...messages.crane_inspection__interval_quadrennial} />;
			case craneInspectionEnums.interval.other:
				return <FormattedMessage {...messages.crane_inspection__interval_other} />;
			default:
				return '--';
		}

	};

	render() {
		const messages = defineMessages({
			crane_inspection__manufacturer: {
				id: 'crane_inspection__manufacturer',
				description: 'Manufacturer',
				defaultMessage: 'Manufacturer'
			},
			crane_inspection__manufacturer_required: {
				id: 'crane_inspection__manufacturer_required',
				description: 'Required',
				defaultMessage: 'Provide manufacturer'
			},
			crane_inspection__capacity: {
				id: 'crane_inspection__capacity',
				description: 'Capacity',
				defaultMessage: 'Capacity'
			},
			crane_inspection__capacity_required: {
				id: 'crane_inspection__capacity_required',
				description: 'Capacity Required',
				defaultMessage: 'Provide capacity'
			},
			crane_inspection__serial_number: {
				id: 'crane_inspection__serial_number',
				description: 'Serial number',
				defaultMessage: 'Serial number'
			},
			crane_inspection__serial_number_required: {
				id: 'crane_inspection__serial_number_required',
				description: 'Serial Number Required',
				defaultMessage: 'Provide serial number'
			},
			crane_inspection__model_number: {
				id: 'crane_inspection__model_number',
				description: 'Model number',
				defaultMessage: 'Model number'
			},
			crane_inspection__model_number_required: {
				id: 'crane_inspection__model_number_required',
				description: 'Model Number Required',
				defaultMessage: 'Provide model number'
			},
			crane_inspection__interval: {
				id: 'crane_inspection__interval',
				description: 'Interval',
				defaultMessage: 'Interval'
			},
			crane_inspection__interval_required: {
				id: 'crane_inspection__interval_required',
				description: 'Interval Required',
				defaultMessage: 'Provide interval'
			},
			crane_inspection__interval_other_required: {
				id: 'crane_inspection__interval_other_required',
				description: 'Interval other required',
				defaultMessage: 'Provide interval other'
			},
			crane_inspection__hoistSystemBrakesType: {
				id: 'crane_inspection__hoistSystemBrakesType',
				description: 'Hoist system brakes',
				defaultMessage: 'Hoist system brakes'
			},
			crane_inspection__hoistSystemBrakesType_required: {
				id: 'crane_inspection__hoistSystemBrakesType_required',
				description: 'Hoist system brakes Required',
				defaultMessage: 'Provide hoist system brakes'
			},
			crane_inspection__boomJibBrakesType: {
				id: 'crane_inspection__boomJibBrakesType',
				description: 'Boom/jib brakes',
				defaultMessage: 'Boom/jib brakes'
			},
			crane_inspection__boomJibBrakesType_required: {
				id: 'crane_inspection__boomJibBrakesType_required',
				description: 'Boom/jib brakes Required',
				defaultMessage: 'Provide boom/jib brakes'
			},
			crane_inspection__interval_annual: {
				id: 'crane_inspection__interval_annual',
				description: 'Annual',
				defaultMessage: 'Annual'
			},
			crane_inspection__interval_quadrennial: {
				id: 'crane_inspection__interval_quadrennial',
				description: 'Quadrennial',
				defaultMessage: 'Quadrennial'
			},
			crane_inspection__interval_other: {
				id: 'crane_inspection__interval_other',
				description: 'Other',
				defaultMessage: 'Other'
			}
		});

		return (
			<div className='crane_manufacture_information'>
				<div className='row crane_manufacture_information_row'>

					<div className='col-sm-4 crane_manufacture_information_col'>
						<label><FormattedMessage {...messages.crane_inspection__manufacturer} /></label>
						<div>
							<input
								type='text'
								className='crane_manufacture_information_input'
								id='craneManufacturer'
								maxLength='30'
								value={this.props.formData.get('craneManufacturer')}
								onChange={this.handleChange}
								ref={(r) => { this.craneManufacturerRef = r }}
							/>
							{!!this.props.formValidationMessages.get('craneManufacturer') &&
								<Popover
									id='craneManufacturerOverlay'
									className='crane_manufacture_information__error_message'
									placement='top'
									positionTop={-25}
									positionLeft={100}
								>
									<RawIntlProvider value={this.props.intl}>
									<FormattedMessage {...(messages[this.props.formValidationMessages.get('craneManufacturer')])} />
									</RawIntlProvider>
								</Popover>
							}
						</div>
					</div>

					<div className='col-sm-4 crane_manufacture_information_col'>
						<label><FormattedMessage {...messages.crane_inspection__capacity} /></label>
						<div>
							<input
								type='text'
								className='crane_manufacture_information_input'
								id='craneCapacity'
								maxLength='30'
								value={this.props.formData.get('craneCapacity')}
								onChange={this.handleChange}
								ref={(r) => { this.craneCapacityRef = r }}
							/>
							{!!this.props.formValidationMessages.get('craneCapacity') &&
								<Popover
									id='craneCapacityOverlay'
									className='crane_manufacture_information__error_message'
									placement='top'
									positionTop={-25}
									positionLeft={130}
									container='body'
								>
									<RawIntlProvider value={this.props.intl}>
									<FormattedMessage {...(messages[this.props.formValidationMessages.get('craneCapacity')])} />
									</RawIntlProvider>
								</Popover>
							}
						</div>
					</div>

					<div className='col-sm-4 crane_manufacture_information_col'>
						<label><FormattedMessage {...messages.crane_inspection__serial_number} /></label>
						<div>
							<input
								type='text'
								className='crane_manufacture_information_input'
								id='craneSerialNumber'
								maxLength='30'
								value={this.props.formData.get('craneSerialNumber')}
								onChange={this.handleChange}
								ref={(r) => { this.craneSerialNumberRef = r }}
							/>
						</div>
						{!!this.props.formValidationMessages.get('craneSerialNumber') &&
							<Popover
								id='craneSerialNumberOverlay'
								className='crane_manufacture_information__error_message'
								placement='top'
								positionTop={-25}
								positionLeft={100}
							>
								<RawIntlProvider value={this.props.intl}>
								<FormattedMessage {...(messages[this.props.formValidationMessages.get('craneSerialNumber')])} />
								</RawIntlProvider>
							</Popover>
						}
					</div>

				</div>

				<div className='row crane_manufacture_information_row'>
					<div className='col-sm-4 crane_manufacture_information_col'>
						<label><FormattedMessage {...messages.crane_inspection__model_number} /></label>
						<div>
							<input
								type='text'
								className='crane_manufacture_information_input'
								id='craneModelNumber'
								maxLength='30'
								value={this.props.formData.get('craneModelNumber')}
								onChange={this.handleChange}
								ref={(r) => { this.craneModelNumberRef = r }}
							/>
							{!!this.props.formValidationMessages.get('craneModelNumber') &&
								<Popover
									id='craneModelNumberOverlay'
									className='crane_manufacture_information__error_message'
									placement='top'
									positionTop={-10}
									positionLeft={50}
								>
									<RawIntlProvider value={this.props.intl}>
									<FormattedMessage {...(messages[this.props.formValidationMessages.get('craneModelNumber')])} />
									</RawIntlProvider>
								</Popover>
							}
						</div>
					</div>

					<div className='col-sm-4 crane_manufacture_information_col'>
						<label><FormattedMessage {...messages.crane_inspection__hoistSystemBrakesType} /></label>
						<div ref={(r) => { this.hoistSystemBrakesTypeRef = r }}>
							<CraneInspectionHoistSystemBrakesType
								formData={this.props.formData}
								formValidationMessages={this.props.formValidationMessages}
								setFormData={this.props.setFormData}
							/>
							{!!this.props.formValidationMessages.get('hoistSystemBrakesType') &&
								<Popover
									id='hoistSystemBrakesTypeOverlay'
									className='crane_manufacture_information__error_message'
									placement='bottom'
									positionTop={50}
									positionLeft={75}
								>
									<RawIntlProvider value={this.props.intl}>
									<FormattedMessage {...(messages[this.props.formValidationMessages.get('hoistSystemBrakesType')])} />
									</RawIntlProvider>
								</Popover>
							}
						</div>
					</div>

					<div className='col-sm-4 crane_manufacture_information_col'>
						<label><FormattedMessage {...messages.crane_inspection__boomJibBrakesType} /></label>
						<div ref={(r) => { this.boomJibBrakesTypeRef = r }}>
							<CraneInspectionBoomJibBrakesType
								formData={this.props.formData}
								formValidationMessages={this.props.formValidationMessages}
								setFormData={this.props.setFormData}
							/>
							{!!this.props.formValidationMessages.get('boomJibBrakesType') &&
								<Popover
									id='boomJibBrakesTypeOverlay'
									className='crane_manufacture_information__error_message'
									placement='bottom'
									positionTop={50}
									positionLeft={50}
								>
									<RawIntlProvider value={this.props.intl}>
									<FormattedMessage {...(messages[this.props.formValidationMessages.get('boomJibBrakesType')])} />
									</RawIntlProvider>
								</Popover>
							}
						</div>
					</div>

				</div>

				<div className='row crane_manufacture_information_row'>
					<div className='col-sm-6 crane_manufacture_information_col'>
						<label><FormattedMessage {...messages.crane_inspection__interval} /></label>
						<div className='row'>
							<div ref={(r) => { this.intervalRef = r }} className={this.props.formData.get('interval') == craneInspectionEnums.interval.other ? 'col-sm-6 crane_manufacture_information_interval_col' : 'col-sm-12'} >
								<SplitButton title={this.getIntervalTitle()} pullRight id="interval">
									<MenuItem onSelect={this.handleIntervalClick} eventKey={craneInspectionEnums.interval.annual}><FormattedMessage {...messages.crane_inspection__interval_annual} /></MenuItem>
									<MenuItem  onSelect={this.handleIntervalClick} eventKey={craneInspectionEnums.interval.quadrennial}><FormattedMessage {...messages.crane_inspection__interval_quadrennial} /></MenuItem>
									<MenuItem  onSelect={this.handleIntervalClick} eventKey={craneInspectionEnums.interval.other}><FormattedMessage {...messages.crane_inspection__interval_other} /></MenuItem>
								</SplitButton>
								{!!this.props.formValidationMessages.get('interval') &&
									<Popover
										id='intervalOverlay'
										className='crane_manufacture_information__error_message'
										placement='right'
										positionTop={0}
										positionLeft={158}
									>
										<RawIntlProvider value={this.props.intl}>
										<FormattedMessage {...(messages[this.props.formValidationMessages.get('interval')])} />
										</RawIntlProvider>
									</Popover>
								}
							</div>
							<div ref={(r) => { this.intervalOtherRef = r }} className={this.props.formData.get('interval') == craneInspectionEnums.interval.other ? 'col-sm-4 crane_manufacture_information_interval_other_col' : 'hidden'} >
								<input
									type='text'
									className='crane_manufacture_information_input'
									id='intervalOther'
									maxLength='15'
									value={this.props.formData.get('intervalOther')}
									onChange={this.handleChange}
								/>
								{!!this.props.formValidationMessages.get('intervalOther') &&
									<Popover
										id='intervalOtherOverlay'
										className='crane_manufacture_information__error_message'
										placement='top'
										positionTop={-40}
										positionLeft={-60}
									>
										<RawIntlProvider value={this.props.intl}>
										<FormattedMessage {...(messages[this.props.formValidationMessages.get('intervalOther')])} />
										</RawIntlProvider>
									</Popover>
								}
							</div>
						</div>
					</div>
					<div className='col-sm-6'>
						&nbsp;
					</div>
				</div>

			</div>
		)
	}
};

export default injectIntl(CraneManufactureInformation);