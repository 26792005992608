import React from 'react';
import ReactDOM from 'react-dom'
const scrollIntoView = require('dom-scroll-into-view')
import { defineMessages, FormattedMessage } from 'react-intl';
import { Popover } from 'react-bootstrap';
import moment from 'moment';

import './FraInspectionForm.css';
import * as constants from 'constants/App';
import CheckCircle from 'components/CheckCircle';
import VehicleComponents from './VehicleComponents';
import Spinner from 'components/Spinner';

class FraInspectionForm extends React.Component {	  				
    handleChange = (event) => {
        event.preventDefault();
		this.props.setFormData(event.currentTarget.id, event.currentTarget.value);		
	}		

	render() {
		const messages = defineMessages({			
			fra_inspection_form__title: {
				id: 'fra_inspection_form__title',
				description: 'FRA inspection form title',
				defaultMessage: 'FRA inspection form'
			},			
			fra_inspection_form__carrier_name: {
				id: 'fra_inspection_form__carrier_name',
				description: 'FRA inspection form Carrier name',
				defaultMessage: 'Carrier name'
			},
			fra_inspection_form__unit_number: {
				id: 'fra_inspection_form__unit_number',
				description: 'FRA inspection form unit number',
				defaultMessage: 'Unit number'
			},
			fra_inspection_form__year: {
				id: 'fra_inspection_form__year',
				description: 'FRA inspection form year',
				defaultMessage: 'Year'
			},
			fra_inspection_form__make: {
				id: 'fra_inspection_form__make',
				description: 'FRA inspection form make',
				defaultMessage: 'Make'
			},
			fra_inspection_form__license_plate: {
				id: 'fra_inspection_form__license_plate',
				description: 'FRA inspection form License plate',
				defaultMessage: 'License plate'
			},
			fra_inspection_form__date: {
				id: 'fra_inspection_form__date',
				description: 'FRA inspection form Date',
				defaultMessage: 'Date'
			},
			fra_inspection_form__save: {
                id: 'fra_inspection_form__save',
                description: 'FRA inspection form save and continue button',
                defaultMessage: 'Save and continue'
			},		
		});

		return (
			<div className='fra_inspection_form'>
				
				{/*Title*/}

				<div className='fra_inspection_form__title'>                
					<FormattedMessage {...messages.fra_inspection_form__title} />					
				</div>				

				<div className='fra_inspection_form__outer_box'>
				
					{/*Header*/}

					<div className='row fra_inspection_form__header_row'>
									
						<div className='form-group col-sm-4 fra_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.fra_inspection_form__carrier_name} />
							</label>
							<div>{this.props.formData.get('carrierName')}</div>
						</div>

						<div className='form-group col-sm-4 fra_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.fra_inspection_form__unit_number} />
							</label>
							<div>{this.props.formData.get('unitNumber')}</div>
						</div>

						<div className='form-group col-sm-4 fra_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.fra_inspection_form__year} />
							</label>
							<div>{this.props.formData.get('year')}</div>
						</div>

						<div className='form-group col-sm-4 fra_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.fra_inspection_form__make} />
							</label>
							<div>{this.props.formData.get('make')}</div>
						</div>

						<div className='form-group col-sm-4 fra_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.fra_inspection_form__license_plate} />
							</label>
							<div>{this.props.formData.get('licensePlate')}</div>
						</div>																	

						<div className='form-group col-sm-4 fra_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.fra_inspection_form__date} />
							</label>
							<div>{moment(this.props.formData.get('inspectionDate')).format("MM/DD/YYYY")}</div>
						</div>

					</div>				

					<VehicleComponents 
						formData={this.props.formData}
						setFormData={this.props.setFormData}
						formValidationMessages={this.props.formValidationMessages}  
						componentDetails={this.props.componentDetails}
						assetType={this.props.assetType}
						userLocale={this.props.userLocale}
						poCreationDate={this.props.poCreationDate}
						onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						onInspectionDateChangeHandler={this.props.onInspectionDateChangeHandler}						
					/>				

				</div>

				{/*Footer*/}

				<div className='fra_inspection_form__footer'>        
					<div className='row'>					

						<div className='col-xs-offset-6 col-xs-6 fra_inspection_form__no_gutter_col'>
							<div className='fra_inspection_form__save' onClick={this.props.onFormSaveClick}>
								<FormattedMessage {...messages.fra_inspection_form__save}/>{' '}
								<img src='/assets/VMS_33button_add_blue.png' />
								<div className='fra_inspection_form__spinner'>
									{this.props.isSaving &&
										<Spinner spinnerType='blue' />										
									}
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>
		);
	}
}

export default FraInspectionForm;