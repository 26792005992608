import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import './PaymentEmailInformation.css'
import VendorAccountVerification from './VendorAccountVerification';
import VendorTaxInformation from './VendorTaxInformation';
import { PAYMENT_TYPE } from 'constants/App';
import { useAppState } from 'shared/AppState/app-state';

const PaymentEmailInformation = (props) => {
        
        const [ appState, dispatch ] = useAppState();

        const messages = defineMessages({           
            payment_email_caption: {
                id: 'payment_email_caption',
                description: 'Payment Email Recipient',
                defaultMessage: 'Payment Email Recipient'
            },

            edit_payment_email_information: {
                id: 'edit_payment_email_information',
                description: 'Edit payment email recipient',
                defaultMessage: 'Edit payment email recipient'
            }

        });
        return <div>            
            {
                <div>
                    <div className="payment_email_info_component__view row">
                        <div className="col-sm-7  payment_email_info_component__title">
                            <FormattedMessage {...messages.payment_email_caption} />
                        </div>
                    </div>
                    <div className="payment_email_info_component__view row">
                        <div className="col-sm-8  payment_email_info_component__text">
                            {props.paymentEmail}
                        </div>

                    </div>
                    { appState.bankAccountPermissions.includes("ED") &&
                    <div className="payment_email_info_component__view3 row">
                        
                        
                            <a className="payment_email_info-component__caption2"
                                onClick={props.onChangePaymentEmailVerifyClick.bind(this)}>
                                <FormattedMessage {...messages.edit_payment_email_information} />{' '}
                                <img src="/assets/VMS_28button_edit_blue.png" />
                            </a>
                       
                    </div>
                    }
                </div>
            }
        </div>;
    }


export default (PaymentEmailInformation);
