import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import './GoToNextStep.css';
import Spinner from 'components/Spinner';
import classnames from 'classnames';

class GoToNextStep extends React.Component {

    render() {
        const messages = defineMessages({
            goto_next_link_caption: {
                id: 'goto_next_link_caption',
                description: 'Go to next step',
                defaultMessage: 'Go to next step'
            },
        });

        return (
            <div className="go-to-next-step">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-8"></div>
                            <div role="button" className={classnames("col-sm-6 col-sm-push-7 go-to-next-step__next-step",{"col-sm-4 col-sm-push-1 go-to-next-step__next-step" : this.props.selectedLocale === "en-US"})}
                                 onClick={this.props.onClickHandler.bind(this)}>
                                <FormattedMessage {...messages.goto_next_link_caption} />
                                {' '}<img src="/assets/VMS_33button_go_bblue.png"/>
                                 <div className="inline-block">
                                {this.props.showSpinner ?                                
                                    <Spinner spinnerType={this.props.spinnerType} />
                                : false
                                }
                            </div>       
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default GoToNextStep;
