import React from 'react';
import  {render} from 'react-dom';
import  { BrowserRouter ,Route,Router  } from 'react-router-dom';
import App from '../screens/App/containers/App';
import ProviderWithRouter from './ProviderWithRouter';



render((
    <div>
        <BrowserRouter>
            <ProviderWithRouter isDevelopment={false}>

                <Route path="/" component={App} />

            </ProviderWithRouter>

        </BrowserRouter>
    </div>
), document.getElementById('root'));

