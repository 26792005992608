import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import './VendorServices.css'
import {Grid, Row, Col} from 'react-bootstrap';
import EditVendorServices from './EditVendorServices';
import { useAppState } from 'shared/AppState/app-state';

const VendorServices = (props) => {

        const [ appState, dispatch ] = useAppState();

        const messages = defineMessages({
            services_view_caption: {
                id: 'services_view_caption',
                description: 'services',
                defaultMessage: 'Services'
            },
            minority_vendor_caption: {
                id: 'minority_vendor_caption',
                description: 'minority vendor caption',
                defaultMessage: 'Minority Vendor'
            },
            edit_services_caption: {
                id: 'edit_services_caption',
                description: 'edit services caption',
                defaultMessage: 'Edit services'
            },
            no_services_caption: {
                id: 'no_services_caption',
                description: 'no_services_caption',
                defaultMessage: 'None'
            },
        });

        const columns = 3;
        const itemsPerColumn = Math.ceil(props.vendorServices.count() / columns);

        return <div className={props.isEditModeServices && 'vendor_services__main--edit_mode'}>
            <hr className="vendor-services__hr"/>
            <div className="vendor-services__caption">
                <FormattedMessage {...messages.services_view_caption}/>
            </div>
            <div className="vendor-services__service_layout">
                {!props.isEditModeServices ?
                    <div>                    
                        <Row className="vendor-services__row">
                            <Col xs={4} className="vendor-services__col">
                                {props.vendorServices.take(itemsPerColumn).map(service =>
                                    <div
                                        className={ service.get("isServiceOffered") ? "vendor-services__title" : "vendor-services__title3" }
                                        key={service.get('id')}>
                                        {service.get("description")}
                                    </div>
                                ).valueSeq()}                            
                            </Col>
                            <Col xs={4} className="vendor-services__col">
                                {props.vendorServices.skip(itemsPerColumn).take(itemsPerColumn).map(service =>
                                    <div
                                        className={ service.get("isServiceOffered") ? "vendor-services__title" : "vendor-services__title3" }
                                        key={service.get('id')}>
                                        {service.get("description")}
                                    </div>
                                ).valueSeq()}                            
                            </Col>
                            <Col xs={4} className="vendor-services__col">
                                {props.vendorServices.skip(itemsPerColumn*2).map(service =>
                                    <div
                                        className={ service.get("isServiceOffered") ? "vendor-services__title" : "vendor-services__title3" }
                                        key={service.get('id')}>
                                        {service.get("description")}
                                    </div>
                                ).valueSeq()}
                            </Col>                            
                        </Row>
                        
                       {props.showVendorMinority?<hr className="vendor-services__black-hr"/>:false
                       }
                       {props.showVendorMinority?
                        <div className="vendor-services__view">
                            <div className="vendor-services__title2">
                                <FormattedMessage {...messages.minority_vendor_caption}/>
                            </div>
                        </div>
                        :false
                       }
                       {props.showVendorMinority?
                        
                        <div className="vendor-services__col">
                            {props.minoritySelected.isEmpty() ?
                                <FormattedMessage {...messages.no_services_caption}/>
                                :
                                props.minoritySelected.map(service =>
                                <div className="vendor-services__title4" key={service.get('id')}>
                                    {service.get("description")}
                                </div>
                            ).valueSeq()}
                        </div>
                        :false
                       }
                        { appState.servicesPermissions.includes("ED") &&
                        <div className="vendor-services__edit_button row">
                            <a className="vendor-services__caption2"
                                onClick={props.vendorServicesClick.bind(this)}>
                                <FormattedMessage {...messages.edit_services_caption}/>{' '}
                                <img src="/assets/VMS_28button_edit_blue.png"/>
                            </a>                        
                        </div>  
                        }                  
                    </div>
                    : 
                    <EditVendorServices
                        vendorServices={props.vendorServices}
                        vendorMinority={props.vendorMinority}
                        vendorServicesSelected={props.vendorServicesSelected}
                        servicesCancel={props.servicesCancel}
                        vendorMinoritySelected={props.vendorMinoritySelected}
                        onSaveVendorServices={props.onSaveVendorServices}
                        onBlurChange={props.onBlurChange.bind(this)}
                        showFooterButtons = {props.showFooterButtons}
                        showVendorMinority={props.showVendorMinority}
                        country={props.country}
                    />
                }
            </div>
        </div>;
    }


export default (VendorServices);
