/**
 * Created by prboyapa on 2/8/2016.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Map} from 'immutable';
import {defineMessages, FormattedMessage, FormattedNumber, FormattedDate, FormattedTime} from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import './EstimatedCompletionDate.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from '../../shared/utils/MomentLocaleUtils';

class EstimatedCompletionDate extends React.Component {

    constructor(props) {
        super(props);
        //const locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(this.props.userLocale);
        
        this.state = {
            format: "MM/DD/YY h:mm A",
            inputFormat: "MM/DD/YYYY",
            mode: "date",
            isEstimateDateValid: true,
            selectedTime: undefined, 
            startDate: undefined
        }
    }

    validateTime = (estimatedDate, from, to) => {
        return (moment(estimatedDate).hours() >= from && moment(estimatedDate).hours() < to);
    }

    onDateChangeHandler = (newDate) => {

        if(newDate !== undefined  ) {

            if (moment(newDate).isSameOrAfter( moment().local())) {
                this.props.onDateChangeEvent(newDate);
                this.props.onUpdateEstimatedCompletionDate(true);
                this.setState({isEstimateDateValid: true});


            } else {
                this.props.onDateChangeEvent(new Date(moment(this.props.estimatedCompletionDate)));
                this.props.onUpdateEstimatedCompletionDate(true);
                this.setState({isEstimateDateValid: true});
            }
        }
     
    }

    onTimeChangeHandler = (newTime)=> {        
        this.setState({selectedTime: newTime});        
        this.updateEstimatedDateTime(newTime, this.props.estimatedCompletionDate);
    }

    updateEstimatedDateTime(hours, newDate) {

        if (newDate != undefined && newDate.isValid()) {

                let estimatedDateTime;

                if (newDate == undefined || !newDate.isValid()) {
                    estimatedDateTime = moment(0, "HH").add(hours == undefined ? 8 : hours, 'hours')
                }
                else {
                    estimatedDateTime = moment(newDate).local().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).add(hours == undefined ? 8 : hours, 'hours')
                }

                estimatedDateTime = moment(estimatedDateTime).local();
                this.props.onDateChangeEvent(estimatedDateTime);
                this.props.onUpdateEstimatedCompletionDate(true);
                this.setState({isEstimateDateValid: true});

        } else {
            this.props.onUpdateEstimatedCompletionDate(false);
            this.props.onDateChangeEvent(undefined);
            this.setState({isEstimateDateValid: false});
        }

    }

    render() {
        const {format, mode, inputFormat} = this.state;
        const messages = defineMessages({
            estimated_completion_description: {
                id: 'estimated_completion_description',
                description: 'Estimated completion for all repairs and services',
                defaultMessage: 'Estimated completion for all repairs and services'
            },
            estimated_completion_morning: {
                id: 'estimated_completion_morning',
                description: 'Morning',
                defaultMessage: 'Morning'
            },
            estimated_completion_afternoon: {
                id: 'estimated_completion_afternoon',
                description: 'Afternoon',
                defaultMessage: 'Afternoon'
            },
            estimated_completion_evening: {
                id: 'estimated_completion_evening',
                description: 'Evening',
                defaultMessage: 'Evening'
            },
            estimated_completion_unavailable: {
                id: 'estimated_completion_unavailable',
                description: 'Unavailable',
                defaultMessage: 'Unavailable'
            }

        });

        return <div className={classNames('estimated_completion',
            {'estimated_completion__edit': this.props.actionType == 'ADD'})}>
            <table className="table-responsive">
                <tbody>
                {
                    /*Show or hide editable date control based on user action */                    <tr className="row">

                            <td className="col-sm-1">
                            </td>
                        <td colSpan="3" className={classNames('col-sm-10',
                                                'estimated_completion__box',
                                                 {"estimated_completion_date_edit__alert": !this.state.isEstimateDateValid || !this.props.isEstimateDateValid })}>
                            <div className='row'>
                                <div className="col-sm-12 estimated_completion__description">
                                    <div className="col-sm-6 estimated_completion__label">
                                        <FormattedMessage {...messages.estimated_completion_description} />
                                    </div>

                                    <div className={classNames('col-sm-6',
                                                    {'estimated_completion__date': this.props.actionType == 'VIEW'},
                                                    {'estimated_completion__date_edit': this.props.actionType != 'VIEW'})}>
                                        <div className='row'>
                                            <div className="col-sm-12">
                                                {this.props.actionType == 'VIEW' ?

                                                    /* TODO: Need to work on this later
                                                     <FormattedDate
                                                     value={this.props.estimatedCompletionDate.format("MM/DD/YY A")}
                                                     day="numeric" month="numeric"
                                                     year="numeric"/>*/

                                                    this.props.purchaseOrder.get('estimatedCompletionDate') != undefined &&
                                                    moment(this.props.purchaseOrder.get('estimatedCompletionDate')).isValid()
                                                        ? moment(this.props.purchaseOrder.get('estimatedCompletionDate')).format(inputFormat)
                                                        : <FormattedMessage {...messages.estimated_completion_unavailable}/>
                                                    :
                                                    <div className = 'row'>
                                                        <div className = 'estimated_completion_date__date_picker'>
                                                            <DayPickerInput
                                                                ref='dtPicker1'
                                                                inputProps={{class:'estimated_completion_date__date_picker-input'} }
                                                                tabIndex={1}
                                                                placeholder ={'MM/DD/YYYY'}
                                                                onDayChange={this.onDateChangeHandler.bind(this)}
                                                                dayPickerProps={{
                                                                    disabledDays: {
                                                                        before: new Date(moment()),
                                                                    },
                                                                    selectedDays:new Date(moment(this.props.estimatedCompletionDate)),
                                                                    locale: this.props.userLocale.substring(0,2),
                                                                    localeUtils: MomentLocaleUtils,
                                                                }}
                                                                formatDate={formatDate}
                                                                parseDate={parseDate}
                                                                format="MM/DD/YYYY"
                                                                value={this.props.estimatedCompletionDate !== undefined
                                                                    ? new Date(moment(this.props.estimatedCompletionDate))
                                                                    : undefined}



                                                            />
                                                        </div>

                                                    </div>                                                                
                                                }
                                            </div>
                                        </div>
                                        <div className='row estimated_completion_time'>
                                            <div className={classNames({'col-sm-3': this.props.userLocale === 'fr-CA'},
                                                {'col-sm-4': this.props.userLocale === 'en-US' || this.props.userLocale === 'es-MX' })}>
                                                <a className={classNames('btn btn-default',
                                                            {'btn-default_selected': this.validateTime(this.props.purchaseOrder.get('estimatedCompletionDate'),0,12)==true},
                                                            {'disabled': this.props.actionType=='VIEW'})}
                                                   role="button"
                                                   onClick={this.onTimeChangeHandler.bind(this,8)}>
                                                    <FormattedMessage {...messages.estimated_completion_morning} /></a>
                                            </div>
                                            <div className={classNames({"col-sm-4": this.props.userLocale === 'es-MX'},
                                            {"col-sm-4 estimated_completion_date__afternoon": this.props.userLocale !== 'es-MX'})}
                                            >
                                                <a className={classNames('btn btn-default',
                                                            {'btn-default_selected': this.validateTime(this.props.purchaseOrder.get('estimatedCompletionDate'),12,18)==true},
                                                            {'disabled': this.props.actionType=='VIEW'}
                                                            )} role="button"
                                                   onClick={this.onTimeChangeHandler.bind(this,12)}>
                                                    <FormattedMessage {...messages.estimated_completion_afternoon} /></a>
                                            </div>
                                            <div className={classNames({"col-sm-4": this.props.userLocale !== 'es-MX'},
                                            {"col-sm-4 estimated_completion_date__afternoon": this.props.userLocale === 'es-MX'})}>
                                                <a className={classNames('btn btn-default',
                                                            {'btn-default_selected': this.validateTime(this.props.purchaseOrder.get('estimatedCompletionDate'),18,24)==true},
                                                            {'disabled': this.props.actionType=='VIEW'})} role="button"
                                                   onClick={this.onTimeChangeHandler.bind(this,18)}>
                                                    <FormattedMessage {...messages.estimated_completion_evening} /></a>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="col-sm-1">
                        </td>
                    </tr>
                }

                </tbody>
            </table>
        </div>
    }
}



export default EstimatedCompletionDate;