import React from 'react';
import { defineMessages, FormattedMessage, injectIntl, RawIntlProvider } from 'react-intl';
import { Popover } from 'react-bootstrap';
import classnames from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import './BitResult.css';
import CheckCircle from 'components/CheckCircle';
import moment from 'moment';
import MomentLocaleUtils, {
	formatDate,
	parseDate,
} from 'shared/utils/MomentLocaleUtils';

class BitResult extends React.Component {
	constructor(props) {
		super(props);
		moment.locale(this.props.userLocale);
	}

	handlePassCheckCircleClick = (name, checked) => {
		if (!checked)
			this.props.setFormData('hasInspectionPassed', true);
		else
			this.props.setFormData('hasInspectionPassed', null);
	}

	handleFailCheckCircleClick = (name, checked) => {
		if (!checked) {
			this.props.setFormData('hasInspectionPassed', false);
			this.props.setFormData('inspectorAttested', false);

			if (this.props.onInspectionFailClick)
				this.props.onInspectionFailClick();
		}
		else
			this.props.setFormData('hasInspectionPassed', null);
	}

	onClickAttest = () => {
		this.props.setFormData('inspectorAttested',
			!this.props.formData.get('inspectorAttested'));
	}

	handleChange = (event) => {
		event.preventDefault();
		this.props.setFormData(event.currentTarget.id, event.currentTarget.value);
	}

	onDateChangeHandler = (selectedDay, modifiers) => {
		this.props.onInspectionDateChangeHandler(selectedDay,modifiers.disabled);
		if (modifiers.disabled || !selectedDay) {
			this.props.setFormData('inspectionDate', null);
			return;
		}
		else {
			this.props.setFormData('inspectionDate', selectedDay);
		}
	}

	render() {
		//#region style
		const modifiers={
			today:{}
		}
		//#endregion

		const messages = defineMessages({
			bit_inspection__pass: {
				id: 'bit_inspection__pass',
				description: 'Inspection pass label',
				defaultMessage: 'PASS'
			},
			bit_inspection__fail: {
				id: 'bit_inspection__fail',
				description: 'Inspection fail label',
				defaultMessage: 'FAIL'
			},
			bit_inspection__has_inspection_passed_required: {
				id: 'bit_inspection__has_inspection_passed_required',
				description: 'Inspection pass/label label required',
				defaultMessage: 'Please select PASS or FAIL'
			},
			bit_inspection__has_inspection_passed_components_invalid: {
				id: 'bit_inspection__has_inspection_passed_components_invalid',
				description: 'Inspection passed but all items are not passed',
				defaultMessage: 'All items need to be marked as OK or N/A to pass inspection'
			},
			bit_inspection__has_inspection_passed_unsafe_conditions: {
				id: 'bit_inspection__has_inspection_passed_unsafe_conditions',
				description: 'Inspection passed but unsafe conditions was filled out',
				defaultMessage: 'The inspection cannot pass with unsafe conditions listed'
			},
			bit_inspection__has_inspection_passed_no_failure_specified: {
				id: 'bit_inspection__has_inspection_passed_no_failure_specified',
				description: 'Inspection failed but no item is marked as failed',
				defaultMessage: 'Please specify failed items'
			},
			bit_inspection__has_inspection_failed_all_components_required: {
				id: 'bit_inspection__has_inspection_failed_all_components_required',
				description: 'Inspection failed but all components must specify OK/NA/Def.',
				defaultMessage: 'All components must specify OK/NA/Def.'
			},
			bit_inspection__has_inspection_other_option_comments_required: {
				id: 'bit_inspection__has_inspection_other_option_comments_required',
				description: 'Comments are required for "Other" vehicle component.',
				defaultMessage: 'Comments are required for "Other" vehicle component.'
			},
			bit_inspection__inspection_date: {
				id: 'bit_inspection__inspection_date',
				description: 'Inspection date label',
				defaultMessage: 'Inspection date'
			},
			bit_inspection__inspection_date_invalid: {
				id: 'bit_inspection__inspection_date_invalid',
				description: 'Inspection date is invalid/incorrectly formatted',
				defaultMessage: 'Date invalid/incorrectly formatted, please enter a valid date'
			},
			bit_inspection__inspection_date_disabled: {
				id: 'bit_inspection__inspection_date_disabled',
				description: 'Inspection date disabled/out of range',
				defaultMessage: 'Please enter a valid date'
			},
			bit_inspection__inspector_attest: {
				id: 'bit_inspection__inspector_attest',
				description: 'Inspector attested label',
				defaultMessage: 'I attest that I am qualified to perform the requested inspection and that this vehicle has passed all the inspection items in accordance with the BIT requirements.'
			},
			bit_inspection__attest_required: {
				id: 'bit_inspection__attest_required',
				description: 'Validation: inspector did not attest',
				defaultMessage: 'Please attest to this statement'
			},
			bit_inspection__inspector_signature: {
				id: 'bit_inspection__inspector_signature',
				description: 'Inspector signature label',
				defaultMessage: "Inspector's signature"
			},
			bit_inspection__inspector_signature_note: {
				id: 'bit_inspection__inspector_signature_note',
				description: 'Inspector signature note',
				defaultMessage: "By typing your name, you agree this will serve as the electronic respresentation of your signature."
			},
			bit_inspection__signature_required: {
				id: 'bit_inspection__signature_required',
				description: 'Validation: inspector signature required',
				defaultMessage: 'Please enter your signature'
			},
		});

		return (
			<div>

				<div className="row no-gutters bit_result__result_code" >

					<div className="col-xs-2 no-gutters-col">
						<div className='bit_result__field_container' tabIndex='0'>
							<CheckCircle
								name='hasInspectionPassedTrue'
								checked={this.props.formData.get('hasInspectionPassed') === true}
								message={<FormattedMessage {...messages.bit_inspection__pass} />}
								onClick={this.handlePassCheckCircleClick} />
						</div>
					</div>

					<div className="col-xs-2 no-gutters-col">
						<div className='bit_result__field_container' tabIndex='0'>
							<CheckCircle
								name='hasInspectionPassedFalse'
								checked={this.props.formData.get('hasInspectionPassed') === false}
								onClick={this.handleFailCheckCircleClick}
								message={
									<FormattedMessage {...messages.bit_inspection__fail} />
								}
							/>

							{!!this.props.formValidationMessages.get('hasInspectionPassed') &&
								<Popover
									id='hasInspectionPassedOverlay'
									className='bit_result__error_message'
									placement='right'
									positionTop={-50}
									positionLeft={75}
								>
									<RawIntlProvider value={this.props.intl}>
										<FormattedMessage {...(messages[this.props.formValidationMessages.get('hasInspectionPassed')])} />
									</RawIntlProvider>
								</Popover>
							}
						</div>
					</div>

				</div>

				<div className='bit_result__inspection_date'>
					<div className='bit_result__field_container' tabIndex='0'>
						<div>
							<FormattedMessage {...messages.bit_inspection__inspection_date} />
						</div>
						<div className='bit_result__date_input'>
							<DayPickerInput
								onDayChange={this.onDateChangeHandler}
								placeholder={'MM/DD/YYYY'}
								format={'MM/DD/YYYY'}
								value={this.props.formData.get('inspectionDate')}
								formatDate={formatDate}
								parseDate={parseDate}
								dayPickerProps={{
									disabledDays: {
										before: new Date(moment(this.props.poCreationDate).subtract(90, 'days')),
										after: new Date(moment().subtract(0, 'days'))
									},
									selectedDays: this.props.formData.get('inspectionDate'),
									locale: this.props.userLocale.substring(0, 2),
									localeUtils: MomentLocaleUtils,
									modifiers: modifiers,
								}}
							/>
							{!!this.props.formValidationMessages.get('inspectionDate') &&
								<Popover
									id='inspectionDateOverlay'
									className='bit_result__error_message'
									placement='right'
									positionTop={10}
									positionLeft={300}
								>
									<RawIntlProvider value={this.props.intl}>
										<FormattedMessage {...(messages[this.props.formValidationMessages.get('inspectionDate')])} />
									</RawIntlProvider>
								</Popover>
							}
						</div>
					</div>
				</div>

				{this.props.formData.get('hasInspectionPassed') !== false &&
					<div className='bit_result__attest'>
						<div className='bit_result__field_container' tabIndex='0'>
							<CheckCircle checked={this.props.formData.get('inspectorAttested')}
								name='inspectorAttested'
								message={<FormattedMessage {...messages.bit_inspection__inspector_attest} />}
								disabled={this.props.formData.get('hasInspectionPassed') === false}
								onClick={this.onClickAttest}
							/>
							{!!this.props.formValidationMessages.get('inspectorAttested') &&
								<Popover
									id='inspectorAttestedOverlay'
									className='bit_result__error_message'
									placement='right'
									positionTop={20}
									positionLeft={500}
								>
									<RawIntlProvider value={this.props.intl}>
										<FormattedMessage {...(messages[this.props.formValidationMessages.get('inspectorAttested')])} />
									</RawIntlProvider>
								</Popover>
							}
						</div>
					</div>
				}

				<div className='bit_result__inspector_signature' ref={(r) => { this.signatureRef = r }}>
					<div className='bit_result__field_container'>
						<FormattedMessage {...messages.bit_inspection__inspector_signature} />
						{' '}
						<input
							id='inspectorSignature'
							className='txtName'
							maxLength='50'
							value={this.props.formData.get('inspectorSignature')}
							onChange={this.handleChange}
						/>
						<div className='bit_result__inspector_signature_note'>
							<FormattedMessage {...messages.bit_inspection__inspector_signature_note} />
						</div>
						{!!this.props.formValidationMessages.get('inspectorSignature') &&
							<Popover
								id='inspectorSignatureOverlay'
								className='bit_result__error_message'
								placement='bottom'
								positionTop={35}
								positionLeft={50}
							>
								<RawIntlProvider value={this.props.intl}>
									<FormattedMessage {...(messages[this.props.formValidationMessages.get('inspectorSignature')])} />
								</RawIntlProvider>
							</Popover>
						}
					</div>
				</div>

			</div>
		)
	}
};

export default injectIntl(BitResult);
