import { Map, List } from 'immutable';
import * as constants from 'constants/App';

const initialAppState = Map({
    uiData: Map({

        shared: Map({
            features: Map({
                workCompletion: false      
            }),
            fieldValidations: Map({
                REFERENCE_NO:  Map({           
                    isRequired : false,
                    minAllowedLength : null,
                    maxAllowedLength : null,
                    isVisible : true,
                    type: 'string'
                }),
                YOUR_NAME:  Map({           
                    isRequired : true,
                    minAllowedLength : null,
                    maxAllowedLength : null,
                    isVisible : true,
                    type: 'string'
                }),
                INVOICE_NO:  Map({           
                    isRequired : true,
                    minAllowedLength : 4,
                    maxAllowedLength : 20,
                    isVisible : true,
                    type: 'string'
                }),
                REPAIR_START_DATE:  Map({           
                    isRequired : true,
                    minAllowedLength : null,
                    maxAllowedLength : null,
                    isVisible : true,
                    type: 'date'
                }),
                REPAIR_COMPLETION_DATE:  Map({           
                    isRequired : true,
                    minAllowedLength : null,
                    maxAllowedLength : null,
                    isVisible : true,
                    type: 'date'
                }),
                INVOICE_DATE:  Map({           
                    isRequired : true,
                    minAllowedLength : null,
                    maxAllowedLength : null,
                    isVisible : true,
                    type: 'date'
                }),
                ODOMETER: Map({           
                    isRequired : true,
                    minAllowedLength : null,
                    maxAllowedLength : null,
                    isVisible : true,
                    type: 'number'
                }),
                TAX: Map({           
                    isRequired : true,
                    minAllowedLength : null,
                    maxAllowedLength : null,
                    isVisible : true,
                    type: 'number'
                }),
                CONFIRM_WORK_IS_COMPLETE: Map({           
                    isRequired : true,
                    minAllowedLength : null,
                    maxAllowedLength : null,
                    isVisible : true,
                    type: 'mustbetrue'
                }),
                CONFIRM_BANK_ACCOUNT: Map({           
                    isRequired : true,
                    minAllowedLength : null,
                    maxAllowedLength : null,
                    isVisible : true,
                    type: 'mustbetrue'
                }),
            }),
            selectedLocale: "en-US",
            /* Map of components currently fetching data */
            componentsFetchingDataFromAPI: Map(),
            errorMessage: [],
            selectedCountry: "USA",
            selectedCurrency: "USD",
            vehicleSearchErrorMessage: undefined,
            showVehicleSearchErrorMessage: false,
            vehicleSearchVin8Value: undefined,
            uiErrorMessage: undefined,
            usStates: [
                { name:'Alabama', abbreviation:'AL'},
                { name:'Alaska', abbreviation:'AK'},
                { name:'Arizona', abbreviation:'AZ'},
                { name:'Arkansas', abbreviation:'AR'},
                { name:'California', abbreviation:'CA'},
                { name:'Colorado', abbreviation:'CO'},
                { name:'Connecticut', abbreviation:'CT'},
                { name:'Delaware', abbreviation:'DE'},
                { name:'Florida', abbreviation:'FL'},
                { name:'Georgia', abbreviation:'GA'},
                { name:'Hawaii', abbreviation:'HI'},
                { name:'Idaho', abbreviation:'ID'},
                { name:'Illinois', abbreviation:'IL'},
                { name:'Indiana', abbreviation:'IN'},
                { name:'Iowa', abbreviation:'IA'},
                { name:'Kansas', abbreviation:'KS'},
                { name:'Kentucky', abbreviation:'KY'},
                { name:'Louisiana', abbreviation:'LA'},
                { name:'Maine', abbreviation:'ME'},
                { name:'Maryland', abbreviation:'MD'},
                { name:'Massachusetts', abbreviation:'MA'},
                { name:'Michigan', abbreviation:'MI'},
                { name:'Minnesota', abbreviation:'MN'},
                { name:'Mississippi', abbreviation:'MS'},
                { name:'Missouri', abbreviation:'MO'},
                { name:'Montana', abbreviation:'MT'},
                { name:'Nebraska', abbreviation:'NE'},
                { name:'Nevada', abbreviation:'NV'},
                { name:'New Hampshire', abbreviation:'NH'},
                { name:'New Jersey', abbreviation:'NJ'},
                { name:'New Mexico', abbreviation:'NM'},
                { name:'New York', abbreviation:'NY'},
                { name:'North Carolina', abbreviation:'NC'},
                { name:'North Dakota', abbreviation:'ND'},
                { name:'Ohio', abbreviation:'OH'},
                { name:'Oklahoma', abbreviation:'OK'},
                { name:'Oregon', abbreviation:'OR'},
                { name:'Pennsylvania', abbreviation:'PA'},
                { name:'Puerto Rico', abbreviation:'PR'},
                { name:'Rhode Island', abbreviation:'RI'},
                { name:'South Carolina', abbreviation:'SC'},
                { name:'South Dakota', abbreviation:'SD'},
                { name:'Tennessee', abbreviation:'TN'},
                { name:'Texas', abbreviation:'TX'},
                { name:'Utah', abbreviation:'UT'},
                { name:'Vermont', abbreviation:'VT'},
                { name:'Virgin Islands', abbreviation:'VI'},
                { name:'Virginia', abbreviation:'VA'},
                { name:'Washington', abbreviation:'WA'},
                { name:'Washington D.C.', abbreviation:'DC'},
                { name:'West Virginia', abbreviation:'WV'}, 
                { name:'Wisconsin', abbreviation:'WI'},
                { name:'Wyoming', abbreviation:'WY'},
            ],
            canadianProvinces: [
                { name: 'Alberta', abbreviation: 'AB' },
                { name: 'British Columbia', abbreviation: 'BC' },
                { name: 'Manitoba', abbreviation: 'MB' },
                { name: 'New Brunswick', abbreviation: 'NB' },
                { name: 'Newfoundland And Labrador', abbreviation: 'NF' },                
                { name: 'Nova Scotia', abbreviation: 'NS' },
                { name: 'Northwest Territories', abbreviation: 'NT'},
                { name: 'Nunavut', abbreviation:'NU'},
                { name: 'Ontario', abbreviation: 'ON' },
                { name: 'Price Edward Island', abbreviation: 'PE' },
                { name: 'Quebec', abbreviation: 'PQ' },             
                { name: 'Saskatchewan', abbreviation: 'SK' },
                { name: 'Yukon', abbreviation: 'YU' }
            ]
        }),

        loginView: Map({
            loginErrorMessage: undefined
        }),

        shopView: Map({
            /* By default Shop View shows only 10 active POs.
             When these flags are true, UI will show ALL active POs */
            showAllApprovedPOs: false,
            showAllWaitingForARIApprovalPOs: false,
            showAllWaitingForClientAuthorizationPOs: false,
            showAllVendorActionNeededPOs: false,
            showAllNewWorkAssignmentPOs:false,
            showAllWorkCompletedPOs:false,
        }),

        odometerView: Map({
            /* User saves the PO data at different steps*/
            isVehicleAtShop: undefined,
            newOdometer: undefined,
            newEngineHours: undefined
        }),
        diagnosisView: Map({
            diagnosisItem: Map({
                diagnosisRadioSelected: false,
                hours: undefined,
                rate: undefined
            })
        }),

        complaintView: Map({
            /* Service Ids selected by Vendor */
            servicesSelected: Map(),

            diagnosisItem: Map({
                diagnosisRadioSelected: false,
                hours: undefined,
                rate: undefined,
                readOnly: false
            }),

            showErrorMessages: false,
        }),

        addServiceLineItemsView: Map({
            /* Can add service line item modal window be shown*/
            showWindow: false,
            /* ATA code data with labor and part*/
            serviceLineItems: Map({
                /*[1E001006]: Map({
                 partCost:0,
                 laborRate:0,
                 }),
                 */
            })
        }),
        /*add new line item modal*/
        addNewLineItemsView: Map({
            showWindow: false,
            selectedATACode: "",
            selectedCorrectionType: "None",
            selectedComplaintCode: "",
            searchedATACode: "",
            hours: undefined,
            cost: undefined,
            rate: undefined,
            qty: undefined,
            hideAtaSearchResultContainer: true,
            itemsAddedToPo: Map(),
            tires:Map(
                {
                    maxAxlesAllowed:10,
                    defaultTreadDepthUnit:'inch', 
                }),
            brakes:Map({
                maxBrakeAxlesAllowed:9,
                defaultBrakeMeasurementUnit:'milli_meter',
            })
        }),
        /* Purchase order view nodes*/
        addEdit: Map({
            isDirty: false,

            /* Complaints */
            complaints: Map({
                complaint1: Map({
                    index: 1,
                    title: "Complaint 1",
                    code: undefined,
                    description: undefined,
                    isSelectMode: true,
                    wasComplaintSkipped: false,
                    isComplaintOpen: true
                }),
            }),
            showErrorMessages: false,
           
            /*
             estimatedCompletionDate: new Date(),

             User saves the multiple complaints data into this object
             complaints: Map({
             MA99: Map({
             complaintCode: "MA99",
             description: "Services",
             complaintType: "Services",
             comments: undefined
             })
             }),

             User saves the multiple line items data into this object
             lineItems: Map({
             lineDescription: "AC Concern description 1",
             ataCode: "ata_56789",
             quantity: 2,
             cost: 79.56,
             amount: 159.12,
             status: constants.LINE_ITEM_STATUS.PENDING,
             complaintCode: "AC99"
             })*/
        }),

        adjustment: Map(),

        close: Map({
            /* invoice details for closing the PO*/
            invoiceDetails: Map({
                invoiceNo: undefined,
                invoiceDate: new Date(),
                submittedBy: undefined,
                repairCompletionDate: new Date(),
                isTaxExempted: false,
                taxAmount: 0.00,
                exemptedReason: "TaxExempt",
                canShowMultipleTaxTypes: false,
                isPaymentCreditToAccount: true,
                isWorkCompleted: false,
                subTotal: 0.00,
                taxes: Map()
            })
        }),

        searchPO: Map({
            showAllServiceInProgressPOs: false,
            showAllPaymentInProgressPOs: false,
            showAllPaidPOs: false,
            searchFilters: Map({
                searchFor: constants.SEARCH_FILTER_TYPE.VIN8,
                days: constants.SEARCH_DURATION_TYPE.DURATION,
                searchText: ''
            })
        }),

        repairHistory: Map({
            offset: 0
        }),
        feedbackForm: Map({

        }),
        actionRequired: Map({
            isBusy: false
        }),
        vendorAccount: Map({
            btnToggle: Map({
                buttonToggleShow: true,
                buttonToggleHide: false,
            }),
            settingsView: Map({
                passwordSettings: Map({
                    isChangingPassword: false,
                    isEditMode: false,
                    ariId: undefined,
                    password: undefined,
                    newPassword: undefined,
                    showConfirmNewPwdError: false,
                    showNewPwdError: false,
                    showCurrentPwdError: false,
                    showAriIdError: false,
                    ariIdErrorMesg: undefined,
                    currentPwdErrorMesg: undefined,
                    newPwdErrorMesg: undefined,
                    confirmPwdErrorMesg: undefined,
                    wasPasswordChanged: false
                }),
                contactSettings: Map({
                    isEditMode: false


                }),
            }),
            accountInformation: Map({
                isEditModeValidation: false,
                isPaymentEmailEditModeValidation:false,
                isPaymentEmailEditMode:false,
                isEditMode: false
            }),
            vendorProfile: Map({


            }),
            authorizationEmailConfig: Map({
                contacts: List([{ id: 0, name: '', email: '', elementState: constants.ELEMENT_STATE.ADD }]),
                isRegistered:undefined, 
                formMode:constants.VIEW,
                firstContactIdForAdd:999999999                
            }),
            authorizationEmailConfigValidationErrs:Map({
                contacts:Map()
            })

        }),
        recoverPassword: Map({

        }),
        vendorEnrollmentView: Map({
            requestSubmitted: false,
            serviceResponse: {},
            enrollmentDetailsInfo: Map({
                id: undefined,
                businessName: undefined,
                contactName: undefined,
                termsReadAndAgreed: false,
                termsAcceptedBy: undefined,
                businessNameDba: undefined,
                selectedTaxClassification: undefined,
                liabilityTaxClassification: undefined,
                ssnArea: undefined,
                ssnGroup: undefined,
                ssnSerial: undefined,
                employerIdFirstPart: undefined,
                employerIdSecondPart: undefined,
                clientReferenceName: undefined,
                serviceClassification: "REPAIR"
            }),
            federalTaxClassifications: Map({
                tax__classification_I: Map({ id:"tax__classification_I", code: "I", description: "Individual/Sole Proprietor" , defaultMessage:"Individual/Sole Proprietor" }),
                tax__classification_C: Map({id:"tax__classification_C", code: "C", description: "C Corporation" , defaultMessage:"C Corporation"}),
                tax__classification_S: Map({ id:"tax__classification_S",code: "S", description: "S Corporation", defaultMessage:"S Corporation" }),
                tax__classification_P: Map({ id:"tax__classification_P", code: "P", description: "Partnership", defaultMessage:"Partnership" }),
                tax__classification_L: Map({ id:"tax__classification_L", code: "L", description: "Limited Liability Company", defaultMessage:"Limited Liability Company" })
            }),
            enrollmentTermsAndConditions: [
                { seq: 1, description: "Authorizes Holman to make payment directly to the bank account you will provide during registration " },
                { seq: 2, description: "Consents to pay the Holman Pay bank handling fee (3.0%) as agreed with Holman" },
                { seq: 3, description: "Acknowledges that the origination of Holman Pay transactions to the account must comply with U.S. law" },
                { seq: 4, description: " **Will inform Holman of any changes, including account and business location at least 5 business days prior to the change**" },
                { seq: 5, description: "Acknowledges that Holman may reproduce an invoice evidencing transactions under this agreement" }
            ],
            liabilityTaxClassification: ['C', 'S', 'P']
        }),
        vendorEnrollmentRequestView: Map({
            requestSubmitted: false,
            serviceResponse: {},
            enrollmentRequestInfo: Map({
                businessName: undefined,
                address1: undefined,
                address2: undefined,
                city: undefined,
                state: undefined,
                zip: undefined,
                country: undefined,
                contactName: undefined,
                phoneArea: undefined,
                phonePrefix: undefined,
                phoneSuffix: undefined,
                extension: undefined,
                email: undefined,
                reEnterEmail: undefined,
                faxArea: undefined,
                faxPrefix: undefined,
                faxSuffix: undefined,
                referralCode: undefined,
            }),
        }),
        documents: Map({}),
        driverValidationComplaints: Map(),
        registrationView: Map({
            formData: Map(),
            formDataValidationErrs: Map()
        }),
        vendorMyUnits: Map({
            myUnits: [],
            myOtherUnits: []
        }),
        vendorLinkedAccounts: Map({
            vendorSubVendor: Map({}),
            linkedVendors: Map({}),
        }),
        vendorLinkedRegister:Map({
            linkedRequestInfo:Map({
                contactName:undefined,
                emailAddress:undefined,
                newPassword:undefined,
                confirmPassword:undefined
            })
        }),
        carWashAccount:Map({
            vehicleSearchError: Map({
                vehicleNumberError:false,
                clientNumberError:false

            }),
            showAllVendorActionNeededPIP:false,
            carWashServiceTypeSelection:true,
            poDetails:Map({
                serviceDate:undefined,
                invoiceNo:undefined,
                odometer:0,
                carWashCost:0,
                detailCarWashCost:0,
                taxes: Map({
                    HST:0,
                    QST:0,
                    GST:0,
                    PST:0,
                }),
                taxAmount:0,
            }),
        }),
        dealerDraftDealerRequests:Map({
            showAllActiveRequests:false,
            showAllProcessedRequests:false,
        }),
        dealerDraftRequestsApproval:Map({}),
        vehicleSearchCreatePO: Map({
            poCreationError:null,
            createFilters: Map({
                createFor: null,

            })
        }),
        resetPassword: Map({
        }),
        ariUser: Map({
        })
    }),
    serverData: Map({

        shared: Map({
            /* Authenticated vendor details that can be used through out the application */
            vendorName: undefined, //"Jost Truck Service Center",
            vendorId: undefined, //"029296UA",
            token: undefined, //"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBUklfQ0MiLCJzdWIiOiJWTVNfVkVORE9SIiwidmlkIjoiMDI5Mjk2VUEiLCJleHAiOiIxNDU4OTcwNjk1In0.NQ_znU8kFz5L2WFOftDbYr_eDqxwHLf79Forf0HPMpk",
            personaToken: undefined,//"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJBUklfQ0MiLCJhdWQiOiJBUklFWFRFUk5BTFNJR05PTiIsImlkIjoiQVBDMDAwMDAwMiIsImFwcEtleSI6IjMzNTVCMzlELTUxNzItNDc2MS05NDI3LTIxNUJENUNCRUY5OSIsImRlbGVnYXRlVXNlciI6dHJ1ZSwiZXhwIjoiMTU1NzI5NjYyNCJ9.9dZnpoPS4_7sUxM2iXq_ywwKs-o3H8afkKf6r07Riak",
            personaUserId:undefined,//"APC0000002",
            adminUser:false,
            businessAddress:undefined,
            vendorDetails: Map({}),
            laborRateWindowShownForThisSession:false,
            /* this is a simple vehicle details object displayed on multiple screens */
            vehicleDetails: Map({
                /* vin8: "",
                 client: "",
                 year: "",
                 make: "",
                 model: "",
                 ariUnitNo: "",
                 plate: "",
                 engineType: "",
                 color: "",
                 mileage: "",
                 engineHours: ""*/
            }),
            recentServices: Map({}),
            frequentlyUsedATACodes: Map(),

            /*Complaint Type master data*/
            complaintTypes: Map({
                MA99: Map({ id:"complaint_code_MA99", index: 0, code: "MA99", description: "Maintenance", visible: true ,defaultMessage:"Maintenance"}),
                RI99: Map({ id:"complaint_code_RI99",index: 1, code: "RI99", description: "Safety / Gov't insp", visible: true,defaultMessage:"Safety / Gov't insp" }),
                TI99: Map({id:"complaint_code_TI99", index: 2, code: "TI99", description: "Tires / Wheels", visible: true ,defaultMessage:"Tires / Wheels"}),
                BR99: Map({ id:"complaint_code_BR99",index: 3, code: "BR99", description: "Brake system", visible: true ,defaultMessage:"Brake system"}),
                LI99: Map({ id:"complaint_code_LI99",index: 4, code: "LI99", description: "Lighting / Wiring", visible: true,defaultMessage:"Lighting / Wiring" }),
                CH99: Map({ id:"complaint_code_CH99",index: 5, code: "CH99", description: "Charging / Starting", visible: true,defaultMessage:"Charging / Starting" }),
                EN99: Map({ id:"complaint_code_EN99",index: 6, code: "EN99", description: "Engine system", visible: true,defaultMessage:"Engine system" }),
                DR99: Map({ id:"complaint_code_DR99",index: 7, code: "DR99", description: "Driveability / Sensors", visible: true,defaultMessage:"Driveability / Sensors" }),
                CO99: Map({ id:"complaint_code_CO99",index: 8, code: "CO99", description: "Cooling system", visible: true,defaultMessage:"Cooling system" }),
                FU99: Map({id:"complaint_code_FU99", index: 9, code: "FU99", description: "Fuel system", visible: true,defaultMessage:"Fuel system" }),
                ST99: Map({id:"complaint_code_ST99", index: 10, code: "ST99", description: "Steering system", visible: true,defaultMessage:"Steering system" }),
                SU99: Map({ id:"complaint_code_SU99",index: 11, code: "SU99", description: "Suspension", visible: true ,defaultMessage:"Suspension"}),
                EX99: Map({ id:"complaint_code_EX99",index: 12, code: "EX99", description: "Exhaust system", visible: true ,defaultMessage:"Exhaust system"}),
                AC99: Map({ id:"complaint_code_AC99",index: 13, code: "AC99", description: "Heating / AC system", visible: true ,defaultMessage:"Heating / AC system"}),
                UN99: Map({id:"complaint_code_UN99", index: 14, code: "UN99", description: "Other system concern", visible: false,defaultMessage:"Other system concern" }), 
                DG99: Map({id:"complaint_code_DG99", index: 15, code: "DG99", description: "Diagnosis", visible: false,defaultMessage:"Diagnosis" }),
                CL99: Map({id:"complaint_code_CL99", index: 16, code: 'CL99', description: 'Clutch concern', visible: false ,defaultMessage:"Clutch concern"}),
                FR99: Map({ id:"complaint_code_FR99",index: 17, code: 'FR99', description: 'Frame concern', visible: false,defaultMessage:"Frame concern" }),
                GA99: Map({id:"complaint_code_GA99", index: 18, code: 'GA99', description: 'Instrumentation concern', visible: false ,defaultMessage:"Instrumentation concern"}),
                GL99: Map({ id:"complaint_code_GL99",index: 19, code: 'GL99', description: 'Glass concern', visible: false ,defaultMessage:"Glass concern"}),
                HE99: Map({ id:"complaint_code_HE99",index: 20, code: 'HE99', description: 'Heater concern', visible: false,defaultMessage:"Heater concern" }),
                IN99: Map({id:"complaint_code_IN99", index: 21, code: 'IN99', description: 'Insurance concern', visible: false,defaultMessage:"Insurance concern" }),
                RA99: Map({ id:"complaint_code_RA99",index: 22, code: 'RA99', description: 'Radio concern', visible: false,defaultMessage:"Radio concern" }),
                TR99: Map({id:"complaint_code_TR99", index: 23, code: 'TR99', description: 'Transmission concern', visible: false,defaultMessage:"Transmission concern" }),
                UP99: Map({ id:"complaint_code_UP99",index: 24, code: 'UP99', description: 'Upfitted equip concern', visible: false,defaultMessage:"Upfitted equip concern" }),
                WI99: Map({id:"complaint_code_WI99", index: 25, code: 'WI99', description: 'Wiring concern', visible: false,defaultMessage:"Wiring concern" }),
                XX99: Map({ id:"complaint_code_XX99",index: 26, code: 'XX99', description: 'Other', visible: true,defaultMessage:"Other" }),
                SA01: Map({id:"complaint_code_SA01", index: 27, code: 'SA01', description: 'Sales tax', visible: false,defaultMessage:"Sales tax" }),
                RE01: Map({id:"complaint_code_RE01", index: 28, code: 'RE01', description: 'Rental', visible: false,defaultMessage:"Rental" }),
                MA01: Map({id:"complaint_code_MA01", index: 29, code: 'MA01', description: 'MA01', visible: false,defaultMessage:"MA01" }),
                1224: Map({id:"complaint_code_1224", index: 30, code: '1224', description: 'R/S due to driver error', visible: false,defaultMessage:"R/S due to driver error" }),
                1024: Map({id:"complaint_code_1024", index: 31, code: '1024', description: 'R/S for a mechnical break down', visible: false,defaultMessage:"R/S for a mechnical break down" }),
                1324: Map({ id:"complaint_code_1324",index: 32, code: '1324', description: 'R/S due to driver abuse', visible: false ,defaultMessage:"R/S due to driver abuse"}),
                3124: Map({id:"complaint_code_3124", index: 33, code: '3124', description: 'Accident non reported', visible: false,defaultMessage:"Accident non reported" }),
                3214: Map({ id:"complaint_code_3214",index: 34, code: '3214', description: 'Accident  reported', visible: false,defaultMessage:"Accident  reported" }),
                3824: Map({ id:"complaint_code_3824",index: 35, code: '3824', description: 'Warranty repair', visible: false ,defaultMessage:"Warranty repair"}),
                4224: Map({ id:"complaint_code_4224",index: 36, code: '4224', description: 'R/S due to a warranty repair', visible: false,defaultMessage:"R/S due to a warranty repair" }),
                3811: Map({id:"complaint_code_3811", index: 37, code: '3811', description: 'Fdx obsolete CD code', visible: false,defaultMessage:"Fdx obsolete CD code" }),
                3814: Map({id:"complaint_code_3814", index: 38, code: '3814', description: 'Campaign directive', visible: false ,defaultMessage:"Campaign directive"}),
                4114: Map({id:"complaint_code_4114", index: 39, code: '4114', description: 'Body maintenance', visible: false ,defaultMessage:"Body maintenance"}),
                RC01: Map({id:"complaint_code_RC01", index: 40, code: 'RC01', description: 'Roadside concern', visible: false ,defaultMessage:"Roadside concern"}),
                FL99: Map({ id:"complaint_code_FL99",index: 41, code: 'FL99', description: 'Forklift', visible: false ,defaultMessage:"Forklift"}),
                HR99: Map({id:"complaint_code_HR99", index: 42, code: 'HR99', description: 'Hyrail', visible: false ,defaultMessage:"Hyrail"}),
                AL99: Map({ id:"complaint_code_AL99",index: 43, code: 'AL99', description: 'Aerial /  Lift', visible: false,defaultMessage:"Aerial /  Lift" }),
                RE99: Map({id:"complaint_code_RE99", index: 44, code: 'RE99', description: 'Refrigeration', visible: false ,defaultMessage:"Refrigeration"}),
                RC99: Map({ id:"complaint_code_RC99",index: 45, code: 'RC99', description: 'Road call', visible: false,defaultMessage:"Road call" }),
                TL99: Map({id:"complaint_code_TL99", index: 47, code: 'TL99', description: 'Trailer', visible: false,defaultMessage:"Trailer" }),
                DL99: Map({id:"complaint_code_DL99", index: 48, code: 'DL99', description: 'Driveline concern', visible: false ,defaultMessage:"Driveline concern"}),
                CA99: Map({ id:"complaint_code_CA99",index: 49, code: 'CA99', description: 'Cab concern', visible: false ,defaultMessage:"Cab concern"}),
                UN01: Map({id:"complaint_code_UN01", index: 50, code: 'UN01', description: 'Not supplied', visible: false,defaultMessage:"Not supplied" }),
            }),

            restrictedATACodes: Map({})
        }),

        /* Based on logged-in vendor details, it auto populates all PO details
         that can be used in user dash-board */
        shopView: Map({
            activePOs: Map({
                /*poNumber: 52017889,
                 track: 'ABC52017889',
                 vehicleYear: 2015,
                 vehicleMake: "Ford",
                 vehicleModel: "F450",
                 vehicleVIN8: "7G133080",
                 poDate: Date(),
                 approvalDate: Date(),
                 totalAmount: 289.33,
                 status: constants.PO_STATUS.VENDOR_ACTION_NEEDED,
                 approvedAmount: 289.33,
                 pendingApprovalAmount: 0*/
            })
        }),

        /* Based on search by VIN, it auto populates all PM services data into this object
         and can be used to render in add/edit complaints */
        complaintView: Map({
            // /* hard coding ATA codes temporarily */
            // ataCodes:Map({
            //     '1E001006': Map({
            //         productCode: '1E001006',
            //         description: 'LUBE, OIL FILTER'
            //     })
            // }),    

            /*Vehicle maintenance service or PM data*/
            maintenanceServices: Map({
                /*"1878_1E001006": Map({
                 "id": "1878_1E001006",
                 "serviceName": "CHANGE OIL AND FILTER",
                 "dueOdometer": 45125,
                 "dueHourMeter": 0,
                 "ataCodes": [{
                 "code": "1E001006",
                 "description": "LUBE,OIL,FILTER"
                 }],
                 "dueBy": "Meter"
                 })*/
            }),
            /*Vehicle regulatory service data*/
            regulatoryServices: Map({
                /*"USDOT2": Map({
                 "Id": "USDOT2",
                 "ServiceName": "DOT AND/OR  STATE INSP",
                 "DueOdometer": 0,
                 "DueHourMeter": 0,
                 "AtaCodes": [
                 {
                 "Code": "1G001001",
                 "Description": "DOT INSPECTION"
                 },
                 {
                 "Code": "1G001009",
                 "Description": "STATE INSPECTION"
                 }
                 ],
                 "DueDate": "2015-07-01T00:00:00",
                 "DueBy": "Days",
                 "RegulatedBy": "Federal"
                 })*/
            }),
        }),

        addServiceLineItemsView: Map({
            /*Parameters for the vehicle*/
            vehicleParameters: [
                "OEM PARTS SHOULD BE USED WHEN POSSIBLE FOR BRAKES, SUSPENSION AND STEERING",
                "CLEAN FUEL INJECTORS",
                "DRAIN AND REFILL RADIATOR"]
        }),
        /*add new line item modal*/
        addNewLineItemsView: Map({
            complaints: Map({
                MA99: Map({
                    code: "MA99",
                    description: "Maintenance",
                    complaintType: "Maintenance"
                })
            }),
            /*Parameters for the vehicle*/
            vehicleParameters: [
                "OEM PARTS SHOULD BE USED WHEN POSSIBLE FOR BRAKES, SUSPENSION AND STEERING",
                "CLEAN FUEL INJECTORS",
                "DRAIN AND REFILL RADIATOR"],
            correctionTypes: [
                Map({id:"correctionTypes_REPLACE",description:"REPLACE",defaultMessage:"REPLACE"}),
                Map({id: "correctionTypes_REPAIR",description:"REPAIR",defaultMessage:"REPAIR"}),
                Map({id:"correctionTypes_ADJUST",description:"ADJUST",defaultMessage:"ADJUST"}),
                Map({id:"correctionTypes_PM",description:"PM",defaultMessage:"PM"})
            ],
        }),
        /* Purchase order view nodes*/
        view: Map({
            isDirty: false,
            /*
             estimatedCompletionDate: new Date(),
             complaints: Map({
             AC99: Map({
             complaintCode: "AC99",
             description: "AC CONCERN description",
             complaintType: "AC CONCERN"
             })
             }),
             lineItems: Map({
             lineDescription: "AC Concern description 1",
             ataCode: "ata_56789",
             quantity: 2,
             cost: 79.56,
             amount: 159.12,
             status: constants.LINE_ITEM_STATUS.PENDING,
             complaintCode: "AC99"
             })*/
            /* ATA codes with attributes for global access. This data shall load on initialization*/

            ataCodes: Map({
                53001010: Map({
                    ataCode: "53001010",
                    description: "INITIAL VEHICLE DIAGNOSIS",
                    complaintCode: "DG99",
                    correctionTypesRestricted: ["PM"]
                })

            }),
        }),

        actionRequired: Map({}),

        adjustment: Map(),
        
        /* Close Purchase order view nodes*/
        close: Map({
            /*complaints: Map({
             AC99: Map({
             complaintCode: "AC99",
             description: "AC CONCERN description",
             complaintType: "AC CONCERN"
             })
             }),
             lineItems: Map({
             lineDescription: "AC Concern description 1",
             ataCode: "ata_56789",
             quantity: 2,
             cost: 79.56,
             amount: 159.12,
             status: constants.LINE_ITEM_STATUS.PENDING,
             complaintCode: "AC99"
             }),
             vehicleDetails: Map({
             vin8: "F32KC34J27G133080",
             client: "Walmatta CGI",
             year: '2011',
             make: 'Ford',
             model: 'F450',
             ariUnitNo: "73697",
             plate: "MD 43T455",
             engineType: "Unavailable",
             color: "White",
             mileage: "182343",
             engineHours: "5233"
             })*/
        }),

        searchPO: Map({
            pos: Map(),
            vehicleDetails: Map()
        }),
        repairHistory: Map({
            services: Map(),
            nextPageExist: true,
            complaintDescriptionsToIgnore: ['INSURANCE', 'RENTAL', 'ROADSIDE CONCERN', 'ROAD CALL', 'SALES TAX']
        }),
        helpFAQSections: Map({
            locale:'en-US',
            sections: Map({
                "1": Map({
                    title: Map({
                        id: "faq_enrollment_title",
                        description: "Title for Enrollment Section",
                        defaultMessage: "Enrollment/Registration"
                    }),
                    isVisible: false
                }),
                "2": Map({
                    title: Map({
                        id: "faq_purchaseOrder_title",
                        description: "Title for Purchase Order",
                        defaultMessage: "Purchase Order/Payment/Invoicing"
                    }),
                    isVisible: false
                }),
                "3": Map({
                    title: Map({
                        id: "faq_bank_title",
                        description: "Title for Bank Section",
                        defaultMessage: "Bank Accounts"
                    }),
                    isVisible: false
                }),
                "4": Map({
                    title: Map({
                        id: "faq_profile_title",
                        description: "Title for Profile Section",
                        defaultMessage: "Profile Information"
                    }),
                    isVisible: false
                }),/*
                "5": Map({
                    title: Map({
                        id:"faq_troubleshoot_title",
                        description: "Title for Troubleshooting",
                        defaultMessage: "Troubleshooting"
                    }),
                    isVisible: false
                }),*/
                "6": Map({
                    title: Map({
                        id: "faq_general_operations_title",
                        description: "Title for General System Operations",
                        defaultMessage: "General System Operation"
                    }),
                    isVisible: false
                }),
                "7": Map({
                    title: Map({
                        id: "faq_general_information_title",
                        description: "Title for General Information",
                        defaultMessage: "General Information"
                    }),
                    isVisible: false
                }),
                "8": Map({
                    title: Map({
                        id: "help_videos",
                        description: "Tutorial Videos",
                        defaultMessage: "Tutorial Videos"
                    }),
                    isVisible: false,
                    isVideoSection: true,
                    restrictedCountry: ['MEX']
                })

            })
        }),

        helpFAQQuestions: Map({
            questions: Map({
                "1": Map({
                    questions1: Map({
                        question: Map({
                            id: "faq_enroll_question1",
                            description: "question 1 for enroll question",
                            defaultMessage: "How can I become a Holman Service Provider?"
                        }),
                        answer: Map({
                            id: "faq_enroll_answer1",
                            description: "answer 1 for enroll answer",
                            defaultMessage: "Simply click the word Enroll on the home page. This is a 2 part process. You’ll be notified via e-mail when you’re accepted. You can then maintain your account online."
                        }),
                        category: "Enroll",
                        id: 1,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"ANY",
                        restrictedCountry: ['MEX']
                    }),
                    questions2: Map({
                        question: Map({
                            id: "faq_enroll_question2",
                            description: "question 2 for enroll question",
                            defaultMessage: "When I try to register it is asking for my Federal Tax ID#."
                        }),
                        answer: Map({
                            id: "faq_enroll_answer2",
                            description: "answer 2 for enroll answer",
                            defaultMessage: "Your Federal Tax ID# is ‘9’ digits; enter without the dash. e.g.: Correct/ 123456789 Incorrect/ 12-3456789"
                        }),
                        category: "Enroll",
                        id: 2,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"ANY",
                        restrictedCountry: ['MEX']
                    }),
                    questions54: Map({
                        question: Map({
                            id: "faq_enroll_question54",
                            description: "question 1 for enroll question",
                            defaultMessage: "How can I become a Holman Service Provider?"
                        }),
                        answer: Map({
                            id: "faq_enroll_answer54",
                            description: "answer 1 for enroll answer",
                            defaultMessage: "Please contact Holman at the toll free number shown"
                        }),
                        category: "Enroll",
                        id: 54,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"ANY",
                        restrictedCountry: ['USA','CAN']
                    }),

                }),
                "2": Map({
                    questions3: Map({
                        question: Map({
                            id: "faq_purchase_question3",
                            description: "question 3 for purchase order question",
                            defaultMessage: "What does approved PO's mean?"
                        }),
                        answer: Map({
                            id: "faq_purchase_answer3",
                            description: "answer 3 for purchase order answer",
                            defaultMessage: "Approved PO’s are your pending invoices that are still pending payment."
                        }),
                        category: "Purchase",
                        id: 3,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR"
                    }),
                    questions4: Map({
                        question: Map({
                            id: "faq_purchase_question4",
                            description: "question 4 for purchase order question",
                            defaultMessage: "What does processed PO's mean?"
                        }),
                        answer: Map({
                            id: "faq_purchase_answer4",
                            description: "answer 4 for purchase order answer",
                            defaultMessage: "Processed PO’s are your PO’s that have been closed for payment.You can check status on these payments by clicking on the processed date for the selected invoice."
                        }),
                        category: "Purchase",
                        id: 4,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR"
                    }),
                    /* questions5: Map({
                         question: Map({
                             id: "faq_purchase_question5",
                             description:"question 5 for purchase order question",
                             defaultMessage:"How do I find past activity on the Purchase order Pages?"
                         }),
                         answer: Map({
                             id:"faq_purchase_answer5",
                             description:"answer 5 for purchase order answer",
                             defaultMessage:"Purchase Order pages begin by displaying the most current activity for your account.Use the Prior Month arrow to view purchase orders from earlier dates. Use the Next Month arrow to move to a later date.You may also select a date directly from the dropdown list.Only dates containing account activity are available for display."
                         }),
                         category: "Purchase",
                         id: 5,
                         isPublicQuestion: false,
                         isVisible: false
                     }),
                     questions6: Map({
                         question: Map({
                             id: "faq_purchase_question6",
                             description:"question 6 for purchase order question",
                             defaultMessage:"Is it possible to find an invoice without paging through months of activity?"
                         }),
                         answer: Map({
                             id:"faq_purchase_answer6",
                             description:"answer 6 for purchase order answer",
                             defaultMessage:"If you know the Invoice, Purchase Order or Payment Number, You can use the Invoice Search page and the optional Find by Number feature. Enter the Item number and press the appropriate Find button. You may also select items by scanning the Search by Grouping section of the page."
                         }),
                         category: "Purchase",
                         id: 6,
                         isPublicQuestion: false,
                         isVisible: false
                     }),
                     questions7: Map({
                         question: Map({
                             id: "faq_purchase_question7",
                             description:"question 7 for purchase order question",
                             defaultMessage:"Can I arrange the items on the Purchase Order page?"
                         }),
                         answer: Map({
                             id:"faq_purchase_answer7",
                             description:"answer 7 for purchase order answer",
                             defaultMessage:"Yes, by selecting any heading from the purchase order table, the items will be sorted on that column. Selecting the same heading a second time will reverse the order of the items."
                         }),
                         category: "Purchase",
                         id: 7,
                         isPublicQuestion: false,
                         isVisible: false
                     }),
                     questions8: Map({
                         question: Map({
                             id: "faq_purchase_question8",
                             description:"question 8 for purchase order question",
                             defaultMessage:"Can I see additional information for a purchase order?"
                         }),
                         answer: Map({
                             id:"faq_purchase_answer8",
                             description:"answer 8 for purchase order answer",
                             defaultMessage:"Yes, you can select the PO number from any purchase order. This will display the PO detail line items. On the Processed page you can also select the Processed date.This will display the payment detail for that item."
                         }),
                         category: "Purchase",
                         id: 8,
                         isPublicQuestion: true,
                         isVisible: false
                     }),*/
                    questions9: Map({
                        question: Map({
                            id: "faq_purchase_question9",
                            description: "question 9 for purchase order question",
                            defaultMessage: "Why is my payment amount different from the amount that I submitted?"
                        }),
                        answer: Map({
                            id: "faq_purchase_answer9",
                            description: "answer 9 for purchase order answer",
                            defaultMessage: "There is a Holman Pay amount applied to each invoice total; regardless of what services are rendered. E.G.: LOF, inspections, services, tax etc."
                        }),
                        category: "Purchase",
                        id: 9,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions100: Map({
                        question: Map({
                            id: "faq_purchase_question100",
                            description: "question 100 for purchase order question",
                            defaultMessage: "Why is my payment amount different from the amount that I submitted?"
                        }),
                        answer: Map({
                            id: "faq_purchase_answer100",
                            description: "answer 100 for purchase order answer",
                            defaultMessage: "There is a Holman Pay amount applied to each invoice total; regardless of what services are rendered. E.G.: LOF, inspections, services, tax etc."
                        }),
                        category: "Purchase",
                        id:100,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"CARWASH"
                    })
                    /*,

                    questions10: Map({
                        question: Map({
                            id: "faq_purchase_question10",
                            description:"question 10 for purchase order question",
                            defaultMessage:"How do I expedite my call into the Holman call center?"
                        }),
                        answer: Map({
                            id:"faq_purchase_answer10",
                            description:"answer 10 for purchase order answer",
                            defaultMessage: "1. Client ID & Vehicle Number or Last 8 Digits of the VIN. <br/>" +
                            "2. Current Mileage on the Vehicle. <br/> "+
                            "3. Able to explain the Repairs or Services needed/suggested.  <br/>" +
                            "4. Pricing broken down on Parts/Labor and Sales Tax. <br/>"
                        }),
                        category: "Purchase",
                        id: 10,
                        isPublicQuestion: true,
                        isVisible: false
                    })*/
                }),
                "3": Map({
                    /*  questions11: Map({
                        question: Map({
                            id: "faq_bank_question11",
                            description:"question 11 for bank question",
                            defaultMessage:"What if I do not receive my payment notification email?"
                        }),
                        answer: Map({
                            id:"faq_bank_answer11",
                            description:"answer 11 for bank answer",
                            defaultMessage:"Log on to the website and go to your processed PO’s, then locate the invoice you are questioning and click on the You will then be provided with the info on that payment.processed date. Please be advised that these notifications can not be resent."
                        }),
                        category: "Bank",
                        id: 11,
                        isPublicQuestion: true,
                        isVisible: false
                    }),*/
                    questions12: Map({
                        question: Map({
                            id: "faq_enroll_question12",
                            description: "question 12 for bank question",
                            defaultMessage: "How will I receive my payment notification?"
                        }),
                        answer: Map({
                            id: "faq_enroll_answer12",
                            description: "answer 12 for bank answer",
                            defaultMessage: "Your payment notification e-mail originates from Fleet-Alert@holman.com. This is not spam & cannot be recreated. Please add this to your address book since these alerts are sent in bulk and can easily be recognized as spam."
                        }),
                        category: "Bank",
                        id: 12,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"ANY",
                        restrictedCountry: ['MEX']
                    }),
                    questions13: Map({
                        question: Map({
                            id: "faq_bank_question13",
                            description: "question 13 for bank question",
                            defaultMessage: "Why don’t I always receive an email each time funds post to my checking account?"
                        }),
                        answer: Map({
                            id: "faq_bank_answer13",
                            description: "answer 13 for bank answer",
                            defaultMessage: "If you’re not allowing our address Fleet-Alert@holman.com to come to your e-mail it may be recognized as spam. You can always verify deposits for a particular vehicle PO using last 8 digits of the VIN in footer search section (“Search for my purchase orders by last 8 characters of VIN”)."
                        }),
                        category: "Bank",
                        id: 13,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"ANY",
                        restrictedCountry: ['MEX']
                    }),
                    questions14: Map({
                        question: Map({
                            id: "faq_bank_question14",
                            description: "question 14 for bank question",
                            defaultMessage: "Why can't I deposit to a savings account?"
                        }),
                        answer: Map({
                            id: "faq_bank_answer14",
                            description: "answer 14 for bank answer",
                            defaultMessage: "At this time we are only able to accept a Checking Account for ACH transfers. Since the vast majority of companies use a Checking Account this was the best choice for us to offer our service providers."
                        }),
                        category: "Bank",
                        id: 14,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    })/*,
                    questions15: Map({
                        question: Map({
                            id: "faq_bank_question15",
                            description:"question 15 for bank question",
                            defaultMessage:"My checking account information has changed but I closed out PO's."
                        }),
                        answer: Map({
                            id:"faq_bank_answer15",
                            description:"answer 15 for bank answer",
                            defaultMessage:"If invalid checking account information is on file, it will result in delayed payment by at least a week. Please go to Member Services and then to Payment account maintenance to update your checking account information, unless Holman has already contacted you about the error."
                        }),
                        category: "Bank",
                        id: 15,
                        isPublicQuestion: false,
                        isVisible: false
                    }),
                    questions16: Map({
                        question: Map({
                            id: "faq_bank_question16",
                            description:"question 16 for bank question",
                            defaultMessage:"Can I change my checking account information online?"
                        }),
                        answer: Map({
                            id:"faq_bank_answer16",
                            description:"answer 16 for bank answer",
                            defaultMessage:"Yes, Go to Member Services: Payment Account Maintenance: Register your new checking account to be used for future payment of services. Enter both your bank routing & account #."
                        }),
                        category: "Bank",
                        id: 16,
                        isPublicQuestion: true,
                        isVisible: false
                    }),
                    questions17: Map({
                        question: Map({
                            id: "faq_bank_question17",
                            description:"question 17 for bank question",
                            defaultMessage:"I already enrolled and registered but skipped the step to add my checking account information."
                        }),
                        answer: Map({
                            id:"faq_enroll_answer17",
                            description:"answer 17 for bank answer",
                            defaultMessage:"Sign in to your account and go to Member Services; Payment Account Maintenance to add your checking account information, this completes the setup of your account."
                        }),
                        category: "Bank",
                        id: 17,
                        isPublicQuestion: false,
                        isVisible: false
                    })*/
                }),
                "4": Map({
                    questions18: Map({
                        question: Map({
                            id: "faq_profile_question18",
                            description: "question 18 for profile question",
                            defaultMessage: "How do I update my profile information?"
                        }),
                        answer: Map({
                            id: "faq_profile_answer18",
                            description: "answer 18 for profile answer",
                            defaultMessage: "Under 'YOUR ACCOUNT', you can change your password, email address and checking account information, update contact information, addresses etc."
                        }),
                        category: "Profile",
                        id: 18,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"ANY"
                    })/*,
                    questions19: Map({
                        question: Map({
                            id: "faq_profile_question19",
                            description:"question 19 for profile question",
                            defaultMessage:"What if I forget my password?"
                        }),
                        answer: Map({
                            id:"faq_profile_answer19",
                            description:"answer 19 for profile answer",
                            defaultMessage:"Go to sign in and click on Email Me. You will be prompted to enter in you Federal Tax ID # (EIN #) and Supplier Id #. Once all information is entered, click email me and your password will be emailed to the address we have on file."
                        }),
                        category: "Profile",
                        id: 19,
                        isPublicQuestion: true,
                        isVisible: false
                    }),
                    questions20: Map({
                        question: Map({
                            id: "faq_profile_question20",
                            description:"question 20 for profile question",
                            defaultMessage:"How do I update my Client Authorization email address?"
                        }),
                        answer: Map({
                            id:"faq_profile_answer20",
                            description:"answer 20 for profile answer",
                            defaultMessage:"Log into your account with your supplier ID and password, go to Member Services on the left hand side of the page and click on Client Authorization, you will need to enter your password again, then enter the updated info and hit submit."
                        }),
                        category: "Profile",
                        id: 20,
                        isPublicQuestion: true,
                        isVisible: false
                    })*/
                }),
                "5": Map({
                    /*  questions21: Map({
                          question: Map({
                              id: "faq_troubleshoot_question21",
                              description:"question 21 for trouble shooting question",
                              defaultMessage:"I am using a newer browser but I cannot register."
                          }),
                          answer: Map({
                              id:"faq_troubleshoot_answer21",
                              description:"answer 21 for trouble shooting answer",
                              defaultMessage:"There are two possibilities. Check to see if your browser has Scripting enabled. This is a required browser option that allows your computer to process some Web page features. The end result is an enriched and speedier interaction with the Web. Also, verify that your browser is accepting browser cookies. This browser option is required to provide you with a personalized dialogue."
                          }),
                          category: "TroubleShoot",
                          id: 21,
                          isPublicQuestion: true,
                          isVisible: false
                      }),
                      questions22: Map({
                          question: Map({
                              id: "faq_troubleshoot_question22",
                              description:"question 22 for trouble shooting question",
                              defaultMessage:"Are you a Corporate office with linked locations trying to sign into your account?"
                          }),
                          answer: Map({
                              id:"faq_troubleshoot_answer22",
                              description:"answer 22 for trouble shooting answer",
                              defaultMessage:"We’ve made enhancements so your ‘linked’ locations can now maintain their own service profiles As a result we added a 2nd level of password protection Please call Supplier Relations #888-683-8327 #1 for assistance. We regret any inconvenience."
                          }),
                          category: "TroubleShoot",
                          id: 22,
                          isPublicQuestion: true,
                          isVisible: false
                      }),
                      questions23: Map({
                          question: Map({
                              id: "faq_troubleshoot_question23",
                              description:"question 23 for trouble shooting question",
                              defaultMessage:"I need to access an existing account."
                          }),
                          answer: Map({
                              id:"faq_troubleshoot_answer23",
                              description:"answer 23 for trouble shooting answer",
                              defaultMessage: "For your security, please call Supplier Relations @ 1-866-274-1025 #1 to verify specific account information. A temporary password will be provided, if needed."
                          }),
                          category: "TroubleShoot",
                          id: 23,
                          isPublicQuestion: true,
                          isVisible: false
                      })*/
                }),
                "6": Map({
                    questions24: Map({
                        question: Map({
                            id: "faq_general_operations_question24",
                            description: "question 24 for General System Operation question",
                            defaultMessage: "Is there an additional charge or fee for using Holman PartnerConnect?"
                        }),
                        answer: Map({
                            id: "faq_general_operations_answer24",
                            description: "answer 24 for General System Operation answer",
                            defaultMessage: "No, Holman PartnerConnect can help reduce the need for phone calls, increasing efficiency and flexibility for your shop. There are no additional fees for using this value added service."
                        }),
                        category: "GeneralSystemOperation",
                        id: 24,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"ANY"
                    }),
                    questions25: Map({
                        question: Map({
                            id: "faq_general_operations_question25",
                            description: "question 25 for General System Operation question",
                            defaultMessage: "Does each user in my shop need to have an individual password?"
                        }),
                        answer: Map({
                            id: "faq_general_operations_answer25",
                            description: "answer 25 for General System Operation answer",
                            defaultMessage: "No, Holman PartnerConnect uses a single sign-on concept, consisting of your current user ID and password."
                        }),
                        category: "GeneralSystemOperation",
                        id: 25,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"ANY"
                    }),
                    questions26: Map({
                        question: Map({
                            id: "faq_general_operations_question26",
                            description: "question 26 for General System Operation question",
                            defaultMessage: "Can I start building a purchase order and submit at a later time?"
                        }),
                        answer: Map({
                            id: "faq_general_operations_answer26",
                            description: "answer 26 for General System Operation answer",
                            defaultMessage: "Yes, PO's which have been started and not submitted for approval will be shown in the" + ' "Your Action is Required" ' + "section."
                        }),
                        category: "GeneralSystemOperation",
                        id: 26,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"ANY",
                        restrictedCountry: ['MEX']
                    }),
                    questions27: Map({
                        question: Map({
                            id: "faq_general_operations_question27",
                            description: "question 27 for General System Operation question",
                            defaultMessage: "Is it possible to make a change once a PO is submitted?"
                        }),
                        answer: Map({
                            id: "faq_general_operations_answer27",
                            description: "answer 27 for General System Operation answer",
                            defaultMessage: "Yes, users are allowed to make edits to approved or pending PO’s, and resubmit them for approval."
                        }),
                        category: "GeneralSystemOperation",
                        id: 27,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"ANY",
                        restrictedCountry: ['MEX']
                    }),
                    questions28: Map({
                        question: Map({
                            id: "faq_general_operations_question28",
                            description: "question 28 for General System Operation question",
                            defaultMessage: "Can I still process payment for open authorized purchase orders on the new" + '<div style="line-height:110%;"> <br> </div>' + "website?"
                        }),
                        answer: Map({
                            id: "faq_general_operations_answer28",
                            description: "answer 28 for General System Operation answer",
                            defaultMessage: "Yes, Holman PartnerConnect allows you to process payments online, as well as view payment status."
                        }),
                        category: "GeneralSystemOperation",
                        id: 28,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"ANY",
                        restrictedCountry: ['MEX']
                    }),
                    questions29: Map({
                        question: Map({
                            id: "faq_general_operations_question29",
                            description: "question 29 for General System Operation question",
                            defaultMessage: "Will I be able to update my shop’s information (Banking, email, service profile) on" + '<div style="line-height:110%;"> <br> </div>' + " Holman PartnerConnect?"
                        }),
                        answer: Map({
                            id: "faq_general_operations_answer29",
                            description: "answer 29 for General System Operation answer",
                            defaultMessage: "Yes, Holman PartnerConnect will allow users to update account critical information such as bank account, phone and e-mail contacts, service performed etc. This can be found by clicking on “YOUR ACCOUNT” at the top of the main landing page."
                        }),
                        category: "GeneralSystemOperation",
                        id: 29,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"REPAIR"
                        
                    }),
                    questions101: Map({
                        question: Map({
                            id: "faq_general_operations_question101",
                            description: "question 101 for General System Operation question",
                            defaultMessage: "Will I be able to update my shop’s information (Banking, email) on" + '<div style="line-height:110%;"> <br> </div>' + " Holman PartnerConnect?"
                        }),
                        answer: Map({
                            id: "faq_general_operations_answer101",
                            description: "answer 101 for General System Operation answer",
                            defaultMessage: "Yes, Holman PartnerConnect will allow users to update account critical information such as bank account, phone and e-mail contacts. This can be found by clicking on “YOUR ACCOUNT” at the top of the main landing page."
                        }),
                        category: "GeneralSystemOperation",
                        id: 101,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"CARWASH"

                    }),
                    questions30: Map({
                        question: Map({
                            id: "faq_general_operations_question30",
                            description: "question 30 for General System Operation question",
                            defaultMessage: "How will Holman help me grow my business?"
                        }),
                        answer: Map({
                            id: "faq_general_operations_answer30",
                            description: "answer 30 for General System Operation answer",
                            defaultMessage: "Our Holman PartnerConnect site will display vehicles which are in your servicing area, and categorize them into assets which need service now, vehicles you've previously worked on, and those that you haven't, as an example."
                        }),
                        category: "GeneralSystemOperation",
                        id: 30,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions305: Map({
                        question: Map({
                            id: "faq_general_operations_question31",
                            description: "question 31 for General System Operation question",
                            defaultMessage: "Question 31"
                        }),
                        answer: Map({
                            id: "faq_general_operations_answer31",
                            description: "answer 31 for General System Operation answer",
                            defaultMessage: "Answer 31"
                        }),
                        category: "GeneralSystemOperation",
                        id: 305,
                        isPublicQuestion: true,
                        isVisible: false,
                        restrictedLocale: ['en-US','es-MX'],
                        vendorType:"ANY"
                    })
                }),
                "7": Map({
                    questions31: Map({
                        question: Map({
                            id: "faq_general_information_question31",
                            description: "question 31 for general_information question",
                            defaultMessage: "What is Holman PartnerConnect?"
                        }),
                        answer: Map({
                            id: "faq_general_information_answer31",
                            description: "answer 31 for general_information answer",
                            defaultMessage: "Holman PartnerConnect is a completely new two-way business portal, designed to save you time and money, while providing you with an easy way to handle the bulk of your Holman business interactions. This new site will be replacing our current supplier webpage."
                        }),
                        category: "General Information",
                        id: 31,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"ANY"
                    }),
                    questions32: Map({
                        question: Map({
                            id: "faq_general_information_question32",
                            description: "question 32 for general_information question",
                            defaultMessage: "How will Holman PartnerConnect benefit me?"
                        }),
                        answer: Map({
                            id: "faq_general_information_answer32",
                            description: "answer 32 for general_information answer",
                            defaultMessage: "Holman PartnerConnect will allow you to create purchase orders online, for most of the common, day to day operations that are typically performed in your shop. In most cases, you can simply skip the phone by creating a purchase order request over the internet.  In addition to creating PO's online, our new system will also provide you with purchase order status at a glance, while allowing for PO edits on the fly. Once work on the vehicle has been completed, you can quickly and easily review and close the purchase order. All of these features are designed to save you time and increase productivity, which translates to real, tangible savings as you go about the course of your busy day."
                        }),
                        category: "General Information",
                        id: 32,
                        isPublicQuestion: true,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions102: Map({
                        question: Map({
                            id: "faq_general_information_question102",
                            description: "question 102 for general_information question",
                            defaultMessage: "How will Holman PartnerConnect benefit me?"
                        }),
                        answer: Map({
                            id: "faq_general_information_answer102",
                            description: "answer 102 for general_information answer",
                            defaultMessage: "Holman PartnerConnect will allow you to create purchase orders online, for washes and detailing,the operations that are typically performed in your shop. In most cases, you can simply skip the phone by creating a purchase order request over the internet.  In addition to creating PO's online, our new system will also provide you with purchase order status at a glance, while allowing for PO edits on the fly. Once work on the vehicle has been completed, you can quickly and easily review and close the purchase order. All of these features are designed to save you time and increase productivity, which translates to real, tangible savings as you go about the course of your busy day."
                        }),
                        category: "General Information",
                        id: 102,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"CARWASH"
                    }),
                }),
                "8": Map({
                    questions32: Map({
                        question: Map({
                            id: "quick_start",
                            description: "Quick start",
                            defaultMessage: "Quick start"
                        }),
                        answer: Map({
                            id: "quick_start",
                            videoId: "quick_start",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 32,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions33: Map({
                        question: Map({
                            id: "quick_start_lube",
                            description: "Quick start guide for Lube Vendors",
                            defaultMessage: "Quick start guide for Lube Vendors"
                        }),
                        answer: Map({
                            id: "quick_start_lube",
                            videoId: "quick_start_lube",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 33,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions34: Map({
                        question: Map({
                            id: "username_and_password",
                            description: "User name and Password",
                            defaultMessage: "User name and Password"
                        }),
                        answer: Map({
                            id: "username_and_password",
                            videoId: "username_and_password",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 34,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions35: Map({
                        question: Map({
                            id: "explanation_of_po_types",
                            description: "Brief explanation of Purchase order types",
                            defaultMessage: "Brief explanation of Purchase order types"
                        }),
                        answer: Map({
                            id: "explanation_of_po_types",
                            videoId: "explanation_of_po_types",
                            isVideo: 'Y'                           
                        }),
                        category: "Help Videos",
                        id: 35,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions36: Map({
                        question: Map({
                            id: "your_action_is_required",
                            description: "Your action is required",
                            defaultMessage: "Your action is required"
                        }),
                        answer: Map({
                            id: "your_action_is_required",
                            videoId: "your_action_is_required",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 36,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions37: Map({
                        question: Map({
                            id: "approved_for_service",
                            description: "Approved for service",
                            defaultMessage: "Approved for service"
                        }),
                        answer: Map({
                            id: "approved_for_service",
                            videoId: "approved_for_service",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 37,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions38: Map({
                        question: Map({
                            id: "waiting_for_ari_client_approval",
                            description: "Waiting for Holman/Client approval",
                            defaultMessage: "Waiting for Holman/Client approval"
                        }),
                        answer: Map({
                            id: "waiting_for_ari_client_approval",
                            videoId: "waiting_for_ari_client_approval",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 38,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions39: Map({
                        question: Map({
                            id: "payment_in_progress",
                            description: "Payment in Progress",
                            defaultMessage: "Payment in Progress"
                        }),
                        answer: Map({
                            id: "payment_in_progress",
                            videoId: "payment_in_progress",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 39,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions40: Map({
                        question: Map({
                            id: "search_for_my_pos",
                            description: "Search my Purchase orders",
                            defaultMessage: "Search my Purchase orders"
                        }),
                        answer: Map({
                            id: "search_for_my_pos",
                            videoId: "search_for_my_pos",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 40,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions41: Map({
                        question: Map({
                            id: "searching_for_vehicle",
                            description: "Entering a vehicle serial number and what it returns",
                            defaultMessage: "Entering a vehicle serial number and what it returns"
                        }),
                        answer: Map({
                            id: "searching_for_vehicle",
                            videoId: "searching_for_vehicle",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 41,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions42: Map({
                        question: Map({
                            id: "close_po",
                            description: "Close a Purchase order",
                            defaultMessage: "Close a Purchase order"
                        }),
                        answer: Map({
                            id: "close_po",
                            videoId: "close_po",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 42,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions43: Map({
                        question: Map({
                            id: "build_po",
                            description: "Build a Purchase order",
                            defaultMessage: "Build a Purchase order"
                        }),
                        answer: Map({
                            id: "build_po",
                            videoId: "build_po",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 43,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions44: Map({
                        question: Map({
                            id: "edit_po",
                            description: "Edit a Purchase order",
                            defaultMessage: "Edit a Purchase order"
                        }),
                        answer: Map({
                            id: "edit_po",
                            videoId: "edit_po",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 44,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions45: Map({
                        question: Map({
                            id: "help",
                            description: "Help",
                            defaultMessage: "Help"
                        }),
                        answer: Map({
                            id: "help",
                            videoId: "help",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 45,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }), 
                    questions46: Map({
                        question: Map({
                            id: "contact",
                            description: "Contact",
                            defaultMessage: "Contact"
                        }),
                        answer: Map({
                            id: "contact",
                            videoId: "contact",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 46,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }), 
                    questions47: Map({
                        question: Map({
                            id: "cancel_po",
                            description: "Cancel a Purchase order",
                            defaultMessage: "Cancel a Purchase order"
                        }),
                        answer: Map({
                            id: "cancel_po",
                            videoId: "cancel_po",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 47,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),  
                    questions48: Map({
                        question: Map({
                            id: "search_tips",
                            description: "Search Tips",
                            defaultMessage: "Search Tips"
                        }),
                        answer: Map({
                            id: "search_tips",
                            videoId: "search_tips",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 48,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),  
                    questions49: Map({
                        question: Map({
                            id: "vehicle_information",
                            description: "Vehicle information, history, parameters, PM schedules",
                            defaultMessage: "Vehicle information, history, parameters, PM schedules"
                        }),
                        answer: Map({
                            id: "vehicle_information",
                            videoId: "vehicle_information",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 49,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),                      
                    questions50: Map({
                        question: Map({
                            id: "enrollement",
                            description: "Enrollment",
                            defaultMessage: "Enrollment"
                        }),
                        answer: Map({
                            id: "enrollement",
                            videoId: "enrollement",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 50,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions51: Map({
                        question: Map({
                            id: "new_supplier_registration",
                            description: "Registration",
                            defaultMessage: "Registration"
                        }),
                        answer: Map({
                            id: "new_supplier_registration",
                            videoId: "new_supplier_registration",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 51,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions52: Map({
                        question: Map({
                            id: "search_for_my_pos",
                            description: "Search for my Purchase orders",
                            defaultMessage: "Search for my Purchase orders"
                        }),
                        answer: Map({
                            id: "search_for_my_pos",
                            videoId: "search_for_my_pos",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 52,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions53: Map({
                        question: Map({
                            id: "work_completed_pos",
                            description: "Work Completed POs",
                            defaultMessage: "Work Completed POs"
                        }),
                        answer: Map({
                            id: "work_completed_pos",
                            videoId: "work_completed_pos",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 53,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions55: Map({
                        question: Map({
                            id: "quick_start_mex",
                            description: "Quick start",
                            defaultMessage: "Quick start"
                        }),
                        answer: Map({
                            id: "quick_start_mex",
                            videoId: "quick_start_mex",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 55,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['USA','CAN']
                    }),
                    questions56: Map({
                        question: Map({
                            id: "adv_search_ata",
                            description: "Search for Parts",
                            defaultMessage: "Search for Parts"
                        }),
                        answer: Map({
                            id: "adv_search_ata",
                            videoId: "adv_search_ata",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 56,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                    questions57: Map({
                        question: Map({
                            id: "registration_information",
                            description: "Courtesy Delivery Requests",
                            defaultMessage: "Courtesy Delivery Requests"
                        }),
                        answer: Map({
                            id: "registration_information",
                            videoId: "registration_information",
                            isVideo: 'Y'                            
                        }),
                        category: "Help Videos",
                        id: 57,
                        isPublicQuestion: false,
                        isVisible: false,
                        vendorType:"REPAIR",
                        restrictedCountry: ['MEX']
                    }),
                                           
                }),
            })

        }),
        clientParameters: Map({
            parameters: []
        }),
        driverValidationComplaints: Map(),
        documents: Map({}),
        vendorAccount: Map(),
        carWashAccount:Map({}),
    })
});

export default initialAppState;