// libraries
import React from 'react';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import { Row, Col, Popover } from 'react-bootstrap';
// our stuff
import CheckCircle from 'components/CheckCircle';

class VehicleComponentGuideWheelLoad extends React.Component {		
	render() {	
		const messages = defineMessages({
			fra_inspection_form__guide_wheel_load_lf: {
				id: 'fra_inspection_form__guide_wheel_load_lf',
				description: 'FRA inspection form - guide wheel load left front label.',
				defaultMessage: 'L/F'
			},
			fra_inspection__guide_wheel_load_lf_required: {
				id: 'fra_inspection__guide_wheel_load_lf_required',
                description: 'Required',
                defaultMessage: 'L/F is required'
			},
			fra_inspection_form__guide_wheel_load_rf: {
				id: 'fra_inspection_form__guide_wheel_load_rf',
				description: 'FRA inspection form - guide wheel load right front label.',
				defaultMessage: 'R/F'
			},
			fra_inspection__guide_wheel_load_rf_required: {
				id: 'fra_inspection__guide_wheel_load_rf_required',
                description: 'Required',
                defaultMessage: 'R/F is required'
			},
			fra_inspection_form__guide_wheel_load_lr: {
				id: 'fra_inspection_form__guide_wheel_load_lr',
				description: 'FRA inspection form - guide wheel load left rear label.',
				defaultMessage: 'L/R'
			},
			fra_inspection__guide_wheel_load_lr_required: {
				id: 'fra_inspection__guide_wheel_load_lr_required',
                description: 'Required',
                defaultMessage: 'L/R is required'
			},
			fra_inspection_form__guide_wheel_load_rr: {
				id: 'fra_inspection_form__guide_wheel_load_rr',
				description: 'FRA inspection form - guide wheel load right rear label.',
				defaultMessage: 'R/R'
			},
			fra_inspection__guide_wheel_load_rr_required: {
				id: 'fra_inspection__guide_wheel_load_rr_required',
                description: 'Required',
                defaultMessage: 'R/R is required'
			},															
		});

		if (!this.props.formData)
			return null;					
			
		return (
			<div>												
				<Row>
					
					<Col xs={2}>					
						<CheckCircle
							name='lf'
							message={<FormattedMessage {...messages.fra_inspection_form__guide_wheel_load_lf} />}
							checked={this.props.formData.get('guideWheelLoadLeftFrontCheckOk')} 						
							onClick={()=>this.props.setFormData('guideWheelLoadLeftFrontCheckOk', !this.props.formData.get('guideWheelLoadLeftFrontCheckOk'))}
						/>
						{ !!this.props.formValidationMessages.get('guideWheelLoadLeftFrontCheckOk') &&
							<Popover 
								id='railGearModelOverlay' 
								className='fra_inspection_vehicle_components__error_message'
								placement='top' 
								positionTop={-70}
								positionLeft={20}
							>
								<RawIntlProvider value={this.props.intl}>
								<FormattedMessage {...(messages[this.props.formValidationMessages.get('guideWheelLoadLeftFrontCheckOk')])}/>
								</RawIntlProvider>
							</Popover>
						} 
					</Col>

					<Col xs={2}>
						<CheckCircle
							name='lf'
							message={<FormattedMessage {...messages.fra_inspection_form__guide_wheel_load_rf} />}
							checked={this.props.formData.get('guideWheelLoadRightFrontCheckOk')} 						
							onClick={()=>this.props.setFormData('guideWheelLoadRightFrontCheckOk', !this.props.formData.get('guideWheelLoadRightFrontCheckOk'))}
						/>
						{ !!this.props.formValidationMessages.get('guideWheelLoadRightFrontCheckOk') &&
							<Popover 
								id='railGearModelOverlay' 
								className='fra_inspection_vehicle_components__error_message'
								placement='top' 
								positionTop={-70}
								positionLeft={20}
							>
								<RawIntlProvider value={this.props.intl}>
								<FormattedMessage {...(messages[this.props.formValidationMessages.get('guideWheelLoadRightFrontCheckOk')])}/>
								</RawIntlProvider>
							</Popover>
						}
					</Col>

					<Col xs={2}>
						<CheckCircle
							name='lf'
							message={<FormattedMessage {...messages.fra_inspection_form__guide_wheel_load_lr} />}
							checked={this.props.formData.get('guideWheelLoadLeftRearCheckOk')} 						
							onClick={()=>this.props.setFormData('guideWheelLoadLeftRearCheckOk', !this.props.formData.get('guideWheelLoadLeftRearCheckOk'))}
						/>
						{ !!this.props.formValidationMessages.get('guideWheelLoadLeftRearCheckOk') &&
							<Popover 
								id='railGearModelOverlay' 
								className='fra_inspection_vehicle_components__error_message'
								placement='top' 
								positionTop={-70}
								positionLeft={20}
							>
								<RawIntlProvider value={this.props.intl}>
								<FormattedMessage {...(messages[this.props.formValidationMessages.get('guideWheelLoadLeftRearCheckOk')])}/>
								</RawIntlProvider>
							</Popover>
						}
					</Col>

					<Col xs={2}>
						<CheckCircle
							name='lf'
							message={<FormattedMessage {...messages.fra_inspection_form__guide_wheel_load_rr} />}
							checked={this.props.formData.get('guideWheelLoadRightRearCheckOk')} 						
							onClick={()=>this.props.setFormData('guideWheelLoadRightRearCheckOk', !this.props.formData.get('guideWheelLoadRightRearCheckOk'))}
						/>
						{ !!this.props.formValidationMessages.get('guideWheelLoadRightRearCheckOk') &&
							<Popover 
								id='railGearModelOverlay' 
								className='fra_inspection_vehicle_components__error_message'
								placement='top' 
								positionTop={-70}
								positionLeft={20}
							>
								<RawIntlProvider value={this.props.intl}>
								<FormattedMessage {...(messages[this.props.formValidationMessages.get('guideWheelLoadRightRearCheckOk')])}/>
								</RawIntlProvider>
							</Popover>
						}
					</Col>

				</Row>			
			</div>
		)
    }
}

export default injectIntl(VehicleComponentGuideWheelLoad);