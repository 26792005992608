import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import { Popover, Overlay } from 'react-bootstrap';
import Spinner from 'components/Spinner';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl,RawIntlProvider } from 'react-intl';
import { falseIfUndefined, emptyMapIfUndefined, emptyStringIfUndefined } from 'utils/HelperFunctions';
import { clearErrorMessage } from 'actions/App';
import 'SearchVendor.css';
import jwtDecode from 'jwt-decode';
import * as helperFunctions from 'utils/HelperFunctions';
import Help from 'components/Help';
import * as actions from '../actions/ARIUser';
import * as constants from 'constants/App';

class SearchVendor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            subject: undefined,
            showErrorMessage: false
        };

        if (this.props.token) {
            const jwtToken = jwtDecode(this.props.token);
            const subject = jwtToken.sub;
            this.state.subject = subject;
        }
    }

    componentDidMount() {
        this.refs.txtVendorSearch.value = this.props.vendorId;
    }

    validVendorId() {

        const { formatMessage } = this.props.intl;
        let flag = false;
        let vendorId = this.refs.txtVendorSearch.value.trim().toUpperCase();
            
        if (vendorId == "") {
            this.props.dispatch(actions.showPopOverVendorIdEmpty(true));
            flag = false;
        } else {
            let suffix = vendorId.substr(vendorId.length - 2);

            if (['UA', 'CC', 'MM'].includes(suffix)) {
                flag = true;
            }
            else {
                this.props.dispatch(actions.showPopOverSuffixMissing(true));
                flag = false;
            }
        }

        return flag;
    }

    onHide() {
        this.props.dispatch(actions.clearSearchVendor());
    }

    onTextChangedHandler(e) {
        e.preventDefault();
    }

    onSearchVendorClickHandler(e) {
        e.preventDefault();
        let hasValidated = this.validVendorId();
        if (hasValidated) {
            const vendorId = this.refs.txtVendorSearch.value.trim().toUpperCase();
            this.props.dispatch(actions.fetchVendor(this.props.token, vendorId));
        }
    }

    render() {
        const isARIUser = window.location.href.includes('/ariuser') ? true : false;
        const { formatMessage } = this.props.intl;
        const messages = defineMessages({
            vendor_search_label: {
                id: 'vendor_search_label',
                description: 'Vendor Search Label',
                defaultMessage: 'Enter a Vendor ID to access the account'
            },
            vendor_search_placeholder: {
                id: 'vendor_search_placeholder',
                description: 'Vendor Search Placeholder',
                defaultMessage: 'Enter Vendor ID'
            },
            vendor_search_invalid: {
                id: 'vendor_search_invalid',
                description: 'vendor search invalid',
                defaultMessage: 'Invalid Vendor Search Container'
            },
            vendor_search_permission_error: {
                id: 'vendor_search_permission_error',
                description: 'vendor search permission error',
                defaultMessage: 'Holman User doesnot have permission to view this vendor'
            },
            vendor_search_empty: {
                id: 'vendor_search_empty',
                description: 'vendor search empty',
                defaultMessage: 'Vendor Id cannot be empty'
            },
            vendor_search_error: {
                id: 'vendor_search_error',
                description: 'vendor search error',
                defaultMessage: 'Vendor does not found'
            },
            vendor_search_suffix_missing: {
                id: 'vendor_search_suffix_missing',
                description: 'vendor search suffix missing',
                defaultMessage: 'Enter the country suffix at the end of each vendor ID (UA, MM, CC)'
            }
        });

        if (!(this.state.subject && this.state.subject === 'ARIUSER') && !isARIUser) {
            return <div className="row no-search-vendor container-fluid" id="no-search-vendor"></div>;
        }

        return <div className={!isARIUser ? "row search-vendor search-vendor-text-center container-fluid" : "row search-vendor container-fluid"} id="search-vendor" ref="divSearchVendor">
            {isARIUser ? false : <div className="visible-lg links col-md-offset-1 col-md-1 col-lg-offset-1 col-lg-2" >&nbsp;</div>}
            <div className={!isARIUser ? "col-xs-12 col-sm-offset-1 col-sm-11 col-md-offset-2 col-md-8 col-lg-offset-0 col-lg-8" : "col-xs-12"} >
                <form className="form-inline" onSubmit={this.onSearchVendorClickHandler.bind(this)}>
                    <div className="form-group">
                        <label for="txtVendorSearch" ref="lblVendorSearch" className="search-vendor-label">
                            <FormattedMessage {...messages.vendor_search_label} />
                        </label>
                        <Overlay
                            show={this.props.hasErrorMessage}
                            onHide={this.onHide.bind(this)}
                            placement="bottom"
                            container={this.refs.divSearchVendor}
                            target={props => ReactDOM.findDOMNode(this.refs.txtVendorSearch)}
                            rootClose
                        >
                            <Popover id="popOverError" className="basic_search__errorMessage">
                                {this.props.errorMessage}
                            </Popover>

                        </Overlay>
                        <Overlay
                            show={this.props.popOverDisplayShowVendorPermission}
                            onHide={this.onHide.bind(this)}
                            placement="right"
                            target={props => ReactDOM.findDOMNode(this.refs.txtVendorSearch)}
                        >
                            <Popover id="VendorIdErrorMessage"
                                className="recover-component__errorMessage">
                                      <RawIntlProvider value={this.props.intl}>
                                <FormattedMessage {...messages.vendor_search_permission_error} />
                                </RawIntlProvider>
                            </Popover>

                        </Overlay>
                        <Overlay
                            show={this.props.popOverDisplayShowVendorIdEmpty}
                            onHide={this.onHide.bind(this)}
                            placement="right"
                            target={props => ReactDOM.findDOMNode(this.refs.txtVendorSearch)}
                        >
                            <Popover id="VendorIdErrorMessage"
                                className="recover-component__errorMessage">
                                    <RawIntlProvider value={this.props.intl}>
                                <FormattedMessage {...messages.vendor_search_empty} />
                                </RawIntlProvider>
                            </Popover>
                        </Overlay>
                        <Overlay
                            show={this.props.popOverDisplayShowSuffixMissing}
                            onHide={this.onHide.bind(this)}
                            placement="bottom"
                            target={props => ReactDOM.findDOMNode(this.refs.txtVendorSearch)}
                        >
                            <Popover id="VendorIdErrorMessage"
                                className="recover-component__errorMessage">
                                    <RawIntlProvider value={this.props.intl}>
                                <FormattedMessage {...messages.vendor_search_suffix_missing} />
                                </RawIntlProvider>
                            </Popover>
                        </Overlay>
                        <input id="txtVendorSearch"
                            name="txtVendorSearch"
                            ref="txtVendorSearch"
                            type="text"
                            className="form-control search-vendor-input"
                            cols="10"
                            maxLength="10"
                            size="10"
                            placeholder={formatMessage(messages.vendor_search_placeholder)}
                            autoFocus
                            onChange={this.onTextChangedHandler.bind(this)}
                            onBlur={this.onHide.bind(this)}
                        />

                        <div className="input-group search-vendor-button">
                            <span className="input-group-btn">
                                {this.props.isfetchingData ?
                                    <div className="form-group search-vendor__spinner-container">
                                        <Spinner spinnerType="dark-blue" />
                                    </div>
                                    : <input type="image" className="basic_search__image" ref="search"
                                        src="/assets/VMS_28button_find_blue.png" role="button"
                                        onClick={this.onSearchVendorClickHandler.bind(this)} />
                                }
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </div>;
    }
}

// Which props do we want to inject, given the global state?
function selectSearchVendor(state) {
    return {
        token: state.appState.getIn(['serverData', 'ariUser', 'token']) || "",
        locale: emptyStringIfUndefined(state.appState.getIn(['uiData', 'shared', 'selectedLocale'])),
        country: emptyStringIfUndefined(state.appState.getIn(['uiData', 'shared', 'selectedCountry'])),
        isfetchingData: falseIfUndefined(state.appState.getIn(['uiData', 'ariUser', 'isFetchingData'])),
        popOverDisplayShowVendorPermission: falseIfUndefined(state.appState.getIn(['uiData', 'ariUser', 'popOver', 'isPopOverDisplayShowVendorPermission'])),
        popOverDisplayShowVendorIdEmpty: falseIfUndefined(state.appState.getIn(['uiData', 'ariUser', 'popOver', 'isPopOverDisplayShowVendorIdEmpty'])),
        popOverDisplayShowSuffixMissing: falseIfUndefined(state.appState.getIn(['uiData', 'ariUser', 'popOver', 'isPopOverDisplayShowSuffixMissing'])),
        hasErrorMessage: falseIfUndefined(state.appState.getIn(['uiData', 'ariUser', 'searchVendorErrorMessage'])),
        errorMessage: state.appState.getIn(['uiData', 'ariUser', 'searchVendorErrorMessage']),
        vendorId: emptyStringIfUndefined(state.appState.getIn(['serverData', 'ariUser', 'vendorId']))
    };
}

export default connect(selectSearchVendor)(injectIntl(SearchVendor));