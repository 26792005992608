import React from 'react';
import ReactDoM from 'react-dom';
import * as constants from 'constants/App';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import Submit from 'components/Submit';
import './VendorTermsAndConditionsRegister.css';
import { Popover, Overlay } from 'react-bootstrap';
import * as helperFunctions from 'utils/HelperFunctions';
import VendorTermsAndConditions from 'components/VendorTermsAndConditions';


class VendorTermsAndConditionsRegister extends React.Component {


    onSubmit(e) {
        e.preventDefault();
        this.props.onSubmitStep(2);
    }

    renderValidationOverlay(field, fieldName, placement) {

        let message = this.props.termsAndConditionsErr;
        let showError = message != undefined ;
        if (message != undefined)
            return <Overlay
                show={showError}
                target={() => field}
                placement={placement != undefined ? placement : "right"}
                container={this}>
                <Popover id={"popOver" + fieldName} className="enrollment-details__error_message step1__popover">
                <RawIntlProvider value={this.props.intl}>
                    <FormattedMessage {...message} />
                    </RawIntlProvider>
                </Popover>
            </Overlay>
        else
            return false;
    }
    render() {
        const messages = defineMessages({
            terms_info: {
                id: 'terms_info',
                description: 'Step 2 of 5: Holman Partner Connect Terms & Conditions',
                defaultMessage: 'Step 2 of 5: Holman Partner Connect Terms & Conditions'
            },
            go_to_next_step: {
                id: 'go_to_next_step',
                description: 'Go to next step',
                defaultMessage: 'Go to next step'
            },
        })

        return (
            <div className='step2'>
                <div className='step2__header'><FormattedMessage {...messages.terms_info } /></div>
                <div className='step2__bank-info'>
                    <div className='step2__bank-info-input'>

                        <div className='step2__input-group'>
                            <VendorTermsAndConditions  ref={input => this.terms_and_conditions = input} termsAndConditions={this.props.termsAndConditions}
                                                      toggleAcceptedFlag={this.props.toggleAcceptedFlag}
                                                      onAcceptedTerms={this.props.onAcceptedTerms.bind(this)}
                                                      isFetchingTermsAndConditions={this.props.isFetchingTermsAndConditions}

                            />
                            {this.renderValidationOverlay(this.terms_and_conditions, "TERMS_AND_CONDITIONS", 'bottom')}
                    <div className='step2__next-step'>
                        <Submit caption={<FormattedMessage {...messages.go_to_next_step }/>}
                                imagePath="/assets/VMS_33button_go_bblue.png"
                                onClickHandler={this.onSubmit.bind(this)}
                                showSpinner={this.props.isFetchingTermsAndConditionsAccepted}/>
                    </div>
                </div>
            </div>
                </div>
            </div>
        );
    }
};
export default injectIntl(VendorTermsAndConditionsRegister);