import React, {Fragment} from 'react';
import {defineMessages, FormattedMessage,injectIntl,RawIntlProvider} from 'react-intl';
import {connect} from 'react-redux';
import * as constants from 'constants/App';
import './EditVendorContact.css';
import {Overlay, Popover} from 'react-bootstrap';
import ReactDOM from 'react-dom';
import {Map} from 'immutable';
import * as helperFunctions from 'utils/HelperFunctions';
import Submit from 'components/Submit';
import FormWrapper from 'components/FormWrapper';

class EditVendorContact extends React.Component {

    onSaveClick() {

        //--------------------------------------------------------------
        //--- The below code is commented out because we are currently not saving physical address from the vms to inifinium
        //--- Uncomment the code below when we have intigration in place
        //--------------------------------------------------------------

        if(this.props.country=='USA')
        this.props.saveOnClickHandler(this.refs.txtVendorName.value,
            this.refs.txtBusinessPhone1.value + this.refs.txtBusinessPhone2.value + this.refs.txtBusinessPhone3.value,
            this.refs.txtVendorExtension.value, this.refs.txtFaxPhone1.value + this.refs.txtFaxPhone2.value + this.refs.txtFaxPhone3.value,
            this.refs.txtVendorStreet1.value,
            this.refs.txtVendorCity.value, this.refs.txtVendorState.value, this.refs.txtVendorZip.value,
            (this.props.isLinkedVendor || this.props.isSSOUser) ? this.refs.txtVendorEmail.value:'');
        // --------------------------------------------------------------
        else

        this.props.saveOnClickHandler(this.refs.txtVendorName.value,
            this.refs.txtBusinessPhone1.value + this.refs.txtBusinessPhone2.value + this.refs.txtBusinessPhone3.value,
            this.refs.txtVendorExtension.value, this.refs.txtFaxPhone1.value + this.refs.txtFaxPhone2.value + this.refs.txtFaxPhone3.value,
            '','', '', '', (this.props.isLinkedVendor || this.props.isSSOUser || this.props.country=='MEX') ? this.refs.txtVendorEmail.value:'');


    }

    render() {

        const messages = defineMessages({
            name_information_title: {
                id: 'name_information_title',
                description: 'Title for name section',
                defaultMessage: 'Business Name'
            },
            position_information_title: {
                id: 'position_information_title',
                description: 'Title for position section',
                defaultMessage: 'Position'
            },
            save_contact_information: {
                id: 'save_contact_information',
                description: 'Button for saving contact information',
                defaultMessage: 'Save contact information settings'
            },
            settings_Cancel: {
                id: 'settings_Cancel',
                description: 'Cancel',
                defaultMessage: 'Cancel'
            },
            vendor_business_phone_number: {
                id: 'vendor_business_phone_number',
                description: 'vendor business number title',
                defaultMessage: 'Business'
            },
            vendor_extension_number_edit: {
                id: 'vendor_extension_number_edit',
                description: 'vendor extension number title',
                defaultMessage: 'Ext. (Optional)'
            },
            vendor_fax_number_edit: {
                id: 'vendor_fax_number_edit',
                description: 'vendor fax number title',
                defaultMessage: 'Fax (Optional)'
            },
            contact_information_title: {
                id: 'contact_information_title',
                description: 'Title for contact information section',
                defaultMessage: 'Contact information'
            },
            vendor_street_address_title: {
                id: 'vendor_street_address_title',
                description: 'Title for vendor address',
                defaultMessage: 'Street Address'
            },
            vendor_city_title: {
                id: 'vendor_city_title',
                description: 'Title for vendor address',
                defaultMessage: 'City'
            },
            vendor_state_title: {
                id: 'vendor_state_title',
                description: 'Title for vendor address',
                defaultMessage: 'State'
            },
            vendor_zip_title: {
                id: 'vendor_zip_title',
                description: 'Title for vendor address',
                defaultMessage: 'Zip Code'
            },
            vendor_name_error_message: {
                id: 'vendor_name_error_message',
                description: 'Error message for vendor name',
                defaultMessage: 'Please enter primary contact first and last name.'
            },
            vendor_street_address_error_message: {
                id: 'vendor_street_address_error_message',
                description: 'Error message for vendor street address',
                defaultMessage: 'Please enter your street address.'
            },
            vendor_city_address_error_message: {
                id: 'vendor_city_address_error_message',
                description: 'Error message for vendor city address',
                defaultMessage: 'Please enter your city address.'
            },
            vendor_zip_address_error_message: {
                id: 'vendor_zip_address_error_message',
                description: 'Error message for vendor zip address',
                defaultMessage: 'Please Enter a valid ZipCode.'
            },
            vendor_business_phone_error_message: {
                id: 'vendor_business_phone_error_message',
                description: 'Error message for vendor business phone number',
                defaultMessage: 'Invalid Phone Number Format. Please enter in the following format: (XXX-XXX-XXXX)'
            },
            vendor_business_extn_error_message: {
                id: 'vendor_business_extn_error_message',
                description: 'Error message for vendor business extension number',
                defaultMessage: 'Please enter valid Extension Number'
            },
            
            vendor_fax_phone_error_message: {
                id: 'vendor_fax_phone_error_message',
                description: 'Error message for vendor fax phone number',
                defaultMessage: 'Invalid Fax Number Format. Please enter in the following format: (XXX-XXX-XXXX)'
            },
            location_title: {
                id: 'location_title',
                description: 'Title for location section',
                defaultMessage: 'Location'
            },
            contact_title: {
                id: 'contact_title',
                description: 'Title for contact section',
                defaultMessage: 'Contact Name'
            },
            email_recipient_title: {
                id: 'email_recipient_title',
                description: 'Title for email section',
                defaultMessage: 'Payment Email Recipient'
            },
            vendor_email_recipient_error_message: {
                id: 'vendor_email_recipient_error_message',
                description: 'Title for email error message',
                defaultMessage: 'Invalid Email Address Format.'
            }

        });
        const tabIndexGroup = this.props.tabIndexStartFrom;
        return <div className="edit_contact_update">
            <hr className="edit-contact-info__hr"/>
            <FormWrapper key='vendorContactUpdateErrorMessage' id="vendorContactUpdateErrorMessage" {...this.props} formErrors={this.props.vendorContactUpdateErrorMessage} />
            <div className="edit-contact-info__view row">
                <div className="col-sm-8  edit-contact-info__title2">
                    <FormattedMessage {...messages.contact_information_title}/>
                </div>
            </div>

            {/*
             //--------------------------------------------------------------
             //--- The below code is commented out because we are currently not saving physical address from the vms to inifinium
             //--- Uncomment the code below when we have intigration in place
             //--------------------------------------------------------------
             */
            }
            {this.props.country=='USA'?<div>
            <div className="edit-contact-component__view row">
                <div className="col-sm-3 edit-contact-component__title">
                    <FormattedMessage {...messages.name_information_title}/>
                </div>
            </div>
            <div className="edit-contact-component__view row">
                <div className="edit-contact-component__text3">
                    {this.props.businessName}
                </div>
            </div>
            <div className="edit_contact__view-row2 row">
                <div className="col-sm-3  edit-contact-info__title">
                    <FormattedMessage {...messages.location_title}/>
                </div>
            </div>
            <div className="edit-contact-component__view2">
                <div className="edit-contact-component__view row">
                    <div className="edit-contact-component__title">
                        <FormattedMessage {...messages.vendor_street_address_title}/>
                    </div>
                </div>
                <div className="edit-contact-component__view row">
                    <div className="edit-contact-component__text4">
                        <Overlay
                            show={this.props.showVendorError.get('showVendorStreetAddress1Error')}
                            placement="right"
                            target={props => ReactDOM.findDOMNode(this.refs.txtVendorStreet1) }

                        >
                            <Popover id="popOver_VendorStreet1" className="edit_contact__errorMessage">
                                 <RawIntlProvider value={this.props.intl}>
                                <FormattedMessage {...messages.vendor_street_address_error_message}/>
                                </RawIntlProvider>
                            </Popover>
                        </Overlay>
                        <input ref="txtVendorStreet1" className="edit-contact__input4" type="text" tabIndex="1"
                               defaultValue={this.props.businessAddress} onBlur={this.props.onBlurChange.bind(this)}/>
                    </div>
                </div>
                <div className="edit-contact-component__view row">
                    <div className="edit-contact-component__title">
                        <FormattedMessage {...messages.vendor_city_title}/> <a className="edit_contact-address_spacing">
                        <FormattedMessage {...messages.vendor_state_title}/></a>
                        <FormattedMessage {...messages.vendor_zip_title}/>
                    </div>
                </div>
                <div className="edit-contact-component__view row">
                    <div className="edit-contact-component__text4">
                        <Overlay
                            show={this.props.showVendorError.get('showVendorCityAddressError')}
                            placement="left"
                            target={props => ReactDOM.findDOMNode(this.refs.txtVendorCity) }

                        >
                            <Popover id="popOver_VendorCity" className="edit_contact__errorMessage">
                            <RawIntlProvider value={this.props.intl}>
                                <FormattedMessage {...messages.vendor_city_address_error_message}/>
                                </RawIntlProvider>
                            </Popover>
                        </Overlay>
                        <input ref="txtVendorCity" className="edit-contact__input" type="text" tabIndex="2"
                               defaultValue={this.props.businessCity} onBlur={this.props.onBlurChange.bind(this)}/>
                        <div className="edit-contact__padding">
                            <select ref="txtVendorState" defaultValue={this.props.businessState}
                                    className="edit-contact__input2" onBlur={this.props.onBlurChange.bind(this)}>
                                <option value="AL">AL</option>
                                <option value="AK">AK</option>
                                <option value="AZ">AZ</option>
                                <option value="AR">AR</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DE">DE</option>
                                <option value="DC">DC</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="IA">IA</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="ME">ME</option>
                                <option value="MD">MD</option>
                                <option value="MA">MA</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MS">MS</option>
                                <option value="MO">MO</option>
                                <option value="MT">MT</option>
                                <option value="NE">NE</option>
                                <option value="NV">NV</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NY">NY</option>
                                <option value="NC">NC</option>
                                <option value="ND">ND</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VT">VT</option>
                                <option value="VA">VA</option>
                                <option value="WA">WA</option>
                                <option value="WV">WV</option>
                                <option value="WI">WI</option>
                                <option value="WY">WY</option>
                            </select>
                        </div>
                        <Overlay
                            show={this.props.showVendorError.get('showVendorZipAddressError')}
                            placement="right"
                            target={props => ReactDOM.findDOMNode(this.refs.txtVendorZip) }

                        >
                            <Popover id="popOver_VendorZip" className="edit_contact__errorMessage">
                            <RawIntlProvider value={this.props.intl}>
                                <FormattedMessage {...messages.vendor_zip_address_error_message}/>
                                </RawIntlProvider>
                            </Popover>
                        </Overlay>
                        <input ref="txtVendorZip" className="edit-contact__input3" maxLength="10" tabIndex="4"
                               type="text"
                               onKeyPress={helperFunctions.isZipCode.bind(this)}
                               defaultValue={this.props.businessZip} onBlur={this.props.onBlurChange.bind(this)}/>

                    </div>
                </div>
            </div>
            </div> :false}
            <div className="edit-contact-component__view row">
                <div className="col-sm-3 edit-contact-component__title">
                    <FormattedMessage {...messages.contact_title}/>
                </div>
            </div>
            <div className="edit-contact-component__view row">
                <div className="col-sm-3 edit-contact-component__text">
                    <Overlay
                        show={this.props.showVendorError.get('showVendorNameError')}
                        placement="right"
                        target={props => ReactDOM.findDOMNode(this.refs.txtVendorName) }
                    >
                        <Popover id="popOver_VendorName" className="edit_contact__errorMessage">
                            <RawIntlProvider value={this.props.intl}>
                            <FormattedMessage {...messages.vendor_name_error_message}/>
                            </RawIntlProvider>
                        </Popover>
                    </Overlay>

                    <input ref="txtVendorName" className="edit-contact__input4" type="text" tabIndex={tabIndexGroup + 1}
                           defaultValue={this.props.contactNamePayment} onBlur={this.props.onBlurChange.bind(this)}/>
                </div>
            </div>
            <div className="edit-contact-component__view row">
                <div className="col-sm-4 edit-contact-component__title">
                    <FormattedMessage {...messages.vendor_business_phone_number}/>
                </div>
                <div className="col-sm-4 edit-contact-component__title">
                    <FormattedMessage {...messages.vendor_extension_number_edit}/>
                </div>
            </div>
            <div className="edit-contact-component__view row">
                <div className="col-sm-4 edit-contact-component__text">
                    <Overlay
                        show={this.props.showVendorError.get('showVendorBusinessNumberError')}
                        placement="left"
                        target={props => ReactDOM.findDOMNode(this.refs.txtBusinessPhone1) }
                    >
                        <Popover id="popOver_VendorName" className="edit_contact__errorMessage">
                            <RawIntlProvider value={this.props.intl}>
                            <FormattedMessage {...messages.vendor_business_phone_error_message}/>
                            </RawIntlProvider>
                        </Popover>
                    </Overlay>
                    <input className="edit-contact__input2" ref="txtBusinessPhone1" maxLength="3"  tabIndex={tabIndexGroup + 2}
                           type="text" onKeyPress={helperFunctions.isNumeric.bind(this)}
                           defaultValue={this.props.businessPhone.substring(0, 3)} onBlur={this.props.onBlurChange.bind(this)}/>
                    -
                    <input className="edit-contact__input2" ref="txtBusinessPhone2" maxLength="3"  tabIndex={tabIndexGroup + 3}
                           type="text" onKeyPress={helperFunctions.isNumeric.bind(this)}
                           defaultValue={this.props.businessPhone.substring(3, 6)} onBlur={this.props.onBlurChange.bind(this)}/>
                    -
                    <input className="edit-contact__input3" ref="txtBusinessPhone3" maxLength="4"  tabIndex={tabIndexGroup + 4}
                           type="text" onKeyPress={helperFunctions.isNumeric.bind(this)}
                           defaultValue={this.props.businessPhone.substring(6, 10)} onBlur={this.props.onBlurChange.bind(this)}/>
                </div>
                <div className="edit-contact-component__text">
                <Overlay
                        show={this.props.showVendorError.get('showBusinessExtensionError')}
                        placement="left"
                        target={props => ReactDOM.findDOMNode(this.refs.txtVendorExtension) }

                    >
                        <Popover id="popOver_VendorName" className="edit_contact__errorMessage">
                            <RawIntlProvider value={this.props.intl}>
                            <FormattedMessage {...messages.vendor_business_extn_error_message}/>
                            </RawIntlProvider>
                        </Popover>
                    </Overlay>

                    <input className="edit-contact__input3" ref="txtVendorExtension"  tabIndex={tabIndexGroup + 5} type="text"  maxLength="7"
                            onKeyPress={helperFunctions.isNumeric.bind(this)}
                           defaultValue={this.props.businessExtension} onBlur={this.props.onBlurChange.bind(this)}/>
                </div>
            </div>

            <div className="edit-contact-component__view row">
                <div className="col-sm-3 edit-contact-component__title">
                    <FormattedMessage {...messages.vendor_fax_number_edit}/>
                </div>
            </div>
            <div className="edit-contact-component__view row">
                <div className="col-sm-4 edit-contact-component__text">
                    <Overlay
                        show={this.props.showVendorError.get('showVendorFaxNumberError')}
                        placement="right"
                        target={props => ReactDOM.findDOMNode(this.refs.txtFaxPhone3) }

                    >
                        <Popover id="popOver_VendorName" className="edit_contact__errorMessage">
                        <RawIntlProvider value={this.props.intl}>
                            <FormattedMessage {...messages.vendor_fax_phone_error_message}/>
                            </RawIntlProvider>
                        </Popover>
                    </Overlay>
                    <input className="edit-contact__input2" maxLength="3" ref="txtFaxPhone1"  tabIndex={tabIndexGroup + 6} type="text" onKeyPress={helperFunctions.isNumeric.bind(this)}
                           defaultValue={this.props.businessFax.substring(0, 3)} onBlur={this.props.onBlurChange.bind(this)}/>
                    -
                    <input className="edit-contact__input2" maxLength="3" ref="txtFaxPhone2"  tabIndex={tabIndexGroup + 7} type="text" onKeyPress={helperFunctions.isNumeric.bind(this)}
                           defaultValue={this.props.businessFax.substring(3, 6)} onBlur={this.props.onBlurChange.bind(this)}/>
                    -
                    <input className="edit-contact__input3" maxLength="4" ref="txtFaxPhone3"  tabIndex={tabIndexGroup + 8} type="text" onKeyPress={helperFunctions.isNumeric.bind(this)}
                           defaultValue={this.props.businessFax.substring(6, 10)} onBlur={this.props.onBlurChange.bind(this)}/>
                </div>
            </div>
            {
                (this.props.isLinkedVendor || this.props.isSSOUser || this.props.country=='MEX') &&
            <Fragment>
            <div className="edit-contact-component__view row">
                <div className="col-sm-5 edit-contact-component__title">
                    <FormattedMessage {...messages.email_recipient_title}/>
                </div>
            </div>
            
            <div className="edit-contact-component__view row">
                <div className="col-sm-3 edit-contact-component__text">
                    <Overlay
                        show={this.props.showVendorError.get('showVendorEmailError')}
                        placement="right"
                        target={props => ReactDOM.findDOMNode(this.refs.txtVendorEmail) }
                    >
                        <Popover id="popOver_VendorEmail" className="edit_contact__errorMessage">
                        <RawIntlProvider value={this.props.intl}>
                            <FormattedMessage {...messages.vendor_email_recipient_error_message}/>
                            </RawIntlProvider>
                        </Popover>
                    </Overlay>

                    <input ref="txtVendorEmail" className="edit-contact__input5" type="text"  tabIndex={tabIndexGroup + 9}
                           defaultValue={this.props.contactEmailPayment} onBlur={this.props.onBlurChange.bind(this)}/>
                </div>
            </div></Fragment>}
            <div className="edit-contact-component__view3 row">
                <div className="col-sm-5"
                    >

                    <a className="edit-contact-component__caption3"
                       onClick={this.props.onCancelContactClick.bind(this)}
                       tabIndex={tabIndexGroup + 11}>
                        <FormattedMessage {...messages.settings_Cancel}/>{' '}
                        <img src="/assets/VMS_28button_delete_blue.png"/>
                    </a>


                </div>
                <div className="col-sm-7 edit-contact__save-padding">

                    <div >
                        <Submit caption={<FormattedMessage {...messages.save_contact_information } />}
                            imagePath="/assets/VMS_28button_edit_clear.png"
                            onClickHandler={this.onSaveClick.bind(this)}
                            showSpinner={this.props.isSubmittingData} 
                            spinnerType ={'blue'}
                            tabIndex={tabIndexGroup + 10}/>
                    </div>
                </div>

            </div>
            <hr className="edit-contact-info__hr"/>
        </div>
    }
}



export default injectIntl(EditVendorContact);