// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Modal } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
// Local code base
import './CraneInspectionContainer.css';
import CraneInspectionResultConfirmation from '../components/CraneInspectionResultConfirmation';
import CraneInspectionResultConfirmationFailOptions from '../components/CraneInspectionResultConfirmationFailOptions';
import CraneInspectionForm from '../components/CraneInspectionForm';
import CraneInspectionFormFailOptions from '../components/CraneInspectionFormFailOptions';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import * as craneInspectionEnums from 'PO/ClosePO/screens/CraneInspection/data/CraneInspectionEnums';

/// Summary
/// Crane Inspection is set up as a 2 step proccess. This is to make it easy for the vendor.
/// First vendor will mark if Crane inspection passed or failed.
/// If Pass, they attest and "sign" their name and save. Done.
/// If Fail, they are asked if they will repair failed components:
///     -Yes: vendor redirected to Edit PO.
///     -No:  take to Crane Inspection form to specify failed components.
///
class CraneInspectionContainer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			step: this.step.result_confirmation
		};
	}

	step = {
		result_confirmation: 'result_confirmation',
		result_confirmation_fail_options: 'result_confirmation_fail_options',
		form: 'form',
		form_fail_options: 'form_fail_options',
	}

	componentDidMount() {
		this.props.reset();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.showWindow === false && this.props.showWindow === true) {
			this.props.windowShown();
			this.setState({ step: this.step.result_confirmation }); // reset to first step
		}
	}

	/* Close window */
	handleCloseWindowClick = () => {
		this.props.cancelClosePo();
		this.props.closeWindow();
	}

	handleCraneFormClick = () => {
		this.props.setFormData('inspectorName', this.props.formData.get('inspectorSignature'));
		this.props.resetError();
		this.props.resetValidationMessages();
		this.setState({ step: this.step.form });
	}

	handleSaveConfirmationClick = () => {
		if (this.props.isSaving) {
			return;
		}

		const isValid = this.validateConfirmation();

		if (isValid === true) {
			this.props.saveConfirmation();
		}
	}

	validateConfirmation = () => {
		let isValid = true; // Start off valid.
		this.props.resetValidationMessages();

		if (!(this.props.formData.get('hasInspectionPassed') === true
			|| this.props.formData.get('hasInspectionPassed') === false)) {
			this.props.setValidationMessage('hasInspectionPassed', 'crane_inspection__has_inspection_passed_required')
			isValid = false;
		}

		//Inspection Date validation.
		if (this.props.formData.get('inspectionDate') === null)
		{
			this.props.setValidationMessage('inspectionDate', 'crane_inspection__inspection_date_disabled');
			isValid = false;
		}

		if (this.props.formData.get('inspectorAttested') !== true) {
			this.props.setValidationMessage('inspectorAttested', 'crane_inspection__attest_required')
			isValid = false;
		}

		if (this.props.formData.get('inspectorSignature') === undefined
			|| this.props.formData.get('inspectorSignature') === '') {
			this.props.setValidationMessage('inspectorSignature', 'crane_inspection__signature_required')
			isValid = false;
		}

		if (this.props.formData.get('craneManufacturer') === '') {
			this.props.setValidationMessage('craneManufacturer', 'crane_inspection__manufacturer_required')
			isValid = false;
		}

		if (this.props.formData.get('craneCapacity') === '') {
			this.props.setValidationMessage('craneCapacity', 'crane_inspection__capacity_required')
			isValid = false;
		}

		if (this.props.formData.get('craneSerialNumber') === '') {
			this.props.setValidationMessage('craneSerialNumber', 'crane_inspection__serial_number_required')
			isValid = false;
		}

		if (this.props.formData.get('craneModelNumber') === '') {
			this.props.setValidationMessage('craneModelNumber', 'crane_inspection__model_number_required')
			isValid = false;
		}

		if (this.props.formData.get('interval') === null) {
			this.props.setValidationMessage('interval', 'crane_inspection__interval_required')
			isValid = false;
		}

		if (this.props.formData.get('interval') == craneInspectionEnums.interval.other && this.props.formData.get('intervalOther') === '') {
			this.props.setValidationMessage('intervalOther', 'crane_inspection__interval_other_required')
			isValid = false;
		}

		if (this.props.formData.get('hoistSystemBrakesType') === null) {
			this.props.setValidationMessage('hoistSystemBrakesType', 'crane_inspection__hoistSystemBrakesType_required')
			isValid = false;
		}

		if (this.props.formData.get('boomJibBrakesType') === null) {
			this.props.setValidationMessage('boomJibBrakesType', 'crane_inspection__boomJibBrakesType_required')
			isValid = false;
		}

		return isValid;
	}

	onInspectionDateChangeHandler = (isDaySelected,isDisabled) =>{
		if (!isDaySelected)
		{
			this.props.setValidationMessage('inspectionDate', 'crane_inspection__inspection_date_invalid');
		} else if (isDisabled){
			this.props.setValidationMessage('inspectionDate', 'crane_inspection__inspection_date_disabled')
		}
		else{
			this.props.resetValidationMessages();
		}
	}

	handleConfirmationFailClick = () => {
		this.props.resetError();
		this.props.resetValidationMessages();
		this.setState({ step: this.step.result_confirmation_fail_options });
	}

	handleConfirmationFailYesClick = () => {
		this.props.closeWindow();
		// redirect to edit PO
		this.props.navigateToEditPo(this.props.poNumber);
	}

	handleConfirmationFailNoClick = () => {
		this.props.skipFailOptions();
		this.props.resetError();
		this.setState({ step: this.step.form });
	}

	handleAllOkClick = (name, checked) => {
		if (checked) {
			this.props.setAllVehicleComponentsOk();
		}
	}

	handleFormSaveClick = () => {
		if (this.props.isSaving) {
			return;
		}

		const isValid = this.validateForm();

		if (isValid) {
			if (this.props.formData.get('hasInspectionPassed') === true) {
				this.props.saveForm();
			}
			else if (this.props.formData.get('hasInspectionPassed') === false) {
				if (this.props.shouldSkipFailOptions === true) {
					this.props.saveForm();
				}
				else {
					this.props.resetError();
					this.setState({ step: this.step.form_fail_options });
				}
			}
			else {
				// DO NOTHING - validation should prevent hasInspectionPassed from being null.
			}
		}
	}

	validateForm = () => {
		let isValid = true; // Start off valid.
		this.props.resetValidationMessages();

		// PASS or FAIL required.
		if (!(this.props.formData.get('hasInspectionPassed') === true
			|| this.props.formData.get('hasInspectionPassed') === false)) {
			this.props.setValidationMessage('hasInspectionPassed', 'crane_inspection__has_inspection_passed_required');
			isValid = false;
		}

		// PASSED inspection: all components must be passed.
		if (this.props.formData.get('hasInspectionPassed') === true
			&& this.props.componentDetails.count((comp) =>
				(comp.get('componentResultCode') === craneInspectionEnums.componentResultCode.PASS
					|| comp.get('componentResultCode') === craneInspectionEnums.componentResultCode.NA))
			< this.props.componentDetails.count()) {
			this.props.setValidationMessage('hasInspectionPassed', 'crane_inspection__has_inspection_passed_components_invalid');
			isValid = false;
		}

		// PASSED or FAILED inspection: if deficiencies must select Unsat.
		if ((this.props.componentDetails.count((comp) =>
			((comp.get('componentFailureReasons') != undefined && comp.get('componentFailureReasons') != null && comp.get('componentFailureReasons') != '')
				&& (comp.get('componentResultCode') === null
					|| comp.get('componentResultCode') === craneInspectionEnums.componentResultCode.PASS
					|| comp.get('componentResultCode') === craneInspectionEnums.componentResultCode.NA))) > 0)) {
			this.props.setValidationMessage('hasInspectionPassed', 'crane_inspection__has_inspection_passed_no_failure_unsat_deficiencies');
			isValid = false;
		}

		if (this.props.formData.get('hasInspectionPassed') === false) {
			// FAILED inspection: All components must specify Sat./Unsat./NA.
			if (this.props.formData.get('hasInspectionPassed') === false
				&& this.props.componentDetails.count((comp) =>
					comp.get('componentResultCode') === craneInspectionEnums.componentResultCode.PASS
					|| comp.get('componentResultCode') === craneInspectionEnums.componentResultCode.NA
					|| comp.get('componentResultCode') === craneInspectionEnums.componentResultCode.FAIL) < this.props.componentDetails.count()) {
				this.props.setValidationMessage('hasInspectionPassed', 'crane_inspection__has_inspection_failed_all_components_required');
				isValid = false;
			}
			// FAILED inspection: at least one component must be failed.
			else if (this.props.componentDetails.count((comp) =>
				comp.get('componentResultCode') === craneInspectionEnums.componentResultCode.FAIL) === 0) {
				this.props.setValidationMessage('hasInspectionPassed', 'crane_inspection__has_inspection_passed_no_failure_specified');
				isValid = false;
			}
			// FAILED inspection: if deficiencies must select Unsat.
			else if (this.props.componentDetails.count((comp) =>
				(comp.get('componentFailureReasons') === null || comp.get('componentFailureReasons') === '')
				&& comp.get('componentResultCode') === craneInspectionEnums.componentResultCode.FAIL) > 0) {
				this.props.setValidationMessage('hasInspectionPassed', 'crane_inspection__has_inspection_fail_deficiencies_without_unsat');
				isValid = false;
			}
		}

		//Inspection Date validation.
		if (this.props.formData.get('inspectionDate') === null)
		{
			this.props.setValidationMessage('inspectionDate', 'crane_inspection__inspection_date_disabled');
			isValid = false;
		}

		// PASSED inspection: ATTEST required.
		if (this.props.formData.get('hasInspectionPassed') === true
			&& this.props.formData.get('inspectorAttested') !== true) {
			this.props.setValidationMessage('inspectorAttested', 'crane_inspection__attest_required');
			isValid = false;
		}

		// PASSED inspection: SIGNATURE required.
		if (this.props.formData.get('inspectorSignature') === undefined
			|| (this.props.formData.get('inspectorSignature') || '') === ''
		) {
			this.props.setValidationMessage('inspectorSignature', 'crane_inspection__signature_required');
			isValid = false;
		}

		if (this.props.formData.get('craneManufacturer') === '') {
			this.props.setValidationMessage('craneManufacturer', 'crane_inspection__manufacturer_required')
			isValid = false;
		}

		if (this.props.formData.get('craneCapacity') === '') {
			this.props.setValidationMessage('craneCapacity', 'crane_inspection__capacity_required')
			isValid = false;
		}

		if (this.props.formData.get('craneSerialNumber') === '') {
			this.props.setValidationMessage('craneSerialNumber', 'crane_inspection__serial_number_required')
			isValid = false;
		}

		if (this.props.formData.get('craneModelNumber') === '') {
			this.props.setValidationMessage('craneModelNumber', 'crane_inspection__model_number_required')
			isValid = false;
		}

		if (this.props.formData.get('interval') === null) {
			this.props.setValidationMessage('interval', 'crane_inspection__interval_required')
			isValid = false;
		}

		if (this.props.formData.get('interval') == craneInspectionEnums.interval.other && this.props.formData.get('intervalOther') === '') {
			this.props.setValidationMessage('intervalOther', 'crane_inspection__interval_other_required')
			isValid = false;
		}

		if (this.props.formData.get('hoistSystemBrakesType') === null) {
			this.props.setValidationMessage('hoistSystemBrakesType', 'crane_inspection__hoistSystemBrakesType_required')
			isValid = false;
		}

		if (this.props.formData.get('boomJibBrakesType') === null) {
			this.props.setValidationMessage('boomJibBrakesType', 'crane_inspection__boomJibBrakesType_required')
			isValid = false;
		}

		return isValid;
	}

	handleFormFailYesClick = () => {
		this.props.closeWindow();
		// redirect to edit PO
		this.props.navigateToEditPo(this.props.poNumber);
	}

	handleFormFailNoClick = () => {
		if (this.props.isSaving) {
			return;
		}

		this.props.saveForm();
	}

	render() {
		const messages = defineMessages({
			close_dialog: {
				id: 'close_dialog',
				description: '  Close',
				defaultMessage: 'Close'
			},
		})

		return (
			<div>
				<Modal
					show={this.props.showWindow}
					dialogClassName={classnames('crane_inspection',
						{
							'crane_inspection--small':
								this.state.step === this.step.form_fail_options
								|| this.state.step === this.step.result_confirmation_fail_options
						})}
					backdrop="static"
					keyboard={false}
					onEntered={() => this.setState({ modalLoaded: true })}
				>
					<Modal.Body>
						<div
							className='crane_inspection__body'>

							{(this.state.step === this.step.form || this.state.step === this.step.result_confirmation) &&
								<div onClick={this.handleCloseWindowClick} className='crane_inspection__close_button'>
									<img className="add-new-line-items__close-button"
										src="/assets/VMS_33button_kill_blue.png" />{"  "}
									{<FormattedMessage {...messages.close_dialog} />}
								</div>
							}

							<FormWrapper key='craneInspectionErrorMessage' id="error_message" {...this.props} formErrors={
								this.props.craneInspectionErrorMessage || this.props.errorMessage
							} />

							{this.state.step === this.step.result_confirmation &&
								<CraneInspectionResultConfirmation
									formData={this.props.formData}
									formValidationMessages={this.props.formValidationMessages}
									setFormData={this.props.setFormData}
									isSaving={this.props.isSaving}
									assetType={this.props.assetType}
									userLocale={this.props.userLocale}
									poCreationDate={this.props.poCreationDate}
									onConfirmationFailClick={this.handleConfirmationFailClick}
									onEditCraneFormClick={this.handleCraneFormClick}
									onSaveConfirmationClick={this.handleSaveConfirmationClick}
									onInspectionDateChangeHandler={this.onInspectionDateChangeHandler}
								/>
							}

							{this.state.step === this.step.result_confirmation_fail_options &&
								<CraneInspectionResultConfirmationFailOptions
									onYesClick={this.handleConfirmationFailYesClick}
									onNoClick={this.handleConfirmationFailNoClick}
								/>
							}


							{this.state.step === this.step.form &&
								<CraneInspectionForm
									formData={this.props.formData}
									formValidationMessages={this.props.formValidationMessages}
									setFormData={this.props.setFormData}
									componentDetails={this.props.componentDetails}
									assetType={this.props.assetType}
									isSaving={this.props.isSaving}
									userLocale={this.props.userLocale}
									poCreationDate={this.props.poCreationDate}
									onComponentDetailsChanged={this.props.setComponentDetails}
									onAllOkClick={this.handleAllOkClick}
									onFormSaveClick={this.handleFormSaveClick}
									onInspectionDateChangeHandler={this.onInspectionDateChangeHandler}
								/>
							}

							{this.state.step === this.step.form_fail_options &&
								<CraneInspectionFormFailOptions
									isSaving={this.props.isSaving}
									onYesClick={this.handleFormFailYesClick}
									onNoClick={this.handleFormFailNoClick}
								/>
							}

						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

function mapToStateProps(state, props) {
	return {
		showWindow: state.appState.getIn(['uiData', 'craneInspection', 'showWindow']),
		errorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage']),
		craneInspectionErrorMessage: state.appState.getIn(['uiData', 'craneInspection', 'errorMessage']),
		poNumber: state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'id']),
		assetType: state.appState.getIn(['serverData', 'close', 'vehicleDetails', 'assetType']),
		formData: state.appState.getIn(['uiData', 'craneInspection', 'formData']) || Map(),
		formValidationMessages: state.appState.getIn(['uiData', 'craneInspection', 'formValidationMessages']) || Map(),
		componentDetails: state.appState.getIn(['uiData', 'craneInspection', 'componentDetails']) || Map(),
		shouldSkipFailOptions: state.appState.getIn(['uiData', 'craneInspection', 'shouldSkipFailOptions']) || false,
		isSaving: state.appState.getIn(['uiData', 'craneInspection', 'isSaving']) || false,
		userLocale: state.appState.getIn(['uiData', 'shared', 'selectedLocale']),
		poCreationDate: state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'createdDate']),
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		reset: () => {
			dispatch({ type: constants.CRANE_INSPECTION__RESET });
		},
		resetError: () => {
			dispatch({ type: constants.CRANE_INSPECTION__RESET_ERROR });
		},
		windowShown: () => {
			dispatch({ type: constants.CRANE_INSPECTION__WINDOW_SHOWN });
		},
		cancelClosePo: () => {
			dispatch({ type: constants.CLOSE_PO_CANCELLED });
		},
		closeWindow: () => {
			dispatch({ type: constants.CRANE_INSPECTION__DISPLAY, show: false });
		},
		setFormData: (field, value) => {
			dispatch({ type: constants.CRANE_INSPECTION__SET_FORM_DATA, field, value });
		},
		setValidationMessage: (field, message) => {
			dispatch({ type: constants.CRANE_INSPECTION__SET_VALIDATION_MESSAGE, field, message });
		},
		resetValidationMessages: () => {
			dispatch({ type: constants.CRANE_INSPECTION__RESET_VALIDATION_MESSAGES });
		},
		saveConfirmation: () => {
			dispatch({ type: constants.CRANE_INSPECTION__SAVE_CONFIRMATION });
		},
		skipFailOptions: () => {
			dispatch({ type: constants.CRANE_INSPECTION__SKIP_FAIL_OPTIONS });
		},
		saveForm: () => {
			dispatch({ type: constants.CRANE_INSPECTION__SAVE_FORM });
		},
		navigateToEditPo: (poNumber) => {
			dispatch({ type: constants.NAVIGATE_TO, path: `/po/edit/${poNumber}` });
		},
		setComponentDetails: (componentId, field, value) => {
			dispatch({ type: constants.CRANE_INSPECTION__SET_COMPONENT_DETAILS, componentId, field, value });
		},
		setAllVehicleComponentsOk: () => {
			dispatch({ type: constants.CRANE_INSPECTION__SET_ALL_COMPONENT_OK });
		},

	}
}

export default connect(mapToStateProps, mapDispatchToProps)(CraneInspectionContainer);