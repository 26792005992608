import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import Spinner from 'components/Spinner';
import { PO_AUTHORIZATION_STATUS, PO_PAYMENT_STATUS, CARWASH } from 'constants/App';
import AddEditViewPurchaseOrders from './../../../../shared/AddEditView/AddEditViewPurchaseOrders';
import PaidPurchaseOrders from '../../shared/components/PaidPurchaseOrders';
import * as purchaseOrderViewActions from '../actions/AddEditView';
import PurchaseOrderViewSelector from '../selectors/AddEditView';
import Shared from 'selectors/shared';
import * as purchaseOrderViewEvents from '../events/AddEditView';
import ConfirmMessageBox from 'AddEditView/ConfirmMessageBox';
import { defineMessages, FormattedMessage } from 'react-intl';
import './AddEditView.css';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import { Link, Prompt, Redirect } from 'react-router-dom';
import { emptyMapIfUndefined, emptyStringIfUndefined } from 'utils/HelperFunctions';
import * as helperFunctions from 'utils/HelperFunctions';
import tireProductCodes from 'data/TireProductCodes';
import brakesProductCodes from 'data/BrakesProductCodes';
import { partVendors } from 'partVendors';
import { withRouter } from 'react-router-dom';
import { useAppState } from 'shared/AppState/app-state';
import ContactCustomerDirectlyForApprovalBanner from 'components/ContactCustomerDirectlyForApprovalBanner';

const PurchaseOrderView = (props) => {

    const [lineItemKey, setLineItemKey] = useState('');
    const [isTireReadOnly, setIsTireReadOnly] = useState(false);
    const [isTireProductCode, setIsTireProductCode] = useState(false);
    const [isBrakesProductCode, setIsBrakesProductCode] = useState(false);
    const [isPartsVendorPO, setIsPartsVendorPO] = useState(false);
    const [appState, dispatch] = useAppState();
    const deleteLineConfirmWindow = useRef(null);
    const closePOConfirmation = useRef(null);

    useEffect(() => {
        try {

            if (props.routeParams.match.params.actionType == 'view' || props.routeParams.match.params.actionType == 'edit') {
                props.dispatch(purchaseOrderViewActions.poDetailsScreenLoaded(props.routeParams.match.params.trackNumber, props.routeParams.match.params.actionType, !appState.isFlatRateFeatureEnabled));
            }

            props.dispatch({ type: constants.RESET_BRAKES_UNIT })
            props.dispatch({ type: constants.RESET_TIRES_UNIT })

            setIsPartsVendorPO(partVendors.includes(props.purchaseORderVendorId) && props.canShow);

        }
        catch (e) {
            //log the exception
            props.dispatch({ type: constants.SET_UI_ERROR_MESSAGE, errorMessage: helperFunctions.getUIGenericErrorMessage(e) })
        }
    }, []);

    useEffect(() => {
        // Load new data - user is already on this screen and did another search
        if (props.routeParams.match.params.actionType == 'view' || props.routeParams.match.params.actionType == 'edit') {
            props.dispatch(purchaseOrderViewActions.poDetailsScreenLoaded(props.routeParams.match.params.trackNumber, props.routeParams.match.params.actionType, !appState.isFlatRateFeatureEnabled));
        }
    }, [props.routeParams.match.params.actionType, props.routeParams.match.params.trackNumber]);

    const onReturnClickHandler = (e) => {
        e.preventDefault();
        window.history.back();
    }

    const onDeleteLineItemShowConfirmation = (lineItemKeyParam, event) => {
        setLineItemKey(lineItemKeyParam);
        deleteLineConfirmWindow.current.showModal();
    }

    const isTireProductCodeFunc = (productCodeForTheLine) => {
        return !tireProductCodes ? false : tireProductCodes.includes(productCodeForTheLine);
    }

    const isBrakesProductCodeFunc = (productCodeForTheLine) => {
        return !brakesProductCodes ? false : brakesProductCodes.findIndex(x => x.productCode == productCodeForTheLine) > -1;
    }

    const onUpdateLineItem = (lineItem) => {

        const lineItemIdBeingEdited = lineItem.get('lineItemKey');
        const currentLineItemBeingEdited = props.lineItems.get(lineItemIdBeingEdited) || Map();
        const complaintCodeForTheLine = currentLineItemBeingEdited.get('complaintCode');
        const productCodeForTheLine = currentLineItemBeingEdited.get('productCode');
        const unitPrice = currentLineItemBeingEdited.get('unitPrice');
        const correctionType = currentLineItemBeingEdited.get('correctionType');

        const tempIsTireProductCode = isTireProductCodeFunc(productCodeForTheLine);
        const tempIsBrakesProductCode = isBrakesProductCodeFunc(productCodeForTheLine);

        setIsTireProductCode(tempIsTireProductCode);
        setIsBrakesProductCode(tempIsBrakesProductCode);

        const quantity = Number(lineItem.get('quantity'));

        const isLineItemParts = currentLineItemBeingEdited.get('lineItemType') == constants.LINE_ITEM_TYPE.PART;

        //generate error if line item qty doesnt match with tire selection
        if ((tempIsTireProductCode) && props.selectedTires != quantity && isLineItemParts) {

            const errorMessages = defineMessages({
                qty_must_match_with_tire_selection_counter: {
                    id: 'qty_must_match_with_tire_selection_counter',
                    description: 'Number of tires selected for replacement does not match the quantity defined in the line item. Please select ',
                    defaultMessage: 'You must CLICK ON THE TIRE(s) in the diagram that corresponds to the position you are replacing. The tire will turn blue once you have clicked on it. Press RETURN to correct your entry.'
                },
                qty_must_match_end_message: {
                    id: 'qty_must_match_end_message',
                    description: ' tire(s).',
                    defaultMessage: ' tire(s).'
                }
            })

            const errorMessage = [{
                message: errorMessages.qty_must_match_with_tire_selection_counter.defaultMessage
                    + quantity
                    + errorMessages.qty_must_match_end_message.defaultMessage
            }]
            props.dispatch({ type: constants.ADD_LINE_ITEM_QTY_MISMATCH_ERR, errorMessage })


            purchaseOrderViewEvents.onOpenTiresInfoHandler(lineItemIdBeingEdited, complaintCodeForTheLine, productCodeForTheLine,
                props.dispatch, quantity, unitPrice, correctionType, tempIsTireProductCode, tempIsBrakesProductCode,
                props.routeParams.match.params.actionType);
        }
        else
            purchaseOrderViewEvents.onUpdateLineItemClickHandler(lineItem, props.dispatch);
    }

    const onOpenTireInfoHandler = (lineItemIdBeingEdited, complaintCodeForTheLine, productCodeForTheLine, qty, unitPrice, correctionType) => {
        //pick line item status and if approvalStatus == 'approved' or 'rejected' then its not editable else if its 'pending' then its editable
        const currentLineItemBeingEdited = props.lineItems.get(lineItemIdBeingEdited) || Map();
        const isLineReadOnly = currentLineItemBeingEdited.get('approvalStatus') == constants.LINE_ITEM_STATUS.APPROVED
            || currentLineItemBeingEdited.get('approvalStatus') == constants.LINE_ITEM_STATUS.REJECTED
            || props.routeParams.match.params.actionType == 'view'

        let isTireProductCodeSet = isTireProductCodeFunc(productCodeForTheLine);
        let isBrakeProductCodeSet = isBrakesProductCodeFunc(productCodeForTheLine);

        setIsTireReadOnly(isLineReadOnly);
        setIsTireProductCode(isTireProductCodeSet);
        setIsBrakesProductCode(isBrakeProductCodeSet);

        purchaseOrderViewEvents.onOpenTiresInfoHandler(lineItemIdBeingEdited, complaintCodeForTheLine, productCodeForTheLine,
            props.dispatch, qty, unitPrice, correctionType, isTireProductCodeSet, isBrakeProductCodeSet,
            props.routeParams.match.params.actionType);
    }

    const cancelUpdateLineItemHandler = (lineItemKey) => {
        purchaseOrderViewEvents.onCancelUpdateLineItemClickHandler(lineItemKey, props.dispatch)
    }

    const onUpdatePurchaseOrderDetailsHandler = () => {

        const toBeApproved = props.isDirtyPO || props.authorizedStatus == PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED;

        //show confirmation dialog if its a parts vendor and po mode is edit (this is an extra check) and PO is already approved
        if (isPartsVendorPO && props.routeParams.match.params.actionType == 'edit' && !toBeApproved)
            closePOConfirmation.current.showModal();

        //update the PO or close the PO if its non parts vendor or po needs an approval
        if (!isPartsVendorPO || toBeApproved)
            purchaseOrderViewEvents.onUpdatePurchaseOrderDetailsHandler(props.dispatch, props.isDirtyPO, props.authorizedStatus, props.routeParams.match.params.actionType)

    }

    const goToUrlClosure = (url) => {
        return () => { props.history.push(url) };
    }


    const messages = defineMessages({
        return: {
            id: 'return',
            description: 'Return',
            defaultMessage: 'Return'
        },
        add_edit_view_confirmation_title: {
            id: 'add_edit_view_confirmation_title',
            description: 'Edit PO',
            defaultMessage: 'Edit PO'
        },
        add_edit_view_confirmation_message: {
            id: 'add_edit_view_confirmation_message',
            description: 'If you are attempting to edit item quantity or price, please delete both parts and labor and resubmit the new values. Are you sure you want to delete this item?',
            defaultMessage: 'If you are attempting to edit item quantity or price, please delete both parts and labor and resubmit the new values. Are you sure you want to delete this item?'
        },
        delete_associated_line_confirmation_message: {
            id: 'delete_associated_line_confirmation_message',
            description: 'Any associated Parts or Labor line will also be deleted. Are you sure you want to delete this line?',
            defaultMessage: 'Any associated Parts or Labor line will also be deleted. Are you sure you want to delete this line?'
        },
        add_edit_view_po_confirmation_no: {
            id: 'add_edit_view_po_confirmation_no',
            description: 'No',
            defaultMessage: 'No'
        },
        add_edit_view_confirmation_yes: {
            id: 'add_edit_view_confirmation_yes',
            description: 'Yes',
            defaultMessage: 'Yes'
        },
        po_close_confirmation_title: {
            id: 'close_confirmation_title',
            description: 'Adjust PO',
            defaultMessage: 'Adjust PO'
        },
        po_close_confirmation_message: {
            id: 'close_confirmation_message',
            description: 'Are there any adjustments required in this PO?',
            defaultMessage: 'Are there any adjustments required in this PO?'
        },
        po_close_confirmation_yes: {
            id: 'close_confirmation_yes',
            description: 'Yes',
            defaultMessage: 'Yes'
        },
        po_close_confirmation_no: {
            id: 'close_confirmation_no',
            description: 'No',
            defaultMessage: 'No'
        },
    });

    if (props.isfetchingData || props.complaints === undefined) {
        return <Spinner />;
    }

    const allowedToCloseViewPO = (props.serviceClassification == CARWASH || props.purchaseOrder.get('isInsurancePo'))
        && props.paymentStatus != PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS
        && props.paymentStatus != PO_PAYMENT_STATUS.PAID
        && props.authorizedStatus == PO_AUTHORIZATION_STATUS.APPROVED
        && props.routeParams.match.params.actionType == 'view';

    const allowedToCancelPO = !(props.authorizedStatus == PO_AUTHORIZATION_STATUS.WORK_COMPLETED || props.purchaseOrder.get('isInsurancePo'));

    const onDeleteLineItemClickHandler = (lineItemKey, actionType) => {

        // Delete current line
        purchaseOrderViewEvents.onDeleteLineItemClickHandler(lineItemKey, actionType);

        // If delete associated line feature is enabled
        if (appState.isDeleteAssociatedLinesFeatureEnabled) {

            // Find and delete associated line
            const currentlineVendorReferenceNo = props.lineItems.getIn([lineItemKey, 'vendorReferenceNo'])

            if (currentlineVendorReferenceNo != null && currentlineVendorReferenceNo != undefined) {
                props.lineItems.filter((v, k) => k != lineItemKey && v.get('vendorReferenceNo') == currentlineVendorReferenceNo).forEach((v, k) => {
                    purchaseOrderViewEvents.onDeleteLineItemClickHandler(k, actionType);
                });
            }
        }
    }

    return <div>

        <Prompt when={props.isDirtyPO && (props.routeParams.match.params.actionType == 'add' || props.routeParams.match.params.actionType == 'edit')} message="This purchase order has unsaved changes. Please click CANCEL to stay on this screen. Then click Request Approval at bottom of the screen to send the changes for approval to Holman." />
        {
            props.actionType == 'view'
                ? false
                : <ConfirmMessageBox ref={deleteLineConfirmWindow}
                    title={messages.add_edit_view_confirmation_title}
                    message={appState.isDeleteAssociatedLinesFeatureEnabled ? messages.delete_associated_line_confirmation_message : messages.add_edit_view_confirmation_message}
                    okButtonText={messages.add_edit_view_confirmation_yes}
                    cancelButtonText={messages.add_edit_view_po_confirmation_no}
                    onOKClickEvent={() => onDeleteLineItemClickHandler(lineItemKey, props.dispatch, props.routeParams.match.params.actionType)}
                    onCancelClickEvent={null} />
        }
        {
            isPartsVendorPO && props.routeParams.match.params.actionType == 'edit' ?
                <ConfirmMessageBox ref={closePOConfirmation}
                    title={messages.po_close_confirmation_title}
                    message={messages.po_close_confirmation_message}
                    okButtonText={messages.po_close_confirmation_yes}
                    cancelButtonText={messages.po_close_confirmation_no}
                    onOKClickEvent={goToUrlClosure(`/po/adjustment/${props.routeParams.match.params.trackNumber}`)}
                    onCancelClickEvent={goToUrlClosure(`/po/close/${props.routeParams.match.params.trackNumber}`)}
                />
                : false
        }
        <FormWrapper key='addEditViewErrorMessage'
            id="addEditViewErrorMessage" {...props}
            formErrors={props.genericErrorMessage} />

        <FormWrapper key='requestApprovalErrorMessage'
            id="requestApprovalErrorMessage" {...props}
            formErrors={props.specificErrorMessage} />

        <FormWrapper key='poViewErrorMessage'
            id="poViewErrorMessage" {...props}
            formErrors={props.poViewErrorMessage} />

        {
            props.paymentStatus != PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS && props.paymentStatus != PO_PAYMENT_STATUS.PAID
                ? <>
                    { (props.vehicleDetails.get('isHsbClientVehicle') 
                        && (props.routeParams.match.params.actionType == 'add' || 
                        (props.entityForAssistance == constants.ENTITY_FOR_ASSISTANCE.CUSTOMER && (props.routeParams.match.params.actionType == 'edit' || props.routeParams.match.params.actionType == 'view'))))
                        && <ContactCustomerDirectlyForApprovalBanner />
                    }
                    <AddEditViewPurchaseOrders vehicleDetails={props.vehicleDetails}
                        client={props.client}
                        complaints={props.complaints}
                        notes={props.notes}
                        notesHistory={props.notesHistory}
                        complaintTypes={props.complaintTypes}
                        canAddAnotherComplaint={props.canAddAnotherComplaint}
                        lineItems={props.lineItems}
                        estimatedCompletionDate={props.estimatedCompletionDate}
                        approvedAmountTotal={props.approvedAmountTotal}
                        pendingAmountTotal={props.pendingAmountTotal}
                        userLocale={props.userLocale}
                        servicesDescription={props.servicesDescription}
                        paymentStatus={props.paymentStatus}
                        authorizedStatus={props.authorizedStatus}
                        bankAccountNo={props.bankAccountNo}
                        actionType={props.routeParams.match.params.actionType}
                        poNumber={props.routeParams.match.params.trackNumber}
                        taxAmount={props.taxAmount}
                        onAddLineItemsClick={(complaintCode) => purchaseOrderViewEvents.onAddLineItemsClickHandler(props.dispatch, complaintCode)}
                        onMissingProductCodeIconClick={(complaintCode, lineItemId) => purchaseOrderViewEvents.onMissingProductCodeIconClickHandler(props.dispatch, complaintCode, lineItemId)}
                        onAddLineItemsMaintenanceClick={() => purchaseOrderViewEvents.onAddLineItemsMaintenanceClickHandler(props.dispatch, props.routeParams, vehicleDetails)}
                        onServiceDescriptionSave={(comments, complaintCode) => purchaseOrderViewEvents.onServiceDescriptionSaveHandler(comments, complaintCode, props.dispatch, props.routeParams.match.params.actionType)}
                        onDeleteLineItemClick={onDeleteLineItemShowConfirmation}
                        onEditLineItemClick={(lineItemKey) => purchaseOrderViewEvents.onEditLineItemClickHandler(lineItemKey, props.dispatch, props.routeParams.match.params.actionType)}
                        onUpdateLineItemClick={onUpdateLineItem}
                        onCancelUpdateLineItemClick={cancelUpdateLineItemHandler}
                        onDateChangeEvent={(newEstimatedDate) => purchaseOrderViewEvents.onDateChangeEventHandler(newEstimatedDate, props.dispatch, props.routeParams.match.params.actionType)}
                        onSavePurchaseOrderDetails={() => purchaseOrderViewEvents.onSavePurchaseOrderDetailsHandler(props.routeParams, props.dispatch)}
                        onCancelPOEvent={(actionType) => purchaseOrderViewEvents.onCancelPOHandler(actionType, props.dispatch)}
                        onUpdatePurchaseOrderDetails={onUpdatePurchaseOrderDetailsHandler}
                        displayErrorMessage={(errorMessage) => purchaseOrderViewEvents.displayErrorMessage(props.dispatch, errorMessage)}
                        purchaseOrder={props.purchaseOrder}
                        isDirtyPO={props.isDirtyPO}
                        canShow={props.canShow}
                        onAddAnotherComplaintClick={(event) => purchaseOrderViewEvents.onAddAnotherComplaintHandler(event, props.dispatch)}
                        onComplaintTypeClick={(complaintCode, complaintDescription, complaintKey) => purchaseOrderViewEvents.onSelectComplaintTypeHandler(complaintCode, complaintDescription, complaintKey, props.dispatch)}
                        onChangeComplaintClick={(complaintKey, event) => purchaseOrderViewEvents.onChangeComplaintHandler(complaintKey, event, props.dispatch)}
                        creatingOrEditingComplaintForComplaintKey={props.creatingOrEditingComplaintForComplaintKey}
                        skippingComplaintForComplaintKey={props.skippingComplaintForComplaintKey}
                        isRequestingApproval={props.isRequestingApproval}
                        onComplaintNotesSave={(complaintKey, notes) => purchaseOrderViewEvents.onComplaintNotesSaveHandler(complaintKey, notes, props.dispatch)}
                        onComplaintNotesSaveState={(complaintKey, notes) => purchaseOrderViewEvents.onComplaintNotesSaveStateHandler(complaintKey, notes, props.dispatch)}
                        onClearServices={() => purchaseOrderViewEvents.onClearServicesHandler(props.dispatch)}
                        anyRemainingAtaCodesToBeAdded={props.anyRemainingAtaCodesToBeAdded}
                        isEstimateDateValid={props.isEstimateDateValid}
                        onUpdateEstimatedCompletionDate={() => purchaseOrderViewEvents.onUpdateEstimatedCompletionDateHandler(props.isEstimateDateValid, props.dispatch)}
                        lineItemIdBeingEdited={props.lineItemIdBeingEdited}
                        onOpenTiresInfo={onOpenTireInfoHandler}
                        isTireReadOnly={isTireReadOnly}
                        isTireProductCode={isTireProductCode}
                        isBrakesProductCode={isBrakesProductCode}
                        requestApprovalDisable={props.requestApprovalDisable}
                        paymentInformation={props.paymentInformation}
                        paymentDate={props.paymentDate}
                        selectedCurrency={props.selectedCurrency}
                        allowedToClosePO={allowedToCloseViewPO}
                        allowedToCancelPO={allowedToCancelPO}
                        listOfComplaintsForWhichNoteIsRequired={props.listOfComplaintsForWhichNoteIsRequired}
                    />
                </>


                : <>
                    {
                        (props.vehicleDetails.get('isHsbClientVehicle') 
                        && (props.routeParams.match.params.actionType == 'add' || 
                        (props.entityForAssistance == constants.ENTITY_FOR_ASSISTANCE.CUSTOMER && (props.routeParams.match.params.actionType == 'edit' || props.routeParams.match.params.actionType == 'view'))))
                        && <ContactCustomerDirectlyForApprovalBanner />
                    }
                    <PaidPurchaseOrders vehicleDetails={props.vehicleDetails}
                        client={props.client}
                        complaints={props.complaints}
                        notesHistory={emptyMapIfUndefined(props.notesHistory)}
                        lineItems={props.lineItems}
                        estimatedCompletionDate={props.estimatedCompletionDate}
                        approvedAmountTotal={props.approvedAmountTotal}
                        pendingAmountTotal={props.pendingAmountTotal}
                        userLocale={props.userLocale}
                        servicesDescription={props.servicesDescription}
                        paymentStatus={props.paymentStatus}
                        authorizedStatus={props.authorizedStatus}
                        bankAccountNo={props.bankAccountNo}
                        actionType={props.routeParams.match.params.actionType}
                        poNumber={props.routeParams.match.params.trackNumber}
                        taxAmount={props.taxAmount}
                        canShow={props.canShow}
                        paymentInformation={props.paymentInformation}
                        paymentDate={props.paymentDate}
                        feeInfo={props.feeInfo}
                        selectedCurrency={props.selectedCurrency}
                        listOfComplaintsForWhichNoteIsRequired={props.listOfComplaintsForWhichNoteIsRequired}
                        features={props.features}
                        vendorAccountType={props.vendorAccountType}
                        poVendorId={props.poVendorId}
                        businessAddress={props.businessAddress}
                        loggedInVendorId={props.loggedInVendorId}
                    />
                </>
        }

        <div className="add-edit-view__return"
            role="button"
            onClick={onReturnClickHandler}>
            <img src="/assets/VMS_33button_back_blue.png" />
            {' '}
            <FormattedMessage {...messages.return} />

        </div>

        {
            (props.routeParams.match.params.actionType == 'add' && props.purchaseOrder.get('id') == undefined)
                ? <Redirect to={{
                    pathname: '/'
                }} />
                : false
        }

    </div>;

}

//I moved the original selector into this function.  It was getting too "clunky".
//If you just needed 1 node from the Redux store, you don't need a reselect.
//Instead you can make a new property in the JSON object below and assign the value from 
//If you need a derived value, create a new selector in a new file and call below.
function mapStateToProps(state, props) {
    return {
        notes: state.appState.getIn(['uiData', 'addEdit', 'notes']),
        notesHistory: props.routeParams.match.params.actionType === 'view' ? state.appState.getIn(['serverData', 'view', 'notesHistory'])
            : state.appState.getIn(['uiData', 'addEdit', 'notesHistory']),
        //example:
        //oneToOnePropToState: state.appState.getIn(['uiData', 'area', 'oneToOnePropToState']),
        //newDerivedProp: newDerivedSelector(state, props),
        ...PurchaseOrderViewSelector(state, props),
        ...Shared(state, props),
        genericErrorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage']),
        specificErrorMessage: state.appState.getIn(['uiData', 'addEdit', 'errorMessage']),
        requestApprovalErrorMessage: state.appState.getIn(['uiData', 'addEdit', 'errorMessage']),
        poViewErrorMessage: state.appState.getIn(['serverData', 'view', 'errorMessage']),
        requestApprovalDisable: state.appState.getIn(['uiData', 'addEdit', 'isRequestingApproval']),
        paymentInformation: state.appState.getIn(['serverData', 'view', 'paymentInfo']),
        paymentDate: state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'paymentDate']),
        feeInfo: {
            paymentType: state.appState.getIn(['serverData', 'shared', 'paymentType']),
            intellipayFeePercent: state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'intellipayFeePercent']),
            nationalAccountFeePercent: state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'nationalAccountFeePercent']),
            isApcPo: state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'isApcPo']),
            isSupplierSitePo: state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'isSupplierSitePo']),
            isInsurancePo: state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'isInsurancePo']),
        },
        selectedCurrency: state.appState.getIn(['uiData', 'shared', 'selectedCurrency']),
        serviceClassification: state.appState.getIn(['serverData', 'shared', 'serviceClassification']),
        features: state.appState.getIn(['uiData', 'shared', 'features']),
        vendorAccountType: state.appState.getIn(['serverData', 'shared', 'vendorAccountType']),
        poVendorId: emptyStringIfUndefined(state.appState.getIn(['serverData', 'view', 'vendor', 'id'])),
        businessAddress: state.appState.getIn(['serverData', 'view', 'vendor', 'businessAddress']),
        loggedInVendorId: state.appState.getIn(['serverData', 'shared', 'vendorId'])
    };
}



export default connect(mapStateToProps)(withRouter((PurchaseOrderView)));