import React from 'react';
import {connect} from 'react-redux';
import {Map} from 'immutable';
import LineItem from './LineItem';
import AddLineItemsLink from './AddLineItemsLink';
import {LINE_ITEM_STATUS} from 'constants/App';
import './LineItems.css';

class LineItems extends React.Component {


  getSortedLineItems(lineItems) {
       return lineItems.sortBy(l => (l.get('productCode') + l.get('lineNo') + (l.get('lineItemType').toUpperCase() == 'PARTS' ? 0 : 1)));
    };

    render() {

        const approvedLineItems = this.props.lineItems.filter(l => l.get('approvalStatus') == LINE_ITEM_STATUS.APPROVED);
        const pendingLineItems = this.props.lineItems.filter(l => l.get('approvalStatus') == LINE_ITEM_STATUS.PENDING);
        const rejectedLineItems = this.props.lineItems.filter(l => l.get('approvalStatus') == LINE_ITEM_STATUS.REJECTED);

        return <div className="line_Items">
            {
                /*Do not render if no approved line items */
                approvedLineItems.count() > 0 ?
                    <LineItem lineItemType={LINE_ITEM_STATUS.APPROVED}
                              lineItems={this.getSortedLineItems(approvedLineItems)}
                              actionType={this.props.actionType}
                              complaintCode={this.props.complaintCode}
                              onMissingProductCodeIconClick={this.props.onMissingProductCodeIconClick}
                              onDeleteLineItemClick={this.props.onDeleteLineItemClick}
                              onEditLineItemClick={this.props.onEditLineItemClick}
                              onUpdateLineItemClick={this.props.onUpdateLineItemClick}
                              onCancelUpdateLineItemClick={this.props.onCancelUpdateLineItemClick}
                              hideCostInformation={this.props.hideCostInformation}
                              lineItemIdBeingEdited={this.props.lineItemIdBeingEdited}
                              onOpenTiresInfo = {this.props.onOpenTiresInfo}
                              selectedCurrency={this.props.selectedCurrency}
                    />
                    : false
            }

            {
                /*Do not render if no pending line items */
                pendingLineItems.count() > 0 ?
                    <LineItem lineItemType={LINE_ITEM_STATUS.PENDING}
                              lineItems={this.getSortedLineItems(pendingLineItems)}
                              actionType={this.props.actionType}
                              complaintCode={this.props.complaintCode}
                              onMissingProductCodeIconClick={this.props.onMissingProductCodeIconClick}
                              onDeleteLineItemClick={this.props.onDeleteLineItemClick}
                              onEditLineItemClick={this.props.onEditLineItemClick}
                              onUpdateLineItemClick={this.props.onUpdateLineItemClick}
                              onCancelUpdateLineItemClick={this.props.onCancelUpdateLineItemClick}
                              hideCostInformation={this.props.hideCostInformation}
                              lineItemIdBeingEdited={this.props.lineItemIdBeingEdited}
                              onOpenTiresInfo = {this.props.onOpenTiresInfo}
                              selectedCurrency={this.props.selectedCurrency}
                    />
                    : false
            }

            {
                /*Do not render if no rejected line items */
                rejectedLineItems.count() > 0 ?
                    <LineItem lineItemType={LINE_ITEM_STATUS.REJECTED}
                              lineItems={this.getSortedLineItems(rejectedLineItems)}
                              actionType={this.props.actionType}
                              complaintCode={this.props.complaintCode}
                              onMissingProductCodeIconClick={this.props.onMissingProductCodeIconClick}
                              onDeleteLineItemClick={this.props.onDeleteLineItemClick}
                              onEditLineItemClick={this.props.onEditLineItemClick}
                              onUpdateLineItemClick={this.props.onUpdateLineItemClick}
                              onCancelUpdateLineItemClick={this.props.onCancelUpdateLineItemClick}
                              hideCostInformation={this.props.hideCostInformation}
                              lineItemIdBeingEdited={this.props.lineItemIdBeingEdited}
                              onOpenTiresInfo = {this.props.onOpenTiresInfo}
                              selectedCurrency={this.props.selectedCurrency}
                    />
                    : false
            }

            {
                /* Add line items - Show these button only for Create and Edit PO;
                 hide it for View PO;                      */
                (this.props.actionType.toUpperCase() == 'ADD'
                || this.props.actionType.toUpperCase() == 'EDIT' || this.props.actionType.toUpperCase() === 'DEALERDRAFT')
                && this.props.complaintCode != 'DG99' ?
                    <AddLineItemsLink actionType={this.props.actionType}
                                      complaintCode={this.props.complaintCode}
                                      complaintType={this.props.complaintType}
                                      isServiceComplaint={this.props.isServiceComplaint}
                                      onAddLineItemsClick={this.props.onAddLineItemsClick}
                                      onAddLineItemsMaintenanceClick={this.props.onAddLineItemsMaintenanceClick}
                                      hideAddLineItemsForServices = {this.props.hideAddLineItemsForServices}
                                      anyRemainingAtaCodesToBeAdded = {this.props.anyRemainingAtaCodesToBeAdded}/>
                    : false
            }

        </div>;
    }
}



export default LineItems;