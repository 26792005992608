import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import './FraInspectionResultConfirmationFailOptions.css';
import * as constants from 'constants/App';

class FraInspectionResultConfirmationFailOptions extends React.Component {	
	handleYesClick = () => {
		this.props.onYesClick();
	}

	handleNoClick = () => {
		this.props.onNoClick();
	}


	render() {
		const messages = defineMessages({			
			fra_inspection_confirmation__fail_confirm_title: {
                id: 'fra_inspection_confirmation__fail_confirm_title',
                description: 'FRA Inspection - fail confirmation title',
                defaultMessage: 'FRA inspection'
            }, 
			fra_inspection_confirmation__fail_confirm_question: {
                id: 'fra_inspection_confirmation__fail_confirm_question',
                description: 'FRA Inspection - fail confirmation question',
                defaultMessage: 'Are you going to repair the failed items on this repair invoice?'
			},
			fra_inspection_confirmation__fail_confirm_yes: {
                id: 'fra_inspection_confirmation__fail_confirm_yes',
                description: 'FRA Inspection confirmation - fail yes button text',
                defaultMessage: 'Yes and edit PO'
            },
            fra_inspection_confirmation__fail_confirm_no: {
                id: 'fra_inspection_confirmation__fail_confirm_no',
                description: 'FRA Inspection confirmation - fail No button text',
                defaultMessage: 'No and specify the failed items'
			},						
		});

		return (
			<div className='fra_inspection_confirmation_fail_options'>
				
				<div className='fra_inspection_confirmation_fail_options__title'>
					<FormattedMessage {...messages.fra_inspection_confirmation__fail_confirm_title} />
				</div>

				<div className='fra_inspection_confirmation_fail_options__question'>
					<FormattedMessage {...messages.fra_inspection_confirmation__fail_confirm_question} />
				</div>

				<div className='row'>
					<div 
						className='col-xs-5 fra_inspection_confirmation_fail_options__answer' 
						onClick={this.handleYesClick}
					>
						<img src="/assets/VMS_28button_kill_clear.png" />{' '}
						<FormattedMessage {...messages.fra_inspection_confirmation__fail_confirm_yes} />
					</div>
					<div 
						className='col-xs-7 fra_inspection_confirmation_fail_options__answer text-right' 
						onClick={this.handleNoClick}
					>
						<FormattedMessage {...messages.fra_inspection_confirmation__fail_confirm_no} />
						{' '}<img src="/assets/VMS_33button_go_bblue.png" />
					</div>
				</div>

			</div>
		);
	}
}

export default FraInspectionResultConfirmationFailOptions;