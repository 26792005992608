/* Shared */
export const SEARCH_VEHICLE_FOR_PO_CREATION_CLICKED = 'SEARCH_VEHICLE_FOR_PO_CREATION_CLICKED';
export const FETCH_VEH_DATA_FOR_PO_CREATION_REQUESTED = 'FETCH_VEH_DATA_FOR_PO_CREATION_REQUESTED';
export const FETCH_VEH_DATA_FOR_PO_CREATION_RESOLVED = 'FETCH_VEH_DATA_FOR_PO_CREATION_RESOLVED';
export const FETCH_VEH_DATA_FOR_PO_CREATION_REJECTED = 'FETCH_VEH_DATA_FOR_PO_CREATION_REJECTED';
export const FETCH_DATA_FROM_API_START = 'FETCH_DATA_FROM_API_START';
export const FETCH_DATA_FROM_API_COMPLETE = 'FETCH_DATA_FROM_API_COMPLETE';
export const SELECT_LOCALE = 'SELECT_LOCALE';
export const SAVE_ERROR_INFORMATION = 'SAVE_ERROR_INFORMATION';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const CLEAR_ADD_NEWLINE_ITEM_ERROR_MESSAGES = 'CLEAR_ADD_NEWLINE_ITEM_ERROR_MESSAGES';
export const SAVE_SIGN_IN_DETAILS = 'SAVE_SIGN_IN_DETAILS';
export const SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY';
export const SET_VEHICLE_SEARCH_ERROR_MESSAGE = "SET_VEHICLE_SEARCH_ERROR_MESSAGE";
export const CLEAR_VEHICLE_SEARCH_ERROR_MESSAGE = "CLEAR_VEHICLE_SEARCH_ERROR_MESSAGE";
export const LOAD_VEHICLE_CLIENT_DATA = "LOAD_VEHICLE_CLIENT_DATA";
export const SIGN_OUT = "SIGN_OUT";
export const CLEAR_VEHICLE_SEARCH_VIN = "CLEAR_VEHICLE_SEARCH_VIN";
export const SET_VEHICLE_SEARCH_VIN = "SET_VEHICLE_SEARCH_VIN";
export const HIDE_VEHICLE_SEARCH_ERROR_MESSAGE = "HIDE_VEHICLE_SEARCH_ERROR_MESSAGE";
export const SET_UI_ERROR_MESSAGE = 'SET_UI_ERROR_MESSAGE';
export const CLEAR_UI_ERROR_MESSAGE = 'CLEAR_UI_ERROR_MESSAGE';

/*Login View*/
export const SIGN_IN_CLICKED = 'SIGN_IN_CLICKED';
export const SIGN_IN_DATA_REQUESTED = 'SIGN_IN_DATA_REQUESTED';
export const FETCH_SIGN_IN_DATA_RESOLVED = 'FETCH_SIGN_IN_DATA_RESOLVED';
export const FETCH_SIGN_IN_DATA_REJECTED = 'FETCH_SIGN_IN_DATA_REJECTED';
export const CLEAR_LOGIN_ERROR_MESSAGE = 'CLEAR_LOGIN_ERROR_MESSAGE';
export const SSO_USER_SIGNED_IN = 'SSO_USER_SIGNED_IN';


/* Shop View */
export const SHOP_VIEW_LOADED = 'SHOP_VIEW_LOADED';
export const SHOP_VIEW_UNLOADED = 'SHOP_VIEW_UNLOADED';
export const SHOP_VIEW_DATA_REQUESTED = 'SHOP_VIEW_DATA_REQUESTED';
export const FETCH_SHOP_VIEW_DATA_RESOLVED = 'FETCH_SHOP_VIEW_DATA_RESOLVED';
export const FETCH_SHOP_VIEW_DATA_REJECTED = 'FETCH_SHOP_VIEW_DATA_REJECTED';
export const TOGGLE_SHOW_ALL_APPROVED_POS = 'TOGGLE_SHOW_ALL_APPROVED_POS';
export const TOGGLE_SHOW_ALL_WAITING_FOR_ARI_APPROVAL_POS = 'TOGGLE_SHOW_ALL_WAITING_FOR_ARI_APPROVAL_POS';
export const TOGGLE_SHOW_ALL_WAITING_FOR_DRIVER_VALIDATION_POS = 'TOGGLE_SHOW_ALL_WAITING_FOR_DRIVER_VALIDATION_POS';
export const TOGGLE_SHOW_ALL_WAITING_FOR_CLIENT_APPROVAL_POS = 'TOGGLE_SHOW_ALL_WAITING_FOR_CLIENT_APPROVAL_POS';
export const TOGGLE_SHOW_ALL_VENDOR_ACTION_NEEDED_POS = 'TOGGLE_SHOW_ALL_VENDOR_ACTION_NEEDED_POS';
export const TOGGLE_SHOW_ALL_NEW_WORK_ASSIGNMENT_POS = 'TOGGLE_SHOW_ALL_NEW_WORK_ASSIGNMENT_POS';
export const TOGGLE_SHOW_ALL_WORK_COMPLETED_POS = 'TOGGLE_SHOW_ALL_WORK_COMPLETED_POS';

export const PO_AUTHORIZATION_STATUS = {
    APPROVED: 'approved',
    WAITING_FOR_ARI_APPROVAL: 'waiting_for_ari_approval',
    WAITING_FOR_CLIENT_APPROVAL: 'waiting_for_client_approval',
    WAITING_FOR_VENDOR_APPROVAL: 'waiting_for_vendor_approval',
    WAITING_FOR_DRIVER_VALIDATION: 'waiting_for_driver_validation',
    CANCELLED: 'cancelled',
    APPROVAL_NOT_REQUESTED: 'approval_not_requested', 
    NEW_WORK_ASSIGNMENT: 'new_work_assignment', 
    WORK_COMPLETED:'work_completed'
};
export const PO_PAYMENT_STATUS = {
    PAYMENT_NOT_REQUESTED: 'payment_not_requested',
    PAYMENT_IN_PROGRESS: 'payment_in_progress',
    PAID: 'paid'
};

/* Odometer View*/
export const ODOMETER_SCREEN_LOADED = 'ODOMETER_SCREEN_LOADED';
export const FETCH_DATA_FOR_ODOMETER_SCREEN_REQUESTED = 'FETCH_DATA_FOR_ODOMETER_SCREEN_REQUESTED';
export const FETCH_DATA_FOR_ODOMETER_SCREEN_RESOLVED = 'FETCH_DATA_FOR_ODOMETER_SCREEN_RESOLVED';
export const FETCH_DATA_FOR_ODOMETER_SCREEN_REJECTED = 'FETCH_DATA_FOR_ODOMETER_SCREEN_REJECTED';
export const CREATE_PO_CLICKED = 'CREATE_PO_CLICKED';
export const CREATE_PO_REQUESTED = 'CREATE_PO_REQUESTED';
export const CREATE_PO_REJECTED = 'CREATE_PO_REJECTED';
export const CREATE_PO_RESOLVED = 'CREATE_PO_RESOLVED';
export const SAVE_REFERENCE_NUMBER = 'SAVE_REFERENCE_NUMBER';

export const LOAD_VEHICLE_DATA = "LOAD_VEHICLE_DATA";
export const SAVE_ODOMETER_DATA = "SAVE_ODOMETER_DATA";
export const VEHICLE_AT_SHOP_SELECTION_CHANGE = 'VEHICLE_AT_SHOP_SELECTION_CHANGE';

export const CLEAR_ODOMETER_DATA = "CLEAR_ODOMETER_DATA";
export const ARI_VENDOR = "Holman VENDOR";

/* Complaint View */
export const GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN = 'GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN';
export const GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_REQUESTED = 'GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_REQUESTED';
export const GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_REJECTED = 'GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_REJECTED';
export const GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_RESOLVED = 'GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_RESOLVED';
export const COMPLAINT_SCREEN_LOADED = 'COMPLAINT_SCREEN_LOADED';
export const FETCH_DATA_FOR_COMPLAINT_SCREEN_REQUESTED = 'FETCH_DATA_FOR_COMPLAINT_SCREEN_REQUESTED';
export const FETCH_DATA_FOR_COMPLAINT_SCREEN_RESOLVED = 'FETCH_DATA_FOR_COMPLAINT_SCREEN_RESOLVED';
export const FETCH_DATA_FOR_COMPLAINT_SCREEN_REJECTED = 'FETCH_DATA_FOR_COMPLAINT_SCREEN_REJECTED';
export const SET_DIAGNOSIS_DETAILS = "SET_DIAGNOSIS_DETAILS";
export const CLEAR_DIAGNOSIS_DETAILS = "CLEAR_DIAGNOSIS_DETAILS";
export const CLEAR_SELECTED_SERVICES = 'CLEAR_SELECTED_SERVICES';
export const SAVE_INITIAL_COMPLAINT_DATA = "SAVE_INITIAL_COMPLAINT_DATA";
export const TOGGLE_SERVICE_SELECTION = 'TOGGLE_SERVICE_SELECTION';
export const SERVICE_TYPE = {
    PM: 'PM',
    INSPECTION: 'INSPECTION'
};
export const SERVICE_STATUS = {
    OVERDUE: 'overdue',
    APPROACHING: 'approaching',
    CURRENT: 'current',
    DUE:'due'
};
export const LOAD_ATA_CODES_DATA = 'LOAD_ATA_CODES_DATA';
export const LOAD_SEARCHED_ATA_CODES_DATA = 'LOAD_SEARCHED_ATA_CODES_DATA';
export const LOAD_FREQUENTLY_USED_ATA_CODES = 'LOAD_FREQUENTLY_USED_ATA_CODES';
export const ADD_ATA_CODES_BY_SEARCH_KEY = 'ADD_ATA_CODES_BY_SEARCH_KEY';
export const CLEAR_ATA_CODES_BY_SEARCH_KEY = 'CLEAR_ATA_CODES_BY_SEARCH_KEY';
export const CLEAR_ATA_CODES_BY_SEARCH = 'CLEAR_ATA_CODES_BY_SEARCH';
export const ADD_EDIT_VIEW_ADD_COMPLAINT = 'ADD_EDIT_VIEW_ADD_COMPLAINT';
export const ADD_EDIT_VIEW_DELETE_COMPLAINT = 'ADD_EDIT_VIEW_DELETE_COMPLAINT';
export const ADD_EDIT_VIEW_DELETE_COMPLAINTS = 'ADD_EDIT_VIEW_DELETE_COMPLAINTS';
export const CREATE_OR_EDIT_COMPLAINT_CLICKED = 'CREATE_OR_EDIT_COMPLAINT_CLICKED';
export const CREATE_OR_EDIT_COMPLAINT_REQUESTED = 'CREATE_OR_EDIT_COMPLAINT_REQUESTED';
export const CREATE_OR_EDIT_COMPLAINT_RESOLVED = 'CREATE_OR_EDIT_COMPLAINT_RESOLVED';
export const CREATE_OR_EDIT_COMPLAINT_REJECTED = 'CREATE_OR_EDIT_COMPLAINT_REJECTED';
export const TOGGLE_COMPLAINT_SELECT_MODE = 'TOGGLE_COMPLAINT_SELECT_MODE';
export const RESET_COMPLAINTS = 'RESET_COMPLAINTS';
export const UPDATE_COMPLAINT_CODE = 'UPDATE_COMPLAINT_CODE';
export const CLEAR_COMPLAINT_NOTES = 'CLEAR_COMPLAINT_NOTES';
export const SKIP_COMPLAINT_CLICKED = 'SKIP_COMPLAINT_CLICKED';
export const SKIP_COMPLAINT_REQUESTED = 'SKIP_COMPLAINT_REQUESTED';
export const SKIP_COMPLAINT_RESOLVED = 'SKIP_COMPLAINT_RESOLVED';
export const SKIP_COMPLAINT_REJECTED = 'SKIP_COMPLAINT_REJECTED';
export const COMPLAINT_VIEW_SHOW_ERRORS = 'COMPLAINT_VIEW_SHOW_ERRORS';
export const CLEAR_COMPLAINT_VIEW_SHOW_ERRORS = 'CLEAR_COMPLAINT_VIEW_SHOW_ERRORS';
export const LOAD_RESTRICTED_ATA_CODES_DATA = 'LOAD_RESTRICTED_ATA_CODES_DATA';
export const SAVE_COMPLAINT_NOTES_CLICKED = 'SAVE_COMPLAINT_NOTES_CLICKED';
export const SAVE_COMPLAINT_NOTES_REQUESTED = 'SAVE_COMPLAINT_NOTES_REQUESTED';
export const UPDATE_COMPLAINT_NOTES = 'UPDATE_COMPLAINT_NOTES';
export const SAVE_COMPLAINT_NOTES_RESOLVED = 'SAVE_COMPLAINT_NOTES_RESOLVED';
export const SAVE_COMPLAINT_NOTES_REJECTED = 'SAVE_COMPLAINT_NOTES_REJECTED';

/* PO adjustment */
export const FETCH_ADJUSTMENT_DATA = 'FETCH_ADJUSTMENT_DATA';
export const FETCH_ADJUSTMENT_DATA_REQUESTED = 'FETCH_ADJUSTMENT_DATA_REQUESTED';
export const FETCH_ADJUSTMENT_DATA_RESOLVED = 'FETCH_ADJUSTMENT_DATA_RESOLVED';
export const FETCH_ADJUSTMENT_DATA_REJECTED = 'FETCH_ADJUSTMENT_DATA_REJECTED';
export const ADJUSTMENT_EDIT_LINE_ITEM = 'ADJUSTMENT_EDIT_LINE_ITEM';
export const ADJUSTMENT_UPDATE_LINE_ITEM_CLICKED = 'ADJUSTMENT_UPDATE_LINE_ITEM_CLICKED';
export const ADJUSTMENT_UPDATE_LINE_ITEM_REQUESTED = 'ADJUSTMENT_UPDATE_LINE_ITEM_REQUESTED';
export const ADJUSTMENT_UPDATE_LINE_ITEM_RESOLVED = 'ADJUSTMENT_UPDATE_LINE_ITEM_RESOLVED';
export const ADJUSTMENT_UPDATE_LINE_ITEM_REJECTED = 'ADJUSTMENT_UPDATE_LINE_ITEM_REJECTED';
export const ADJUSTMENT_CANCEL_UPDATE_LINE_ITEM = 'ADJUSTMENT_CANCEL_UPDATE_LINE_ITEM';
export const ADJUSTMENT_ADJUST_LINE_ITEM = 'ADJUSTMENT_ADJUST_LINE_ITEM';
export const ADJUSTMENT_CLOSE_PO = 'ADJUSTMENT_CLOSE_PO';
export const ADJUSTMENT_SAVE_COMPLAINT_NOTES_CLICKED = 'ADJUSTMENT_SAVE_COMPLAINT_NOTES_CLICKED';
export const ADJUSTMENT_SAVE_COMPLAINT_NOTES_REQUESTED = 'ADJUSTMENT_SAVE_COMPLAINT_NOTES_REQUESTED';
export const ADJUSTMENT_SAVE_COMPLAINT_NOTES_RESOLVED = 'ADJUSTMENT_SAVE_COMPLAINT_NOTES_RESOLVED';
export const ADJUSTMENT_SAVE_COMPLAINT_NOTES_REJECTED = 'ADJUSTMENT_SAVE_COMPLAINT_NOTES_REJECTED';
export const ADJUSTMENT_DELETE_LINE_ITEM = 'ADJUSTMENT_DELETE_LINE_ITEM';
export const ADJUSTMENT_DELETE_LINE_ITEM_REQUESTED = 'ADJUSTMENT_DELETE_LINE_ITEM_REQUESTED';
export const ADJUSTMENT_DELETE_LINE_ITEM_RESOLVED = 'ADJUSTMENT_DELETE_LINE_ITEM_RESOLVED';
export const ADJUSTMENT_DELETE_LINE_ITEM_REJECTED = 'ADJUSTMENT_DELETE_LINE_ITEM_REJECTED';
export const ADJUSTMENT_DOCUMENT_CLICK = 'ADJUSTMENT_DOCUMENT_CLICK';

/* Driver validation complaints */
export const DRIVER_VALIDATION_COMPLAINTS_SCREEN_LOADING = 'DRIVER_VALIDATION_COMPLAINTS_SCREEN_LOADING';
export const FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_REQUESTED = 'FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_REQUESTED';
export const FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_REJECTED = 'FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_REJECTED';
export const FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_RESOLVED = 'FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_RESOLVED';

export const DRIVER_VALIDATION_COMPLAINT_SELECTED = 'DRIVER_VALIDATION_COMPLAINT_SELECTED';
export const DRIVER_VALIDATION_COMPLAINT_UNSELECTED = 'DRIVER_VALIDATION_COMPLAINT_UNSELECTED';

export const DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP = 'DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP';
export const DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_REQUESTED = 'DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_REQUESTED';
export const DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_RESOLVED = 'DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_RESOLVED';
export const DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_REJECTED = 'DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_REJECTED';

/* Purchase order View */
export const ADD_SERVICE_LINE_ITEM_REQUESTED = 'ADD_SERVICE_LINE_ITEM_REQUESTED';
export const ADD_SERVICE_LINE_ITEM_RESOLVED = 'ADD_SERVICE_LINE_ITEM_RESOLVED';
export const ADD_SERVICE_LINE_ITEM_REJECTED = 'ADD_SERVICE_LINE_ITEM_REJECTED';
export const ADD_SERVICE_LINE_ITEM_CLICKED = 'ADD_SERVICE_LINE_ITEM_CLICKED';
export const SAVE_LINE_ITEM = 'SAVE_LINE_ITEM';
export const PO_DETAILS_SCREEN_LOADED = 'PO_DETAILS_SCREEN_LOADED';
export const FETCH_PO_DATA_REQUESTED = 'FETCH_PO_DATA_REQUESTED';
export const FETCH_PO_DATA_RESOLVED = 'FETCH_PO_DATA_RESOLVED';
export const FETCH_PO_DATA_REJECTED = 'FETCH_PO_DATA_REJECTED';
export const DELETE_ALL_LINE_ITEMS = "DELETE_ALL_LINE_ITEMS";
export const CLEAR_PURCHASE_ORDER_DETAILS = "CLEAR_PURCHASE_ORDER_DETAILS";
export const LOAD_PURCHASE_ORDER_DATA = "LOAD_PURCHASE_ORDER_DATA";
export const SAVE_SERVICE_DESCRIPTION = "SAVE_SERVICE_DESCRIPTION";
export const SAVE_ESTIMATED_DATE = "SAVE_ESTIMATED_DATE";
export const ESTIMATED_COMPLETION_DATE_CLICKED = 'ESTIMATED_COMPLETION_DATE_CLICKED';
export const ESTIMATED_COMPLETION_DATE_CHANGE_RESOLVED = 'ESTIMATED_COMPLETION_DATE_CHANGE_RESOLVED';
export const ESTIMATED_COMPLETION_DATE_CHANGE_REJECTED = 'ESTIMATED_COMPLETION_DATE_CHANGE_REJECTED';
export const ESTIMATED_COMPLETION_DATE_CHANGE_REQUESTED = 'ESTIMATED_COMPLETION_DATE_CHANGE_REQUESTED';
export const DELETE_LINE_ITEM_CLICKED = 'DELETE_LINE_ITEM_CLICKED';
export const DELETE_LINE_ITEM_REQUESTED = 'DELETE_LINE_ITEM_REQUESTED';
export const DELETE_LINE_ITEM_RESOLVED = 'DELETE_LINE_ITEM_RESOLVED';
export const DELETE_LINE_ITEM_REJECTED = 'DELETE_LINE_ITEM_REJECTED';
export const DELETE_LINE_ITEM = "DELETE_LINE_ITEM";
export const EDIT_LINE_ITEM = "EDIT_LINE_ITEM";
export const UPDATE_LINE_ITEM_CLICKED = 'UPDATE_LINE_ITEM_CLICKED';
export const UPDATE_LINE_ITEM_REQUESTED = 'UPDATE_LINE_ITEM_REQUESTED';
export const UPDATE_LINE_ITEM_REJECTED = 'UPDATE_LINE_ITEM_REJECTED';
export const UPDATE_LINE_ITEM_RESOLVED = 'UPDATE_LINE_ITEM_RESOLVED';
export const UPDATE_LINE_ITEM = "UPDATE_LINE_ITEM";
export const CANCEL_UPDATE_LINE_ITEM = "CANCEL_UPDATE_LINE_ITEM";
export const REQUEST_APPROVAL_CLICKED = 'REQUEST_APPROVAL_CLICKED';
export const REQUEST_APPROVAL_REQUESTED = 'REQUEST_APPROVAL_REQUESTED';
export const REQUEST_APPROVAL_REJECTED = 'REQUEST_APPROVAL_REJECTED';
export const REQUEST_APPROVAL_RESOLVED = 'REQUEST_APPROVAL_RESOLVED';

export const ADD_SERVICE_ATA_CODE_TO_PO_DATA = "ADD_SERVICE_ATA_CODE_TO_PO_DATA";
export const REMOVE_SERVICE_ATA_CODE_DATA = 'REMOVE_SERVICE_ATA_CODE_DATA';
export const PART = 'P';
export const LABOR = 'L';
export const ADD_SERVICE_ITEM = 'ADD_SERVICE_ITEM';
export const REMOVE_SERVICE_ITEM = 'REMOVE_SERVICE_ITEM';

export const LINE_ITEM_STATUS = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    PENDING: 'pending',
    ADJUSTED: 'adjusted',
    WAITING_FOR_VENDOR_NOTES: 'waiting_for_vendor_notes'
};
export const VENDOR_ACKNOWLEDGEMENT_STATUS = {
    APPROVED: 'approved',
    PENDING: 'pending',
    NO_ACTION_REQUIRED: 'no_action_required' ,
    WAITING_FOR_VENDOR_NOTES: 'waiting_for_vendor_notes'   
};
export const LINE_ITEM_TYPE = {
    PART: 'parts',
    LABOR: 'labor',
    PM: 'preventive_maint',
    TAX: 'tax',
    OTHER: 'other',
    RENTAL: 'rental',
    CANADA_SALES_TAX: 'canada_sales_tax',
    ROADSIDE: 'roadside',
    ON_SITE_PM: 'on_site_pm',
    INSPECTION: 'inspection',
    MENU_PRICING: 'menu_pricing',
    FUEL: 'fuel',
    SUBLET: 'sublet',
    CORE: 'core',
    PM_TEXT: 'PM'
};

export const SERVICE_LINE_ITEM_DETAILS_TYPE = {
    PART: 'parts',
    LABOR: 'labor',
    TOTAL: 'total'
};

export const TOGGLE_SHOW_MODAL_ADD_SERVICE_LINE_ITEMS = 'TOGGLE_SHOW_MODAL_ADD_SERVICE_LINE_ITEMS';
export const TOGGLE_SHOW_MODAL_ADD_PO_LINE_ITEMS = 'TOGGLE_SHOW_MODAL_ADD_PO_LINE_ITEMS';
export const SHOW_MODAL_ADD_SERVICE_LINE_ITEMS = 'SHOW_MODAL_ADD_SERVICE_LINE_ITEMS';
export const UPDATE_ATA_CODE = 'UPDATE_ATA_CODE';
export const UPDATE_SELECTED_CORRECTION_TYPE = 'UPDATE_SELECTED_CORRECTION_TYPE';
export const ADD_CORRECTION_DETAILS = 'ADD_CORRECTION_DETAILS';
export const CORRECTION_DETAILS_TYPE = {
    QTY: 'QTY',
    COST: 'COST',
    HOURS: 'HOURS',
    RATE: 'RATE',
    WARRANTY_MONTHS: 'WARRANTY_MONTHS',
    WARRANTY_MILES: 'WARRANTY_MILES',
    WARRANTY_HOURS: 'WARRANTY_HOURS',
}
export const ADD_NEW_LINE_ITEM_CLICKED = 'ADD_NEW_LINE_ITEM_CLICKED';
export const ADD_NEW_LINE_ITEM_REQUESTED = 'ADD_NEW_LINE_ITEM_REQUESTED';
export const ADD_NEW_LINE_ITEM_RESOLVED = 'ADD_NEW_LINE_ITEM_RESOLVED';
export const ADD_NEW_LINE_ITEM_REJECTED = 'ADD_NEW_LINE_ITEM_REJECTED';
export const ADD_NEW_LINE_ITEM_TO_PO_DATA = 'ADD_NEW_LINE_ITEM_TO_PO_DATA';
export const TOGGLE_SHOW_MODAL_ADD_NEW_LINE_ITEMS = 'TOGGLE_SHOW_MODAL_ADD_NEW_LINE_ITEMS';
export const ADD_COMPLAINT_CODE = 'ADD_COMPLAINT_CODE';
export const RESET_ADD_NEW_LINE_ITEM_DATA = 'RESET_ADD_NEW_LINE_ITEM_DATA';
export const UPDATE_ATA_CODE_TYPE = {
    SELECTED_ATA_CODE: 'SELECTED_ATA_CODE',
    SEARCHED_ATA_CODE: 'SEARCHED_ATA_CODE'
}
export const AUTO_COMPLETE_ATA_CODE_DESCRIPTION = 'AUTO_COMPLETE_ATA_CODE_DESCRIPTION';
export const DUE_SERVICES_DATA_LOADED = 'DUE_SERVICES_DATA_LOADED';
export const FETCH_DATA_FOR_DUE_SERVICES_REQUESTED = 'FETCH_DATA_FOR_DUE_SERVICES_REQUESTED';
export const FETCH_DATA_FOR_DUE_SERVICES_RESOLVED = 'FETCH_DATA_FOR_DUE_SERVICES_RESOLVED';
export const FETCH_DATA_FOR_DUE_SERVICES_REJECTED = 'FETCH_DATA_FOR_DUE_SERVICES_REJECTED';
export const CLEAR_ADD_EDIT_VIEW_SERVICES = 'CLEAR_ADD_EDIT_VIEW_SERVICES';
export const CLEAR_FIELDS_AFTER_SAVE_ADD_NEW_LINE_ITEM_DATA = 'CLEAR_FIELDS_AFTER_SAVE_ADD_NEW_LINE_ITEM_DATA';
export const SAVE_RECENT_ITEMS_ADDED_TO_PO = 'SAVE_RECENT_ITEMS_ADDED_TO_PO';
export const REQUEST_APPROVAL_OR_CLOSEPO_CLICKED = 'REQUEST_APPROVAL_OR_CLOSEPO_CLICKED';
export const UPDATE_IS_ESTIMATED_COMPLETION_DATE_VALID = 'UPDATE_IS_ESTIMATED_COMPLETION_DATE_VALID';
export const CLEAR_PURCHASE_ORDER = 'CLEAR_PURCHASE_ORDER';

/* Close PO */
export const CLOSE_PO_SCREEN_LOADED = 'CLOSE_PO_SCREEN_LOADED';
export const FETCH_CLOSE_PO_DATA_REQUESTED = 'FETCH_CLOSE_PO_DATA_REQUESTED';
export const FETCH_CLOSE_PO_DATA_RESOLVED = 'FETCH_CLOSE_PO_DATA_RESOLVED';
export const FETCH_CLOSE_PO_DATA_REJECTED = 'FETCH_CLOSE_PO_DATA_REJECTED';
export const CLOSE_PO_CLICKED = 'CLOSE_PO_CLICKED';
export const CLOSE_PO_REQUESTED = 'CLOSE_PO_REQUESTED';
export const CLOSE_PO_RESOLVED = 'CLOSE_PO_RESOLVED';
export const CLOSE_PO_REJECTED = 'CLOSE_PO_REJECTED';
export const CLOSE_PO_CANCELLED = 'CLOSE_PO_CANCELLED';
export const SAVE_INVOICE_DETAILS = 'SAVE_INVOICE_DETAILS';
export const RESET_INVOICE_DETAILS = 'RESET_INVOICE_DETAILS';
export const POP_OVER_DISPLAY_SHOW_INVOICE = 'POP_OVER_DISPLAY_SHOW_INVOICE';
export const POP_OVER_DISPLAY_SHOW_INVOICE_DATE = 'POP_OVER_DISPLAY_SHOW_INVOICE_DATE';
export const POP_OVER_DISPLAY_SHOW_INVOICE_NAME = 'POP_OVER_DISPLAY_SHOW_INVOICE_NAME';
export const POP_OVER_DISPLAY_SHOW_REPAIR_COMPLETE_DATE = 'POP_OVER_DISPLAY_SHOW_REPAIR_COMPLETE_DATE';
export const POP_OVER_DISPLAY_SHOW_REPAIR_START_DATE = 'POP_OVER_DISPLAY_SHOW_REPAIR_START_DATE';
export const POP_OVER_DISPLAY_SHOW_PAYMENT_CREDIT = 'POP_OVER_DISPLAY_SHOW_PAYMENT_CREDIT';
export const POP_OVER_DISPLAY_SHOW_WORK_COMPLETE = 'POP_OVER_DISPLAY_SHOW_WORK_COMPLETE';
export const POP_OVER_DISPLAY_SHOW_TAX = 'POP_OVER_DISPLAY_SHOW_TAX';
export const POP_OVER_DISPLAY_SHOW_TAX_CANADA = 'POP_OVER_DISPLAY_SHOW_TAX_CANADA';
export const TAX_ERROR_MESSAGE = 'TAX_ERROR_MESSAGE';
export const POP_OVER_DISPLAY_SHOW_ODOMETER = 'POP_OVER_DISPLAY_SHOW_ODOMETER';
export const POP_OVER_DISPLAY_SHOW_ENGINE_HOURS = 'POP_OVER_DISPLAY_SHOW_ENGINE_HOURS';
export const SHOW_CLOSE_PO_ODOMETER_WARNING = 'SHOW_CLOSE_PO_ODOMETER_WARNING';
export const SHOW_CLOSE_PO_ENGINE_HOURS_WARNING = 'SHOW_CLOSE_PO_ENGINE_HOURS_WARNING';
export const POP_OVER_DISPLAY_SHOW_STANDARD = 'POP_OVER_DISPLAY_SHOW_STANDARD';
export const CLOSE_PO__HIDE_ALL_POPOVERS = 'CLOSE_PO__HIDE_ALL_POPOVERS';

/* DOT inspection */
export const DOT_INSPECTION__DISPLAY = 'DOT_INSPECTION__DISPLAY';
export const DOT_INSPECTION__RESET = 'DOT_INSPECTION__RESET';
export const DOT_INSPECTION__RESET_ERROR = 'DOT_INSPECTION__RESET_ERROR';
export const DOT_INSPECTION__SET_FORM_DATA = 'DOT_INSPECTION__SET_FORM_DATA';
export const DOT_INSPECTION__SAVE_CONFIRMATION = 'DOT_INSPECTION__SAVE_CONFIRMATION';
export const DOT_INSPECTION__SAVE_CONFIRMATION_REQUESTED = 'DOT_INSPECTION__SAVE_CONFIRMATION_REQUESTED';
export const DOT_INSPECTION__SAVE_CONFIRMATION_RESOLVED = 'DOT_INSPECTION__SAVE_CONFIRMATION_RESOLVED';
export const DOT_INSPECTION__SAVE_CONFIRMATION_REJECTED = 'DOT_INSPECTION__SAVE_CONFIRMATION_REJECTED';
export const DOT_INSPECTION__SAVE_FORM = 'DOT_INSPECTION__SAVE_FORM';
export const DOT_INSPECTION__SAVE_FORM_REQUESTED = 'DOT_INSPECTION__SAVE_FORM_REQUESTED';
export const DOT_INSPECTION__SAVE_FORM_RESOLVED = 'DOT_INSPECTION__SAVE_FORM_RESOLVED';
export const DOT_INSPECTION__SAVE_FORM_REJECTED = 'DOT_INSPECTION__SAVE_FORM_REJECTED';
export const DOT_INSPECTION__SET_VALIDATION_MESSAGE = 'DOT_INSPECTION__SET_VALIDATION_MESSAGE';
export const DOT_INSPECTION__RESET_VALIDATION_MESSAGES = 'DOT_INSPECTION__RESET_VALIDATION_MESSAGES';
export const DOT_INSPECTION__SET_COMPONENT_DETAILS = 'DOT_INSPECTION__SET_COMPONENT_DETAILS';
export const DOT_INSPECTION__SET_ALL_COMPONENT_OK = 'DOT_INSPECTION__SET_ALL_COMPONENT_OK';
export const DOT_INSPECTION__SET_DEFAULT_VALUES = 'DOT_INSPECTION__SET_DEFAULT_VALUES';
export const DOT_INSPECTION__SKIP_FAIL_OPTIONS = 'DOT_INSPECTION__SKIP_FAIL_OPTIONS';

/* BIT inspection */
export const BIT_INSPECTION__DISPLAY = 'BIT_INSPECTION__DISPLAY';
export const BIT_INSPECTION__RESET = 'BIT_INSPECTION__RESET';
export const BIT_INSPECTION__RESET_ERROR = 'BIT_INSPECTION__RESET_ERROR';
export const BIT_INSPECTION__SET_FORM_DATA = 'BIT_INSPECTION__SET_FORM_DATA';
export const BIT_INSPECTION__SAVE_CONFIRMATION = 'BIT_INSPECTION__SAVE_CONFIRMATION';
export const BIT_INSPECTION__SAVE_CONFIRMATION_REQUESTED = 'BIT_INSPECTION__SAVE_CONFIRMATION_REQUESTED';
export const BIT_INSPECTION__SAVE_CONFIRMATION_RESOLVED = 'BIT_INSPECTION__SAVE_CONFIRMATION_RESOLVED';
export const BIT_INSPECTION__SAVE_CONFIRMATION_REJECTED = 'BIT_INSPECTION__SAVE_CONFIRMATION_REJECTED';
export const BIT_INSPECTION__SAVE_FORM = 'BIT_INSPECTION__SAVE_FORM';
export const BIT_INSPECTION__SAVE_FORM_REQUESTED = 'BIT_INSPECTION__SAVE_FORM_REQUESTED';
export const BIT_INSPECTION__SAVE_FORM_RESOLVED = 'BIT_INSPECTION__SAVE_FORM_RESOLVED';
export const BIT_INSPECTION__SAVE_FORM_REJECTED = 'BIT_INSPECTION__SAVE_FORM_REJECTED';
export const BIT_INSPECTION__SET_VALIDATION_MESSAGE = 'BIT_INSPECTION__SET_VALIDATION_MESSAGE';
export const BIT_INSPECTION__RESET_VALIDATION_MESSAGES = 'BIT_INSPECTION__RESET_VALIDATION_MESSAGES';
export const BIT_INSPECTION__SET_COMPONENT_DETAILS = 'BIT_INSPECTION__SET_COMPONENT_DETAILS';
export const BIT_INSPECTION__SET_DEFAULT_VALUES = 'BIT_INSPECTION__SET_DEFAULT_VALUES';
export const BIT_INSPECTION__WINDOW_SHOWN = 'BIT_INSPECTION__WINDOW_SHOWN';
export const BIT_INSPECTION__SKIP_FAIL_OPTIONS = 'BIT_INSPECTION__SKIP_FAIL_OPTIONS';

/* FRA inspection */
export const FRA_INSPECTION__DISPLAY = 'FRA_INSPECTION__DISPLAY';
export const FRA_INSPECTION__RESET = 'FRA_INSPECTION__RESET';
export const FRA_INSPECTION__RESET_ERROR = 'FRA_INSPECTION__RESET_ERROR';
export const FRA_INSPECTION__SET_FORM_DATA = 'FRA_INSPECTION__SET_FORM_DATA';
export const FRA_INSPECTION__SAVE_CONFIRMATION = 'FRA_INSPECTION__SAVE_CONFIRMATION';
export const FRA_INSPECTION__SAVE_CONFIRMATION_REQUESTED = 'FRA_INSPECTION__SAVE_CONFIRMATION_REQUESTED';
export const FRA_INSPECTION__SAVE_CONFIRMATION_RESOLVED = 'FRA_INSPECTION__SAVE_CONFIRMATION_RESOLVED';
export const FRA_INSPECTION__SAVE_CONFIRMATION_REJECTED = 'FRA_INSPECTION__SAVE_CONFIRMATION_REJECTED';
export const FRA_INSPECTION__SAVE_FORM = 'FRA_INSPECTION__SAVE_FORM';
export const FRA_INSPECTION__SAVE_FORM_REQUESTED = 'FRA_INSPECTION__SAVE_FORM_REQUESTED';
export const FRA_INSPECTION__SAVE_FORM_RESOLVED = 'FRA_INSPECTION__SAVE_FORM_RESOLVED';
export const FRA_INSPECTION__SAVE_FORM_REJECTED = 'FRA_INSPECTION__SAVE_FORM_REJECTED';
export const FRA_INSPECTION__SET_VALIDATION_MESSAGE = 'FRA_INSPECTION__SET_VALIDATION_MESSAGE';
export const FRA_INSPECTION__RESET_VALIDATION_MESSAGES = 'FRA_INSPECTION__RESET_VALIDATION_MESSAGES';
export const FRA_INSPECTION__SET_COMPONENT_DETAILS = 'FRA_INSPECTION__SET_COMPONENT_DETAILS';
export const FRA_INSPECTION__SET_DEFAULT_VALUES = 'FRA_INSPECTION__SET_DEFAULT_VALUES';
export const FRA_INSPECTION__WINDOW_SHOWN = 'FRA_INSPECTION__WINDOW_SHOWN';
export const FRA_INSPECTION__SKIP_FAIL_OPTIONS = 'FRA_INSPECTION__SKIP_FAIL_OPTIONS';

/* Action Required */
export const ACTION_REQUIRED_SCREEN_LOADED = 'ACTION_REQUIRED_SCREEN_LOADED';
export const FETCH_ACTION_REQUIRED_DATA_REQUESTED = 'FETCH_ACTION_REQUIRED_DATA_REQUESTED';
export const FETCH_ACTION_REQUIRED_DATA_RESOLVED = 'FETCH_ACTION_REQUIRED_DATA_RESOLVED';
export const FETCH_ACTION_REQUIRED_DATA_REJECTED = 'FETCH_ACTION_REQUIRED_DATA_REJECTED';
export const ACKNOWLEDGE_LINE_ITEMS = 'ACKNOWLEDGE_LINE_ITEMS';
export const ACKNOWLEDGE_LINE_ITEMS_REQUESTED = 'ACKNOWLEDGE_LINE_ITEMS_REQUESTED';
export const ACKNOWLEDGE_LINE_ITEMS_RESOLVED = 'ACKNOWLEDGE_LINE_ITEMS_RESOLVED';
export const ACKNOWLEDGE_LINE_ITEMS_REJECTED = 'ACKNOWLEDGE_LINE_ITEMS_REJECTED';
export const ACKNOWLEDGE_LINES = 'ACKNOWLEDGE_LINES';
export const ACTION_REQUIRED_CANCEL_PO_CLICKED = 'ACTION_REQUIRED_CANCEL_PO_CLICKED';
export const ACTION_REQUIRED_CANCEL_PO_REQUESTED = 'ACTION_REQUIRED_CANCEL_PO_REQUESTED';
export const ACTION_REQUIRED_CANCEL_PO_RESOLVED = 'ACTION_REQUIRED_CANCEL_PO_RESOLVED';
export const ACTION_REQUIRED_CANCEL_PO_REJECTED = 'ACTION_REQUIRED_CANCEL_PO_REJECTED';
export const REJECT_PO_ADJUSTMENTS = 'REJECT_PO_ADJUSTMENTS';
export const REJECT_PO_ADJUSTMENTS_REQUESTED='REJECT_PO_ADJUSTMENTS_REQUESTED';
export const ACTION_REQUIRED_SUBMIT_VENDOR_NOTES = 'ACTION_REQUIRED_SUBMIT_VENDOR_NOTES';
export const ACTION_REQUIRED_SUBMIT_CLIENT_VENDOR_NOTES_REJECTED = 'ACTION_REQUIRED_SUBMIT_CLIENT_VENDOR_NOTES_REJECTED';

export const INVOICE_FIELD_TYPE = {
    INVOICE_NO: 'INVOICE_NO',
    INVOICE_DATE: 'INVOICE_DATE',
    NAME: 'NAME',
    REPAIR_COMPLETION_DATE: 'REPAIR_COMPLETION_DATE',
    TAX_AMOUNT: 'TAX_AMOUNT',
    TAX_REQUIRED: 'TAX_REQUIRED',
    IS_TAX_EXEMPTED: 'IS_TAX_EXEMPTED',
    IS_GST_SHOW: 'IS_GST_SHOW',
    IS_CREDIT_PAYMENT: 'IS_CREDIT_PAYMENT',
    IS_WORK_COMPLETED: 'IS_WORK_COMPLETED',
    EXEMPTED_REASON: 'EXEMPTED_REASON',
    TOTAL_AMOUNT: 'TOTAL_AMOUNT',
    ODOMETER: 'ODOMETER',
    ENGINE_HOURS: 'ENGINE_HOURS',
    IS_INVOICE_REPRODICUBLE: 'IS_INVOICE_REPRODICUBLE',
    REF_NUMBER:'REF_NUMBER',
    REPAIR_START_DATE: 'REPAIR_START_DATE',
    CLIENT_REF_NO: 'CLIENT_REF_NO'
};

export const ACTION_TYPE = {
    CLOSE: 'CLOSE'
};

/* Add New line item constants */
export const CORRECTION_TYPE = {
    REPAIR: 'REPAIR',
    REPLACE: 'REPLACE',
    ADJUST: 'ADJUST',
    PM: 'PM'
};
export const HIDE_ATA_SEARCH_RESULTS_CONTAINER = 'HIDE_ATA_SEARCH_RESULTS_CONTAINER';
export const SHOW_ATA_SEARCH_RESULTS_CONTAINER = 'SHOW_ATA_SEARCH_RESULTS_CONTAINER';
export const CLEAR_ADD_LINE_ITEMS_SERVER_DATA = 'CLEAR_ADD_LINE_ITEMS_SERVER_DATA';
export const SEARCHED_ATA_CODES_DATA_LOADED = 'SEARCHED_ATA_CODES_DATA_LOADED';
export const LOAD_SEARCHED_ATA_CODES_DATA_REQUESTED = 'LOAD_SEARCHED_ATA_CODES_DATA_REQUESTED';
export const LOAD_SEARCHED_ATA_CODES_DATA_RESOLVED = 'LOAD_SEARCHED_ATA_CODES_DATA_RESOLVED';
export const LOAD_SEARCHED_ATA_CODES_DATA_REJECTED = 'LOAD_SEARCHED_ATA_CODES_DATA_REJECTED';
export const CORRECTION_TYPE_PLACE_HOLDER = 'CORRECTION_TYPE_PLACE_HOLDER';
export const SHOW_ATA_SEARCH_TOOL_TIP = 'SHOW_ATA_SEARCH_TOOL_TIP';
export const CAN_SHOW_ATA_SEARCH_TOOL_TIP = 'CAN_SHOW_ATA_SEARCH_TOOL_TIP';
export const TIRE_DETAILS = {
    TIRE_WIDTH: 'TIRE_WIDTH',
    ASPECT_RATIO: 'ASPECT_RATIO',
    RIM_DIAMETER: 'RIM_DIAMETER',
    MANUFACTURER: 'MANUFACTURER',
    MODEL: 'MODEL',
    REASON: 'REASON'
}
export const UPDATE_TIRE_DETAILS = 'UPDATE_TIRE_DETAILS';
export const UPDATE_TIRE_DETAILS_VALIDATION_ERRS = 'UPDATE_TIRE_DETAILS_VALIDATION_ERRS';
export const REMOVE_TIRE_DETAILS_VALIDATION_ERROR = 'REMOVE_TIRE_DETAILS_VALIDATION_ERROR';
export const SAVE_TIRE_DETAILS = 'SAVE_TIRE_DETAILS';
export const FETCH_TIRE_SPECIFICATIONS = 'FETCH_TIRE_SPECIFICATIONS'
export const SHOULD_SHOW_LINK_TO_TIRE_SPECIFICATION = 'SHOULD_SHOW_LINK_TO_TIRE_SPECIFICATION'
export const SHOW_TIRE_SPECIFICATION = 'SHOW_TIRE_SPECIFICATION'
export const UPDATE_AXLES = 'UPDATE_AXLES'
export const UPDATE_TREAD_DEPTH_MEASUREMENT_UNIT = 'UPDATE_TREAD_DEPTH_MEASUREMENT_UNIT';
export const SAVE_TREAD_DEPTH_MEASUREMENT_UNIT = 'SAVE_TREAD_DEPTH_MEASUREMENT_UNIT';
export const SAVE_NEW_LINE_ITEM_WITH_TIRE_SPECS = 'ADD_NEW_LINE_ITEM_WITH_TIRE_SPECS';
export const UPDATE_TIRE_SPECIFICATION_IDS = 'UPDATE_TIRE_SPECIFICATION_IDS';
export const SAVE_TIRE_SPECIFICATIONS_REQUESTED = 'SAVE_TIRE_SPECIFICATIONS_REQUESTED';
export const SAVE_TIRE_SPECIFICATIONS_REJECTED = 'SAVE_TIRE_SPECIFICATIONS_REJECTED';
export const SAVE_TIRE_SPECIFICATIONS_RESOLVED = 'SAVE_TIRE_SPECIFICATIONS_RESOLVED';
export const FETCH_TIRE_SPECIFICATIONS_REQUESTED = 'FETCH_TIRE_SPECIFICATIONS_REQUESTED';
export const FETCH_TIRE_SPECIFICATIONS_RESOLVED = 'FETCH_TIRE_SPECIFICATIONS_RESOLVED';
export const FETCH_TIRE_SPECIFICATIONS_REJECTED = 'FETCH_TIRE_SPECIFICATIONS_REJECTED';
export const LOAD_TIRE_SPECIFICATION = 'LOAD_TIRE_SPECIFICATION';
export const ADD_NEW_AXLE = 'ADD_NEW_AXLE';
export const ADD_DUMMY_AXLE = 'ADD_DUMMY_AXLE';
export const ADD_NEW_WHEEL = 'ADD_NEW_WHEEL';
export const REMOVE_AXLE = 'REMOVE_AXLE';
export const REMOVE_WHEEL = 'REMOVE_WHEEL';
export const UPDATE_TREAD_DEPTH = 'UPDATE_TREAD_DEPTH';
export const NEW_TREAD_DEPTH = 'NEW';
export const OLD_TREAD_DEPTH = 'OLD';
export const UPDATE_TIRE_SELECTION = 'UPDATE_TIRE_SELECTION';
export const LOAD_COPY_TIRE_SPECIFICATION = 'LOAD_COPY_TIRE_SPECIFICATION';
export const STATE_OF_WHEEL = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    DELETE: 'DELETE'
}

export const TIRE_POSITIONS = {
    LEFT_INNER: 'left_inner',
    LEFT_OUTER: 'left_outer',
    RIGHT_INNER: 'right_inner',
    RIGHT_OUTER: 'right_outer',
    SPARE: 'spare',
    LEFT_FRONT: 'left_front',
    RIGHT_FRONT: 'right_front'
}
export const EVENT_TYPE = {
    EXISTING: 'existing',
    REPLACED: 'replaced',
    REPAIRED: 'repaired'
}
export const RESET_TIRE_SPECIFICATIONS = 'RESET_TIRE_SPECIFICATIONS';
export const SAVE_LATEST_LINE_ITEM_ID = 'SAVE_LATEST_LINE_ITEM_ID';
export const CLEAR_LINE_ITEM_ID_FOR_TIRES = 'CLEAR_LINE_ITEM_ID_FOR_TIRES';
export const SAVE_LINE_ITEM_FOR_TIRES = 'SAVE_LINE_ITEM_FOR_TIRES';
export const CLEAR_LINE_ITEM_ID_MISSING_PRODUCT_CODE = 'CLEAR_LINE_ITEM_ID_MISSING_PRODUCT_CODE';
export const SAVE_LINE_ITEM_ID_MISSING_PRODUCT_CODE = 'SAVE_LINE_ITEM_ID_MISSING_PRODUCT_CODE';
export const UPDATE_TIRE_DETAILS_ALL = 'UPDATE_TIRE_DETAILS_ALL';
export const RESTOR_TIRE_SPECITIFICATIONS = 'RESTOR_TIRE_SPECITIFICATIONS';
export const ADD_LINE_ITEM_QTY_MISMATCH_ERR = 'ADD_LINE_ITEM_QTY_MISMATCH_ERR';
export const REMOVE_LINE_ITEM_QTY_MISMATCH_ERR = 'REMOVE_LINE_ITEM_QTY_MISMATCH_ERR';

/* Search PO VIN8 */
export const SEARCH_FOR_POS_CLICKED = 'SEARCH_FOR_POS_CLICKED';
export const SEARCH_FOR_PO_BY_VIN8_AND_VEHICLEID = 'SEARCH_FOR_PO_BY_VIN8_AND_VEHICLEID';
export const SEARCH_VENDOR_POS_LOADED = 'SEARCH_VENDOR_POS_LOADED';
export const SEARCH_FOR_POS_BY_REQUESTED = 'SEARCH_FOR_POS_BY_REQUESTED';
export const SEARCH_FOR_POS_BY_RESOLVED = 'SEARCH_FOR_POS_BY_RESOLVED';
export const FETCH_VEH_DATA_FOR_SEARCH_VENDOR_POS_REJECTED = 'FETCH_VEH_DATA_FOR_SEARCH_VENDOR_POS_REJECTED';
export const FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_REQUESTED = 'FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_REQUESTED';
export const FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED = 'FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED';
export const FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_REJECTED = 'FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_REJECTED';
export const TOGGLE_SHOW_ALL_SERVICE_IN_PROGRESS_POS = 'TOGGLE_SHOW_ALL_SERVICE_IN_PROGRESS_POS';
export const TOGGLE_SHOW_ALL_PAYMENT_IN_PROGRESS_POS = 'TOGGLE_SHOW_ALL_PAYMENT_IN_PROGRESS_POS';
export const TOGGLE_SHOW_ALL_PAID_POS = 'TOGGLE_SHOW_ALL_PAID_POS';
export const SET_SEARCH_PO_VIN8_ERROR_MESSAGE = 'SET_SEARCH_PO_VIN8_ERROR_MESSAGE';
export const HIDE_SEARCH_PO_VIN8_ERROR_MESSAGE = 'HIDE_SEARCH_PO_VIN8_ERROR_MESSAGE';
export const SAVE_MULTI_CLIENT_PO_DATA_FOR_PO_CREATION_DATA = 'SAVE_MULTI_CLIENT_PO_DATA_FOR_PO_CREATION_DATA';
export const CLEAR_SAVE_MULTI_CLIENT_PO_DATA_FOR_PO_CREATION_DATA = 'CLEAR_SAVE_MULTI_CLIENT_PO_DATA_FOR_PO_CREATION_DATA';

/* Repair History*/
export const FETCH_DATA_FOR_REPAIR_HISTORY_SCREEN_REQUESTED = 'FETCH_DATA_FOR_REPAIR_HISTORY_SCREEN_REQUESTED';
export const FETCH_DATA_FOR_REPAIR_HISTORY_SCREEN_RESOLVED = 'FETCH_DATA_FOR_REPAIR_HISTORY_SCREEN_RESOLVED';
export const FETCH_DATA_FOR_REPAIR_HISTORY_SCREEN_REJECTED = 'FETCH_DATA_FOR_REPAIR_HISTORY_SCREEN_REJECTED';
export const REPAIR_HISTORY_SCREEN_LOADED = 'REPAIR_HISTORY_SCREEN_LOADED';
export const REPAIR_HISTORY_SCREEN_UNLOADED = 'REPAIR_HISTORY_SCREEN_UNLOADED';
export const REPAIR_HISTORY_SCREEN_FETCH_MORE_POS_CLICKED = 'REPAIR_HISTORY_SCREEN_FETCH_MORE_POS_CLICKED';
export const SAVE_RECENT_SERVICE_DATA = "SAVE_RECENT_SERVICE_DATA";
export const CLEAR_REPAIR_HISTORY = 'CLEAR_REPAIR_HISTORY';

/* Help FAQ */
export const TOGGLE_HELP_FAQ_TITLE = 'TOGGLE_HELP_FAQ_TITLE';
export const TOGGLE_HELP_FAQ_QUESTION = 'TOGGLE_HELP_FAQ_QUESTION';
export const CHANGE_FAQ_LOCALE = 'CHANGE_FAQ_LOCALE';

/*Contact/Feedback Form*/
export const SAVE_FEEDBACK_FORM = 'SAVE_FEEDBACK_FORM';
export const SAVE_FEEDBACK_FORM_QUESTIONS_SELECTION = 'SAVE_FEEDBACK_FORM_QUESTIONS_SELECTION';
export const FETCH_DATA_FOR_FEEDBACK_FORM_REQUEST = 'FETCH_DATA_FOR_FEEDBACK_FORM_REQUEST';
export const SEND_CLICK_FEEDBACK_FORM_RESOLVED = 'SEND_CLICK_FEEDBACK_FORM_RESOLVED';
export const SEND_CLICK_FEEDBACK_FORM_REJECTED = 'SEND_CLICK_FEEDBACK_FORM_REJECTED';
export const SEND_FEEDBACK_FORM_CLICKED = 'SEND_FEEDBACK_FORM_CLICKED';
export const SEND_FEEDBACK_FORM_APPROVED = 'SEND_FEEDBACK_FORM_APPROVED';
export const SEND_FEEDBACK_FORM_MOUNT = 'SEND_FEEDBACK_FORM_MOUNT';

export const GENERIC_ERROR_MESSAGE = 'GENERIC_ERROR_MESSAGE';// 'An unexpected error has occurred. Please try again later or Click here to report an issue.';

/*Client Parameters*/
export const SAVE_CLIENT_PARAMETERS = 'SAVE_PARAMETERS';
export const FETCH_DATA_FOR_CLIENT_PARAMETERS = 'FETCH_DATA_FOR_CLIENT_PARAMETERS';
export const SEND_CLICK_CLIENT_PARAMETERS_RESOLVED = 'SEND_CLICK_CLIENT_PARAMETERS_RESOLVED';
export const SEND_CLICK_CLIENT_PARAMETERS_REJECTED = 'SEND_CLICK_CLIENT_PARAMETERS_REJECTED';
export const CLIENT_PARAMETERS_SCREEN_LOADED = 'CLIENT_PARAMETERS_SCREEN_LOADED';
export const CLIENT_PARAMETERS_SCREEN_UNLOADED = 'CLIENT_PARAMETERS_SCREEN_UNLOADED';

/*Ata Parameters*/
export const FETCH_DATA_FOR_ATA_PARAMETERS = 'FETCH_DATA_FOR_ATA_PARAMETERS';
export const SAVE_ATA_PARAMETERS = 'SAVE_ATA_PARAMETERS';
export const SEND_CLICK_ATA_PARAMETERS_RESOLVED = 'SEND_CLICK_ATA_PARAMETERS_RESOLVED';
export const SEND_CLICK_ATA_PARAMETERS_REJECTED = 'SEND_CLICK_ATA_PARAMETERS_REJECTED';
export const ATA_PARAMETERS_SCREEN_LOADED = 'ATA_PARAMETERS_SCREEN_LOADED';

/*Ata Pm Parameters*/
export const FETCH_DATA_FOR_ATA_PM_PARAMETERS = 'FETCH_DATA_FOR_ATA_PM_PARAMETERS';
export const SAVE_ATA_PM_PARAMETERS = 'SAVE_ATA_PM_PARAMETERS';
export const SEND_CLICK_ATA_PM_PARAMETERS_RESOLVED = 'SEND_CLICK_ATA_PM_PARAMETERS_RESOLVED';
export const SEND_CLICK_ATA_PM_PARAMETERS_REJECTED = 'SEND_CLICK_ATA_PM_PARAMETERS_REJECTED';
export const ATA_PM_PARAMETERS_SCREEN_LOADED = 'ATA_PM_PARAMETERS_SCREEN_LOADED';

/*PM Information*/
export const FETCH_DATA_FOR_PM_SHEETS_RESOLVED = 'FETCH_DATA_FOR_PM_SHEETS_RESOLVED';
export const FETCH_DATA_FOR_PM_SHEETS_REJECTED = 'FETCH_DATA_FOR_PM_SHEETS_REJECTED';
export const PM_SHEETS_INFORMATION = 'PM_SHEETS_INFORMATION';
export const PM_SHEETS_INFORMATION_LOADED = 'PM_SHEETS_INFORMATION_LOADED';

/*Bulletin Message*/
export const FETCH_BULLETIN_MESSAGE = 'FETCH_BULLETIN_MESSAGE';
export const SAVE_BULLETIN_MESSAGE = 'SAVE_BULLETIN_MESSAGE';
export const SEND_BULLETIN_MESSAGE_RESOLVED = 'SEND_BULLETIN_MESSAGE_RESOLVED';
export const SEND_BULLETIN_MESSAGE_REJECTED = 'SEND_BULLETIN_MESSAGE_REJECTED';

export const CLEAR_EXISTING_PO = 'CLEAR_EXISTING_PO';

/*Recover Password*/
export const SAVE_RECOVER_PASSWORD_INFORMATION_ID = 'SAVE_RECOVER_PASSWORD_INFORMATION_ID';
export const SAVE_RECOVER_PASSWORD_INFORMATION_PERSONAL = 'SAVE_RECOVER_PASSWORD_INFORMATION_PERSONAL';
export const SENDING_RECOVER_PASSWORD_INFORMATION = 'SENDING_RECOVER_PASSWORD_INFORMATION';
export const SENDING_RECOVER_PASSWORD_INFO_PERSONA_USER = 'SENDING_RECOVER_PASSWORD_INFO_PERSONA_USER';

export const SEND_RECOVER_PASSWORD_RESOLVED = 'SEND_RECOVER_PASSWORD_RESOLVED';
export const SEND_RECOVER_PASSWORD_REJECTED = 'SEND_RECOVER_PASSWORD_REJECTED';
export const POP_OVER_DISPLAY_SHOW_ARI_ID = 'POP_OVER_DISPLAY_SHOW_ARI_ID';
export const POP_OVER_DISPLAY_SHOW_PERSONAL = 'POP_OVER_DISPLAY_SHOW_PERSONAL';
export const CLEAR_RECOVER_PASSWORD_ERROR = 'CLEAR_RECOVER_PASSWORD_ERROR';
export const SEND_RECOVER_PASSWORD_APPROVED = 'SEND_RECOVER_PASSWORD_APPROVED';
export const RECOVER_PASSWORD_MOUNT = 'RECOVER_PASSWORD_MOUNT';
export const ERROR_RECOVER_PASSWORD_PERSONAL = 'ERROR_RECOVER_PASSWORD_PERSONAL';
export const SEND_RECOVER_PASSWORD_REQUESTED = 'SEND_RECOVER_PASSWORD_REQUESTED';
export const POP_OVER_DISPLAY_SHOW_TAX_ID = 'POP_OVER_DISPLAY_SHOW_TAX_ID';
export const POP_OVER_DISPLAY_SHOW_BUSINESS_NUMBER = 'POP_OVER_DISPLAY_SHOW_BUSINESS_NUMBER';
export const POP_OVER_DISPLAY_SHOW_EMAIL = 'POP_OVER_DISPLAY_SHOW_EMAIL';
export const POP_OVER_DISPLAY_SHOW_FEDERAL_TAX_ID = 'POP_OVER_DISPLAY_SHOW_FEDERAL_TAX_ID';

export const NAVIGATE_TO = 'NAVIGATE_TO';

/*Cancel PO*/
export const CANCEL_PO_REQUESTED = 'CANCEL_PO_REQUESTED';
export const CANCEL_PO_RESOLVED = 'CANCEL_PO_RESOLVED';
export const CANCEL_PO_REJECTED = 'CANCEL_PO_REJECTED';

export const EXISTING_PO_YES = 'EXISTING_PO_YES';

export const RESET_VENDOR_VEHICLE_PARAMETERS = "RESET_VENDOR_VEHICLE_PARAMETERS";
export const SET_VENDOR_VEHICLE_PARAMETERS = "SET_VENDOR_VEHICLE_PARAMETERS";

/* Vendor Documents */
export const VENDOR_DOCUMENTS_LOADING = 'VENDOR_DOCUMENTS_LOADING';
export const VENDOR_DOCUMENTS_UNLOADING = 'VENDOR_DOCUMENTS_UNLOADING';
export const FETCH_VENDOR_DOCUMENTS = 'FETCH_VENDOR_DOCUMENTS';
export const FETCH_VENDOR_DOCUMENTS_DATA_REQUESTED = 'FETCH_VENDOR_DOCUMENTS_DATA_REQUESTED';
export const FETCH_VENDOR_DOCUMENTS_DATA_RESOLVED = 'FETCH_VENDOR_DOCUMENTS_DATA_RESOLVED';
export const FETCH_VENDOR_DOCUMENTS_DATA_REJECTED = 'FETCH_VENDOR_DOCUMENTS_DATA_REJECTED';
export const SHOW_VENDOR_DOCUMENTS = 'SHOW_VENDOR_DOCUMENTS';
export const SHOW_MORE_VENDOR_DOCUMENTS = 'SHOW_MORE_VENDOR_DOCUMENTS';
export const SHOW_LESS_VENDOR_DOCUMENTS = 'SHOW_LESS_VENDOR_DOCUMENTS';
export const SET_VENDOR_DOCUMENTS_STEP = 'SET_VENDOR_DOCUMENTS_STEP';
export const VENDOR_DOCUMENT_STEPS = {
    DOCUMENTS: 'DOCUMENTS',
    BROWSE: 'BROWSE',
    UPLOAD: 'UPLOAD',
};
export const VENDOR_DOCUMENTS_SET_UPLOAD_FILE_NAME = 'VENDOR_DOCUMENTS_SET_UPLOAD_FILE_NAME';
export const SET_FILE_INFO_VENDOR_DOCUMENTS = 'SET_FILE_INFO_VENDOR_DOCUMENTS';
export const CLEAR_FILE_INFO_VENDOR_DOCUMENTS = 'CLEAR_FILE_INFO_VENDOR_DOCUMENTS';
export const VENDOR_DOCUMENTS_CLEAR_ERROR = 'VENDOR_DOCUMENTS_CLEAR_ERROR';
export const UPLOAD_VENDOR_DOCUMENT = 'UPLOAD_VENDOR_DOCUMENT';
export const UPLOAD_VENDOR_DOCUMENT_REQUESTED = 'UPLOAD_VENDOR_DOCUMENT_REQUESTED';
export const VENDOR_DOCUMENTS_VALIDATION_FAILED = 'VENDOR_DOCUMENTS_VALIDATION_FAILED';
export const UPLOAD_VENDOR_DOCUMENT_RESOLVED = 'UPLOAD_VENDOR_DOCUMENT_RESOLVED';
export const UPLOAD_VENDOR_DOCUMENT_REJECTED = 'UPLOAD_VENDOR_DOCUMENT_REJECTED';

/* Vehicle Documents */
export const VEHICLE_DOCUMENTS_LOADING = 'VEHICLE_DOCUMENTS_LOADING';
export const VEHICLE_DOCUMENTS_UNLOADING = 'VEHICLE_DOCUMENTS_UNLOADING';
export const FETCH_VEHICLE_DOCUMENTS = 'FETCH_VEHICLE_DOCUMENTS';
export const FETCH_VEHICLE_DOCUMENTS_DATA_REQUESTED = 'FETCH_VEHICLE_DOCUMENTS_DATA_REQUESTED';
export const FETCH_VEHICLE_DOCUMENTS_DATA_RESOLVED = 'FETCH_VEHICLE_DOCUMENTS_DATA_RESOLVED';
export const FETCH_VEHICLE_DOCUMENTS_DATA_REJECTED = 'FETCH_VEHICLE_DOCUMENTS_DATA_REJECTED';
export const SHOW_VEHICLE_DOCUMENTS = 'SHOW_VEHICLE_DOCUMENTS';
export const SHOW_MORE_VEHICLE_DOCUMENTS = 'SHOW_MORE_VEHICLE_DOCUMENTS';
export const SHOW_LESS_VEHICLE_DOCUMENTS = 'SHOW_LESS_VEHICLE_DOCUMENTS';
export const SET_VEHICLE_DOCUMENTS_STEP = 'SET_VEHICLE_DOCUMENTS_STEP';
export const VEHICLE_DOCUMENT_STEPS = {
    DOCUMENTS: 'DOCUMENTS',
    BROWSE: 'BROWSE',
    UPLOAD: 'UPLOAD',
};
export const VEHICLE_DOCUMENTS_SET_UPLOAD_FILE_NAME = 'VEHICLE_DOCUMENTS_SET_UPLOAD_FILE_NAME';
export const SET_FILE_INFO_VEHICLE_DOCUMENTS = 'SET_FILE_INFO_VEHICLE_DOCUMENTS';
export const CLEAR_FILE_INFO_VEHICLE_DOCUMENTS = 'CLEAR_FILE_INFO_VEHICLE_DOCUMENTS';
export const VEHICLE_DOCUMENTS_CLEAR_ERROR = 'VEHICLE_DOCUMENTS_CLEAR_ERROR';
export const UPLOAD_VEHICLE_DOCUMENT = 'UPLOAD_VEHICLE_DOCUMENT';
export const UPLOAD_VEHICLE_DOCUMENT_REQUESTED = 'UPLOAD_VEHICLE_DOCUMENT_REQUESTED';
export const VEHICLE_DOCUMENTS_VALIDATION_FAILED = 'VEHICLE_DOCUMENTS_VALIDATION_FAILED';
export const UPLOAD_VEHICLE_DOCUMENT_RESOLVED = 'UPLOAD_VEHICLE_DOCUMENT_RESOLVED';
export const UPLOAD_VEHICLE_DOCUMENT_REJECTED = 'UPLOAD_VEHICLE_DOCUMENT_REJECTED';

/*Vendor settings Information */
export const SHOW_CONTACT_SETTINGS = 'SHOW_CONTACT_SETTINGS';
export const HIDE_CONTACT_SETTINGS = 'HIDE_CONTACT_SETTINGS';
export const SHOW_PASSWORD_SETTINGS = 'SHOW_PASSWORD_SETTINGS';
export const UPDATE_ARI_ID_ERROR_MESSAGE = 'UPDATE_ARI_ID_ERROR_MESSAGE';
export const UPDATE_CURRENT_PASSWORD_ERROR_MESSAGE = 'UPDATE_CURRENT_PASSWORD_ERROR_MESSAGE';
export const UPDATE_NEW_PWD_ERROR_MESSAGE = 'UPDATE_NEW_PWD_ERROR_MESSAGE';
export const UPDATE_CONFIRM_PWD_ERROR_MESSAGE = 'UPDATE_CONFIRM_PWD_ERROR_MESSAGE';
export const HIDE_PASSWORD_SETTINGS = 'HIDE_PASSWORD_SETTINGS';
export const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED';
export const CHANGE_PASSWORD_RESOLVED = 'CHANGE_PASSWORD_RESOLVED';
export const CHANGE_PASSWORD_REJECTED = 'CHANGE_PASSWORD_REJECTED';
export const UPDATE_PASSWORD_TO_STORE = 'UPDATE_PASSWORD_TO_STORE';
export const SHOW_ARI_ID_ERROR_MESSAGE = 'SHOW_ARI_ID_ERROR_MESSAGE';
export const SHOW_CURRENT_PWD_ERROR_MESSAGE = 'SHOW_CURRENT_PWD_ERROR_MESSAGE';
export const SHOW_NEW_PWD_ERROR_MESSAGE = 'SHOW_NEW_PWD_ERROR_MESSAGE';
export const SHOW_CONFIRM_NEW_PWD_ERROR_MESSAGE = 'SHOW_CONFIRM_NEW_PWD_ERROR_MESSAGE';
export const RESET_ERROR_MESSAGES = 'RESET_ERROR_MESSAGES';
export const CLEAR_PASSWORD_SETTINGS = 'CLEAR_PASSWORD_SETTINGS';
export const SAVE_VENDOR_DETAILS = 'SAVE_VENDOR_DETAILS';
export const SHOW_VENDOR_NAME_ERROR = 'SHOW_VENDOR_NAME_ERROR';
export const SHOW_VENDOR_BUSINESS_NUMBER_ERROR = 'SHOW_VENDOR_BUSINESS_NUMBER_ERROR';
export const SHOW_BUSINESS_EXTN_ERROR = 'SHOW_BUSINESS_EXTN_ERROR';
export const SHOW_VENDOR_FAX_NUMBER_ERROR = 'SHOW_VENDOR_FAX_NUMBER_ERROR';
export const SHOW_VENDOR_STREET_ADDRESS1_ERROR = 'SHOW_VENDOR_STREET_ADDRESS1_ERROR';
export const SHOW_VENDOR_STREET_ADDRESS2_ERROR = 'SHOW_VENDOR_STREET_ADDRESS2_ERROR';
export const SHOW_VENDOR_CITY_ADDRESS_ERROR = 'SHOW_VENDOR_CITY_ADDRESS_ERROR';
export const SHOW_VENDOR_STATE_ADDRESS_ERROR = 'SHOW_VENDOR_STATE_ADDRESS_ERROR';
export const SHOW_VENDOR_ZIP_ADDRESS_ERROR = 'SHOW_VENDOR_ZIP_ADDRESS_ERROR';
export const RESET_ERROR_MESSAGES_VENDOR = 'RESET_ERROR_MESSAGES_VENDOR';
export const CLEAR_CONTACT_SETTINGS = 'CLEAR_CONTACT_SETTINGS';
export const UPDATE_VENDOR_DETAILS = 'UPDATE_VENDOR_DETAILS';
export const SAVE_PASSWORD = 'SAVE_PASSWORD';
export const SHOW_VENDOR_EMAIL_ERROR = 'SHOW_VENDOR_EMAIL_ERROR';
export const VENDOR_INFORMATION_REQUESTED = 'VENDOR_INFORMATION_REQUESTED';
export const FETCH_VENDOR_INFORMATION = 'FETCH_VENDOR_INFORMATION';
export const VENDOR_INFORMATION_RESOLVED = 'VENDOR_INFORMATION_RESOLVED';
export const VENDOR_INFORMATION_REJECTED = 'VENDOR_INFORMATION_REJECTED';
export const SET_VENDOR_INFORMATION = 'SET_VENDOR_INFORMATION';
export const CHANGE_VENDOR_INFO_REJECTED = 'CHANGE_VENDOR_INFO_REJECTED';
export const SET_VENDOR_INFORMATION_EMAIL_CONTACT = 'SET_VENDOR_INFORMATION_EMAIL_CONTACT'

/*Vendor account Information*/
export const SHOW_ACCOUNT_VALIDATION = 'SHOW_ACCOUNT_VALIDATION';
export const HIDE_ACCOUNT_VALIDATION = 'HIDE_ACCOUNT_VALIDATION';
export const SHOW_ACCOUNT_EDIT = 'SHOW_ACCOUNT_EDIT';
export const HIDE_ACCOUNT_EDIT = 'HIDE_ACCOUNT_EDIT';
export const CLEAR_ACCOUNT_INFORMATION = 'CLEAR_ACCOUNT_INFORMATION';
export const UPDATE_VENDOR_BANKING_ACCOUNT_DETAILS = 'UPDATE_VENDOR_BANKING_ACCOUNT_DETAILS';
export const FETCH_VENDOR_BANKING_INFORMATION = 'FETCH_VENDOR_BANKING_INFORMATION';
export const SAVE_ACCOUNT_INFORMATION_ID = 'SAVE_ACCOUNT_INFORMATION_ID';
export const SAVE_ACCOUNT_INFORMATION_PERSONAL = 'SAVE_ACCOUNT_INFORMATION_PERSONAL';
export const CLEAR_ACCOUNT_INFORMATION_ERROR = 'CLEAR_ACCOUNT_INFORMATION_ERROR';
export const SEND_ACCOUNT_VERIFICATION_REJECTED = 'SEND_ACCOUNT_VERIFICATION_REJECTED';
export const SEND_ACCOUNT_VERIFICATION_RESOLVED = 'SEND_ACCOUNT_VERIFICATION_RESOLVED';
export const SEND_ACCOUNT_VERIFICATION_APPROVED = 'SEND_ACCOUNT_VERIFICATION_APPROVED';
export const ACCOUNT_VERIFICATION_MOUNT = 'ACCOUNT_VERIFICATION_MOUNT';
export const ERROR_ACCOUNT_INFORMATION_ID = 'ERROR_ACCOUNT_INFORMATION_ID';
export const ERROR_ACCOUNT_INFORMATION_PERSONAL = 'ERROR_ACCOUNT_INFORMATION_PERSONAL';
export const SEND_ACCOUNT_VERIFICATION_REQUESTED = 'SEND_ACCOUNT_VERIFICATION_REQUESTED';
export const AGREE_TERMS_AND_CONDITIONS = 'AGREE_TERMS_AND_CONDITIONS';
export const ERROR_CHECKING_ACCOUNT_INFORMATION = 'ERROR_CHECKING_ACCOUNT_INFORMATION';
export const ERROR_ROUTING_NUMBER = 'ERROR_ROUTING_NUMBER';
export const ERROR_SECOND_CHECKING_ACCOUNT = 'ERROR_SECOND_CHECKING_ACCOUNT';
export const POP_OVER_DISPLAY_SHOW_CHECKING1 = 'POP_OVER_DISPLAY_SHOW_CHECKING1';
export const POP_OVER_DISPLAY_SHOW_CHECKING2 = 'POP_OVER_DISPLAY_SHOW_CHECKING2';
export const POP_OVER_DISPLAY_SHOW_ROUTING_NUMBER = 'POP_OVER_DISPLAY_SHOW_ROUTING_NUMBER';
export const SAVE_ACCOUNT_CHECKING1 = 'SAVE_ACCOUNT_CHECKING1';
export const SAVE_ACCOUNT_CHECKING2 = 'SAVE_ACCOUNT_CHECKING2';
export const SAVE_ACCOUNT_ROUTING_NUMBER = 'SAVE_ACCOUNT_ROUTING_NUMBER';
export const VENDOR_BANKING_ACCOUNT_SUCCESS = 'VENDOR_BANKING_ACCOUNT_SUCCESS';
export const SHOW_TERMS_AND_CONDITION_ERROR = 'SHOW_TERMS_AND_CONDITION_ERROR';
export const POP_OVER_DISPLAY_SHOW_TERMS = 'POP_OVER_DISPLAY_SHOW_TERMS';
export const VENDOR_SERVICES_MOUNT = 'VENDOR_SERVICES_MOUNT';
export const SAVE_VENDOR_SERVICES = 'SAVE_VENDOR_SERVICES';
export const SHOW_VENDOR_SERVICES_EDIT = 'SHOW_VENDOR_SERVICES_EDIT';
export const HIDE_VENDOR_SERVICES_EDIT = 'HIDE_VENDOR_SERVICES_EDIT';
export const VENDOR_SERVICE_SELECTED = 'VENDOR_SERVICE_SELECTED';
export const VENDOR_MINORITY_SELECTED = 'VENDOR_MINORITY_SELECTED';
export const VENDOR_ACCOUNT_IS_DIRTY = 'VENDOR_ACCOUNT_IS_DIRTY';
export const FETCH_VENDOR_SERVICES = 'FETCH_VENDOR_SERVICES';
export const FETCH_VENDOR_VERIFY_INFORMATION = 'FETCH_VENDOR_VERIFY_INFORMATION';
export const FETCH_VENDOR_MINORITY = 'FETCH_VENDOR_MINORITY';
export const VENDOR_MINORITY_UPDATE = 'VENDOR_MINORITY_UPDATE';
export const SET_VENDOR_BANKING_INFORMATION = 'SET_VENDOR_BANKING_INFORMATION';
export const SET_VENDOR_BANKING_VERIFY_TOKEN = 'SET_VENDOR_BANKING_VERIFY_TOKEN';
export const FETCH_VENDOR_TAX_INFORMATION = 'FETCH_VENDOR_TAX_INFORMATION';
export const UPDATE_VENDOR_TAX_INFORMATION = 'UPDATE_VENDOR_TAX_INFORMATION';

export const ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_REQUESTED = 'ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_REQUESTED';
export const ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_RESOLVED = 'ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_RESOLVED';
export const ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_REJECTED = 'ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_REJECTED';
export const ACCOUNT_VIEW_UPDATE_NOTIFICATION_CONTACT = 'ACCOUNT_VIEW_UPDATE_NOTIFICATION_CONTACT';
export const ACCOUNT_VIEW_REMOVE_NOTIFICATION_CONTACT = 'ACCOUNT_VIEW_REMOVE_NOTIFICATION_CONTACT';
export const ACCOUNT_VIEW_UPDATE_EMAIL_CONFIG_REGISTRATION = 'ACCOUNT_VIEW_UPDATE_EMAIL_CONFIG_REGISTRATION';
export const ACCOUNT_VIEW_UPDATE_EMAIL_CONFIG_VALIDATION_ERRS = 'ACCOUNT_VIEW_UPDATE_EMAIL_CONFIG_VALIDATION_ERRS';
export const ACCOUNT_VIEW_REMOVE_EMAIL_CONFIG_VALIDATION_ERRS = 'ACCOUNT_VIEW_REMOVE_EMAIL_CONFIG_VALIDATION_ERRS';
export const ACCOUNT_VIEW_SAVE_EMAIL_AUTH_NOTIFICATION = 'ACCOUNT_VIEW_SAVE_EMAIL_AUTH_NOTIFICATION';
export const ACCOUNT_VIEW_UPDATE_REGISTRATION_FIELD = 'ACCOUNT_VIEW_UPDATE_REGISTRATION_FIELD';
export const ACCOUNT_VIEW_RESET_AUTH_EMAIL_NOTIFICATION = 'ACCOUNT_VIEW_RESET_AUTH_EMAIL_NOTIFICATION';
export const FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION = 'FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION';
export const FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_REJECTED = 'FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_REJECTED';
export const FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_RESOLVED = 'FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_REJECTED';
export const FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_REQUESTED = 'FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_REJECTED';
export const ADD = 'ADD';
export const EDIT = 'EDIT';
export const VIEW = 'VIEW';
export const ACCOUNT_VIEW_EMAIL_CONFIG_FORM_MODE = 'ACCOUNT_VIEW_EMAIL_CONFIG_FORM_MODE';
export const ACCOUNT_VIEW_CLEAR_AUTH_EMAIL_NOTIFICATION = 'ACCOUNT_VIEW_CLEAR_AUTH_EMAIL_NOTIFICATION';
export const ELEMENT_STATE = {
    ADD: 'ADD',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE'
}
export const ACCOUNT_VIEW_SAVE_AND_FETCH_EMAIL_AUTH_NOTIFICATION = 'ACCOUNT_VIEW_SAVE_AND_FETCH_EMAIL_AUTH_NOTIFICATION';
export const VENDOR_INFORMATION_SUBMIT_DATA_REQUESTED = 'VENDOR_INFORMATION_SUBMIT_DATA_REQUESTED';
export const VENDOR_INFORMATION_SUBMIT_DATA_RESOLVED = 'VENDOR_INFORMATION_SUBMIT_DATA_RESOLVED'
/*Vendor enrollment request*/
export const UPDATE_ENROLLMENT_REQUEST_INFO = 'UPDATE_ENROLLMENT_REQUEST_INFO';
export const VENDOR_ENROLLMENT_REQUEST_FIELD_NAME = {
    BUSINESS_NAME: 'BUSINESS_NAME',
    ADDRESS_1: 'ADDRESS_1',
    ADDRESS_2: 'ADDRESS_2',
    CITY: 'CITY',
    STATE: 'STATE',
    ZIP: 'ZIP',
    COUNTRY: 'COUNTRY',
    CONTACT_NAME: 'CONTACT_NAME',
    PHONE: 'PHONE',
    PHONE_AREA: 'PHONE_AREA',
    PHONE_PREFIX: 'PHONE_PREFIX',
    PHONE_SUFFIX: 'PHONE_SUFFIX',
    EXTENSION: 'EXTENSION',
    EMAIL: 'EMAIL',
    RE_ENTER_EMAIL: 'RE_ENTER_EMAIL',
    FAX_AREA: 'FAX_AREA',
    FAX_PREFIX: 'FAX_PREFIX',
    FAX_SUFFIX: 'FAX_SUFFIX',
    REFERRAL_CODE: 'REFERRAL_CODE',
    ID: 'ID',
    RECAPTCHA_VERIFICATION_TOKEN:'RECAPTCHA_VERIFICATION_TOKEN'
};
export const SUBMIT_ENROLLMENT_REQUEST_INFO = 'SUBMIT_ENROLLMENT_REQUEST_INFO';
export const SUBMIT_VENDOR_ENROLLMENT_REQUEST_REQUESTED = 'SUBMIT_VENDOR_ENROLLMENT_REQUEST_REQUESTED';
export const SUBMIT_VENDOR_ENROLLMENT_REQUEST_RESOLVED = 'SUBMIT_VENDOR_ENROLLMENT_REQUEST_RESOLVED';
export const SUBMIT_VENDOR_ENROLLMENT_REQUEST_REJECTED = 'SUBMIT_VENDOR_ENROLLMENT_REQUEST_REJECTED';
export const UPDATE_ENROLLMENT_STEP = 'UPDATE_ENROLLMENT_STEP';
export const ENROLLMENT_REQUEST_FORM_IS_DIRTY = 'ENROLLMENT_REQUEST_FORM_IS_DIRTY';
export const ENROLLMENT_FORM_IS_DIRTY = 'ENROLLMENT_FORM_IS_DIRTY';

export const RESET_ENROLLMENT_REQUEST_INFO = 'RESET_ENROLLMENT_REQUEST_INFO';
export const RESET_REQUEST_SUBMITTED_FLAG = 'RESET_REQUEST_SUBMITTED_FLAG';
export const UPDATE_ENROLLMENT_DETAIL_FROM_REQUEST_INFO = 'UPDATE_ENROLLMENT_DETAIL_FROM_REQUEST_INFO';
export const UPDATE_PRE_LOAD_DETAIL_INFO = 'UPDATE_PRE_LOAD_DETAIL_INFO';

//vendor enrollment
export const UPDATE_ENROLLMENT_DETAIL_INFO = 'UPDATE_ENROLLMENT_DETAIL_INFO';
export const VENDOR_ENROLLMENT_DETAIL_FIELD_NAME = {
    BUSINESS_NAME: 'BUSINESS_NAME',
    CONTACT_NAME: 'CONTACT_NAME',
    TERMS_READ_AND_AGREED: 'TERMS_READ_AND_AGREED',
    TERMS_ACCEPTED_BY: 'TERMS_ACCEPTED_BY',
    BUSINESS_NAME_DBA: 'BUSINESS_NAME_DBA',
    SELECTED_TAX_CLASSIFICATION: 'SELECTED_TAX_CLASSIFICATION',
    LIABILITY_TAX_CLASSIFICATION: 'LIABILITY_TAX_CLASSIFICATION',
    SSN_AREA: 'SSN_AREA',
    SSN_GROUP: 'SSN_GROUP',
    SSN_SERIAL: 'SSN_SERIAL',
    EMPLOYER_ID_FIRST_PART: 'EMPLOYER_ID_FIRST_PART',
    EMPLOYER_ID_SECOND_PART: 'EMPLOYER_ID_SECOND_PART',
    CLIENT_REFERENCE_NAME: 'CLIENT_REFERENCE_NAME',
    ID: 'ID',
    SERVICE_CLASSIFICATION: 'SERVICE_CLASSIFICATION',
    BUSINESS_NUMBER:'BUSINESS_NUMBER',
    VENDOR_LABOR_RATE:'VENDOR_LABOR_RATE',
    BODY_SHOP_RATE:'BODY_SHOP_RATE',
    MOBILE_RATE:'MOBILE_RATE',
    EQUIPMENT_RATE:'EQUIPMENT_RATE',
    LIGHT_DUTY_RATE:'LIGHT_DUTY_RATE',
    MEDIUM_DUTY_RATE:'MEDIUM_DUTY_RATE',
    HEAVY_DUTY_RATE:'HEAVY_DUTY_RATE',
    TRAILER_RATE:'TRAILER_RATE'
};
export const SUBMIT_ENROLLMENT_DETAIL_INFO = 'SUBMIT_ENROLLMENT_DETAIL_INFO';
export const SUBMIT_VENDOR_ENROLLMENT_DETAIL_REQUESTED = 'SUBMIT_VENDOR_ENROLLMENT_DETAIL_REQUESTED';
export const SUBMIT_VENDOR_ENROLLMENT_DETAIL_RESOLVED = 'SUBMIT_VENDOR_ENROLLMENT_DETAIL_RESOLVED';
export const SUBMIT_VENDOR_ENROLLMENT_DETAIL_REJECTED = 'SUBMIT_VENDOR_ENROLLMENT_DETAIL_REJECTED';
export const RESET_ENROLLMENT_INFO = 'RESET_ENROLLMENT_INFO';

//registration 
export const SUBMIT_ACCOUNT_INFO = 'SUBMIT_ACCOUNT_INFO';
export const SUBMIT_BANK_INFO = 'SUBMIT_BANK_INFO';
export const FORM_DATA = {
    ARI_ID: 'ARI_ID',
    TAX_ID: 'TAX_ID',
    TAX_ID_TYPE: 'TAX_ID_TYPE',
    PASSWORD: 'PASSWORD',
    CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
    ROUTING_NUMBER: 'ROUTING_NUMBER',
    CHECKING_ACC_NUMBER: 'CHECKING_ACC_NUMBER',
    CONFIRM_CHECKING_ACC_NUMBER: 'CONFIRM_CHECKING_ACC_NUMBER',
    PAYMENT_EMAIL: 'PAYMENT_EMAIL',
    SSN_AREA: 'SSN_AREA',
    SSN_GROUP: 'SSN_GROUP',
    SSN_SERIAL: 'SSN_SERIAL',
    EMPLOYER_ID_FIRST_PART: 'EMPLOYER_ID_FIRST_PART',
    EMPLOYER_ID_SECOND_PART: 'EMPLOYER_ID_SECOND_PART',
    SSN: 'SSN',
    EMPLOYER_ID: 'EMPLOYER_ID',
    COUNTRY:'COUNTRY',
    BUSINESS_NUMBER:'BUSINESS_NUMBER',
    INSTITUTE_ID:'INSTITTUTE_ID',
    TRANSIT_ID:'TRANSIT_ID',
    FEDERAL_TAX_ID:'FEDERAL_TAX_ID',
}
export const UPDATE_REGISTRATION_FIELD = 'UPDATE_REGISTRATION_FIELD';
export const REGISTRATION_NEXT_STEP = 'REGISTRATION_NEXT_STEP';
export const RESET_REGISTRATION = 'RESET_REGISTRATION';
export const FETCH_VENDOR_SERVICES_FOR_REGISTRATION = 'FETCH_VENDOR_SERVICES_FOR_REGISTRATION';
export const FETCH_VENDOR_MINORITY_FOR_REGISTRATION = 'FETCH_VENDOR_MINORITY_FOR_REGISTRATION';
export const SAVE_VENDOR_MINORITY_FOR_REGISTRATION = 'SAVE_VENDOR_MINORITY_FOR_REGISTRATION';
export const SAVE_VENDOR_SERVICES_FOR_REGISTRATION = 'SAVE_VENDOR_SERVICES_FOR_REGISTRATION';
export const LOAD_VENDOR_SERVICES_FOR_REGISTRATION = 'LOAD_VENDOR_SERVICES_FOR_REGISTRATION';
export const SUBMIT_VENDOR_PROFILE_SERVICES = 'SUBMIT_VENDOR_PROFILE_SERVICES';
export const FETCH_TOKEN_FOR_VENDOR_REGISTRATION = 'FETCH_TOKEN_FOR_VENDOR_REGISTRATION';
export const UPDATE_FORM_DATA_VALIDATION_ERRS = 'UPDATE_FORM_DATA_VALIDATION_ERRS';
export const REMOVE_VALIDATION_ERROR = 'REMOVE_VALIDATION_ERROR';
export const UPDATE_NOTIFICATION_CONTACT = 'UPDATE_NOTIFICATION_CONTACT';
export const REMOVE_NOTIFICATION_CONTACT = 'REMOVE_NOTIFICATION_CONTACT';
export const UPDATE_EMAIL_CONFIG_REGISTRATION = 'UPDATE_EMAIL_CONFIG_REGISTRATION';
export const UPDATE_EMAIL_CONFIG_VALIDATION_ERRS = 'UPDATE_EMAIL_CONFIG_VALIDATION_ERRS';
export const REMOVE_EMAIL_CONFIG_VALIDATION_ERRS = 'REMOVE_EMAIL_CONFIG_VALIDATION_ERRS';
export const SELECTED_COUNTRY_CHANGED='SELECTED_COUNTRY_CHANGED';

export const SUBMIT_STEP_ACCOUNT_INFO_REQUESTED = 'SUBMIT_STEP_ACCOUNT_INFO_REQUESTED';
export const SUBMIT_STEP_ACCOUNT_INFO_RESOLVED = 'SUBMIT_STEP_ACCOUNT_INFO_RESOLVED';
export const SUBMIT_STEP_ACCOUNT_INFO_REJECTED = 'SUBMIT_STEP_ACCOUNT_INFO_REJECTED';
export const SUBMIT_BANK_INFO_REQUESTED = 'SUBMIT_BANK_INFO_REQUESTED';
export const SUBMIT_BANK_INFO_RESOLVED = 'SUBMIT_BANK_INFO_RESOLVED';
export const SUBMIT_BANK_INFO_REJECTED = 'SUBMIT_BANK_INFO_REJECTED';
export const SUBMIT_SERVICE_INFO_REQUESTED = 'SUBMIT_SERVICE_INFO_REQUESTED';
export const SUBMIT_SERVICE_INFO_RESOLVED = 'SUBMIT_SERVICE_INFO_RESOLVED';
export const SUBMIT_SERVICE_INFO_REJECTED = 'SUBMIT_SERVICE_INFO_REJECTED';
export const SUBMIT_AUTH_EMAIL_CONFIG_INFO_REQUESTED = 'SUBMIT_AUTH_EMAIL_CONFIG_INFO_REQUESTED';
export const SUBMIT_AUTH_EMAIL_CONFIG_INFO_RESOLVED = 'SUBMIT_AUTH_EMAIL_CONFIG_INFO_RESOLVED';
export const SUBMIT_AUTH_EMAIL_CONFIG_INFO_REJECTED = 'SUBMIT_AUTH_EMAIL_CONFIG_INFO_REJECTED';

export const FETCH_SERVICE_REQUESTED = 'FETCH_SERVICE_REQUESTED';
export const FETCH_SERVICE_RESOLVED = 'FETCH_SERVICE_RESOLVED';
export const FETCH_SERVICE_REJECTED = 'FETCH_SERVICE_REJECTED';

export const VENDOR_SERVICE_SELECTED_FOR_REGISTRATION = 'VENDOR_SERVICE_SELECTED_FOR_REGISTRATION';
export const VENDOR_MINORITY_SELECTED_FOR_REGISTRATION = 'VENDOR_MINORITY_SELECTED_FOR_REGISTRATION';
export const SAVE_EMAIL_AUTH_NOTIFICATION = 'SAVE_EMAIL_AUTH_NOTIFICATION';


//my units
export const MY_UNITS_SCREEN_UNLOADED = 'MY_UNITS_SCREEN_UNLOADED';
export const MY_OTHER_UNITS_SCREEN_UNLOADED = 'MY_OTHER_UNITS_SCREEN_UNLOADED';
export const FETCH_MORE_UNITS_CLICK = 'FETCH_MORE_UNITS_CLICK';
export const FETCH_MORE_OTHER_UNITS_CLICK = 'FETCH_MORE_OTHER_UNITS_CLICK';
export const EMAIL_VENDOR_UNITS = 'EMAIL_VENDOR_UNITS';
export const REJECT_VENDOR_UNITS = 'REJECT_VENDOR_UNITS';
export const CURRENT_VEHICLE_ID = 'CURRENT_VEHICLE_ID';
export const CURRENT_VIN_ID = 'CURRENT_VIN_ID';
export const FETCH_VENDOR_BUSINESS_GROWTH = 'FETCH_VENDOR_BUSINESS_GROWTH';
export const FETCH_VENDOR_UNITS = 'FETCH_VENDOR_UNITS';
export const FETCH_VENDOR_OTHER_UNITS = 'FETCH_VENDOR_OTHER_UNITS';
export const MY_UNITS_REQUESTED = 'MY_UNITS_REQUESTED';
export const MY_UNITS_HP_MOUNT = 'MY_UNITS_HP_MOUNT';
export const MY_UNITS_RESOLVED = 'MY_UNITS_RESOLVED';
export const MY_UNITS_REJECTED = 'MY_UNITS_REJECTED';
export const MY_BUSINESS_GROWTH_REQUESTED = 'MY_BUSINESS_GROWTH_REQUESTED';
export const MY_BUSINESS_GROWTH_MOUNT = 'MY_BUSINESS_GROWTH_MOUNT';
export const MY_BUSINESS_GROWTH_RESOLVED = 'MY_BUSINESS_GROWTH_RESOLVED';
export const MY_BUSINESS_GROWTH_REJECTED = 'MY_BUSINESS_GROWTH_REJECTED';
export const CLEAR_MY_UNITS = 'CLEAR_MY_UNITS';
export const MY_OTHER_UNITS_REQUESTED = 'MY_OTHER_UNITS_REQUESTED';
export const MY_OTHER_UNITS_HP_MOUNT = 'MY_OTHER_UNITS_HP_MOUNT';
export const MY_OTHER_UNITS_RESOLVED = 'MY_OTHER_UNITS_RESOLVED';
export const MY_OTHER_UNITS_REJECTED = 'MY_OTHER_UNITS_REJECTED';
export const CLEAR_MY_OTHER_UNITS = 'CLEAR_MY_OTHER_UNITS';
export const SHOW_DUE_SERVICES = 'SHOW_DUE_SERVICES';
export const SHOW_MY_UNITS = 'SHOW_MY_UNITS';

//linked vendor accounts
export const LINKED_VENDORS_CORPORATE_MASK = 'LINKED_VENDORS_CORPORATE_MASK';
export const SAVE_CORPORATE_VENDOR_DETAILS = 'SAVE_CORPORATE_VENDOR_DETAILS';
export const CLEAR_CORPORATE_VENDOR_DETAILS = 'CLEAR_CORPORATE_VENDOR_DETAILS';
export const LINKED_VENDORS_REQUESTED = 'LINKED_VENDORS_REQUESTED';
export const LINKED_VENDORS_RESOLVED = 'LINKED_VENDORS_RESOLVED';
export const LINKED_VENDORS_REJECTED = 'LINKED_VENDORS_REJECTED';
export const LINKED_VENDORS_SEARCHED = 'LINKED_VENDORS_SEARCHED';
export const CLEAR_LINKED_VENDORS_SEARCHED = 'CLEAR_LINKED_VENDORS_SEARCHED';
export const FETCH_LINKED_VENDORS = 'FETCH_LINKED_VENDORS';
export const SAVE_REGISTER_LINKED_VENDOR = 'SAVE_REGISTER_LINKED_VENDOR';
export const LINKED_VENDORS_SCREEN_UNMOUNTED = 'LINKED_VENDORS_SCREEN_UNMOUNTED';
export const FETCH_MORE_LINKED_VENDORS_CLICK = 'FETCH_MORE_LINKED_VENDORS_CLICK';
export const SUBMIT_LINKED_VENDOR_REGISTER = 'SUBMIT_LINKED_VENDOR_REGISTER';
export const UPDATE_LINKED_ENROLLMENT_INFO = 'UPDATE_LINKED_ENROLLMENT_INFO';
export const CLOSE_PO_FETCH_LINE_ITEMS = 'CLOSE_PO_FETCH_LINE_ITEMS';
export const FORGOT_CORPORATE_VENDOR_PASSWORD = "FORGOT_CORPORATE_VENDOR_PASSWORD";

export const CAR_WASH_VEHICLE_SEARCH_ERROR_MESSAGE = {
    VEHICLE_NUMBER_ERROR_MESSAGE: 'VEHICLE_NUMBER_ERROR_MESSAGE',
    CLIENT_NUMBER_SELECTION_ERROR_MESSAGE: 'CLIENT_NUMBER_SELECTION_ERROR_MESSAGE'
};

export const PO_FIELD_TYPE = {
    SERVICE_DATE: 'SERVICE_DATE',
    INVOICE_NO: 'INVOICE_NO',
    ODOMETER: 'ODOMETER',
    TAX_AMOUNT: 'TAX_AMOUNT',
    CAR_WASH_COST: 'CAR_WASH_COST',
    DETAIL_CAR_WASH_COST: 'DETAIL_CAR_WASH_COST',

};

/* Basic && Advanced Search */
export const SEARCH_FILTER_TYPE = {
    VIN8: 'VIN8',
    PURCHASEORDER: 'PURCHASEORDER',
    PAYMENT: 'PAYMENT',
    INVOICE: 'INVOICE',
    DEALER_SUBMITTED: 'DEALERSUBMITTED',
    PLATE_STATE:'PLATE_STATE',
    SERVICE_CARD:'SERVICE_CARD',
    TAG_PROV:'TAG_PROV',
    CLIENT_VEHICLE:'CLIENT_VEHICLE',
    TAG:'TAG',
    PROVIDENCE:'PROVIDENCE',
    LICENSE:'LICENSE',
    STATE:'STATE',
    CLIENT:'CLIENT',
    VEHICLE:'VEHICLE',
    CLIENT_REF_NO:'CLIENT_REF_NO'
};

export const SEARCH_DURATION_TYPE = {
    DURATION: 'DURATION',
    LAST_60_DAYS: 'LAST_60_DAYS',
    LAST_120_DAYS: 'LAST_120_DAYS'
};

export const SAVE_SEARCH_FILTER_SEARCH_FOR = 'SAVE_SEARCH_FILTER_SEARCH_FOR';
export const SAVE_SEARCH_FILTER_DAYS = 'SAVE_SEARCH_FILTER_DAYS';
export const SAVE_SEARCH_TEXT = 'SAVE_SEARCH_TEXT';
export const RESET_SEARCH_FILTERS = 'RESET_SEARCH_FILTERS';
export const RESOLVE_SEARCH_FILTERS = 'RESOLVE_SEARCH_FILTERS';

export const PO_FIELD_ERROR_TYPE = {
    SERVICE_DATE: 'SERVICE_DATE',
    INVOICE_NO: 'INVOICE_NO',
    ODOMETER: 'ODOMETER',
    TAX_AMOUNT: 'TAX_AMOUNT',
    CAR_WASH_COST: 'CAR_WASH_COST',
    DETAIL_CAR_WASH_COST: 'DETAIL_CAR_WASH_COST',
    TAX_HST: 'TAX_HST',
    TAX_GST: 'TAX_GST',
    TAX_QST: 'TAX_QST',
    TAX_PST: 'TAX_PST',

};


export const DEALER_DRAFT_CORRECTION_DETAILS_TYPE = {
    COST: 'COST',
    DESCRIPTION: 'DESCRIPTION',
    LINE_ITEM_KEY: 'LINE_ITEM_KEY',
    DELIVERY_DATE: 'DELIVERY_DATE',
    REGISTRATION_DATE: 'REGISTRATION_DATE',
    LICENSE_PLATE: 'LICENSE_PLATE',
    REGISTRATION_STATE: 'REGISTRATION_STATE',
    TEMP_LICENSE_PLATE: 'TEMP_LICENSE_PLATE',
    TEMP_REGISTRATION_EXP_DATE: 'TEMP_REGISTRATION_EXP_DATE',
    TEMP_REGISTRATION_STATE: 'TEMP_REGISTRATION_STATE'

};
/*Brakes*/
export const SHOW_BRAKES = 'SHOW_BRAKES';
export const BRAKE_COMPONENT_TYPE = {
    SHOE: 'shoe',
    DRUM: 'drum',
    PAD: 'pad',
    ROTOR: 'rotor'
}
export const ADD_NEW_BRAKE_AXLE = 'ADD_NEW_BRAKE_AXLE';
export const BRAKE_POSITIONS = {
    LEFT: 'left',
    RIGHT: 'right',
    MAX_DRUM_DIAMETER: 'maxDrumDiameter',
    MIN_PAD_THICKNESS: 'minRotorThickness'
}
export const SAVE_NEW_LINE_ITEM_WITH_BRAKE_SPECS = 'SAVE_NEW_LINE_ITEM_WITH_BRAKE_SPECS';
export const RESET_BRAKE_SPECIFICATIONS = 'RESET_BRAKE_SPECIFICATIONS';
export const SAVE_BRAKE_SPECIFICATIONS_REQUESTED = 'SAVE_BRAKE_SPECIFICATIONS_REQUESTED';
export const SAVE_BRAKE_SPECIFICATIONS_RESOLVED = 'SAVE_BRAKE_SPECIFICATIONS_RESOLVED';
export const SAVE_BRAKE_SPECIFICATIONS_REJECTED = 'SAVE_BRAKE_SPECIFICATIONS_REJECTED';
export const UPDATE_BRAKES_VALIDATION_ERRS = 'UPDATE_BRAKES_VALIDATION_ERRS';
export const CLEAR_BRAKES_VALIDATION_ERRS = 'CLEAR_BRAKES_VALIDATION_ERRS';
export const SAVE_BRAKES_MEASUREMENT_UNIT = 'SAVE_BRAKES_MEASUREMENT_UNIT';
export const FETCH_BRAKES_SPECIFICATIONS_REQUESTED = 'FETCH_BRAKES_SPECIFICATIONS_REQUESTED';
export const LOAD_BRAKES_SPECIFICATION = 'LOAD_BRAKES_SPECIFICATION';
export const LOAD_COPY_BRAKES_SPECIFICATION = 'LOAD_COPY_BRAKES_SPECIFICATION';
export const FETCH_BRAKES_SPECIFICATIONS_RESOLVED = 'FETCH_BRAKES_SPECIFICATIONS_RESOLVED';
export const FETCH_BRAKES_SPECIFICATIONS_REJECTED = 'FETCH_BRAKES_SPECIFICATIONS_REJECTED';
export const FETCH_BRAKES_SPECIFICATIONS = 'FETCH_BRAKES_SPECIFICATIONS';
export const SAVE_BRAKES_DETAILS = 'SAVE_BRAKES_DETAILS';
export const RESET_BRAKES_UNIT = 'RESET_BRAKES_UNIT';
export const RESET_TIRES_UNIT = 'RESET_TIRES_UNIT';

/*Terms & Conditions */
export const TOGGLE_TERMS_AND_CONDITIONS_ACCEPTED = 'TOGGLE_TERMS_AND_CONDITIONS_ACCEPTED';
export const FETCH_TERMS_AND_CONDITIONS_RESOLVED = 'FETCH_TERMS_AND_CONDITIONS_RESOLVED';
export const FETCH_TERMS_AND_CONDITIONS_REJECTED = 'FETCH_TERMS_AND_CONDITIONS_REJECTED';
export const FETCH_TERMS_AND_CONDITIONS_ACCEPTED_REQUESTED = 'FETCH_TERMS_AND_CONDITIONS_ACCEPTED_REQUESTED';
export const FETCH_TERMS_AND_CONDITIONS_ACCEPTED_RESOLVED = 'FETCH_TERMS_AND_CONDITIONS_ACCEPTED_RESOLVED';
export const FETCH_TERMS_AND_CONDITIONS_ACCEPTED_REJECTED = 'FETCH_TERMS_AND_CONDITIONS_ACCEPTED_REJECTED';
export const CLEAR_TERMS_AND_CONDITIONS = 'CLEAR_TERMS_AND_CONDITIONS';
export const SAVE_TERMS_AND_CONDITIONS_STATUS = 'SAVE_TERMS_AND_CONDITIONS_STATUS';
export const SAVE_VENDOR_TERMS_AND_CONDITIONS = 'SAVE_VENDOR_TERMS_AND_CONDITIONS';
export const FETCH_TERMS_AND_CONDITIONS_REQUESTED = 'FETCH_TERMS_AND_CONDITIONS_REQUESTED';
export const ACCEPTED_TERMS_AND_CONDITIONS_SUBMISSION = 'ACCEPTED_TERMS_AND_CONDITIONS_SUBMISSION';
export const FETCH_TERMS_STATUS_REQUESTED = 'FETCH_TERMS_STATUS_REQUESTED';
export const FETCH_TERMS_STATUS_RESOLVED = 'FETCH_TERMS_STATUS_RESOLVED';
export const FETCH_TERMS_STATUS_REJECTED = 'FETCH_TERMS_STATUS_REJECTED';
export const FETCH_TERMS_STATUS = 'FETCH_TERMS_STATUS';
export const FETCH_TERMS_TEXT = 'FETCH_TERMS_TEXT';

export const SELECT_CURRENCY = 'SELECT_CURRENCY';
export const CARWASH = 'CARWASH';
export const PAYMENT_TYPE = {
    INTELLIPAY: 'INTELLIPAY',
    STANDARD: 'STANDARD',
    EDI:'EDI'
}

export const CLEAR_MULTI_VEH_DATA_FOR_PO_CREATION = 'CLEAR_MULTI_VEH_DATA_FOR_PO_CREATION';
export const SAVE_MULTI_VEH_DATA_FOR_PO_CREATION_DATA = 'SAVE_MULTI_VEH_DATA_FOR_PO_CREATION_DATA';
export const SAVE_VEH_DATA_FOR_PO_CREATION_DATA = 'SAVE_VEH_DATA_FOR_PO_CREATION_DATA';

export const SAVE_IS_WORK_COMPLETION_FEATURE_ENABLED = 'SAVE_IS_WORK_COMPLETION_FEATURE_ENABLED';
export const SAVE_FIELD_VALIDATIONS = 'SAVE_FIELD_VALIDATIONS';

/* Reset Password */
export const POP_OVER_DISPLAY_INVALID_PASSWORD = 'POP_OVER_DISPLAY_INVALID_PASSWORD';
export const POP_OVER_DISPLAY_CONFIRM_PASSWORD = 'POP_OVER_DISPLAY_CONFIRM_PASSWORD';
export const RESET_PASSWORD_END_POINT_ERROR = 'RESET_PASSWORD_END_POINT_ERROR';
export const RESET_PASSWORD_INVALID_TOKEN_ERROR = 'RESET_PASSWORD_INVALID_TOKEN_ERROR';
export const CLEAR_RESET_PASSWORD_ERROR = 'CLEAR_RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const SAVE_RESET_PASSWORD_INFORMATION_PERSONAL = 'SAVE_RESET_PASSWORD_INFORMATION_PERSONAL';
export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_APPROVED = 'RESET_PASSWORD_APPROVED';
export const RESET_PASSWORD_RESOLVED = 'RESET_PASSWORD_RESOLVED';
export const RESET_PASSWORD_REJECTED = 'RESET_PASSWORD_REJECTED';
export const RESET_PASSWORD_MOUNT = 'RESET_PASSWORD_MOUNT';
export const SAVE_CONFIRM_PASSWORD_ERROR_MESSGAE = 'SAVE_CONFIRM_PASSWORD_ERROR_MESSGAE';

/* Holman User Accesss */
export const SAVE_ARIUSER_TOKEN = 'SAVE_ARIUSER_TOKEN';
export const ARIUSER_SEARCH_VENDOR = 'ARIUSER_SEARCH_VENDOR';
export const POP_OVER_DISPLAY_SHOW_VENDOR_ID_EMPTY = 'POP_OVER_DISPLAY_SHOW_VENDOR_ID_EMPTY';
export const POP_OVER_DISPLAY_SHOW_VENDOR_PERMISSION = 'POP_OVER_DISPLAY_SHOW_VENDOR_PERMISSION';
export const SET_SEARCH_VENDOR_ERROR_MESSAGE = 'SET_SEARCH_VENDOR_ERROR_MESSAGE';
export const HIDE_SEARCH_VENDOR_ERROR_MESSAGE = 'HIDE_SEARCH_VENDOR_ERROR_MESSAGE';
export const CLEAR_ARIUER_SEARCH_VENDOR = 'CLEAR_ARIUER_SEARCH_VENDOR';
export const POP_OVER_DISPLAY_SHOW_SUFFIX_MISSING = 'POP_OVER_DISPLAY_SHOW_SUFFIX_MISSING';

export const FETCH_SEARCH_VENDOR_REQUESTED = 'FETCH_SEARCH_VENDOR_REQUESTED';
export const FETCH_SEARCH_VENDOR_REJECTED = 'FETCH_SEARCH_VENDOR_REJECTED';
export const FETCH_SEARCH_VENDOR_RESOLVED = 'FETCH_SEARCH_VENDOR_RESOLVED';
export const ARIUSER__SEARCH_NEW_VENDOR = 'ARIUSER__SEARCH_NEW_VENDOR';

//ChangePassword
export const POP_OVER_DISPLAY_INVALID_CURRENT_PASSWORD = 'POP_OVER_DISPLAY_INVALID_CURRENT_PASSWORD';
export const CLEAR_FORCE_PASSWORD_CHANGE_ERROR = 'CLEAR_FORCE_PASSWORD_CHANGE_ERROR';
export const SAVE_CURRENT_PASSWORD_ERROR_MESSGAE = 'SAVE_CURRENT_PASSWORD_ERROR_MESSGAE';
export const SAVE_NEW_PASSWORD_ERROR_MESSGAE = 'SAVE_NEW_PASSWORD_ERROR_MESSGAE';
export const SAVE_PASSWORD_EXPIRY_DATE = 'SAVE_PASSWORD_EXPIRY_DATE';
export const CHANGE_PASSWORD_MOUNT = 'CHANGE_PASSWORD_MOUNT';
export const RESET_PASSWORD_EXPIRY_DATE = 'RESET_PASSWORD_EXPIRY_DATE';
export const SHOW_RESET_PASSWORD_ERROR_MESSAGE = 'SHOW_RESET_PASSWORD_ERROR_MESSAGE';

/*
Notification
*/

export const POSITION = {
    TOP_LEFT: 'top-left',
    TOP_RIGHT: 'top-right',
    TOP_CENTER: 'top-center',
    BOTTOM_LEFT: 'bottom-left',
    BOTTOM_RIGHT: 'bottom-right',
    BOTTOM_CENTER: 'bottom-center'
};

export const TYPE = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    DEFAULT: 'default'
};

/* Crane inspection */
export const CRANE_INSPECTION__DISPLAY = 'CRANE_INSPECTION__DISPLAY';
export const CRANE_INSPECTION__RESET = 'CRANE_INSPECTION__RESET';
export const CRANE_INSPECTION__RESET_ERROR = 'CRANE_INSPECTION__RESET_ERROR';
export const CRANE_INSPECTION__SET_FORM_DATA = 'CRANE_INSPECTION__SET_FORM_DATA';
export const CRANE_INSPECTION__SAVE_CONFIRMATION = 'CRANE_INSPECTION__SAVE_CONFIRMATION';
export const CRANE_INSPECTION__SAVE_CONFIRMATION_REQUESTED = 'CRANE_INSPECTION__SAVE_CONFIRMATION_REQUESTED';
export const CRANE_INSPECTION__SAVE_CONFIRMATION_RESOLVED = 'CRANE_INSPECTION__SAVE_CONFIRMATION_RESOLVED';
export const CRANE_INSPECTION__SAVE_CONFIRMATION_REJECTED = 'CRANE_INSPECTION__SAVE_CONFIRMATION_REJECTED';
export const CRANE_INSPECTION__SAVE_FORM = 'CRANE_INSPECTION__SAVE_FORM';
export const CRANE_INSPECTION__SAVE_FORM_REQUESTED = 'CRANE_INSPECTION__SAVE_FORM_REQUESTED';
export const CRANE_INSPECTION__SAVE_FORM_RESOLVED = 'CRANE_INSPECTION__SAVE_FORM_RESOLVED';
export const CRANE_INSPECTION__SAVE_FORM_REJECTED = 'CRANE_INSPECTION__SAVE_FORM_REJECTED';
export const CRANE_INSPECTION__SET_VALIDATION_MESSAGE = 'CRANE_INSPECTION__SET_VALIDATION_MESSAGE';
export const CRANE_INSPECTION__RESET_VALIDATION_MESSAGES = 'CRANE_INSPECTION__RESET_VALIDATION_MESSAGES';
export const CRANE_INSPECTION__SET_COMPONENT_DETAILS = 'CRANE_INSPECTION__SET_COMPONENT_DETAILS';
export const CRANE_INSPECTION__SET_ALL_COMPONENT_OK = 'CRANE_INSPECTION__SET_ALL_COMPONENT_OK';
export const CRANE_INSPECTION__SET_DEFAULT_VALUES = 'CRANE_INSPECTION__SET_DEFAULT_VALUES';
export const CRANE_INSPECTION__WINDOW_SHOWN = 'CRANE_INSPECTION__WINDOW_SHOWN';
export const CRANE_INSPECTION__SKIP_FAIL_OPTIONS = 'CRANE_INSPECTION__SKIP_FAIL_OPTIONS';

export const RECOVER_PASSWORD_SELECTED_COUNTRY_CHANGED = 'RECOVER_PASSWORD_SELECTED_COUNTRY_CHANGED';

export const UPDATE_VENDOR_PAYMENT_EMAIL_DETAILS='UPDATE_VENDOR_PAYMENT_EMAIL_DETAILS';
export const VENDOR_PAYMENT_EMAIL_SUCCESS='VENDOR_PAYMENT_EMAIL_SUCCESS';
export const SHOW_PAYMENT_EMAIL_VALIDATION='SHOW_PAYMENT_EMAIL_VALIDATION';
export const HIDE_PAYMENT_EMAIL_VALIDATION='HIDE_PAYMENT_EMAIL_VALIDATION';

export const ACCEPT_ADJUSTMENT_REQUESTED = 'ACCEPT_ADJUSTMENT_REQUESTED';
export const CLEAR_COMPLAINTS = 'CLEAR_COMPLAINTS';
export const LOAD_RECENT_SERVICES_DATA = 'LOAD_RECENT_SERVICES_DATA';
export const SET_VENDOR_DETAILS = 'SET_VENDOR_DETAILS';
export const UPDATE_OFFSET = 'UPDATE_OFFSET';
export const UPLOAD_DOCUMENT_REQUESTED = 'UPLOAD_DOCUMENT_REQUESTED';
export const VEHICLE_DOCUMENT_SET_UPLOAD_FILE_NAME = 'VEHICLE_DOCUMENT_SET_UPLOAD_FILE_NAME';
export const LOAD_SERVICE_DATA = 'LOAD_SERVICE_DATA';

export const FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR = {
    APPROVED_LINEITEM: 'APPROVED_LINEITEM',
    REJECTED_LINEITEM: 'REJECTED_LINEITEM',
    ISSUE_NOTE: 'ISSUE_NOTE',
    ADJUSTED_LINEITEM: 'ADJUSTED_LINEITEM',
    NEW_LINEITEM: 'NEW_LINEITEM',
    NEED_MORE_NOTES: 'NEED_MORE_NOTES'
};

//entityForAssistance
export const ENTITY_FOR_ASSISTANCE = {
    Holman: 'ari',
    CUSTOMER: 'customer'
};

//send Order Notes To
export const SEND_ORDER_NOTES_TO = {
    CUSTOMER: 'customer'
};