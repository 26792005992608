import React from 'react'
import { createGlobalStyle } from 'styled-components'
import jwtDecode from "jwt-decode"
import moment from 'moment'
import { defineMessages, FormattedMessage } from 'react-intl'

import { useAppState } from 'shared/AppState/app-state'
import * as Api from 'utils/Api'
import configuration from 'configuration'
import { PrimaryButtonStyled, SecondaryButtonStyled} from 'shared/DesignSpecs/components/HolmanButtons.js'
import { useLocalStorage } from 'shared/hooks/useLocalStorage'
import TranslationIcon from 'components/SiteTranslations'

// #region Styled Components
const GlobalStyle = createGlobalStyle`
  html {
    position: fixed; 
  }
  `  
  // #endregion

const getTodayMMDD = () => {
  let temp = moment().format('MMDD')  
  return temp
}

export const HolmanRedirect = () => {
  const [appState, dispatch] = useAppState()
  const [step, setStep] = useLocalStorage('redirect')
  const [redirectMMDD, setRedirectMMDD] = useLocalStorage('holman-redirect-mmdd', '');
  const showHolmanRedirectScreenFlag = configuration.showHolmanRedirectScreen  
  const [redirectOverlayVisible, setRedirectOverlayVisible] = React.useState(
    showHolmanRedirectScreenFlag === 'enabled' && redirectMMDD !== getTodayMMDD()
  )
  
  const messages = defineMessages({
    holman_redirect__holman_button1: {
      id: 'holman_redirect__holman_button1',
      description: 'Go to new site',
      defaultMessage: 'Go to new site'
    },
    holman_redirect__ari_button1: {
      id: 'holman_redirect__ari_button1',
      description: 'Stay on old site',
      defaultMessage: 'Stay on old site'
    },
    holman_redirect__holman_redirect__holman_button2: {
      id: 'holman_redirect__holman_redirect__holman_button2',
      description: 'Go to the new Holman PartnerConnect website',
      defaultMessage: 'Go to the new Holman PartnerConnect website'
    },
    holman_redirect__ari_button2: {
      id: 'holman_redirect__ari_button2',
      description: 'Stay on old ARI PartnerConnect website',
      defaultMessage: 'Stay on old ARI PartnerConnect website'
    },
    holman_redirect__holman_redirect__holman_button3: {
      id: 'holman_redirect__holman_redirect__holman_button3',
      description: 'Go to the new Holman PartnerConnect website',
      defaultMessage: 'Go to the new Holman PartnerConnect website'
    },
    holman_redirect__instructions_new_site: {
      id: 'holman_redirect__instructions_new_site',
      description: 'Instructions for going to new site',
      defaultMessage: `<p>
      Please click the button below to navigate to the new Holman PartnerConnect website.
      If you have any of the following issues after navigating, follow the instructions below:
    </p>

    <ul>
      <li>
        Corporate firewall blocking new website: partnerconnect.holman.com
        <ul>
          <li>Click the <b>back button</b> to return to previous screen and then click <b>Stay on old site.</b></li>
          <li>Work with your IT support team to unblock access to partnerconnect.holman.com</li>
        </ul>
      </li>
      <li>
        Not able to login because your username and password were stored on old site
        <ul>
          <li>Use the forgot password link on the new site to reset your password.</li>                  
        </ul>
      </li>
      <li>
        If the previous solutions do not resolve your issues...
        <ul>
          <li>Contact Holman via email: <Mailto>aripartnerconnect@arifleet.com</Mailto></li>
        </ul>
      </li>
    </ul>`
    },
    holman_redirect__instructions_old_site: {
      id: 'holman_redirect__instructions_old_site',
      description: 'Instructions for staying on old site',
      defaultMessage: `<p>
      You can continue using the old site until <Date>April 30th</Date>.  During that time please try to use the new
      web site and resolve any issues.  Below are common issues and their resolutions:
    </p>
  
    <ul>
      <li>
        Corporate firewall blocking new website: partnerconnect.holman.com
        <ul>
          <li>Click the <b>back button</b> to return to previous screen and then click <b>Stay on old site.</b></li>                    
          <li>Work with your IT support team to unblock access to partnerconnect.holman.com</li>
        </ul>
      </li>
      <li>
        Not able to login because your username and password were stored on old site
        <ul>
          <li>Use the forgot password link on the new site to reset your password.</li>                  
        </ul>
      </li>
      <li>
        If the previous solutions do not resolve your issues...
        <ul>
          <li>Contact Holman via email: <Mailto>aripartnerconnect@arifleet.com</Mailto></li>
        </ul>
      </li>
    </ul>`
    },
  });

  const handleAriClick = () => {
    setStep('ariInstructions')
  }

  const handleHolmanClick = () => {    
    setStep('holmanInstructions')
  }  

  const handleImageClick = () => {
    setStep('holmanInstructions')
  }  

  const handleAriClick2 = () => {
    setRedirectMMDD(getTodayMMDD())    
    setRedirectOverlayVisible(false)
    setStep('redirect')
    Api.logHolmanRedirectChoice(window.location.hostname)
  }

  const handleHolmanClick2 = () => {
    setRedirectMMDD(getTodayMMDD())    
    Api.logHolmanRedirectChoice(configuration.holmanPartnerConnectRedirectUrl)
    setStep('redirect')
    window.location.href = configuration.holmanPartnerConnectRedirectUrl;      
  }
  
  // Hide redirect screen for SSO vendors.
  React.useEffect(() => {
    if (!appState.token) return
    const aud = jwtDecode(appState.token).aud.toUpperCase()
    
    if ((['FCASECUREAUTH', 'LESSCHWABSECUREAUTH', 'NAVISTARSSO'].includes(aud))) {
      setRedirectOverlayVisible(false)
    }    
  }, [appState.token])

  if (!redirectOverlayVisible) 
    return null;

  return (    
    <div
      css={`        
        background: white;        
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow-y: auto;
        z-index: 9999;              
      `}
    >
      <GlobalStyle/>

      <div
        css={`                    
          height: 100%;
          position: relative;          
          font-family: ${({theme})=>theme.holmanFont.primary};
        `}
      >        
          <>
            <img 
              src='/assets/HolmanPartnerConnectRedirect.jpg' 
              className='img-responsive'
              css={` 
                width: 100%;                 
                position: absolute;
                cursor: pointer;
              `}
              onClick={handleImageClick}
            />
          
            <div 
              css={`                      
                position: absolute;            
                display: flex;
                padding: 1rem;
                top: 1rem;
                left: 30%;
                right: 0;                
                z-index: 9999;                
              `}
            >
              <PrimaryButtonStyled variant='contrast' id='Redirect-NewSite' onClick={handleHolmanClick}>                
                <FormattedMessage {...messages.holman_redirect__holman_button1} />
              </PrimaryButtonStyled>              

              &nbsp;
              
              <SecondaryButtonStyled variant='contrast' id='Redirect-OldSite' onClick={handleAriClick}>
                <FormattedMessage {...messages.holman_redirect__ari_button1} />
              </SecondaryButtonStyled>   
              
              &nbsp;
              
              <div css={`
                margin-left: 3rem;
                margin-top: .5rem;
              `}>
                <TranslationIcon />
              </div>

            </div>
          </>
        
        {step === 'ariInstructions' &&
            
            <Instructions>

              <div css={`li { padding: 1rem;}`}>
                <FormattedMessage 
                  {...messages.holman_redirect__instructions_old_site} 
                  values={{
                    p: chunks => <p>{chunks}</p>,
                    b: chunks => <b>{chunks}</b>,
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => <li>{chunks}</li>,
                    Date: chunks => <span css={`font-size:4rem; font-weight:900;`}>{chunks}</span>,
                    Mailto: chunks => <a href={`mailto:${chunks}`}>{chunks}</a>,
                  }}
                />
              </div>

              <div>

                <SecondaryButtonStyled id='Redirect-OldSite-Confirmation' onClick={handleAriClick2}>
                  <FormattedMessage {...messages.holman_redirect__ari_button2} /> {' '}                
                </SecondaryButtonStyled>

                {' '}

                <PrimaryButtonStyled id='Redirect-NewSite-Instead' onClick={handleHolmanClick2}>
                  <FormattedMessage {...messages.holman_redirect__holman_redirect__holman_button3} /> {' '}
                  <img src="/assets/arrow-right-white.svg"></img>
                </PrimaryButtonStyled>

              </div>

            </Instructions>  
          }
        
          
                            
        {step === 'holmanInstructions' &&
          <Instructions>

            <div css={`li { padding: 1rem;}`}>
              <FormattedMessage 
                {...messages.holman_redirect__instructions_new_site} 
                values={{
                  p: chunks => <p>{chunks}</p>,
                  b: chunks => <b>{chunks}</b>,
                  ul: chunks => <ul>{chunks}</ul>,
                  li: chunks => <li>{chunks}</li>,                  
                  Mailto: chunks => <a href={`mailto:${chunks}`}>{chunks}</a>,
                }}
              />
            </div>

            <div>
              
              <PrimaryButtonStyled id='Redirect-NewSite-Confirmation' onClick={handleHolmanClick2}>
                <FormattedMessage {...messages.holman_redirect__holman_redirect__holman_button2} />
                <img src="/assets/arrow-right-white.svg"></img>
              </PrimaryButtonStyled>

            </div>

          </Instructions>
        }

      </div>

    </div>
  )
}

const Instructions = ({children}) => {
  return (
    <div
      css={`        
        background-color: rgba(0, 0, 0, 0.4);        
        width: 100%;
        height: 100%;
        position: absolute;
        top: 2rem;
        margin: auto;
        
        z-index: 9999;              
      `}
    >
      <div
        css={`
          background: white;        
          color: #003057; 
          font-weight: 500;
          font-size: 2rem;
          margin: 7rem;  
          margin-top: 2rem;        
        `}
      > 
        <div css={`border: 2px solid #003057;padding: 2rem;`}>
          <div css={`display: flex; justify-content: space-between;`}>
            <div css={`width: 300px; padding-bottom: 4rem; `}>
              <img 
                src='/assets/Logo-Primary-PMS.png'
                className='img-responsive'
              />           
            </div>
                      
            <div css={`
                margin-right: 5rem;
              `}>
                <TranslationIcon />
              </div>
              
          </div>

          {children}

        </div>
      </div>
    </div>
  )
}